///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useState} from 'react'
import { faCaretUp, faGears, faMagnifyingGlass, faPlusMinus, faDownload, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip';

import { createNotification } from '../../services/NotificationService';
import { getMonthName } from '../../services/DateService';
import { useAuth } from '../../context/AuthContext';
import ExcelResultTable from './ExcelResultTable';



const HandleExcel = ({startData}) => {

    const [oldGenerated, setOldGenerated] = useState([])
    const handleSetOld = (data) => {
        setOldGenerated(data)
        data.length > 0 && setData(prev => ({...prev, isOldData:true, oldData:data }))
    }
    const [topOpen, setTopOpen] = useState(true);
    const [data, setData]       = useState({
        ...startData,
        setOldData: handleSetOld,
        oldData:   oldGenerated,
        fullWidth: false,
        isOldData: false,
        detailed:  false,
        onlyReq:   false,
        showErr:   false,
        valStat:   false,
        reviz:     false,
    })
    const [actions, setActions] = useState({
        exportExcelErr: false,
        downloadOldXML: false,
        calcValStat:    false,
        exportExcel:    false,
        generate:       false,
        checkVat:       false,
    })

    const {
        companies,
        userData,
        layouts
    } = useAuth()

    const { data: uData, subscriptions: uSub } =  userData

    const toolTipData = [
        `Calculează valoarea statistică și modifică procentele din meniul "Calcul val stat"`,
        `Marchează tipul declarației ca "revizuită"`,
        `La generarea fișierului XML nu se vor cumula valorile`,
        `Afișează doar erorile în tabelul de mai jos`,
        `Afișează doar coloanele necesare în tabelul de mai jos`,
        `Mărește lățimea coloanelor din tabelul de mai jos`
    ]

    const handleClick = (type) => {
        // generare
        if(type === 0) {
            const inverted = !actions.generate
            !isDisabled(0) ? setActions(prev => ({...prev, generate: inverted})) :
            createNotification('info', 'Această funcție nu este disponibilă')
        }
        // verificare vat
        else if(type === 1) {
            const inverted = !actions.checkVat
            !isDisabled(1) ? setActions(prev => ({...prev, checkVat: inverted})) :
            createNotification('info', 'Această funcție nu este disponibilă în abonamentul actual')
        }
        // schimb valutar
        else if(type === 2) {
            
            ////console.log('CURS')
        }
        // export excel
        else if(type === 3) {
            const inverted = !actions.exportExcel
            !isDisabled(3) ? setActions(prev => ({...prev, exportExcel: inverted})) :
            createNotification('info', 'Această funcție nu este disponibilă')
        }
        // val stat
        else if(type === 4) {
            const inverted = !actions.calcValStat
            !isDisabled(4) ? setActions(prev => ({...prev, calcValStat: inverted})) :
            createNotification('info', 'Această funcție nu este disponibilă în abonamentul actual')
        }
        // errors excel
        else if(type === 5) {
            const inverted = !actions.exportExcelErr
            !isDisabled(5) ? setActions(prev => ({...prev, exportExcelErr: inverted})) :
            createNotification('info', 'Această funcție nu este disponibilă')
        }
        // download generated
        else if(type === 6) {
            const inverted = !actions.downloadOldXML
            !isDisabled(6) ? setActions(prev => ({...prev, downloadOldXML: inverted})) :
            createNotification('info', 'Nu există un fișier generat încă')
        }
    }

    const isDisabled = (type) => {
        // generare
        if(type === 0) {
            return false
        }
        // verificare vat
        else if(type === 1) {
            if (uSub[0].cifCheck) {
                return layouts[data.layout].type === 'achizitii'
            }
            return true
        }
        // schimb valutar
        else if(type === 2) {
            return true
        }
        // export excel
        else if(type === 3) {
            return false
        }
        // val stat
        else if(type === 4) {
            if (uSub[0].valStatCalc) {
                return !data.valStat
            }
            return true
        }
        // errors excel
        else if(type === 5) {
            return false
        }
        // download generated
        else if(type === 6) {
            return oldGenerated.length < 1
        }
    }

    return (
        <div className={`handle-section`} >
            <div className={`handle-top-info ${topOpen ? '' : 'collapsed'}`}>
                <div id='collapse-btn' onClick={() => setTopOpen(!topOpen)}>
                    <FontAwesomeIcon id='inside' icon={faCaretUp} />
                </div>
                <div className='handle-small-wrapper'>
                    <div className='handle-multi-row 3'>
                        <div>Perioadă: {data.month} ({getMonthName(data.month)}) 
                        - {data.year}</div>
                        <div>Tip: {layouts[data.layout].type.toUpperCase()}</div>
                        <div>Firm{data.tert ? 'e' : 'ă'}: {data.tert ? 
                            <>
                            <span data-tip data-for="my-comp" >{`${uData.cui} `}</span>
                            -<span data-tip data-for="for-comp" >{` ${companies[data.compFor].cif}`}</span> 
                            </>
                            : <span data-tip data-for="my-comp" > {`${uData.cui}`} </span>}
                        </div>
                    </div>

                    <div className='handle-multi-row 3'>
                        <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                            <input className='checkbox' value={data.valStat} defaultChecked={data.valStat}
                                onChange={(e) => setData(prev => ({...prev, valStat: e.target.checked}))}
                                type="checkbox" id="valStat" name="valStat" title='Calcul valoare statistică'/>
                            <label htmlFor="valStat" data-tip="0" data-for="select-tooltip">Calcul valoare statistică
                            </label>
                        </div>

                        <div className='checkbox-wrapper' style={{marginTop:'2em'}} >
                            <input className='checkbox' value={data.reviz} defaultChecked={data.reviz}
                                onChange={(e) => setData(prev => ({...prev, reviz: e.target.checked}))}
                                type="checkbox" id="reviz" name="reviz" title='Declarație revizuită'/>
                            <label htmlFor="reviz" data-tip="1" data-for="select-tooltip">Declarație revizuită
                            </label>
                        </div>

                        <div className='checkbox-wrapper' style={{marginTop:'2em'}} >
                            <input className='checkbox' value={data.detailed} defaultChecked={data.detailed}
                                onChange={(e) => setData(prev => ({...prev, detailed: e.target.checked}))}
                                type="checkbox" id="detailed" name="detailed" title='XML detaliat'/>
                            <label htmlFor="detailed" data-tip="2" data-for="select-tooltip">XML detaliat
                            </label>
                        </div>

                    </div>

                    <div className='handle-multi-row 3'>
                        <div className='checkbox-wrapper' style={{marginTop:'2em'}} >
                            <input className='checkbox' value={data.showErr} defaultChecked={data.showErr}
                                onChange={(e) => setData(prev => ({...prev, showErr: e.target.checked}))}
                                type="checkbox" id="showErr" name="showErr" title='Afișează doar erori'/>
                            <label htmlFor="showErr" data-tip="3" data-for="select-tooltip">
                                Afișează doar erori
                            </label>
                        </div>

                        <div className='checkbox-wrapper' style={{marginTop:'2em'}} >
                            <input className='checkbox' value={data.onlyReq} defaultChecked={data.onlyReq}
                                onChange={(e) => setData(prev => ({...prev, onlyReq: e.target.checked}))}
                                type="checkbox" id="onlyReq" name="onlyReq" title='Afișează doar necesare'/>
                            <label htmlFor="onlyReq" data-tip="4" data-for="select-tooltip">Afișează doar necesare
                            </label>
                        </div>

                        <div className='checkbox-wrapper' style={{marginTop:'2em'}} >
                            <input className='checkbox' value={data.fullWidth} defaultChecked={data.fullWidth}
                                onChange={(e) => setData(prev => ({...prev, fullWidth: e.target.checked}))}
                                type="checkbox" id="fullWidth" name="fullWidth" title='Lățime întreagă tabel'/>
                            <label htmlFor="fullWidth" data-tip="5" data-for="select-tooltip">Lățime întreagă tabel
                            </label>
                        </div>

                    </div>

                    <div className='handle-single-row'>
                        <div className={`button-secondary selected-btn${isDisabled(0) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(0)}>
                            <FontAwesomeIcon icon={faGears} /> Generare</div>
 
                        <div data-tip data-for="download" className={`button-secondary selected-btn${isDisabled(6) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(6)} style={{width:'auto'}}>
                            <FontAwesomeIcon icon={faDownload} /></div>

                        <div className={`button-secondary selected-btn type-blue${isDisabled(1) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(1)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} /> Verificare VAT</div>

                        <div className={`button-secondary selected-btn type-blue${isDisabled(4) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(4)}>
                            <FontAwesomeIcon icon={faPlusMinus} /> Calcul val stat</div>

                        <div className={`button-secondary${isDisabled(3) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(3)}>
                            <FontAwesomeIcon icon={faDownload} /> Exportă excel</div>

                        <div className={`button-secondary${isDisabled(5) ? ' disabled' : ''}`} 
                        onClick={() => handleClick(5)}>
                            <FontAwesomeIcon icon={faTriangleExclamation} /> Exportă erori</div>
                    </div>

                </div>
            </div>
            <ExcelResultTable data = {data} actions = {actions} />
            <ReactTooltip effect="solid" id='for-comp' type="success">{companies[data.compFor].name}</ReactTooltip>
            <ReactTooltip effect="solid" id='my-comp' type="success">{companies[0].name}</ReactTooltip>
            <ReactTooltip effect="solid" id='download' type="success"><h3>Descarcă fișierul deja generat</h3></ReactTooltip>

            <ReactTooltip effect="solid" id='select-tooltip' type="info" 
            getContent={(dataTip) => `${toolTipData[parseInt(dataTip)]}`} />
        </div>
    )
}

export default HandleExcel