///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const counties = 
[
    'Bucuresti',
    'Alba',
    'Arad',
    'Arges',
    'Bacau',
    'Bihor',
    'Bistrita - Nasaud',
    'Botosani',
    'Braila',
    'Brasov',
    'Buzau',
    'Calarasi',
    'Caras - Severin',
    'Cluj',
    'Constanta',
    'Covasna',
    'Dambovita',
    'Dolj',
    'Galati',
    'Giurgiu',
    'Gorj',
    'Harghita',
    'Hunedoara',
    'Ialomita',
    'Iasi',
    'Ilfov',
    'Maramures',
    'Mehedinti',
    'Mures',
    'Neamt',
    'Olt',
    'Prahova',
    'Salaj',
    'Satu Mare',
    'Sibiu',
    'Suceava',
    'Teleorman',
    'Timis',
    'Tulcea',
    'Valcea',
    'Vaslui',
    'Vrancea',
];

export const roCountyCodes = [
    {
        "ALBA": "1"
    },
    {
        "ARAD": "2"
    },
    {
        "ARGES": "3"
    },
    {
        "BACAU": "4"
    },
    {
        "BIHOR": "5"
    },
    {
        "BISTRITA-NASAUD": "6"
    },
    {
        "BOTOSANI": "7"
    },
    {
        "BRASOV": "8"
    },
    {
        "BRAILA": "9"
    },
    {
        "BUZAU": "10"
    },
    {
        "CARAS-SEVERIN": "11"
    },
    {
        "CLUJ": "12"
    },
    {
        "CONSTANTA": "13"
    },
    {
        "COVASNA": "14"
    },
    {
        "DIMBOVITA": "15"
    },
    {
        "DOLJ": "16"
    },
    {
        "GALATI": "17"
    },
    {
        "GORJ": "18"
    },
    {
        "HARGHITA": "19"
    },
    {
        "HUNEDOARA": "20"
    },
    {
        "IALOMITA": "21"
    },
    {
        "IASI": "22"
    },
    {
        "ILFOV": "23"
    },
    {
        "MARAMURES": "24"
    },
    {
        "MEHEDINTI": "25"
    },
    {
        "MURES": "26"
    },
    {
        "NEAMT": "27"
    },
    {
        "OLT": "28"
    },
    {
        "PRAHOVA": "29"
    },
    {
        "SATU-MARE": "30"
    },
    {
        "SALAJ": "31"
    },
    {
        "SIBIU": "32"
    },
    {
        "SUCEAVA": "33"
    },
    {
        "TELEORMAN": "34"
    },
    {
        "TIMIS": "35"
    },
    {
        "TULCEA": "36"
    },
    {
        "VASLUI": "37"
    },
    {
        "VILCEA": "38"
    },
    {
        "VRANCEA": "39"
    },
    {
        "BUCURESTI": "40"
    },
    {
        "CALARASI": "51"
    },
    {
        "GIURGIU": "52"
    }
]

export const cities = 
[
    "Bucuresti",
    "Timisoara",
    "Cluj-Napoca",
    "Brasov",
    "Iasi",
    "Constanta",
    "Craiova",
    "Galati",
    "Ploiesti",
    "Oradea",
    "Braila",
    "Arad",
    "Pitesti",
    "Sibiu",
    "Bacau",
    "Targu-Mures",
    "Baia Mare",
    "Buzau",
    "Botosani",
    "Satu Mare",
    "Ramnicu Valcea",
    "Drobeta-Turnu Severin",
    "Tulcea",
    "Suceava",
    "Piatra Neamt",
    "Resita",
    "Targu Jiu",
    "Targoviste",
    "Focsani",
    "Bistrita",
    "Slatina",
    "Calarasi",
    "Alba Iulia",
    "Giurgiu",
    "Deva",
    "Hunedoara",
    "Zalau",
    "Sfantu-Gheorghe",
    "Barlad",
    "Vaslui",
    "Roman",
    "Turda",
    "Medias",
    "Slobozia",
    "Alexandria",
    "Voluntari",
    "Miercurea-Ciuc",
    "Lugoj",
    "Medgidia",
    "Onesti",
    "Sighetu Marmatiei",
    "Petrosani",
    "Mangalia",
    "Tecuci",
    "Odorheiu Secuiesc",
    "Ramnicu Sarat",
    "Pascani",
    "Dej",
    "Reghin",
    "Navodari",
    "Campina",
    "Mioveni",
    "Campulung",
    "Caracal",
    "Sacele",
    "Fagaras",
    "Fetesti",
    "Sighisoara",
    "Borsa",
    "Rosiori de Vede",
    "Curtea de Arges",
    "Sebes",
    "Husi",
    "Falticeni",
    "Pantelimon",
    "Oltenita",
    "Turnu Magurele",
    "Caransebes",
    "Dorohoi",
    "Vulcan",
    "Radauti",
    "Zarnesti",
    "Lupeni",
    "Aiud",
    "Petrila",
    "Campia Turzii",
    "Buftea",
    "Tarnaveni",
    "Popesti-Leordeni",
    "Moinesti",
    "Codlea",
    "Cugir",
    "Carei",
    "Gherla",
    "Scobinti",
    "Blaj",
    "Floresti",
    "Comanesti",
    "Motru",
    "Targu Neamt",
    "Moreni",
    "Toflea",
    "Targu Secuiesc",
    "Gheorgheni",
    "Orastie",
    "Bals",
    "Baicoi",
    "Dragasani",
    "Salonta",
    "Bailesti",
    "Calafat",
    "Cernavoda",
    "Filiasi",
    "Campulung Moldovenesc",
    "Corabia",
    "Adjud",
    "Breaza",
    "Bocsa",
    "Marghita",
    "Baia-Sprie",
    "Bragadiru",
    "Ludus",
    "Urziceni",
    "Viseu de Sus",
    "Rasnov",
    "Buhusi",
    "Brad",
    "Vatra Dornei",
    "Mizil",
    "Cisnadie",
    "Pucioasa",
    "Chitila",
    "Zimnicea",
    "Toplita",
    "Otopeni",
    "Ovidiu",
    "Gura Humorului",
    "Jimbolia",
    "Gaesti",
    "Vicovu de Sus",
    "Ocna Mures",
    "Bolintin Vale",
    "Avrig",
    "Pecica",
    "Simeria",
    "Valu lui Traian",
    "Moldova Noua",
    "Cumpana",
    "Sannicolau Mare",
    "Darmanesti",
    "Jilava",
    "Dabuleni",
    "Comarnic",
    "Negresti-Oas",
    "Rovinari",
    "Scornicesti",
    "Targu Lapus",
    "Valenii de Munte",
    "Matca",
    "Sacueni",
    "Videle",
    "Santana",
    "Oravita",
    "Targu Ocna",
    "Calan",
    "Boldesti-Scaeni",
    "Magurele",
    "Harlau",
    "Draganesti-Olt",
    "Cristuru Secuiesc",
    "Poiana Mare",
    "Marasesti",
    "Beius",
    "Beclean",
    "Urlati",
    "Otelu Rosu",
    "Strehaia",
    "Targu Frumos",
    "Orsova",
    "Sinaia",
    "Jibou",
    "Sovata",
    "Costesti",
    "Ianca",
    "Lipova",
    "Dolhasca",
    "Topoloveni",
    "Murfatlar",
    "Nehoiu",
    "Flamanzi",
    "Pechea",
    "Covasna",
    "Poienile de sub Munte",
    "Alesd",
    "Valea lui Mihai",
    "Darabani",
    "Hateg",
    "Sangeorz-Bai",
    "Titu",
    "Harsova",
    "Liteni",
    "Podu Iloaiei",
    "Odobesti",
    "Huedin",
    "Moisei",
    "Ineu",
    "Salcea",
    "Seini",
    "Uricani",
    "Babadag",
    "Bumbesti-Jiu",
    "Liesti",
    "Busteni",
    "Sangeorgiu de Mures",
    "Agnita",
    "Sabaoani",
    "Iernut",
    "Baraolt",
    "Tasnad",
    "Bicaz",
    "Roznov",
    "Marginea",
    "Babeni",
    "Negresti",
    "Recas",
    "Mihail Kogalniceanu",
    "Macin",
    "Tomesti",
    "Targu Carbunesti",
    "Rosu",
    "Chisineu Cris",
    "Borcea",
    "Siret",
    "Mihailesti",
    "Afumati",
    "Domnesti",
    "Prim Decembrie",
    "Lumina",
    "Budesti",
    "Panciu",
    "Vlahita",
    "Campeni",
    "Mogosoaia",
    "Calimanesti",
    "Fieni",
    "Modelu",
    "Intorsura Buzaului",
    "Dumbravita",
    "Zlatna",
    "Anina",
    "Chirnogi",
    "Curtici",
    "Nadlac",
    "Dumbraveni",
    "Victoria",
    "Amara",
    "Branesti",
    "Patarlagele",
    "Techirghiol",
    "Pogoanele",
    "Ulmeni",
    "Turceni",
    "Cehu Silvaniei",
    "Tautii Magherus",
    "Murgeni",
    "Tismana",
    "Buzias",
    "Segarcea",
    "Dumbraveni",
    "Saveni",
    "Pancota",
    "Ungheni",
    "Sarmasu",
    "Racari",
    "Talmaciu",
    "Cajvana",
    "Fundulea",
    "Livada",
    "Faget",
    "Tuzla",
    "Sadova",
    "Teius",
    "Diosig",
    "Insuratei",
    "Lehliu-Gara",
    "Cobadin",
    "Gilau",
    "Vladimirescu",
    "Dancu",
    "Peretu",
    "Cudalbi",
    "Bosanci",
    "Piatra Olt",
    "Bujor",
    "Balotesti",
    "Horezu",
    "Deta",
    "Ardud",
    "Lunca Cetatuii",
    "Dragalina",
    "Remetea",
    "Balan",
    "Sandominic",
    "Strejnicu",
    "Baciu",
    "Roseti",
    "Cornetu",
    "Slanic",
    "Brezoi",
    "Sebis",
    "Apahida",
    "Berceni",
    "Vicovu de Jos",
    "Savinesti",
    "Barbulesti",
    "Plosca",
    "Frasin",
    "Gataia",
    "Feldru",
    "Potcoava",
    "Peris",
    "Fundeni",
    "Giroc",
    "Oituz",
    "Rucar",
    "Curcani",
    "Macea",
    "Rodna",
    "Miercurea Nirajului",
    "Ruscova",
    "Brosteni",
    "Glina",
    "Novaci-Straini",
    "Saliste",
    "Copsa Mica",
    "Motatei",
    "Gugesti",
    "Sancraiu de Mures",
    "Baia de Arama",
    "Ciacova",
    "Maieru",
    "Vanju-Mare",
    "Prejmer",
    "Geoagiu",
    "Dobroesti",
    "Rupea",
    "Arbore",
    "Homocea",
    "Corund",
    "Tufesti",
    "Giarmata",
    "Baia",
    "Sangeorgiu de Padure",
    "Horodnicu de Sus",
    "Greci",
    "Tudora",
    "Straja",
    "Negru Voda",
    "Rasinari",
    "Abrud",
    "Ostrov",
    "Raducaneni",
    "Paunesti",
    "Isaccea",
    "Baile Herculane",
    "Tunari",
    "Milisauti",
    "Valea Lupului",
    "Cavnic",
    "Fierbinti-Targ",
    "Ulmeni",
    "Filipestii de Padure",
    "Volovat",
    "Viseu de Jos",
    "Corbu",
    "Salistea de Sus",
    "Tudor Vladimirescu",
    "Ditrau",
    "Balcesti",
    "Agigea",
    "Berbesti",
    "Sanpetru",
    "Izbiceni",
    "Facaeni",
    "Cernele",
    "Bucov",
    "Cetate",
    "Predeal",
    "Cristesti",
    "Joseni",
    "Marsani",
    "Desa",
    "Corod",
    "Vanatori-Neamt",
    "Repedea",
    "Clinceni",
    "Baleni Sarbi",
    "Ghimbav",
    "Halaucesti",
    "Harman",
    "Bascov",
    "Poarta Alba",
    "Zorleni",
    "Tinca",
    "Ghiroda",
    "Ghelinta",
    "Cleja",
    "Sacalaz",
    "Suraia",
    "Doicesti",
    "Ivesti",
    "Cosereni",
    "Patrauti",
    "Chiajna",
    "Periam",
    "Cristian",
    "Viisoara",
    "Crasna",
    "Belcesti",
    "Cioranii de Jos",
    "Azuga",
    "Islaz",
    "Moara Vlasiei",
    "Manasia",
    "Voitinel",
    "Turt",
    "Zetea",
    "Independenta",
    "Deleni",
    "Aninoasa",
    "Piscu",
    "Santandrei",
    "Floresti",
    "Feldioara",
    "Ciumani",
    "Ocnita",
    "Valea Mare-Podgoria",
    "Ieud",
    "Slobozia",
    "Niculitel",
    "Floresti",
    "Victoria",
    "Bucecea",
    "Munteni",
    "Galicea Mare",
    "Razvad",
    "Ciocanesti",
    "Ceratu",
    "Odoreu",
    "Cenad",
    "Slanic-Moldova",
    "Romanesti",
    "Teliu",
    "Budila",
    "Todiresti",
    "Lunguletu",
    "Baile Olanesti",
    "Perisoru",
    "Ungheni-Prut",
    "Mihai Viteazu",
    "Pleasa",
    "Tulucesti",
    "Fratautii Noi",
    "Padina",
    "Corni",
    "Magureni",
    "Maldaeni",
    "Baia de Aries",
    "Vinga",
    "Jijila",
    "Fundeni",
    "Ciupercenii Noi",
    "Tileagd",
    "Podari",
    "Bradu",
    "Zanesti",
    "Zagon",
    "Ditesti",
    "Bragadiru",
    "Garbovi",
    "Band",
    "Ogrezeni",
    "Calarasi",
    "Hotarele",
    "Faraoani",
    "Buzescu",
    "Ciorogarla",
    "Miercurea Sibiului",
    "Bogdanesti",
    "Varasti",
    "Telega",
    "Oglinzi",
    "Plenita",
    "Podoleni",
    "Daneti",
    "Vidra",
    "Bucesti",
    "Maneciu-Pamanteni",
    "Varias",
    "Bolintin Deal",
    "Focuri",
    "Buruenesti",
    "Tesila",
    "Unirea",
    "Radovanu",
    "Barsana",
    "Palanca",
    "Sfantu Ilie",
    "Galbinasi",
    "Dumbravita",
    "Isalnita",
    "Ipotesti",
    "Dor Marunt",
    "Jina",
    "Mironeasa",
    "Tarlungeni",
    "Cartojani",
    "Lapus",
    "Barca",
    "Vorniceni",
    "Turia",
    "Sanmartin",
    "Gheraesti",
    "Bucsani",
    "Semlac",
    "Cristian",
    "Darmanesti",
    "Dornesti",
    "Sulina",
    "Baleni Romani",
    "Bechet",
    "Dudestii Vechi",
    "Blejoiu",
    "Chiscani",
    "Viziru",
    "Barcea",
    "Botiz",
    "Fartanesti",
    "Gura Raului",
    "Vulcan",
    "Brastavatu",
    "Leu",
    "Parcovaci",
    "Ion Luca Caragiale",
    "Matasari",
    "Cristesti",
    "Telciu",
    "Nicolae Balcescu",
    "Faurei",
    "Vasilati",
    "Cuza Voda",
    "Nanov",
    "Jebel",
    "Bilca",
    "Manastirea",
    "Tichilesti",
    "Halmeu",
    "Bistrita Bargaului",
    "Ocna Sibiului",
    "Rusanesti",
    "Letcani",
    "Carpinis",
    "Prundu",
    "Albesti",
    "Perieni",
    "Motca",
    "Ostroveni",
    "Sita Buzaului",
    "Sacel",
    "Biharea",
    "Ramnicelu",
    "Golesti",
    "Vama",
    "Draganesti-Vlasca",
    "Comisani",
    "Contesti",
    "Maglavit",
    "Poiana",
    "Cornu de Jos",
    "Praid",
    "Margineni",
    "Santana de Mures",
    "Vadastrita",
    "Luncavita",
    "Adjudeni",
    "Munteni Buzau",
    "Carcea",
    "Unirea",
    "Gherta Mica",
    "Lueta",
    "Chiselet",
    "Piatra",
    "Gura Sutii",
    "Comlosu Mare",
    "Varbilau",
    "Garla-Mare",
    "Bezdead",
    "Manastirea Casin",
    "Pildesti",
    "Vultureni",
    "Rastu",
    "Cerneti",
    "Frumoasa",
    "Dobrotesti",
    "Zemes",
    "Zabala",
    "Baneasa",
    "Ghirdoveni",
    "Poiana Marului",
    "Iancu Jianu",
    "Ocnele Mari",
    "Adunatii-Copaceni",
    "Bordusani",
    "Magurele",
    "Capatanesti",
    "Berca",
    "Cernat",
    "Biled",
    "Cazanesti",
    "Movileni",
    "Traian",
    "Ilva Mica",
    "Lazarea",
    "Geoseni",
    "Smeeni",
    "Ojdula",
    "Cilieni",
    "Topraisar",
    "Cosoveni",
    "Baneasa",
    "Valea Dragului",
    "Maracineni",
    "Barcanesti",
    "Rona de Sus",
    "Lovrin",
    "Ploestiori",
    "Nusfalau",
    "Dragomiresti",
    "Orlat",
    "Domnesti",
    "Malu Spart",
    "Valea Stanciului",
    "Baia de Fier",
    "Stalpu",
    "Aghiresu-Fabricei",
    "Cervenia",
    "Marginenii de Jos",
    "Turcoaia",
    "Osorheiu",
    "Dudestii Noi",
    "Vacaresti",
    "Branesti",
    "Certeze",
    "Apata",
    "Traian",
    "Iaslovat",
    "Rebra",
    "Gura Ocnitei",
    "Candesti",
    "Tomnatic",
    "Cernica",
    "Brebu Manastirei",
    "Poroschia",
    "Copacenii de Jos",
    "Valea Seaca",
    "Dersca",
    "Dridu",
    "Tanganu",
    "Geamana",
    "Vedea",
    "Patulele",
    "Poiana Campina",
    "Banesti",
    "Jilavele",
    "Vadu Pasii",
    "Peciu Nou",
    "Lunca Ilvei",
    "Bicaz Chei",
    "Cogealac",
    "Lipovu",
    "Bertea",
    "Nicolae Balcescu",
    "Mitocu Dragomirnei",
    "Sanandrei",
    "Tamaseni",
    "Baraganul",
    "Osica de Sus",
    "Jirlau",
    "Valea Adanca",
    "Slobozia-Conache",
    "Micula",
    "Gologanu",
    "Halchiu",
    "Liebling",
    "Sanislau",
    "Starchiojd",
    "Hantesti",
    "Capleni",
    "Rebrisoara",
    "Darasti-Ilfov",
    "Iazu",
    "Schela",
    "Voinesti",
    "Salard",
    "Tatarani",
    "Comani",
    "Goranu",
    "Parscov",
    "Bughea de Sus",
    "Maracineni",
    "Mogosesti",
    "Limanu",
    "Picior de Munte",
    "Branistea",
    "Buciumeni",
    "Mihail Kogalniceanu",
    "Dumbrava Rosie",
    "Pietrosani",
    "Cazasu",
    "Satchinez",
    "Sintesti",
    "Pecineaga",
    "Fantanele",
    "Vadu Crisului",
    "Visina",
    "Letea Veche",
    "Crevedia",
    "Malini",
    "Suseni",
    "Frumusani",
    "Beresti-Targ",
    "Orbeasca de Sus",
    "Gheraseni",
    "Ciobanu",
    "Sapanta",
    "Tulghes",
    "Raucesti",
    "Visina",
    "Branceni",
    "Valeni",
    "Frumusita",
    "Jaristea",
    "Balaceanca",
    "Sabareni",
    "Bughea de Jos",
    "Satu Nou",
    "Movila Miresei",
    "Saveni",
    "Crampoia",
    "Becicherecu Mic",
    "Doua Mai",
    "Rotunda",
    "Remetea Chioarului",
    "Mosnita Noua",
    "Ulmu",
    "Ciolpani",
    "Curtuiuseni",
    "Havarna",
    "Ostra",
    "Racaciuni",
    "Trifesti",
    "Rozavlea",
    "Vladeni",
    "Stulpicani",
    "Sanmihaiu Roman",
    "Ograda",
    "Zizin",
    "Broscauti",
    "Amarastii de Jos",
    "Salcia",
    "Lunca Calnicului",
    "Catelu",
    "Teregova",
    "Baia",
    "Periceiu",
    "Ruseni",
    "Valea Marului",
    "Potlogi",
    "Vama",
    "Palanca",
    "Sarichioi",
    "Daia Romana",
    "Scanteia",
    "Criscior",
    "Adancata",
    "Racsa",
    "Goicea",
    "Troianul",
    "Persinari",
    "Stancea",
    "Floresti",
    "Sagna",
    "Marsa",
    "Salva",
    "Roma",
    "Holboca",
    "Chisoda",
    "Sanzieni",
    "Siculeni",
    "Aricestii-Rahtivani",
    "Brebeni",
    "Botiza",
    "Cretesti",
    "Lechinta",
    "Felnac",
    "Mavrodin",
    "Glodeni",
    "Lita",
    "Petelea",
    "Rasca",
    "Catamaresti-Deal",
    "Hodac",
    "Dragomiresti-Deal",
    "Lazuri",
    "Vladestii de Sus",
    "Calinesti-Oas",
    "Casin",
    "Vetrisoaia",
    "Fratautii Vechi",
    "Corunca",
    "Tarsolt",
    "Stoicanesti",
    "Brahasestii de Sus",
    "Silistea Noua",
    "Ploscuteni",
    "Saravale",
    "Scurtesti",
    "Copalau",
    "Popricani",
    "Brebu Megiesesc",
    "Ghiaba",
    "Cosoba",
    "Falcoiu",
    "Alunis",
    "Trestieni",
    "Bixad",
    "Ineu",
    "Maruntei",
    "Pojorata",
    "Popeni"
]
