///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, November 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import packageJson from '../../package.json'

export const debugMsgLog = (message, id) => {
    if (packageJson.debug) {
        if (id === undefined) console.log(message)
        else console.trace(`ID: ${id}  -  Message: ${message}`)
        return true
    }
    return false
}