///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, December 2023
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import WeclomePage from "../WeclomePage";
import Tiriac from "./Tiriac";

const HelperMenu = () => {
  const { currentUser, checkHelperAuthorisation, loadingAction } =
    useAuth();

  const [authorised, setAuthorised] = useState(false);
  const [page, setPage] = useState("");

  // Check if authorised
  useEffect(() => {
    if (!currentUser) return;

    checkHelperAuthorisation().then((res) => setAuthorised(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      {loadingAction && !authorised ? (
        <h1>?</h1>
      ) : !authorised ? (
        <WeclomePage />
      ) : page === "" ? (
        <div className="app-wrapper">
          <h1 className="main-title">Programe ajutătoare</h1>
          <div className="app-section">
            <div className="app-row">
              <div
                onClick={() => setPage("01")}
                className="button-secondary w60"
              >
                01
              </div>{" "}
              {/* TIRIAC */}
            </div>
          </div>
        </div>
      ) : page === "01" ? (
        <Tiriac goBack={() => setPage("")} />
      ) : (
        <></>
      )}
    </>
  );
};

export default HelperMenu;
