///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const VERSION = 2024;

export const CoduriVamale = new Set([
'01012100',
    '01012910',
    '01012990',
    '01013000',
    '01019000',
    '01022110',
    '01022130',
    '01022190',
    '01022905',
    '01022910',
    '01022921',
    '01022929',
    '01022941',
    '01022949',
    '01022951',
    '01022959',
    '01022961',
    '01022969',
    '01022991',
    '01022999',
    '01023100',
    '01023910',
    '01023990',
    '01029020',
    '01029091',
    '01029099',
    '01031000',
    '01039110',
    '01039190',
    '01039211',
    '01039219',
    '01039290',
    '01041010',
    '01041030',
    '01041080',
    '01042010',
    '01042090',
    '01051111',
    '01051119',
    '01051191',
    '01051199',
    '01051200',
    '01051300',
    '01051400',
    '01051500',
    '01059400',
    '01059910',
    '01059920',
    '01059930',
    '01059950',
    '01061100',
    '01061200',
    '01061300',
    '01061410',
    '01061490',
    '01061900',
    '01062000',
    '01063100',
    '01063200',
    '01063300',
    '01063910',
    '01063980',
    '01064100',
    '01064900',
    '01069000',
    '02011000',
    '02012020',
    '02012030',
    '02012050',
    '02012090',
    '02013000',
    '02021000',
    '02022010',
    '02022030',
    '02022050',
    '02022090',
    '02023010',
    '02023050',
    '02023090',
    '02031110',
    '02031190',
    '02031211',
    '02031219',
    '02031290',
    '02031911',
    '02031913',
    '02031915',
    '02031955',
    '02031959',
    '02031990',
    '02032110',
    '02032190',
    '02032211',
    '02032219',
    '02032290',
    '02032911',
    '02032913',
    '02032915',
    '02032955',
    '02032959',
    '02032990',
    '02041000',
    '02042100',
    '02042210',
    '02042230',
    '02042250',
    '02042290',
    '02042300',
    '02043000',
    '02044100',
    '02044210',
    '02044230',
    '02044250',
    '02044290',
    '02044310',
    '02044390',
    '02045011',
    '02045013',
    '02045015',
    '02045019',
    '02045031',
    '02045039',
    '02045051',
    '02045053',
    '02045055',
    '02045059',
    '02045071',
    '02045079',
    '02050020',
    '02050080',
    '02061010',
    '02061095',
    '02061098',
    '02062100',
    '02062200',
    '02062910',
    '02062991',
    '02062999',
    '02063000',
    '02064100',
    '02064900',
    '02068010',
    '02068091',
    '02068099',
    '02069010',
    '02069091',
    '02069099',
    '02071110',
    '02071130',
    '02071190',
    '02071210',
    '02071290',
    '02071310',
    '02071320',
    '02071330',
    '02071340',
    '02071350',
    '02071360',
    '02071370',
    '02071391',
    '02071399',
    '02071410',
    '02071420',
    '02071430',
    '02071440',
    '02071450',
    '02071460',
    '02071470',
    '02071491',
    '02071499',
    '02072410',
    '02072490',
    '02072510',
    '02072590',
    '02072610',
    '02072620',
    '02072630',
    '02072640',
    '02072650',
    '02072660',
    '02072670',
    '02072680',
    '02072691',
    '02072699',
    '02072710',
    '02072720',
    '02072730',
    '02072740',
    '02072750',
    '02072760',
    '02072770',
    '02072780',
    '02072791',
    '02072799',
    '02074120',
    '02074130',
    '02074180',
    '02074230',
    '02074280',
    '02074300',
    '02074410',
    '02074421',
    '02074431',
    '02074441',
    '02074451',
    '02074461',
    '02074471',
    '02074481',
    '02074491',
    '02074499',
    '02074510',
    '02074521',
    '02074531',
    '02074541',
    '02074551',
    '02074561',
    '02074571',
    '02074581',
    '02074593',
    '02074595',
    '02074599',
    '02075110',
    '02075190',
    '02075210',
    '02075290',
    '02075300',
    '02075410',
    '02075421',
    '02075431',
    '02075441',
    '02075451',
    '02075461',
    '02075471',
    '02075481',
    '02075491',
    '02075499',
    '02075510',
    '02075521',
    '02075531',
    '02075541',
    '02075551',
    '02075561',
    '02075571',
    '02075581',
    '02075593',
    '02075595',
    '02075599',
    '02076005',
    '02076010',
    '02076021',
    '02076031',
    '02076041',
    '02076051',
    '02076061',
    '02076081',
    '02076091',
    '02076099',
    '02081010',
    '02081090',
    '02083000',
    '02084010',
    '02084020',
    '02084080',
    '02085000',
    '02086000',
    '02089010',
    '02089030',
    '02089060',
    '02089070',
    '02089098',
    '02091011',
    '02091019',
    '02091090',
    '02099000',
    '02101111',
    '02101119',
    '02101131',
    '02101139',
    '02101190',
    '02101211',
    '02101219',
    '02101290',
    '02101910',
    '02101920',
    '02101930',
    '02101940',
    '02101950',
    '02101960',
    '02101970',
    '02101981',
    '02101989',
    '02101990',
    '02102010',
    '02102090',
    '02109100',
    '02109210',
    '02109291',
    '02109292',
    '02109299',
    '02109300',
    '02109910',
    '02109921',
    '02109929',
    '02109931',
    '02109939',
    '02109941',
    '02109949',
    '02109951',
    '02109959',
    '02109971',
    '02109979',
    '02109985',
    '02109990',
    '03011100',
    '03011900',
    '03019110',
    '03019190',
    '03019210',
    '03019230',
    '03019290',
    '03019300',
    '03019410',
    '03019490',
    '03019500',
    '03019911',
    '03019917',
    '03019985',
    '03021110',
    '03021120',
    '03021180',
    '03021300',
    '03021400',
    '03021900',
    '03022110',
    '03022130',
    '03022190',
    '03022200',
    '03022300',
    '03022400',
    '03022910',
    '03022980',
    '03023110',
    '03023190',
    '03023210',
    '03023290',
    '03023310',
    '03023390',
    '03023410',
    '03023490',
    '03023511',
    '03023519',
    '03023591',
    '03023599',
    '03023610',
    '03023690',
    '03023920',
    '03023980',
    '03024100',
    '03024200',
    '03024310',
    '03024330',
    '03024390',
    '03024400',
    '03024510',
    '03024530',
    '03024590',
    '03024600',
    '03024700',
    '03024911',
    '03024919',
    '03024990',
    '03025110',
    '03025190',
    '03025200',
    '03025300',
    '03025411',
    '03025415',
    '03025419',
    '03025490',
    '03025500',
    '03025600',
    '03025910',
    '03025920',
    '03025930',
    '03025940',
    '03025990',
    '03027100',
    '03027200',
    '03027300',
    '03027400',
    '03027900',
    '03028115',
    '03028130',
    '03028140',
    '03028180',
    '03028200',
    '03028300',
    '03028410',
    '03028490',
    '03028510',
    '03028530',
    '03028590',
    '03028910',
    '03028921',
    '03028929',
    '03028931',
    '03028939',
    '03028940',
    '03028950',
    '03028960',
    '03028990',
    '03029100',
    '03029200',
    '03029900',
    '03031100',
    '03031200',
    '03031300',
    '03031410',
    '03031420',
    '03031490',
    '03031900',
    '03032300',
    '03032400',
    '03032500',
    '03032600',
    '03032900',
    '03033110',
    '03033130',
    '03033190',
    '03033200',
    '03033300',
    '03033400',
    '03033910',
    '03033930',
    '03033950',
    '03033985',
    '03034110',
    '03034190',
    '03034220',
    '03034290',
    '03034310',
    '03034390',
    '03034410',
    '03034490',
    '03034512',
    '03034518',
    '03034591',
    '03034599',
    '03034610',
    '03034690',
    '03034920',
    '03034985',
    '03035100',
    '03035310',
    '03035330',
    '03035390',
    '03035410',
    '03035490',
    '03035510',
    '03035530',
    '03035590',
    '03035600',
    '03035700',
    '03035910',
    '03035921',
    '03035929',
    '03035990',
    '03036310',
    '03036330',
    '03036390',
    '03036400',
    '03036500',
    '03036611',
    '03036612',
    '03036613',
    '03036619',
    '03036690',
    '03036700',
    '03036810',
    '03036890',
    '03036910',
    '03036930',
    '03036950',
    '03036970',
    '03036980',
    '03036990',
    '03038115',
    '03038130',
    '03038140',
    '03038190',
    '03038200',
    '03038300',
    '03038410',
    '03038490',
    '03038910',
    '03038921',
    '03038929',
    '03038931',
    '03038939',
    '03038940',
    '03038950',
    '03038955',
    '03038960',
    '03038965',
    '03038970',
    '03038990',
    '03039110',
    '03039190',
    '03039200',
    '03039900',
    '03043100',
    '03043200',
    '03043300',
    '03043900',
    '03044100',
    '03044210',
    '03044250',
    '03044290',
    '03044300',
    '03044410',
    '03044430',
    '03044490',
    '03044500',
    '03044600',
    '03044710',
    '03044720',
    '03044730',
    '03044790',
    '03044800',
    '03044910',
    '03044950',
    '03044990',
    '03045100',
    '03045200',
    '03045300',
    '03045400',
    '03045500',
    '03045610',
    '03045620',
    '03045630',
    '03045690',
    '03045700',
    '03045910',
    '03045950',
    '03045990',
    '03046100',
    '03046200',
    '03046300',
    '03046900',
    '03047110',
    '03047190',
    '03047200',
    '03047300',
    '03047411',
    '03047415',
    '03047419',
    '03047490',
    '03047500',
    '03047910',
    '03047930',
    '03047950',
    '03047980',
    '03047990',
    '03048100',
    '03048210',
    '03048250',
    '03048290',
    '03048310',
    '03048330',
    '03048350',
    '03048390',
    '03048400',
    '03048500',
    '03048600',
    '03048700',
    '03048811',
    '03048815',
    '03048818',
    '03048819',
    '03048890',
    '03048910',
    '03048921',
    '03048929',
    '03048930',
    '03048941',
    '03048949',
    '03048960',
    '03048990',
    '03049100',
    '03049200',
    '03049310',
    '03049390',
    '03049410',
    '03049490',
    '03049510',
    '03049521',
    '03049525',
    '03049529',
    '03049530',
    '03049540',
    '03049550',
    '03049560',
    '03049590',
    '03049610',
    '03049620',
    '03049630',
    '03049690',
    '03049700',
    '03049910',
    '03049921',
    '03049923',
    '03049929',
    '03049955',
    '03049961',
    '03049965',
    '03049999',
    '03052000',
    '03053100',
    '03053211',
    '03053219',
    '03053290',
    '03053910',
    '03053950',
    '03053990',
    '03054100',
    '03054200',
    '03054300',
    '03054410',
    '03054490',
    '03054910',
    '03054920',
    '03054930',
    '03054980',
    '03055110',
    '03055190',
    '03055200',
    '03055310',
    '03055390',
    '03055430',
    '03055450',
    '03055490',
    '03055970',
    '03055985',
    '03056100',
    '03056200',
    '03056300',
    '03056400',
    '03056910',
    '03056930',
    '03056950',
    '03056980',
    '03057100',
    '03057200',
    '03057900',
    '03061110',
    '03061190',
    '03061210',
    '03061290',
    '03061410',
    '03061430',
    '03061490',
    '03061500',
    '03061691',
    '03061699',
    '03061791',
    '03061792',
    '03061793',
    '03061794',
    '03061799',
    '03061910',
    '03061990',
    '03063100',
    '03063210',
    '03063291',
    '03063299',
    '03063310',
    '03063390',
    '03063400',
    '03063510',
    '03063550',
    '03063590',
    '03063610',
    '03063650',
    '03063690',
    '03063910',
    '03063990',
    '03069100',
    '03069210',
    '03069290',
    '03069310',
    '03069390',
    '03069400',
    '03069511',
    '03069519',
    '03069520',
    '03069530',
    '03069540',
    '03069590',
    '03069910',
    '03069990',
    '03071110',
    '03071190',
    '03071200',
    '03071900',
    '03072110',
    '03072190',
    '03072210',
    '03072290',
    '03072295',
    '03072910',
    '03072990',
    '03073110',
    '03073190',
    '03073210',
    '03073290',
    '03073920',
    '03073980',
    '03074210',
    '03074220',
    '03074230',
    '03074240',
    '03074290',
    '03074321',
    '03074325',
    '03074329',
    '03074331',
    '03074333',
    '03074335',
    '03074338',
    '03074391',
    '03074392',
    '03074395',
    '03074399',
    '03074920',
    '03074940',
    '03074950',
    '03074960',
    '03074980',
    '03075100',
    '03075200',
    '03075900',
    '03076000',
    '03077100',
    '03077210',
    '03077290',
    '03077900',
    '03078100',
    '03078200',
    '03078300',
    '03078400',
    '03078700',
    '03078800',
    '03079100',
    '03079200',
    '03079900',
    '03081100',
    '03081200',
    '03081900',
    '03082100',
    '03082200',
    '03082900',
    '03083050',
    '03083080',
    '03089010',
    '03089050',
    '03089090',
    '03091000',
    '03099000',
    '04011010',
    '04011090',
    '04012011',
    '04012019',
    '04012091',
    '04012099',
    '04014010',
    '04014090',
    '04015011',
    '04015019',
    '04015031',
    '04015039',
    '04015091',
    '04015099',
    '04021011',
    '04021019',
    '04021091',
    '04021099',
    '04022111',
    '04022118',
    '04022191',
    '04022199',
    '04022911',
    '04022915',
    '04022919',
    '04022991',
    '04022999',
    '04029110',
    '04029130',
    '04029151',
    '04029159',
    '04029191',
    '04029199',
    '04029910',
    '04029931',
    '04029939',
    '04029991',
    '04029999',
    '04032011',
    '04032013',
    '04032019',
    '04032031',
    '04032033',
    '04032039',
    '04032041',
    '04032049',
    '04032051',
    '04032053',
    '04032059',
    '04032091',
    '04032093',
    '04032099',
    '04039011',
    '04039013',
    '04039019',
    '04039031',
    '04039033',
    '04039039',
    '04039051',
    '04039053',
    '04039059',
    '04039061',
    '04039063',
    '04039069',
    '04039071',
    '04039073',
    '04039079',
    '04039091',
    '04039093',
    '04039099',
    '04041002',
    '04041004',
    '04041006',
    '04041012',
    '04041014',
    '04041016',
    '04041026',
    '04041028',
    '04041032',
    '04041034',
    '04041036',
    '04041038',
    '04041048',
    '04041052',
    '04041054',
    '04041056',
    '04041058',
    '04041062',
    '04041072',
    '04041074',
    '04041076',
    '04041078',
    '04041082',
    '04041084',
    '04049021',
    '04049023',
    '04049029',
    '04049081',
    '04049083',
    '04049089',
    '04051011',
    '04051019',
    '04051030',
    '04051050',
    '04051090',
    '04052010',
    '04052030',
    '04052090',
    '04059010',
    '04059090',
    '04061030',
    '04061050',
    '04061080',
    '04062000',
    '04063010',
    '04063031',
    '04063039',
    '04063090',
    '04064010',
    '04064050',
    '04064090',
    '04069001',
    '04069013',
    '04069015',
    '04069017',
    '04069018',
    '04069021',
    '04069023',
    '04069025',
    '04069029',
    '04069032',
    '04069035',
    '04069037',
    '04069039',
    '04069050',
    '04069061',
    '04069063',
    '04069069',
    '04069073',
    '04069074',
    '04069075',
    '04069076',
    '04069078',
    '04069079',
    '04069081',
    '04069082',
    '04069084',
    '04069085',
    '04069086',
    '04069089',
    '04069092',
    '04069093',
    '04069099',
    '04071100',
    '04071911',
    '04071919',
    '04071990',
    '04072100',
    '04072910',
    '04072990',
    '04079010',
    '04079090',
    '04081120',
    '04081180',
    '04081920',
    '04081981',
    '04081989',
    '04089120',
    '04089180',
    '04089920',
    '04089980',
    '04090000',
    '04101010',
    '04101091',
    '04101099',
    '04109000',
    '05010000',
    '05021000',
    '05029000',
    '05040000',
    '05051010',
    '05051090',
    '05059000',
    '05061000',
    '05069000',
    '05071000',
    '05079000',
    '05080010',
    '05080090',
    '05100000',
    '05111000',
    '05119110',
    '05119190',
    '05119910',
    '05119931',
    '05119939',
    '05119985',
    '06011010',
    '06011020',
    '06011030',
    '06011040',
    '06011090',
    '06012010',
    '06012030',
    '06012090',
    '06021010',
    '06021090',
    '06022010',
    '06022020',
    '06022030',
    '06022080',
    '06023000',
    '06024000',
    '06029010',
    '06029020',
    '06029030',
    '06029041',
    '06029045',
    '06029046',
    '06029047',
    '06029048',
    '06029050',
    '06029070',
    '06029091',
    '06029099',
    '06031100',
    '06031200',
    '06031300',
    '06031400',
    '06031500',
    '06031910',
    '06031920',
    '06031970',
    '06039000',
    '06042011',
    '06042019',
    '06042020',
    '06042040',
    '06042090',
    '06049011',
    '06049019',
    '06049091',
    '06049099',
    '07011000',
    '07019010',
    '07019050',
    '07019090',
    '07020000',
    '07031011',
    '07031019',
    '07031090',
    '07032000',
    '07039000',
    '07041010',
    '07041090',
    '07042000',
    '07049010',
    '07049090',
    '07051100',
    '07051900',
    '07052100',
    '07052900',
    '07061000',
    '07069010',
    '07069030',
    '07069090',
    '07070005',
    '07070090',
    '07081000',
    '07082000',
    '07089000',
    '07092000',
    '07093000',
    '07094000',
    '07095100',
    '07095200',
    '07095300',
    '07095400',
    '07095500',
    '07095600',
    '07095900',
    '07096010',
    '07096091',
    '07096095',
    '07096099',
    '07097000',
    '07099100',
    '07099210',
    '07099290',
    '07099310',
    '07099390',
    '07099910',
    '07099920',
    '07099940',
    '07099950',
    '07099960',
    '07099990',
    '07101000',
    '07102100',
    '07102200',
    '07102900',
    '07103000',
    '07104000',
    '07108010',
    '07108051',
    '07108059',
    '07108061',
    '07108069',
    '07108070',
    '07108080',
    '07108085',
    '07108095',
    '07109000',
    '07112010',
    '07112090',
    '07114000',
    '07115100',
    '07115900',
    '07119010',
    '07119030',
    '07119050',
    '07119070',
    '07119080',
    '07119090',
    '07122000',
    '07123100',
    '07123200',
    '07123300',
    '07123400',
    '07123900',
    '07129005',
    '07129011',
    '07129019',
    '07129030',
    '07129050',
    '07129090',
    '07131010',
    '07131090',
    '07132000',
    '07133100',
    '07133200',
    '07133310',
    '07133390',
    '07133400',
    '07133500',
    '07133900',
    '07134000',
    '07135000',
    '07136000',
    '07139000',
    '07141000',
    '07142010',
    '07142090',
    '07143000',
    '07144000',
    '07145000',
    '07149020',
    '07149090',
    '08011100',
    '08011200',
    '08011900',
    '08012100',
    '08012200',
    '08013100',
    '08013200',
    '08021110',
    '08021190',
    '08021210',
    '08021290',
    '08022100',
    '08022200',
    '08023100',
    '08023200',
    '08024100',
    '08024200',
    '08025100',
    '08025200',
    '08026100',
    '08026200',
    '08027000',
    '08028000',
    '08029100',
    '08029200',
    '08029910',
    '08029990',
    '08031010',
    '08031090',
    '08039011',
    '08039019',
    '08039090',
    '08041000',
    '08042010',
    '08042090',
    '08043000',
    '08044000',
    '08045000',
    '08051022',
    '08051024',
    '08051028',
    '08051080',
    '08052110',
    '08052190',
    '08052200',
    '08052900',
    '08054000',
    '08055010',
    '08055090',
    '08059000',
    '08061010',
    '08061090',
    '08062010',
    '08062030',
    '08062090',
    '08071100',
    '08071900',
    '08072000',
    '08081010',
    '08081080',
    '08083010',
    '08083090',
    '08084000',
    '08091000',
    '08092100',
    '08092900',
    '08093020',
    '08093030',
    '08093080',
    '08094005',
    '08094090',
    '08101000',
    '08102010',
    '08102090',
    '08103010',
    '08103030',
    '08103090',
    '08104010',
    '08104030',
    '08104050',
    '08104090',
    '08105000',
    '08106000',
    '08107000',
    '08109020',
    '08109075',
    '08111011',
    '08111019',
    '08111090',
    '08112011',
    '08112019',
    '08112031',
    '08112039',
    '08112051',
    '08112059',
    '08112090',
    '08119011',
    '08119019',
    '08119031',
    '08119039',
    '08119050',
    '08119070',
    '08119075',
    '08119080',
    '08119085',
    '08119095',
    '08121000',
    '08129025',
    '08129030',
    '08129040',
    '08129070',
    '08129098',
    '08131000',
    '08132000',
    '08133000',
    '08134010',
    '08134030',
    '08134050',
    '08134065',
    '08134095',
    '08135012',
    '08135015',
    '08135019',
    '08135031',
    '08135039',
    '08135091',
    '08135099',
    '08140000',
    '09011100',
    '09011200',
    '09012100',
    '09012200',
    '09019010',
    '09019090',
    '09021000',
    '09022000',
    '09023000',
    '09024000',
    '09030000',
    '09041100',
    '09041200',
    '09042110',
    '09042190',
    '09042200',
    '09051000',
    '09052000',
    '09061100',
    '09061900',
    '09062000',
    '09071000',
    '09072000',
    '09081100',
    '09081200',
    '09082100',
    '09082200',
    '09083100',
    '09083200',
    '09092100',
    '09092200',
    '09093100',
    '09093200',
    '09096100',
    '09096200',
    '09101100',
    '09101200',
    '09102010',
    '09102090',
    '09103000',
    '09109105',
    '09109110',
    '09109190',
    '09109910',
    '09109931',
    '09109933',
    '09109939',
    '09109950',
    '09109991',
    '09109999',
    '10011100',
    '10011900',
    '10019110',
    '10019120',
    '10019190',
    '10019900',
    '10021000',
    '10029000',
    '10031000',
    '10039000',
    '10041000',
    '10049000',
    '10051013',
    '10051015',
    '10051018',
    '10051090',
    '10059000',
    '10061010',
    '10061030',
    '10061050',
    '10061071',
    '10061079',
    '10061090',
    '10062011',
    '10062013',
    '10062015',
    '10062017',
    '10062019',
    '10062092',
    '10062094',
    '10062096',
    '10062098',
    '10062099',
    '10063021',
    '10063023',
    '10063025',
    '10063027',
    '10063029',
    '10063042',
    '10063044',
    '10063046',
    '10063048',
    '10063049',
    '10063061',
    '10063063',
    '10063065',
    '10063067',
    '10063069',
    '10063092',
    '10063094',
    '10063096',
    '10063098',
    '10063099',
    '10064000',
    '10071010',
    '10071090',
    '10079000',
    '10081000',
    '10082100',
    '10082900',
    '10083000',
    '10084000',
    '10085000',
    '10086000',
    '10089000',
    '11010011',
    '11010015',
    '11010090',
    '11022010',
    '11022090',
    '11029010',
    '11029030',
    '11029050',
    '11029070',
    '11029090',
    '11031110',
    '11031190',
    '11031310',
    '11031390',
    '11031920',
    '11031940',
    '11031950',
    '11031990',
    '11032025',
    '11032030',
    '11032040',
    '11032050',
    '11032060',
    '11032090',
    '11041210',
    '11041290',
    '11041910',
    '11041930',
    '11041950',
    '11041961',
    '11041969',
    '11041991',
    '11041999',
    '11042240',
    '11042250',
    '11042295',
    '11042340',
    '11042398',
    '11042904',
    '11042905',
    '11042908',
    '11042917',
    '11042930',
    '11042951',
    '11042955',
    '11042959',
    '11042981',
    '11042985',
    '11042989',
    '11043010',
    '11043090',
    '11051000',
    '11052000',
    '11061000',
    '11062010',
    '11062090',
    '11063010',
    '11063090',
    '11071011',
    '11071019',
    '11071091',
    '11071099',
    '11072000',
    '11081100',
    '11081200',
    '11081300',
    '11081400',
    '11081910',
    '11081990',
    '11082000',
    '11090000',
    '12011000',
    '12019000',
    '12023000',
    '12024100',
    '12024200',
    '12030000',
    '12040010',
    '12040090',
    '12051010',
    '12051090',
    '12059000',
    '12060010',
    '12060091',
    '12060099',
    '12071000',
    '12072100',
    '12072900',
    '12073000',
    '12074010',
    '12074090',
    '12075010',
    '12075090',
    '12076000',
    '12077000',
    '12079110',
    '12079190',
    '12079920',
    '12079991',
    '12079996',
    '12081000',
    '12089000',
    '12091000',
    '12092100',
    '12092210',
    '12092280',
    '12092311',
    '12092315',
    '12092380',
    '12092400',
    '12092510',
    '12092590',
    '12092945',
    '12092950',
    '12092960',
    '12092980',
    '12093000',
    '12099130',
    '12099180',
    '12099910',
    '12099991',
    '12099999',
    '12101000',
    '12102010',
    '12102090',
    '12112000',
    '12113000',
    '12114000',
    '12115000',
    '12116000',
    '12119030',
    '12119086',
    '12122100',
    '12122900',
    '12129120',
    '12129180',
    '12129200',
    '12129300',
    '12129400',
    '12129941',
    '12129949',
    '12129995',
    '12130000',
    '12141000',
    '12149010',
    '12149090',
    '13012000',
    '13019000',
    '13021100',
    '13021200',
    '13021300',
    '13021400',
    '13021905',
    '13021970',
    '13022010',
    '13022090',
    '13023100',
    '13023210',
    '13023290',
    '13023900',
    '14011000',
    '14012000',
    '14019000',
    '14042000',
    '14049000',
    '15011010',
    '15011090',
    '15012010',
    '15012090',
    '15019000',
    '15021010',
    '15021090',
    '15029010',
    '15029090',
    '15030011',
    '15030019',
    '15030030',
    '15030090',
    '15041010',
    '15041091',
    '15041099',
    '15042010',
    '15042090',
    '15043010',
    '15043090',
    '15050010',
    '15050090',
    '15060000',
    '15071010',
    '15071090',
    '15079010',
    '15079090',
    '15081010',
    '15081090',
    '15089010',
    '15089090',
    '15092000',
    '15093000',
    '15094000',
    '15099000',
    '15101000',
    '15109000',
    '15111010',
    '15111090',
    '15119011',
    '15119019',
    '15119091',
    '15119099',
    '15121110',
    '15121191',
    '15121199',
    '15121910',
    '15121990',
    '15122110',
    '15122190',
    '15122910',
    '15122990',
    '15131110',
    '15131191',
    '15131199',
    '15131911',
    '15131919',
    '15131930',
    '15131991',
    '15131999',
    '15132110',
    '15132130',
    '15132190',
    '15132911',
    '15132919',
    '15132930',
    '15132950',
    '15132990',
    '15141110',
    '15141190',
    '15141910',
    '15141990',
    '15149110',
    '15149190',
    '15149910',
    '15149990',
    '15151100',
    '15151910',
    '15151990',
    '15152110',
    '15152190',
    '15152910',
    '15152990',
    '15153010',
    '15153090',
    '15155011',
    '15155019',
    '15155091',
    '15155099',
    '15156011',
    '15156051',
    '15156059',
    '15156060',
    '15156091',
    '15156099',
    '15159011',
    '15159021',
    '15159029',
    '15159031',
    '15159039',
    '15159040',
    '15159051',
    '15159059',
    '15159060',
    '15159091',
    '15159099',
    '15161010',
    '15161090',
    '15162010',
    '15162091',
    '15162095',
    '15162096',
    '15162098',
    '15163091',
    '15163098',
    '15171010',
    '15171090',
    '15179010',
    '15179091',
    '15179093',
    '15179099',
    '15180010',
    '15180031',
    '15180039',
    '15180091',
    '15180095',
    '15180099',
    '15200000',
    '15211000',
    '15219010',
    '15219091',
    '15219099',
    '15220010',
    '15220031',
    '15220039',
    '15220091',
    '15220099',
    '16010010',
    '16010091',
    '16010099',
    '16021000',
    '16022010',
    '16022090',
    '16023111',
    '16023119',
    '16023180',
    '16023211',
    '16023219',
    '16023230',
    '16023290',
    '16023921',
    '16023929',
    '16023985',
    '16024110',
    '16024190',
    '16024210',
    '16024290',
    '16024911',
    '16024913',
    '16024915',
    '16024919',
    '16024930',
    '16024950',
    '16024990',
    '16025010',
    '16025031',
    '16025095',
    '16029010',
    '16029031',
    '16029051',
    '16029061',
    '16029069',
    '16029091',
    '16029095',
    '16029099',
    '16030010',
    '16030080',
    '16041100',
    '16041210',
    '16041291',
    '16041299',
    '16041311',
    '16041319',
    '16041390',
    '16041421',
    '16041426',
    '16041428',
    '16041431',
    '16041436',
    '16041438',
    '16041441',
    '16041446',
    '16041448',
    '16041490',
    '16041511',
    '16041519',
    '16041590',
    '16041600',
    '16041700',
    '16041800',
    '16041910',
    '16041931',
    '16041939',
    '16041950',
    '16041991',
    '16041992',
    '16041993',
    '16041994',
    '16041995',
    '16041997',
    '16042005',
    '16042010',
    '16042030',
    '16042040',
    '16042050',
    '16042070',
    '16042090',
    '16043100',
    '16043200',
    '16051000',
    '16052110',
    '16052190',
    '16052900',
    '16053010',
    '16053090',
    '16054000',
    '16055100',
    '16055200',
    '16055310',
    '16055390',
    '16055400',
    '16055500',
    '16055600',
    '16055700',
    '16055800',
    '16055900',
    '16056100',
    '16056200',
    '16056300',
    '16056900',
    '17011210',
    '17011290',
    '17011310',
    '17011390',
    '17011410',
    '17011490',
    '17019100',
    '17019910',
    '17019990',
    '17021100',
    '17021900',
    '17022010',
    '17022090',
    '17023010',
    '17023050',
    '17023090',
    '17024010',
    '17024090',
    '17025000',
    '17026010',
    '17026080',
    '17026095',
    '17029010',
    '17029030',
    '17029050',
    '17029071',
    '17029075',
    '17029079',
    '17029080',
    '17029095',
    '17031000',
    '17039000',
    '17041010',
    '17041090',
    '17049010',
    '17049030',
    '17049051',
    '17049055',
    '17049061',
    '17049065',
    '17049071',
    '17049075',
    '17049081',
    '17049099',
    '18010000',
    '18020000',
    '18031000',
    '18032000',
    '18040000',
    '18050000',
    '18061015',
    '18061020',
    '18061030',
    '18061090',
    '18062010',
    '18062030',
    '18062050',
    '18062070',
    '18062080',
    '18062095',
    '18063100',
    '18063210',
    '18063290',
    '18069011',
    '18069019',
    '18069031',
    '18069039',
    '18069050',
    '18069060',
    '18069070',
    '18069090',
    '19011000',
    '19012000',
    '19019011',
    '19019019',
    '19019091',
    '19019095',
    '19019099',
    '19021100',
    '19021910',
    '19021990',
    '19022010',
    '19022030',
    '19022091',
    '19022099',
    '19023010',
    '19023090',
    '19024010',
    '19024090',
    '19030000',
    '19041010',
    '19041030',
    '19041090',
    '19042010',
    '19042091',
    '19042095',
    '19042099',
    '19043000',
    '19049010',
    '19049080',
    '19051000',
    '19052010',
    '19052030',
    '19052090',
    '19053111',
    '19053119',
    '19053130',
    '19053191',
    '19053199',
    '19053205',
    '19053211',
    '19053219',
    '19053291',
    '19053299',
    '19054010',
    '19054090',
    '19059010',
    '19059020',
    '19059030',
    '19059045',
    '19059055',
    '19059070',
    '19059080',
    '20011000',
    '20019010',
    '20019020',
    '20019030',
    '20019040',
    '20019050',
    '20019065',
    '20019070',
    '20019092',
    '20019097',
    '20021011',
    '20021019',
    '20021090',
    '20029011',
    '20029019',
    '20029020',
    '20029041',
    '20029049',
    '20029080',
    '20031020',
    '20031030',
    '20039010',
    '20039090',
    '20041010',
    '20041091',
    '20041099',
    '20049010',
    '20049030',
    '20049050',
    '20049091',
    '20049098',
    '20051000',
    '20052010',
    '20052020',
    '20052080',
    '20054000',
    '20055100',
    '20055900',
    '20056000',
    '20057000',
    '20058000',
    '20059100',
    '20059910',
    '20059920',
    '20059930',
    '20059950',
    '20059960',
    '20059980',
    '20060010',
    '20060031',
    '20060035',
    '20060038',
    '20060091',
    '20060099',
    '20071010',
    '20071091',
    '20071099',
    '20079110',
    '20079130',
    '20079190',
    '20079910',
    '20079920',
    '20079931',
    '20079933',
    '20079935',
    '20079939',
    '20079950',
    '20079993',
    '20079997',
    '20081110',
    '20081191',
    '20081196',
    '20081198',
    '20081912',
    '20081913',
    '20081919',
    '20081992',
    '20081993',
    '20081995',
    '20081999',
    '20082011',
    '20082019',
    '20082031',
    '20082039',
    '20082051',
    '20082059',
    '20082071',
    '20082079',
    '20082090',
    '20083011',
    '20083019',
    '20083031',
    '20083039',
    '20083051',
    '20083055',
    '20083059',
    '20083071',
    '20083075',
    '20083079',
    '20083090',
    '20084011',
    '20084019',
    '20084021',
    '20084029',
    '20084031',
    '20084039',
    '20084051',
    '20084059',
    '20084071',
    '20084079',
    '20084090',
    '20085011',
    '20085019',
    '20085031',
    '20085039',
    '20085051',
    '20085059',
    '20085061',
    '20085069',
    '20085071',
    '20085079',
    '20085092',
    '20085098',
    '20086011',
    '20086019',
    '20086031',
    '20086039',
    '20086050',
    '20086060',
    '20086070',
    '20086090',
    '20087011',
    '20087019',
    '20087031',
    '20087039',
    '20087051',
    '20087059',
    '20087061',
    '20087069',
    '20087071',
    '20087079',
    '20087092',
    '20087098',
    '20088011',
    '20088019',
    '20088031',
    '20088039',
    '20088050',
    '20088070',
    '20088090',
    '20089100',
    '20089311',
    '20089319',
    '20089321',
    '20089329',
    '20089391',
    '20089393',
    '20089399',
    '20089703',
    '20089705',
    '20089712',
    '20089714',
    '20089716',
    '20089718',
    '20089732',
    '20089734',
    '20089736',
    '20089738',
    '20089751',
    '20089759',
    '20089772',
    '20089774',
    '20089776',
    '20089778',
    '20089792',
    '20089793',
    '20089794',
    '20089796',
    '20089797',
    '20089798',
    '20089911',
    '20089919',
    '20089921',
    '20089923',
    '20089924',
    '20089928',
    '20089931',
    '20089934',
    '20089936',
    '20089937',
    '20089938',
    '20089940',
    '20089941',
    '20089943',
    '20089945',
    '20089948',
    '20089949',
    '20089951',
    '20089963',
    '20089967',
    '20089972',
    '20089978',
    '20089985',
    '20089991',
    '20089999',
    '20091111',
    '20091119',
    '20091191',
    '20091199',
    '20091200',
    '20091911',
    '20091919',
    '20091991',
    '20091998',
    '20092100',
    '20092911',
    '20092919',
    '20092991',
    '20092999',
    '20093111',
    '20093119',
    '20093151',
    '20093159',
    '20093191',
    '20093199',
    '20093911',
    '20093919',
    '20093931',
    '20093939',
    '20093951',
    '20093955',
    '20093959',
    '20093991',
    '20093995',
    '20093999',
    '20094192',
    '20094199',
    '20094911',
    '20094919',
    '20094930',
    '20094991',
    '20094993',
    '20094999',
    '20095010',
    '20095090',
    '20096110',
    '20096190',
    '20096911',
    '20096919',
    '20096951',
    '20096959',
    '20096971',
    '20096979',
    '20096990',
    '20097120',
    '20097199',
    '20097911',
    '20097919',
    '20097930',
    '20097991',
    '20097998',
    '20098111',
    '20098119',
    '20098131',
    '20098151',
    '20098159',
    '20098195',
    '20098199',
    '20098911',
    '20098919',
    '20098934',
    '20098935',
    '20098936',
    '20098938',
    '20098950',
    '20098961',
    '20098963',
    '20098969',
    '20098971',
    '20098973',
    '20098979',
    '20098985',
    '20098986',
    '20098988',
    '20098989',
    '20098996',
    '20098997',
    '20098999',
    '20099011',
    '20099019',
    '20099021',
    '20099029',
    '20099031',
    '20099039',
    '20099041',
    '20099049',
    '20099051',
    '20099059',
    '20099071',
    '20099073',
    '20099079',
    '20099092',
    '20099094',
    '20099095',
    '20099096',
    '20099097',
    '20099098',
    '21011100',
    '21011292',
    '21011298',
    '21012020',
    '21012092',
    '21012098',
    '21013011',
    '21013019',
    '21013091',
    '21013099',
    '21021010',
    '21021031',
    '21021039',
    '21021090',
    '21022011',
    '21022019',
    '21022090',
    '21023000',
    '21031000',
    '21032000',
    '21033010',
    '21033090',
    '21039010',
    '21039030',
    '21039090',
    '21041000',
    '21042000',
    '21050010',
    '21050091',
    '21050099',
    '21061020',
    '21061080',
    '21069020',
    '21069030',
    '21069051',
    '21069055',
    '21069059',
    '21069092',
    '21069098',
    '22011011',
    '22011019',
    '22011090',
    '22019000',
    '22021000',
    '22029100',
    '22029911',
    '22029915',
    '22029919',
    '22029991',
    '22029995',
    '22029999',
    '22030001',
    '22030009',
    '22030010',
    '22041011',
    '22041013',
    '22041015',
    '22041091',
    '22041093',
    '22041094',
    '22041096',
    '22041098',
    '22042106',
    '22042107',
    '22042108',
    '22042109',
    '22042111',
    '22042112',
    '22042113',
    '22042117',
    '22042118',
    '22042119',
    '22042122',
    '22042123',
    '22042124',
    '22042126',
    '22042127',
    '22042128',
    '22042131',
    '22042132',
    '22042134',
    '22042136',
    '22042137',
    '22042138',
    '22042142',
    '22042143',
    '22042144',
    '22042146',
    '22042147',
    '22042148',
    '22042161',
    '22042162',
    '22042166',
    '22042167',
    '22042168',
    '22042169',
    '22042171',
    '22042174',
    '22042176',
    '22042177',
    '22042178',
    '22042179',
    '22042180',
    '22042181',
    '22042182',
    '22042183',
    '22042184',
    '22042185',
    '22042186',
    '22042187',
    '22042188',
    '22042189',
    '22042190',
    '22042191',
    '22042193',
    '22042194',
    '22042195',
    '22042196',
    '22042197',
    '22042198',
    '22042210',
    '22042222',
    '22042223',
    '22042224',
    '22042226',
    '22042227',
    '22042228',
    '22042232',
    '22042233',
    '22042238',
    '22042278',
    '22042279',
    '22042280',
    '22042281',
    '22042282',
    '22042283',
    '22042284',
    '22042285',
    '22042286',
    '22042288',
    '22042290',
    '22042291',
    '22042293',
    '22042294',
    '22042295',
    '22042296',
    '22042297',
    '22042298',
    '22042910',
    '22042922',
    '22042923',
    '22042924',
    '22042926',
    '22042927',
    '22042928',
    '22042932',
    '22042938',
    '22042978',
    '22042979',
    '22042980',
    '22042981',
    '22042982',
    '22042983',
    '22042984',
    '22042985',
    '22042986',
    '22042988',
    '22042990',
    '22042991',
    '22042993',
    '22042994',
    '22042995',
    '22042996',
    '22042997',
    '22042998',
    '22043010',
    '22043092',
    '22043094',
    '22043096',
    '22043098',
    '22051010',
    '22051090',
    '22059010',
    '22059090',
    '22060010',
    '22060031',
    '22060039',
    '22060051',
    '22060059',
    '22060081',
    '22060089',
    '22071000',
    '22072000',
    '22082012',
    '22082014',
    '22082016',
    '22082018',
    '22082019',
    '22082026',
    '22082028',
    '22082062',
    '22082066',
    '22082069',
    '22082086',
    '22082088',
    '22083011',
    '22083019',
    '22083030',
    '22083041',
    '22083049',
    '22083061',
    '22083069',
    '22083071',
    '22083079',
    '22083082',
    '22083088',
    '22084011',
    '22084031',
    '22084039',
    '22084051',
    '22084091',
    '22084099',
    '22085011',
    '22085019',
    '22085091',
    '22085099',
    '22086011',
    '22086019',
    '22086091',
    '22086099',
    '22087010',
    '22087090',
    '22089011',
    '22089019',
    '22089033',
    '22089038',
    '22089041',
    '22089045',
    '22089048',
    '22089054',
    '22089056',
    '22089069',
    '22089071',
    '22089075',
    '22089077',
    '22089078',
    '22089091',
    '22089099',
    '22090011',
    '22090019',
    '22090091',
    '22090099',
    '23011000',
    '23012000',
    '23021010',
    '23021090',
    '23023010',
    '23023090',
    '23024002',
    '23024008',
    '23024010',
    '23024090',
    '23025000',
    '23031011',
    '23031019',
    '23031090',
    '23032010',
    '23032090',
    '23033000',
    '23040000',
    '23050000',
    '23061000',
    '23062000',
    '23063000',
    '23064100',
    '23064900',
    '23065000',
    '23066000',
    '23069005',
    '23069011',
    '23069019',
    '23069090',
    '23070011',
    '23070019',
    '23070090',
    '23080011',
    '23080019',
    '23080040',
    '23080090',
    '23091011',
    '23091013',
    '23091015',
    '23091019',
    '23091031',
    '23091033',
    '23091039',
    '23091051',
    '23091053',
    '23091059',
    '23091070',
    '23091090',
    '23099010',
    '23099020',
    '23099031',
    '23099033',
    '23099035',
    '23099039',
    '23099041',
    '23099043',
    '23099049',
    '23099051',
    '23099053',
    '23099059',
    '23099070',
    '23099091',
    '23099096',
    '24011035',
    '24011060',
    '24011070',
    '24011085',
    '24011095',
    '24012035',
    '24012060',
    '24012070',
    '24012085',
    '24012095',
    '24013000',
    '24021000',
    '24022010',
    '24022090',
    '24029000',
    '24031100',
    '24031910',
    '24031990',
    '24039100',
    '24039910',
    '24039990',
    '24041100',
    '24041200',
    '24041910',
    '24041990',
    '24049110',
    '24049190',
    '24049200',
    '24049900',
    '25010010',
    '25010031',
    '25010051',
    '25010091',
    '25010099',
    '25020000',
    '25030010',
    '25030090',
    '25041000',
    '25049000',
    '25051000',
    '25059000',
    '25061000',
    '25062000',
    '25070020',
    '25070080',
    '25081000',
    '25083000',
    '25084000',
    '25085000',
    '25086000',
    '25087000',
    '25090000',
    '25101000',
    '25102000',
    '25111000',
    '25112000',
    '25120000',
    '25131000',
    '25132000',
    '25140000',
    '25151100',
    '25151200',
    '25152000',
    '25161100',
    '25161200',
    '25162000',
    '25169000',
    '25171010',
    '25171020',
    '25171080',
    '25172000',
    '25173000',
    '25174100',
    '25174900',
    '25181000',
    '25182000',
    '25191000',
    '25199010',
    '25199030',
    '25199090',
    '25201000',
    '25202000',
    '25210000',
    '25221000',
    '25222000',
    '25223000',
    '25231000',
    '25232100',
    '25232900',
    '25233000',
    '25239000',
    '25241000',
    '25249000',
    '25251000',
    '25252000',
    '25253000',
    '25261000',
    '25262000',
    '25280000',
    '25291000',
    '25292100',
    '25292200',
    '25293000',
    '25301000',
    '25302000',
    '25309030',
    '25309040',
    '25309050',
    '25309070',
    '26011100',
    '26011200',
    '26012000',
    '26020000',
    '26030000',
    '26040000',
    '26050000',
    '26060000',
    '26070000',
    '26080000',
    '26090000',
    '26100000',
    '26110000',
    '26121010',
    '26121090',
    '26122010',
    '26122090',
    '26131000',
    '26139000',
    '26140000',
    '26151000',
    '26159000',
    '26161000',
    '26169000',
    '26171000',
    '26179000',
    '26180000',
    '26190020',
    '26190095',
    '26190097',
    '26201100',
    '26201900',
    '26202100',
    '26202900',
    '26203000',
    '26204000',
    '26206000',
    '26209100',
    '26209910',
    '26209920',
    '26209940',
    '26209960',
    '26209995',
    '26211000',
    '26219000',
    '27011100',
    '27011210',
    '27011290',
    '27011900',
    '27012000',
    '27021000',
    '27022000',
    '27030000',
    '27040010',
    '27040030',
    '27040090',
    '27050000',
    '27060000',
    '27071000',
    '27072000',
    '27073000',
    '27074000',
    '27075000',
    '27079100',
    '27079911',
    '27079919',
    '27079920',
    '27079950',
    '27079980',
    '27079991',
    '27079999',
    '27081000',
    '27082000',
    '27090010',
    '27090090',
    '27101211',
    '27101215',
    '27101221',
    '27101225',
    '27101231',
    '27101241',
    '27101245',
    '27101249',
    '27101250',
    '27101270',
    '27101290',
    '27101911',
    '27101915',
    '27101921',
    '27101925',
    '27101929',
    '27101931',
    '27101935',
    '27101943',
    '27101946',
    '27101947',
    '27101948',
    '27101951',
    '27101955',
    '27101962',
    '27101966',
    '27101967',
    '27101971',
    '27101975',
    '27101981',
    '27101983',
    '27101985',
    '27101987',
    '27101991',
    '27101993',
    '27101999',
    '27102011',
    '27102016',
    '27102019',
    '27102032',
    '27102038',
    '27102090',
    '27109100',
    '27109900',
    '27111100',
    '27111211',
    '27111219',
    '27111291',
    '27111293',
    '27111294',
    '27111297',
    '27111310',
    '27111330',
    '27111391',
    '27111397',
    '27111400',
    '27111900',
    '27112100',
    '27112900',
    '27121010',
    '27121090',
    '27122010',
    '27122090',
    '27129011',
    '27129019',
    '27129031',
    '27129033',
    '27129039',
    '27129091',
    '27129099',
    '27131100',
    '27131200',
    '27132000',
    '27139010',
    '27139090',
    '27141000',
    '27149000',
    '27150000',
    '27160000',
    '28011000',
    '28012000',
    '28013010',
    '28013090',
    '28020000',
    '28030000',
    '28041000',
    '28042100',
    '28042910',
    '28042990',
    '28043000',
    '28044000',
    '28045010',
    '28045090',
    '28046100',
    '28046900',
    '28047010',
    '28047090',
    '28048000',
    '28049000',
    '28051100',
    '28051200',
    '28051910',
    '28051990',
    '28053010',
    '28053021',
    '28053029',
    '28053031',
    '28053039',
    '28053040',
    '28053080',
    '28054010',
    '28054090',
    '28061000',
    '28062000',
    '28070000',
    '28080000',
    '28091000',
    '28092000',
    '28100010',
    '28100090',
    '28111100',
    '28111200',
    '28111910',
    '28111980',
    '28112100',
    '28112200',
    '28112905',
    '28112910',
    '28112930',
    '28112990',
    '28121100',
    '28121200',
    '28121300',
    '28121400',
    '28121500',
    '28121600',
    '28121700',
    '28121910',
    '28121990',
    '28129000',
    '28131000',
    '28139010',
    '28139090',
    '28141000',
    '28142000',
    '28151100',
    '28151200',
    '28152000',
    '28153000',
    '28161000',
    '28164000',
    '28170000',
    '28181011',
    '28181019',
    '28181091',
    '28181099',
    '28182000',
    '28183000',
    '28191000',
    '28199010',
    '28199090',
    '28201000',
    '28209010',
    '28209090',
    '28211000',
    '28212000',
    '28220000',
    '28230000',
    '28241000',
    '28249000',
    '28251000',
    '28252000',
    '28253000',
    '28254000',
    '28255000',
    '28256000',
    '28257000',
    '28258000',
    '28259011',
    '28259019',
    '28259020',
    '28259040',
    '28259060',
    '28259085',
    '28261200',
    '28261910',
    '28261990',
    '28263000',
    '28269010',
    '28269080',
    '28271000',
    '28272000',
    '28273100',
    '28273200',
    '28273500',
    '28273910',
    '28273920',
    '28273930',
    '28273985',
    '28274100',
    '28274910',
    '28274990',
    '28275100',
    '28275900',
    '28276000',
    '28281000',
    '28289000',
    '28291100',
    '28291900',
    '28299010',
    '28299040',
    '28299080',
    '28301000',
    '28309011',
    '28309085',
    '28311000',
    '28319000',
    '28321000',
    '28322000',
    '28323000',
    '28331100',
    '28331900',
    '28332100',
    '28332200',
    '28332400',
    '28332500',
    '28332700',
    '28332920',
    '28332930',
    '28332960',
    '28332980',
    '28333000',
    '28334000',
    '28341000',
    '28342100',
    '28342920',
    '28342940',
    '28342980',
    '28351000',
    '28352200',
    '28352400',
    '28352500',
    '28352600',
    '28352910',
    '28352930',
    '28352990',
    '28353100',
    '28353900',
    '28362000',
    '28363000',
    '28364000',
    '28365000',
    '28366000',
    '28369100',
    '28369200',
    '28369911',
    '28369917',
    '28369990',
    '28371100',
    '28371900',
    '28372000',
    '28391100',
    '28391900',
    '28399000',
    '28401100',
    '28401910',
    '28401990',
    '28402010',
    '28402090',
    '28403000',
    '28413000',
    '28415000',
    '28416100',
    '28416900',
    '28417000',
    '28418000',
    '28419030',
    '28419085',
    '28421000',
    '28429010',
    '28429080',
    '28431010',
    '28431090',
    '28432100',
    '28432900',
    '28433000',
    '28439010',
    '28439090',
    '28441010',
    '28441030',
    '28441050',
    '28441090',
    '28442025',
    '28442035',
    '28442051',
    '28442059',
    '28442099',
    '28443011',
    '28443019',
    '28443051',
    '28443055',
    '28443061',
    '28443069',
    '28443091',
    '28443099',
    '28444110',
    '28444190',
    '28444210',
    '28444290',
    '28444310',
    '28444320',
    '28444380',
    '28444400',
    '28445000',
    '28451000',
    '28452000',
    '28453000',
    '28454000',
    '28459010',
    '28459090',
    '28461000',
    '28469030',
    '28469040',
    '28469050',
    '28469060',
    '28469070',
    '28469090',
    '28470000',
    '28491000',
    '28492000',
    '28499010',
    '28499030',
    '28499050',
    '28499090',
    '28500020',
    '28500060',
    '28500090',
    '28521000',
    '28529000',
    '28531000',
    '28539010',
    '28539030',
    '28539090',
    '29011000',
    '29012100',
    '29012200',
    '29012300',
    '29012400',
    '29012900',
    '29021100',
    '29021900',
    '29022000',
    '29023000',
    '29024100',
    '29024200',
    '29024300',
    '29024400',
    '29025000',
    '29026000',
    '29027000',
    '29029000',
    '29031100',
    '29031200',
    '29031300',
    '29031400',
    '29031500',
    '29031900',
    '29032100',
    '29032200',
    '29032300',
    '29032900',
    '29034100',
    '29034200',
    '29034300',
    '29034400',
    '29034500',
    '29034600',
    '29034700',
    '29034800',
    '29034910',
    '29034930',
    '29034990',
    '29035100',
    '29035900',
    '29036100',
    '29036200',
    '29036911',
    '29036919',
    '29036980',
    '29037100',
    '29037200',
    '29037300',
    '29037400',
    '29037500',
    '29037610',
    '29037620',
    '29037690',
    '29037760',
    '29037790',
    '29037800',
    '29037930',
    '29037980',
    '29038100',
    '29038200',
    '29038300',
    '29038910',
    '29038980',
    '29039100',
    '29039200',
    '29039300',
    '29039400',
    '29039910',
    '29039980',
    '29041000',
    '29042000',
    '29043100',
    '29043200',
    '29043300',
    '29043400',
    '29043500',
    '29043600',
    '29049100',
    '29049900',
    '29051100',
    '29051200',
    '29051300',
    '29051410',
    '29051490',
    '29051620',
    '29051685',
    '29051700',
    '29051900',
    '29052200',
    '29052910',
    '29052990',
    '29053100',
    '29053200',
    '29053920',
    '29053926',
    '29053928',
    '29053930',
    '29053995',
    '29054100',
    '29054200',
    '29054300',
    '29054411',
    '29054419',
    '29054491',
    '29054499',
    '29054500',
    '29054900',
    '29055100',
    '29055991',
    '29055998',
    '29061100',
    '29061200',
    '29061310',
    '29061390',
    '29061900',
    '29062100',
    '29062900',
    '29071100',
    '29071200',
    '29071300',
    '29071510',
    '29071590',
    '29071910',
    '29071990',
    '29072100',
    '29072200',
    '29072300',
    '29072900',
    '29081100',
    '29081900',
    '29089100',
    '29089200',
    '29089900',
    '29091100',
    '29091910',
    '29091990',
    '29092000',
    '29093010',
    '29093031',
    '29093035',
    '29093038',
    '29093090',
    '29094100',
    '29094300',
    '29094400',
    '29094911',
    '29094980',
    '29095000',
    '29096010',
    '29096090',
    '29101000',
    '29102000',
    '29103000',
    '29104000',
    '29105000',
    '29109000',
    '29110000',
    '29121100',
    '29121200',
    '29121900',
    '29122100',
    '29122900',
    '29124100',
    '29124200',
    '29124900',
    '29125000',
    '29126000',
    '29130000',
    '29141100',
    '29141200',
    '29141300',
    '29141910',
    '29141990',
    '29142200',
    '29142300',
    '29142900',
    '29143100',
    '29143900',
    '29144010',
    '29144090',
    '29145000',
    '29146100',
    '29146200',
    '29146910',
    '29146980',
    '29147100',
    '29147900',
    '29151100',
    '29151200',
    '29151300',
    '29152100',
    '29152400',
    '29152900',
    '29153100',
    '29153200',
    '29153300',
    '29153600',
    '29153900',
    '29154000',
    '29155000',
    '29156011',
    '29156019',
    '29156090',
    '29157040',
    '29157050',
    '29159030',
    '29159070',
    '29161100',
    '29161200',
    '29161300',
    '29161400',
    '29161500',
    '29161600',
    '29161910',
    '29161940',
    '29161995',
    '29162000',
    '29163100',
    '29163200',
    '29163400',
    '29163910',
    '29163990',
    '29171100',
    '29171200',
    '29171310',
    '29171390',
    '29171400',
    '29171910',
    '29171920',
    '29171980',
    '29172000',
    '29173200',
    '29173300',
    '29173400',
    '29173500',
    '29173600',
    '29173700',
    '29173920',
    '29173935',
    '29173985',
    '29181100',
    '29181200',
    '29181300',
    '29181400',
    '29181500',
    '29181600',
    '29181700',
    '29181800',
    '29181930',
    '29181940',
    '29181998',
    '29182100',
    '29182200',
    '29182300',
    '29182900',
    '29183000',
    '29189100',
    '29189940',
    '29189990',
    '29191000',
    '29199000',
    '29201100',
    '29201900',
    '29202100',
    '29202200',
    '29202300',
    '29202400',
    '29202900',
    '29203000',
    '29209010',
    '29209070',
    '29211100',
    '29211200',
    '29211300',
    '29211400',
    '29211940',
    '29211950',
    '29211999',
    '29212100',
    '29212200',
    '29212900',
    '29213010',
    '29213091',
    '29213099',
    '29214100',
    '29214200',
    '29214300',
    '29214400',
    '29214500',
    '29214600',
    '29214900',
    '29215111',
    '29215119',
    '29215190',
    '29215950',
    '29215990',
    '29221100',
    '29221200',
    '29221400',
    '29221500',
    '29221600',
    '29221700',
    '29221800',
    '29221900',
    '29222100',
    '29222900',
    '29223100',
    '29223900',
    '29224100',
    '29224200',
    '29224300',
    '29224400',
    '29224920',
    '29224985',
    '29225000',
    '29231000',
    '29232000',
    '29233000',
    '29234000',
    '29239000',
    '29241100',
    '29241200',
    '29241900',
    '29242100',
    '29242300',
    '29242400',
    '29242500',
    '29242910',
    '29242970',
    '29251100',
    '29251200',
    '29251920',
    '29251995',
    '29252100',
    '29252900',
    '29261000',
    '29262000',
    '29263000',
    '29264000',
    '29269020',
    '29269070',
    '29270000',
    '29280010',
    '29280090',
    '29291000',
    '29299000',
    '29301000',
    '29302000',
    '29303000',
    '29304010',
    '29304090',
    '29306000',
    '29307000',
    '29308000',
    '29309013',
    '29309016',
    '29309030',
    '29309040',
    '29309050',
    '29309098',
    '29311000',
    '29312000',
    '29314100',
    '29314200',
    '29314300',
    '29314400',
    '29314500',
    '29314600',
    '29314700',
    '29314800',
    '29314910',
    '29314920',
    '29314930',
    '29314940',
    '29314990',
    '29315100',
    '29315200',
    '29315300',
    '29315400',
    '29315910',
    '29315990',
    '29319000',
    '29321100',
    '29321200',
    '29321300',
    '29321400',
    '29321900',
    '29322010',
    '29322020',
    '29322090',
    '29329100',
    '29329200',
    '29329300',
    '29329400',
    '29329500',
    '29329600',
    '29329900',
    '29331110',
    '29331190',
    '29331910',
    '29331990',
    '29332100',
    '29332910',
    '29332990',
    '29333100',
    '29333200',
    '29333300',
    '29333400',
    '29333500',
    '29333600',
    '29333700',
    '29333910',
    '29333920',
    '29333925',
    '29333935',
    '29333940',
    '29333945',
    '29333950',
    '29333955',
    '29333999',
    '29334100',
    '29334910',
    '29334930',
    '29334990',
    '29335200',
    '29335310',
    '29335390',
    '29335400',
    '29335500',
    '29335910',
    '29335920',
    '29335995',
    '29336100',
    '29336910',
    '29336940',
    '29336980',
    '29337100',
    '29337200',
    '29337900',
    '29339110',
    '29339190',
    '29339200',
    '29339920',
    '29339950',
    '29339980',
    '29341000',
    '29342020',
    '29342080',
    '29343010',
    '29343090',
    '29349100',
    '29349200',
    '29349960',
    '29349990',
    '29351000',
    '29352000',
    '29353000',
    '29354000',
    '29355000',
    '29359030',
    '29359090',
    '29362100',
    '29362200',
    '29362300',
    '29362400',
    '29362500',
    '29362600',
    '29362700',
    '29362800',
    '29362900',
    '29369000',
    '29371100',
    '29371200',
    '29371900',
    '29372100',
    '29372200',
    '29372300',
    '29372900',
    '29375000',
    '29379000',
    '29381000',
    '29389010',
    '29389030',
    '29389090',
    '29391100',
    '29391900',
    '29392000',
    '29393000',
    '29394100',
    '29394200',
    '29394300',
    '29394400',
    '29394500',
    '29394900',
    '29395100',
    '29395900',
    '29396100',
    '29396200',
    '29396300',
    '29396900',
    '29397200',
    '29397910',
    '29397990',
    '29398000',
    '29400000',
    '29411000',
    '29412030',
    '29412080',
    '29413000',
    '29414000',
    '29415000',
    '29419000',
    '29420000',
    '30012010',
    '30012090',
    '30019020',
    '30019091',
    '30019098',
    '30021200',
    '30021300',
    '30021400',
    '30021500',
    '30024110',
    '30024190',
    '30024200',
    '30024900',
    '30025100',
    '30025900',
    '30029010',
    '30029030',
    '30031000',
    '30032000',
    '30033100',
    '30033900',
    '30034100',
    '30034200',
    '30034300',
    '30034900',
    '30036000',
    '30039000',
    '30041000',
    '30042000',
    '30043100',
    '30043200',
    '30043900',
    '30044100',
    '30044200',
    '30044300',
    '30044900',
    '30045000',
    '30046000',
    '30049000',
    '30051000',
    '30059010',
    '30059031',
    '30059050',
    '30059099',
    '30061010',
    '30061030',
    '30061090',
    '30063000',
    '30064000',
    '30065000',
    '30066000',
    '30067000',
    '30069100',
    '30069200',
    '30069300',
    '31010000',
    '31021010',
    '31021090',
    '31022100',
    '31022900',
    '31023010',
    '31023090',
    '31024010',
    '31024090',
    '31025000',
    '31026000',
    '31028000',
    '31029000',
    '31031100',
    '31031900',
    '31039000',
    '31042010',
    '31042050',
    '31042090',
    '31043000',
    '31049000',
    '31051000',
    '31052010',
    '31052090',
    '31053000',
    '31054000',
    '31055100',
    '31055900',
    '31056000',
    '31059020',
    '31059080',
    '32011000',
    '32012000',
    '32019020',
    '32019090',
    '32021000',
    '32029000',
    '32030010',
    '32030090',
    '32041100',
    '32041200',
    '32041300',
    '32041400',
    '32041500',
    '32041600',
    '32041700',
    '32041800',
    '32041900',
    '32042000',
    '32049000',
    '32050000',
    '32061100',
    '32061900',
    '32062000',
    '32064100',
    '32064200',
    '32064910',
    '32064970',
    '32065000',
    '32071000',
    '32072010',
    '32072090',
    '32073000',
    '32074040',
    '32074085',
    '32081010',
    '32081090',
    '32082010',
    '32082090',
    '32089011',
    '32089013',
    '32089019',
    '32089091',
    '32089099',
    '32091000',
    '32099000',
    '32100010',
    '32100090',
    '32110000',
    '32121000',
    '32129000',
    '32131000',
    '32139000',
    '32141010',
    '32141090',
    '32149000',
    '32151100',
    '32151900',
    '32159020',
    '32159070',
    '33011210',
    '33011290',
    '33011310',
    '33011390',
    '33011920',
    '33011980',
    '33012410',
    '33012490',
    '33012510',
    '33012590',
    '33012911',
    '33012931',
    '33012942',
    '33012949',
    '33012971',
    '33012979',
    '33012991',
    '33013000',
    '33019010',
    '33019021',
    '33019030',
    '33019090',
    '33021010',
    '33021021',
    '33021029',
    '33021040',
    '33021090',
    '33029010',
    '33029090',
    '33030010',
    '33030090',
    '33041000',
    '33042000',
    '33043000',
    '33049100',
    '33049900',
    '33051000',
    '33052000',
    '33053000',
    '33059000',
    '33061000',
    '33062000',
    '33069000',
    '33071000',
    '33072000',
    '33073000',
    '33074100',
    '33074900',
    '33079000',
    '34011100',
    '34011900',
    '34012010',
    '34012090',
    '34013000',
    '34023100',
    '34023910',
    '34023990',
    '34024100',
    '34024200',
    '34024900',
    '34025010',
    '34025090',
    '34029010',
    '34029090',
    '34031100',
    '34031910',
    '34031920',
    '34031980',
    '34039100',
    '34039900',
    '34042000',
    '34049000',
    '34051000',
    '34052000',
    '34053000',
    '34054000',
    '34059010',
    '34059090',
    '34060000',
    '34070000',
    '35011010',
    '35011050',
    '35011090',
    '35019010',
    '35019090',
    '35021110',
    '35021190',
    '35021910',
    '35021990',
    '35022010',
    '35022091',
    '35022099',
    '35029020',
    '35029070',
    '35029090',
    '35030010',
    '35030080',
    '35040010',
    '35040090',
    '35051010',
    '35051050',
    '35051090',
    '35052010',
    '35052030',
    '35052050',
    '35052090',
    '35061000',
    '35069110',
    '35069190',
    '35069900',
    '35071000',
    '35079030',
    '35079090',
    '36010000',
    '36020000',
    '36031000',
    '36032000',
    '36033000',
    '36034000',
    '36035000',
    '36036000',
    '36041000',
    '36049000',
    '36050000',
    '36061000',
    '36069010',
    '36069090',
    '37011000',
    '37012000',
    '37013000',
    '37019100',
    '37019900',
    '37021000',
    '37023191',
    '37023197',
    '37023210',
    '37023220',
    '37023285',
    '37023900',
    '37024100',
    '37024200',
    '37024300',
    '37024400',
    '37025200',
    '37025300',
    '37025400',
    '37025500',
    '37025600',
    '37029610',
    '37029690',
    '37029710',
    '37029790',
    '37029800',
    '37031000',
    '37032000',
    '37039000',
    '37040010',
    '37040090',
    '37050010',
    '37050090',
    '37061020',
    '37061099',
    '37069052',
    '37069091',
    '37069099',
    '37071000',
    '37079020',
    '37079090',
    '38011000',
    '38012010',
    '38012090',
    '38013000',
    '38019000',
    '38021000',
    '38029000',
    '38030010',
    '38030090',
    '38040000',
    '38051010',
    '38051030',
    '38051090',
    '38059010',
    '38059090',
    '38061000',
    '38062000',
    '38063000',
    '38069000',
    '38070010',
    '38070090',
    '38085200',
    '38085900',
    '38086100',
    '38086200',
    '38086900',
    '38089110',
    '38089120',
    '38089130',
    '38089140',
    '38089190',
    '38089210',
    '38089220',
    '38089230',
    '38089240',
    '38089250',
    '38089260',
    '38089290',
    '38089311',
    '38089313',
    '38089315',
    '38089317',
    '38089321',
    '38089323',
    '38089327',
    '38089330',
    '38089390',
    '38089410',
    '38089420',
    '38089490',
    '38089910',
    '38089990',
    '38091010',
    '38091030',
    '38091050',
    '38091090',
    '38099100',
    '38099200',
    '38099300',
    '38101000',
    '38109010',
    '38109090',
    '38111110',
    '38111190',
    '38111900',
    '38112100',
    '38112900',
    '38119000',
    '38121000',
    '38122010',
    '38122090',
    '38123100',
    '38123910',
    '38123990',
    '38130000',
    '38140010',
    '38140090',
    '38151100',
    '38151200',
    '38151910',
    '38151990',
    '38159010',
    '38159090',
    '38160010',
    '38160090',
    '38170050',
    '38170080',
    '38180010',
    '38180090',
    '38190000',
    '38200000',
    '38210000',
    '38221100',
    '38221200',
    '38221300',
    '38221900',
    '38229000',
    '38231100',
    '38231200',
    '38231300',
    '38231910',
    '38231930',
    '38231990',
    '38237000',
    '38241000',
    '38243000',
    '38244000',
    '38245010',
    '38245090',
    '38246011',
    '38246019',
    '38246091',
    '38246099',
    '38248100',
    '38248200',
    '38248300',
    '38248400',
    '38248500',
    '38248600',
    '38248700',
    '38248800',
    '38248900',
    '38249100',
    '38249200',
    '38249910',
    '38249915',
    '38249920',
    '38249925',
    '38249930',
    '38249945',
    '38249950',
    '38249955',
    '38249961',
    '38249962',
    '38249964',
    '38249965',
    '38249970',
    '38249975',
    '38249980',
    '38249985',
    '38249986',
    '38249992',
    '38249993',
    '38249996',
    '38251000',
    '38252000',
    '38253000',
    '38254100',
    '38254900',
    '38255000',
    '38256100',
    '38256900',
    '38259010',
    '38259090',
    '38260010',
    '38260090',
    '38271100',
    '38271200',
    '38271300',
    '38271400',
    '38272000',
    '38273100',
    '38273200',
    '38273900',
    '38274000',
    '38275100',
    '38275900',
    '38276100',
    '38276200',
    '38276300',
    '38276400',
    '38276500',
    '38276800',
    '38276900',
    '38279000',
    '39011010',
    '39011090',
    '39012010',
    '39012090',
    '39013000',
    '39014000',
    '39019030',
    '39019080',
    '39021000',
    '39022000',
    '39023000',
    '39029010',
    '39029020',
    '39029090',
    '39031100',
    '39031900',
    '39032000',
    '39033000',
    '39039010',
    '39039020',
    '39039090',
    '39041000',
    '39042100',
    '39042200',
    '39043000',
    '39044000',
    '39045010',
    '39045090',
    '39046100',
    '39046910',
    '39046920',
    '39046980',
    '39049000',
    '39051200',
    '39051900',
    '39052100',
    '39052900',
    '39053000',
    '39059100',
    '39059910',
    '39059990',
    '39061000',
    '39069010',
    '39069020',
    '39069030',
    '39069040',
    '39069050',
    '39069060',
    '39069090',
    '39071000',
    '39072100',
    '39072911',
    '39072920',
    '39072991',
    '39072999',
    '39073000',
    '39074000',
    '39075000',
    '39076100',
    '39076900',
    '39077000',
    '39079110',
    '39079190',
    '39079905',
    '39079910',
    '39079980',
    '39081000',
    '39089000',
    '39091000',
    '39092000',
    '39093100',
    '39093900',
    '39094000',
    '39095010',
    '39095090',
    '39100000',
    '39111000',
    '39112000',
    '39119011',
    '39119013',
    '39119019',
    '39119092',
    '39119099',
    '39121100',
    '39121200',
    '39122011',
    '39122019',
    '39122090',
    '39123100',
    '39123920',
    '39123985',
    '39129010',
    '39129090',
    '39131000',
    '39139000',
    '39140000',
    '39151010',
    '39151020',
    '39152000',
    '39153000',
    '39159011',
    '39159020',
    '39159070',
    '39161000',
    '39162000',
    '39169010',
    '39169050',
    '39169090',
    '39171010',
    '39171090',
    '39172110',
    '39172190',
    '39172210',
    '39172290',
    '39172310',
    '39172390',
    '39172900',
    '39173100',
    '39173200',
    '39173300',
    '39173900',
    '39174000',
    '39181010',
    '39181090',
    '39189000',
    '39191012',
    '39191015',
    '39191019',
    '39191080',
    '39199020',
    '39199080',
    '39201023',
    '39201024',
    '39201025',
    '39201028',
    '39201040',
    '39201081',
    '39201089',
    '39202021',
    '39202029',
    '39202080',
    '39203000',
    '39204310',
    '39204390',
    '39204910',
    '39204990',
    '39205100',
    '39205910',
    '39205990',
    '39206100',
    '39206212',
    '39206219',
    '39206290',
    '39206300',
    '39206900',
    '39207100',
    '39207310',
    '39207380',
    '39207910',
    '39207990',
    '39209100',
    '39209200',
    '39209300',
    '39209400',
    '39209921',
    '39209928',
    '39209952',
    '39209953',
    '39209959',
    '39209990',
    '39211100',
    '39211200',
    '39211310',
    '39211390',
    '39211400',
    '39211900',
    '39219010',
    '39219030',
    '39219041',
    '39219043',
    '39219049',
    '39219055',
    '39219060',
    '39219090',
    '39221000',
    '39222000',
    '39229000',
    '39231010',
    '39231090',
    '39232100',
    '39232910',
    '39232990',
    '39233010',
    '39233090',
    '39234010',
    '39234090',
    '39235010',
    '39235090',
    '39239000',
    '39241000',
    '39249000',
    '39251000',
    '39252000',
    '39253000',
    '39259010',
    '39259020',
    '39259080',
    '39261000',
    '39262000',
    '39263000',
    '39264000',
    '39269050',
    '39269060',
    '39269097',
    '40011000',
    '40012100',
    '40012200',
    '40012900',
    '40013000',
    '40021100',
    '40021910',
    '40021920',
    '40021930',
    '40021990',
    '40022000',
    '40023100',
    '40023900',
    '40024100',
    '40024900',
    '40025100',
    '40025900',
    '40026000',
    '40027000',
    '40028000',
    '40029100',
    '40029910',
    '40029990',
    '40030000',
    '40040000',
    '40051000',
    '40052000',
    '40059100',
    '40059900',
    '40061000',
    '40069000',
    '40070000',
    '40081100',
    '40081900',
    '40082110',
    '40082190',
    '40082900',
    '40091100',
    '40091200',
    '40092100',
    '40092200',
    '40093100',
    '40093200',
    '40094100',
    '40094200',
    '40101100',
    '40101200',
    '40101900',
    '40103100',
    '40103200',
    '40103300',
    '40103400',
    '40103500',
    '40103600',
    '40103900',
    '40111000',
    '40112010',
    '40112090',
    '40113000',
    '40114000',
    '40115000',
    '40117000',
    '40118000',
    '40119000',
    '40121100',
    '40121200',
    '40121300',
    '40121900',
    '40122000',
    '40129020',
    '40129030',
    '40129090',
    '40131000',
    '40132000',
    '40139000',
    '40141000',
    '40149000',
    '40151200',
    '40151900',
    '40159000',
    '40161000',
    '40169100',
    '40169200',
    '40169300',
    '40169400',
    '40169500',
    '40169952',
    '40169957',
    '40169991',
    '40169997',
    '40170000',
    '41012010',
    '41012030',
    '41012050',
    '41012080',
    '41015010',
    '41015030',
    '41015050',
    '41015090',
    '41019000',
    '41021010',
    '41021090',
    '41022100',
    '41022900',
    '41032000',
    '41033000',
    '41039000',
    '41041110',
    '41041151',
    '41041159',
    '41041190',
    '41041910',
    '41041951',
    '41041959',
    '41041990',
    '41044111',
    '41044119',
    '41044151',
    '41044159',
    '41044190',
    '41044911',
    '41044919',
    '41044951',
    '41044959',
    '41044990',
    '41051000',
    '41053010',
    '41053090',
    '41062100',
    '41062210',
    '41062290',
    '41063100',
    '41063200',
    '41064010',
    '41064090',
    '41069100',
    '41069200',
    '41071111',
    '41071119',
    '41071190',
    '41071211',
    '41071219',
    '41071291',
    '41071299',
    '41071910',
    '41071990',
    '41079110',
    '41079190',
    '41079210',
    '41079290',
    '41079910',
    '41079990',
    '41120000',
    '41131000',
    '41132000',
    '41133000',
    '41139000',
    '41141010',
    '41141090',
    '41142000',
    '41151000',
    '41152000',
    '42010000',
    '42021110',
    '42021190',
    '42021211',
    '42021219',
    '42021250',
    '42021291',
    '42021299',
    '42021910',
    '42021990',
    '42022100',
    '42022210',
    '42022290',
    '42022900',
    '42023100',
    '42023210',
    '42023290',
    '42023900',
    '42029110',
    '42029180',
    '42029211',
    '42029215',
    '42029219',
    '42029291',
    '42029298',
    '42029900',
    '42031000',
    '42032100',
    '42032910',
    '42032990',
    '42033000',
    '42034000',
    '42050011',
    '42050019',
    '42050090',
    '42060000',
    '43011000',
    '43013000',
    '43016000',
    '43018000',
    '43019000',
    '43021100',
    '43021915',
    '43021935',
    '43021941',
    '43021949',
    '43021975',
    '43021980',
    '43021999',
    '43022000',
    '43023010',
    '43023025',
    '43023051',
    '43023055',
    '43023099',
    '43031010',
    '43031090',
    '43039000',
    '43040000',
    '44011100',
    '44011200',
    '44012100',
    '44012210',
    '44012290',
    '44013100',
    '44013200',
    '44013900',
    '44014100',
    '44014900',
    '44021000',
    '44022000',
    '44029000',
    '44031100',
    '44031200',
    '44032110',
    '44032190',
    '44032200',
    '44032310',
    '44032390',
    '44032400',
    '44032510',
    '44032590',
    '44032600',
    '44034100',
    '44034200',
    '44034910',
    '44034935',
    '44034985',
    '44039100',
    '44039300',
    '44039400',
    '44039510',
    '44039590',
    '44039600',
    '44039700',
    '44039800',
    '44039900',
    '44041000',
    '44042000',
    '44050000',
    '44061100',
    '44061200',
    '44069100',
    '44069200',
    '44071110',
    '44071120',
    '44071190',
    '44071210',
    '44071220',
    '44071290',
    '44071300',
    '44071400',
    '44071910',
    '44071920',
    '44071990',
    '44072110',
    '44072191',
    '44072199',
    '44072210',
    '44072291',
    '44072299',
    '44072310',
    '44072320',
    '44072390',
    '44072510',
    '44072530',
    '44072550',
    '44072590',
    '44072610',
    '44072630',
    '44072650',
    '44072690',
    '44072710',
    '44072791',
    '44072799',
    '44072810',
    '44072891',
    '44072899',
    '44072915',
    '44072920',
    '44072983',
    '44072985',
    '44072995',
    '44072996',
    '44072997',
    '44072998',
    '44079115',
    '44079131',
    '44079139',
    '44079190',
    '44079200',
    '44079310',
    '44079391',
    '44079399',
    '44079410',
    '44079491',
    '44079499',
    '44079510',
    '44079591',
    '44079599',
    '44079610',
    '44079691',
    '44079699',
    '44079710',
    '44079791',
    '44079799',
    '44079927',
    '44079940',
    '44079990',
    '44081015',
    '44081091',
    '44081098',
    '44083111',
    '44083121',
    '44083125',
    '44083130',
    '44083915',
    '44083921',
    '44083930',
    '44083955',
    '44083970',
    '44083985',
    '44083995',
    '44089015',
    '44089035',
    '44089085',
    '44089095',
    '44091011',
    '44091018',
    '44092100',
    '44092200',
    '44092910',
    '44092991',
    '44092999',
    '44101110',
    '44101130',
    '44101150',
    '44101190',
    '44101210',
    '44101290',
    '44101900',
    '44109000',
    '44111210',
    '44111292',
    '44111294',
    '44111310',
    '44111392',
    '44111394',
    '44111410',
    '44111492',
    '44111495',
    '44111497',
    '44119210',
    '44119290',
    '44119300',
    '44119410',
    '44119490',
    '44121000',
    '44123110',
    '44123190',
    '44123310',
    '44123320',
    '44123330',
    '44123390',
    '44123400',
    '44123900',
    '44124191',
    '44124199',
    '44124200',
    '44124900',
    '44125110',
    '44125190',
    '44125200',
    '44125900',
    '44129110',
    '44129191',
    '44129199',
    '44129210',
    '44129290',
    '44129910',
    '44129990',
    '44130000',
    '44141010',
    '44141090',
    '44149000',
    '44151010',
    '44151090',
    '44152020',
    '44152090',
    '44160000',
    '44170000',
    '44181100',
    '44181950',
    '44181990',
    '44182110',
    '44182190',
    '44182950',
    '44182980',
    '44183000',
    '44184000',
    '44185000',
    '44187310',
    '44187390',
    '44187400',
    '44187500',
    '44187900',
    '44188100',
    '44188200',
    '44188300',
    '44188900',
    '44189100',
    '44189200',
    '44189900',
    '44191100',
    '44191200',
    '44191900',
    '44192010',
    '44192090',
    '44199000',
    '44201110',
    '44201190',
    '44201900',
    '44209010',
    '44209091',
    '44209099',
    '44211000',
    '44212010',
    '44212090',
    '44219100',
    '44219910',
    '44219999',
    '45011000',
    '45019000',
    '45020000',
    '45031010',
    '45031090',
    '45039000',
    '45041011',
    '45041019',
    '45041091',
    '45041099',
    '45049020',
    '45049080',
    '46012110',
    '46012190',
    '46012210',
    '46012290',
    '46012910',
    '46012990',
    '46019205',
    '46019210',
    '46019290',
    '46019305',
    '46019310',
    '46019390',
    '46019405',
    '46019410',
    '46019490',
    '46019905',
    '46019910',
    '46019990',
    '46021100',
    '46021200',
    '46021910',
    '46021990',
    '46029000',
    '47010010',
    '47010090',
    '47020000',
    '47031100',
    '47031900',
    '47032100',
    '47032900',
    '47041100',
    '47041900',
    '47042100',
    '47042900',
    '47050000',
    '47061000',
    '47062000',
    '47063000',
    '47069100',
    '47069200',
    '47069300',
    '47071000',
    '47072000',
    '47073010',
    '47073090',
    '47079010',
    '47079090',
    '48010000',
    '48021000',
    '48022000',
    '48024010',
    '48024090',
    '48025400',
    '48025515',
    '48025525',
    '48025530',
    '48025590',
    '48025620',
    '48025680',
    '48025700',
    '48025810',
    '48025890',
    '48026115',
    '48026180',
    '48026200',
    '48026900',
    '48030010',
    '48030031',
    '48030039',
    '48030090',
    '48041111',
    '48041115',
    '48041119',
    '48041190',
    '48041912',
    '48041919',
    '48041930',
    '48041990',
    '48042110',
    '48042190',
    '48042910',
    '48042990',
    '48043151',
    '48043158',
    '48043180',
    '48043951',
    '48043958',
    '48043980',
    '48044191',
    '48044198',
    '48044200',
    '48044900',
    '48045100',
    '48045200',
    '48045910',
    '48045990',
    '48051100',
    '48051200',
    '48051910',
    '48051990',
    '48052400',
    '48052500',
    '48053000',
    '48054000',
    '48055000',
    '48059100',
    '48059200',
    '48059320',
    '48059380',
    '48061000',
    '48062000',
    '48063000',
    '48064010',
    '48064090',
    '48070030',
    '48070080',
    '48081000',
    '48084000',
    '48089000',
    '48092000',
    '48099000',
    '48101300',
    '48101400',
    '48101900',
    '48102200',
    '48102930',
    '48102980',
    '48103100',
    '48103210',
    '48103290',
    '48103900',
    '48109210',
    '48109230',
    '48109290',
    '48109910',
    '48109980',
    '48111000',
    '48114120',
    '48114190',
    '48114900',
    '48115100',
    '48115900',
    '48116000',
    '48119000',
    '48120000',
    '48131000',
    '48132000',
    '48139010',
    '48139090',
    '48142000',
    '48149010',
    '48149070',
    '48162000',
    '48169000',
    '48171000',
    '48172000',
    '48173000',
    '48181010',
    '48181090',
    '48182010',
    '48182091',
    '48182099',
    '48183000',
    '48185000',
    '48189010',
    '48189090',
    '48191000',
    '48192000',
    '48193000',
    '48194000',
    '48195000',
    '48196000',
    '48201010',
    '48201030',
    '48201050',
    '48201090',
    '48202000',
    '48203000',
    '48204000',
    '48205000',
    '48209000',
    '48211010',
    '48211090',
    '48219010',
    '48219090',
    '48221000',
    '48229000',
    '48232000',
    '48234000',
    '48236100',
    '48236910',
    '48236990',
    '48237010',
    '48237090',
    '48239040',
    '48239085',
    '49011000',
    '49019100',
    '49019900',
    '49021000',
    '49029000',
    '49030000',
    '49040000',
    '49052000',
    '49059000',
    '49060000',
    '49070010',
    '49070030',
    '49070090',
    '49081000',
    '49089000',
    '49090000',
    '49100000',
    '49111010',
    '49111090',
    '49119100',
    '49119900',
    '50010000',
    '50020000',
    '50030000',
    '50040010',
    '50040090',
    '50050010',
    '50050090',
    '50060010',
    '50060090',
    '50071000',
    '50072011',
    '50072019',
    '50072021',
    '50072031',
    '50072039',
    '50072041',
    '50072051',
    '50072059',
    '50072060',
    '50072071',
    '50079010',
    '50079030',
    '50079050',
    '50079090',
    '51011100',
    '51011900',
    '51012100',
    '51012900',
    '51013000',
    '51021100',
    '51021910',
    '51021930',
    '51021940',
    '51021990',
    '51022000',
    '51031010',
    '51031090',
    '51032000',
    '51033000',
    '51040000',
    '51051000',
    '51052100',
    '51052900',
    '51053100',
    '51053900',
    '51054000',
    '51061010',
    '51061090',
    '51062010',
    '51062091',
    '51062099',
    '51071010',
    '51071090',
    '51072010',
    '51072030',
    '51072051',
    '51072059',
    '51072091',
    '51072099',
    '51081010',
    '51081090',
    '51082010',
    '51082090',
    '51091010',
    '51091090',
    '51099000',
    '51100000',
    '51111100',
    '51111900',
    '51112000',
    '51113010',
    '51113080',
    '51119010',
    '51119091',
    '51119098',
    '51121100',
    '51121900',
    '51122000',
    '51123010',
    '51123080',
    '51129010',
    '51129091',
    '51129098',
    '51130000',
    '52010010',
    '52010090',
    '52021000',
    '52029100',
    '52029900',
    '52030000',
    '52041100',
    '52041900',
    '52042000',
    '52051100',
    '52051200',
    '52051300',
    '52051400',
    '52051510',
    '52051590',
    '52052100',
    '52052200',
    '52052300',
    '52052400',
    '52052600',
    '52052700',
    '52052800',
    '52053100',
    '52053200',
    '52053300',
    '52053400',
    '52053500',
    '52054100',
    '52054200',
    '52054300',
    '52054400',
    '52054600',
    '52054700',
    '52054800',
    '52061100',
    '52061200',
    '52061300',
    '52061400',
    '52061500',
    '52062100',
    '52062200',
    '52062300',
    '52062400',
    '52062500',
    '52063100',
    '52063200',
    '52063300',
    '52063400',
    '52063500',
    '52064100',
    '52064200',
    '52064300',
    '52064400',
    '52064500',
    '52071000',
    '52079000',
    '52081110',
    '52081190',
    '52081216',
    '52081219',
    '52081296',
    '52081299',
    '52081300',
    '52081900',
    '52082110',
    '52082190',
    '52082216',
    '52082219',
    '52082296',
    '52082299',
    '52082300',
    '52082900',
    '52083100',
    '52083216',
    '52083219',
    '52083296',
    '52083299',
    '52083300',
    '52083900',
    '52084100',
    '52084200',
    '52084300',
    '52084900',
    '52085100',
    '52085200',
    '52085910',
    '52085990',
    '52091100',
    '52091200',
    '52091900',
    '52092100',
    '52092200',
    '52092900',
    '52093100',
    '52093200',
    '52093900',
    '52094100',
    '52094200',
    '52094300',
    '52094900',
    '52095100',
    '52095200',
    '52095900',
    '52101100',
    '52101900',
    '52102100',
    '52102900',
    '52103100',
    '52103200',
    '52103900',
    '52104100',
    '52104900',
    '52105100',
    '52105900',
    '52111100',
    '52111200',
    '52111900',
    '52112000',
    '52113100',
    '52113200',
    '52113900',
    '52114100',
    '52114200',
    '52114300',
    '52114910',
    '52114990',
    '52115100',
    '52115200',
    '52115900',
    '52121110',
    '52121190',
    '52121210',
    '52121290',
    '52121310',
    '52121390',
    '52121410',
    '52121490',
    '52121510',
    '52121590',
    '52122110',
    '52122190',
    '52122210',
    '52122290',
    '52122310',
    '52122390',
    '52122410',
    '52122490',
    '52122510',
    '52122590',
    '53011000',
    '53012100',
    '53012900',
    '53013000',
    '53021000',
    '53029000',
    '53031000',
    '53039000',
    '53050000',
    '53061010',
    '53061030',
    '53061050',
    '53061090',
    '53062010',
    '53062090',
    '53071000',
    '53072000',
    '53081000',
    '53082010',
    '53082090',
    '53089012',
    '53089019',
    '53089050',
    '53089090',
    '53091110',
    '53091190',
    '53091900',
    '53092100',
    '53092900',
    '53101010',
    '53101090',
    '53109000',
    '53110010',
    '53110090',
    '54011012',
    '54011014',
    '54011016',
    '54011018',
    '54011090',
    '54012010',
    '54012090',
    '54021100',
    '54021900',
    '54022000',
    '54023100',
    '54023200',
    '54023300',
    '54023400',
    '54023900',
    '54024400',
    '54024500',
    '54024600',
    '54024700',
    '54024800',
    '54024900',
    '54025100',
    '54025200',
    '54025300',
    '54025900',
    '54026100',
    '54026200',
    '54026300',
    '54026900',
    '54031000',
    '54033100',
    '54033200',
    '54033300',
    '54033900',
    '54034100',
    '54034200',
    '54034900',
    '54041100',
    '54041200',
    '54041900',
    '54049010',
    '54049090',
    '54050000',
    '54060000',
    '54071000',
    '54072011',
    '54072019',
    '54072090',
    '54073000',
    '54074100',
    '54074200',
    '54074300',
    '54074400',
    '54075100',
    '54075200',
    '54075300',
    '54075400',
    '54076110',
    '54076130',
    '54076150',
    '54076190',
    '54076910',
    '54076990',
    '54077100',
    '54077200',
    '54077300',
    '54077400',
    '54078100',
    '54078200',
    '54078300',
    '54078400',
    '54079100',
    '54079200',
    '54079300',
    '54079400',
    '54081000',
    '54082100',
    '54082210',
    '54082290',
    '54082300',
    '54082400',
    '54083100',
    '54083200',
    '54083300',
    '54083400',
    '55011100',
    '55011900',
    '55012000',
    '55013000',
    '55014000',
    '55019000',
    '55021000',
    '55029000',
    '55031100',
    '55031900',
    '55032000',
    '55033000',
    '55034000',
    '55039000',
    '55041000',
    '55049000',
    '55051010',
    '55051030',
    '55051050',
    '55051070',
    '55051090',
    '55052000',
    '55061000',
    '55062000',
    '55063000',
    '55064000',
    '55069000',
    '55070000',
    '55081010',
    '55081090',
    '55082010',
    '55082090',
    '55091100',
    '55091200',
    '55092100',
    '55092200',
    '55093100',
    '55093200',
    '55094100',
    '55094200',
    '55095100',
    '55095200',
    '55095300',
    '55095900',
    '55096100',
    '55096200',
    '55096900',
    '55099100',
    '55099200',
    '55099900',
    '55101100',
    '55101200',
    '55102000',
    '55103000',
    '55109000',
    '55111000',
    '55112000',
    '55113000',
    '55121100',
    '55121910',
    '55121990',
    '55122100',
    '55122910',
    '55122990',
    '55129100',
    '55129910',
    '55129990',
    '55131120',
    '55131190',
    '55131200',
    '55131300',
    '55131900',
    '55132100',
    '55132310',
    '55132390',
    '55132900',
    '55133100',
    '55133900',
    '55134100',
    '55134900',
    '55141100',
    '55141200',
    '55141910',
    '55141990',
    '55142100',
    '55142200',
    '55142300',
    '55142900',
    '55143010',
    '55143030',
    '55143050',
    '55143090',
    '55144100',
    '55144200',
    '55144300',
    '55144900',
    '55151110',
    '55151130',
    '55151190',
    '55151210',
    '55151230',
    '55151290',
    '55151311',
    '55151319',
    '55151391',
    '55151399',
    '55151910',
    '55151930',
    '55151990',
    '55152110',
    '55152130',
    '55152190',
    '55152211',
    '55152219',
    '55152291',
    '55152299',
    '55152900',
    '55159110',
    '55159130',
    '55159190',
    '55159920',
    '55159940',
    '55159980',
    '55161100',
    '55161200',
    '55161300',
    '55161400',
    '55162100',
    '55162200',
    '55162310',
    '55162390',
    '55162400',
    '55163100',
    '55163200',
    '55163300',
    '55163400',
    '55164100',
    '55164200',
    '55164300',
    '55164400',
    '55169100',
    '55169200',
    '55169300',
    '55169400',
    '56012110',
    '56012190',
    '56012210',
    '56012290',
    '56012900',
    '56013000',
    '56021011',
    '56021019',
    '56021031',
    '56021038',
    '56021090',
    '56022100',
    '56022900',
    '56029000',
    '56031110',
    '56031190',
    '56031210',
    '56031290',
    '56031310',
    '56031390',
    '56031410',
    '56031420',
    '56031480',
    '56039110',
    '56039190',
    '56039210',
    '56039290',
    '56039310',
    '56039390',
    '56039410',
    '56039420',
    '56039480',
    '56041000',
    '56049010',
    '56049090',
    '56050000',
    '56060010',
    '56060091',
    '56060099',
    '56072100',
    '56072900',
    '56074100',
    '56074911',
    '56074919',
    '56074990',
    '56075011',
    '56075019',
    '56075030',
    '56075090',
    '56079020',
    '56079090',
    '56081120',
    '56081180',
    '56081911',
    '56081919',
    '56081930',
    '56081990',
    '56089000',
    '56090000',
    '57011010',
    '57011090',
    '57019010',
    '57019090',
    '57021000',
    '57022000',
    '57023110',
    '57023180',
    '57023200',
    '57023900',
    '57024110',
    '57024190',
    '57024200',
    '57024900',
    '57025010',
    '57025031',
    '57025039',
    '57025090',
    '57029100',
    '57029210',
    '57029290',
    '57029900',
    '57031000',
    '57032100',
    '57032910',
    '57032919',
    '57032991',
    '57032999',
    '57033100',
    '57033910',
    '57033919',
    '57033991',
    '57033999',
    '57039020',
    '57039080',
    '57041000',
    '57042000',
    '57049000',
    '57050030',
    '57050080',
    '58011000',
    '58012100',
    '58012200',
    '58012300',
    '58012600',
    '58012700',
    '58013100',
    '58013200',
    '58013300',
    '58013600',
    '58013700',
    '58019010',
    '58019090',
    '58021000',
    '58022000',
    '58023000',
    '58030010',
    '58030030',
    '58030090',
    '58041010',
    '58041090',
    '58042100',
    '58042900',
    '58043000',
    '58050000',
    '58061000',
    '58062000',
    '58063100',
    '58063210',
    '58063290',
    '58063900',
    '58064000',
    '58071010',
    '58071090',
    '58079010',
    '58079090',
    '58081000',
    '58089000',
    '58090000',
    '58101010',
    '58101090',
    '58109110',
    '58109190',
    '58109210',
    '58109290',
    '58109910',
    '58109990',
    '58110000',
    '59011000',
    '59019000',
    '59021010',
    '59021090',
    '59022010',
    '59022090',
    '59029010',
    '59029090',
    '59031010',
    '59031090',
    '59032010',
    '59032090',
    '59039010',
    '59039091',
    '59039099',
    '59041000',
    '59049000',
    '59050010',
    '59050030',
    '59050050',
    '59050070',
    '59050090',
    '59061000',
    '59069100',
    '59069910',
    '59069990',
    '59070000',
    '59080000',
    '59090010',
    '59090090',
    '59100000',
    '59111000',
    '59112000',
    '59113111',
    '59113119',
    '59113190',
    '59113211',
    '59113219',
    '59113290',
    '59114000',
    '59119010',
    '59119091',
    '59119099',
    '60011000',
    '60012100',
    '60012200',
    '60012900',
    '60019100',
    '60019200',
    '60019900',
    '60024000',
    '60029000',
    '60031000',
    '60032000',
    '60033010',
    '60033090',
    '60034000',
    '60039000',
    '60041000',
    '60049000',
    '60052100',
    '60052200',
    '60052300',
    '60052400',
    '60053500',
    '60053600',
    '60053700',
    '60053800',
    '60053900',
    '60054100',
    '60054200',
    '60054300',
    '60054400',
    '60059010',
    '60059090',
    '60061000',
    '60062100',
    '60062200',
    '60062300',
    '60062400',
    '60063100',
    '60063200',
    '60063300',
    '60063400',
    '60064100',
    '60064200',
    '60064300',
    '60064400',
    '60069000',
    '61012010',
    '61012090',
    '61013010',
    '61013090',
    '61019020',
    '61019080',
    '61021010',
    '61021090',
    '61022010',
    '61022090',
    '61023010',
    '61023090',
    '61029010',
    '61029090',
    '61031010',
    '61031090',
    '61032200',
    '61032300',
    '61032900',
    '61033100',
    '61033200',
    '61033300',
    '61033900',
    '61034100',
    '61034200',
    '61034300',
    '61034900',
    '61041300',
    '61041920',
    '61041990',
    '61042200',
    '61042300',
    '61042910',
    '61042990',
    '61043100',
    '61043200',
    '61043300',
    '61043900',
    '61044100',
    '61044200',
    '61044300',
    '61044400',
    '61044900',
    '61045100',
    '61045200',
    '61045300',
    '61045900',
    '61046100',
    '61046200',
    '61046300',
    '61046900',
    '61051000',
    '61052010',
    '61052090',
    '61059010',
    '61059090',
    '61061000',
    '61062000',
    '61069010',
    '61069030',
    '61069050',
    '61069090',
    '61071100',
    '61071200',
    '61071900',
    '61072100',
    '61072200',
    '61072900',
    '61079100',
    '61079900',
    '61081100',
    '61081900',
    '61082100',
    '61082200',
    '61082900',
    '61083100',
    '61083200',
    '61083900',
    '61089100',
    '61089200',
    '61089900',
    '61091000',
    '61099020',
    '61099090',
    '61101110',
    '61101130',
    '61101190',
    '61101210',
    '61101290',
    '61101910',
    '61101990',
    '61102010',
    '61102091',
    '61102099',
    '61103010',
    '61103091',
    '61103099',
    '61109010',
    '61109090',
    '61112010',
    '61112090',
    '61113010',
    '61113090',
    '61119011',
    '61119019',
    '61119090',
    '61121100',
    '61121200',
    '61121900',
    '61122000',
    '61123110',
    '61123190',
    '61123910',
    '61123990',
    '61124110',
    '61124190',
    '61124910',
    '61124990',
    '61130010',
    '61130090',
    '61142000',
    '61143000',
    '61149000',
    '61151010',
    '61151090',
    '61152100',
    '61152200',
    '61152900',
    '61153011',
    '61153019',
    '61153090',
    '61159400',
    '61159500',
    '61159610',
    '61159691',
    '61159699',
    '61159900',
    '61161020',
    '61161080',
    '61169100',
    '61169200',
    '61169300',
    '61169900',
    '61171000',
    '61178010',
    '61178080',
    '61179000',
    '62012000',
    '62013010',
    '62013090',
    '62014010',
    '62014090',
    '62019000',
    '62022000',
    '62023010',
    '62023090',
    '62024010',
    '62024090',
    '62029000',
    '62031100',
    '62031200',
    '62031910',
    '62031930',
    '62031990',
    '62032210',
    '62032280',
    '62032310',
    '62032380',
    '62032911',
    '62032918',
    '62032930',
    '62032990',
    '62033100',
    '62033210',
    '62033290',
    '62033310',
    '62033390',
    '62033911',
    '62033919',
    '62033990',
    '62034110',
    '62034130',
    '62034190',
    '62034211',
    '62034231',
    '62034233',
    '62034235',
    '62034251',
    '62034259',
    '62034290',
    '62034311',
    '62034319',
    '62034331',
    '62034339',
    '62034390',
    '62034911',
    '62034919',
    '62034931',
    '62034939',
    '62034950',
    '62034990',
    '62041100',
    '62041200',
    '62041300',
    '62041910',
    '62041990',
    '62042100',
    '62042210',
    '62042280',
    '62042310',
    '62042380',
    '62042911',
    '62042918',
    '62042990',
    '62043100',
    '62043210',
    '62043290',
    '62043310',
    '62043390',
    '62043911',
    '62043919',
    '62043990',
    '62044100',
    '62044200',
    '62044300',
    '62044400',
    '62044910',
    '62044990',
    '62045100',
    '62045200',
    '62045300',
    '62045910',
    '62045990',
    '62046110',
    '62046185',
    '62046211',
    '62046231',
    '62046233',
    '62046239',
    '62046251',
    '62046259',
    '62046290',
    '62046311',
    '62046318',
    '62046331',
    '62046339',
    '62046390',
    '62046911',
    '62046918',
    '62046931',
    '62046939',
    '62046950',
    '62046990',
    '62052000',
    '62053000',
    '62059010',
    '62059080',
    '62061000',
    '62062000',
    '62063000',
    '62064000',
    '62069010',
    '62069090',
    '62071100',
    '62071900',
    '62072100',
    '62072200',
    '62072900',
    '62079100',
    '62079910',
    '62079990',
    '62081100',
    '62081900',
    '62082100',
    '62082200',
    '62082900',
    '62089100',
    '62089200',
    '62089900',
    '62092000',
    '62093000',
    '62099010',
    '62099090',
    '62101010',
    '62101092',
    '62101098',
    '62102000',
    '62103000',
    '62104000',
    '62105000',
    '62111100',
    '62111200',
    '62112000',
    '62113210',
    '62113231',
    '62113241',
    '62113242',
    '62113290',
    '62113310',
    '62113331',
    '62113341',
    '62113342',
    '62113390',
    '62113900',
    '62114210',
    '62114231',
    '62114241',
    '62114242',
    '62114290',
    '62114310',
    '62114331',
    '62114341',
    '62114342',
    '62114390',
    '62114900',
    '62121010',
    '62121090',
    '62122000',
    '62123000',
    '62129000',
    '62132000',
    '62139000',
    '62141000',
    '62142000',
    '62143000',
    '62144000',
    '62149000',
    '62151000',
    '62152000',
    '62159000',
    '62160000',
    '62171000',
    '62179000',
    '63011000',
    '63012010',
    '63012090',
    '63013010',
    '63013090',
    '63014010',
    '63014090',
    '63019010',
    '63019090',
    '63021000',
    '63022100',
    '63022210',
    '63022290',
    '63022910',
    '63022990',
    '63023100',
    '63023210',
    '63023290',
    '63023920',
    '63023990',
    '63024000',
    '63025100',
    '63025310',
    '63025390',
    '63025910',
    '63025990',
    '63026000',
    '63029100',
    '63029310',
    '63029390',
    '63029910',
    '63029990',
    '63031200',
    '63031900',
    '63039100',
    '63039210',
    '63039290',
    '63039910',
    '63039990',
    '63041100',
    '63041910',
    '63041930',
    '63041990',
    '63042000',
    '63049100',
    '63049200',
    '63049300',
    '63049900',
    '63051010',
    '63051090',
    '63052000',
    '63053211',
    '63053219',
    '63053290',
    '63053310',
    '63053390',
    '63053900',
    '63059000',
    '63061200',
    '63061900',
    '63062200',
    '63062900',
    '63063000',
    '63064000',
    '63069000',
    '63071010',
    '63071030',
    '63071090',
    '63072000',
    '63079010',
    '63079091',
    '63079092',
    '63079093',
    '63079095',
    '63079098',
    '63080000',
    '63090000',
    '63101000',
    '63109000',
    '64011000',
    '64019210',
    '64019290',
    '64019900',
    '64021210',
    '64021290',
    '64021900',
    '64022000',
    '64029110',
    '64029190',
    '64029905',
    '64029910',
    '64029931',
    '64029939',
    '64029950',
    '64029991',
    '64029993',
    '64029996',
    '64029998',
    '64031200',
    '64031900',
    '64032000',
    '64034000',
    '64035105',
    '64035111',
    '64035115',
    '64035119',
    '64035191',
    '64035195',
    '64035199',
    '64035905',
    '64035911',
    '64035931',
    '64035935',
    '64035939',
    '64035950',
    '64035991',
    '64035995',
    '64035999',
    '64039105',
    '64039111',
    '64039113',
    '64039116',
    '64039118',
    '64039191',
    '64039193',
    '64039196',
    '64039198',
    '64039905',
    '64039911',
    '64039931',
    '64039933',
    '64039936',
    '64039938',
    '64039950',
    '64039991',
    '64039993',
    '64039996',
    '64039998',
    '64041100',
    '64041910',
    '64041990',
    '64042010',
    '64042090',
    '64051000',
    '64052010',
    '64052091',
    '64052099',
    '64059010',
    '64059090',
    '64061010',
    '64061090',
    '64062010',
    '64062090',
    '64069030',
    '64069050',
    '64069060',
    '64069090',
    '65010000',
    '65020000',
    '65040000',
    '65050010',
    '65050030',
    '65050090',
    '65061010',
    '65061080',
    '65069100',
    '65069910',
    '65069990',
    '65070000',
    '66011000',
    '66019100',
    '66019920',
    '66019990',
    '66020000',
    '66032000',
    '66039010',
    '66039090',
    '67010000',
    '67021000',
    '67029000',
    '67030000',
    '67041100',
    '67041900',
    '67042000',
    '67049000',
    '68010000',
    '68021000',
    '68022100',
    '68022300',
    '68022900',
    '68029100',
    '68029200',
    '68029310',
    '68029390',
    '68029910',
    '68029990',
    '68030010',
    '68030090',
    '68041000',
    '68042100',
    '68042212',
    '68042218',
    '68042230',
    '68042250',
    '68042290',
    '68042300',
    '68043000',
    '68051000',
    '68052000',
    '68053000',
    '68061000',
    '68062010',
    '68062090',
    '68069000',
    '68071000',
    '68079000',
    '68080000',
    '68091100',
    '68091900',
    '68099000',
    '68101110',
    '68101190',
    '68101900',
    '68109100',
    '68109900',
    '68114000',
    '68118100',
    '68118200',
    '68118900',
    '68128010',
    '68128090',
    '68129100',
    '68129910',
    '68129990',
    '68132000',
    '68138100',
    '68138900',
    '68141000',
    '68149000',
    '68151100',
    '68151200',
    '68151300',
    '68151900',
    '68152000',
    '68159100',
    '68159900',
    '69010000',
    '69021000',
    '69022010',
    '69022091',
    '69022099',
    '69029000',
    '69031000',
    '69032010',
    '69032090',
    '69039010',
    '69039090',
    '69041000',
    '69049000',
    '69051000',
    '69059000',
    '69060000',
    '69072100',
    '69072200',
    '69072300',
    '69073000',
    '69074000',
    '69091100',
    '69091200',
    '69091900',
    '69099000',
    '69101000',
    '69109000',
    '69111000',
    '69119000',
    '69120021',
    '69120023',
    '69120025',
    '69120029',
    '69120081',
    '69120083',
    '69120085',
    '69120089',
    '69131000',
    '69139010',
    '69139093',
    '69139098',
    '69141000',
    '69149000',
    '70010010',
    '70010091',
    '70010099',
    '70021000',
    '70022010',
    '70022090',
    '70023100',
    '70023200',
    '70023900',
    '70031210',
    '70031291',
    '70031299',
    '70031910',
    '70031990',
    '70032000',
    '70033000',
    '70042010',
    '70042091',
    '70042099',
    '70049010',
    '70049080',
    '70051005',
    '70051025',
    '70051030',
    '70051080',
    '70052125',
    '70052130',
    '70052180',
    '70052925',
    '70052935',
    '70052980',
    '70053000',
    '70060010',
    '70060090',
    '70071110',
    '70071190',
    '70071910',
    '70071920',
    '70071980',
    '70072120',
    '70072180',
    '70072900',
    '70080020',
    '70080081',
    '70080089',
    '70091000',
    '70099100',
    '70099200',
    '70101000',
    '70102000',
    '70109010',
    '70109021',
    '70109031',
    '70109041',
    '70109043',
    '70109045',
    '70109047',
    '70109051',
    '70109053',
    '70109055',
    '70109057',
    '70109061',
    '70109067',
    '70109071',
    '70109079',
    '70109091',
    '70109099',
    '70111000',
    '70112000',
    '70119000',
    '70131000',
    '70132210',
    '70132290',
    '70132810',
    '70132890',
    '70133311',
    '70133319',
    '70133391',
    '70133399',
    '70133710',
    '70133751',
    '70133759',
    '70133791',
    '70133799',
    '70134110',
    '70134190',
    '70134200',
    '70134910',
    '70134991',
    '70134999',
    '70139110',
    '70139190',
    '70139900',
    '70140000',
    '70151000',
    '70159000',
    '70161000',
    '70169010',
    '70169040',
    '70169070',
    '70171000',
    '70172000',
    '70179000',
    '70181011',
    '70181019',
    '70181030',
    '70181051',
    '70181059',
    '70181090',
    '70182000',
    '70189010',
    '70189090',
    '70191100',
    '70191200',
    '70191300',
    '70191400',
    '70191500',
    '70191900',
    '70196100',
    '70196210',
    '70196290',
    '70196300',
    '70196400',
    '70196500',
    '70196600',
    '70196910',
    '70196990',
    '70197100',
    '70197200',
    '70197300',
    '70198010',
    '70198090',
    '70199000',
    '70200005',
    '70200007',
    '70200008',
    '70200010',
    '70200030',
    '70200080',
    '71011000',
    '71012100',
    '71012200',
    '71021000',
    '71022100',
    '71022900',
    '71023100',
    '71023900',
    '71031000',
    '71039100',
    '71039900',
    '71041000',
    '71042100',
    '71042900',
    '71049100',
    '71049900',
    '71051000',
    '71059000',
    '71061000',
    '71069100',
    '71069200',
    '71070000',
    '71081100',
    '71081200',
    '71081310',
    '71081380',
    '71082000',
    '71090000',
    '71101100',
    '71101910',
    '71101980',
    '71102100',
    '71102900',
    '71103100',
    '71103900',
    '71104100',
    '71104900',
    '71110000',
    '71123000',
    '71129100',
    '71129200',
    '71129900',
    '71131100',
    '71131900',
    '71132000',
    '71141100',
    '71141900',
    '71142000',
    '71151000',
    '71159000',
    '71161000',
    '71162011',
    '71162080',
    '71171100',
    '71171900',
    '71179000',
    '71181000',
    '71189000',
    '72011011',
    '72011019',
    '72011030',
    '72011090',
    '72012000',
    '72015010',
    '72015090',
    '72021120',
    '72021180',
    '72021900',
    '72022100',
    '72022910',
    '72022990',
    '72023000',
    '72024110',
    '72024190',
    '72024910',
    '72024950',
    '72024990',
    '72025000',
    '72026000',
    '72027000',
    '72028000',
    '72029100',
    '72029200',
    '72029300',
    '72029910',
    '72029930',
    '72029980',
    '72031000',
    '72039000',
    '72041000',
    '72042110',
    '72042190',
    '72042900',
    '72043000',
    '72044110',
    '72044191',
    '72044199',
    '72044910',
    '72044930',
    '72044990',
    '72045000',
    '72051000',
    '72052100',
    '72052900',
    '72061000',
    '72069000',
    '72071111',
    '72071114',
    '72071116',
    '72071190',
    '72071210',
    '72071290',
    '72071912',
    '72071919',
    '72071980',
    '72072011',
    '72072015',
    '72072017',
    '72072019',
    '72072032',
    '72072039',
    '72072052',
    '72072059',
    '72072080',
    '72081000',
    '72082500',
    '72082600',
    '72082700',
    '72083600',
    '72083700',
    '72083800',
    '72083900',
    '72084000',
    '72085120',
    '72085191',
    '72085198',
    '72085210',
    '72085291',
    '72085299',
    '72085310',
    '72085390',
    '72085400',
    '72089020',
    '72089080',
    '72091500',
    '72091610',
    '72091690',
    '72091710',
    '72091790',
    '72091810',
    '72091891',
    '72091899',
    '72092500',
    '72092610',
    '72092690',
    '72092710',
    '72092790',
    '72092810',
    '72092890',
    '72099020',
    '72099080',
    '72101100',
    '72101220',
    '72101280',
    '72102000',
    '72103000',
    '72104100',
    '72104900',
    '72105000',
    '72106100',
    '72106900',
    '72107010',
    '72107080',
    '72109030',
    '72109040',
    '72109080',
    '72111300',
    '72111400',
    '72111900',
    '72112320',
    '72112330',
    '72112380',
    '72112900',
    '72119020',
    '72119080',
    '72121010',
    '72121090',
    '72122000',
    '72123000',
    '72124020',
    '72124080',
    '72125020',
    '72125030',
    '72125040',
    '72125061',
    '72125069',
    '72125090',
    '72126000',
    '72131000',
    '72132000',
    '72139110',
    '72139120',
    '72139141',
    '72139149',
    '72139170',
    '72139190',
    '72139910',
    '72139990',
    '72141000',
    '72142000',
    '72143000',
    '72149110',
    '72149190',
    '72149910',
    '72149931',
    '72149939',
    '72149950',
    '72149971',
    '72149979',
    '72149995',
    '72151000',
    '72155011',
    '72155019',
    '72155080',
    '72159000',
    '72161000',
    '72162100',
    '72162200',
    '72163110',
    '72163190',
    '72163211',
    '72163219',
    '72163291',
    '72163299',
    '72163310',
    '72163390',
    '72164010',
    '72164090',
    '72165010',
    '72165091',
    '72165099',
    '72166110',
    '72166190',
    '72166900',
    '72169110',
    '72169180',
    '72169900',
    '72171010',
    '72171031',
    '72171039',
    '72171050',
    '72171090',
    '72172010',
    '72172030',
    '72172050',
    '72172090',
    '72173041',
    '72173049',
    '72173050',
    '72173090',
    '72179020',
    '72179050',
    '72179090',
    '72181000',
    '72189110',
    '72189180',
    '72189911',
    '72189919',
    '72189920',
    '72189980',
    '72191100',
    '72191210',
    '72191290',
    '72191310',
    '72191390',
    '72191410',
    '72191490',
    '72192110',
    '72192190',
    '72192210',
    '72192290',
    '72192300',
    '72192400',
    '72193100',
    '72193210',
    '72193290',
    '72193310',
    '72193390',
    '72193410',
    '72193490',
    '72193510',
    '72193590',
    '72199020',
    '72199080',
    '72201100',
    '72201200',
    '72202021',
    '72202029',
    '72202041',
    '72202049',
    '72202081',
    '72202089',
    '72209020',
    '72209080',
    '72210010',
    '72210090',
    '72221111',
    '72221119',
    '72221181',
    '72221189',
    '72221910',
    '72221990',
    '72222011',
    '72222019',
    '72222021',
    '72222029',
    '72222031',
    '72222039',
    '72222081',
    '72222089',
    '72223051',
    '72223091',
    '72223097',
    '72224010',
    '72224050',
    '72224090',
    '72230011',
    '72230019',
    '72230091',
    '72230099',
    '72241010',
    '72241090',
    '72249002',
    '72249003',
    '72249005',
    '72249007',
    '72249014',
    '72249018',
    '72249031',
    '72249038',
    '72249090',
    '72251100',
    '72251910',
    '72251990',
    '72253010',
    '72253030',
    '72253090',
    '72254012',
    '72254015',
    '72254040',
    '72254060',
    '72254090',
    '72255020',
    '72255080',
    '72259100',
    '72259200',
    '72259900',
    '72261100',
    '72261910',
    '72261980',
    '72262000',
    '72269120',
    '72269191',
    '72269199',
    '72269200',
    '72269910',
    '72269930',
    '72269970',
    '72271000',
    '72272000',
    '72279010',
    '72279050',
    '72279095',
    '72281020',
    '72281050',
    '72281090',
    '72282010',
    '72282091',
    '72282099',
    '72283020',
    '72283041',
    '72283049',
    '72283061',
    '72283069',
    '72283070',
    '72283089',
    '72284010',
    '72284090',
    '72285020',
    '72285040',
    '72285061',
    '72285069',
    '72285080',
    '72286020',
    '72286080',
    '72287010',
    '72287090',
    '72288000',
    '72292000',
    '72299020',
    '72299050',
    '72299090',
    '73011000',
    '73012000',
    '73021010',
    '73021022',
    '73021028',
    '73021040',
    '73021050',
    '73021090',
    '73023000',
    '73024000',
    '73029000',
    '73030010',
    '73030090',
    '73041100',
    '73041910',
    '73041930',
    '73041990',
    '73042200',
    '73042300',
    '73042400',
    '73042910',
    '73042930',
    '73042990',
    '73043120',
    '73043180',
    '73043950',
    '73043982',
    '73043983',
    '73043988',
    '73044100',
    '73044983',
    '73044985',
    '73044989',
    '73045110',
    '73045181',
    '73045189',
    '73045930',
    '73045982',
    '73045983',
    '73045989',
    '73049000',
    '73051100',
    '73051200',
    '73051900',
    '73052000',
    '73053100',
    '73053900',
    '73059000',
    '73061100',
    '73061900',
    '73062100',
    '73062900',
    '73063012',
    '73063018',
    '73063041',
    '73063049',
    '73063072',
    '73063077',
    '73063080',
    '73064020',
    '73064080',
    '73065021',
    '73065029',
    '73065080',
    '73066110',
    '73066192',
    '73066199',
    '73066910',
    '73066990',
    '73069000',
    '73071110',
    '73071190',
    '73071910',
    '73071990',
    '73072100',
    '73072210',
    '73072290',
    '73072310',
    '73072390',
    '73072910',
    '73072980',
    '73079100',
    '73079210',
    '73079290',
    '73079311',
    '73079319',
    '73079391',
    '73079399',
    '73079910',
    '73079980',
    '73081000',
    '73082000',
    '73083000',
    '73084000',
    '73089051',
    '73089059',
    '73089098',
    '73090010',
    '73090030',
    '73090051',
    '73090059',
    '73090090',
    '73101000',
    '73102111',
    '73102119',
    '73102191',
    '73102199',
    '73102910',
    '73102990',
    '73110011',
    '73110013',
    '73110019',
    '73110030',
    '73110091',
    '73110099',
    '73121020',
    '73121041',
    '73121049',
    '73121061',
    '73121065',
    '73121069',
    '73121081',
    '73121083',
    '73121085',
    '73121089',
    '73121098',
    '73129000',
    '73130000',
    '73141200',
    '73141400',
    '73141900',
    '73142010',
    '73142090',
    '73143100',
    '73143900',
    '73144100',
    '73144200',
    '73144900',
    '73145000',
    '73151110',
    '73151190',
    '73151200',
    '73151900',
    '73152000',
    '73158100',
    '73158200',
    '73158900',
    '73159000',
    '73160000',
    '73170020',
    '73170060',
    '73170080',
    '73181100',
    '73181210',
    '73181290',
    '73181300',
    '73181410',
    '73181491',
    '73181499',
    '73181520',
    '73181535',
    '73181542',
    '73181548',
    '73181552',
    '73181558',
    '73181562',
    '73181568',
    '73181575',
    '73181582',
    '73181588',
    '73181595',
    '73181631',
    '73181639',
    '73181640',
    '73181660',
    '73181692',
    '73181699',
    '73181900',
    '73182100',
    '73182200',
    '73182300',
    '73182400',
    '73182900',
    '73194000',
    '73199010',
    '73199090',
    '73201011',
    '73201019',
    '73201090',
    '73202020',
    '73202081',
    '73202085',
    '73202089',
    '73209010',
    '73209030',
    '73209090',
    '73211110',
    '73211190',
    '73211200',
    '73211900',
    '73218100',
    '73218200',
    '73218900',
    '73219000',
    '73221100',
    '73221900',
    '73229000',
    '73231000',
    '73239100',
    '73239200',
    '73239300',
    '73239400',
    '73239900',
    '73241000',
    '73242100',
    '73242900',
    '73249000',
    '73251000',
    '73259100',
    '73259910',
    '73259990',
    '73261100',
    '73261910',
    '73261990',
    '73262000',
    '73269030',
    '73269040',
    '73269050',
    '73269060',
    '73269092',
    '73269094',
    '73269096',
    '73269098',
    '74010000',
    '74020000',
    '74031100',
    '74031200',
    '74031300',
    '74031900',
    '74032100',
    '74032200',
    '74032900',
    '74040010',
    '74040091',
    '74040099',
    '74050000',
    '74061000',
    '74062000',
    '74071000',
    '74072110',
    '74072190',
    '74072900',
    '74081100',
    '74081910',
    '74081990',
    '74082100',
    '74082200',
    '74082900',
    '74091100',
    '74091900',
    '74092100',
    '74092900',
    '74093100',
    '74093900',
    '74094000',
    '74099000',
    '74101100',
    '74101200',
    '74102100',
    '74102200',
    '74111010',
    '74111090',
    '74112110',
    '74112190',
    '74112200',
    '74112900',
    '74121000',
    '74122000',
    '74130000',
    '74151000',
    '74152100',
    '74152900',
    '74153300',
    '74153900',
    '74181010',
    '74181090',
    '74182000',
    '74192000',
    '74198010',
    '74198030',
    '74198090',
    '75011000',
    '75012000',
    '75021000',
    '75022000',
    '75030010',
    '75030090',
    '75040000',
    '75051100',
    '75051200',
    '75052100',
    '75052200',
    '75061000',
    '75062000',
    '75071100',
    '75071200',
    '75072000',
    '75081000',
    '75089000',
    '76011010',
    '76011090',
    '76012030',
    '76012040',
    '76012080',
    '76020011',
    '76020019',
    '76020090',
    '76031000',
    '76032000',
    '76041010',
    '76041090',
    '76042100',
    '76042910',
    '76042990',
    '76051100',
    '76051900',
    '76052100',
    '76052900',
    '76061130',
    '76061150',
    '76061191',
    '76061193',
    '76061199',
    '76061211',
    '76061219',
    '76061230',
    '76061250',
    '76061292',
    '76061293',
    '76061299',
    '76069100',
    '76069200',
    '76071111',
    '76071119',
    '76071190',
    '76071910',
    '76071990',
    '76072010',
    '76072091',
    '76072099',
    '76081000',
    '76082020',
    '76082081',
    '76082089',
    '76090000',
    '76101000',
    '76109010',
    '76109090',
    '76110000',
    '76121000',
    '76129020',
    '76129030',
    '76129080',
    '76130000',
    '76141000',
    '76149000',
    '76151010',
    '76151030',
    '76151080',
    '76152000',
    '76161000',
    '76169100',
    '76169910',
    '76169990',
    '78011000',
    '78019100',
    '78019910',
    '78019990',
    '78020000',
    '78041100',
    '78041900',
    '78042000',
    '78060010',
    '78060080',
    '79011100',
    '79011210',
    '79011230',
    '79011290',
    '79012000',
    '79020000',
    '79031000',
    '79039000',
    '79040000',
    '79050000',
    '79070000',
    '80011000',
    '80012000',
    '80020000',
    '80030000',
    '80070010',
    '80070080',
    '81011000',
    '81019400',
    '81019600',
    '81019700',
    '81019910',
    '81019990',
    '81021000',
    '81029400',
    '81029500',
    '81029600',
    '81029700',
    '81029900',
    '81032000',
    '81033000',
    '81039100',
    '81039910',
    '81039990',
    '81041100',
    '81041900',
    '81042000',
    '81043000',
    '81049000',
    '81052000',
    '81053000',
    '81059000',
    '81061010',
    '81061090',
    '81069010',
    '81069090',
    '81082000',
    '81083000',
    '81089030',
    '81089050',
    '81089060',
    '81089090',
    '81092100',
    '81092900',
    '81093100',
    '81093900',
    '81099100',
    '81099900',
    '81101000',
    '81102000',
    '81109000',
    '81110011',
    '81110019',
    '81110090',
    '81121200',
    '81121300',
    '81121900',
    '81122110',
    '81122190',
    '81122200',
    '81122900',
    '81123100',
    '81123900',
    '81124110',
    '81124190',
    '81124900',
    '81125100',
    '81125200',
    '81125900',
    '81126100',
    '81126910',
    '81126990',
    '81129221',
    '81129240',
    '81129281',
    '81129289',
    '81129291',
    '81129295',
    '81129940',
    '81129950',
    '81129970',
    '81130020',
    '81130040',
    '81130090',
    '82011000',
    '82013000',
    '82014000',
    '82015000',
    '82016000',
    '82019000',
    '82021000',
    '82022000',
    '82023100',
    '82023900',
    '82024000',
    '82029100',
    '82029920',
    '82029980',
    '82031000',
    '82032000',
    '82033000',
    '82034000',
    '82041100',
    '82041200',
    '82042000',
    '82051000',
    '82052000',
    '82053000',
    '82054000',
    '82055100',
    '82055910',
    '82055980',
    '82056000',
    '82057000',
    '82059010',
    '82059090',
    '82060000',
    '82071300',
    '82071910',
    '82071990',
    '82072010',
    '82072090',
    '82073010',
    '82073090',
    '82074010',
    '82074030',
    '82074090',
    '82075010',
    '82075030',
    '82075050',
    '82075060',
    '82075070',
    '82075090',
    '82076010',
    '82076030',
    '82076050',
    '82076070',
    '82076090',
    '82077010',
    '82077031',
    '82077037',
    '82077090',
    '82078011',
    '82078019',
    '82078090',
    '82079010',
    '82079030',
    '82079050',
    '82079071',
    '82079078',
    '82079091',
    '82079099',
    '82081000',
    '82082000',
    '82083000',
    '82084000',
    '82089000',
    '82090020',
    '82090080',
    '82100000',
    '82111000',
    '82119100',
    '82119200',
    '82119300',
    '82119400',
    '82119500',
    '82121010',
    '82121090',
    '82122000',
    '82129000',
    '82130000',
    '82141000',
    '82142000',
    '82149000',
    '82151020',
    '82151030',
    '82151080',
    '82152010',
    '82152090',
    '82159100',
    '82159910',
    '82159990',
    '83011000',
    '83012000',
    '83013000',
    '83014011',
    '83014019',
    '83014090',
    '83015000',
    '83016000',
    '83017000',
    '83021000',
    '83022000',
    '83023000',
    '83024110',
    '83024150',
    '83024190',
    '83024200',
    '83024900',
    '83025000',
    '83026000',
    '83030040',
    '83030090',
    '83040000',
    '83051000',
    '83052000',
    '83059000',
    '83061000',
    '83062100',
    '83062900',
    '83063000',
    '83071000',
    '83079000',
    '83081000',
    '83082000',
    '83089000',
    '83091000',
    '83099010',
    '83099090',
    '83100000',
    '83111000',
    '83112000',
    '83113000',
    '83119000',
    '84011000',
    '84012000',
    '84013000',
    '84014000',
    '84021100',
    '84021200',
    '84021910',
    '84021990',
    '84022000',
    '84029000',
    '84031010',
    '84031090',
    '84039010',
    '84039090',
    '84041000',
    '84042000',
    '84049000',
    '84051000',
    '84059000',
    '84061000',
    '84068100',
    '84068200',
    '84069010',
    '84069090',
    '84071000',
    '84072110',
    '84072191',
    '84072199',
    '84072900',
    '84073100',
    '84073210',
    '84073290',
    '84073320',
    '84073380',
    '84073410',
    '84073430',
    '84073491',
    '84073499',
    '84079010',
    '84079050',
    '84079080',
    '84079090',
    '84081011',
    '84081019',
    '84081023',
    '84081027',
    '84081031',
    '84081039',
    '84081041',
    '84081049',
    '84081051',
    '84081059',
    '84081061',
    '84081069',
    '84081071',
    '84081079',
    '84081081',
    '84081089',
    '84081091',
    '84081099',
    '84082010',
    '84082031',
    '84082035',
    '84082037',
    '84082051',
    '84082055',
    '84082057',
    '84082099',
    '84089021',
    '84089027',
    '84089041',
    '84089043',
    '84089045',
    '84089047',
    '84089061',
    '84089065',
    '84089067',
    '84089081',
    '84089085',
    '84089089',
    '84091000',
    '84099100',
    '84099900',
    '84101100',
    '84101200',
    '84101300',
    '84109000',
    '84111100',
    '84111210',
    '84111230',
    '84111280',
    '84112100',
    '84112220',
    '84112280',
    '84118100',
    '84118220',
    '84118260',
    '84118280',
    '84119100',
    '84119900',
    '84121000',
    '84122120',
    '84122180',
    '84122920',
    '84122981',
    '84122989',
    '84123100',
    '84123900',
    '84128010',
    '84128080',
    '84129020',
    '84129040',
    '84129080',
    '84131100',
    '84131900',
    '84132000',
    '84133020',
    '84133080',
    '84134000',
    '84135020',
    '84135040',
    '84135061',
    '84135069',
    '84135080',
    '84136020',
    '84136031',
    '84136039',
    '84136061',
    '84136069',
    '84136070',
    '84136080',
    '84137021',
    '84137029',
    '84137030',
    '84137035',
    '84137045',
    '84137051',
    '84137059',
    '84137065',
    '84137075',
    '84137081',
    '84137089',
    '84138100',
    '84138200',
    '84139100',
    '84139200',
    '84141015',
    '84141025',
    '84141081',
    '84141089',
    '84142020',
    '84142080',
    '84143020',
    '84143081',
    '84143089',
    '84144010',
    '84144090',
    '84145100',
    '84145915',
    '84145925',
    '84145935',
    '84145995',
    '84146000',
    '84147000',
    '84148011',
    '84148019',
    '84148022',
    '84148028',
    '84148051',
    '84148059',
    '84148073',
    '84148075',
    '84148078',
    '84148080',
    '84149000',
    '84151010',
    '84151090',
    '84152000',
    '84158100',
    '84158200',
    '84158300',
    '84159000',
    '84161010',
    '84161090',
    '84162010',
    '84162020',
    '84162080',
    '84163000',
    '84169000',
    '84171000',
    '84172010',
    '84172090',
    '84178030',
    '84178050',
    '84178070',
    '84179000',
    '84181020',
    '84181080',
    '84182110',
    '84182151',
    '84182159',
    '84182191',
    '84182199',
    '84182900',
    '84183020',
    '84183080',
    '84184020',
    '84184080',
    '84185011',
    '84185019',
    '84185090',
    '84186100',
    '84186900',
    '84189100',
    '84189910',
    '84189990',
    '84191100',
    '84191200',
    '84191900',
    '84192000',
    '84193300',
    '84193400',
    '84193500',
    '84193900',
    '84194000',
    '84195020',
    '84195080',
    '84196000',
    '84198120',
    '84198180',
    '84198910',
    '84198930',
    '84198998',
    '84199015',
    '84199085',
    '84201010',
    '84201030',
    '84201081',
    '84201089',
    '84209110',
    '84209180',
    '84209900',
    '84211100',
    '84211200',
    '84211920',
    '84211970',
    '84212100',
    '84212200',
    '84212300',
    '84212920',
    '84212980',
    '84213100',
    '84213200',
    '84213915',
    '84213925',
    '84213935',
    '84213985',
    '84219100',
    '84219910',
    '84219990',
    '84221100',
    '84221900',
    '84222000',
    '84223000',
    '84224000',
    '84229010',
    '84229090',
    '84231010',
    '84231090',
    '84232010',
    '84232090',
    '84233010',
    '84233090',
    '84238121',
    '84238123',
    '84238125',
    '84238129',
    '84238180',
    '84238220',
    '84238281',
    '84238289',
    '84238920',
    '84238980',
    '84239010',
    '84239090',
    '84241000',
    '84242000',
    '84243001',
    '84243008',
    '84243010',
    '84243090',
    '84244100',
    '84244910',
    '84244990',
    '84248210',
    '84248290',
    '84248940',
    '84248970',
    '84249020',
    '84249080',
    '84251100',
    '84251900',
    '84253100',
    '84253900',
    '84254100',
    '84254200',
    '84254900',
    '84261100',
    '84261200',
    '84261900',
    '84262000',
    '84263000',
    '84264100',
    '84264900',
    '84269110',
    '84269190',
    '84269900',
    '84271010',
    '84271090',
    '84272011',
    '84272019',
    '84272090',
    '84279000',
    '84281020',
    '84281080',
    '84282020',
    '84282080',
    '84283100',
    '84283200',
    '84283300',
    '84283920',
    '84283990',
    '84284000',
    '84286000',
    '84287000',
    '84289071',
    '84289079',
    '84289090',
    '84291100',
    '84291900',
    '84292000',
    '84293000',
    '84294010',
    '84294030',
    '84294090',
    '84295110',
    '84295191',
    '84295199',
    '84295210',
    '84295290',
    '84295900',
    '84301000',
    '84302000',
    '84303100',
    '84303900',
    '84304100',
    '84304900',
    '84305000',
    '84306100',
    '84306900',
    '84311000',
    '84312000',
    '84313100',
    '84313900',
    '84314100',
    '84314200',
    '84314300',
    '84314920',
    '84314980',
    '84321000',
    '84322100',
    '84322910',
    '84322930',
    '84322950',
    '84322990',
    '84323100',
    '84323911',
    '84323919',
    '84323990',
    '84324100',
    '84324200',
    '84328000',
    '84329000',
    '84331110',
    '84331151',
    '84331159',
    '84331190',
    '84331910',
    '84331951',
    '84331959',
    '84331970',
    '84331990',
    '84332010',
    '84332050',
    '84332090',
    '84333000',
    '84334000',
    '84335100',
    '84335200',
    '84335310',
    '84335330',
    '84335390',
    '84335911',
    '84335919',
    '84335985',
    '84336000',
    '84339000',
    '84341000',
    '84342000',
    '84349000',
    '84351000',
    '84359000',
    '84361000',
    '84362100',
    '84362900',
    '84368010',
    '84368090',
    '84369100',
    '84369900',
    '84371000',
    '84378000',
    '84379000',
    '84381010',
    '84381090',
    '84382000',
    '84383000',
    '84384000',
    '84385000',
    '84386000',
    '84388010',
    '84388091',
    '84388099',
    '84389000',
    '84391000',
    '84392000',
    '84393000',
    '84399100',
    '84399900',
    '84401010',
    '84401020',
    '84401030',
    '84401040',
    '84401090',
    '84409000',
    '84411010',
    '84411020',
    '84411030',
    '84411070',
    '84412000',
    '84413000',
    '84414000',
    '84418000',
    '84419010',
    '84419090',
    '84423000',
    '84424000',
    '84425000',
    '84431100',
    '84431200',
    '84431310',
    '84431332',
    '84431334',
    '84431338',
    '84431390',
    '84431400',
    '84431500',
    '84431600',
    '84431700',
    '84431920',
    '84431940',
    '84431970',
    '84433100',
    '84433210',
    '84433280',
    '84433900',
    '84439110',
    '84439191',
    '84439199',
    '84439910',
    '84439990',
    '84440010',
    '84440090',
    '84451100',
    '84451200',
    '84451300',
    '84451900',
    '84452000',
    '84453000',
    '84454000',
    '84459000',
    '84461000',
    '84462100',
    '84462900',
    '84463000',
    '84471100',
    '84471200',
    '84472020',
    '84472080',
    '84479000',
    '84481100',
    '84481900',
    '84482000',
    '84483100',
    '84483200',
    '84483300',
    '84483900',
    '84484200',
    '84484900',
    '84485110',
    '84485190',
    '84485900',
    '84490000',
    '84501111',
    '84501119',
    '84501190',
    '84501200',
    '84501900',
    '84502000',
    '84509000',
    '84511000',
    '84512100',
    '84512900',
    '84513000',
    '84514000',
    '84515000',
    '84518010',
    '84518030',
    '84518080',
    '84519000',
    '84521011',
    '84521019',
    '84521090',
    '84522100',
    '84522900',
    '84523000',
    '84529000',
    '84531000',
    '84532000',
    '84538000',
    '84539000',
    '84541000',
    '84542000',
    '84543010',
    '84543090',
    '84549000',
    '84551000',
    '84552100',
    '84552200',
    '84553010',
    '84553031',
    '84553039',
    '84553090',
    '84559000',
    '84561110',
    '84561190',
    '84561210',
    '84561290',
    '84562000',
    '84563011',
    '84563019',
    '84563090',
    '84564000',
    '84565000',
    '84569000',
    '84571010',
    '84571090',
    '84572000',
    '84573010',
    '84573090',
    '84581120',
    '84581141',
    '84581149',
    '84581180',
    '84581900',
    '84589120',
    '84589180',
    '84589900',
    '84591000',
    '84592100',
    '84592900',
    '84593100',
    '84593900',
    '84594100',
    '84594900',
    '84595100',
    '84595900',
    '84596110',
    '84596190',
    '84596910',
    '84596990',
    '84597000',
    '84601200',
    '84601900',
    '84602200',
    '84602300',
    '84602400',
    '84602910',
    '84602990',
    '84603100',
    '84603900',
    '84604010',
    '84604090',
    '84609000',
    '84612000',
    '84613010',
    '84613090',
    '84614011',
    '84614019',
    '84614031',
    '84614039',
    '84614071',
    '84614079',
    '84614090',
    '84615011',
    '84615019',
    '84615090',
    '84619000',
    '84621110',
    '84621190',
    '84621910',
    '84621990',
    '84622210',
    '84622290',
    '84622300',
    '84622400',
    '84622500',
    '84622600',
    '84622900',
    '84623210',
    '84623290',
    '84623300',
    '84623900',
    '84624200',
    '84624900',
    '84625100',
    '84625900',
    '84626110',
    '84626190',
    '84626210',
    '84626290',
    '84626310',
    '84626390',
    '84626910',
    '84626990',
    '84629010',
    '84629090',
    '84631010',
    '84631090',
    '84632000',
    '84633000',
    '84639000',
    '84641000',
    '84642011',
    '84642019',
    '84642080',
    '84649000',
    '84651010',
    '84651090',
    '84652000',
    '84659110',
    '84659120',
    '84659190',
    '84659200',
    '84659300',
    '84659400',
    '84659500',
    '84659600',
    '84659900',
    '84661020',
    '84661031',
    '84661038',
    '84661080',
    '84662020',
    '84662091',
    '84662098',
    '84663000',
    '84669120',
    '84669195',
    '84669220',
    '84669280',
    '84669340',
    '84669350',
    '84669360',
    '84669400',
    '84671110',
    '84671190',
    '84671900',
    '84672110',
    '84672191',
    '84672199',
    '84672210',
    '84672230',
    '84672290',
    '84672920',
    '84672951',
    '84672953',
    '84672959',
    '84672970',
    '84672980',
    '84672985',
    '84678100',
    '84678900',
    '84679100',
    '84679200',
    '84679900',
    '84681000',
    '84682000',
    '84688000',
    '84689000',
    '84701000',
    '84702100',
    '84702900',
    '84703000',
    '84705000',
    '84709000',
    '84713000',
    '84714100',
    '84714900',
    '84715000',
    '84716060',
    '84716070',
    '84717020',
    '84717030',
    '84717050',
    '84717070',
    '84717080',
    '84717098',
    '84718000',
    '84719000',
    '84721000',
    '84723000',
    '84729010',
    '84729080',
    '84732110',
    '84732190',
    '84732910',
    '84732990',
    '84733020',
    '84733080',
    '84734010',
    '84734080',
    '84735020',
    '84735080',
    '84741000',
    '84742000',
    '84743100',
    '84743200',
    '84743900',
    '84748010',
    '84748090',
    '84749010',
    '84749090',
    '84751000',
    '84752100',
    '84752900',
    '84759010',
    '84759090',
    '84762100',
    '84762900',
    '84768100',
    '84768910',
    '84768990',
    '84769010',
    '84769090',
    '84771000',
    '84772000',
    '84773000',
    '84774000',
    '84775100',
    '84775910',
    '84775980',
    '84778011',
    '84778019',
    '84778091',
    '84778093',
    '84778095',
    '84778099',
    '84779010',
    '84779080',
    '84781000',
    '84789000',
    '84791000',
    '84792000',
    '84793010',
    '84793090',
    '84794000',
    '84795000',
    '84796000',
    '84797100',
    '84797900',
    '84798100',
    '84798200',
    '84798300',
    '84798930',
    '84798960',
    '84798970',
    '84798997',
    '84799015',
    '84799020',
    '84799070',
    '84801000',
    '84802000',
    '84803010',
    '84803090',
    '84804100',
    '84804900',
    '84805000',
    '84806000',
    '84807100',
    '84807900',
    '84811005',
    '84811019',
    '84811099',
    '84812010',
    '84812090',
    '84813091',
    '84813099',
    '84814010',
    '84814090',
    '84818011',
    '84818019',
    '84818031',
    '84818039',
    '84818040',
    '84818051',
    '84818059',
    '84818061',
    '84818063',
    '84818069',
    '84818071',
    '84818073',
    '84818079',
    '84818081',
    '84818085',
    '84818087',
    '84818099',
    '84819000',
    '84821010',
    '84821090',
    '84822000',
    '84823000',
    '84824000',
    '84825000',
    '84828000',
    '84829110',
    '84829190',
    '84829900',
    '84831021',
    '84831025',
    '84831029',
    '84831050',
    '84831095',
    '84832000',
    '84833032',
    '84833038',
    '84833080',
    '84834021',
    '84834023',
    '84834025',
    '84834029',
    '84834030',
    '84834051',
    '84834059',
    '84834090',
    '84835020',
    '84835080',
    '84836020',
    '84836080',
    '84839020',
    '84839081',
    '84839089',
    '84841000',
    '84842000',
    '84849000',
    '84851000',
    '84852000',
    '84853010',
    '84853090',
    '84858010',
    '84858090',
    '84859010',
    '84859090',
    '84861000',
    '84862000',
    '84863000',
    '84864000',
    '84869000',
    '84871010',
    '84871090',
    '84879040',
    '84879051',
    '84879057',
    '84879059',
    '84879090',
    '85011010',
    '85011091',
    '85011093',
    '85011099',
    '85012000',
    '85013100',
    '85013200',
    '85013300',
    '85013400',
    '85014020',
    '85014080',
    '85015100',
    '85015220',
    '85015230',
    '85015290',
    '85015350',
    '85015381',
    '85015394',
    '85015399',
    '85016120',
    '85016180',
    '85016200',
    '85016300',
    '85016400',
    '85017100',
    '85017200',
    '85018000',
    '85021120',
    '85021180',
    '85021200',
    '85021320',
    '85021340',
    '85021380',
    '85022020',
    '85022040',
    '85022060',
    '85022080',
    '85023100',
    '85023920',
    '85023980',
    '85024000',
    '85030010',
    '85030091',
    '85030099',
    '85041020',
    '85041080',
    '85042100',
    '85042210',
    '85042290',
    '85042300',
    '85043121',
    '85043129',
    '85043180',
    '85043200',
    '85043300',
    '85043400',
    '85044060',
    '85044083',
    '85044085',
    '85044086',
    '85044095',
    '85045000',
    '85049011',
    '85049013',
    '85049017',
    '85049090',
    '85051110',
    '85051190',
    '85051910',
    '85051990',
    '85052000',
    '85059021',
    '85059029',
    '85059050',
    '85059090',
    '85061011',
    '85061018',
    '85061091',
    '85061098',
    '85063000',
    '85064000',
    '85065010',
    '85065030',
    '85065090',
    '85066000',
    '85068005',
    '85068080',
    '85069000',
    '85071020',
    '85071080',
    '85072020',
    '85072080',
    '85073020',
    '85073080',
    '85075000',
    '85076000',
    '85078000',
    '85079030',
    '85079080',
    '85081100',
    '85081900',
    '85086000',
    '85087000',
    '85094000',
    '85098000',
    '85099000',
    '85101000',
    '85102000',
    '85103000',
    '85109000',
    '85111000',
    '85112000',
    '85113000',
    '85114000',
    '85115000',
    '85118000',
    '85119000',
    '85121000',
    '85122000',
    '85123010',
    '85123090',
    '85124000',
    '85129010',
    '85129090',
    '85131000',
    '85139000',
    '85141100',
    '85141910',
    '85141980',
    '85142010',
    '85142080',
    '85143110',
    '85143190',
    '85143210',
    '85143290',
    '85143910',
    '85143990',
    '85144000',
    '85149030',
    '85149070',
    '85151100',
    '85151910',
    '85151990',
    '85152100',
    '85152900',
    '85153100',
    '85153913',
    '85153918',
    '85153990',
    '85158010',
    '85158090',
    '85159020',
    '85159080',
    '85161011',
    '85161080',
    '85162100',
    '85162910',
    '85162950',
    '85162991',
    '85162999',
    '85163100',
    '85163200',
    '85163300',
    '85164000',
    '85165000',
    '85166010',
    '85166050',
    '85166070',
    '85166080',
    '85166090',
    '85167100',
    '85167200',
    '85167920',
    '85167970',
    '85168020',
    '85168080',
    '85169000',
    '85171100',
    '85171300',
    '85171400',
    '85171800',
    '85176100',
    '85176200',
    '85176910',
    '85176920',
    '85176930',
    '85176990',
    '85177100',
    '85177900',
    '85181000',
    '85182100',
    '85182200',
    '85182900',
    '85183000',
    '85184000',
    '85185000',
    '85189000',
    '85192010',
    '85192091',
    '85192099',
    '85193000',
    '85198100',
    '85198900',
    '85211020',
    '85211095',
    '85219000',
    '85221000',
    '85229000',
    '85232100',
    '85232915',
    '85232919',
    '85232990',
    '85234110',
    '85234130',
    '85234190',
    '85234910',
    '85234920',
    '85234990',
    '85235110',
    '85235190',
    '85235200',
    '85235910',
    '85235990',
    '85238010',
    '85238090',
    '85241100',
    '85241200',
    '85241900',
    '85249100',
    '85249200',
    '85249900',
    '85255000',
    '85256000',
    '85258100',
    '85258200',
    '85258300',
    '85258900',
    '85261000',
    '85269120',
    '85269180',
    '85269200',
    '85271200',
    '85271300',
    '85271900',
    '85272120',
    '85272152',
    '85272159',
    '85272170',
    '85272192',
    '85272198',
    '85272900',
    '85279100',
    '85279200',
    '85279900',
    '85284200',
    '85284900',
    '85285210',
    '85285291',
    '85285299',
    '85285900',
    '85286200',
    '85286920',
    '85286980',
    '85287111',
    '85287115',
    '85287119',
    '85287191',
    '85287199',
    '85287210',
    '85287220',
    '85287230',
    '85287240',
    '85287260',
    '85287280',
    '85287300',
    '85291011',
    '85291030',
    '85291065',
    '85291069',
    '85291080',
    '85291095',
    '85299015',
    '85299018',
    '85299020',
    '85299040',
    '85299065',
    '85299091',
    '85299092',
    '85299097',
    '85301000',
    '85308000',
    '85309000',
    '85311030',
    '85311095',
    '85312020',
    '85312040',
    '85312095',
    '85318040',
    '85318070',
    '85319000',
    '85321000',
    '85322100',
    '85322200',
    '85322300',
    '85322400',
    '85322500',
    '85322900',
    '85323000',
    '85329000',
    '85331000',
    '85332100',
    '85332900',
    '85333100',
    '85333900',
    '85334010',
    '85334090',
    '85339000',
    '85340011',
    '85340019',
    '85340090',
    '85351000',
    '85352100',
    '85352900',
    '85353010',
    '85353090',
    '85354000',
    '85359000',
    '85361010',
    '85361050',
    '85361090',
    '85362010',
    '85362090',
    '85363010',
    '85363030',
    '85363090',
    '85364110',
    '85364190',
    '85364900',
    '85365003',
    '85365005',
    '85365007',
    '85365011',
    '85365015',
    '85365019',
    '85365080',
    '85366110',
    '85366190',
    '85366910',
    '85366930',
    '85366990',
    '85367000',
    '85369001',
    '85369010',
    '85369040',
    '85369095',
    '85371010',
    '85371091',
    '85371095',
    '85371098',
    '85372091',
    '85372099',
    '85381000',
    '85389011',
    '85389019',
    '85389091',
    '85389099',
    '85391000',
    '85392130',
    '85392192',
    '85392198',
    '85392210',
    '85392290',
    '85392930',
    '85392992',
    '85392998',
    '85393110',
    '85393190',
    '85393220',
    '85393290',
    '85393920',
    '85393980',
    '85394100',
    '85394900',
    '85395100',
    '85395200',
    '85399010',
    '85399090',
    '85401100',
    '85401200',
    '85402010',
    '85402080',
    '85404000',
    '85406000',
    '85407100',
    '85407900',
    '85408100',
    '85408900',
    '85409100',
    '85409900',
    '85411000',
    '85412100',
    '85412900',
    '85413000',
    '85414100',
    '85414200',
    '85414300',
    '85414900',
    '85415100',
    '85415900',
    '85416000',
    '85419000',
    '85423111',
    '85423119',
    '85423190',
    '85423211',
    '85423219',
    '85423231',
    '85423239',
    '85423245',
    '85423255',
    '85423261',
    '85423269',
    '85423275',
    '85423290',
    '85423310',
    '85423390',
    '85423911',
    '85423919',
    '85423990',
    '85429000',
    '85431000',
    '85432000',
    '85433040',
    '85433070',
    '85434000',
    '85437001',
    '85437002',
    '85437003',
    '85437004',
    '85437005',
    '85437006',
    '85437007',
    '85437008',
    '85437009',
    '85437010',
    '85437030',
    '85437050',
    '85437060',
    '85437090',
    '85439000',
    '85441110',
    '85441190',
    '85441900',
    '85442000',
    '85443000',
    '85444210',
    '85444290',
    '85444920',
    '85444991',
    '85444993',
    '85444995',
    '85444999',
    '85446010',
    '85446090',
    '85447000',
    '85451100',
    '85451900',
    '85452000',
    '85459010',
    '85459090',
    '85461000',
    '85462000',
    '85469010',
    '85469090',
    '85471000',
    '85472000',
    '85479000',
    '85480020',
    '85480030',
    '85480090',
    '85491110',
    '85491190',
    '85491210',
    '85491220',
    '85491290',
    '85491310',
    '85491320',
    '85491390',
    '85491410',
    '85491420',
    '85491490',
    '85491910',
    '85491920',
    '85491990',
    '85492100',
    '85492900',
    '85493100',
    '85493900',
    '85499100',
    '85499900',
    '86011000',
    '86012000',
    '86021000',
    '86029000',
    '86031000',
    '86039000',
    '86040000',
    '86050000',
    '86061000',
    '86063000',
    '86069110',
    '86069180',
    '86069200',
    '86069900',
    '86071100',
    '86071200',
    '86071910',
    '86071990',
    '86072110',
    '86072190',
    '86072900',
    '86073000',
    '86079110',
    '86079190',
    '86079910',
    '86079980',
    '86080000',
    '86090010',
    '86090090',
    '87011000',
    '87012110',
    '87012190',
    '87012210',
    '87012290',
    '87012310',
    '87012390',
    '87012410',
    '87012490',
    '87012900',
    '87013000',
    '87019110',
    '87019190',
    '87019210',
    '87019290',
    '87019310',
    '87019390',
    '87019410',
    '87019490',
    '87019510',
    '87019590',
    '87021011',
    '87021019',
    '87021091',
    '87021099',
    '87022010',
    '87022090',
    '87023010',
    '87023090',
    '87024000',
    '87029011',
    '87029019',
    '87029031',
    '87029039',
    '87029090',
    '87031011',
    '87031018',
    '87032110',
    '87032190',
    '87032210',
    '87032290',
    '87032311',
    '87032319',
    '87032390',
    '87032410',
    '87032490',
    '87033110',
    '87033190',
    '87033211',
    '87033219',
    '87033290',
    '87033311',
    '87033319',
    '87033390',
    '87034010',
    '87034090',
    '87035000',
    '87036010',
    '87036090',
    '87037000',
    '87038010',
    '87038090',
    '87039000',
    '87041010',
    '87041090',
    '87042110',
    '87042131',
    '87042139',
    '87042191',
    '87042199',
    '87042210',
    '87042291',
    '87042299',
    '87042310',
    '87042391',
    '87042399',
    '87043110',
    '87043131',
    '87043139',
    '87043191',
    '87043199',
    '87043210',
    '87043291',
    '87043299',
    '87044110',
    '87044131',
    '87044139',
    '87044191',
    '87044199',
    '87044210',
    '87044291',
    '87044299',
    '87044310',
    '87044391',
    '87044399',
    '87045110',
    '87045131',
    '87045139',
    '87045191',
    '87045199',
    '87045210',
    '87045291',
    '87045299',
    '87046000',
    '87049000',
    '87051000',
    '87052000',
    '87053000',
    '87054000',
    '87059030',
    '87059080',
    '87060011',
    '87060019',
    '87060091',
    '87060099',
    '87071010',
    '87071090',
    '87079010',
    '87079090',
    '87081010',
    '87081090',
    '87082110',
    '87082190',
    '87082210',
    '87082290',
    '87082910',
    '87082990',
    '87083010',
    '87083091',
    '87083099',
    '87084020',
    '87084050',
    '87084091',
    '87084099',
    '87085020',
    '87085035',
    '87085055',
    '87085091',
    '87085099',
    '87087010',
    '87087050',
    '87087091',
    '87087099',
    '87088020',
    '87088035',
    '87088055',
    '87088091',
    '87088099',
    '87089120',
    '87089135',
    '87089191',
    '87089199',
    '87089220',
    '87089235',
    '87089291',
    '87089299',
    '87089310',
    '87089390',
    '87089420',
    '87089435',
    '87089491',
    '87089499',
    '87089510',
    '87089591',
    '87089599',
    '87089910',
    '87089993',
    '87089997',
    '87091110',
    '87091190',
    '87091910',
    '87091990',
    '87099000',
    '87100000',
    '87111000',
    '87112010',
    '87112092',
    '87112098',
    '87113010',
    '87113090',
    '87114000',
    '87115000',
    '87116010',
    '87116090',
    '87119000',
    '87120030',
    '87120070',
    '87131000',
    '87139000',
    '87141010',
    '87141020',
    '87141030',
    '87141040',
    '87141050',
    '87141090',
    '87142000',
    '87149110',
    '87149130',
    '87149190',
    '87149210',
    '87149290',
    '87149300',
    '87149420',
    '87149490',
    '87149500',
    '87149610',
    '87149630',
    '87149690',
    '87149910',
    '87149930',
    '87149950',
    '87149990',
    '87150010',
    '87150090',
    '87161092',
    '87161098',
    '87162000',
    '87163100',
    '87163910',
    '87163930',
    '87163950',
    '87163980',
    '87164000',
    '87168000',
    '87169010',
    '87169030',
    '87169050',
    '87169090',
    '88010010',
    '88010090',
    '88021100',
    '88021200',
    '88022000',
    '88023000',
    '88024000',
    '88026011',
    '88026019',
    '88026090',
    '88040000',
    '88051010',
    '88051090',
    '88052100',
    '88052900',
    '88061010',
    '88061090',
    '88062110',
    '88062190',
    '88062210',
    '88062290',
    '88062300',
    '88062400',
    '88062910',
    '88062920',
    '88069100',
    '88069200',
    '88069300',
    '88069400',
    '88069910',
    '88069920',
    '88071000',
    '88072000',
    '88073000',
    '88079010',
    '88079021',
    '88079029',
    '88079030',
    '88079090',
    '89011010',
    '89011090',
    '89012010',
    '89012090',
    '89013010',
    '89013090',
    '89019010',
    '89019090',
    '89020010',
    '89020090',
    '89031100',
    '89031200',
    '89031900',
    '89032100',
    '89032210',
    '89032290',
    '89032310',
    '89032390',
    '89033100',
    '89033210',
    '89033290',
    '89033310',
    '89033390',
    '89039310',
    '89039390',
    '89039910',
    '89039999',
    '89040010',
    '89040091',
    '89040099',
    '89051010',
    '89051090',
    '89052000',
    '89059010',
    '89059090',
    '89061000',
    '89069010',
    '89069091',
    '89069099',
    '89071000',
    '89079000',
    '89080000',
    '90011010',
    '90011090',
    '90012000',
    '90013000',
    '90014020',
    '90014041',
    '90014049',
    '90014080',
    '90015020',
    '90015041',
    '90015049',
    '90015080',
    '90019000',
    '90021100',
    '90021900',
    '90022000',
    '90029000',
    '90031100',
    '90031900',
    '90039000',
    '90041010',
    '90041091',
    '90041099',
    '90049010',
    '90049090',
    '90051000',
    '90058000',
    '90059000',
    '90063000',
    '90064000',
    '90065310',
    '90065380',
    '90065900',
    '90066100',
    '90066900',
    '90069100',
    '90069900',
    '90071000',
    '90072000',
    '90079100',
    '90079200',
    '90085000',
    '90089000',
    '90101000',
    '90105000',
    '90106000',
    '90109020',
    '90109080',
    '90111000',
    '90112010',
    '90112090',
    '90118000',
    '90119000',
    '90121000',
    '90129000',
    '90131010',
    '90131090',
    '90132000',
    '90138040',
    '90138080',
    '90139005',
    '90139080',
    '90141000',
    '90142020',
    '90142080',
    '90148000',
    '90149000',
    '90151000',
    '90152000',
    '90153010',
    '90153090',
    '90154000',
    '90158020',
    '90158040',
    '90158080',
    '90159000',
    '90160010',
    '90160090',
    '90171010',
    '90171090',
    '90172005',
    '90172010',
    '90172039',
    '90172090',
    '90173000',
    '90178010',
    '90178090',
    '90179000',
    '90181100',
    '90181200',
    '90181300',
    '90181400',
    '90181910',
    '90181990',
    '90182000',
    '90183110',
    '90183190',
    '90183210',
    '90183290',
    '90183900',
    '90184100',
    '90184910',
    '90184990',
    '90185010',
    '90185090',
    '90189010',
    '90189020',
    '90189030',
    '90189040',
    '90189050',
    '90189060',
    '90189075',
    '90189084',
    '90191010',
    '90191090',
    '90192010',
    '90192020',
    '90192090',
    '90200010',
    '90200090',
    '90211010',
    '90211090',
    '90212110',
    '90212190',
    '90212900',
    '90213100',
    '90213910',
    '90213990',
    '90214000',
    '90215000',
    '90219010',
    '90219090',
    '90221200',
    '90221300',
    '90221400',
    '90221900',
    '90222100',
    '90222900',
    '90223000',
    '90229020',
    '90229080',
    '90230010',
    '90230080',
    '90241020',
    '90241040',
    '90241080',
    '90248000',
    '90249000',
    '90251120',
    '90251180',
    '90251900',
    '90258020',
    '90258040',
    '90258080',
    '90259000',
    '90261021',
    '90261029',
    '90261081',
    '90261089',
    '90262020',
    '90262040',
    '90262080',
    '90268020',
    '90268080',
    '90269000',
    '90271010',
    '90271090',
    '90272000',
    '90273000',
    '90275000',
    '90278100',
    '90278910',
    '90278930',
    '90278990',
    '90279000',
    '90281000',
    '90282000',
    '90283011',
    '90283019',
    '90283090',
    '90289010',
    '90289090',
    '90291000',
    '90292031',
    '90292038',
    '90292090',
    '90299000',
    '90301000',
    '90302000',
    '90303100',
    '90303200',
    '90303320',
    '90303370',
    '90303900',
    '90304000',
    '90308200',
    '90308400',
    '90308900',
    '90309000',
    '90311000',
    '90312000',
    '90314100',
    '90314910',
    '90314990',
    '90318020',
    '90318080',
    '90319000',
    '90321020',
    '90321080',
    '90322000',
    '90328100',
    '90328900',
    '90329000',
    '90330010',
    '90330090',
    '91011100',
    '91011900',
    '91012100',
    '91012900',
    '91019100',
    '91019900',
    '91021100',
    '91021200',
    '91021900',
    '91022100',
    '91022900',
    '91029100',
    '91029900',
    '91031000',
    '91039000',
    '91040000',
    '91051100',
    '91051900',
    '91052100',
    '91052900',
    '91059100',
    '91059900',
    '91061000',
    '91069000',
    '91070000',
    '91081100',
    '91081200',
    '91081900',
    '91082000',
    '91089000',
    '91091000',
    '91099000',
    '91101110',
    '91101190',
    '91101200',
    '91101900',
    '91109000',
    '91111000',
    '91112000',
    '91118000',
    '91119000',
    '91122000',
    '91129000',
    '91131010',
    '91131090',
    '91132000',
    '91139000',
    '91143000',
    '91144000',
    '91149010',
    '91149090',
    '92011010',
    '92011090',
    '92012000',
    '92019000',
    '92021010',
    '92021090',
    '92029030',
    '92029080',
    '92051000',
    '92059010',
    '92059030',
    '92059050',
    '92059090',
    '92060000',
    '92071010',
    '92071030',
    '92071050',
    '92071080',
    '92079010',
    '92079090',
    '92081000',
    '92089000',
    '92093000',
    '92099100',
    '92099200',
    '92099400',
    '92099920',
    '92099940',
    '92099950',
    '92099970',
    '93011000',
    '93012000',
    '93019000',
    '93020000',
    '93031000',
    '93032010',
    '93032095',
    '93033000',
    '93039000',
    '93040000',
    '93051000',
    '93052000',
    '93059100',
    '93059900',
    '93062100',
    '93062900',
    '93063010',
    '93063030',
    '93063090',
    '93069010',
    '93069090',
    '93070000',
    '94011000',
    '94012000',
    '94013100',
    '94013900',
    '94014100',
    '94014900',
    '94015200',
    '94015300',
    '94015900',
    '94016100',
    '94016900',
    '94017100',
    '94017900',
    '94018000',
    '94019110',
    '94019190',
    '94019910',
    '94019920',
    '94019980',
    '94021000',
    '94029000',
    '94031051',
    '94031058',
    '94031091',
    '94031093',
    '94031098',
    '94032020',
    '94032080',
    '94033011',
    '94033019',
    '94033091',
    '94033099',
    '94034010',
    '94034090',
    '94035000',
    '94036010',
    '94036030',
    '94036090',
    '94037000',
    '94038200',
    '94038300',
    '94038900',
    '94039100',
    '94039910',
    '94039990',
    '94041000',
    '94042110',
    '94042190',
    '94042910',
    '94042990',
    '94043000',
    '94044010',
    '94044090',
    '94049010',
    '94049090',
    '94051140',
    '94051150',
    '94051190',
    '94051940',
    '94051950',
    '94051990',
    '94052140',
    '94052150',
    '94052190',
    '94052940',
    '94052950',
    '94052990',
    '94053100',
    '94053900',
    '94054110',
    '94054131',
    '94054139',
    '94054210',
    '94054231',
    '94054239',
    '94054910',
    '94054940',
    '94054990',
    '94055000',
    '94056120',
    '94056180',
    '94056920',
    '94056980',
    '94059110',
    '94059190',
    '94059200',
    '94059900',
    '94061000',
    '94062000',
    '94069010',
    '94069031',
    '94069038',
    '94069090',
    '95030010',
    '95030021',
    '95030029',
    '95030030',
    '95030035',
    '95030039',
    '95030041',
    '95030049',
    '95030055',
    '95030061',
    '95030069',
    '95030070',
    '95030075',
    '95030079',
    '95030081',
    '95030085',
    '95030087',
    '95030095',
    '95030099',
    '95042000',
    '95043010',
    '95043020',
    '95043090',
    '95044000',
    '95045000',
    '95049010',
    '95049080',
    '95051010',
    '95051090',
    '95059000',
    '95061110',
    '95061121',
    '95061129',
    '95061180',
    '95061200',
    '95061900',
    '95062100',
    '95062900',
    '95063100',
    '95063200',
    '95063910',
    '95063990',
    '95064000',
    '95065100',
    '95065900',
    '95066100',
    '95066200',
    '95066910',
    '95066990',
    '95067010',
    '95067030',
    '95067090',
    '95069110',
    '95069190',
    '95069910',
    '95069990',
    '95071000',
    '95072010',
    '95072090',
    '95073000',
    '95079000',
    '95081000',
    '95082100',
    '95082200',
    '95082300',
    '95082400',
    '95082500',
    '95082600',
    '95082900',
    '95083000',
    '95084000',
    '96011000',
    '96019000',
    '96020000',
    '96031000',
    '96032100',
    '96032930',
    '96032980',
    '96033010',
    '96033090',
    '96034010',
    '96034090',
    '96035000',
    '96039010',
    '96039091',
    '96039099',
    '96040000',
    '96050000',
    '96061000',
    '96062100',
    '96062200',
    '96062900',
    '96063000',
    '96071100',
    '96071900',
    '96072010',
    '96072090',
    '96081010',
    '96081092',
    '96081099',
    '96082000',
    '96083000',
    '96084000',
    '96085000',
    '96086000',
    '96089100',
    '96089900',
    '96091010',
    '96091090',
    '96092000',
    '96099010',
    '96099090',
    '96100000',
    '96110000',
    '96121010',
    '96121020',
    '96121080',
    '96122000',
    '96131000',
    '96132000',
    '96138000',
    '96139000',
    '96140010',
    '96140090',
    '96151100',
    '96151900',
    '96159000',
    '96161010',
    '96161090',
    '96162000',
    '96170000',
    '96180000',
    '96190030',
    '96190040',
    '96190050',
    '96190071',
    '96190075',
    '96190079',
    '96190081',
    '96190089',
    '96200010',
    '96200091',
    '96200099',
    '97012100',
    '97012200',
    '97012900',
    '97019100',
    '97019200',
    '97019900',
    '97021000',
    '97029000',
    '97031000',
    '97039000',
    '97040000',
    '97051000',
    '97052100',
    '97052200',
    '97052900',
    '97053100',
    '97053900',
    '97061000',
    '97069000',
    '99302400',
    '99302700',
    '99309900',
    '99312400',
    '99312700',
    '99319900',
    
]);

export const RefCodes = [
'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '1.000 p/st',
    '1.000 p/st',
    'p/st',
    '1.000 p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg/net eda',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg/net eda',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg/net eda',
    'Kg/net eda',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg/net eda',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'l alc. 100%',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'l alc. 100%',
    '',
    '',
    '',
    '',
    '',
    '',
    'l',
    'l',
    'l',
    '',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l alc. 100%',
    'l',
    'l',
    'l',
    'l',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '1.000 p/st',
    '1.000 p/st',
    '1.000 p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '1.000 m3',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm3',
    'm3',
    'm3',
    'm3',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'TJ',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'TJ',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '1.000 KWh',
    '',
    '',
    '',
    '',
    '',
    '',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg P2O5',
    'Kg P2O5',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg NaOH',
    'Kg KOH',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'G',
    '',
    'G',
    'Kg U',
    'Kg U',
    'Kg U',
    'Kg U',
    'gi F/S',
    'gi F/S',
    'gi F/S',
    'gi F/S',
    'gi F/S',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'gi F/S',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg H2O2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg met. am.',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg N',
    'Kg P2O5',
    'Kg P2O5',
    'Kg P2O5',
    'Kg K2O',
    'Kg K2O',
    'Kg K2O',
    'Kg K2O',
    'Kg K2O',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'p/st',
    'm2',
    '',
    '',
    'm2',
    'm',
    'm',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    'p/st',
    'p/st',
    'm',
    '',
    '',
    'p/st',
    '',
    'm',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm',
    'm',
    'm',
    'm',
    'm',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'p/st',
    'p/st',
    'm2',
    '',
    '',
    '',
    'p/st',
    '',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'pa',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    '',
    '',
    '',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm2',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm',
    '',
    '',
    '',
    'm',
    'm2',
    '',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    'm3',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm3',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    '',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    'Kg 90% sdt.',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'pa',
    '',
    'pa',
    '',
    'pa',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'pa',
    '',
    'p/st',
    'p/st',
    'p/st',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'pa',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    'pa',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    'p/st',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    'm2',
    '',
    '',
    '',
    '',
    'm2',
    'm2',
    'm2',
    '',
    '',
    'm2',
    'm2',
    'm2',
    'm2',
    'p/st',
    '',
    '',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'G',
    'G',
    'G',
    'c/k',
    'c/k',
    'c/k',
    'c/k',
    'c/k',
    '',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    '',
    'G',
    'G',
    'G',
    'G',
    'G',
    '',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    'G',
    '',
    '',
    '',
    '',
    '',
    'G',
    'G',
    '',
    'G',
    'G',
    '',
    '',
    '',
    'G',
    'G',
    'G',
    '',
    '',
    '',
    'G',
    'G',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm2',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '1.000 p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'gi F/S',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '1.000 p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'ce/el',
    'ce/el',
    'p/st',
    'ce/el',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'pa',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'ct/l',
    'p/st',
    'ct/l',
    'p/st',
    'ct/l',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '100 p/st',
    '100 p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '1.000 p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'pa',
    'p/st',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'pa',
    'pa',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'm',
    'm',
    '',
    '',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    'p/st',
    '',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'p/st',
    'p/st',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    
];

export const Definitions = [
'Cai magari catari si bardoi vii Reproducatori de rasa pura',
    'Cai magari catari si bardoi vii Destinati sacrificarii',
    'Cai magari catari si bardoi vii Altele',
    'Cai magari catari si bardoi vii Magari',
    'Cai magari catari si bardoi vii Altele',
    'Animale vii din specia bovine Juninci care nu au fatat niciodata',
    'Animale vii din specia bovine Vaci',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Din subgenul Bibos sau din subgenul Poephagus',
    'Animale vii din specia bovine Cu o greutate de maximum kg',
    'Animale vii din specia bovine Destinate sacrificarii',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Destinate sacrificarii',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Destinate sacrificarii',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Destinate sacrificarii',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Destinate sacrificarii',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Reproducatori de rasa pura',
    'Animale vii din specia bovine De specie domestica',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia bovine Reproducatori de rasa pura',
    'Animale vii din specia bovine De specie domestica',
    'Animale vii din specia bovine Altele',
    'Animale vii din specia porcine Reproducatori de rasa pura',
    'Animale vii din specia porcine Animale domestice din specia porcina',
    'Animale vii din specia porcine Altele',
    'Animale vii din specia porcine Scroafe cu o greutate minima de kg care au fatat cel putin o data',
    'Animale vii din specia porcine Altele',
    'Animale vii din specia porcine Altele',
    'Animale vii din specia ovine sau caprine Reproducatori de rasa pura',
    'Animale vii din specia ovine sau caprine Miei pana la  an',
    'Animale vii din specia ovine sau caprine Altele',
    'Animale vii din specia ovine sau caprine Reproducatori de rasa pura',
    'Animale vii din specia ovine sau caprine Altele',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice De rase ouatoare',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Altele',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice De rase ouatoare',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Altele',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Curcani si curci',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Rate',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Gaste',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Bibilici',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Cocosi si gaini',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Rate',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Gaste',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Curcani si curci',
    'Cocosi gaini rate gaste curcani curci si bibilici vii din specii domestice Bibilici',
    'Alte animale vii Primate',
    'Alte animale vii Balene delfini si marsuini mamifere din ordinul Cetacee lamantini si dugongi mamifere din ordinul Sirenia foci lei de mare si morse mamifere din subordinul Pinnipedia',
    'Alte animale vii Camile si alte camelide Camelidae',
    'Alte animale vii Iepuri domestici',
    'Alte animale vii Altele',
    'Alte animale vii Altele',
    'Alte animale vii Reptile inclusiv serpi si broaste testoase de mare',
    'Alte animale vii Pasari de prada',
    'Alte animale vii Psittaciforme inclusiv papagali perusi arakanga si cacaduu',
    'Alte animale vii Struti emu Dromaius novaehollandiae',
    'Alte animale vii Porumbei',
    'Alte animale vii Altele',
    'Alte animale vii Albine',
    'Alte animale vii Altele',
    'Alte animale vii Altele',
    'Carne de animale din specia bovine proaspata sau refrigerata In carcase sau semicarcase',
    'Carne de animale din specia bovine proaspata sau refrigerata Sferturi numite compensate',
    'Carne de animale din specia bovine proaspata sau refrigerata Sferturi anterioare neseparate sau separate',
    'Carne de animale din specia bovine proaspata sau refrigerata Sferturi posterioare neseparate sau separate',
    'Carne de animale din specia bovine proaspata sau refrigerata Altele',
    'Carne de animale din specia bovine proaspata sau refrigerata Dezosata',
    'Carne de animale din specia bovine congelata In carcase sau semicarcase',
    'Carne de animale din specia bovine congelata Sferturi numite compensate',
    'Carne de animale din specia bovine congelata Sferturi anterioare neseparate sau separate',
    'Carne de animale din specia bovine congelata Sferturi posterioare neseparate sau separate',
    'Carne de animale din specia bovine congelata Altele',
    'Carne de animale din specia bovine congelata Sferturi anterioare intregi sau taiate in maximum cinci bucati fiecare sfert anterior fiind prezentat intrun singur bloc congelat sferturi numite compensate prezentate in doua blocuri congelate unul dintre ele continand partea anterioara a carcasei intreaga sau transata in maximum cinci bucati iar celalalt continand sfertul posterior intro singura bucata fara muschiul file',
    'Carne de animale din specia bovine congelata Bucati rezultate din sferturi anterioare si din piept numite australiene',
    'Carne de animale din specia bovine congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata De animale domestice din specia porcine',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Jamboane si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Spete si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Parti anterioare si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Spinari si parti din acestea nedezosate',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Piept impanat si parti de piept',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Dezosate',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata De la animale domestice din specia porcine',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Jamboane si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Spete si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Parti anterioare si parti din acestea',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Spinari si parti din acestea nedezosate',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Piept impanat si parti de piept',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Dezosate',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din specia porcine proaspata refrigerata sau congelata Altele',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Carcase si semicarcase de miel proaspete sau refrigerate',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata In carcase sau semicarcase',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Casca sau semicasca',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Spinali sisau sa sau semispinali sisau semisa',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Chiulota sau semichiulota',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Altele',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Dezosata',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Carcase si semicarcase de miel congelate',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata In carcase sau semicarcase',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Casca sau semicasca',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Spinali sisau sa sau semispinali sisau semisa',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Chiulota sau semichiulota',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Altele',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata De miel',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Altele',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Carcase sau semicarcase',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Casca sau semicasca',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Spinali sisau sa sau semispinali sisau semisa',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Chiulota sau semichiulota',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Bucati nedezosate',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Bucati dezosate',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Carcase sau semicarcase',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Casca sau semicasca',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Spinali sisau sa sau semispinali sisau semisa',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Chiulota sau semichiulota',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Bucati nedezosate',
    'Carne de animale din speciile ovine sau caprine proaspata refrigerata sau congelata Bucati dezosate',
    'Carne de cal de magar sau de catar proaspata refrigerata sau congelata Proaspata sau refrigerata',
    'Carne de cal de magar sau de catar proaspata refrigerata sau congelata Congelata',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Destinate fabricarii de produse farmaceutice',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Muschii pilieri diafragmatici si diafragma',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Altele',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Limba',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Ficat',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Destinate fabricarii produselor farmaceutice',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Muschii pilieri diafragmatici si diafragma',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Altele',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Din specia porcine proaspete sau refrigerate',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Ficat',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Altele',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Destinate fabricarii de produse farmaceutice',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate De cai magari sau catari',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Din speciile ovine sau caprine',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Destinate fabricarii de produse farmaceutice',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate De cai magari sau catari',
    'Organe comestibile de animale din speciile bovine porcine ovine caprine de cai de magari sau de catari proaspete refrigerate sau congelate Din speciile ovine sau caprine',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate cu cap si picioare denumite pui',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare dar cu gat inima ficat si pipota denumite pui',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare si fara gat inima ficat si pipota denumite pui sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare dar cu gat inima ficat si pipota denumite pui',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare si fara gat inima ficat si pipota denumite pui sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau  fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu gat inima ficat si pipota denumite curcani',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si gat fara picioare inima ficat si pipota denumite curcani sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu gat inima ficat si pipota denumite curcani',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si gat fara picioare inima ficat si pipota denumite curcani sau altfel prezentati',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Copane si bucati de copane',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Copane si bucati de copane',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene si sange fara intestine dar neeviscerate cu cap si picioare denumite rate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu gat inima ficat si pipota denumite rate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare si fara gat inima ficat si pipota denumite rate sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu gat inima ficat si pipota denumite rate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare si fara gat inima ficat si pipota denumite rate sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat gras proaspat sau refrigerat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Parti numite trunchi de rate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat altul decat ficatul gras',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Parti numite trunchi de rate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat gras',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene si sange neeviscerate cu cap si picioare denumite gaste',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu sau fara inima si pipota denumite gaste sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene si sange neeviscerate cu cap si picioare denumite gaste',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Fara pene eviscerate fara cap si picioare cu sau fara inima si pipota denumite gaste sau altfel prezentate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat gras proaspat sau refrigerat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Parti numite trunchi de gaste',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat altul decat ficatul gras',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Parti numite trunchi de gaste',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat gras',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Netransate in bucati proaspete refrigerate sau congelate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Dezosate',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Jumatati sau sferturi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Aripi intregi cu sau fara varfuri',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Spate gaturi spate cu gaturi impreuna tartite varfuri de aripi',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Piept si bucati de piept',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Pulpe si bucati de pulpe',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Ficat',
    'Carne si organe comestibile proaspete refrigerate sau congelate de pasari de la pozitia Altele',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De iepuri domestici',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Altele',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De primate',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Carne de balena',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Carne de foca',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Altele',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De reptile inclusiv serpi si broaste testoase de mare',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De camile si alte camelide Camelidae',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De porumbei domestici',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De vanat altul decat iepurii domestici sau iepurii de camp',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate De reni',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Pulpe de broasca',
    'Alta carne si organe comestibile proaspete refrigerate sau congelate Altele',
    'Slanina fara parti slabe grasime de porc si de pasare netopite si nici altfel extrase proaspete refrigerate congelate sarate sau in saramura uscate sau afumate Proaspata refrigerata congelata sarata sau in saramura',
    'Slanina fara parti slabe grasime de porc si de pasare netopite si nici altfel extrase proaspete refrigerate congelate sarate sau in saramura uscate sau afumate Uscata sau afumata',
    'Slanina fara parti slabe grasime de porc si de pasare netopite si nici altfel extrase proaspete refrigerate congelate sarate sau in saramura uscate sau afumate Grasime de porc alta decat cea de la subpozitiile si',
    'Slanina fara parti slabe grasime de porc si de pasare netopite si nici altfel extrase proaspete refrigerate congelate sarate sau in saramura uscate sau afumate Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Jamboane si bucati de jamboane',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Spete si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Jamboane si bucati de jamboane',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Spete si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Sarate sau in saramura',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Uscate sau afumate',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Semicarcase de bacon sau trei sferturi anterior',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Trei sferturi posterior sau mijloc',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Parti anterioare si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Spinari si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Parti anterioare si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Spinari si bucati din acestea',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Dezosate',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Nedezosata',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Dezosata',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe De primate',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe De balene delfini si delfini bruni mamifere din ordinul Cetacee de lamantini si dugongi mamifere din ordinul Sirenia',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Carne',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Organe',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Faina si pudra comestibile de carne sau de organe comestibile',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe De reptile inclusiv serpi si broaste testoase de mare',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe De cal sarata in saramura sau uscata',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Nedezosata',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Dezosata',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe De ren',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Ficat',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Muschii pilieri diafragmatici si diafragma',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Ficat gras de gasca sau de rata sarat sau in saramura',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Altele',
    'Carne si organe comestibile sarate sau in saramura uscate sau afumate faina si pudra comestibile de carne sau de organe Faina si pudra comestibile de carne sau de organe',
    'Pesti vii De apa dulce',
    'Pesti vii Altele',
    'Pesti vii Din specia Oncorhynchus apache sau Oncorhynchus chrysogaster',
    'Pesti vii Altele',
    'Pesti vii Cu lungimea mai mica de cm',
    'Pesti vii Cu lungimea de minimum cm dar mai mica de cm',
    'Pesti vii Cu lungimea de minimum cm',
    'Pesti vii Crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobrama spp',
    'Pesti vii Toni rosii de Atlantic Thunnus thynnus',
    'Pesti vii Toni rosii de Pacific Thunnus orientalis',
    'Pesti vii Toni rosii din sud Thunnus maccoyii',
    'Pesti vii Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus somoni de Atlantic Salmo salar si lostrita Hucho hucho',
    'Pesti vii Altele',
    'Pesti vii Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Din specia Oncorhynchus apache sau Oncorhynchus chrysogaster',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Din specia Oncorhynchus mykiss cu cap si branhii eviscerati cantarind peste kg fiecare sau fara cap si fara branhii eviscerati cantarind peste kg fiecare',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Somon de Atlantic Salmo salar si lostrita Hucho hucho',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Halibut negru Reinhardtius hippoglossoides',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Halibut de Atlantic Hippoglossus hippoglossus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Halibut de Pacific Hippoglossus stenolepis',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Cambula Pleuronectes platessa',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Calcan Solea spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Calcani Psetta maxima',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Calcani mici Lepidorhombus spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Heringi Clupea harengus Clupea pallasii',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Ansoa Engraulis spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Sardine din specia Sardina pilchardus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Sardine din genul Sardinops sardinele Sardinella spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Sproturi Sprattus sprattus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Scrumbii Scomber scombrus Scomber australasicus Scomber japonicus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Stavrid de Atlantic Trachurus trachurus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Stavrid chilian Trachurus murphyi',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Cobie Rachycentron canadum',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pestespada Xiphias gladius',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Din specia Gadus morhua',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Eglefin Melanogramnus aeglefinus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Cod negru Pollachius virens',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pesti marini din specia Merluccius capensis sau Merluccius paradoxus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pesti marini din specia Merluccius australis',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pesti marini din genul Urophycis',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Peste marin de Alaska Theragra chalcogramma',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Merlani albastri Micromesistius poutassou Micromesistius australis',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Cod arctic Boreogadus saida',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Merlani Merlangus merlangus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Polac Pollachius pollachius',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Mihaltidemare Molva spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Tilapia Oreochromis spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobrama spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Anghile Anguilla spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Rechinul Scrumbiilor Lamna nasus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Rechin albastru Prionace glauca',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pisici si vulpidemare Rajidae',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Toothfish Dissostichus spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Lupi de mare Dicentrarchus labrax',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Dorada de mare din specia Dentex dentex sau Pagellus spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Dorade regale Sparus aurata',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia De apa dulce',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor care fac obiectul pozitiei',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Din specia Sebastes marinus',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Pesti din specia Brama Brama spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Peteundiar Lophius spp',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Tipari roz Genypterus blacodes',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Ficat icre si lapti',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Inotatoare de rechin',
    'Peste proaspat sau refrigerat cu exceptia fileului de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Somon rosu Oncorhynchus nerka',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Alti somoni de Pacific Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Somon de Atlantic Salmo salar si lostrita Hucho hucho',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Oncorhnchus apache sau Oncorhynchus chrysogaster',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Oncorhynchus mykiss cu cap si branhii eviscerati cantarind peste kg fiecare sau fara cap si fara branhii eviscerati cantarind peste kg fiecare',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Tilapia Oreochromis spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobrama spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Anghile Anguilla spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Halibut negru Reinhardtius hippoglossoides',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Halibut de Atlantic Hippoglossus hippoglossus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Halibut de Pacific Hippoglossus stenolepis',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Cambula Pleuronectes platessa',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Limba de mare Solea spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Calcani Psetta maxima',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti din specia Platichthys flesus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti din genul Rhombosolea',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti din specia Pelotreis flavilatus sau Peltorhamphus novaezelandiae',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Heringi Clupea harengus Clupea pallasii',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Sardine din specia Sardina pilchardus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Sardine din genul Sardinops sardinele Sardinella spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Sproturi Sprattus sprattus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Scomber scombrus sau Scomber japonicus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Scomber australasicus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Stavrid de Atlantic Trachurus trachurus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Stavrid chilian Trachurus murphyi',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Cobie Rachycentron canadum',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pestespada Xiphias gladius',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Ansoa Engraulis spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Gadus morhua',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Gadus ogac',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Gadus macrocephalus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Eglefin Melanogrammus aeglefinus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Cod negru Pollachius virens',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti marini din specia Merluccius capensis sau Merluccius paradoxus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti marini din specia Merluccius hubbsi',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti marini din specia Merluccius australis',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti marini din genul Urophycis',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Peste marin de Alaska Theragra chalcogramma',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Merlani albastri Micromesistius poutassou',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Merlani albastri sudici Micromesistius australis',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Cod arctic Boreogadus saida',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Merlani Merlangius merlangus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Polac Pollachius pollachius',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Grenadieri albastri Macruronus novaezelandiae',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Mihaltidemare Molva spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Rechinul Scrumbiilor Lamna nasus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Rechin albastru Prionace glauca',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pisici si vulpidemare Rajidae',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Toothfish Dissostichus spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Lupi de mare Dicentrarchus labrax',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti de apa dulce',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Destinati fabricarii industriale a produselor de la pozitia',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Din specia Sebastes marinus',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti din specia Orcynopsis unicolor',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Dorada de mare din specia Dentex dentex sau Pagellus spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Dorade regale Sparus aurata',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Pesti din specia Brama Brama spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Peteundiar Lophius spp',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Tipari roz Genypterus blacodes',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Icre si lapti folositi pentru obtinerea acidului dezoxiribonucleic sau a sulfatului de protamina',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Inotatoare de rechin',
    'Pesti congelati cu exceptia fileurilor de peste si a carnii de peste de la pozitia Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Tilapia Oreochromis spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Biban de Nil Lates niloticus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus somon de Atlantic Salmo salar si lostrita Hucho hucho',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Din specia Oncorhynchus mykiss cantarind peste g fiecare',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Din specia Oncorhynchus apache sau Oncorhynchus chrysogaster',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti plati Pleuronectidae Bothidae Cynoglossidae Soleidae Scophthalmidae si Citharidae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod Gadus morhua Gadus ogac Gadus macrocephalus si cod arctic Boreogadus saida',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod negru Pollachius virens',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate De pestispada Xiphias gladius',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate De Toothfish Dissostichus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechinul scrumbiilor Lamna nasus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechin albastru Prionace glauca',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pisici si vulpidemare Rajidae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti de apa dulce',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Sebaste Sebastes spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Tilapia Oreochromis spp specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo sppOsteochilus hasselti Leptobarbus hoeveni Megalobrama spp anghile Anguilla spp biban de Nil Lates niloticus si pesti capdesarpe Channa spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Salmonide',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti din familiile Bregmacerotidae Euclichthyidae Gadidae Macrouridae Melanonidae Merlucciidae Moridae si Muraenolepididae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pestespada Xiphias gladius',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Toothfish Dissostichus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechinul scrumbiilor Lamna nasus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechin albastru Prionace glauca',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pisici si vulpidemare Rajidae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti de apa dulce',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Flanc de heringi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Tilapia Oreochromis spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Biban de Nil Lates niloticus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod din specia Gadus macrocephalus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Eglefin Melanogramnus aeglefinus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod negru Pollachius virens',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti marini din specia Merluccius capensis sau Merluccius paradoxus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti marini din specia Merluccius hubbsi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti marini din genul Urophycis',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Peste marin de Alaska Theragra chalcogramma',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod arctic Boreogadus saida',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Merlani Merlangius merlangus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Grenadieri albastri Macruronus novaezelandiae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Mihaltidemare Molva spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus somon de Atlantic Salmo salar si lostrita Hucho hucho',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Din specia Oncorhynchus mykiss cantarind peste g fiecare',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Din specia Oncorhynchus apache sau Oncorhynchus chrysogaster',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cambula de Baltica Pleuronectes platessa',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cambula Platichthys flesus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cardina alba Lepidorhombus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate De pestispada Xiphias gladius',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate De Toothfish Dissostichus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Heringi Clupea harengus Clupea pallasii',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Toni din genul Thunnus pesti saritori bonite cu abdomenul vargat Katsuwonus pelamis',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechinul scrumbiilor Lamna nasus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechin albastru Prionace glauca',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pisici si vulpidemare Rajidae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti de apa dulce',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Din specia Sebastes marinus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti din genul Euthynnus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Macrou din specia Scomber australasicus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Peteundiar Lophius spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pestispada Xiphias gladius',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Toothfish Dissostichus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Surimi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Surimi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Surimi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod din specia Gadus macrocephalus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod din specia Gadus morhua',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Eglefin Melanogramnus aeglefinus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cod negru Pollachius virens',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti marini din genul Merluccius',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Merlani albastri Micromesistius poutassou',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Caini de mare Squalus acanthias si rechini pisica Scyliorhinus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechinul scrumbiilor Lamna nasus',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Rechin albastru Prionace glauca',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pisici si vulpidemare Rajidae',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Surimi',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti de apa dulce',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Heringi Clupea harengus Clupea pallasii',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Sebaste Sebastes spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Cardina alba Lepidorhombus spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Pesti din specia Brama Brama spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Peteundiar Lophius spp',
    'Fileuri de peste si carne de peste chiar tocata proaspete refrigerate sau congelate Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Ficat de pete icre si lapti uscati afumati sarati sau in saramura',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Tilapia Oreochromis spp specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobrama spp anghile Anguilla spp biban de Nil Lates niloticus si pesti capdesarpe Channa spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Cod din specia Gadus macrocephalus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus de somoni de Atlantic Salmo salar si de lostrita Hucho hucho sarati sau in saramura',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Halibut negru Reinhardtius hippoglossoides sarat sau in saramura',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus somoni de Atlantic Salmo salar si lostrita Hucho hucho',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Heringi Clupea harengus Clupea pallasii',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Pastravi Salmo trutta Oncorhynchus mykiss Oncorhynchus clarki Oncorhynchus aguabonita Oncorhynchus gilae Oncorhynchus apache si Oncorhynchus chrysogaster',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Anghile Anguilla spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Halibut negru Reinhardtius hippoglossoides',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Halibut de Atlantic Hippoglossus hippoglossus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Specii de macrou Scomber scombrus Scomber australasicus Scomber japonicus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Uscati nesarati',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Uscati si sarati',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Tilapia Oreochromis spp specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp  crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobrama spp anghile Anguilla spp biban de Nil Lates niloticus si pesti capdesarpe Channa spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Cod arctic Boreogadus saida',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Heringi Clupea harengus Clupea pallasii',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Ansoa Engraulis spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Halibut de Atlantic Hippoglossus hippoglossus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Heringi Clupea harengus Clupea pallasii',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Cod Gadus morhua Gadus ogac Gadus macrocephalus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Ansoa Engraulis spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Tilapia Oreochromis spp specii de Anarhicatide Pangasius spp Silurus spp Clarias spp Ictalurus spp crapi Cyprinus spp Carassius spp Ctenopharyngodon idellus Hypophthalmichthys spp Cirrhinus spp Mylopharyngodon piceus Catla catla Labeo spp Osteochilus hasselti Leptobarbus hoeveni Megalobramaspp anghile Anguilla spp biban de Nil Lates niloticus si pesti capdesarpe Channa spp',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Cod arctic Boreogadus saida',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Halibut de Atlantic Hippoglossus hippoglossus',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Somoni de Pacific Oncorhynchus nerka Oncorhynchus gorbuscha Oncorhynchus keta Oncorhynchus tschawytscha Oncorhynchus kisutch Oncorhynchus masou si Oncorhynchus rhodurus somoni de Atlantic Salmo salar si lostrita Hucho hucho',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Inotatoare de rechin',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Capete cozi si vezici inotatoare de peste',
    'Peste uscat sarat sau in saramura peste afumat chiar fiert inainte sau in timpul afumarii Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Cozi de languste',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Intregi',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Crabi din specia Paralithodes camchaticus Chionoecetes spp sau Callinectes sapidus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Crabi din specia Cancer pagurus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Homari norvegieni Nephrops norvegicus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din specia Crangon crangon',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti de adancime Parapenaeus longirostris',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din genul Penaeus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din familia Pandalidae cu exceptia genului Pandalus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din genul Crangon cu exceptia speciei Crangon crangon',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Raci de apa dulce',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Languste Palinurus spp Panulirus spp Jasus spp',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Vii',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Intregi',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Crabi din specia Cancer pagurus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Homari norvegieni Nephrops norvegicus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Proaspeti sau refrigerati',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din familia Pandalidae cu exceptia genului Pandalus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din genul Crangon cu exceptia speciei Crangon crangon',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Raci de apa dulce',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Languste Palinurus spp Panulirus spp Jasus spp',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Intregi',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Crabi din specia Cancer pagurus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Homari norvegieni Nephrops norvegicus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Fierti in apa sau in vapori',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Pandalus spp',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din familia Pandalidae cu exceptia genului Pandalus',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Creveti din genul Crangon cu exceptia speciei Crangon crangon',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Raci de apa dulce',
    'Crustacee atat decorticate cat si nedecorticate vii proaspete refrigerate congelate uscate sarate sau in saramura crustacee afumate atat decorticate cat si nedecorticate atat fierte cat si nefierte inainte sau in timpul afumarii crustacee nedecorticate fierte in apa sau in aburi chiar refrigerate congelate uscate sarate sau in saramura Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Stridii plate Ostrea spp vii de maximum g fiecare inclusiv cochiliile',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Congelate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Scoici St Jacques sau scoicipieptane pecteni si alte scoici din genul Pecten Chlamys sau Placopecten',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Scoici St Jacques Pecten maximus',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Scoici St Jacques sau scoicipieptane pecteni si alte scoici din genul Pecten Chlamys sau Placopecten',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Mytilus spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Perna spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Mytilus spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Perna spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Mytilus spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Perna spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Sepia officinalis Rossia macrosoma Sepiola spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Loligo spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Ommastrephes spp Nototodarus spp Sepioteuthis spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Todarodes sagittatus',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Sepiola rondeleti',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Sepia officinalis Rossia macrosoma',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Loligo vulgaris',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Loligo pealei',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Loligo gahi',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Ommastrephes spp altele decat Ommastrephes sagittatus Nototodarus spp Sepioteuthis spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Illex spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Todarodes sagittatus Ommastrephes sagittatus',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Sepia officinalis Rossia macrosoma Sepiola spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Loligo spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Ommastrephes spp altele decat Ommastrephes sagittatus Nototodarus spp Sepioteuthis spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Todarodes sagittatus Ommastrephes sagittatus',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspete sau refrigerate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Congelate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Melci altii decat melcii de mare',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspete sau refrigerate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Pesti vargati sau alte specii din familia Veneridae',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Urechea marii Haliotis spp vii proaspete sau refrigerate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Specii de melci din genul Strombus Strombus spp vii proaspeti sau refrigerati',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Urechea marii Haliotis spp congelate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Specii de melci din genul Strombus Strombus spp congelati',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Alte specii de urechea marii Haliotis spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Alte specii de melci din genul Strombus Strombus spp',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspete sau refrigerate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Congelate',
    'Moluste separate sau nu de cochilie vii proaspete refrigerate congelate uscate sarate sau in saramura moluste afumate separate sau nu de cochilie atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspeti sau refrigerati',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Congelati',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspeti sau refrigerati',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Congelati',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Congelate',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Vii proaspete sau refrigerate',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Congelate',
    'Nevertebrate acvatice altele decat crustaceele si molustele vii proaspete refrigerate congelate uscate sarate sau in saramura nevertebrate acvatice altele decat crustaceele si molustele afumate atat fierte cat si nefierte inainte sau in timpul afumarii Altele',
    'Faina pudra si aglomerate sub forma de pelete de peste de crustacee de moluste si de alte nevertebrate acvatice adecvate pentru consumul uman De peste',
    'Faina pudra si aglomerate sub forma de pelete de peste de crustacee de moluste si de alte nevertebrate acvatice adecvate pentru consumul uman Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum l',
    'Lapte si smantana din lapte neconcentrate fara adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Lapte special numit pentru sugari in recipiente inchise ermetic cu un continut net de maximum g si cu un continut de grasimi de peste  din greutate',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Cu un continut de grasimi de maxim  din greutate',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Cu un continut de grasimi de peste  dar de maximum  din greutate',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Cu un continut de grasimi de maximum  din greutate',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori In ambalaje directe cu un continut net de maximum kg',
    'Lapte si smantana din lapte concentrate sau cu adaos de zahar sau alti indulcitori Altele',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Cu un continut sub   de grasimi din lapte sub   zaharoza inclusiv zahar invertit sau izoglucoza sub   glucoza amidon sau fecule',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Altele',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao De maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  dar maximum  din greutate',
    'Iaurt lapte acru lapte si smantana covasite  chefir si alte sortimente de lapte si smantana fermentate sau acrite chiar concentrate sau cu adaos de zahar sau alti indulcitori sau aromatizate sau cu adaos de fructe fructe cu coaja lemnoasa sau cacao Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte De maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  dar maximum  din greutate',
    'Zer chiar concentrat sau cu adaos de zahar sau de alti indulcitori produse obtinute din compusi naturali ai laptelui chiar cu adaos de zahar sau alti indulcitori nedenumite si necuprinse in alta parte Peste  din greutate',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine In ambalaje directe cu un continut net de maximum kg',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Altele',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Unt recombinat',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Unt din zer',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Altele',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Cu un continut de grasimi egal cu sau mai mare de  dar mai mic de  din greutate',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Cu un continut de grasimi egal cu sau mai mare de  dar de maximum  din greutate',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Cu un continut de grasimi de peste  dar mai mic de  din greutate',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Cu un continut de grasimi egal cu sau mai mare de  si cu un continut de apa de maximum  din greutate',
    'Unt si alte grasimi care provin din lapte pasta din lapte pentru tartine Altele',
    'Branza si cas Mozzarella cu sau fara lichid',
    'Branza si cas Altele',
    'Branza si cas Altele',
    'Branza si cas Branza rasa sau pudra de orice tip',
    'Branza si cas La fabricarea carora nu sau folosit alte branzeturi decat Emmental Gruyre si Appenzell si care pot avea adaugata branza Glarus cu ierburi zisa si Schabziger conditionate pentru vanzarea cu amanuntul cu un continut de grasimi in substanta uscata mai mic de sau egal cu  din greutate',
    'Branza si cas De maximum  din greutate',
    'Branza si cas Peste  din greutate',
    'Branza si cas Cu un continut de grasimi de peste  din greutate',
    'Branza si cas Roquefort',
    'Branza si cas Gorgonzola',
    'Branza si cas Altele',
    'Branza si cas Destinate prelucrarii',
    'Branza si cas Emmental',
    'Branza si cas Gruyre Sbrinz',
    'Branza si cas Bergkse Appenzell',
    'Branza si cas Branza Fribourgeois Vacherin Mont dOr si Tte de Moine',
    'Branza si cas Cheddar',
    'Branza si cas Edam',
    'Branza si cas Tilsit',
    'Branza si cas Cascaval',
    'Branza si cas Feta',
    'Branza si cas KefaloTyri',
    'Branza si cas Finlandia',
    'Branza si cas Jarlsberg',
    'Branza si cas Branza din lapte de oaie sau de bivolita in recipiente continand saramura sau in burduf din piele de oaie sau de capra',
    'Branza si cas Grana Padano Parmigiano Reggiano',
    'Branza si cas Fiore Sardo Pecorino',
    'Branza si cas Altele',
    'Branza si cas Provolone',
    'Branza si cas Maasdam',
    'Branza si cas Asiago Caciocavallo Montasio Ragusano',
    'Branza si cas Danbo Fontal Fontina Fynbo Havarti Maribo Sams',
    'Branza si cas Gouda',
    'Branza si cas Esrom Italico Kernhem SaintNectaire SaintPaulin Taleggio',
    'Branza si cas Cantal Cheshire Wensleydale Lancashire Double Gloucester Blarney Colby Monterey',
    'Branza si cas Camembert',
    'Branza si cas Brie',
    'Branza si cas Kefalograviera Kasseri',
    'Branza si cas Peste  dar de maximum  din greutate',
    'Branza si cas Peste  dar de maximum  din greutate',
    'Branza si cas Peste  dar de maximum  din greutate',
    'Branza si cas Peste  din greutate',
    'Branza si cas Altele',
    'Oua de pasari in coaja proaspete conservate sau fierte De gaini din specia Gallus domesticus',
    'Oua de pasari in coaja proaspete conservate sau fierte De curci sau de gaste',
    'Oua de pasari in coaja proaspete conservate sau fierte Altele',
    'Oua de pasari in coaja proaspete conservate sau fierte Altele',
    'Oua de pasari in coaja proaspete conservate sau fierte De gaini din specia Gallus domesticus',
    'Oua de pasari in coaja proaspete conservate sau fierte De pasari de curte altele decat cele de gaini din specia Gallus domesticus',
    'Oua de pasari in coaja proaspete conservate sau fierte Altele',
    'Oua de pasari in coaja proaspete conservate sau fierte De pasari de curte',
    'Oua de pasari in coaja proaspete conservate sau fierte Altele',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Improprii consumului alimentar',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Altele',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Improprii consumului alimentar',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Lichide',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Altele inclusiv congelate',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Improprii consumului alimentar',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Altele',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Improprii consumului alimentar',
    'Oua de pasari fara coaja si galbenusuri proaspete uscate fierte in apa sau in abur turnate in forma congelate sau altfel conservate chiar cu adaos de zahar sau de alti indulcitori Altele',
    'Miere naturala Miere naturala',
    'Insecte si alte produse comestibile de origine animala nedenumite si necuprinse in alta parte Proaspete refrigerate sau congelate altele decat faina i pudra',
    'Insecte si alte produse comestibile de origine animala nedenumite si necuprinse in alta parte Faina i pudra',
    'Insecte si alte produse comestibile de origine animala nedenumite si necuprinse in alta parte Altele',
    'Insecte si alte produse comestibile de origine animala nedenumite si necuprinse in alta parte Altele',
    'Par uman brut chiar spalat sau degresat deseuri de par uman Par uman brut chiar spalat sau degresat deseuri de par uman',
    'Par de porc sau de mistret par de bursuc si alte tipuri de par pentru perii si articole similare deseuri din aceste tipuri de par Par de porc sau de mistret si deseuri din par de porc sau de mistret',
    'Par de porc sau de mistret par de bursuc si alte tipuri de par pentru perii si articole similare deseuri din aceste tipuri de par Altele',
    'Intestine vezici si stomacuri de animale intregi sau bucati altele decat cele de peste proaspete refrigerate congelate sarate sau in saramura uscate sau afumate Intestine vezici si stomacuri de animale intregi sau bucati altele decat cele de peste proaspete refrigerate congelate sarate sau in saramura uscate sau afumate',
    'Piei de pasari si alte parti de pasari acoperite cu penele lor sau cu fulgii ori cu puful lor pene si parti de pene cu marginile fasonate sau nu puf brute sau simplu curatate dezinfectate sau tratate in vederea conservarii lor pulbere si deseuri de pene sau de parti de pene In stare bruta',
    'Piei de pasari si alte parti de pasari acoperite cu penele lor sau cu fulgii ori cu puful lor pene si parti de pene cu marginile fasonate sau nu puf brute sau simplu curatate dezinfectate sau tratate in vederea conservarii lor pulbere si deseuri de pene sau de parti de pene Altele',
    'Piei de pasari si alte parti de pasari acoperite cu penele lor sau cu fulgii ori cu puful lor pene si parti de pene cu marginile fasonate sau nu puf brute sau simplu curatate dezinfectate sau tratate in vederea conservarii lor pulbere si deseuri de pene sau de parti de pene Altele',
    'Oase si coarne brute degresate prelucrate sumar dar nedecupate in forme tratate cu acid sau degelatinate pulbere si deseuri din acestea Oseina si oase tratate cu acid',
    'Oase si coarne brute degresate prelucrate sumar dar nedecupate in forme tratate cu acid sau degelatinate pulbere si deseuri din acestea Altele',
    'Fildes carapace de broasca testoasa fanoane inclusiv filamentele de fanoane de balena sau de alte mamifere marine coarne ramuri de coarne de cerb copite unghii gheare si ciocuri brute sau prelucrate sumar dar nedecupate in forme pulbere si deseuri din aceste materiale Fildes pulbere si deseuri de fildes',
    'Fildes carapace de broasca testoasa fanoane inclusiv filamentele de fanoane de balena sau de alte mamifere marine coarne ramuri de coarne de cerb copite unghii gheare si ciocuri brute sau prelucrate sumar dar nedecupate in forme pulbere si deseuri din aceste materiale Altele',
    'Corali si similare brute sau preparate sumar dar neprelucrate altfel cochilii si carapace de moluste de crustacee sau de echinoderme si oase de sepii brute sau preparate sumar dar nedecupate in forme pulbere si deseuri din acestea Coral rosu Corallium rubrum',
    'Corali si similare brute sau preparate sumar dar neprelucrate altfel cochilii si carapace de moluste de crustacee sau de echinoderme si oase de sepii brute sau preparate sumar dar nedecupate in forme pulbere si deseuri din acestea Altele',
    'Chihlimbar cenusiu castoreum zibeta mosc cantaride bila chiar uscata glande si alte substante de origine animala folosite la prepararea produselor farmaceutice proaspete refrigerate congelate sau altfel conservate provizoriu Chihlimbar cenusiu castoreum zibeta mosc cantaride bila chiar uscata glande si alte substante de origine animala folosite la prepararea produselor farmaceutice proaspete refrigerate congelate sau altfel conservate provizoriu',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Sperma de bovine',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Deseuri de peste',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Altele',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Tendoane si vene unghii si deseuri similare de piei brute',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane In stare bruta',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Altele',
    'Produse de origine animala nedenumite si necuprinse in alta parte animale moarte de la capitolele sau  improprii alimentatiei umane Altele',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Zambile',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Narcise',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Lalele',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Gladiole',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Altele',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Puieti plante si radacini de cicoare',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Orhidee zambile narcise si lalele',
    'Bulbi tuberculi cepe radacini tuberculate grife si rizomi in repaus vegetativ in vegetatie sau in floare puieti plante si radacini de cicoare altele decat radacinile de la pozitia Altele',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci De vita de vie',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Altele',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Butasi de vita de vie altoiti sau radacinosi',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Cu radacini curaate',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Citrice',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Altele',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Rododendroni si azalee altoiti sau nealtoiti',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Trandafiri altoiti sau nealtoiti',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Spori de ciuperci',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Puieti de ananas',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Rasaduri de legume si rasaduri de capsuni',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci De padure',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Butasi radacinosi si plante tinere',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Cu radacini curaate',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Conifere si plante sempervirescente',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Altele',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Alte plante care cresc in aer liber',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Butasi radacinosi si plante tinere cu exceptia cactusilor',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Plante cu flori imbobocite sau in floare cu exceptia cactusilor',
    'Alte plante vii inclusiv radacinile acestora butasi si altoi spori de ciuperci Altele',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Trandafiri',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Garoafe',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Orhidee',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Crizanteme',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Crini Lilium spp',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Gladiole',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Ranunculi',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Flori si boboci de flori taiate pentru buchete sau ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Muschiul renului',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Pomi de Craciun',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Ramuri de conifere',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Muschiul renului',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Neprelucrate altfel decat prin uscare',
    'Frunze ramuri si alte parti de plante fara flori si fara boboci de flori ierburi muschi si licheni pentru buchete si ornamente proaspete uscate albite vopsite impregnate sau altfel pregatite Altele',
    'Cartofi in stare proaspata sau refrigerata Destinati insamantarii',
    'Cartofi in stare proaspata sau refrigerata Destinati fabricarii amidonului',
    'Cartofi in stare proaspata sau refrigerata Cartofi noi de la ianuarie la iunie',
    'Cartofi in stare proaspata sau refrigerata Altele',
    'Tomate in stare proaspata sau refrigerata Tomate in stare proaspata sau refrigerata',
    'Ceapa ceapa esalota usturoi praz si alte legume aliacee in stare proaspata sau refrigerata Destinata insamantarii',
    'Ceapa ceapa esalota usturoi praz si alte legume aliacee in stare proaspata sau refrigerata Altele',
    'Ceapa ceapa esalota usturoi praz si alte legume aliacee in stare proaspata sau refrigerata Ceapa esalota',
    'Ceapa ceapa esalota usturoi praz si alte legume aliacee in stare proaspata sau refrigerata Usturoi',
    'Ceapa ceapa esalota usturoi praz si alte legume aliacee in stare proaspata sau refrigerata Praz si alte legume aliacee',
    'Varza conopida varza creata gulii si produse comestibile similare din genul Brassica in stare proaspata sau refrigerata Conopida si conopida broccoli',
    'Varza conopida varza creata gulii si produse comestibile similare din genul Brassica in stare proaspata sau refrigerata Altele',
    'Varza conopida varza creata gulii si produse comestibile similare din genul Brassica in stare proaspata sau refrigerata Varza de Bruxelles',
    'Varza conopida varza creata gulii si produse comestibile similare din genul Brassica in stare proaspata sau refrigerata Varza alba si varza rosie',
    'Varza conopida varza creata gulii si produse comestibile similare din genul Brassica in stare proaspata sau refrigerata Altele',
    'Salata verde Lactuca sativa si cicoare Cichorium spp in stare proaspata sau refrigerata Salata verde varietatea cu capatana',
    'Salata verde Lactuca sativa si cicoare Cichorium spp in stare proaspata sau refrigerata Altele',
    'Salata verde Lactuca sativa si cicoare Cichorium spp in stare proaspata sau refrigerata Cicoare Witloof Cichorium intybus var foliosum',
    'Salata verde Lactuca sativa si cicoare Cichorium spp in stare proaspata sau refrigerata Altele',
    'Morcovi napi sfecla rosie pentru salata barbacaprei telina de radacina ridichi si radacinoase comestibile similare in stare proaspata sau refrigerata Morcovi si napi',
    'Morcovi napi sfecla rosie pentru salata barbacaprei telina de radacina ridichi si radacinoase comestibile similare in stare proaspata sau refrigerata Telina de radacina',
    'Morcovi napi sfecla rosie pentru salata barbacaprei telina de radacina ridichi si radacinoase comestibile similare in stare proaspata sau refrigerata Hrean Cochlearia armoracia',
    'Morcovi napi sfecla rosie pentru salata barbacaprei telina de radacina ridichi si radacinoase comestibile similare in stare proaspata sau refrigerata Altele',
    'Castraveti si cornison in stare proaspata sau refrigerata Castraveti',
    'Castraveti si cornison in stare proaspata sau refrigerata Cornison',
    'Legume pastai curatate sau nu de pastai in stare proaspata sau refrigerata Mazare Pisum sativum',
    'Legume pastai curatate sau nu de pastai in stare proaspata sau refrigerata Fasole Vigna spp Phaseolus spp',
    'Legume pastai curatate sau nu de pastai in stare proaspata sau refrigerata Alte legume pastai',
    'Alte legume in stare proaspata sau refrigerata Sparanghel',
    'Alte legume in stare proaspata sau refrigerata Vinete',
    'Alte legume in stare proaspata sau refrigerata Telina alta decat telina de radacina',
    'Alte legume in stare proaspata sau refrigerata Ciuperci din genul Agaricus',
    'Alte legume in stare proaspata sau refrigerata Ciuperci din genul Boletus',
    'Alte legume in stare proaspata sau refrigerata Ciuperci din genul Cantharellus',
    'Alte legume in stare proaspata sau refrigerata Shiitake Lentinus edodes',
    'Alte legume in stare proaspata sau refrigerata Matsutake Tricholoma matsutake Tricholoma magnivelare Tricholoma anatolicum Tricholoma dulciolens Tricholoma caligatum',
    'Alte legume in stare proaspata sau refrigerata Trufe Tuber spp',
    'Alte legume in stare proaspata sau refrigerata Altele',
    'Alte legume in stare proaspata sau refrigerata Ardei grasi',
    'Alte legume in stare proaspata sau refrigerata Din genul Capsicum destinate fabricarii capsicinei sau a extractelor de oleorasini de Capsicum',
    'Alte legume in stare proaspata sau refrigerata Destinate fabricarii industriale a uleiurilor esentiale sau a rezinoidelor',
    'Alte legume in stare proaspata sau refrigerata Altele',
    'Alte legume in stare proaspata sau refrigerata Spanac ghizdei spanac de Noua Zeelanda si loboda',
    'Alte legume in stare proaspata sau refrigerata Anghinare',
    'Alte legume in stare proaspata sau refrigerata Destinate altor utilizari decat pentru productia de ulei',
    'Alte legume in stare proaspata sau refrigerata Altele',
    'Alte legume in stare proaspata sau refrigerata Dovlecei',
    'Alte legume in stare proaspata sau refrigerata Altele',
    'Alte legume in stare proaspata sau refrigerata Salata alta decat salata verde Lactuca sativa si cicoarea Cichorium spp',
    'Alte legume in stare proaspata sau refrigerata Cardon anghinare americana si frunze comestibile ale acesteia',
    'Alte legume in stare proaspata sau refrigerata Capere',
    'Alte legume in stare proaspata sau refrigerata Fenicul',
    'Alte legume in stare proaspata sau refrigerata Porumb dulce',
    'Alte legume in stare proaspata sau refrigerata Altele',
    'Legume nefierte sau fierte in apa sau in abur congelate Cartofi',
    'Legume nefierte sau fierte in apa sau in abur congelate Mazare Pisum sativum',
    'Legume nefierte sau fierte in apa sau in abur congelate Fasole Vigna spp Phaseolus spp',
    'Legume nefierte sau fierte in apa sau in abur congelate Altele',
    'Legume nefierte sau fierte in apa sau in abur congelate Spanac ghizdei spanac de Noua Zeelanda si loboda',
    'Legume nefierte sau fierte in apa sau in abur congelate Porumb dulce',
    'Legume nefierte sau fierte in apa sau in abur congelate Masline',
    'Legume nefierte sau fierte in apa sau in abur congelate Ardei grasi',
    'Legume nefierte sau fierte in apa sau in abur congelate Altele',
    'Legume nefierte sau fierte in apa sau in abur congelate Din genul Agaricus',
    'Legume nefierte sau fierte in apa sau in abur congelate Altele',
    'Legume nefierte sau fierte in apa sau in abur congelate Tomate',
    'Legume nefierte sau fierte in apa sau in abur congelate Anghinare',
    'Legume nefierte sau fierte in apa sau in abur congelate Sparanghel',
    'Legume nefierte sau fierte in apa sau in abur congelate Altele',
    'Legume nefierte sau fierte in apa sau in abur congelate Amestecuri de legume',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Destinate altor utilizari decat pentru productia de ulei',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Altele',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Castraveti si cornison',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Ciuperci de genul Agaricus',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Altele',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Ardei din genul Capsicum sau din genul Pimenta cu exceptia ardeilor grasi',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Porumb dulce',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Ceapa',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Capere',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Altele',
    'Legume conservate provizoriu dar improprii consumului in aceasta stare Amestecuri de legume',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Ceapa',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Ciuperci din genul Agaricus',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Ciuperci din genul Auricularia',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Ciuperci din genul Tremella',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Shiitake Lentinus edodes',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Altele',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Cartofi chiar taiati in bucati sau in felii dar nepreparati in alt mod',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Hibrizi destinati insamantarilor',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Altele',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Tomate',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Morcovi',
    'Legume uscate chiar taiate felii sau bucati sau chiar sfaramate sau pulverizate dar nepreparate altfel Altele',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Destinata insamantarii',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Altele',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Naut',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Fasole din speciile Vigna mungo L Hepper sau Vigna radiata L Wilczek',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Fasole mica rosie Adzuki Phaseolus sau Vigna angularis',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Destinata insamantarii',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Altele',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Bambara beans Vigna subterranea sau Voandzeia subterranea',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Fasole  ochi negri Vigna unguiculata',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Altele',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Linte',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Bob mare Vicia faba var major si mazariche Vicia faba var equina Vicia faba var minor',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Mazare de Angola Cajanus cajan',
    'Legume pastai uscate curatate de pastai chiar decorticate sau sfaramate Altele',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Radacini de manioc',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Proaspete intregi destinate consumului alimentar',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Altele',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Igname Dioscorea spp',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Taro Colocasia spp',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Yautia Xanthosoma spp',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Radacini de arorut si de salep radacini si tuberculi similari cu un continut ridicat de fecule',
    'Radacini de manioc de arorut sau de salep topinamburi batate si radacini si tuberculi similari cu continut ridicat de fecule sau inulina proaspete refrigerate congelate sau uscate chiar taiate in bucati sau aglomerate sub forma de pelete miez de sagotier Altele',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita Uscate',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita In coaja interna endocarp',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita Altele',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Nuci de cocos nuci de Brazilia si nuci de cajou proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Amare',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Altele',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Amare',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Altele',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Decojit',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita In coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Decojite',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Nuci de cola Cola spp',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Nuci de arec sau betel',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Seminte de pin  cu coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Seminte de pin  fara coaja',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Nuci Pecan',
    'Alte fructe cu coaja proaspete sau uscate chiar decojite sau fara pielita Altele',
    'Banane inclusiv din specia Musa paradisiaca plantains proaspete sau uscate Proaspete',
    'Banane inclusiv din specia Musa paradisiaca plantains proaspete sau uscate Uscate',
    'Banane inclusiv din specia Musa paradisiaca plantains proaspete sau uscate Pltano de Canarias',
    'Banane inclusiv din specia Musa paradisiaca plantains proaspete sau uscate Altele',
    'Banane inclusiv din specia Musa paradisiaca plantains proaspete sau uscate Uscate',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Curmale',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Proaspete',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Uscate',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Ananas',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Avocado',
    'Curmale smochine ananas avocado guave mango si mangustan proaspete sau uscate Guave mango si mangustan',
    'Citrice proaspete sau uscate Portocale Navel',
    'Citrice proaspete sau uscate Portocale albe',
    'Citrice proaspete sau uscate Altele',
    'Citrice proaspete sau uscate Altele',
    'Citrice proaspete sau uscate Satsuma',
    'Citrice proaspete sau uscate Altele',
    'Citrice proaspete sau uscate Clementine',
    'Citrice proaspete sau uscate Altele',
    'Citrice proaspete sau uscate Grepfrut si pomelo',
    'Citrice proaspete sau uscate Lamai Citrus limon Citrus limonum',
    'Citrice proaspete sau uscate Lamai mici lime Citrus aurantifolia Citrus latifolia',
    'Citrice proaspete sau uscate Altele',
    'Struguri proaspeti sau uscati De masa',
    'Struguri proaspeti sau uscati Altele',
    'Struguri proaspeti sau uscati Struguri de Corint',
    'Struguri proaspeti sau uscati Stafide sultanine',
    'Struguri proaspeti sau uscati Altele',
    'Pepeni inclusiv pepeni verzi si papaia proaspeti Pepeni verzi',
    'Pepeni inclusiv pepeni verzi si papaia proaspeti Altele',
    'Pepeni inclusiv pepeni verzi si papaia proaspeti Papaia',
    'Mere pere si gutui proaspete Mere pentru fabricarea cidrului prezentate in vrac de la septembrie la decembrie',
    'Mere pere si gutui proaspete Altele',
    'Mere pere si gutui proaspete Pere pentru fabricarea cidrului prezentate in vrac de la august la decembrie',
    'Mere pere si gutui proaspete Altele',
    'Mere pere si gutui proaspete Gutui',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Caise',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Visine Prunus cerasus',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Altele',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Piersici plate Prunus persica var platycarpa si nectarine plate Prunus persica var platerina',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Nectarine',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Altele',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Prune',
    'Caise cirese visine piersici inclusiv nectarine prune si porumbe proaspete Porumbe',
    'Alte fructe proaspete Capsuni fragi',
    'Alte fructe proaspete Zmeura',
    'Alte fructe proaspete Altele',
    'Alte fructe proaspete Coacaze negre',
    'Alte fructe proaspete Coacaze rosii',
    'Alte fructe proaspete Altele',
    'Alte fructe proaspete Merisor fructe din specia Vaccinium vitis idaea',
    'Alte fructe proaspete Afine fructe din specia Vaccinium myrtillus',
    'Alte fructe proaspete Fructe din specia Vaccinium macrocarpon si din specia Vaccinium corymbosum',
    'Alte fructe proaspete Altele',
    'Alte fructe proaspete Kiwi',
    'Alte fructe proaspete Durian',
    'Alte fructe proaspete Kaki',
    'Alte fructe proaspete Tamarine mere de cajou litchi fructe de jaquier arbore de paine sapotile fructele pasiunii carambola i pitahaya',
    'Alte fructe proaspete Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Cu un continut de zahar peste  din greutate',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Cu un continut de zahar peste  din greutate',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Zmeura',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Coacaze negre',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Coacaze rosii',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Dude mure si hibrizi de zmeura si mure',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Fructe tropicale si fructe tropicale cu coaja',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Fructe tropicale si fructe tropicale cu coaja',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Afine fructe din specia Vaccinium myrtillus',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Fructe din specia Vaccinium myrtilloides si din specia Vaccinium angustifolium',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Visine Prunus cerasus',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Fructe tropicale si fructe tropicale cu coaja',
    'Fructe fierte sau nu in apa sau in abur congelate chiar cu adaos de zahar sau alti indulcitori Altele',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Cirese visine',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Caise portocale',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Papaya',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Afine fructe din specia Vaccinium myrtillus',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Guave mango mangustan tamarine mere de cajou litchi fructe de jaquier arbore de paine fructe de sapotier fructele pasiunii carambola pitahaia si fructe tropicale cu coaja',
    'Fructe conservate provizoriu dar improprii alimentatiei in aceasta stare Altele',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Caise',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Prune',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Mere',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Piersici inclusiv nectarine',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Pere',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Papaya',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Tamarine mere de cajou litchi fructe de jaquier arbore de paine sapotile fructele pasiunii carambola i pitahaya',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Altele',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol De papaya tamarin mere de cajou litchi fructe de jaquier arbore de paine fructe de sapotier fructele pasiunii carambola si pitahaia',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Altele',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Care contin prune',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Din fructe tropicale cu coaja',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Altele',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Care nu contin prune sau smochine',
    'Fructe uscate altele decat cele de la pozitiile amestecuri de fructe uscate sau de fructe cu coaja de la acest capitol Altele',
    'Coji de citrice sau de pepeni inclusiv pepeni verzi proaspete congelate uscate prezentate in apa sarata sulfurate sau cu adaos de alte substante care asigura provizoriu conservarea lor Coji de citrice sau de pepeni inclusiv pepeni verzi proaspete congelate uscate prezentate in apa sarata sulfurate sau cu adaos de alte substante care asigura provizoriu conservarea lor',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Nedecafeinizata',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Decafeinizata',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Nedecafeinizata',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Decafeinizata',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Coji si pelicule de cafea',
    'Cafea chiar prajita sau decafeinizata coji si pelicule de cafea inlocuitori de cafea care contin cafea indiferent de proportiile amestecului Inlocuitori de cafea care contin cafea',
    'Ceai chiar aromatizat Ceai verde nefermentat prezentat in ambalaje directe cu un continut de maximum kg',
    'Ceai chiar aromatizat Ceai verde nefermentat altfel prezentat',
    'Ceai chiar aromatizat Ceai negru fermentat si ceai partial fermentat prezentat in ambalaje directe cu un continut de maximum kg',
    'Ceai chiar aromatizat Ceai negru fermentat si ceai partial fermentat altfel prezentat',
    'Mat Mat',
    'Piper din genul Piper ardei din genul Capsicum sau din genul Pimenta uscat sau sfaramat sau macinat Nesfaramat si nemacinat',
    'Piper din genul Piper ardei din genul Capsicum sau din genul Pimenta uscat sau sfaramat sau macinat Sfaramat sau macinat',
    'Piper din genul Piper ardei din genul Capsicum sau din genul Pimenta uscat sau sfaramat sau macinat Ardei grasi Capsicum annuum',
    'Piper din genul Piper ardei din genul Capsicum sau din genul Pimenta uscat sau sfaramat sau macinat Altele',
    'Piper din genul Piper ardei din genul Capsicum sau din genul Pimenta uscat sau sfaramat sau macinat Sfaramati sau macinati',
    'Vanilie Nesfaramata si nemacinata',
    'Vanilie Sfaramata sau macinata',
    'Scortisoara si flori de scortisoara Scortisoara Cinnamonum zeylanicum Blume',
    'Scortisoara si flori de scortisoara Altele',
    'Scortisoara si flori de scortisoara Sfaramate sau macinate',
    'Cuisoare fructe intregi cuisoare si codite Nesfaramate si nemacinate',
    'Cuisoare fructe intregi cuisoare si codite Sfaramate sau macinate',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Nesfaramata si nemacinata',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Sfaramata sau macinata',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Nesfaramata si nemacinata',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Sfaramata sau macinata',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Nesfaramat si nemacinat',
    'Nucsoara mirodenie din coaja uscata a nucsoarei si cardamom Sfaramat sau macinat',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Nesfaramate si nemacinate',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Sfaramate sau macinate',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Nesfaramate si nemacinate',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Sfaramate sau macinate',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Nesfaramate si nemacinate',
    'Seminte de anason de badian de fenicul de coriandru de chimen de chimion bace de ienupar Sfaramate sau macinate',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Nesfaramat si nemacinat',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Sfaramat sau macinat',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Nesfaramat i nemacinat',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Sfaramat sau macinat',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Curcuma',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Curry',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Nesfaramate i nemacinate',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Sfaramate sau macinate',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Seminte de schinduf',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Cimbrisor Thymus serpyllum L',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Altele',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Sfaramat sau macinat',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Frunze de dafin',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Nesfaramate i nemacinate',
    'Ghimbir sofran curcuma cimbru frunze de dafin curry si alte mirodenii Sfaramate sau macinate',
    'Grau si meslin Destinat insamantarii',
    'Grau si meslin Altele',
    'Grau si meslin Triticum spelta',
    'Grau si meslin Grau comun si meslin',
    'Grau si meslin Altele',
    'Grau si meslin Altele',
    'Secara Destinata insamantarii',
    'Secara Altele',
    'Orz Destinat insamantarii',
    'Orz Altele',
    'Ovaz Destinat insamantarii',
    'Ovaz Altele',
    'Porumb Hibrizi incrucisati de trei ori',
    'Porumb Hibrizi simpli',
    'Porumb Altele',
    'Porumb Altele',
    'Porumb Altele',
    'Orez Destinat insamantarii',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Cu bobul rotund',
    'Orez Cu bobul mijlociu',
    'Orez Care au raportul lungimelatime peste  dar sub',
    'Orez Care au raportul lungimelatime minimum',
    'Orez Altele',
    'Orez Brizura de orez',
    'Sorg boabe Hibrizi',
    'Sorg boabe Altele',
    'Sorg boabe Altele',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Hrisca',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Destinat insamantarii',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Altele',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Seminte de iarbacanarasului Phalaris canariensis',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Meisor Digitaria spp',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Quinoa Chenopodium quinoa',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Triticale',
    'Hrisca mei seminte de iarbacanarasului Phalaris canariensis alte cereale Alte cereale',
    'Faina de grau sau de meslin De grau dur',
    'Faina de grau sau de meslin De grau comun si de alac',
    'Faina de grau sau de meslin De meslin',
    'Faina de cereale alta decat de grau sau de meslin Cu un continut de grasimi de maximum  din greutate',
    'Faina de cereale alta decat de grau sau de meslin Altele',
    'Faina de cereale alta decat de grau sau de meslin Faina de orz',
    'Faina de cereale alta decat de grau sau de meslin Faina de ovaz',
    'Faina de cereale alta decat de grau sau de meslin Faina de orez',
    'Faina de cereale alta decat de grau sau de meslin Faina de secara',
    'Faina de cereale alta decat de grau sau de meslin Altele',
    'Crupe gris si aglomerate sub forma de pelete din cereale De grau dur',
    'Crupe gris si aglomerate sub forma de pelete din cereale De grau comun si de alac',
    'Crupe gris si aglomerate sub forma de pelete din cereale Cu un continut de grasimi de maximum  din greutate',
    'Crupe gris si aglomerate sub forma de pelete din cereale Altele',
    'Crupe gris si aglomerate sub forma de pelete din cereale Din secara sau orz',
    'Crupe gris si aglomerate sub forma de pelete din cereale De ovaz',
    'Crupe gris si aglomerate sub forma de pelete din cereale De orez',
    'Crupe gris si aglomerate sub forma de pelete din cereale Altele',
    'Crupe gris si aglomerate sub forma de pelete din cereale Din secara sau orz',
    'Crupe gris si aglomerate sub forma de pelete din cereale De ovaz',
    'Crupe gris si aglomerate sub forma de pelete din cereale De porumb',
    'Crupe gris si aglomerate sub forma de pelete din cereale De orez',
    'Crupe gris si aglomerate sub forma de pelete din cereale De grau',
    'Crupe gris si aglomerate sub forma de pelete din cereale Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Boabe presate',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Sub forma de fulgi',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De grau',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De secara',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De porumb',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Boabe presate',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Sub forma de fulgi',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Fulgi de orez',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Decojite decorticate chiar taiate sau zdrobite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Lustruite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Decojite decorticate chiar taiate sau zdrobite lustruite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Decojite decorticate chiar taiate sau zdrobite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Lustruite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Decojite decorticate chiar taiate sau zdrobite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Lustruite',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De grau',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De secara',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De grau',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De secara',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti De grau',
    'Boabe de cereale altfel prelucrate de exemplu decojite presate sub forma de fulgi lustruite taiate sau zdrobite cu exceptia orezului de la pozitia germeni de cereale intregi presati sub forma de fulgi sau zdrobiti Altele',
    'Faina gris pudra fulgi granule si aglomerate sub forma de pelete din cartofi Faina gris si pudra',
    'Faina gris pudra fulgi granule si aglomerate sub forma de pelete din cartofi Fulgi granule si aglomerate sub forma de pelete',
    'Faina gris si pudra din legume pastai uscate de la pozitia din sago sau din radacini sau tuberculi de la pozitia si din produsele de la capitolul Din legume pastai uscate de la pozitia',
    'Faina gris si pudra din legume pastai uscate de la pozitia din sago sau din radacini sau tuberculi de la pozitia si din produsele de la capitolul Denaturate',
    'Faina gris si pudra din legume pastai uscate de la pozitia din sago sau din radacini sau tuberculi de la pozitia si din produsele de la capitolul Altele',
    'Faina gris si pudra din legume pastai uscate de la pozitia din sago sau din radacini sau tuberculi de la pozitia si din produsele de la capitolul Din banane',
    'Faina gris si pudra din legume pastai uscate de la pozitia din sago sau din radacini sau tuberculi de la pozitia si din produsele de la capitolul Altele',
    'Malt chiar prajit Prezentat sub forma de faina',
    'Malt chiar prajit Altele',
    'Malt chiar prajit Prezentat sub forma de faina',
    'Malt chiar prajit Altele',
    'Malt chiar prajit Prajit',
    'Amidon si fecule inulina Amidon de grau',
    'Amidon si fecule inulina Amidon de porumb',
    'Amidon si fecule inulina Fecule de cartofi',
    'Amidon si fecule inulina Fecule de manioc',
    'Amidon si fecule inulina Amidon de orez',
    'Amidon si fecule inulina Altele',
    'Amidon si fecule inulina Inulina',
    'Gluten de grau chiar uscat Gluten de grau chiar uscat',
    'Boabe de soia chiar sfaramate Destinate insamantarii',
    'Boabe de soia chiar sfaramate Altele',
    'Arahide neprajite si nici altfel preparate termic chiar decorticate sau sfaramate Destinate insamantarii',
    'Arahide neprajite si nici altfel preparate termic chiar decorticate sau sfaramate In coaja',
    'Arahide neprajite si nici altfel preparate termic chiar decorticate sau sfaramate Decorticate chiar sfaramate',
    'Copra Copra',
    'Seminte de in chiar sfaramate Destinate insamantarii',
    'Seminte de in chiar sfaramate Altele',
    'Seminte de rapita sau de rapita salbatica chiar sfaramate Destinate insamantarii',
    'Seminte de rapita sau de rapita salbatica chiar sfaramate Altele',
    'Seminte de rapita sau de rapita salbatica chiar sfaramate Altele',
    'Seminte de floareasoarelui chiar sfaramate Destinate insamantarii',
    'Seminte de floareasoarelui chiar sfaramate Decorticate in coji cu dungi albe sau gri',
    'Seminte de floareasoarelui chiar sfaramate Altele',
    'Alte seminte si fructe oleaginoase chiar sfaramate Nuci si samburi de nuci de palmier',
    'Alte seminte si fructe oleaginoase chiar sfaramate Destinate insamantarii',
    'Alte seminte si fructe oleaginoase chiar sfaramate Altele',
    'Alte seminte si fructe oleaginoase chiar sfaramate Seminte de ricin',
    'Alte seminte si fructe oleaginoase chiar sfaramate Destinate insamantarii',
    'Alte seminte si fructe oleaginoase chiar sfaramate Altele',
    'Alte seminte si fructe oleaginoase chiar sfaramate Destinate insamantarii',
    'Alte seminte si fructe oleaginoase chiar sfaramate Altele',
    'Alte seminte si fructe oleaginoase chiar sfaramate Seminte de sofranas Carthamus tinctorius',
    'Alte seminte si fructe oleaginoase chiar sfaramate Seminte de pepene',
    'Alte seminte si fructe oleaginoase chiar sfaramate Destinate insamantarii',
    'Alte seminte si fructe oleaginoase chiar sfaramate Altele',
    'Alte seminte si fructe oleaginoase chiar sfaramate Destinate insamantarii',
    'Alte seminte si fructe oleaginoase chiar sfaramate Seminte de canepa',
    'Alte seminte si fructe oleaginoase chiar sfaramate Altele',
    'Faina si gris din fructe si seminte oleaginoase altele decat cele de mustar Din boabe de soia',
    'Faina si gris din fructe si seminte oleaginoase altele decat cele de mustar Altele',
    'Seminte fructe si spori destinate insamantarii Seminte de sfecla de zahar',
    'Seminte fructe si spori destinate insamantarii Seminte de lucerna',
    'Seminte fructe si spori destinate insamantarii De trifoi rosu Trifolium pratense L',
    'Seminte fructe si spori destinate insamantarii Altele',
    'Seminte fructe si spori destinate insamantarii De paius de livada Festuca pratensis Huds',
    'Seminte fructe si spori destinate insamantarii De paius rosu Festuca rubra L',
    'Seminte fructe si spori destinate insamantarii Altele',
    'Seminte fructe si spori destinate insamantarii Seminte de planta furajera de Kentucky Poa pratensis L',
    'Seminte fructe si spori destinate insamantarii De raigras aristat Lolium multiflorum Lam',
    'Seminte fructe si spori destinate insamantarii De raigras peren Lolium perenne L',
    'Seminte fructe si spori destinate insamantarii Semine de timoftica mazariche firuta de balta Poa palustris L si suvar Poa trivialis L golomat Dactylis glomerata iarba vantului Agrostis',
    'Seminte fructe si spori destinate insamantarii Seminte de lupin',
    'Seminte fructe si spori destinate insamantarii Seminte de sfecla furajera Beta vulgaris var alba',
    'Seminte fructe si spori destinate insamantarii Altele',
    'Seminte fructe si spori destinate insamantarii Seminte de plante erbacee cultivate in principal pentru flori',
    'Seminte fructe si spori destinate insamantarii Seminte de sfecla rosie Beta vulgaris var conditiva',
    'Seminte fructe si spori destinate insamantarii Altele',
    'Seminte fructe si spori destinate insamantarii Seminte de arbori de padure',
    'Seminte fructe si spori destinate insamantarii Seminte de plante cultivate in principal pentru florile lor altele decat cele mentionate la subpozitia',
    'Seminte fructe si spori destinate insamantarii Altele',
    'Conuri de hamei proaspete sau uscate chiar sfaramate macinate sau sub forma de pulbere sau pelete lupulina Conuri de hamei nesfaramate nemacinate si nici sub forma de pelete',
    'Conuri de hamei proaspete sau uscate chiar sfaramate macinate sau sub forma de pulbere sau pelete lupulina Conuri de hamei sfaramate macinate sau sub forma de pelete cu un continut ridicat de lupulina lupulina',
    'Conuri de hamei proaspete sau uscate chiar sfaramate macinate sau sub forma de pulbere sau pelete lupulina Altele',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Radacini de ginseng',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Frunze de coca',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Tulpini de mac',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Efedra',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Scoarta de cires african Prunus africana',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Seminte de tonka',
    'Plante si parti de plante seminte si fructe din speciile folosite in principal in parfumerie medicina sau ca insecticide fungicide sau in scopuri similare proaspete refrigerate congelate sau uscate chiar taiate sfaramate sau sub forma de pulbere Altele',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Adecvate pentru consumul uman',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Altele',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Uscata chiar macinata',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Altele',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Roscove',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Trestie de zahar',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Radacini de cicoare',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Nedecorticate nezdrobite nemacinate',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Altele',
    'Roscove alge sfecla de zahar si trestie de zahar proaspete refrigerate congelate sau uscate chiar pulverizate samburi si miez de samburi de fructe si alte produse vegetale inclusiv radacini de cicoare neprajite din varietatea Cichorium intybus sativum destinate in principal alimentatiei umane nedenumite si necuprinse in alta parte Altele',
    'Paie si pleava de cereale brute chiar tocate macinate presate sau aglomerate sub forma de pelete Paie si pleava de cereale brute chiar tocate macinate presate sau aglomerate sub forma de pelete',
    'Gulii furajere sfecla furajera radacini furajere fan lucerna trifoi sparceta varza furajera lupin mazariche si alte produse furajere similare chiar aglomerate sub forma de pelete Faina si aglomerate sub forma de pelete de lucerna',
    'Gulii furajere sfecla furajera radacini furajere fan lucerna trifoi sparceta varza furajera lupin mazariche si alte produse furajere similare chiar aglomerate sub forma de pelete Sfecla furajera gulii furajere si alte radacini furajere',
    'Gulii furajere sfecla furajera radacini furajere fan lucerna trifoi sparceta varza furajera lupin mazariche si alte produse furajere similare chiar aglomerate sub forma de pelete Altele',
    'Selac gume rasini gumerasini si oleorasini de exemplu balsamuri naturale Guma arabica',
    'Selac gume rasini gumerasini si oleorasini de exemplu balsamuri naturale Altele',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Opiu',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Din lemndulce',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Din hamei',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Din efedra',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Oleorasini de vanilie',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Altele',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Uscate',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Altele',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Agaragar',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Din roscove sau din boabe de roscove',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Din seminte de guar',
    'Seve si extracte vegetale substante pectice pectinati si pectati agaragar si alte mucilagii si agenti de marire a viscozitatii derivate din produse vegetale chiar modificate Altele',
    'Materii vegetale de tipul celor folosite in principal in industria impletiturilor de exemplu bambus ramuri de palmier papura stuf trestie rachita rafie paie de cereale curatate albite sau vopsite coaja de tei Bambus',
    'Materii vegetale de tipul celor folosite in principal in industria impletiturilor de exemplu bambus ramuri de palmier papura stuf trestie rachita rafie paie de cereale curatate albite sau vopsite coaja de tei Ramuri de palmier',
    'Materii vegetale de tipul celor folosite in principal in industria impletiturilor de exemplu bambus ramuri de palmier papura stuf trestie rachita rafie paie de cereale curatate albite sau vopsite coaja de tei Altele',
    'Produse vegetale nedenumite si necuprinse in alta parte Linters de bumbac',
    'Produse vegetale nedenumite si necuprinse in alta parte Altele',
    'Grasime de porc inclusiv untura si grasime de pasare altele decat cele de la pozitiile sau  Destinata utilizarilor industriale alta decat cea destinata fabricarii produselor pentru alimentatia umana',
    'Grasime de porc inclusiv untura si grasime de pasare altele decat cele de la pozitiile sau  Altele',
    'Grasime de porc inclusiv untura si grasime de pasare altele decat cele de la pozitiile sau  Destinata utilizarilor industriale alta decat cea destinata fabricarii produselor pentru alimentatia umana',
    'Grasime de porc inclusiv untura si grasime de pasare altele decat cele de la pozitiile sau  Altele',
    'Grasime de porc inclusiv untura si grasime de pasare altele decat cele de la pozitiile sau  Altele',
    'Grasimi de animale din speciile bovine ovine sau caprine altele decat cele de la pozitia Destinate utilizarilor industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Grasimi de animale din speciile bovine ovine sau caprine altele decat cele de la pozitia Altele',
    'Grasimi de animale din speciile bovine ovine sau caprine altele decat cele de la pozitia Destinata utilizarilor industriale alta decat cea destinata fabricarii produselor pentru alimentatia umana',
    'Grasimi de animale din speciile bovine ovine sau caprine altele decat cele de la pozitia Altele',
    'Stearina din untura ulei de untura oleostearina oleomargarina si ulei de seu neemulsionate neamestecate si nici altfel preparate Destinate utilizarilor industriale',
    'Stearina din untura ulei de untura oleostearina oleomargarina si ulei de seu neemulsionate neamestecate si nici altfel preparate Altele',
    'Stearina din untura ulei de untura oleostearina oleomargarina si ulei de seu neemulsionate neamestecate si nici altfel preparate Ulei de seu destinat utilizarilor industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Stearina din untura ulei de untura oleostearina oleomargarina si ulei de seu neemulsionate neamestecate si nici altfel preparate Altele',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Cu un continut de vitaminaA egal sau mai mic de unitati internationale per gram',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic De halibut',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Altele',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Fractiuni solide',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Altele',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Fractiuni solide',
    'Grasimi si uleiuri si fractiunile acestora de peste sau de mamifere marine chiar rafinate dar nemodificate chimic Altele',
    'Usuc si grasimi derivate din acesta inclusiv lanolina Usuc brut suintina',
    'Usuc si grasimi derivate din acesta inclusiv lanolina Altele',
    'Alte grasimi si uleiuri de origine animala si fractiunile acestora chiar rafinate dar nemodificate chimic Alte grasimi si uleiuri de origine animala si fractiunile acestora chiar rafinate dar nemodificate chimic',
    'Ulei de soia si fractiunile acestuia chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de soia si fractiunile acestuia chiar rafinate dar nemodificate chimic Altele',
    'Ulei de soia si fractiunile acestuia chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de soia si fractiunile acestuia chiar rafinate dar nemodificate chimic Altele',
    'Ulei de arahide si fractiunile acestuia chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de arahide si fractiunile acestuia chiar rafinate dar nemodificate chimic Altele',
    'Ulei de arahide si fractiunile acestuia chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de arahide si fractiunile acestuia chiar rafinate dar nemodificate chimic Altele',
    'Ulei de masline si fractiunile acestuia chiar rafinate dar nemodificate chimic Ulei de masline extravirgin',
    'Ulei de masline si fractiunile acestuia chiar rafinate dar nemodificate chimic Ulei de masline virgin',
    'Ulei de masline si fractiunile acestuia chiar rafinate dar nemodificate chimic Alte uleiuri de masline virgine',
    'Ulei de masline si fractiunile acestuia chiar rafinate dar nemodificate chimic Altele',
    'Alte uleiuri si fractiunile acestora obtinute numai din masline chiar rafinate dar nemodificate chimic si amestecurile acestor uleiuri sau fractiuni cu uleiuri sau fractiuni de la pozitia Ulei brut din resturi de masline',
    'Alte uleiuri si fractiunile acestora obtinute numai din masline chiar rafinate dar nemodificate chimic si amestecurile acestor uleiuri sau fractiuni cu uleiuri sau fractiuni de la pozitia Altele',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Altele',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Altfel prezentate',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de palmier si fractiunile lui chiar rafinate dar nemodificate chimic Altele',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic De floareasoarelui',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic De ofrana',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de seminte de floareasoarelui de ofrana sau de seminte de bumbac si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Ulei de cocos ulei de copra de samburi de palmier sau de babassu si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Uleiuri de rapita de rapita salbatica sau de mustar si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Ulei brut',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate producerii acidului aminoundecanoic fabricarii fibrelor sintetice sau a materialelor plastice',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide prezentate in ambalaje directe cu un continut net de maximum kg',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide altfel prezentate fluide',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide prezentate in ambalaje directe cu un continut net de maximum kg',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide altfel prezentate fluide',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Ulei de tung uleiuri de jojoba de oiticica ceara de myrica ceara de Japonia fractiunile acestora',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinat utilizarilor tehnice sau industriale altul decat cel destinat fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Altele',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide prezentate in ambalaje directe cu un continut net de maximum kg',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide altfel prezentate fluide',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide prezentate in ambalaje directe cu un continut net de maximum kg',
    'Alte grasimi si uleiuri fixe de origine vegetala sau microbiana inclusiv ulei de jojoba si fractiunile acestora chiar rafinate dar nemodificate chimic Solide altfel prezentate fluide',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Altele',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Uleiuri de ricin hidrogenate numite ceara opal',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Uleiuri de rapita de rapita salbatica de in de floareasoarelui de ilipe de karite de makore de touloucouna sau de babassu destinate utilizarilor tehnice sau industriale altele decat cele destinate fabricarii produselor pentru alimentatia umana',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Uleiuri de arahide de seminte de bumbac de boabe de soia sau de floareasoarelui alte uleiuri cu continut sub  din greutate acizi grasi liberi si care nu contin uleiuri din miez de palmier de ilipe de nuci de cocos de rapita de rapita salbatica sau de copaiba',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Altele',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Prezentate in ambalaje directe cu un continut net de maximum kg',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora hidrogenate partial sau total interesterificate reesterificate sau elaidinizate chiar rafinate dar nepreparate altfel Altele',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Cu un continut de grasimi din lapte peste  dar de maximum  din greutate',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Altele',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Cu un continut de grasimi din lapte peste  dar de maximum  din greutate',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Uleiuri fixe grase de origine vegetala fluide amestecate',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Amestecuri sau preparate culinare utilizate pentru a usura scoaterea preparatelor din forme',
    'Margarina amestecuri sau preparate alimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri din prezentul capitol altele decat grasimile si uleiurile alimentare si fractiunile acestora de la pozitia  Altele',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Linoxin',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Brute',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Altele',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Grasimi si uleiuri de origine animal vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Amestecuri sau preparate nealimentare de grasimi uleiuri si fractiunile acestora de origine animala sau  de origine animala si vegetala sau microbiana',
    'Grasimi si uleiuri de origine animala vegetala sau microbiana si fractiunile acestora fierte oxidate deshidratate sulfurate suflate polimerizate prin caldura in vacuum sau in gaz inert sau altfel modificate chimic cu exceptia celor de la pozitia  amestecuri sau preparate nealimentare de grasimi sau de uleiuri de origine animala vegetala sau microbiana sau din fractiunile diferitelor grasimi sau uleiuri de la prezentul capitol nedenumite si necuprinse in alta parte Altele',
    'Glicerina bruta ape si lesii de glicerina Glicerina bruta ape si lesii de glicerina',
    'Ceara de origine vegetala alta decat trigliceridele ceara de albine sau de alte insecte si spermantet chiar rafinata sau colorata Ceara de origine vegetala',
    'Ceara de origine vegetala alta decat trigliceridele ceara de albine sau de alte insecte si spermantet chiar rafinata sau colorata Spermantet chiar rafinat sau colorat',
    'Ceara de origine vegetala alta decat trigliceridele ceara de albine sau de alte insecte si spermantet chiar rafinata sau colorata Bruta',
    'Ceara de origine vegetala alta decat trigliceridele ceara de albine sau de alte insecte si spermantet chiar rafinata sau colorata Altele',
    'Degras reziduuri provenite de la tratarea grasimilor sau a cerii de origine animala sau vegetala Degras',
    'Degras reziduuri provenite de la tratarea grasimilor sau a cerii de origine animala sau vegetala Paste de neutralizare soapstocks',
    'Degras reziduuri provenite de la tratarea grasimilor sau a cerii de origine animala sau vegetala Altele',
    'Degras reziduuri provenite de la tratarea grasimilor sau a cerii de origine animala sau vegetala Uleiuri de borhot sau de drojdie paste de neutralizare soapstocks',
    'Degras reziduuri provenite de la tratarea grasimilor sau a cerii de origine animala sau vegetala Altele',
    'Carnati carnaciori salamuri si produse similare din carne din organe din sange sau din insecte preparate alimentare pe baza acestor produse Din ficat',
    'Carnati carnaciori salamuri si produse similare din carne din organe din sange sau din insecte preparate alimentare pe baza acestor produse Carnati carnaciori salamuri si produse similare uscati sau pentru tartine nefierti',
    'Carnati carnaciori salamuri si produse similare din carne din organe din sange sau din insecte preparate alimentare pe baza acestor produse Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Preparate omogenizate',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De gasca sau de rata',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Care contin numai carne de curcan nefiarta',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Nefierte',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Care contin carne sau organe comestibile de pasari de curte in proportie de minimum  din greutate dar sub  din greutate',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Nefierte',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De animale domestice din specia porcine',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De animale domestice din specia porcine',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Spinari fara coloana vertebrala si parti din acestea inclusiv amestecuri de spinari si jamboane',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Coloana vertebrala si parti din aceasta inclusiv amestecuri de coloana vertebrala si spete',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Alte amestecuri care contin jamboane spete sau spinari si parti din acestea',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Care contin carne sau organe de orice fel inclusiv grasimi de orice natura sau origine in proportie de minimum  dar sub  din greutate',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Care contin carne sau organe de orice fel inclusiv grasimi de orice natura sau origine in proportie de sub  din greutate',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Nefierte amestecuri de carne sau organe fierte si de carne sau organe nefierte',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Corned beef in recipiente inchise ermetic',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Preparate din sange de orice animal',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De vanat sau de iepure',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Care contin carne sau organe de animale domestice din specia porcine',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Nefierte amestecuri de carne sau organe fierte si de carne sau organe nefierte',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De ovine',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte De caprine',
    'Alte preparate si conserve din carne din organe  din sange sau din insecte Altele',
    'Extracte si sucuri de carne de pesti sau de crustacee de moluste sau de alte nevertebrate acvatice In ambalaje directe cu un continut net de maximum kg',
    'Extracte si sucuri de carne de pesti sau de crustacee de moluste sau de alte nevertebrate acvatice Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Somoni',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File crud acoperit doar cu aluat sau pesmet pane chiar preprajit in ulei congelat',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste In recipiente inchise ermetic',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste In ulei de masline',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste In ulei vegetal',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File denumite spate',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste In ulei vegetal',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File denumite spate',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste In ulei vegetal',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File denumite spate',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Bonite Sarda spp',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Din specia Scomber australasicus',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Ansoa Engraulius eurasicholus',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Anghile',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Inotatoare de rechini',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Salmonide altele decat somonii',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File denumite spate',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Pesti din specia Orcynopsis unicolor',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste File crud doar acoperit cu aluat sau pesmet pane chiar preprajit in ulei congelat',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Cod Gadus morhua Gadus ogac Gadus macrocephalus',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Pesti marini din specia Pollachius virens',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Merlucius Merluccius spp Urophycis spp',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Pesti marini de Alaska Theragra chalcogramma si pesti marini din specia Pollachius pollachius',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Altele',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Preparate din surimi',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De somoni',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De salmonide altele decat somonii',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De ansoa Engraulius eurasicholus',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De sardine de bonite de macrouri din specia Scomber scombrus si Scomber japonicus si de pesti din specia Orcynopsis unicolor',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De toni de pesti saritori si de alti pesti de genul Euthynnus',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste De alti pesti',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Caviar',
    'Preparate si conserve din peste caviar si inlocuitorii acestuia preparati din icre de peste Inlocuitori de caviar',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Crabi',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate In ambalaje directe cu un continut net de maximum kg',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Carne de homari preparata pentru fabricarea untului de homar a pastelor pateurilor supelor sau sosurilor de homar',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Alte crustacee',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Stridii',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Scoici St Jacques inclusiv scoicipieptene',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate In recipiente inchise ermetic',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Sepii si calmari',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Caracatite',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Bivalve marine',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Urechea marii',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Melci altii decat melcii de mare',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Castraveti de mare',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Aricidemare',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Meduze',
    'Crustacee moluste si alte nevertebrate acvatice preparate sau conservate Altele',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Destinat rafinarii',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Altele',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Destinat rafinarii',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Altele',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Destinat rafinarii',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Altele',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Cu adaos de aromatizanti sau coloranti',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Zahar alb',
    'Zahar din trestie sau din sfecla si zaharoza chimic pura in stare solida Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Care contin lactoza minimum  din greutate exprimata in lactoza anhidra calculata la materia uscata',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Zahar de artar in stare solida cu adaos de aromatizanti sau de coloranti',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Izoglucoza',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Sub forma de pudra alba cristalina aglomerata sau nu',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Izoglucoza',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Fructoza chimic pura',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Izoglucoza',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Sirop de inulina',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Maltoza chimic pura',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Izoglucoza',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Maltodextrina si sirop de maltodextrina',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Care contin in stare uscata minimum  din greutate zaharoza',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Sub forma de pudra aglomerata sau nu',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Sirop de inulina',
    'Alte zaharuri inclusiv lactoza maltoza glucoza si fructoza levuloza chimic pure in stare solida siropuri de zahar la care nu sau adaugat aromatizanti sau coloranti inlocuitori de miere chiar amestecati cu miere naturala zaharuri si melase caramelizate Altele',
    'Melase rezultate din extractia sau rafinarea zaharului Melase din trestie',
    'Melase rezultate din extractia sau rafinarea zaharului Altele',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Cu un continut de zaharoza mai mic de  din greutate inclusiv zahar invertit calculat in zaharoza',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Cu un continut de zaharoza egal sau mai mare de  din greutate inclusiv zahar invertit calculat in zaharoza',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Extracte de lemn dulce care contin zaharoza peste  din greutate fara adaos de alte substante',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Ciocolata alba',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Paste inclusiv martipan in ambalaje directe cu un continut net de minimum kg',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Pastile pentru gat si dropsuri contra tusei',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Drajeuri si dulciuri similare drajeificate',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Gume si jeleuri inclusiv paste de fructe sub forma de dulciuri',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Bomboane de zahar prelucrat termic umplute sau nu',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Caramele',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Obtinute prin compresie',
    'Produse zaharoase fara cacao inclusiv ciocolata alba Altele',
    'Cacao boabe si sparturi de boabe de cacao crude sau prajite Cacao boabe si sparturi de boabe de cacao crude sau prajite',
    'Coji pelicule pielite si alte resturi de cacao Coji pelicule pielite si alte resturi de cacao',
    'Pasta de cacao chiar degresata Nedegresata',
    'Pasta de cacao chiar degresata Degresata total sau partial',
    'Unt grasime si ulei de cacao Unt grasime si ulei de cacao',
    'Pudra de cacao fara adaos de zahar sau alti indulcitori Pudra de cacao fara adaos de zahar sau alti indulcitori',
    'Ciocolata si alte preparate alimentare care contin cacao Care nu contine zaharoza sau contine zaharoza sub  din greutate inclusiv zahar invertit calculat in zaharoza sau izoglucoza calculata de asemenea in zaharoza',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut de zaharoza de minimum  dar sub  din greutate inclusiv zahar invertit calculat in zaharoza sau izoglucoza calculata de asemenea in zaharoza',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut de zaharoza inclusiv zahar invertit calculat in zaharoza sau izoglucoza calculata de asemenea in zaharoza de minimum  dar sub  din greutate',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut de zaharoza inclusiv zahar invertit calculat in zaharoza sau izoglucoza calculata de asemenea in zaharoza de minimum',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut de unt de cacao de minimum  din greutate sau cu un continut total de unt de cacao si grasimi din lapte de minimum  din greutate',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut total de unt de cacao si grasimi din lapte de minimum  din greutate si sub  din greutate',
    'Ciocolata si alte preparate alimentare care contin cacao Cu un continut de unt de cacao de minimum  din greutate',
    'Ciocolata si alte preparate alimentare care contin cacao Preparate numite chocolate milk crumb',
    'Ciocolata si alte preparate alimentare care contin cacao Glazura de cacao',
    'Ciocolata si alte preparate alimentare care contin cacao Altele',
    'Ciocolata si alte preparate alimentare care contin cacao Umplute',
    'Ciocolata si alte preparate alimentare care contin cacao Cu adaos de cereale nuci sau alte fructe',
    'Ciocolata si alte preparate alimentare care contin cacao Altele',
    'Ciocolata si alte preparate alimentare care contin cacao Care contin alcool',
    'Ciocolata si alte preparate alimentare care contin cacao Altele',
    'Ciocolata si alte preparate alimentare care contin cacao Umplute',
    'Ciocolata si alte preparate alimentare care contin cacao Neumplute',
    'Ciocolata si alte preparate alimentare care contin cacao Dulciuri si inlocuitori ai acestora fabricati din inlocuitori ai zaharului care contin cacao',
    'Ciocolata si alte preparate alimentare care contin cacao Pasta pentru tartine care contine cacao',
    'Ciocolata si alte preparate alimentare care contin cacao Preparate continand cacao pentru bauturi',
    'Ciocolata si alte preparate alimentare care contin cacao Altele',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Preparate potrivite pentru sugari sau pentru copiii de varsta mica prezentate pentru vanzarea cu amanuntul',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Amestecuri si aluaturi pentru prepararea produselor de brutarie de patiserie sau a biscuitilor de la pozitia',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Cu un continut de extract uscat de minimum  din greutate',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Altele',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Care nu contin grasimi din lapte zaharoza izoglucoza glucoza amidon sau fecule sau care contin in greutate sub  grasimi din lapte sub  zaharoza inclusiv zahar invertit sau izoglucoza sub  glucoza amidon sau fecule cu exceptia preparatelor alimentare sub forma de pudra de la pozitiile',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Preparate alimentare sub forma de pudra constituite dintrun amestec de lapte degresat sisau zer si grasimiuleiuri vegetale cu un continut de grasimiuleiuri de maximum   din greutate',
    'Extracte de malt preparate alimentare din faina crupe gris amidon fecule sau extracte de malt care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte preparate alimentare din produsele de la pozitiile care nu contin cacao sau care contin cacao intro proportie de sub  din greutate calculata pe o baza complet degresata nedenumite si necuprinse in alta parte Altele',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Care contin oua',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Care nu contin faina sau gris din grau comun',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Altele',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Care contin pesti crustacee moluste sau alte nevertebrate acvatice in proportie de peste  din greutate',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Care contin carnati carnaciori si produse similare carne si organe de orice fel inclusiv grasimi de orice natura sau origine intro proportie de peste  din greutate',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Fierte',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Altele',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Uscate',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Altele',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Nepreparat',
    'Paste alimentare chiar fierte sau umplute cu carne sau cu alte substante sau chiar altfel preparate cum ar fi spaghete macaroane fidea lasagna gnochi ravioli caneloni cuscus chiar preparat Altele',
    'Tapioca si inlocuitorii sai preparati din fecule sub forma de fulgi granule boabe mici criblura sau alte forme similare Tapioca si inlocuitorii sai preparati din fecule sub forma de fulgi granule boabe mici criblura sau alte forme similare',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Pe baza de porumb',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Pe baza de orez',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Altele',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Preparate de tip Musli pe baza de fulgi de cereale neprajiti',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Pe baza de porumb',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Pe baza de orez',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Altele',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Grau numit bulgur',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Pe baza de orez',
    'Produse pe baza de cereale obtinute prin expandare sau prajire de exemplu corn flakes cereale altele decat porumbul sub forma de boabe sau sub forma de fulgi ori de alte graunte preparate cu exceptia fainii crupelor si a grisului prefierte sau altfel preparate nedenumite si necuprinse in alta parte Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Paine crocanta denumita Knckebrot',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de zaharoza inclusiv zahar invertit calculat in zaharoza sub  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de zaharoza inclusiv zahar invertit calculat in zaharoza de minimum  dar sub  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de zaharoza inclusiv zahar invertit calculat in zaharoza de minimum  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare In ambalaje directe cu un continut net de maximum g',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de grasimi din lapte de minimum  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Sandvis de biscuiti',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de apa peste  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare In ambalaje directe cu un continut net de maximum g',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Sarate umplute sau neumplute',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Pesmet',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Azima mazoth',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Ostii casete goale pentru uz farmaceutic vafe cu capac pasta uscata din faina din amidon sau din fecule in foi si produse similare',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Paine la care nu sa adaugat miere oua branza sau fructe si care are un continut de zahar in substanta uscata de maximum  din greutate si grasimi in substanta uscata de maximum  din greutate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Biscuiti',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Produse extrudate sau expandate sarate sau aromatizate',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Cu un continut de cel putin  in greutate de zaharoza zahar invertit sau izoglucoza',
    'Produse de brutarie de patiserie si biscuiti chiar cu adaos de cacao ostii casete goale de tipul celor utilizate pentru medicamente vafe cu capac paste uscate din faina din amidon sau din fecule in foi si produse similare Altele',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Castraveti si cornison',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Chutney condimente de mango',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Fructe din genul Capsicum altele decat ardeii grasi',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Porumb dulce Zea mays var saccharata',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Ignami batate si parti comestibile similare de plante cu un continut de amidon sau de fecule de minimum  din greutate',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Ciuperci',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Masline',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Ardei grasi',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Fructe tropicale si fructe tropicale cu coaja miez inima de palmier',
    'Legume fructe si alte parti comestibile de plante preparate sau conservate in otet sau acid acetic Altele',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de peste kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de maximum kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic Altele',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de peste kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de maximum kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic Cu un continut de materie uscata de minimum   dar de maximum   din greutate',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de peste kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic In ambalaje directe cu un continut net de maximum kg',
    'Tomate preparate sau conservate altfel decat in otet sau acid acetic Cu un continut de materie uscata mai mare de  din greutate',
    'Ciuperci si trufe preparate sau conservate altfel decat in otet sau acid acetic Conservate provizoriu complet fierte',
    'Ciuperci si trufe preparate sau conservate altfel decat in otet sau acid acetic Altele',
    'Ciuperci si trufe preparate sau conservate altfel decat in otet sau acid acetic Trufe',
    'Ciuperci si trufe preparate sau conservate altfel decat in otet sau acid acetic Altele',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Fierti si nepreparati altfel',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Sub forma de faina gris sau fulgi',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Altele',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Porumb dulce Zea mays var saccharata',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Varza acra capere si masline',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Mazare Pisum sativum si fasole verde',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Ceapa fiarta si nepreparata altfel',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic congelate altele decat produsele de la pozitia Altele',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Legume omogenizate',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Sub forma de faina gris sau fulgi',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Taiati in felii subtiri prajiti sau copti chiar sarati sau aromatizati in ambalaje inchise ermetic pentru consumul direct',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Altele',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Mazare Pisum sativum',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Fasole boabe',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Altele',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Sparanghel',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Masline',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Porumb dulce Zea mays var saccharata',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Muguri de bambus',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Fructe din genul Capsicum altele decat ardeii grasi',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Capere',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Anghinare',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Amestecuri de legume',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Varza acra',
    'Alte legume preparate sau conservate altfel decat in otet sau acid acetic necongelate altele decat produsele de la pozitia Altele',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Ghimbir',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Cirese visine',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Fructe tropicale si fructe tropicale cu coaja',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Altele',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Fructe tropicale si fructe tropicale cu coaja',
    'Legume fructe coji de fructe si alte parti de plante confiate uscate glasate sau cristalizate Altele',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar de peste  din greutate',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Din fructe tropicale',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar de peste  din greutate',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar de peste  din greutate dar de maximum  din greutate',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Piure si pasta de prune in ambalaje directe cu un continut net de peste kg si care sunt destinate prelucrarii industriale',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Piure si pasta de castane',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori De cirese de visine',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori De capsuni de fragi',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori De zmeura',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar de peste  din greutate dar de maximum  din greutate',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Din fructe tropicale si fructe tropicale cu coaja',
    'Dulceturi jeleuri marmelade paste si piureuri de fructe obtinute prin fierbere cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Unt de arahide',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte De peste kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Prajite',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale cu coaja amestecuri care contin minimum  din greutate fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Migdale si fistic prajite',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale cu coaja amestecuri care contin minimum  din greutate fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Migdale si fistic',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  in greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  in greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  in greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fara adaos de zahar',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Segmente de grepfrut si pomelo',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Mandarine inclusiv tangerine si satsuma clementine wilkings si alti hibrizi similari de citrice',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Segmente de grepfrut si pomelo',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Mandarine inclusiv tangerine si satsuma clementine wilkings si alti hibrizi similari de citrice',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fara adaos de zahar',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fara adaos de zahar',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Minimum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Sub kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Peste kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte De maximum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Minimum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Sub kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Minimum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Sub kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu adaos de zahar in ambalaje directe cu un continut net de peste kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu adaos de zahar in ambalaje directe cu un continut net de maximum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fara adaos de zahar',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Miez inima de palmier',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu adaos de zahar in ambalaje directe cu un continut net de peste kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu adaos de zahar in ambalaje directe cu un continut net de maximum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fara adaos de zahar',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte In ambalaje directe cu un continut net de peste kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte In ambalaje directe cu un continut net de maximum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Din fructe tropicale inclusiv amestecuri care contin minimum  din greutate fructe tropicale si fructe tropicale cu coaja',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un titru alcoolic masic existent de maximum  mas',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Cu un continut de zahar de peste  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Ghimbir',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Struguri',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Prune',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Ghimbir',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Fructe tropicale',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Minimum kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Sub kg',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Porumb altul decat porumbul dulce Zea mays var saccharata',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Ignami batate si parti similare comestibile de plante cu un continut de amidon sau de fecula egal sau mai mare de  din greutate',
    'Fructe si alte parti comestibile de plante altfel preparate sau conservate cu sau fara adaos de zahar sau de alti indulcitori sau de alcool nedenumite si necuprinse in alta parte Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta si cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Necongelate cu o valoare Brix de maximum',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta si cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare Brix de maximum',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta si cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de peste kg greutate neta care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de peste kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Concentrate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Concentrate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de peste kg greutate neta care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de peste kg greutate neta care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc de fructe din specia Vaccinium macrocarpon',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de peste kg greutate neta care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc de cirese sau de visine',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc de cirese sau de visine',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Suc din fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu o valoare de maximum kg greutate neta',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori De o valoare de maximum kg greutate neta si cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de peste  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Cu un continut de zahar adaugat de maximum  din greutate',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Care nu contin zahar adaugat',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Amestecuri de sucuri de fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Amestecuri de sucuri de fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Amestecuri de sucuri de fructe tropicale',
    'Sucuri de fructe inclusiv mustul de struguri si apa de cocos si sucuri de legume nefermentate fara adaos de alcool cu sau fara adaos de zahar sau de alti indulcitori Altele',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Extracte esente si concentrate',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Preparate pe baza de extracte esente sau concentrate de cafea',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Altele',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Extracte esente si concentrate',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Pe baza de extracte esente sau concentrate de ceai sau de mat',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Altele',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Cicoare prajita',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Altele',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora De cicoare prajita',
    'Extracte esente si concentrate de cafea de ceai sau de mat si preparate pe baza de aceste produse sau pe baza de cafea de ceai sau de mat cicoare prajita si alti inlocuitori prajiti de cafea si extractele esentele si concentratele acestora Altele',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Drojdii de cultura',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Uscate',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Altele',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Altele',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat In tablete cuburi sau in alte forme similare sau in ambalaje directe cu un continut net de maximum kg',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Altele',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Altele',
    'Drojdii active sau inactive alte microorganisme monocelulare moarte cu exceptia vaccinurilor de la pozitia praf de copt preparat Praf de copt preparat',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Sos de soia',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Ketchup si alte sosuri de tomate',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Faina si pudra de mustar',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Mustar preparat',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Chutney condimente de mango lichide',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Biter aromat cu un titru alcoolic volumic de minimum vol si de maximum vol cu un continut de gentiana mirodenii si ingrediente diverse de la  pana la  din greutate cu un continut de zahar de la  pana la  si prezentat in recipiente cu un continut de maximum l',
    'Preparate pentru sosuri si sosuri preparate condimente si produse de asezonare amestecate faina si pudra de mustar si mustar preparat Altele',
    'Preparate pentru supe ciorbe sau supe crema supe ciorbe sau supe crema preparate preparate alimentare compuse omogenizate Preparate pentru supe ciorbe sau supe crema supe ciorbe sau supe crema preparate',
    'Preparate pentru supe ciorbe sau supe crema supe ciorbe sau supe crema preparate preparate alimentare compuse omogenizate Preparate alimentare compuse omogenizate',
    'Inghetate si alte produse similare sub forma de inghetata comestibile cu sau fara cacao Care nu contin grasimi din lapte sau contin sub  din greutate grasimi din lapte',
    'Inghetate si alte produse similare sub forma de inghetata comestibile cu sau fara cacao De minimum  dar sub',
    'Inghetate si alte produse similare sub forma de inghetata comestibile cu sau fara cacao De minimum',
    'Preparate alimentare nedenumite si necuprinse in alta parte Care nu contin grasimi din lapte zaharoza izoglucoza glucoza amidon sau fecule sau contin sub  grasimi din lapte sub  zaharoza sau izoglucoza sub  glucoza amidon sau fecule',
    'Preparate alimentare nedenumite si necuprinse in alta parte Altele',
    'Preparate alimentare nedenumite si necuprinse in alta parte Preparate alcoolice compuse altele decat cele pe baza de substante odorifiante de tipul celor utilizate pentru fabricarea bauturilor',
    'Preparate alimentare nedenumite si necuprinse in alta parte De izoglucoza',
    'Preparate alimentare nedenumite si necuprinse in alta parte De lactoza',
    'Preparate alimentare nedenumite si necuprinse in alta parte De glucoza sau de maltodextrine',
    'Preparate alimentare nedenumite si necuprinse in alta parte Altele',
    'Preparate alimentare nedenumite si necuprinse in alta parte Care nu contin grasimi din lapte zaharoza izoglucoza glucoza amidon sau fecule sau contin sub  grasimi din lapte sub  zaharoza sau izoglucoza sub  glucoza amidon sau fecule',
    'Preparate alimentare nedenumite si necuprinse in alta parte Altele',
    'Ape inclusiv ape minerale naturale sau artificiale si ape gazeificate care nu contin zahar sau alti indulcitori si nici aromatizanti gheata si zapada Fara dioxid de carbon',
    'Ape inclusiv ape minerale naturale sau artificiale si ape gazeificate care nu contin zahar sau alti indulcitori si nici aromatizanti gheata si zapada Altele',
    'Ape inclusiv ape minerale naturale sau artificiale si ape gazeificate care nu contin zahar sau alti indulcitori si nici aromatizanti gheata si zapada Altele',
    'Ape inclusiv ape minerale naturale sau artificiale si ape gazeificate care nu contin zahar sau alti indulcitori si nici aromatizanti gheata si zapada Altele',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Ape inclusiv ape minerale si ape gazeificate care contin zahar sau alti indulcitori sau aromatizate',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Bere fara alcool',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Bauturi pe baza de soia cu un continut de proteine de minimum   din greutate',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Bauturi pe baza de soia cu un continut de proteine mai mic de   din greutate bauturi pe baza de fructe cu coaja lemnoasa de la capitolul  cereale de la capitolul  sau seminte de la capitolul',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Altele',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia Sub  din greutate',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia De minimum  dar sub',
    'Ape inclusiv ape minerale si ape gazeificate care contin adaos de zahar sau de alti indulcitori sau aromatizate si alte bauturi nealcoolice cu exceptia sucurilor de fructe fructe cu coaja lemnoasa sau de legume de la pozitia De minimum',
    'Bere fabricata din malt In recipiente sub forma de sticla',
    'Bere fabricata din malt Altele',
    'Bere fabricata din malt Prezentate in recipiente cu un continut de peste l',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Sampanie',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Cava',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Prosecco',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Asti spumante',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri cu indicaie geografica protejata IGP',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Alte vinuri din soiuri',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri cu denumire de origine protejata DOP',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri cu indicaie geografica protejata IGP',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Alte vinuri din soiuri',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Alsace',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bordeaux',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bourgogne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Val de Loire',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Mosel',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Pfalz Palatinat',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Rheinhessen Hesse rhenane',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Tokay',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Lazio Latium',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Toscana Toscane',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Trentino Alto Adige si Friuli',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Veneto',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Sicilia',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinho Verde',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Peneds',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Rioja',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Valencia',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bordeaux',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bourgogne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Beaujolais',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Valle du Rhne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia LanguedocRoussillon',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Val de Loire',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Sicilia',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Piemonte Piemont',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Toscana Toscane',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Trentino si Alto Adige',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Veneto',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Do Bairrada si Douro',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Navarra',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Peneds',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Rioja',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Valdepeas',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Madeira si muscatel de Setubal',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Xeres',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Marsala',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Samos si muscat de Lemnos',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Porto',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri altele decat cele de la subpozitia prezentate in sticle inchise cu dopuri tip ciuperca fixate cu ajutorul clemelor sau legaturilor vinuri altfel prezentate care au la temperatura de C o suprapresiune datorata anhidridei carbonice in solutie de minimum bar dar sub bari',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bordeaux',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bourgogne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Beaujolais',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Valle du Rhne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia LanguedocRoussillon',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Val de Loire',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Piemonte Piemont',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Tokay',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Madeira si muscatel de Setubal',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Xeres',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Samos si muscat de Lemnos',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri altele decat cele de la subpozitia prezentate in sticle inchise cu dopuri tip ciuperca fixate cu ajutorul clemelor sau legaturilor vinuri altfel prezentate care au la temperatura de C o suprapresiune datorata anhidridei carbonice in solutie de minimum bar dar sub bari',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bordeaux',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Bourgogne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Beaujolais',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Valle du Rhne',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia LanguedocRoussillon',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Val de Loire',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Piemonte Piemont',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Madeira si muscatel de Setubal',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Xeres',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Samos si muscat de Lemnos',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Vinuri albe',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Partial fermentate sau a caror fermentatie a fost oprita altfel decat cu adaos de alcool',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Concentrate',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Concentrate',
    'Vinuri din struguri proaspeti inclusiv vinurile imbogatite cu alcool musturi de struguri altele decat cele de la pozitia Altele',
    'Vermuturi si alte vinuri din struguri proaspeti aromatizate cu plante sau substante aromatice Cu titru alcoolic volumic existent de maximum vol',
    'Vermuturi si alte vinuri din struguri proaspeti aromatizate cu plante sau substante aromatice Cu titru alcoolic volumic existent de peste vol',
    'Vermuturi si alte vinuri din struguri proaspeti aromatizate cu plante sau substante aromatice Cu titru alcoolic volumic existent de maximum vol',
    'Vermuturi si alte vinuri din struguri proaspeti aromatizate cu plante sau substante aromatice Cu titru alcoolic volumic existent de peste vol',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Piquette',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Cidru de mere si de pere',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Altele',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Cidru de mere si cidru de pere',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Altele',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Cidru de mere si cidru de pere',
    'Alte bauturi fermentate de exemplu cidru de mere cidru de pere hidromel sak amestecuri de bauturi fermentate si amestecuri de bauturi fermentate si bauturi nealcoolice nedenumite si necuprinse in alta parte Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic de minimum vol alcool etilic si alte distilate denaturate cu orice titru Alcool etilic nedenaturat cu titru alcoolic volumic de minim vol',
    'Alcool etilic nedenaturat cu titru alcoolic volumic de minimum vol alcool etilic si alte distilate denaturate cu orice titru Alcool etilic denaturat si alte distilate denaturate cu orice titru',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Coniac',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Armaniac',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Brandy de Jerez',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Grappa',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Coniac',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Brandy sau Weinbrand',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Grappa',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Whisky single malt',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Rom cu un continut de substante volatile altele decat alcool etilic si metilic egal sau mai mare de g per hectolitru de alcool pur cu o toleranta de',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Cu o valoare de peste  per litru de alcool pur',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Rom cu un continut de substante volatile altele decat alcool etilic si metilic egal sau mai mare de g per hectolitru de alcool pur cu o toleranta de',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Cu o valoare de peste  per litru de alcool pur',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Prezentate in recipiente cu un continut de maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Prezentate in recipiente cu un continut de peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Uzo',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Calvados',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Tequila',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Alte bauturi spirtoase',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Din fructe',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Tequila',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Altele',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase Alte bauturi spirtoase',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De maximum l',
    'Alcool etilic nedenaturat cu titru alcoolic volumic sub vol distilate rachiuri lichioruri si alte bauturi spirtoase De peste l',
    'Otet comestibil si inlocuitori de otet comestibil obtinuti din acid acetic De maximum l',
    'Otet comestibil si inlocuitori de otet comestibil obtinuti din acid acetic De peste l',
    'Otet comestibil si inlocuitori de otet comestibil obtinuti din acid acetic De maximum l',
    'Otet comestibil si inlocuitori de otet comestibil obtinuti din acid acetic De peste l',
    'Faina pudra si aglomerate sub forma de pelete din carne organe peste sau crustacee din moluste sau din alte nevertebrate acvatice improprii alimentatiei umane jumari Faina pudra si aglomerate sub forma de pelete din carne sau din organe jumari',
    'Faina pudra si aglomerate sub forma de pelete din carne organe peste sau crustacee din moluste sau din alte nevertebrate acvatice improprii alimentatiei umane jumari Faina pudra si aglomerate sub forma de pelete din peste sau crustacee din moluste sau din alte nevertebrate acvatice',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Al caror continut de amidon este mai mic sau egal cu  din greutate',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Altele',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Al caror continut de amidon este mai mic sau egal cu  din greutate si procentajul lui de trecere printro sita cu tesatura metalica cu deschizatura de ochi de plasa de mm este sub  din greutate sau in caz contrar produsul cernut are un continut de cenusa calculat pe produs uscat egal sau mai mare de  din greutate',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Altele',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Al caror continut de amidon este mai mic sau egal cu  din greutate',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Altele',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Al caror continut de amidon este mai mic sau egal cu  din greutate si procentajul lui de trecere printro sita cu tesatura metalica cu deschizatura de ochi de plasa de mm este sub  din greutate sau in caz contrar produsul cernut are un continut de cenusa calculat pe produs uscat egal sau mai mare de  din greutate',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor Altele',
    'Tarate sparturi si alte reziduuri chiar aglomerate sub forma de pelete provenite din macinarea presarea cernerea sau din alte procedee de prelucrare a cerealelor sau a leguminoaselor De leguminoase',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Mai mare de  din greutate',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Mai mic sau egal cu  din greutate',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Altele',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Pulpa de sfecla de zahar',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Altele',
    'Reziduuri rezultate de la fabricarea amidonului si reziduuri similare pulpa de sfecla de zahar resturi rezultate din prelucrarea trestiei de zahar si alte deseuri rezultate de la fabricarea zaharului depuneri si deseuri rezultate de la fabricarea berii sau de la distilare chiar aglomerate sub forma de pelete Depuneri si deseuri rezultate de la fabricarea berii sau de la distilare',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia uleiului de soia Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia uleiului de soia',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia uleiului de arahide Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia uleiului de arahide',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din seminte de bumbac',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din seminte de in',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din seminte de floareasoarelui',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din seminte de rapita sau rapita salbatica cu continut redus de acid erucic',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Altele',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din nuca de cocos sau copra',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din nuci sau miez de nuca de palmier',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Din germeni de porumb',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Cu un continut de ulei de masline mai mic sau egal cu  din greutate',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Cu un continut de ulei de masline mai mare de  din greutate',
    'Turte si alte reziduuri solide chiar macinate sau aglomerate sub forma de pelete rezultate din extractia grasimilor sau a uleiurilor vegetale sau de origine microbiana altele decat cele de la pozitiile sau  Altele',
    'Drojdii de vin tartru brut Cu titru alcoolic total de maximum mas si cu un continut de substanta uscata de minimum  din greutate',
    'Drojdii de vin tartru brut Altele',
    'Drojdii de vin tartru brut Tartru brut',
    'Materiale vegetale si deseuri vegetale reziduuri si subproduse vegetale chiar aglomerate sub forma de pelete de tipul celor folosite in hrana animalelor nedenumite si necuprinse in alta parte Cu titru alcoolic total de maximum mas si un continut de substanta uscata de minimum  din greutate',
    'Materiale vegetale si deseuri vegetale reziduuri si subproduse vegetale chiar aglomerate sub forma de pelete de tipul celor folosite in hrana animalelor nedenumite si necuprinse in alta parte Altele',
    'Materiale vegetale si deseuri vegetale reziduuri si subproduse vegetale chiar aglomerate sub forma de pelete de tipul celor folosite in hrana animalelor nedenumite si necuprinse in alta parte Ghinde de stejar si castane de India drojdii de fructe altele decat cele de struguri',
    'Materiale vegetale si deseuri vegetale reziduuri si subproduse vegetale chiar aglomerate sub forma de pelete de tipul celor folosite in hrana animalelor nedenumite si necuprinse in alta parte Altele',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de   din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin amidon sau fecule glucoza sau sirop de glucoza maltodextrine sau sirop de maltodextrine si care contin produse lactate',
    'Preparate de tipul celor folosite pentru hrana animalelor Altele',
    'Preparate de tipul celor folosite pentru hrana animalelor Produse numite solubile din peste sau din mamifere marine',
    'Preparate de tipul celor folosite pentru hrana animalelor Produse prevazute la nota complementara de la prezentul capitol',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin produse lactate sau cu un continut de produse lactate mai mic de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate si mai mic de',
    'Preparate de tipul celor folosite pentru hrana animalelor Cu un continut de produse lactate egal sau mai mare de  din greutate',
    'Preparate de tipul celor folosite pentru hrana animalelor Care nu contin amidon sau fecule glucoza sau sirop de glucoza maltodextrine sau sirop de maltodextrine si care contin produse lactate',
    'Preparate de tipul celor folosite pentru hrana animalelor Pulpa de sfecla de zahar cu adaos de melasa',
    'Preparate de tipul celor folosite pentru hrana animalelor Altele',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu aer cald light air cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri de tip oriental uscate la soare sun cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu fum rece dark air cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu fum flue cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Altele',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu aer cald light air cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri de tip oriental uscate la soare sun cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu fum rece dark air cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Tutunuri uscate cu fum flue cured',
    'Tutunuri brute sau neprelucrate deseuri de tutun Altele',
    'Tutunuri brute sau neprelucrate deseuri de tutun Deseuri de tutun',
    'Tigari de foi inclusiv cele cu capete taiate trabucuri si tigarete din tutun sau din inlocuitori de tutun Tigari de foi inclusiv cele cu capete taiate si trabucuri care contin tutun',
    'Tigari de foi inclusiv cele cu capete taiate trabucuri si tigarete din tutun sau din inlocuitori de tutun Care contin cuisoare',
    'Tigari de foi inclusiv cele cu capete taiate trabucuri si tigarete din tutun sau din inlocuitori de tutun Altele',
    'Tigari de foi inclusiv cele cu capete taiate trabucuri si tigarete din tutun sau din inlocuitori de tutun Altele',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun Tutun pentru pipa cu apa mentionat la nota de subpozitii a capitolului',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun In ambalaje directe cu un continut net de maximum g',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun Altele',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun Tutunuri omogenizate sau reconstituite',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun Tutunuri pentru mestecat si tutunuri pentru prizat tutunuri pentru uz nazal',
    'Alte tutunuri si inlocuitori de tutun prelucrate tutunuri omogenizate sau reconstituite extracte si esente de tutun Altele',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Care contin tutun sau tutun reconstituit',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Altele care contin nicotina',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Care contin inlocuitori de tutun',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Altele',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Produse care contin nicotina destinate sa ajute la renuntarea la fumat',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Altele',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Pentru aplicare transdermica',
    'Produse care contin tutun tutun reconstituit nicotina sau inlocuitori de tutun sau de nicotina destinate inhalarii fara ardere alte produse care contin nicotina destinate absorbtiei nicotinei in corpul uman Altele',
    'Sare inclusiv sarea de masa si sarea denaturata si clorura de sodiu pura chiar sub forma de solutie apoasa sau cu adaos de agenti antiaglomeranti sau agenti care asigura o buna fluiditate apa de mare Apa de mare si ape care provin din saline',
    'Sare inclusiv sarea de masa si sarea denaturata si clorura de sodiu pura chiar sub forma de solutie apoasa sau cu adaos de agenti antiaglomeranti sau agenti care asigura o buna fluiditate apa de mare Destinate transformarii chimice separarea Na de Cl pentru fabricarea altor produse',
    'Sare inclusiv sarea de masa si sarea denaturata si clorura de sodiu pura chiar sub forma de solutie apoasa sau cu adaos de agenti antiaglomeranti sau agenti care asigura o buna fluiditate apa de mare Denaturate sau destinate altor utilizari industriale inclusiv pentru rafinare cu exceptia conservarii sau prepararii produselor destinate alimentatiei umane sau animale',
    'Sare inclusiv sarea de masa si sarea denaturata si clorura de sodiu pura chiar sub forma de solutie apoasa sau cu adaos de agenti antiaglomeranti sau agenti care asigura o buna fluiditate apa de mare Sare destinata alimentatiei umane',
    'Sare inclusiv sarea de masa si sarea denaturata si clorura de sodiu pura chiar sub forma de solutie apoasa sau cu adaos de agenti antiaglomeranti sau agenti care asigura o buna fluiditate apa de mare Altele',
    'Pirite de fier neprajite Pirite de fier neprajite',
    'Sulf de orice fel cu exceptia sulfului sublimat a sulfului precipitat si a sulfului coloidal Sulf brut si sulf nerafinat',
    'Sulf de orice fel cu exceptia sulfului sublimat a sulfului precipitat si a sulfului coloidal Altele',
    'Grafit natural Sub forma de pulbere sau foite',
    'Grafit natural Altele',
    'Nisipuri naturale de orice fel chiar colorate cu exceptia nisipurilor metalifere de la capitolul Nisipuri silicioase si nisipuri cuartoase',
    'Nisipuri naturale de orice fel chiar colorate cu exceptia nisipurilor metalifere de la capitolul Alte nisipuri',
    'Cuart altul decat nisipurile naturale cuartite chiar degrosate sau simplu debitate cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Cuart',
    'Cuart altul decat nisipurile naturale cuartite chiar degrosate sau simplu debitate cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Cuartite',
    'Caolin si alte argile caolinice chiar calcinate Caolin',
    'Caolin si alte argile caolinice chiar calcinate Alte argile caolinice',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Bentonita',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Argile refractare',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Alte argile',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Andaluzit cianit si silimanit',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Mulit',
    'Alte argile cu exceptia argilelor expandate de la pozitia andaluzit cianit silimanit chiar calcinate mulit pamanturi de samota si de caramizi silicioase Pamanturi de samota sau de caramizi silicioase',
    'Creta Creta',
    'Fosfati de calciu naturali fosfati aluminocalcici naturali si creta fosfatica Nemacinate',
    'Fosfati de calciu naturali fosfati aluminocalcici naturali si creta fosfatica Macinate',
    'Sulfat de bariu natural baritina carbonat de bariu natural witherit chiar calcinat altele decat oxidul de bariu de la pozitia Sulfat de bariu natural baritina',
    'Sulfat de bariu natural baritina carbonat de bariu natural witherit chiar calcinat altele decat oxidul de bariu de la pozitia Carbonat de bariu natural witherit',
    'Faina silicioasa fosila de exemplu kieselgur tripolit si diatomit si alte pamanturi silicioase similare cu o densitate aparenta de maximum  chiar calcinate Faina silicioasa fosila de exemplu kieselgur tripolit si diatomit si alte pamanturi silicioase similare cu o densitate aparenta de maximum  chiar calcinate',
    'Piatra ponce emeri piatra de slefuit corindon natural granat natural si alte abrazive naturale chiar tratate termic Piatra ponce',
    'Piatra ponce emeri piatra de slefuit corindon natural granat natural si alte abrazive naturale chiar tratate termic Emeri piatra de slefuit corindon natural granat natural si alte abrazive naturale',
    'Ardezie chiar degrosata sau simplu debitata prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Ardezie chiar degrosata sau simplu debitata prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara',
    'Marmura travertin ecausin si alte pietre calcaroase pentru cioplit sau pentru constructii avand o densitate aparenta de minimum  si alabastru chiar degrosate sau simplu debitate prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Brute sau degrosate',
    'Marmura travertin ecausin si alte pietre calcaroase pentru cioplit sau pentru constructii avand o densitate aparenta de minimum  si alabastru chiar degrosate sau simplu debitate prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Simplu debitate prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara',
    'Marmura travertin ecausin si alte pietre calcaroase pentru cioplit sau pentru constructii avand o densitate aparenta de minimum  si alabastru chiar degrosate sau simplu debitate prin taiere cu ferastraul sau prin alt procedeu in blocuri sau in placi de forma patrata sau dreptunghiulara Ecausin si alte pietre calcaroase pentru cioplit sau pentru constructii alabastru',
    'Granit porfir bazalt gresie si alte pietre pentru cioplit sau pentru constructii chiar degrosate sau simplu debitate cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara Brut sau degrosat',
    'Granit porfir bazalt gresie si alte pietre pentru cioplit sau pentru constructii chiar degrosate sau simplu debitate cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara Simplu debitat cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara',
    'Granit porfir bazalt gresie si alte pietre pentru cioplit sau pentru constructii chiar degrosate sau simplu debitate cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara Gresie',
    'Granit porfir bazalt gresie si alte pietre pentru cioplit sau pentru constructii chiar degrosate sau simplu debitate cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara Alte pietre pentru cioplit sau pentru constructii',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Bolovani prundis silex si pietris',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Piatra de var dolomita si alte pietre calcaroase concasate',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Altele',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Macadam de zgura de zgura metalurgica sau de deseuri industriale similare care contine chiar materialele mentionate la subpozitia',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Macadam gudronat pentru sosele',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic De marmura',
    'Prundis pietris pietre concasate din cele folosite in general ca agregate pentru betoane sau pietruirea drumurilor pentru caile ferate sau alt balast pietris si silex chiar tratate termic macadam de zgura de zgura metalurgica de deseuri industriale similare care contine chiar materiale cuprinse in prima parte a pozitiei macadam gudronat pentru sosele granule sparturi si praf din pietre de la pozitiile sau  chiar tratate termic Altele',
    'Dolomita chiar sinterizata sau calcinata inclusiv dolomita degrosata sau simplu debitata cu ferastraul sau prin alte procedee in blocuri sau in placi de forma patrata sau dreptunghiulara Dolomita necalcinata nesinterizata denumita cruda',
    'Dolomita chiar sinterizata sau calcinata inclusiv dolomita degrosata sau simplu debitata cu ferastraul sau prin alte procedee in blocuri sau in placi de forma patrata sau dreptunghiulara Dolomita calcinata sau sinterizata',
    'Carbonat de magneziu natural magnezita magnezie topita magnezie calcinata total sinterizata care contine chiar cantitati mici de alti oxizi adaugati inainte de sinterizare alti oxizi de magneziu chiar in stare pura Carbonat de magneziu natural magnezita',
    'Carbonat de magneziu natural magnezita magnezie topita magnezie calcinata total sinterizata care contine chiar cantitati mici de alti oxizi adaugati inainte de sinterizare alti oxizi de magneziu chiar in stare pura Oxid de magneziu altul decat carbonatul natural de magneziu magnezita calcinat',
    'Carbonat de magneziu natural magnezita magnezie topita magnezie calcinata total sinterizata care contine chiar cantitati mici de alti oxizi adaugati inainte de sinterizare alti oxizi de magneziu chiar in stare pura Magnezie calcinata total sinterizata',
    'Carbonat de magneziu natural magnezita magnezie topita magnezie calcinata total sinterizata care contine chiar cantitati mici de alti oxizi adaugati inainte de sinterizare alti oxizi de magneziu chiar in stare pura Altele',
    'Gips anhidrit ipsos din gips calcinat sau din sulfat de calciu chiar colorat cu sau fara adaos de cantitati mici de acceleratori sau intarzietori Gips anhidrit',
    'Gips anhidrit ipsos din gips calcinat sau din sulfat de calciu chiar colorat cu sau fara adaos de cantitati mici de acceleratori sau intarzietori Ipsos',
    'Piatra de var pentru furnale piatra de var si alte roci calcaroase de tipul celor folosite pentru fabricarea varului sau a cimentului Piatra de var pentru furnale piatra de var si alte roci calcaroase de tipul celor folosite pentru fabricarea varului sau a cimentului',
    'Var nestins var stins si var hidraulic cu exceptia oxidului si a hidroxidului de calciu de la pozitia Var nestins',
    'Var nestins var stins si var hidraulic cu exceptia oxidului si a hidroxidului de calciu de la pozitia Var stins',
    'Var nestins var stins si var hidraulic cu exceptia oxidului si a hidroxidului de calciu de la pozitia Var hidraulic',
    'Cimenturi hidraulice inclusiv cimenturi nepulverizate numite clinkers chiar colorate Cimenturi nepulverizate numite clinkers',
    'Cimenturi hidraulice inclusiv cimenturi nepulverizate numite clinkers chiar colorate Cimenturi albe chiar colorate artificial',
    'Cimenturi hidraulice inclusiv cimenturi nepulverizate numite clinkers chiar colorate Altele',
    'Cimenturi hidraulice inclusiv cimenturi nepulverizate numite clinkers chiar colorate Cimenturi aluminoase',
    'Cimenturi hidraulice inclusiv cimenturi nepulverizate numite clinkers chiar colorate Alte cimenturi hidraulice',
    'Azbest Crocidolit',
    'Azbest Altele',
    'Mica inclusiv despicata in foite sau lamele neregulate splittings deseuri de mica Mica bruta sau despicata in foite sau lamele neregulate',
    'Mica inclusiv despicata in foite sau lamele neregulate splittings deseuri de mica Pudra de mica',
    'Mica inclusiv despicata in foite sau lamele neregulate splittings deseuri de mica Deseuri de mica',
    'Steatit natural chiar degrosat sau simplu debitat prin taiere cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara talc Nemacinate nepulverizate',
    'Steatit natural chiar degrosat sau simplu debitat prin taiere cu ferastraul sau prin alte procedee in blocuri sau placi de forma patrata sau dreptunghiulara talc Macinate sau pulverizate',
    'Borati naturali si concentratele lor chiar calcinati cu exceptia boratilor extrasi din saramuri naturale acid boric natural cu o concentratie de maximum  HBO din produsul uscat Borati naturali si concentratele lor chiar calcinati cu exceptia boratilor extrasi din saramuri naturale acid boric natural cu o concentratie de maximum  HBO din produsul uscat',
    'Feldspat leucit nefelin si nefelin sienit fluorina Feldspat',
    'Feldspat leucit nefelin si nefelin sienit fluorina Cu maximum  din greutate fluorura de calciu',
    'Feldspat leucit nefelin si nefelin sienit fluorina Cu peste  din greutate fluorura de calciu',
    'Feldspat leucit nefelin si nefelin sienit fluorina Leucit nefelin si nefelin sienit',
    'Substante minerale nedenumite si necuprinse in alta parte Vermiculita perlit si clorita neexpandate',
    'Substante minerale nedenumite si necuprinse in alta parte Kieserit epsomit sulfati de magneziu naturali',
    'Substante minerale nedenumite si necuprinse in alta parte Celestina si strontianit',
    'Substante minerale nedenumite si necuprinse in alta parte Spodumen petalit lepidolit ambligonit hectorit jadarit si minerale similare adecvate pentru extractia litiului',
    'Substante minerale nedenumite si necuprinse in alta parte Bastnaezit xenotim si minerale similare pentru extractia metalelor din pamanturi rare a scandiului sau a ytriului',
    'Substante minerale nedenumite si necuprinse in alta parte Altele',
    'Minereuri de fier si concentratele lor inclusiv piritele de fier prajite cenusa de pirite Neaglomerate',
    'Minereuri de fier si concentratele lor inclusiv piritele de fier prajite cenusa de pirite Aglomerate',
    'Minereuri de fier si concentratele lor inclusiv piritele de fier prajite cenusa de pirite Pirite de fier prajite cenusa de pirite',
    'Minereuri de mangan si concentratele lor inclusiv minereuri de mangan feruginoase si concentratele lor cu un continut minim de mangan de  din greutatea produsului uscat Minereuri de mangan si concentratele lor inclusiv minereuri de mangan feruginoase si concentratele lor cu un continut minim de mangan de  din greutatea produsului uscat',
    'Minereuri de cupru si concentratele lor Minereuri de cupru si concentratele lor',
    'Minereuri de nichel si concentratele lor Minereuri de nichel si concentratele lor',
    'Minereuri de cobalt si concentratele lor Minereuri de cobalt si concentratele lor',
    'Minereuri de aluminiu si concentratele lor Minereuri de aluminiu si concentratele lor',
    'Minereuri de plumb si concentratele lor Minereuri de plumb si concentratele lor',
    'Minereuri de zinc si concentratele lor Minereuri de zinc si concentratele lor',
    'Minereuri de staniu si concentratele lor Minereuri de staniu si concentratele lor',
    'Minereuri de crom si concentratele lor Minereuri de crom si concentratele lor',
    'Minereuri de tungsten si concentratele lor Minereuri de tungsten si concentratele lor',
    'Minereuri de uraniu sau de toriu si concentratele lor Minereuri de uraniu si pehblenda si concentratele lor cu un continut de uraniu peste  din greutate Euratom',
    'Minereuri de uraniu sau de toriu si concentratele lor Altele',
    'Minereuri de uraniu sau de toriu si concentratele lor Monazit uranotorianit si alte minereuri de toriu si concentratele lor cu un continut de toriu peste  din greutate Euratom',
    'Minereuri de uraniu sau de toriu si concentratele lor Altele',
    'Minereuri de molibden si concentratele lor Prajite',
    'Minereuri de molibden si concentratele lor Altele',
    'Minereuri de titan si concentratele lor Minereuri de titan si concentratele lor',
    'Minereuri de niobiu de tantal de vanadiu sau de zirconiu si concentratele lor Minereuri de zirconiu si concentratele lor',
    'Minereuri de niobiu de tantal de vanadiu sau de zirconiu si concentratele lor Altele',
    'Minereuri de metale pretioase si concentratele lor Minereuri de argint si concentratele lor',
    'Minereuri de metale pretioase si concentratele lor Altele',
    'Alte minereuri si concentratele lor Minereuri de antimoniu si concentratele lor',
    'Alte minereuri si concentratele lor Altele',
    'Zgura de furnal granulata nisip de zgura rezultata de la fabricarea fontei fierului sau otelului Zgura de furnal granulata nisip de zgura rezultata de la fabricarea fontei fierului sau otelului',
    'Zgura zgura de furnal altele decat zgura de furnal granulata coji tunder si alte deseuri rezultate de la fabricarea fontei fierului sau a otelului Deseuri destinate recuperarii fierului sau manganului',
    'Zgura zgura de furnal altele decat zgura de furnal granulata coji tunder si alte deseuri rezultate de la fabricarea fontei fierului sau a otelului Deseuri destinate recuperarii vanadiu',
    'Zgura zgura de furnal altele decat zgura de furnal granulata coji tunder si alte deseuri rezultate de la fabricarea fontei fierului sau a otelului Altele',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Mate de galvanizare',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Altele',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Namoluri de benzina cu plumb si namoluri de compusi antidetonanti care contin plumb',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Altele',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal cupru',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal aluminiu',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin arsen mercur taliu sau amestecuri ale acestora de tipul celor utilizate la extractia arsenului sau a metalelor specificate ori pentru fabricarea compusilor lor chimici',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin antimoniu beriliu cadmiu crom sau amestecuri ale acestora',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal nichel',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal niobiu sau tantal',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal staniu',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Care contin in principal titan',
    'Zgura cenusa si reziduuri altele decat cele rezultate de la fabricarea fontei fierului sau otelului care contin arsen metale sau compusi ai metalelor Altele',
    'Alte zguri si cenusa inclusiv cenusa de vareh cenusa si reziduuri care provin de la incinerarea deseurilor orasenesti Cenusa si reziduuri provenind de la incinerarea deseurilor orasenesti',
    'Alte zguri si cenusa inclusiv cenusa de vareh cenusa si reziduuri care provin de la incinerarea deseurilor orasenesti Altele',
    'Huila brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila Antracit',
    'Huila brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila Huila de cocs',
    'Huila brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila Altele',
    'Huila brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila Alta huila',
    'Huila brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila Brichete aglomerate de forma ovoidala si combustibili solizi in forme similare obtinuti din huila',
    'Lignit chiar aglomerat cu exceptia gagatului Lignit chiar pulverizat dar neaglomerat',
    'Lignit chiar aglomerat cu exceptia gagatului Lignit aglomerat',
    'Turba inclusiv turba pentru asternut chiar aglomerata Turba inclusiv turba pentru asternut chiar aglomerata',
    'Cocs si semicocs de huila de lignit sau de turba chiar aglomerate carbune de retorta Cocs si semicocs de huila',
    'Cocs si semicocs de huila de lignit sau de turba chiar aglomerate carbune de retorta Cocs si semicocs de lignit',
    'Cocs si semicocs de huila de lignit sau de turba chiar aglomerate carbune de retorta Altele',
    'Gaz de huila gaz de apa gaz de generator si gaze similare cu exceptia gazului de sonda si alte hidrocarburi gazoase Gaz de huila gaz de apa gaz de generator si gaze similare cu exceptia gazului de sonda si alte hidrocarburi gazoase',
    'Gudron de huila de lignit sau de turba si alte gudroane minerale chiar deshidratate sau partial distilate inclusiv gudroane reconstituite Gudron de huila de lignit sau de turba si alte gudroane minerale chiar deshidratate sau partial distilate inclusiv gudroane reconstituite',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Benzol benzen',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Toluol toluen',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Xilol xileni',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Naftalina',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Alte amestecuri de hidrocarburi aromatice care distila minimum  din volum inclusiv pierderile la C dupa metoda ISO echivalenta cu metoda ASTM D',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Uleiuri de creozot',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Uleiuri usoare brute care distila in proportie de minimum  in volum pana la temperatura de C',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Altele',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Fractiuni usoare frunti sulfurate antracen',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Produse bazice',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Fenoli',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Destinate fabricarii produselor de la pozitia',
    'Uleiuri si alte produse rezultate din distilarea gudronului de huila la temperaturi inalte produse similare la care greutatea constituentilor aromatici depaseste greutatea constituentilor nearomatici Altele',
    'Smoala si cocs de smoala din gudron de huila sau din alte gudroane minerale Smoala',
    'Smoala si cocs de smoala din gudron de huila sau din alte gudroane minerale Cocs de smoala',
    'Uleiuri brute din petrol sau din minerale bituminoase Condensate de gaz natural',
    'Uleiuri brute din petrol sau din minerale bituminoase Altele',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unui tratament specific',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unei transformari chimice printrun tratament altul decat cele specificate pentru subpozitia',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri White spirit',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Altele',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Benzine pentru aviatie',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu cifra octanica COR sub',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu cifra octanica COR de minimum  dar sub',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu cifra octanica COR de minimum',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Peste g pe litru',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Benzina tip jet fuel',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Alte uleiuri usoare',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unui tratament specific',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unei transformari chimice printrun tratament altul decat cele specificate pentru subpozitia',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Jet fuel',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Altele',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Altele',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinata a fi supusa unui tratament specific',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinata a fi supusa unei transformari chimice printrun tratament altul decat cele specificate pentru subpozitia',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de maximum  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  dar de maximum',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  dar de maximum',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinata a fi supusa unui tratament specific',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinata a fi supusa unei transformari chimice printrun tratament altul decat cele specificate pentru subpozitia',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de maximum  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  dar de maximum  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unui tratament specific',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Destinate a fi supuse unei transformari chimice printrun tratament altul decat cele specificate pentru subpozitia',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri pentru motoare uleiuri lubrifiante pentru compresoare si uleiuri lubrifiante pentru turbine',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri hidraulice',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri albe parafine lichide',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri pentru angrenaje',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri pentru prelucrarea metalelor uleiuri de scoatere din forme uleiuri anticorozive',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Uleiuri electroizolante',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Alte uleiuri lubrifiante si alte uleiuri',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de maximum  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  dar de maximum',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de maximum  in greutate',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Cu un continut de sulf de peste',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Alte uleiuri',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Care contin difenili policlorurati PCB terfenili policlorurati PCT sau difenili polibromurati PBB',
    'Uleiuri din petrol sau uleiuri din minerale bituminoase altele decat uleiurile brute preparatele nedenumite si necuprinse in alta parte care contin in greutate minimum  uleiuri din petrol sau din minerale bituminoase si pentru care aceste uleiuri constituie elementele de baza deseuri de uleiuri Altele',
    'Gaz de sonda si alte hidrocarburi gazoase Gaz natural',
    'Gaz de sonda si alte hidrocarburi gazoase Destinat utilizarii drept carburant sau combustibil',
    'Gaz de sonda si alte hidrocarburi gazoase Destinat altor utilizari',
    'Gaz de sonda si alte hidrocarburi gazoase Destinat a fi supus unui tratament specific',
    'Gaz de sonda si alte hidrocarburi gazoase Destinat a fi supus unei transformari chimice printrun tratament altul decat cele definite pentru subpozitia',
    'Gaz de sonda si alte hidrocarburi gazoase De o puritate peste  dar sub',
    'Gaz de sonda si alte hidrocarburi gazoase Altele',
    'Gaz de sonda si alte hidrocarburi gazoase Destinati a fi supus unui tratament specific',
    'Gaz de sonda si alte hidrocarburi gazoase Destinati a fi supus unei transformari chimice printrun tratament altul decat cele definite pentru subpozitia',
    'Gaz de sonda si alte hidrocarburi gazoase De o puritate peste  dar sub',
    'Gaz de sonda si alte hidrocarburi gazoase Altele',
    'Gaz de sonda si alte hidrocarburi gazoase Etilena propilena butilena si butadiena',
    'Gaz de sonda si alte hidrocarburi gazoase Altele',
    'Gaz de sonda si alte hidrocarburi gazoase Gaz natural',
    'Gaz de sonda si alte hidrocarburi gazoase Altele',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Bruta',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Altele',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Ceara de parafina sintetica cu o greutate moleculara de minimum  dar de maximum',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Altele',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Brute',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Altele',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Destinate a fi supuse unui tratament specific',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Destinate a fi supuse unei transformari chimice printrun tratament altul decat cele definite pentru subpozitia',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Destinate altor utilizari',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Amestec de alchene care contine in greutate minimum  alchene cu catene de minimum atomi de carbon dar de maximum atomi de carbon',
    'Vaselina ceara de parafina ceara de petrol microcristalina ceara din praf de carbune slack wax ozocherita ceara de lignit ceara de turba alte tipuri de ceara minerala si produse similare obtinute prin sinteza sau prin alte procedee chiar colorate Altele',
    'Cocs de petrol bitum de petrol si alte reziduuri de uleiuri din petrol sau de uleiuri din minerale bituminoase Necalcinat',
    'Cocs de petrol bitum de petrol si alte reziduuri de uleiuri din petrol sau de uleiuri din minerale bituminoase Calcinat',
    'Cocs de petrol bitum de petrol si alte reziduuri de uleiuri din petrol sau de uleiuri din minerale bituminoase Bitum de petrol',
    'Cocs de petrol bitum de petrol si alte reziduuri de uleiuri din petrol sau de uleiuri din minerale bituminoase Destinate fabricarii produselor de la pozitia',
    'Cocs de petrol bitum de petrol si alte reziduuri de uleiuri din petrol sau de uleiuri din minerale bituminoase Altele',
    'Bitumuri si asfalturi naturale sisturi si nisipuri bituminoase asfaltiti si roci asfaltice Sisturi si nisipuri bituminoase',
    'Bitumuri si asfalturi naturale sisturi si nisipuri bituminoase asfaltiti si roci asfaltice Altele',
    'Amestecuri bituminoase pe baza de asfalt natural sau de bitum natural de bitum de petrol de gudron mineral sau de smoala de gudron mineral de exemplu mastic bituminos cutbacks Amestecuri bituminoase pe baza de asfalt natural sau de bitum natural de bitum de petrol de gudron mineral sau de smoala de gudron mineral de exemplu mastic bituminos cutbacks',
    'Energie electrica Energie electrica',
    'Fluor clor brom si iod Clor',
    'Fluor clor brom si iod Iod',
    'Fluor clor brom si iod Fluor',
    'Fluor clor brom si iod Brom',
    'Sulf sublimat sau precipitat sulf coloidal Sulf sublimat sau precipitat sulf coloidal',
    'Carbon negru de fum si alte forme de carbon nedenumite si necuprinse in alta parte Carbon negru de fum si alte forme de carbon nedenumite si necuprinse in alta parte',
    'Hidrogen gaze rare si alte nemetale Hidrogen',
    'Hidrogen gaze rare si alte nemetale Argon',
    'Hidrogen gaze rare si alte nemetale Heliu',
    'Hidrogen gaze rare si alte nemetale Altele',
    'Hidrogen gaze rare si alte nemetale Azot',
    'Hidrogen gaze rare si alte nemetale Oxigen',
    'Hidrogen gaze rare si alte nemetale Bor',
    'Hidrogen gaze rare si alte nemetale Telur',
    'Hidrogen gaze rare si alte nemetale Cu un continut in greutate de minimum  siliciu',
    'Hidrogen gaze rare si alte nemetale Altele',
    'Hidrogen gaze rare si alte nemetale Fosfor rosu',
    'Hidrogen gaze rare si alte nemetale Altele',
    'Hidrogen gaze rare si alte nemetale Arsen',
    'Hidrogen gaze rare si alte nemetale Seleniu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Sodiu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Calciu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Strontiu si bariu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Altele',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Amestecate sau aliate intre ele',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur  Ceriu si lantan',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Praseodim neodim si samariu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Gadoliniu terbiu si disprosiu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Europiu holmiu erbiu tuliu yterbiu lutetiu si ytriu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Scandiu',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Altele',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur In recipiente cu continutul net de kg greutate standard si cu valoarea FOB pe recipient de maximum',
    'Metale alcaline sau alcalinopamantoase metale din pamanturi rare ytriu si scandiu chiar amestecate sau aliate intre ele mercur Altele',
    'Clorura de hidrogen acid clorhidric acid clorosulfuric Clorura de hidrogen acid clorhidric',
    'Clorura de hidrogen acid clorhidric acid clorosulfuric Acid clorosulfuric',
    'Acid sulfuric oleum Acid sulfuric oleum',
    'Acid nitric azotic acizi sulfonitrici Acid nitric azotic acizi sulfonitrici',
    'Pentaoxid de difosfor acid fosforic acizi polifosforici cu compozitie chimica definita sau nu Pentaoxid de difosfor',
    'Pentaoxid de difosfor acid fosforic acizi polifosforici cu compozitie chimica definita sau nu Acid fosforic si acizi polifosforici',
    'Oxizi de bor acizi borici Trioxid de dibor',
    'Oxizi de bor acizi borici Altele',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Fluorura de hidrogen acid fluorhidric',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Cianura de hidrogen acid cianhidric',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Bromura de hidrogen acid bromhidric',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Altele',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Dioxid de carbon',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Dioxid de siliciu',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Dioxid de sulf',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Trioxid de sulf anhidrida sulfurica trioxid de diarsen anhidrida arsenioasa',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Oxizi de azot',
    'Alti acizi anorganici si alti compusi oxigenati anorganici ai nemetalelor Altele',
    'Halogenuri si oxihalogenuri de nemetale Diclorura de carbonil fosgen',
    'Halogenuri si oxihalogenuri de nemetale Oxiclorura de fosfor',
    'Halogenuri si oxihalogenuri de nemetale Triclorura de fosfor',
    'Halogenuri si oxihalogenuri de nemetale Pentaclorura de fosfor',
    'Halogenuri si oxihalogenuri de nemetale Monoclorura de sulf',
    'Halogenuri si oxihalogenuri de nemetale Diclorura de sulf',
    'Halogenuri si oxihalogenuri de nemetale Clorura de tionil',
    'Halogenuri si oxihalogenuri de nemetale De fosfor',
    'Halogenuri si oxihalogenuri de nemetale Altele',
    'Halogenuri si oxihalogenuri de nemetale Altele',
    'Sulfuri de nemetale trisulfura de fosfor comerciala Disulfura de carbon',
    'Sulfuri de nemetale trisulfura de fosfor comerciala Sulfuri de fosfor inclusiv trisulfura de fosfor comerciala',
    'Sulfuri de nemetale trisulfura de fosfor comerciala Altele',
    'Amoniac anhidru sau in solutie apoasa Amoniac anhidru',
    'Amoniac anhidru sau in solutie apoasa Amoniac in solutie apoasa',
    'Hidroxid de sodiu soda caustica hidroxid de potasiu potasa caustica peroxizi de sodiu sau de potasiu Solid',
    'Hidroxid de sodiu soda caustica hidroxid de potasiu potasa caustica peroxizi de sodiu sau de potasiu In solutie apoasa lesie de soda caustica',
    'Hidroxid de sodiu soda caustica hidroxid de potasiu potasa caustica peroxizi de sodiu sau de potasiu Hidroxid de potasiu potasa caustica',
    'Hidroxid de sodiu soda caustica hidroxid de potasiu potasa caustica peroxizi de sodiu sau de potasiu Peroxizi de sodiu sau de potasiu',
    'Hidroxid si peroxid de magneziu oxizi hidroxizi si peroxizi de strontiu sau de bariu Hidroxid si peroxid de magneziu',
    'Hidroxid si peroxid de magneziu oxizi hidroxizi si peroxizi de strontiu sau de bariu Oxizi hidroxizi si peroxizi de strontiu sau de bariu',
    'Oxid de zinc peroxid de zinc Oxid de zinc peroxid de zinc',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu cu sub  din greutatea totala avand marimea particulelor de minimum mm',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu cu minimum  din greutatea totala avand marimea particulelor de minimum mm',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu cu sub  din greutatea totala avand marimea particulelor de minimum mm',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu cu minimum  din greutatea totala avand marimea particulelor de minimum mm',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu Oxid de aluminiu altul decat corindon artificial',
    'Corindon artificial cu compozitie chimica definita sau nu oxid de aluminiu hidroxid de aluminiu Hidroxid de aluminiu',
    'Oxizi si hidroxizi de crom Trioxid de crom',
    'Oxizi si hidroxizi de crom Dioxid de crom',
    'Oxizi si hidroxizi de crom Altele',
    'Oxizi de mangan Dioxid de mangan',
    'Oxizi de mangan Oxid de mangan care contine mangan minimum  in greutate',
    'Oxizi de mangan Altele',
    'Oxizi si hidroxizi de fier pamanturi colorante care contin minimum  in greutate fier combinat evaluat in FeO Oxizi si hidroxizi de fier',
    'Oxizi si hidroxizi de fier pamanturi colorante care contin minimum  in greutate fier combinat evaluat in FeO Pamanturi colorante',
    'Oxizi si hidroxizi de cobalt oxizi de cobalt comerciali Oxizi si hidroxizi de cobalt oxizi de cobalt comerciali',
    'Oxizi de titan Oxizi de titan',
    'Oxizi de plumb miniu de plumb rosu si portocaliu Monoxid de plumb litarga massicot',
    'Oxizi de plumb miniu de plumb rosu si portocaliu Altele',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Hidrazina si hidroxilamina si sarurile lor anorganice',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxid si hidroxid de litiu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi si hidroxizi de vanadiu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi si hidroxizi de nichel',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi si hidroxizi de cupru',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi de germaniu si dioxid de zirconiu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi si hidroxizi de molibden',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi de antimoniu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Hidroxid de calciu de o puritate calculata in substanta uscata de minimum  sub forma de particule dintre care  maximum  in greutate sunt de o dimensiune peste microni si  maximum  in greutate sunt de o dimensiune sub microni',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Altele',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxid si hidroxid de beriliu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxizi si hidroxizi de tungsten',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Oxid de cadmiu',
    'Hidrazina si hidroxilamina si sarurile lor anorganice alte baze anorganice alti oxizi hidroxizi si peroxizi de metale Altele',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor De aluminiu',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor De amoniu sau de sodiu',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor Altele',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor Hexafluoroaluminat de sodiu criolit sintetic',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor Hexafluorozirconat de dipotasiu',
    'Fluoruri fluorosilicati fluoroaluminati si alte saruri complexe de fluor Altele',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Clorura de amoniu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Clorura de calciu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De magneziu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De aluminiu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De nichel',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De staniu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De fier',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De cobalt',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Altele',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De cupru',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri De plumb',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Altele',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Bromuri de sodiu sau de potasiu',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Altele',
    'Cloruri oxicloruri si hidroxicloruri bromuri si oxibromuri ioduri si oxiioduri Ioduri si oxiioduri',
    'Hipocloriti hipoclorit de calciu comercial cloriti hipobromiti Hipoclorit de calciu comercial si alti hipocloriti de calciu',
    'Hipocloriti hipoclorit de calciu comercial cloriti hipobromiti Altele',
    'Clorati si perclorati bromati si perbromati iodati si periodati De sodiu',
    'Clorati si perclorati bromati si perbromati iodati si periodati Altele',
    'Clorati si perclorati bromati si perbromati iodati si periodati Perclorati',
    'Clorati si perclorati bromati si perbromati iodati si periodati Bromat de potasiu sau de sodiu',
    'Clorati si perclorati bromati si perbromati iodati si periodati Altele',
    'Sulfuri polisulfuri cu compozitie chimica definita sau nu Sulfuri de sodiu',
    'Sulfuri polisulfuri cu compozitie chimica definita sau nu Sulfuri de calciu de antimoniu de fier',
    'Sulfuri polisulfuri cu compozitie chimica definita sau nu Altele',
    'Ditioniti si sulfoxilati De sodiu',
    'Ditioniti si sulfoxilati Altele',
    'Sulfiti tiosulfati Sulfiti de sodiu',
    'Sulfiti tiosulfati Alti sulfiti',
    'Sulfiti tiosulfati Tiosulfati',
    'Sulfati alauni persulfati Sulfat de disodiu',
    'Sulfati alauni persulfati Altele',
    'Sulfati alauni persulfati De magneziu',
    'Sulfati alauni persulfati De aluminiu',
    'Sulfati alauni persulfati De nichel',
    'Sulfati alauni persulfati De cupru',
    'Sulfati alauni persulfati De bariu',
    'Sulfati alauni persulfati De cadmiu de crom de zinc',
    'Sulfati alauni persulfati De cobalt de titan',
    'Sulfati alauni persulfati De plumb',
    'Sulfati alauni persulfati Altele',
    'Sulfati alauni persulfati Alauni',
    'Sulfati alauni persulfati Persulfati',
    'Nitriti azotiti nitrati azotati Nitriti azotiti',
    'Nitriti azotiti nitrati azotati De potasiu',
    'Nitriti azotiti nitrati azotati De bariu de beriliu de cadmiu de cobalt de nichel de plumb',
    'Nitriti azotiti nitrati azotati De cupru',
    'Nitriti azotiti nitrati azotati Altele',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Fosfinati hipofosfiti si fosfonati fosfiti',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu De mono sau de disodiu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu De potasiu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Hidrogenoortofosfat de calciu fosfat dicalcic',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Alti fosfati de calciu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu De triamoniu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu De trisodiu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Altele',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Trifosfat de sodiu tripolifosfat de sodiu',
    'Fosfinati hipofosfiti fosfonati fosfiti si fosfati polifosfati cu compozitie chimica definita sau nu Altele',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonat disodic',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Hidrogenocarbonat de sodiu bicarbonat de sodiu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonati de potasiu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonat de calciu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonat de bariu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonati de litiu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Carbonat de strontiu',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu De magneziu de cupru',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Altele',
    'Carbonati percarbonati carbonat de amoniu comercial care contine carbamat de amoniu Percarbonati',
    'Cianuri oxicianuri si cianuri complexe De sodiu',
    'Cianuri oxicianuri si cianuri complexe Altele',
    'Cianuri oxicianuri si cianuri complexe Cianuri complexe',
    'Silicati silicati de metale alcaline comerciali Metasilicati',
    'Silicati silicati de metale alcaline comerciali Altele',
    'Silicati silicati de metale alcaline comerciali Altele',
    'Borati perborati Anhidru',
    'Borati perborati Tetraborat de disodiu pentahidrat',
    'Borati perborati Altele',
    'Borati perborati Borati de sodiu anhidri',
    'Borati perborati Altii',
    'Borati perborati Perborati',
    'Saruri ale oxiacizilor metalici sau permetalici Dicromat de sodiu',
    'Saruri ale oxiacizilor metalici sau permetalici Alti cromati si dicromati percromati',
    'Saruri ale oxiacizilor metalici sau permetalici Permanganat de potasiu',
    'Saruri ale oxiacizilor metalici sau permetalici Altele',
    'Saruri ale oxiacizilor metalici sau permetalici Molibdati',
    'Saruri ale oxiacizilor metalici sau permetalici Tungstenati wolframati',
    'Saruri ale oxiacizilor metalici sau permetalici Zincati vanadati',
    'Saruri ale oxiacizilor metalici sau permetalici Altele',
    'Alte saruri ale acizilor sau peracizilor anorganici inclusiv aluminosilicati cu compozitie chimica definita sau nu altele decat azidele Silicati dubli sau complecsi inclusiv aluminosilicati cu compozitie chimica definita sau nu',
    'Alte saruri ale acizilor sau peracizilor anorganici inclusiv aluminosilicati cu compozitie chimica definita sau nu altele decat azidele Saruri simple duble sau complexe ale acizilor seleniului sau telurului',
    'Alte saruri ale acizilor sau peracizilor anorganici inclusiv aluminosilicati cu compozitie chimica definita sau nu altele decat azidele Altele',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Argint',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Altele',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Nitrat de argint',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Altele',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Compusi ai aurului',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Amalgame',
    'Metale pretioase in stare coloidala compusi anorganici sau organici ai metalelor pretioase cu compozitie chimica definita sau nu amalgame de metale pretioase Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Brut deseuri si resturi Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Prelucrat Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Ferouraniu',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Ferouraniu',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Ferouraniu',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Metaloceramice',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Metaloceramice',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Brut deseuri si resturi Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Bare profile fire tole benzi si foi Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse De uraniu saracit in U de toriu chiar amestecati intre ei Euratom cu exceptia sarurilor de toriu',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Izotop radioactiv artificial Euratom compusi ai izotopului radioactiv artificial Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Izotopi radioactivi artificiali Euratom compusi ai izotopilor radioactivi artificiali Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Uraniu si compusii sai aliaje dispersii inclusiv metaloceramice produse ceramice si amestecuri care contin uraniu sau compusii sai',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Izotopi radioactivi artificiali Euratom compusi ai izotopilor radioactivi artificiali Euratom',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Altele',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Reziduuri radioactive',
    'Elemente chimice radioactive si izotopi radioactivi inclusiv elementele chimice si izotopii fisionabili sau fertili si compusii lor amestecuri si reziduuri care contin aceste produse Elemente combustibile cartuse uzate iradiate ale reactoarelor nucleare Euratom',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Apa grea oxid de deuteriu Euratom',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Bor imbogatit in bor si compusii sai',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Litiu imbogatit in litiu si compusii sai',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Heliu',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Deuteriu si compusi de deuteriu hidrogen si compusii sai imbogatiti in deuteriu amestecuri si solutii care contin aceste produse Euratom',
    'Izotopi altii decat cei de la pozitia compusii lor anorganici sau organici cu compozitie chimica definita sau nu Altele',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai ceriului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai scandiului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai lantanului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai praseodimului ai neodimului sau ai samariului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai gadoliniului ai terbiului sau ai dysprosiului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai europiului ai holmiului ai erbiului ai tuliului ai yterbiului ai lutetiului sau ai ytriului',
    'Compusi anorganici sau organici ai metalelor de pamanturi rare ai ytriului sau ai scandiului sau ai amestecurilor din aceste metale Compusi ai amestecurilor de metale',
    'Peroxid de hidrogen apa oxigenata chiar solidificat cu uree Peroxid de hidrogen apa oxigenata chiar solidificat cu uree',
    'Carburi cu compozitie chimica definita sau nu De calciu',
    'Carburi cu compozitie chimica definita sau nu De siliciu',
    'Carburi cu compozitie chimica definita sau nu De bor',
    'Carburi cu compozitie chimica definita sau nu De tungsten',
    'Carburi cu compozitie chimica definita sau nu De aluminiu de crom de molibden de vanadiu de tantal de titan',
    'Carburi cu compozitie chimica definita sau nu Altele',
    'Hidruri nitruri azide siliciuri si boruri cu compozitie chimica definita sau nu altele decat compusii care constituie in acelasi timp carburi de la pozitia Hidruri nitruri',
    'Hidruri nitruri azide siliciuri si boruri cu compozitie chimica definita sau nu altele decat compusii care constituie in acelasi timp carburi de la pozitia Azide siliciuri',
    'Hidruri nitruri azide siliciuri si boruri cu compozitie chimica definita sau nu altele decat compusii care constituie in acelasi timp carburi de la pozitia Boruri',
    'Compusi organici sau anorganici ai mercurului cu compozitie chimica definita sau nu cu exceptia amalgamelor Cu compozitie chimica definita',
    'Compusi organici sau anorganici ai mercurului cu compozitie chimica definita sau nu cu exceptia amalgamelor Altele',
    'Fosfuri definite chimic sau nu cu exceptia ferofosfurilor alti compusi anorganici inclusiv apa distilata sau de conductibilitate si apa de puritate similara aer lichid indiferent daca gazele rare au fost eliminate sau nu aer comprimat amalgame altele decat amalgamele de metale pretioase Clorura de cianogen clorcian',
    'Fosfuri definite chimic sau nu cu exceptia ferofosfurilor alti compusi anorganici inclusiv apa distilata sau de conductibilitate si apa de puritate similara aer lichid indiferent daca gazele rare au fost eliminate sau nu aer comprimat amalgame altele decat amalgamele de metale pretioase Apa distilata apa de conductibilitate sau apa de puritate similara',
    'Fosfuri definite chimic sau nu cu exceptia ferofosfurilor alti compusi anorganici inclusiv apa distilata sau de conductibilitate si apa de puritate similara aer lichid indiferent daca gazele rare au fost eliminate sau nu aer comprimat amalgame altele decat amalgamele de metale pretioase Aer lichid inclusiv aerul lichid din care au fost eliminate gazele rare aer comprimat',
    'Fosfuri definite chimic sau nu cu exceptia ferofosfurilor alti compusi anorganici inclusiv apa distilata sau de conductibilitate si apa de puritate similara aer lichid indiferent daca gazele rare au fost eliminate sau nu aer comprimat amalgame altele decat amalgamele de metale pretioase Altele',
    'Hidrocarburi aciclice Saturate',
    'Hidrocarburi aciclice Etilena',
    'Hidrocarburi aciclice Propena propilena',
    'Hidrocarburi aciclice Butena butilena si izomerii acesteia',
    'Hidrocarburi aciclice Butadiena si izopren',
    'Hidrocarburi aciclice Altele',
    'Hidrocarburi ciclice Ciclohexan',
    'Hidrocarburi ciclice Altele',
    'Hidrocarburi ciclice Benzen',
    'Hidrocarburi ciclice Toluen',
    'Hidrocarburi ciclice oXilen',
    'Hidrocarburi ciclice mXilen',
    'Hidrocarburi ciclice pXilen',
    'Hidrocarburi ciclice Amestec de izomeri ai xilenului',
    'Hidrocarburi ciclice Stiren',
    'Hidrocarburi ciclice Etilbenzen',
    'Hidrocarburi ciclice Cumen',
    'Hidrocarburi ciclice Altele',
    'Derivati halogenati ai hidrocarburilor Clormetan clorura de metil si cloretan clorura de etil',
    'Derivati halogenati ai hidrocarburilor Diclormetan clorura de metilen',
    'Derivati halogenati ai hidrocarburilor Cloroform triclormetan',
    'Derivati halogenati ai hidrocarburilor Tetraclorura de carbon',
    'Derivati halogenati ai hidrocarburilor Clorura de etilena ISO dicloretan',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Clorura de vinil cloretilena',
    'Derivati halogenati ai hidrocarburilor Tricloretilena',
    'Derivati halogenati ai hidrocarburilor Tetracloretilena percloretilena',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Trifluormetan HFC',
    'Derivati halogenati ai hidrocarburilor Difluormetan HFC',
    'Derivati halogenati ai hidrocarburilor Fluormetan HFC difluoretan HFC si difluoretan HFCa',
    'Derivati halogenati ai hidrocarburilor Pentafluoretan HFC trifluoretan HFCa si trifluoretan HFC',
    'Derivati halogenati ai hidrocarburilor tetrafluoretan HFCa si tetrafluoretan HFC',
    'Derivati halogenati ai hidrocarburilor heptafluorpropan HFCea hexafluorpropan HFCcb hexafluorpropan HFCea si hexafluorpropan HFCfa',
    'Derivati halogenati ai hidrocarburilor pentafluorpropan HFCfa si pentafluorpropan HFCca',
    'Derivati halogenati ai hidrocarburilor pentafluorbutan HFCmfc si decafluorpentan HFCmee',
    'Derivati halogenati ai hidrocarburilor Alti pentafluorpropani hexafluorpropani si heptafluorpropani',
    'Derivati halogenati ai hidrocarburilor Derivati perfluorurati',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor tetrafluorpropena HFOyf tetrafluorpropena HFOze si Zhexafluorbutena HFOmzz',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Bromura de metil bromometan',
    'Derivati halogenati ai hidrocarburilor Dibromura de etilena ISO dibrometan',
    'Derivati halogenati ai hidrocarburilor Dibrometan',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Ioduri',
    'Derivati halogenati ai hidrocarburilor Clorodifluormetan HCFC',
    'Derivati halogenati ai hidrocarburilor Diclorotrifluoroetani HCFC',
    'Derivati halogenati ai hidrocarburilor Diclorofluoroetani HCFC b',
    'Derivati halogenati ai hidrocarburilor Clorodifluoroetani HCFC b',
    'Derivati halogenati ai hidrocarburilor Dicloropentafluoropropani HCFC ca cb',
    'Derivati halogenati ai hidrocarburilor Bromoclorodifluormetan Halon',
    'Derivati halogenati ai hidrocarburilor Bromotrifluorometan Halon',
    'Derivati halogenati ai hidrocarburilor Dibromotetrafluoroetani Halon',
    'Derivati halogenati ai hidrocarburilor Triclorfluormetan diclordifluormetan triclortrifluoretani diclortetrafluoretani si clorpentafluoretan',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Alti derivati perhalogenati',
    'Derivati halogenati ai hidrocarburilor Halogenati numai cu brom si clor cu fluor si clor sau cu fluor si brom',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Hexaclorciclohexan HCH ISO inclusiv lindan ISO DCI',
    'Derivati halogenati ai hidrocarburilor Aldrin ISO clordan ISO si heptaclor ISO',
    'Derivati halogenati ai hidrocarburilor Mirex ISO',
    'Derivati halogenati ai hidrocarburilor Dibromdibrometilciclohexan tetrabromciclooctani',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati halogenati ai hidrocarburilor Clorbenzen ortodiclorbenzen si paradiclorbenzen',
    'Derivati halogenati ai hidrocarburilor Hexaclorbenzen ISO si DDT ISO clofenotan DCI triclorbispclorfeniletan',
    'Derivati halogenati ai hidrocarburilor Pentaclorbenzen ISO',
    'Derivati halogenati ai hidrocarburilor Hexabrombifenili',
    'Derivati halogenati ai hidrocarburilor Pentabrometilbenzen',
    'Derivati halogenati ai hidrocarburilor Altele',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Derivati care contin numai grupe sulfonice sarurile lor si esterii lor etilici',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Derivati care contin numai grupe nitro sau nitrozo',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Acid perfluoroctansulfonic',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Perfluoroctan sulfonat de amoniu',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Perfluoroctan sulfonat de litiu',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Perfluoroctan sulfonat de potasiu',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Alte saruri ale acidului perfluoroctansulfonic',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Fluorura de sulfonil perfluorooctanica',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Triclornitrometan cloropicrina',
    'Derivati sulfonati nitrati sau nitrozati ai hidrocarburilor chiar halogenate Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Metanol alcool metilic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Propanol alcool propilic si propanol alcool izopropilic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Butanol alcool nbutilic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Metilpropanol alcool tertbutilic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Octanol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Dodecanol alcool lauric hexadecanol alcool cetilic si octadecanol alcool stearic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Alcooli terpenici aciclici',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Alcool alilic',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Etilen glicol etandiol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Propilen glicol propandiol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Butandiol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Butandiol sau tetrametilen glicol butandiol cu un continut de carbon din biomasa de  in masa',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Tetrametildecinadiol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Etilhidroximetilpropandiol trimetilolpropan',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Pentaeritritol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Manitol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Care contine Dmanitol intro proportie de maximum  in greutate calculat in raport cu continutul de Dglucitol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Care contin Dmanitol intro proportie de maximum  in greutate calculat in raport cu continutul de Dglucitol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Glicerina',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Etclorvinol DCI',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Bisbromometilpropandiol',
    'Alcooli aciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Mentol',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Ciclohexanol metilciclohexanoli si dimetilciclohexanoli',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Steroli',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Inozitoli',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Alcool benzilic',
    'Alcooli ciclici si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Fenoli fenolialcooli Fenol hidroxibenzen si sarurile lui',
    'Fenoli fenolialcooli Crezoli si sarurile lor',
    'Fenoli fenolialcooli Octifenol nonilfenol si izomerii lor sarurile acestor produse',
    'Fenoli fenolialcooli Naftol',
    'Fenoli fenolialcooli Altele',
    'Fenoli fenolialcooli Xilenoli si sarurile lor',
    'Fenoli fenolialcooli Altele',
    'Fenoli fenolialcooli Rezorcinol si sarurile lui',
    'Fenoli fenolialcooli Hidrochinona si sarurile ei',
    'Fenoli fenolialcooli Izopropilidendifenol bisfenolA difenilolpropan si sarurile lui',
    'Fenoli fenolialcooli Altele',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai fenolilor sau ai fenolialcoolilor Pentaclorfenol ISO',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai fenolilor sau ai fenolialcoolilor Altele',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai fenolilor sau ai fenolialcoolilor Dinoseb ISO si sarurile sale',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai fenolilor sau ai fenolialcoolilor Dinitroocrezol DNOC ISO si sarurile sale',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai fenolilor sau ai fenolialcoolilor Altele',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Eter etilic oxid de dietil',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Terbutil etil eter etilterbutileter ETBE',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Eteri ciclanici ciclenici cicloterpenici si derivatii lor halogenati sulfonati nitrati sau nitrozati',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Difenil eter oxid de difenil',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Pentabromdifenil eter tetrabrombis pentabromfenoxibenzen',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Bistribromfenoxietan pentru producerea acrilonitrilbutadienstiren ABS',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Oxidietanol dietilenglicol',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Eteri monobutilici de etilen glicol sau de dietilen glicol',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Alti eteri monoalchilici de etilen glicol sau de dietilen glicol',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati cloretoxietanol',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Eterifenoli eterialcoolifenoli si derivatii lor halogenati sulfonati nitrati sau nitrozati',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati peroxizi ai acetalilor si ai semiacetalilor',
    'Eteri eterialcooli eterifenoli eterialcoolifenoli peroxizi ai alcoolilor peroxizi ai eterilor peroxizi ai acetalilor si ai semiacetalilor peroxizi ai cetonelor cu compozitie chimica definita sau nu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Oxiran oxid de etilena',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Metiloxiran oxid de propilena',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Clorepoxipropan epiclorhidrina',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Dieldrin ISO DCI',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Endrin ISO',
    'Epoxizi epoxialcooli epoxifenoli si epoxieteri cu trei atomi in ciclu si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acetali si semiacetali care contin chiar alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Acetali si semiacetali care contin chiar alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Metanal formaldehida',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Etanal acetaldehida',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Altele',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Benzaldehida aldehida benzoica',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Altele',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Vanilina metilprotocatechualdehida sau hidroximetoxibenzaldehida',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Etilvanilina etil protocatechualdehida sau etoxihidroxibenzaldehida',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Altele',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Polimeri ciclici ai aldehidelor',
    'Aldehide chiar continand alte functii oxigenate polimeri ciclici ai aldehidelor paraformaldehida Paraformaldehida',
    'Derivati halogenati sulfonati nitrati sau nitrozati ai produselor de la pozitia Derivati halogenati sulfonati nitrati sau nitrozati ai produselor de la pozitia',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Acetona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Butanona metiletilcetona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Metilpentanona metilizobutilcetona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Metilhexanona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Ciclohexanona si metilciclohexanone',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Ionone metilionone',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Fenilacetona fenilpropanona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Hidroximetilpentanona diacetona alcool',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Cetonefenoli si cetone care contin alte functii oxigenate',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Antrachinona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Coenzima Q ubidecarenon DCI',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Naftachinona',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Clordecon ISO',
    'Cetone si chinone chiar continand alte functii oxigenate si derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid formic',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Sarurile acidului formic',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Esterii acidului formic',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid acetic',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Anhidrida acetica',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acetat de etil',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acetat de vinil',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acetat de nbutil',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Dinoseb ISO acetat',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi mono di sau tricloracetici sarurile si esterii lor',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid propionic sarurile si esterii lui',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Izopropildimetiltrimetilen diisobutirat',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi pentanoici sarurile si esterii lor',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid palmitic sarurile si esterii lui',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid stearic sarurile si esterii lui',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid lauric sarurile si esterii lui',
    'Acizi monocarboxilici aciclici saturati si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid acrilic si sarurile lui',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Esterii acidului acrilic',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid metacrilic si sarurile lui',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Esterii acidului metacrilic',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi oleic linoleic sau linolenic sarurile si esterii lor',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Binapacril ISO',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi undecenoici sarurile si esterii lor',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid crotonic',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi monocarboxilici ciclanici ciclenici sau cicloterpenici anhidridele halogenurile peroxizii peroxiacizii si derivatii lor',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid benzoic sarurile si esterii lui',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Peroxid de benzoil si clorura de benzoil',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid fenilacetic si sarurile sale',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Esteri ai acidului fenilacetic',
    'Acizi monocarboxilici aciclici nesaturati si acizi monocarboxilici ciclici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid oxalic sarurile si esterii lui',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid adipic sarurile si esterii lui',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid sebacic',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Anhidrida maleica',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid malonic sarurile si esterii sai',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid etandicarboxilic sau acid butandioic acid succinic cu un continut de carbon din biomasa de  in masa',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi policarboxilici ciclanici ciclenici sau cicloterpenici anhidridele halogenurile peroxizii peroxiacizii si derivatii lor',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Ortoftalatii de dioctil',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Ortoftalatii de dinonil sau de didecil',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Alti esteri ai acidului ortoftalic',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Anhidrida ftalica',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid tereftalic si sarurile lui',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Tereftalat de dimetil',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Ester sau anhidrida ale acidului tetrabromftalic acid benzentricarboxilic isoftaloil diclorura care contine in greutate maximum  tereftaloil diclorura acid naftalentetracarboxilic anhidrida tetraclorftalica bismetoxicarbonil benzensulfonat de sodiu',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Bisetilhexil benzendicarboxilat DOTP',
    'Acizi policarboxilici anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid lactic sarurile si esterii lui',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid tartric',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Sarurile si esterii acidului tartric',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid citric',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Sarurile si esterii acidului citric',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid gluconic sarurile si esterii lui',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid difenilhidroxiacetic acid benzilic',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Clorbenzilat ISO',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid colic acid dihidroxicolanoic acid dezoxicolic sarurile si esterii lor',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid bishidroximetilpropionic',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid salicilic si sarurile lui',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid oacetilsalicilic sarurile si esterii lui',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Alti esteri ai acidului salicilic si sarurile lor',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acizi carboxilici cu functie aldehida sau cetona dar fara alte functii oxigenate anhidridele halogenurile peroxizii peroxiacizii si derivatii lor',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati T ISO acid triclorfenoxiacetic sarurile si esterii lui',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Acid dimetoxibenzoic dicamba ISO fenoxiacetat de sodiu',
    'Acizi carboxilici cu functii oxigenate suplimentare si anhidridele halogenurile peroxizii si peroxiacizii lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Esteri fosforici si sarurile lor inclusiv lactofosfati derivatii lor halogenati sulfonati nitrati sau nitrozati Trisdibromopropil fosfat',
    'Esteri fosforici si sarurile lor inclusiv lactofosfati derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Paration ISO si paration metil ISO metilparation',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Fosfit de dimetil',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Fosfit de dietil',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Fosfit de trimetil',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Fosfit de trietil',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Endosulfan ISO',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Esteri sulfurici si esteri carbonici sarurile lor si derivatii lor halogenati sulfonati nitrati nitrozati',
    'Esterii altor acizi anorganici ai nemetalelor cu exceptia esterilor acizilor halogenhidrici si sarurile lor derivatii lor halogenati sulfonati nitrati sau nitrozati Altele',
    'Compusi cu functie aminica Mono di sau trimetilamina si sarurile lor',
    'Compusi cu functie aminica Clorura de NNdimetilaminoetil clorhidrat',
    'Compusi cu functie aminica Clorura de NNdietilaminoetil clorhidrat',
    'Compusi cu functie aminica Clorura de NNdiizopropilaminoetil clorhidrat',
    'Compusi cu functie aminica Tetrametilbutilamina',
    'Compusi cu functie aminica Dietilamina si sarurile ei',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica Etilendiamina si sarurile ei',
    'Compusi cu functie aminica Hexametilendiamina si sarurile ei',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica Ciclohexilamina ciclohexildimetilamina si sarurile lor',
    'Compusi cu functie aminica Ciclohexilendiamina diaminociclohexan',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica Anilina si sarurile ei',
    'Compusi cu functie aminica Derivati ai anilinei si sarurile acestor produse',
    'Compusi cu functie aminica Toluidine si derivatii lor sarurile acestor produse',
    'Compusi cu functie aminica Difenilamina si derivatii ei sarurile acestor produse',
    'Compusi cu functie aminica Naftilamina alfanaftilamina naftilamina betanaftilamina si derivatii lor sarurile acestor produse',
    'Compusi cu functie aminica Amfetamina DCI benzfetamina DCI dexamfetamina DCI etilamfetamina DCI fencamfamina DCI lefetamina DCI levamfetamina DCI mefenorex DCI si fentermina DCI sarurile acestor produse',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica mFenilendiamina de o puritate de minimum  in greutate si care contine  maximum  in greutate apa  maximum mgkg ofenilendiamina si  maximum mgkg pfenilendiamina',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica Altele',
    'Compusi cu functie aminica mFenilenbismetilamina diclormetilendianilina biotoluidina naftilendiamina',
    'Compusi cu functie aminica Altele',
    'Amino compusi cu functii oxigenate Monoetanolamina si sarurile ei',
    'Amino compusi cu functii oxigenate Dietanolamina si sarurile ei',
    'Amino compusi cu functii oxigenate Dextropropoxifen DCI si sarurile lui',
    'Amino compusi cu functii oxigenate Trietanolamina',
    'Amino compusi cu functii oxigenate Perfluoroctan sulfonat de dietanolamoniu',
    'Amino compusi cu functii oxigenate Metildietanolamina si etildietanolamina',
    'Amino compusi cu functii oxigenate NNdiizopropilaminoetanol',
    'Amino compusi cu functii oxigenate Altele',
    'Amino compusi cu functii oxigenate Acizi aminonaftolsulfonici si sarurile lor',
    'Amino compusi cu functii oxigenate Altele',
    'Amino compusi cu functii oxigenate Amfepramona DCI metadona DCI si normetadona DCI sarurile acestor produse',
    'Amino compusi cu functii oxigenate Altele',
    'Amino compusi cu functii oxigenate Lizina si esterii ei sarurile acestor produse',
    'Amino compusi cu functii oxigenate Acid glutamic si sarurile lui',
    'Amino compusi cu functii oxigenate Acid antranilic si sarurile lui',
    'Amino compusi cu functii oxigenate Tilidina DCI si sarurile ei',
    'Amino compusi cu functii oxigenate betaAlanina',
    'Amino compusi cu functii oxigenate Altele',
    'Amino compusi cu functii oxigenate Aminoalcoolifenoli aminoacizifenoli si alti aminocompusi cu functii oxigenate',
    'Saruri si hidroxizi de amoniu cuaternari lecitine si alte fosfoaminolipide cu compozitie chimica definita sau nu Colina si sarurile ei',
    'Saruri si hidroxizi de amoniu cuaternari lecitine si alte fosfoaminolipide cu compozitie chimica definita sau nu Lecitine si alte fosfoaminolipide',
    'Saruri si hidroxizi de amoniu cuaternari lecitine si alte fosfoaminolipide cu compozitie chimica definita sau nu Perfluoroctan sulfonat de tetraetilamoniu',
    'Saruri si hidroxizi de amoniu cuaternari lecitine si alte fosfoaminolipide cu compozitie chimica definita sau nu Perfluoroctan sulfonat de didecildimetilamoniu',
    'Saruri si hidroxizi de amoniu cuaternari lecitine si alte fosfoaminolipide cu compozitie chimica definita sau nu Altele',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Meprobamat DCI',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Fluoracetamid ISO monocrotofos ISO si fosfamidon ISO',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Altele',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Ureine si derivatii lor sarurile acestor produse',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Acid acetamidobenzoic acid Nacetilantranilic si sarurile lui',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Etinamat DCI',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Alaclor ISO',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Lidocaina DCI',
    'Compusi cu functie carboxiamida compusi cu functie amida ai acidului carbonic Altele',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina Zaharina si sarurile ei',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina Glutetimid DCI',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina OctabromNNetilendiftalimida NNetilenbisdibromhexahidrometanftalmida',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina Altele',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina Clordimeform ISO',
    'Compusi cu functie carboximida inclusiv zaharina si sarurile ei si compusi cu functie imina Altele',
    'Compusi cu functie nitril Acrilonitril',
    'Compusi cu functie nitril Cianoguanidina diciandiamida',
    'Compusi cu functie nitril Fenproporex DCI si sarurile lui metadona DCI intermediar cianodimetilaminodifenilbutan',
    'Compusi cu functie nitril Alfafenilacetoacetonitril',
    'Compusi cu functie nitril Izoftalonitril',
    'Compusi cu functie nitril Altele',
    'Compusi diazoici azoici sau azoxici Compusi diazoici azoici sau azoxici',
    'Derivati organici ai hidrazinei sau ai hidroxilaminei NNbismetoxietilhidroxilamina',
    'Derivati organici ai hidrazinei sau ai hidroxilaminei Altele',
    'Compusi cu alte functii azotate Izocianati',
    'Compusi cu alte functii azotate Altele',
    'Tiocompusi organici NNdimetilamino etanetiol',
    'Tiocompusi organici Tiocarbamati si ditiocarbamati',
    'Tiocompusi organici Mono disau tetrasulfuri de tiouram',
    'Tiocompusi organici Metionina DCI',
    'Tiocompusi organici Altele',
    'Tiocompusi organici NNdietilaminoetantiol',
    'Tiocompusi organici Sulfura de bihidroxietil tiodiglicol DCI',
    'Tiocompusi organici Aldicarb ISO captafol ISO si metamidofos ISO',
    'Tiocompusi organici Cisteina si cistina',
    'Tiocompusi organici Derivati de cisteina si cistina',
    'Tiocompusi organici Acid DLhidroximetiltio butiric',
    'Tiocompusi organici tiodietilbisditertbutilhidroxifenilpropionat',
    'Tiocompusi organici Amestec de izomeri metilbis metiltiomfenilendiamina si metilbis metiltiomfenilendiamina',
    'Tiocompusi organici Altele',
    'Alti compusi organoanorganici Tetrametil de plumb si tetraetil de plumb',
    'Alti compusi organoanorganici Compusi tributilstanici',
    'Alti compusi organoanorganici Metilfosfonat de dimetil',
    'Alti compusi organoanorganici Propilfosfonat de dimetil',
    'Alti compusi organoanorganici Etilfosfonat de dietil',
    'Alti compusi organoanorganici Acid metilfosfonic',
    'Alti compusi organoanorganici Sare de acid metilfosfonic si de aminoiminometiluree',
    'Alti compusi organoanorganici tripropiltrioxatrifosfinan trioxid',
    'Alti compusi organoanorganici etilmetiloxidodioxafosfinanilmetil metilfosfonat',
    'Alti compusi organoanorganici dimetiltetraoxadifosfaspiro undecan dioxid',
    'Alti compusi organoanorganici trihidroxisililpropil metilfosfonat de sodiu',
    'Alti compusi organoanorganici bietilmetiloxidodioxafosfinanilmetil metilfosfonat',
    'Alti compusi organoanorganici Acid etidronic INN acid hidroxietandifosfonic si sarurile sale',
    'Alti compusi organoanorganici Nitrilotrimetandiiltrisacid fosfonic etandiilbisnitrilobismetilentetrakisacid fosfonic acidbisbisfosfonometilaminoetilaminometilfosfonic hexandiilbisnitrilobismetilentetrakisacid fosfonic hidroxietiliminobismetilenbisacid fosfonic si acidbisbisfosfonometilaminohexilaminometilfosfonic sarurile acestor produse',
    'Alti compusi organoanorganici Altele',
    'Alti compusi organoanorganici Diclorura metilfosfonica',
    'Alti compusi organoanorganici Diclorura propilfosfonica',
    'Alti compusi organoanorganici Ocloropropil Onitrotrifluormetilfenil metilfosfonotionat',
    'Alti compusi organoanorganici Triclorfon ISO',
    'Alti compusi organoanorganici Difluorura de metilfosfonoil difluorura metilfosfonica',
    'Alti compusi organoanorganici Altele',
    'Alti compusi organoanorganici Altele',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Tetrahidrofuran',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Furaldehida furfuraldehida furfural',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Alcool furfurilic si alcool tetrahidrofurfurilic',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Sucraloza',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Altele',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Fenolftaleina acid hidroxihidroximetoxicarbonilnaftiloxoHHbenzodeizocromeniloctadeciloxinaftoic clorciclohexilaminospiroizobenzofuranHxantenona NetilptoluidinometilspiroizobenzofuranHxantenona docosiloxihidroxihidroximetilfenantriloxoHHnaftocdpiranilnaftalencarboxilat de metil',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen gammaButirlactona',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Altele',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Izosafrol',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Benzodioxalilpropanona',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Piperonal',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Safrol',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Tetrahidroxocanabinoli toti izomerii',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Carbofuran ISO',
    'Compusi heterociclici care contin ca heteroatomi numai oxigen Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Propifenazona DCI',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Fenilbutazona DCI',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Hidantoina si derivatii ei',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Clorhidrat de nafazolina DCIM si nitrat de nafazolina DCIM fentolamina DCI clorhidrat de tolazolina DCIM',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Piridina si sarurile ei',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Piperidina si sarurile ei',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Alfentanil DCI anileridina DCI bezitramida DCI bromazepam DCI carfentanil DCI difenoxin DCI difenoxilat DCI dipipanona DCI fentanil DCI ketobemidona DCI metilfenidat DCI pentazocina DCI petidina DCI petidina DCI intermediar A fenciclidina DCI PCP fenoperidina DCI pipradrol DCI piritramida DCI propiram DCI remifentanil DCI si trimeperidina DCI sarurile acestora',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Alti fentanili si derivatii lor',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate quinuclidin',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate anilinoNfenetilpiperidina ANPP',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Nfenetilpiperidona NPP',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Iproniazida DCI clorhidrat de cetobemidona DCIM bromura de piridostigmina DCI',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Tetraclorpiridina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Acid diclorpiridincarboxilic',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Diclorpiridincarboxilat de hidroxietilamoniu',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Butoxietil triclorpiridiloxiacetat',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Diclortrifluorpiridina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Ester metilic de fluoroxipir ISO',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Metilpiridina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Levorfanol DCI si sarurile lui',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Derivati halogenati ai chinoleinei derivati ai acizilor chinoleincarboxilici',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Dextrometorfan DCI si sarurile lui',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Maloniluree acid barbituric si sarurile ei',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Fenobarbital DCI barbital DCI si sarurile lor',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Alte derivate de maloniluree acid barbituric sarurile acestor produse',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Loprazolam DCI meclocualona DCI metacualona DCI si zipeprol sarurile acestor produse',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Diazinon ISO',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Diazabiciclooctan trietilendiamina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Melamina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Atrazina ISO propazina ISO simazina ISO hexahidrotrinitrotriazina hexogen trimetilentrinitramina',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Metenamina DCI hexametilentetramina ditertbutilbisoctiltiotriazinilaminofenol',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Hexanlactama epsiloncaprolactama',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Clobazam DCI si metiprilona DCI',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Alte lactame',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Clordiazepoxid DCI',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Azinfosmetil ISO',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Indol metilindol scatol alildihidroHdibenzoceazepina azapetina fenindamina DCI si sarurile lor clorhidrat de imipramina DCIM',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Ditertbutilclorbenzotriazolil fenol',
    'Compusi heterociclici cu heteroatomi de azot in exclusivitate Altele',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Compusi a caror structura contine un ciclu tiazol hidrogenat sau nu necondensat',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Disulfura de dibenzotiazolil benzotiazoltiol mercaptobenzotiazol si sarurile lui',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Altele',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Tietilperazina DCI tioridazina DCI si sarurile ei',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Altele',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Aminorex DCI brotizolam DCI clotiazepam DCI cloxazolam DCI dextromoramida DCI haloxazolam DCI ketazolam DCI mesocarb DCI oxazolam DCI pemolina DCI fendimetrazina DCI fenmetrazina DCI si sufentanil DCI sarurile acestor produse',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Alti fentanili si derivatii lor',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Clorprotixen DCI tenalidina DCI tartratii si maleatii ei furazolidon DCI acid aminocefalosporanic saruri si esteri ai acidului RRacetoximetilRformiloxifenilacetamidooxotiaazabiciclooctenacarboxilic bromura de dioxaniletilmetilpiridina',
    'Acizi nucleici si sarurile lor cu compozitie chimica definita sau nu alti compusi heterociclici Altele',
    'Sulfonamide Sulfonamida de Nmetilperfluoroctan',
    'Sulfonamide Sulfonamida de Netilperfluoroctan',
    'Sulfonamide Sulfonamida de NetilNhidroxietil perfluoroctan',
    'Sulfonamide Sulfonamida de NhidroxietilNmetilperfluoroctan',
    'Sulfonamide Alte sulfonamide de perfluoroctan',
    'Sulfonamide hexadecilsulfonilaminoHindoliloxoHHnaftocdpiranilNNdimetilHindolsulfonamida metosulam ISO',
    'Sulfonamide Altele',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitamineleA si derivatii lor',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaB si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaB si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii Acid D sau DLpantotenic vitaminaB si derivatii sai',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaB si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaB si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaC si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii VitaminaE si derivatii ei',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii Alte vitamine si derivatii lor',
    'Provitamine si vitamine naturale sau obtinute prin sinteza inclusiv concentratele naturale precum si derivatii lor utilizati in principal ca vitamine amestecati sau nu intre ei chiar in diferite solutii Altele inclusiv concentrate naturale',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Somatotropina derivatii si analogii ei structurali',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Insulina si sarurile ei',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Altele',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Cortizon hidrocortizon prednison dehidrocortizon si prednisolon dehidrohidrocortizon',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Derivati halogenati ai hormonilor corticosteroizi',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Estrogeni si progestogeni',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Altele',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Prostaglandine tromboxani si leucotriene derivatii si analogii lor structurali',
    'Hormoni prostaglandine tromboxani si leucotriene naturali sau reprodusi prin sinteza derivatii si analogii lor structurali inclusiv polipeptidele cu catena modificata utilizati in principal ca hormoni Altele',
    'Glicozide naturale sau reproduse prin sinteza sarurile eterii esterii lor si alti derivati Rutozida rutina si derivatii ei',
    'Glicozide naturale sau reproduse prin sinteza sarurile eterii esterii lor si alti derivati Glicozidele digitalelor',
    'Glicozide naturale sau reproduse prin sinteza sarurile eterii esterii lor si alti derivati Acid glicirizic si glicirizati',
    'Glicozide naturale sau reproduse prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Concentrat din tulpini de mac buprenorfina DCI codeina dihidrocodeina DCI etilmorfina etorfina DCI heroina hidrocodona DCI hidromorfona DCI morfina nicomorfina DCI oxicodona DCI oximorfona DCI folcodina DCI tebacona DCI si tebaina sarurile acestor produse',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Alcaloizii arborelui de chinina si derivatii lor sarurile acestor produse',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Cafeina si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Efedrina si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Pseudoefedrina DCI si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Catina DCI si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Norefedrina si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Levometamfetamina metamfetamina DCI racemat de metamfetamina si sarurile lor',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Fenetilina DCI si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Ergometrina DCI si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Ergotamina DCI si sarurile ei',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Acidul lisergic si sarurile lui',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Cocaina ecgonina saruri esteri si alti derivati ai acestor produse',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Nicotina si sarurile eterii esterii si alti derivati ai acesteia',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Alcaloizi naturali sau reprodusi prin sinteza sarurile eterii esterii lor si alti derivati Altele',
    'Zaharuri chimic pure cu exceptia zaharozei a lactozei a maltozei a glucozei si a fructozei levuloza eteri acetali si esteri ai zaharurilor si sarurile lor altele decat produsele de la pozitiile  si  Zaharuri chimic pure cu exceptia zaharozei a lactozei a maltozei a glucozei si a fructozei levuloza eteri acetali si esteri ai zaharurilor si sarurile lor altele decat produsele de la pozitiile  si',
    'Antibiotice Penicilinele si derivatii lor cu structura de acid penicilanic sarurile acestor produse',
    'Antibiotice Dihidrostreptomicina saruri esteri si hidrati ai acesteia',
    'Antibiotice Altele',
    'Antibiotice Tetraciclinele si derivatii lor sarurile acestor produse',
    'Antibiotice Cloramfenicol si derivatii lui sarurile acestor produse',
    'Antibiotice Eritromicina si derivatii ei sarurile acestor produse',
    'Antibiotice Altele',
    'Alti compusi organici Alti compusi organici',
    'Glande si alte organe pentru utilizari organoterapeutice uscate chiar pulverizate extracte pentru utilizari organoterapeutice de glande sau de alte organe sau din secretiile acestora heparina si sarurile acesteia alte substante de origine umana sau animala preparate pentru utilizari terapeutice sau profilactice nedenumite si necuprinse in alta parte De origine umana',
    'Glande si alte organe pentru utilizari organoterapeutice uscate chiar pulverizate extracte pentru utilizari organoterapeutice de glande sau de alte organe sau din secretiile acestora heparina si sarurile acesteia alte substante de origine umana sau animala preparate pentru utilizari terapeutice sau profilactice nedenumite si necuprinse in alta parte Altele',
    'Glande si alte organe pentru utilizari organoterapeutice uscate chiar pulverizate extracte pentru utilizari organoterapeutice de glande sau de alte organe sau din secretiile acestora heparina si sarurile acesteia alte substante de origine umana sau animala preparate pentru utilizari terapeutice sau profilactice nedenumite si necuprinse in alta parte De origine umana',
    'Glande si alte organe pentru utilizari organoterapeutice uscate chiar pulverizate extracte pentru utilizari organoterapeutice de glande sau de alte organe sau din secretiile acestora heparina si sarurile acesteia alte substante de origine umana sau animala preparate pentru utilizari terapeutice sau profilactice nedenumite si necuprinse in alta parte Heparina si sarurile acesteia',
    'Glande si alte organe pentru utilizari organoterapeutice uscate chiar pulverizate extracte pentru utilizari organoterapeutice de glande sau de alte organe sau din secretiile acestora heparina si sarurile acesteia alte substante de origine umana sau animala preparate pentru utilizari terapeutice sau profilactice nedenumite si necuprinse in alta parte Altele',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Antiseruri si alte fractiuni ale sangelui',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Produse imunologice neamestecate neconditionate in doze masurate sau in forme sau ambalaje pentru vanzarea cu amanuntul',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Produse imunologice amestecate neconditionate in doze masurate sau in forme sau ambalaje pentru vanzarea cu amanuntul',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Produse imunologice conditionate in doze masurate sau in forme sau ambalaje pentru vanzarea cu amanuntul',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Vaccinuri impotriva coronavirusurilor legate de SARS specia SARSCoV',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Altele',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Vaccinuri pentru medicina veterinara',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Altele',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Produse pentru terapie celulara',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Altele',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Sange uman',
    'Sange uman sange animal preparat pentru utilizari terapeutice profilactice sau de diagnosticare antiseruri alte fractiuni ale sangelui produse imunologice chiar modificate sau obtinute pe cale biotehnologica vaccinuri toxine culturi de microorganisme excluzand drojdiile si produse similare culturi celulare chiar modificate Sange animal preparat in vederea utilizarii terapeutice profilactice sau de diagnostic',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Care contin peniciline sau derivati ai acestor produse cu structura de acid penicilanic sau care contin streptomicine sau derivati ai acestor produse',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Altele care contin antibiotice',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Care contin insulina',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Altele',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Care contin efedrina sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Care contin pseudoefedrina DCI sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Care contin norefedrina sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Altele',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Altele care contin principii active antimalarie descrise in nota de subpozitie  din prezentul capitol',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate intre ele preparate pentru utilizari terapeutice sau profilactice dar neprezentate sub forma de doze nici conditionate pentru vanzarea cu amanuntul Altele',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin peniciline sau derivati ai acestor produse cu structura de acid penicilanic sau care contin streptomicine sau derivati ai acestor produse',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele care contin antibiotice',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin insulina',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin hormoni corticosteroizi derivatii sau analogii lor structurali',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin efedrina sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin pseudoefedrina DCI sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Care contin norefedrina sau saruri ale acesteia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele care contin vitamine sau alte produse de la pozitia',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele care contin principii active antimalarie descrise in nota de subpozitie  din prezentul capitol',
    'Medicamente cu exceptia produselor de la pozitiile  sau  constituite din produse amestecate sau neamestecate preparate in scopuri terapeutice sau profilactice prezentate sub forma de doze inclusiv cele care se administreaza transdermic sau conditionate pentru vanzarea cu amanuntul Altele',
    'Vata tifoane fese si articole similare de exemplu pansamente plasturi adezivi cataplasme impregnate sau acoperite cu substante farmaceutice sau conditionate pentru vanzarea cu amanuntul in scopuri medicale chirurgicale stomatologice sau veterinare Pansamente adezive si alte articole prevazute cu un strat adeziv',
    'Vata tifoane fese si articole similare de exemplu pansamente plasturi adezivi cataplasme impregnate sau acoperite cu substante farmaceutice sau conditionate pentru vanzarea cu amanuntul in scopuri medicale chirurgicale stomatologice sau veterinare Vata si articole din vata',
    'Vata tifoane fese si articole similare de exemplu pansamente plasturi adezivi cataplasme impregnate sau acoperite cu substante farmaceutice sau conditionate pentru vanzarea cu amanuntul in scopuri medicale chirurgicale stomatologice sau veterinare Tifon si articole din tifon',
    'Vata tifoane fese si articole similare de exemplu pansamente plasturi adezivi cataplasme impregnate sau acoperite cu substante farmaceutice sau conditionate pentru vanzarea cu amanuntul in scopuri medicale chirurgicale stomatologice sau veterinare Altele',
    'Vata tifoane fese si articole similare de exemplu pansamente plasturi adezivi cataplasme impregnate sau acoperite cu substante farmaceutice sau conditionate pentru vanzarea cu amanuntul in scopuri medicale chirurgicale stomatologice sau veterinare Altele',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Catguturi sterile',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Bariere antiaderente sterile pentru chirurgie sau stomatologie resorbabile sau neresorbabile',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Altele',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Preparate opacifiante pentru examinari radiografice reactivi de diagnostic conceputi pentru a fi administrati pacientilor',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Cimenturi si alte produse pentru obturatii dentare cimenturi pentru refaceri osoase',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Truse si cutii complete pentru prim ajutor',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Preparate chimice anticonceptionale pe baza de hormoni de alte produse de la pozitia sau de spermicide',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Preparate prezentate sub forma de gel concepute pentru a fi utilizate in medicina umana sau veterinara ca lubrifianti pentru anumite parti ale corpului in timpul operatiilor chirurgicale sau examenelor medicale sau ca agent de legatura intre corp si instrumentele medicale',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Articole identificabile ca fiind pentru stomie',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Deseuri farmaceutice',
    'Preparate si articole farmaceutice mentionate la nota a capitolului Placebo si truse pentru trialuri clinice orb sau dubluorb pentru un trial clinic recunoscut prezentate in doze masurate',
    'Ingrasaminte de origine animala sau vegetala chiar amestecate intre ele sau tratate chimic ingrasaminte obtinute prin amestecarea sau tratarea chimica a produselor de origine animala sau vegetala Ingrasaminte de origine animala sau vegetala chiar amestecate intre ele sau tratate chimic ingrasaminte obtinute prin amestecarea sau tratarea chimica a produselor de origine animala sau vegetala',
    'Ingrasaminte minerale sau chimice azotate Uree cu un continut de azot de peste  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice azotate Altele',
    'Ingrasaminte minerale sau chimice azotate Sulfat de amoniu',
    'Ingrasaminte minerale sau chimice azotate Altele',
    'Ingrasaminte minerale sau chimice azotate In solutie apoasa',
    'Ingrasaminte minerale sau chimice azotate Altele',
    'Ingrasaminte minerale sau chimice azotate Cu un continut de azot de maximum  din greutate',
    'Ingrasaminte minerale sau chimice azotate Cu un continut de azot de peste  din greutate',
    'Ingrasaminte minerale sau chimice azotate Nitrat de sodiu',
    'Ingrasaminte minerale sau chimice azotate Saruri duble si amestecuri de nitrat de calciu si de nitrat de amoniu',
    'Ingrasaminte minerale sau chimice azotate Amestecuri de uree si de nitrat de amoniu in solutii apoase sau amoniacale',
    'Ingrasaminte minerale sau chimice azotate Altele inclusiv amestecurile nedenumite la subpozitiile precedente',
    'Ingrasaminte minerale sau chimice fosfatice Care contin peste   din greutate pentaoxid de difosfor PO',
    'Ingrasaminte minerale sau chimice fosfatice Altele',
    'Ingrasaminte minerale sau chimice fosfatice Altele',
    'Ingrasaminte minerale sau chimice potasice Cu un continut de potasiu calculat in KO de maximum  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice potasice Cu un continut de potasiu calculat in KO de peste  dar de maximum  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice potasice Cu un continut de potasiu calculat in KO de peste  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice potasice Sulfat de potasiu',
    'Ingrasaminte minerale sau chimice potasice Altele',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Produsele acestui capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Cu un continut de azot de peste  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Altele',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Hidrogenoortofosfat de diamoniu fosfat diamoniu',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Dihidrogenoortofosfat de amoniu fosfat monoamoniu chiar in amestec cu hidrogenoortofosfat de diamoniu fosfat diamoniu',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Care contin nitrati si fosfati',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Altele',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Ingrasaminte minerale sau chimice care contin doua elemente fertilizante fosfor si potasiu',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Cu un continut de azot de peste  din greutatea produsului anhidru in stare uscata',
    'Ingrasaminte minerale sau chimice care contin doua sau trei dintre elemente fertilizante azot fosfor si potasiu alte ingrasaminte produse de la acest capitol prezentate fie in tablete sau forme similare fie in ambalaje cu o greutate bruta de maximum kg Altele',
    'Extracte tanante de origine vegetala tanini si sarurile lor eterii esterii si alti derivati ai acestor produse Extract de quebracho',
    'Extracte tanante de origine vegetala tanini si sarurile lor eterii esterii si alti derivati ai acestor produse Exctract de mimoza salcam australian',
    'Extracte tanante de origine vegetala tanini si sarurile lor eterii esterii si alti derivati ai acestor produse Extracte de sumac de vallonia de stejar sau de castan',
    'Extracte tanante de origine vegetala tanini si sarurile lor eterii esterii si alti derivati ai acestor produse Altele',
    'Produse tanante organice sintetice produse tanante anorganice preparate tanante care contin chiar produse tanante naturale preparate enzimatice pentru pretabacire Produse tanante organice sintetice',
    'Produse tanante organice sintetice produse tanante anorganice preparate tanante care contin chiar produse tanante naturale preparate enzimatice pentru pretabacire Altele',
    'Substante colorante de origine vegetala sau animala inclusiv extracte colorante cu exceptia negrului de origine animala chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante de origine vegetala sau animala Substante colorante de origine vegetala si preparate pe baza acestora',
    'Substante colorante de origine vegetala sau animala inclusiv extracte colorante cu exceptia negrului de origine animala chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante de origine vegetala sau animala Substante colorante de origine animala si preparate pe baza acestora',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti dispersati si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti acizi chiar metalizati si preparate pe baza acestor coloranti coloranti cu mordanti si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti bazici si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti directi si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti de cada inclusiv cei utilizabili in aceasta stare ca pigmenti si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Coloranti reactivi si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Colorantipigment si preparate pe baza acestor coloranti',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Substante colorante pe baza de carotenoide si preparate pe baza de aceste substante',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Altele inclusiv amestecurile de doua sau mai multe substante colorante de la subpozitiile',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta',
    'Substante colorante organice sintetice chiar cu compozitie chimica definita preparate menionate la nota de capitol pe baza de substante colorante organice sintetice produse organice sintetice de tipul celor utilizate ca agenti de stralucire fluorescenta sau ca luminofori chiar cu compozitie chimica definita Altele',
    'Lacuri colorante preparate menionate la nota de capitol pe baza de lacuri colorante Lacuri colorante preparate menionate la nota de capitol pe baza de lacuri colorante',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Care contin dioxid de titan minimum  din greutatea produsului uscat',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Altele',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Pigmenti si preparate pe baza de compusi ai cromului',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Ultramarin si preparatele pe baza de ultramarin',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Litopon si alti pigmenti si preparate pe baza de sulfura de zinc',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Magnetita',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Altele',
    'Alte substante colorante preparate mentionate la nota de capitol altele decat cele de la pozitiile  sau  produse anorganice de tipul celor utilizate ca luminofori chiar cu compozitie chimica definita Produse anorganice de tipul celor utilizate ca luminofori',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Pigmenti opacifianti si culori preparate si preparate similare',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Engobe',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Altele',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Produse lichide pentru obtinerea luciului si preparate similare',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Sticla sub forma de fulgi cu lungimea de minimum mm si maximum mm si grosimea de minimum microni si maximum microni sticla sub forma de pulbere sau granule care contine dioxid de siliciu minimum  in greutate',
    'Pigmenti opacifianti si culori preparate compozitii vitrifiabile engobe produse lichide pentru obtinerea luciului si preparate similare de tipul celor utilizate in industria ceramica a sticlei si a emailurilor frite de sticla si alta sticla sub forma de pulbere de granule de lamele sau de fulgi Altele',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Solutii definite la nota a capitolului',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Altele',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Solutii definite la nota de capitol',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Altele',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Poliuretan obtinut din tertbutiliminodietanol si metilendiciclohexil diizocianat sub forma de solutie in NNdimetilacetamida care contine polimer minimum  in greutate',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Copolimer de pcresol si divinilbenzen sub forma de solutie in NNdimetilacetamida care contine polimer minimum  in greutate',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Altele',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Pe baza de polimeri sintetici',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu neapos solutii definite la nota a capitolului Pe baza de polimeri naturali modificati chimic',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu apos Pe baza de polimeri acrilici sau vinilici',
    'Lacuri si vopsele inclusiv emailuri pe baza de polimeri sintetici sau de polimeri naturali modificati dispersati sau dizolvati intrun mediu apos Altele',
    'Alte lacuri si vopsele inclusiv emailuri i vopsele de apa pigmenti de apa preparati de tipul celor utilizati pentru finisarea pieilor Lacuri si vopsele pe baza de ulei',
    'Alte lacuri si vopsele inclusiv emailuri i vopsele de apa pigmenti de apa preparati de tipul celor utilizati pentru finisarea pieilor Altele',
    'Sicativi preparati Sicativi preparati',
    'Pigmenti inclusiv pulberi si fulgi metalici dispersati in medii neapoase sub forma de lichid sau de pasta de tipul celor utilizate pentru fabricarea vopselelor inclusiv a emailurilor folii pentru marcare prin presare la cald tincturi si alte substante colorante prezentate in forme sau ambalaje conditionate pentru vanzarea cu amanuntul Folii pentru marcare prin presare la cald',
    'Pigmenti inclusiv pulberi si fulgi metalici dispersati in medii neapoase sub forma de lichid sau de pasta de tipul celor utilizate pentru fabricarea vopselelor inclusiv a emailurilor folii pentru marcare prin presare la cald tincturi si alte substante colorante prezentate in forme sau ambalaje conditionate pentru vanzarea cu amanuntul Altele',
    'Culori pentru pictura artistica pentru uz didactic pictarea firmelor modificarea nuantelor amuzament si culori similare in tuburi tablete flacoane sticle tavite sau in alte forme similare de ambalaj Seturi de culori',
    'Culori pentru pictura artistica pentru uz didactic pictarea firmelor modificarea nuantelor amuzament si culori similare in tuburi tablete flacoane sticle tavite sau in alte forme similare de ambalaj Altele',
    'Chit pentru geamuri chit pentru imbinari cimenturi de rasina si alte masticuri glet pentru zugravit gleturi nerefractare de tipul celor utilizate in constructii la faade perei interiori podele tavane i similare Chit pentru geamuri chit pentru imbinari cimenturi de rasina si alte masticuri',
    'Chit pentru geamuri chit pentru imbinari cimenturi de rasina si alte masticuri glet pentru zugravit gleturi nerefractare de tipul celor utilizate in constructii la faade perei interiori podele tavane i similare Gleturi pentru zugravit',
    'Chit pentru geamuri chit pentru imbinari cimenturi de rasina si alte masticuri glet pentru zugravit gleturi nerefractare de tipul celor utilizate in constructii la faade perei interiori podele tavane i similare Altele',
    'Cerneluri de imprimat de scris sau de desen si alte cerneluri chiar concentrate sau sub forme solide Negre',
    'Cerneluri de imprimat de scris sau de desen si alte cerneluri chiar concentrate sau sub forme solide Altele',
    'Cerneluri de imprimat de scris sau de desen si alte cerneluri chiar concentrate sau sub forme solide Cartuse de cerneala fara un cap de imprimare integrat destinate introducerii in aparatele de la subpozitiile   sau  si dotate cu componente mecanice sau electrice cerneala solida in forme special concepute pentru introducerea in aparatele de la  subpozitiile   sau',
    'Cerneluri de imprimat de scris sau de desen si alte cerneluri chiar concentrate sau sub forme solide Altele',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Nedeterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Deterpenizate',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale De trandafir',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Altele',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale De muscata de iasomie de vetiver',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale De levantica sau de levandina',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Altele',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Rezinoide',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Subproduse terpenice rezultate din deterpenizarea uleiurilor esentiale',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale De lemn dulce si de hamei',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Altele',
    'Uleiuri esentiale deterpenizate sau nu inclusiv cele asazise concrete sau absolute rezinoide oleorasini de extractie solutii concentrate de uleiuri esentiale in grasimi in uleiuri fixe grase in ceara sau in substante similare obtinute prin extractie din flori sau macerare subproduse terpenice reziduale de la deterpenizarea uleiurilor esentiale ape distilate aromatice si solutii apoase ale uleiurilor esentiale Altele',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Cu o concentratie de alcool de peste vol',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Care nu contin grasimi provenite din lapte zaharoza izoglucoza glucoza amidon sau fecule sau care contin in greutate sub  grasimi din lapte sub  zaharoza sau izoglucoza sub  glucoza sau amidon sau fecule',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Altele',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Altele',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor De tipul celor utilizate in industria alimentara',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Solutii alcoolice',
    'Amestecuri de substante odoriferante si amestecuri inclusiv solutiile alcoolice pe baza uneia sau mai multor substante odoriferante de tipul celor utilizate ca materii prime pentru industrie alte preparate pe baza de substante odoriferante de tipul celor utilizate pentru fabricarea bauturilor Altele',
    'Parfumuri si ape de toaleta Parfumuri',
    'Parfumuri si ape de toaleta Ape de toaleta',
    'Produse de infrumusetare sau de machiaj si preparate pentru intretinerea sau ingrijirea pielii altele decat medicamentele inclusiv preparate de protectie impotriva soarelui si preparate pentru bronzare preparate pentru manichiura sau pedichiura Preparate de machiaj pentru buze',
    'Produse de infrumusetare sau de machiaj si preparate pentru intretinerea sau ingrijirea pielii altele decat medicamentele inclusiv preparate de protectie impotriva soarelui si preparate pentru bronzare preparate pentru manichiura sau pedichiura Preparate de machiaj pentru ochi',
    'Produse de infrumusetare sau de machiaj si preparate pentru intretinerea sau ingrijirea pielii altele decat medicamentele inclusiv preparate de protectie impotriva soarelui si preparate pentru bronzare preparate pentru manichiura sau pedichiura Preparate pentru manichiura sau pedichiura',
    'Produse de infrumusetare sau de machiaj si preparate pentru intretinerea sau ingrijirea pielii altele decat medicamentele inclusiv preparate de protectie impotriva soarelui si preparate pentru bronzare preparate pentru manichiura sau pedichiura Pudra inclusiv compacta',
    'Produse de infrumusetare sau de machiaj si preparate pentru intretinerea sau ingrijirea pielii altele decat medicamentele inclusiv preparate de protectie impotriva soarelui si preparate pentru bronzare preparate pentru manichiura sau pedichiura Altele',
    'Preparate pentru ingrijirea parului Sampoane',
    'Preparate pentru ingrijirea parului Preparate pentru ondularea sau indreptarea permanenta a parului',
    'Preparate pentru ingrijirea parului Lacuri pentru par',
    'Preparate pentru ingrijirea parului Altele',
    'Preparate pentru igiena bucala sau dentara inclusiv pudrele si cremele pentru facilitarea aderentei protezelor dentare fire utilizate pentru curatarea spatiilor interdentare fire dentare in ambalaje individuale pentru vanzarea cu amanuntul Preparate pentru curatarea dintilor',
    'Preparate pentru igiena bucala sau dentara inclusiv pudrele si cremele pentru facilitarea aderentei protezelor dentare fire utilizate pentru curatarea spatiilor interdentare fire dentare in ambalaje individuale pentru vanzarea cu amanuntul Ata utilizata pentru curatarea spatiilor interdentare ata dentara',
    'Preparate pentru igiena bucala sau dentara inclusiv pudrele si cremele pentru facilitarea aderentei protezelor dentare fire utilizate pentru curatarea spatiilor interdentare fire dentare in ambalaje individuale pentru vanzarea cu amanuntul Altele',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Preparate pentru preras ras sau dupa ras',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Deodorante corporale si antisudorifice',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Saruri parfumate si alte preparate pentru baie',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Agarbatti si alte preparate odoriferante actionand prin ardere',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Altele',
    'Preparate pentru preras ras sau dupa ras deodorante corporale preparate pentru baie depilatoare alte preparate de parfumerie sau de toaleta si alte preparate cosmetice nedenumite si necuprinse in alta parte deodorante pentru incaperi preparate chiar parfumate care au sau nu proprietati dezinfectante Altele',
    'Sapunuri produse si preparate organice tensioactive folosite ca sapun in forma de bare in calupuri in bucati chiar modelate care contin sau nu sapun produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun hartie vata fetru si materiale netesute impregnate imbibate sau acoperite cu sapun sau detergent De toaleta inclusiv produsele medicinale',
    'Sapunuri produse si preparate organice tensioactive folosite ca sapun in forma de bare in calupuri in bucati chiar modelate care contin sau nu sapun produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun hartie vata fetru si materiale netesute impregnate imbibate sau acoperite cu sapun sau detergent Altele',
    'Sapunuri produse si preparate organice tensioactive folosite ca sapun in forma de bare in calupuri in bucati chiar modelate care contin sau nu sapun produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun hartie vata fetru si materiale netesute impregnate imbibate sau acoperite cu sapun sau detergent Fulgi foie granule sau pudra',
    'Sapunuri produse si preparate organice tensioactive folosite ca sapun in forma de bare in calupuri in bucati chiar modelate care contin sau nu sapun produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun hartie vata fetru si materiale netesute impregnate imbibate sau acoperite cu sapun sau detergent Altele',
    'Sapunuri produse si preparate organice tensioactive folosite ca sapun in forma de bare in calupuri in bucati chiar modelate care contin sau nu sapun produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun hartie vata fetru si materiale netesute impregnate imbibate sau acoperite cu sapun sau detergent Produse si preparate organice tensioactive pentru spalarea pielii sub forma lichida sau crema conditionate pentru vanzarea cu amanuntul care contin sau nu sapun',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Acizi sulfonici de alchilbenzeni lineari si sarurile lor',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Solutii apoase care contin alchil oxidibenzensulfonat de disodiu minimum  in greutate dar maximum',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Altele',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Cationici',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Neionici',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Altele',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Preparate tensioactive',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Preparate pentru spalat si preparate pentru curatat',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Preparate tensioactive',
    'Agenti organici de suprafata altii decat sapunurile preparate tensioactive preparate pentru spalat inclusiv preparatele auxiliare pentru spalat si preparate de curatat care contin sau nu sapun altele decat cele de la pozitia Preparate pentru spalat si preparate pentru curatat',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Preparate pentru tratarea materialelor textile a pieilor a blanurilor sau a altor materiale',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Care contin minimum  in greutate uleiuri din petrol sau din minerale bituminoase neconsiderate constituenti de baza',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Lubrifianti cu un continut de carbon din biomasa de cel putin  in masa si biodegradabili la un nivel de minimum',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Altele',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Preparate pentru tratarea materialelor textile a pieilor a blanurilor sau a altor materiale',
    'Preparate lubrifiante inclusiv lichide de racire preparate pentru degriparea suruburilor preparate antirugina sau anticorozive si preparate pentru demulare pe baza de lubrifianti si preparate de tipul celor utilizate pentru tratarea cu ulei si gresarea materialelor textile pieilor blanurilor sau altor materiale cu exceptia preparatelor care contin cu rol de constituenti de baza minimum  in greutate uleiuri din petrol sau din minerale bituminoase Altele',
    'Ceara artificiala si ceara preparata Din polioxietilena polietilenglicol',
    'Ceara artificiala si ceara preparata Altele',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Ceara creme si preparate similare pentru incaltaminte sau piele',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Ceara de parchet creme si preparate similare pentru intretinerea mobilei din lemn a parchetului sau a altor obiecte din lemn',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Materiale de lustruit si preparate similare pentru caroserii altele decat cele pentru lustruirea metalelor',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Paste prafuri si alte preparate pentru curatat',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Materiale pentru lustruirea metalelor',
    'Ceara si creme pentru incaltaminte ceara de parchet materiale de lustruit pentru caroserii sticla sau metal pasta si praf pentru curatat si preparate similare chiar sub forma de hartie vata fetru materiale netesute materiale plastice sau cauciuc alveolar impregnate imbibate sau acoperite cu astfel de preparate fara a include ceara de la pozitia Altele',
    'Lumanari si articole similare Lumanari si articole similare',
    'Paste pentru modelat inclusiv cele prezentate pentru joaca compozitii numite ceara pentru stomatologie sau materiale pentru impresiuni dentare prezentate in seturi in ambalaje pentru vanzarea cu amanuntul sau in plachete potcoave batoane sau forme similare alte compozitii pentru stomatologie pe baza de ipsos sau ghips ghips calcinat sau sulfat de calciu Paste pentru modelat inclusiv cele prezentate pentru joaca compozitii numite ceara pentru stomatologie sau materiale pentru impresiuni dentare prezentate in seturi in ambalaje pentru vanzarea cu amanuntul sau in plachete potcoave batoane sau forme similare alte compozitii pentru stomatologie pe baza de ipsos sau ghips ghips calcinat sau sulfat de calciu',
    'Cazeina cazeinati si alti derivati ai cazeinei cleiuri de cazeina Destinata fabricarii fibrelor textile artificiale',
    'Cazeina cazeinati si alti derivati ai cazeinei cleiuri de cazeina Destinata utilizarilor industriale altele decat fabricarea produselor alimentare sau furajere',
    'Cazeina cazeinati si alti derivati ai cazeinei cleiuri de cazeina Altele',
    'Cazeina cazeinati si alti derivati ai cazeinei cleiuri de cazeina Cleiuri de cazeina',
    'Cazeina cazeinati si alti derivati ai cazeinei cleiuri de cazeina Altele',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Improprie sau devenita improprie alimentatiei umane',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Altele',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Improprie sau devenita improprie alimentatiei umane',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Altele',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Improprie sau devenita improprie alimentatiei umane',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Uscate in foi fulgi granule pudra etc',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Altele',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Improprii sau devenite improprii alimentatiei umane',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Altele',
    'Albumine inclusiv concentratele care contin doua sau mai multe proteine din zer care contin proteine din zer peste  din greutatea substantei uscate albuminati si alti derivati de albumine Albuminati si alti derivati de albumine',
    'Gelatine inclusiv cele prezentate in foi de forma patrata sau dreptunghiulara chiar prelucrate la suprafata sau colorate si derivatii lor clei de peste ihtiocol alte cleiuri de origine animala cu exceptia cleiurilor de cazeina de la pozitia Gelatine si derivatii lor',
    'Gelatine inclusiv cele prezentate in foi de forma patrata sau dreptunghiulara chiar prelucrate la suprafata sau colorate si derivatii lor clei de peste ihtiocol alte cleiuri de origine animala cu exceptia cleiurilor de cazeina de la pozitia Altele',
    'Peptone si derivatii lor alte substante proteice si derivatii lor nedenumite si necuprinse in alta parte pulbere de piele tratata sau nu cu crom Concentrate de proteine din lapte menionate la nota complementara a capitolului',
    'Peptone si derivatii lor alte substante proteice si derivatii lor nedenumite si necuprinse in alta parte pulbere de piele tratata sau nu cu crom Altele',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Dextrine',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Amidonuri si fecule esterificate sau eterificate',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Altele',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Cu un continut de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate sub  din greutate',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Cu un continut de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate de minimum  din greutate dar sub',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Cu un continut de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate de minimum  din greutate dar sub',
    'Dextrine si alte amidonuri si fecule modificate de exemplu amidonuri si fecule pregelatinizate sau esterificate cleiuri pe baza de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate Cu un continut de amidon sau de fecule de dextrine sau de alte amidonuri sau fecule modificate de minimum  din greutate',
    'Cleiuri si alti adezivi preparati nedenumiti si necuprinsi in alta parte produse de orice fel folosite ca adezivi sau cleiuri conditionate pentru vanzarea cu amanuntul ca adezivi sau cleiuri cu o greutate neta de maximum kg Produse de orice fel folosite ca adezivi sau cleiuri conditionate pentru vanzarea cu amanuntul ca adezivi sau cleiuri cu o greutate neta de maximum kg',
    'Cleiuri si alti adezivi preparati nedenumiti si necuprinsi in alta parte produse de orice fel folosite ca adezivi sau cleiuri conditionate pentru vanzarea cu amanuntul ca adezivi sau cleiuri cu o greutate neta de maximum kg Adezivi sub forma de film transparenti optic si adezivi lichizi transparenti optic care se intaresc de tipul celor utilizati exclusiv sau in principal pentru fabricarea dispozitivelor de afisare cu ecran plat sau a ecranelor tactile',
    'Cleiuri si alti adezivi preparati nedenumiti si necuprinsi in alta parte produse de orice fel folosite ca adezivi sau cleiuri conditionate pentru vanzarea cu amanuntul ca adezivi sau cleiuri cu o greutate neta de maximum kg Altele',
    'Cleiuri si alti adezivi preparati nedenumiti si necuprinsi in alta parte produse de orice fel folosite ca adezivi sau cleiuri conditionate pentru vanzarea cu amanuntul ca adezivi sau cleiuri cu o greutate neta de maximum kg Altele',
    'Enzime enzime preparate nedenumite si necuprinse in alta parte Cheag si concentratele sale',
    'Enzime enzime preparate nedenumite si necuprinse in alta parte Lipoprotein lipaza Aspergillus alkalin proteaza',
    'Enzime enzime preparate nedenumite si necuprinse in alta parte Altele',
    'Pulberi propulsive Pulberi propulsive',
    'Explozivi preparati altii decat pulberile propulsive Explozivi preparati altii decat pulberile propulsive',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Fitiluri de siguranta',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Fitiluri detonante',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Amorse explozive',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Capse explozive',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Aprinzatoare',
    'Fitiluri de siguranta fitiluri detonante amorse si capse explozive aprinzatoare detonatoare electrice Detonatoare electrice',
    'Articole pentru focuri de artificii rachete de semnalizare sau contra grindinei si similare petarde si alte articole pirotehnice Articole pentru focuri de artificii',
    'Articole pentru focuri de artificii rachete de semnalizare sau contra grindinei si similare petarde si alte articole pirotehnice Altele',
    'Chibrituri altele decat articolele pirotehnice de la pozitia Chibrituri altele decat articolele pirotehnice de la pozitia',
    'Feroceriu si alte aliaje piroforice sub toate formele articole din materiale inflamabile specificate la nota a capitolului Combustibili lichizi si gaze combustibile lichefiate in recipiente de tipul celor utilizate pentru alimentarea sau reincarcarea brichetelor sau aprinzatoarelor care au o capacitate de maximum cm',
    'Feroceriu si alte aliaje piroforice sub toate formele articole din materiale inflamabile specificate la nota a capitolului Feroceriu si alte aliaje piroforice sub toate formele',
    'Feroceriu si alte aliaje piroforice sub toate formele articole din materiale inflamabile specificate la nota a capitolului Altele',
    'Placi si filme fotografice plane sensibilizate neimpresionate din alte materiale decat hartia cartonul sau textilele filme fotografice plane cu developare si revelare instantanee sensibilizate neimpresionate chiar in incarcatoare Pentru razeX',
    'Placi si filme fotografice plane sensibilizate neimpresionate din alte materiale decat hartia cartonul sau textilele filme fotografice plane cu developare si revelare instantanee sensibilizate neimpresionate chiar in incarcatoare Filme cu developare si revelare instantanee',
    'Placi si filme fotografice plane sensibilizate neimpresionate din alte materiale decat hartia cartonul sau textilele filme fotografice plane cu developare si revelare instantanee sensibilizate neimpresionate chiar in incarcatoare Alte filme si placi a caror dimensiune este peste mm pe cel putin una dintre laturi',
    'Placi si filme fotografice plane sensibilizate neimpresionate din alte materiale decat hartia cartonul sau textilele filme fotografice plane cu developare si revelare instantanee sensibilizate neimpresionate chiar in incarcatoare Pentru fotografii in culori policrome',
    'Placi si filme fotografice plane sensibilizate neimpresionate din alte materiale decat hartia cartonul sau textilele filme fotografice plane cu developare si revelare instantanee sensibilizate neimpresionate chiar in incarcatoare Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Pentru razeX',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Filme color negative  cu o latime de minimum mm dar de maximum mm si  cu o lungime de minimum mdestinate fabricarii incarcatoarelor pentru aparatele fotografice cu developare si revelare instantanee',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Microfilme filme pentru artele grafice',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm si o lungime de peste m pentru fotografii in culori policrome',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm si o lungime de peste m altele decat cele pentru fotografii in culori',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm si o lungime de maximum m',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm dar de maximum mm',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de maximum mm',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm dar de maximum mm si o lungime de maximum m pentru diapozitive',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm dar de maximum mm si o lungime de maximum m altele decat cele pentru diapozitive',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm dar de maximum mm si o lungime de peste m',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Microfilme filme pentru artele grafice',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Microfilme filme pentru artele grafice',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Altele',
    'Pelicule fotografice sensibilizate neimpresionate in role din alte materiale decat hartia cartonul sau textilele pelicule fotografice cu developare si revelare instantanee in role sensibilizate neimpresionate Cu o latime de peste mm',
    'Hartii cartoane si textile fotografice sensibilizate neimpresionate In role cu o latime de peste mm',
    'Hartii cartoane si textile fotografice sensibilizate neimpresionate Altele pentru fotografii in culori policrome',
    'Hartii cartoane si textile fotografice sensibilizate neimpresionate Altele',
    'Placi pelicule filme hartii cartoane si textile fotografice impresionate dar nedevelopate Placi pelicule si filme',
    'Placi pelicule filme hartii cartoane si textile fotografice impresionate dar nedevelopate Altele',
    'Placi si pelicule fotografice impresionate si developate altele decat filmele cinematografice Pentru reproducere offset',
    'Placi si pelicule fotografice impresionate si developate altele decat filmele cinematografice Altele',
    'Filme cinematografice impresionate si developate care contin sau nu inregistrarea sunetului sau care contin numai inregistrarea sunetului Care nu contin decat inregistrarea sunetului negative pozitive intermediare de lucru',
    'Filme cinematografice impresionate si developate care contin sau nu inregistrarea sunetului sau care contin numai inregistrarea sunetului Alte pozitive',
    'Filme cinematografice impresionate si developate care contin sau nu inregistrarea sunetului sau care contin numai inregistrarea sunetului Care nu contin decat inregistrarea sunetului negative pozitive intermediare de lucru filme de actualitati',
    'Filme cinematografice impresionate si developate care contin sau nu inregistrarea sunetului sau care contin numai inregistrarea sunetului Sub mm',
    'Filme cinematografice impresionate si developate care contin sau nu inregistrarea sunetului sau care contin numai inregistrarea sunetului De minimum mm',
    'Preparate chimice pentru utilizari fotografice altele decat lacurile cleiurile adezivii si preparatele similare produse neamestecate pentru utilizari fotografice dozate sau conditionate pentru vanzarea cu amanuntul intro forma gata de folosire Emulsii pentru sensibilizarea suprafetelor',
    'Preparate chimice pentru utilizari fotografice altele decat lacurile cleiurile adezivii si preparatele similare produse neamestecate pentru utilizari fotografice dozate sau conditionate pentru vanzarea cu amanuntul intro forma gata de folosire Revelatori si fixatori',
    'Preparate chimice pentru utilizari fotografice altele decat lacurile cleiurile adezivii si preparatele similare produse neamestecate pentru utilizari fotografice dozate sau conditionate pentru vanzarea cu amanuntul intro forma gata de folosire Altele',
    'Grafit artificial grafit coloidal sau semicoloidal preparate pe baza de grafit sau de alt carbune sub forma de paste blocuri plachete sau alte semifabricate Grafit artificial',
    'Grafit artificial grafit coloidal sau semicoloidal preparate pe baza de grafit sau de alt carbune sub forma de paste blocuri plachete sau alte semifabricate Grafit coloidal sub forma de suspensie in ulei grafit semicoloidal',
    'Grafit artificial grafit coloidal sau semicoloidal preparate pe baza de grafit sau de alt carbune sub forma de paste blocuri plachete sau alte semifabricate Altele',
    'Grafit artificial grafit coloidal sau semicoloidal preparate pe baza de grafit sau de alt carbune sub forma de paste blocuri plachete sau alte semifabricate Paste carbonate pentru electrozi si paste similare pentru captusirea cuptoarelor',
    'Grafit artificial grafit coloidal sau semicoloidal preparate pe baza de grafit sau de alt carbune sub forma de paste blocuri plachete sau alte semifabricate Altele',
    'Carbune activ materiale minerale naturale activate negru de origine animala inclusiv negru animal rezidual Carbune activ',
    'Carbune activ materiale minerale naturale activate negru de origine animala inclusiv negru animal rezidual Altele',
    'Ulei de tal chiar rafinat Brut',
    'Ulei de tal chiar rafinat Altele',
    'Lesii reziduale rezultate de la fabricarea pastei de celuloza chiar concentrate sau din care sa extras zaharul sau tratate chimic inclusiv lignosulfonati dar fara a include uleiul de tal de la pozitia Lesii reziduale rezultate de la fabricarea pastei de celuloza chiar concentrate sau din care sa extras zaharul sau tratate chimic inclusiv lignosulfonati dar fara a include uleiul de tal de la pozitia',
    'Esente de terebentina de lemn de pin sau de celuloza sulfatata rezultata din industria hartiei si alte esente terpenice provenind de la distilare sau de la alte tratamente ale lemnului de conifere dipenten brut esenta de la obtinerea celulozei cu bisulfit si alte paracimene brute ulei de pin care contine alfaterpinol ca principal constituent Esenta de terebentina',
    'Esente de terebentina de lemn de pin sau de celuloza sulfatata rezultata din industria hartiei si alte esente terpenice provenind de la distilare sau de la alte tratamente ale lemnului de conifere dipenten brut esenta de la obtinerea celulozei cu bisulfit si alte paracimene brute ulei de pin care contine alfaterpinol ca principal constituent Esenta de lemn de pin',
    'Esente de terebentina de lemn de pin sau de celuloza sulfatata rezultata din industria hartiei si alte esente terpenice provenind de la distilare sau de la alte tratamente ale lemnului de conifere dipenten brut esenta de la obtinerea celulozei cu bisulfit si alte paracimene brute ulei de pin care contine alfaterpinol ca principal constituent Esenta de celuloza sulfatata rezultata din industria hartiei',
    'Esente de terebentina de lemn de pin sau de celuloza sulfatata rezultata din industria hartiei si alte esente terpenice provenind de la distilare sau de la alte tratamente ale lemnului de conifere dipenten brut esenta de la obtinerea celulozei cu bisulfit si alte paracimene brute ulei de pin care contine alfaterpinol ca principal constituent Ulei de pin',
    'Esente de terebentina de lemn de pin sau de celuloza sulfatata rezultata din industria hartiei si alte esente terpenice provenind de la distilare sau de la alte tratamente ale lemnului de conifere dipenten brut esenta de la obtinerea celulozei cu bisulfit si alte paracimene brute ulei de pin care contine alfaterpinol ca principal constituent Altele',
    'Colofoniu si acizi rezinici derivatii lor esenta de colofoniu si uleiuri de colofoniu gume topite Colofoniu si acizi rezinici',
    'Colofoniu si acizi rezinici derivatii lor esenta de colofoniu si uleiuri de colofoniu gume topite Saruri de colofoniu de acizi rezinici sau de derivati de colofoniu sau de acizi rezinici altii decat sarurile de aducti de colofoniu',
    'Colofoniu si acizi rezinici derivatii lor esenta de colofoniu si uleiuri de colofoniu gume topite Gume esteri',
    'Colofoniu si acizi rezinici derivatii lor esenta de colofoniu si uleiuri de colofoniu gume topite Altele',
    'Gudron de lemn uleiuri din gudron de lemn creozot de lemn metanol brut spirt de lemn smoala vegetala smoala pentru butoaie de bere si preparate similare pe baza de colofoniu de acizi rezinici sau de smoala vegetala Gudron de lemn',
    'Gudron de lemn uleiuri din gudron de lemn creozot de lemn metanol brut spirt de lemn smoala vegetala smoala pentru butoaie de bere si preparate similare pe baza de colofoniu de acizi rezinici sau de smoala vegetala Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor DDT ISO clofenotan DCI in ambalaje cu o greutate neta de maximum g',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor In ambalaje cu o greutate neta de maximum g',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor In ambalaje cu o greutate neta de peste  g dar de maximum kg',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de piretroide',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de hidrocarburi clorurate',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de carbamati',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de compusi organofosforici',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Preparate pe baza de compusi de cupru',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de ditiocarbamati',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de benzimidazoli',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de diazoli sau triazoli',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de diazine sau morfoline',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de fenoxifitohormoni',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de triazine',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de amide',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de carbamati',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de derivati de dinitroanilina',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de derivati de uree de uracil sau de sulfoniluree',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Inhibitori de germinare',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Regulatori de crestere pentru plante',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de saruri cuaternare de amoniu',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Pe baza de compusi halogenati',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Rodenticide',
    'Insecticide rodenticide fungicide erbicide inhibitori de germinare si regulatori de crestere pentru plante dezinfectanti si produse similare prezentate in forme sau ambalaje pentru vanzarea cu amanuntul sau ca preparate sau ca articole de exemplu panglici mese si lumanari cu sulf si hartie speciala contra mustelor Altele',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte Cu un continut de astfel de substante sub  in greutate',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte Cu un continut de astfel de substante de minimum  dar sub  in greutate',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte Cu un continut de astfel de substante de minimum  dar sub  in greutate',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte Cu un continut de astfel de substante de minimum  in greutate',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte De felul celor folosite in industria textila sau in industrii similare',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte De felul celor folosite in industria hartiei sau in industrii similare',
    'Agenti de apretare sau finisare acceleratori de vopsire sau de fixare a substantelor colorante si alte produse si preparate de exemplu produse pentru scrobit si preparate pentru mordansare de felul celor folosite in industria textila industria hartiei industria pielariei sau in alte industrii similare nedenumite si necuprinse in alta parte De felul celor folosite in industria pielariei sau in industrii similare',
    'Preparate pentru decaparea suprafeei metalelor flux de sudura sau de lipire si alte preparate auxiliare pentru sudarea sau lipirea metalelor paste si pulberi din metale si din alte materiale pentru sudura sau lipire preparate de tipul celor utilizate pentru acoperirea sau umplerea electrozilor sau a baghetelor de sudura Preparate pentru decaparea suprafeei metalelor paste si pulberi din metale si alte materiale pentru sudura sau lipire',
    'Preparate pentru decaparea suprafeei metalelor flux de sudura sau de lipire si alte preparate auxiliare pentru sudarea sau lipirea metalelor paste si pulberi din metale si din alte materiale pentru sudura sau lipire preparate de tipul celor utilizate pentru acoperirea sau umplerea electrozilor sau a baghetelor de sudura Preparate de tipul celor utilizate pentru acoperirea sau umplerea electrozilor sau a baghetelor de sudura',
    'Preparate pentru decaparea suprafeei metalelor flux de sudura sau de lipire si alte preparate auxiliare pentru sudarea sau lipirea metalelor paste si pulberi din metale si din alte materiale pentru sudura sau lipire preparate de tipul celor utilizate pentru acoperirea sau umplerea electrozilor sau a baghetelor de sudura Altele',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Pe baza de plumb tetraetil',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Altele',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Altele',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Care contin uleiuri din petrol sau din minerale bituminoase',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Altele',
    'Preparate antidetonante inhibitori de oxidare aditivi peptizanti amelioratori de viscozitate aditivi anticorozivi si alti aditivi preparati pentru uleiurile minerale inclusiv pentru benzina sau pentru alte lichide utilizate in aceleasi scopuri ca si uleiurile minerale Altele',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Preparate numite acceleratori de vulcanizare',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Amestecuri de reactie care contin benzil izobutiriloxiizopropildimetilpropil ftalat si benzil izobutiriloxitrimetilpentil ftalat',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Altele',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Amestecuri de oligomeri de trimetildihidrochinolina TMQ',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Preparate antioxidante',
    'Preparate numite acceleratori de vulcanizare plastifianti compusi pentru cauciuc sau materiale plastice nedenumite si necuprinse in alta parte preparate antioxidante si alti stabilizatori compusi pentru cauciuc sau materiale plastice Altele',
    'Preparate si incarcaturi pentru aparate extinctoare grenade si bombe extinctoare Preparate si incarcaturi pentru aparate extinctoare grenade si bombe extinctoare',
    'Solventi si diluanti organici compozii nedenumiti si necuprinsi in alta parte preparate concepute pentru indepartarea lacurilor si vopselelor Pe baza de acetat de butil',
    'Solventi si diluanti organici compozii nedenumiti si necuprinsi in alta parte preparate concepute pentru indepartarea lacurilor si vopselelor Altele',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Cu nichel sau compusi de nichel ca substanta activa',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Cu metale pretioase sau compusi ai metalelor pretioase ca substanta activa',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Catalizatori sub forma de granule din care minimum  din greutate au marimea particulelor de maximum microni constand intrun amestec de oxizi pe un suport de silicat de magneziu i care contin in greutate  minimum  dar maximum  cupru i  minimum  dar maximum  bismutsi cu densitate specifica aparenta de minimum  dar maximum',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Altele',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Catalizator compus din acetat de etiltrifenilfosfoniu sub forma de solutie in metanol',
    'Initiatori de reactie acceleratori de reactie si preparate catalitice nedenumite si necuprinse in alta parte Altele',
    'Cimenturi refractare mortare refractare betoane refractare si compozitii similare inclusiv dolomita aglomerata altele decat produsele de la pozitia  Dolomita aglomerata',
    'Cimenturi refractare mortare refractare betoane refractare si compozitii similare inclusiv dolomita aglomerata altele decat produsele de la pozitia  Altele',
    'Alchilbenzeni in amestec si alchilnaftaline in amestec altele decat cele de la pozitia sau  Alchilbenzeni lineari',
    'Alchilbenzeni in amestec si alchilnaftaline in amestec altele decat cele de la pozitia sau  Altele',
    'Elemente chimice dopate in vederea utilizarii lor in electronica sub forma de discuri plachete sau forme similare compusi chimici dopati in vederea utilizarii lor in electronica Siliciu dopat',
    'Elemente chimice dopate in vederea utilizarii lor in electronica sub forma de discuri plachete sau forme similare compusi chimici dopati in vederea utilizarii lor in electronica Altele',
    'Lichide pentru frane hidraulice si alte lichide preparate pentru transmisii hidraulice care nu contin sau care contin sub  din greutate uleiuri de petrol sau de minerale bituminoase Lichide pentru frane hidraulice si alte lichide preparate pentru transmisii hidraulice care nu contin sau care contin sub  din greutate uleiuri de petrol sau de minerale bituminoase',
    'Preparate antigel si lichide preparate pentru degivrare Preparate antigel si lichide preparate pentru degivrare',
    'Medii de cultura preparate pentru dezvoltarea si pastrarea microorganismelor inclusiv virusi si microorganisme similare sau celulelor umane de plante sau de animale Medii de cultura preparate pentru dezvoltarea si pastrarea microorganismelor inclusiv virusi si microorganisme similare sau celulelor umane de plante sau de animale',
    'Reactivi de diagnostic sau de laborator pe orice fel de suport reactivi de diagnostic sau de laborator preparati chiar pe un suport chiar prezentati sub forma de kituri altii decat cei de la pozitia  materiale de referinta certificate Pentru malarie',
    'Reactivi de diagnostic sau de laborator pe orice fel de suport reactivi de diagnostic sau de laborator preparati chiar pe un suport chiar prezentati sub forma de kituri altii decat cei de la pozitia  materiale de referinta certificate Pentru Zika si pentru alte boli transmise de tantarii din genul Aedes',
    'Reactivi de diagnostic sau de laborator pe orice fel de suport reactivi de diagnostic sau de laborator preparati chiar pe un suport chiar prezentati sub forma de kituri altii decat cei de la pozitia  materiale de referinta certificate Pentru determinarea grupelor de sange',
    'Reactivi de diagnostic sau de laborator pe orice fel de suport reactivi de diagnostic sau de laborator preparati chiar pe un suport chiar prezentati sub forma de kituri altii decat cei de la pozitia  materiale de referinta certificate Altele',
    'Reactivi de diagnostic sau de laborator pe orice fel de suport reactivi de diagnostic sau de laborator preparati chiar pe un suport chiar prezentati sub forma de kituri altii decat cei de la pozitia  materiale de referinta certificate Altele',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Acid stearic',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Acid oleic',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Acizi grasi de tal',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Acizi grasi distilati',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Distilat de acid gras',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Altele',
    'Acizi grasi monocarboxilici industriali uleiuri acide de rafinare alcooli grasi industriali Alcooli grasi industriali',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Lianti preparati pentru tipare si miezuri de turnatorie',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Carburi metalice neaglomerate amestecate intre ele sau cu lianti metalici',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Aditivi preparati pentru cimenturi mortare sau betoane',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Beton gata de turnare',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contine Dmanitol maximum  in greutate calculat in raport cu continutul de Dglucitol',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contine Dmanitol maximum  in greutate calculat in raport cu continutul de Dglucitol',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin oxiran oxid de etilena',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin bifenili policlorurati PCB terfenili policlorurati PCT sau bifenili polibromurati PBB',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin fosfat de tridibrompropil',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin aldrin ISO camfeclor ISO toxafen clordan ISO clordecona ISO DDT ISO clofenotan DCI triclorbipclorfeniletan dieldrin ISO DCI endosulfan ISO endrin ISO heptaclor ISO sau mirex ISO',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin hexaclorciclohexan HCH ISO inclusiv lindan ISO DCI',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin pentaclorbenzen ISO sau hexaclorbenzen ISO',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin acid perfluoroctansulfonic sarurile sale sulfonamide de perfluoroctan sau fluorura de sulfonil perfluorooctanica',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin  tetra penta hexa hepta sau octabromdifenil eter',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Care contin parafine clorurate cu catena scurta',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Amestecuri si preparate constand in principal in etilmetiloxidodioxafosfinanilmetil metil metilfosfonat si in bietilmetiloxidodioxafosfinanilmetil metilfosfonat',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Esteri poliglicerici ai acidului metilfosfonic',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Sulfonati de petrol cu exceptia sulfonatilor de petrol ai metalelor alcaline ai amoniului sau ai etanolaminelor acizi sulfonici tiofenati din uleiuri de minerale bituminoase si sarurile lor',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Schimbatori de ioni',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Compozitii absorbante pentru desavarsirea vidului din tuburile sau valvele electrice',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Piroligniti de exemplu de calciu tartrat de calciu brut citrat de calciu brut',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Acizi naftenici sarurile lor insolubile in apa si esterii lor',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Preparate pentru dezincrustare si similare',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Preparate pentru galvanoplastie',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Amestecuri de mono di si tri esteri ai glicerinei cu acizi grasi emulsionanti pentru grasimi',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Produse intermediare din procesul de fabricare a antibioticelor obtinute prin fermentarea Streptomyces tenebrarius uscate sau nu utilizate la fabricarea medicamentelor de uz uman de la pozitia',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Produse intermediare ale procesului de fabricare a sarurilor monensin',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Produse auxiliare de felul celor utilizate in turnatorie altele decat cele mentionate la subpozitia',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Preparate ignifuge hidrofuge si altele utilizate pentru protectia constructiilor',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Discuri de niobat de litiu nedopate',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Amestecuri de amine derivate de la dimerizarea acizilor grasi cu greutatea moleculara medie de minimum  dar maximum',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Etilmetilpropil isoxazolilamina sub forma de solutie in toluen',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Amestecuri continand in principal dimetil metilfosfonat oxiran si pentaoxid de difosfor',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte In forma lichida la C',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Lianti preparati pentru tipare sau miezuri de turnatorie produse chimice si preparate ale industriei chimice sau ale industriilor conexe inclusiv cele constand in amestecuri de produse naturale nedenumite si necuprinse in alta parte Altele',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Deseuri orasenesti',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Namol de epurare',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Deseuri clinice',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Halogenati',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Altele',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Deseuri de solutii lesii decapante pentru metale de lichide hidraulice de lichide de frana si de lichide antigel',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Care contin in principal constituenti organici',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Altele',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Oxizi de fier alcalinizati pentru purificarea gazelor',
    'Produse reziduale ale industriei chimice sau ale industriilor conexe nedenumite si necuprinse in alta parte deseuri orasenesti namol de epurare alte deseuri mentionate la nota a capitolului Altele',
    'Biodiesel si amestecuri de biodiesel care nu contin sau care contin sub  in greutate uleiuri petroliere sau uleiuri obtinute din minerale bituminoase Esteri monoalchilici de acid gras cu un continut de esteri de minimum   din greutate',
    'Biodiesel si amestecuri de biodiesel care nu contin sau care contin sub  in greutate uleiuri petroliere sau uleiuri obtinute din minerale bituminoase Altele',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin clorofluorocarburi CFCuri care conin sau nu hidroclorofluorocarburi HCFCuri perfluorocarburi PFCuri sau hidrofluorocarburi HFCuri',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin hidrobromofluorocarburi HBFCuri',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin tetraclorura de carbon',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin tricloretan metilcloroform',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin bromoclorodifluormetan Halon bromotrifluorometan Halon sau dibromotetrafluoroetani Halon',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Containing substances of subheadings',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele care contin substante de la supozitiile',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin bromura de metil bromometan sau bromoclorometan',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin trifluorometan HFC',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Care contin trifluoretan HFCa in proportie de   sau mai mult in greutate',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele neincluse la subpozitia de mai sus care contin pentafluoretan HFC in proportie de   sau mai mult in greutate dar care nu contin derivati fluorurati nesaturati ai hidrocarburilor aciclice HFOuri',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele neincluse la subpozitiile de mai sus care contin pentafluoretan HFC in proportie de   sau mai mult in greutate',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele neincluse la subpozitiile de mai sus care contin tetrafluoretan HFCa in proportie de   sau mai mult in greutate dar care nu contin derivati fluorurati nesaturati ai hidrocarburilor aciclice HFOuri',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele neincluse la subpozitiile de mai sus care contin difluormetan HFC in proportie de   sau mai mult in greutate si pentafluoretan HFC in proportie de   sau mai mult in greutate',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele neincluse la subpozitiile de mai sus care contin substante de la subpozitiile',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele',
    'Amestecuri continand derivati halogenati ai metanului etanului sau propanului nedenumite si necuprinse in alta parte Altele',
    'Polimeri de etilena in forme primare Polietilena liniara',
    'Polimeri de etilena in forme primare Altele',
    'Polimeri de etilena in forme primare Polietilena in una din formele mentionate la nota literab a capitolului cu densitate de minimum  la C care contine  maximum mgkg aluminiu  maximum mgkg calciu  maximum mgkg crom  maximum mgkg fier  maximum mgkg nichel  maximum mgkg titan  maximum mgkg vanadiupentru fabricarea polietilenei clorosulfonate',
    'Polimeri de etilena in forme primare Altele',
    'Polimeri de etilena in forme primare Copolimeri de etilena si acetat de vinil',
    'Polimeri de etilena in forme primare Copolimeri etilenalfaolefinici care au o densitate relativa de sub',
    'Polimeri de etilena in forme primare Rasina ionomer constand intro sare a unui terpolimer de etilena cu acrilat de izobutil si acid metacrilic copolimer de polistiren de tip bloc ABA copolimer de etilenabutilena si polistiren care contine in greutate maximum  stiren in una din formele mentionate la nota literab a capitolului',
    'Polimeri de etilena in forme primare Altele',
    'Polimeri de propilena sau de alte olefine sub forme primare Polipropilena',
    'Polimeri de propilena sau de alte olefine sub forme primare Poliizobutilena',
    'Polimeri de propilena sau de alte olefine sub forme primare Copolimeri de propilena',
    'Polimeri de propilena sau de alte olefine sub forme primare Copolimer de polistiren de tip bloc ABA copolimer de etilenabutilena si polistiren care contine in greutate maximum  stiren in una din formele mentionate la nota literab a capitolului',
    'Polimeri de propilena sau de alte olefine sub forme primare Polibutena un copolimer al butenei cu etilena care contine in greutate maximum  etilena sau un amestec de polibutena cu polietilena sisau polipropilena care contine in greutate maximum  polietilena sisau maximum  polipropilena in una din formele mentionate la nota literab a capitolului',
    'Polimeri de propilena sau de alte olefine sub forme primare Altele',
    'Polimeri de stiren sub forme primare Expandabil',
    'Polimeri de stiren sub forme primare Altele',
    'Polimeri de stiren sub forme primare Copolimeri de stirenacrilonitril SAN',
    'Polimeri de stiren sub forme primare Copolimeri de acrilonitrilbutadienstiren ABS',
    'Polimeri de stiren sub forme primare Copolimer de stiren numai cu alcool alilic cu valoare acetil de minimum',
    'Polimeri de stiren sub forme primare Polistiren bromurat care contine in greutate minimum  dar maximum  brom in una dintre formele mentionate la nota literab a capitolului',
    'Polimeri de stiren sub forme primare Altele',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Policlorura de vinil neamestecata cu alte substante',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Neplastifiata',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Plastifiata',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Copolimeri de clorura de vinil si de acetat de vinil',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Alti copolimeri de clorura de vinil',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Copolimer de clorura de viniliden cu acrilonitril sub forma de bile expandabile cu diametru de minimum microni dar maximum de microni',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Altele',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Politetrafluoroetilena',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Polifluorura de vinil in una din formele mentionate la nota literab a capitolului',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Fluoroelastomeri FKM',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Altele',
    'Polimeri de clorura de vinil sau de alte olefine halogenate sub forme primare Altele',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare In dispersie apoasa',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Altele',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare In dispersie apoasa',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Altele',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Polivinil alcool care contine sau nu grupari acetat nehidrolizate',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Copolimeri',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Polivinil formal in una din formele mentionate la nota literab a capitolului cu o greutate moleculara de minimum  dar de maximum  si continand in greutate  minimum  dar maximum  grupari acetil evaluate ca acetat de vinil si  minimum  dar maximum  grupari hidroxil evaluate ca alcool vinilic',
    'Polimeri de acetat de vinil sau de alti esteri de vinil sub forme primare alti polimeri de vinil sub forme primare Altele',
    'Polimeri acrilici sub forme primare Polimetacrilat de metil',
    'Polimeri acrilici sub forme primare PoliNhidroxiiminodimetilbutil acrilamida',
    'Polimeri acrilici sub forme primare Copolimer de metacrilat de diizopropilaminoetil cu metracrilat de decil sub forma de solutie in NNdimetilacetamida care contine in greutate minimum  copolimer',
    'Polimeri acrilici sub forme primare Copolimer de acid acrilic cu acrilat de etilhexil care contine in greutate minimum  dar maximum  acrilat de etilhexil',
    'Polimeri acrilici sub forme primare Copolimer de acrilonitril cu acrilat de metil modificat cu polibutadienacrilonitril NBR',
    'Polimeri acrilici sub forme primare Produs de polimerizare a acidului acrilic cu metacrilat de alchil si mici cantitati de alti monomeri utilizat ca substanta de ingrosare in fabricarea pastelor utilizate la imprimarea textilelor',
    'Polimeri acrilici sub forme primare Copolimer de acrilat de metil cu etilena si un monomer care contin ca substituent o grupa carboxi neterminala cu o greutate de minimum  acrilat de metil combinat sau nu cu silice',
    'Polimeri acrilici sub forme primare Altele',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Poliacetali',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Bispolioxietilen metilfosfonat',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Polietilenglicoli',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Altele',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Copolimer de clorepoxipropan cu oxid de etilena',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Altele',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Rasini epoxidice',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Policarbonati',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Rasini alchidice',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Cu un indice de viscozitate de minimum  mlg',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Altele',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Poliacid lactic',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Lichizi',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Altele',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Copolimeri termoplastici de cristale lichide pe baza de poliester aromatic',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Polietilennaftalindicarboxilat',
    'Poliacetali alti polieteri si rasini epoxidice sub forme primare policarbonati rasini alchidice poliesteri alilici si alti poliesteri sub forme primare Altele',
    'Poliamide sub forme primare Poliamida      sau',
    'Poliamide sub forme primare Altele',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Rasini ureice rasini de tiouree',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Rasini melaminice',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Polimetilen fenil izocianat MDI brut MDI polimeric',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Altele',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Rasini fenolice',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Poliuretan de tertbutiliminodietanol si de diizocianat de metilendiciclohexil sub forma de solutie in NNdimetilacetamida care contine in greutate minimum  polimer',
    'Rasini aminice rasini fenolice si poliuretani sub forme primare Altele',
    'Siliconi sub forme primare Siliconi sub forme primare',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Rasini de petrol rasini de cumarona rasini de inden rasini cumaronindenice si politerpene',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Polifenilen metilfosfonat',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Polioxifenilensulfonilfenilenoxifenilenizopropilidenfenilena in una din formele mentionate la nota literab a capitolului',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Politiofenilena',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Altele',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Copolimer de pcresol si divinilbenzen sub forma de solutie in NNdimetilacetamida care contine in greutate minimum  polimer copolimeri hidrogenati de viniltoluen si metilstiren',
    'Rasini de petrol rasini cumaronindenice politerpene polisulfuri polisulfoni si alte produse mentionate la nota din prezentul capitol nedenumite si neclasificate in alta parte sub forme primare Altele',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Neplastifiati',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Plastifiati',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Colodiu si celoidin',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Altele',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Plastifiati',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Carboximetilceluloza si sarurile ei',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Hidroxipropilceluloza',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Altele',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Esteri de celuloza',
    'Celuloza si derivatii ei chimici nedenumiti si necuprinsi in alta parte sub forme primare Altele',
    'Polimeri naturali de exemplu acid alginic si polimeri naturali modificati de exemplu proteine intarite derivati chimici ai cauciucului natural nedenumiti si necuprinsi in alta parte sub forme primare Acid alginic sarurile si esterii acestuia',
    'Polimeri naturali de exemplu acid alginic si polimeri naturali modificati de exemplu proteine intarite derivati chimici ai cauciucului natural nedenumiti si necuprinsi in alta parte sub forme primare Altele',
    'Schimbatori de ioni pe baza de polimeri de la pozitiile sub forme primare Schimbatori de ioni pe baza de polimeri de la pozitiile sub forme primare',
    'Deseuri span talas sparturi de materiale plastice care au o greutate specifica mai mica de  de exemplu PELD',
    'Deseuri span talas sparturi de materiale plastice care au o greutate specifica de  sau mai mult de exemplu PEHD',
    'Deseuri span talas sparturi de materiale plastice De polimeri de stiren',
    'Deseuri span talas sparturi de materiale plastice De polimeri de clorura de vinil',
    'Deseuri span talas sparturi de materiale plastice De polimeri de propilena',
    'Deseuri span talas sparturi de materiale plastice din polimeri de etilentereftalat de exemplu PET',
    'Deseuri span talas sparturi de materiale plastice Altele',
    'Monofilamente a caror dimensiune maxima in sectiunea transversala depaseste mm monofire inele tije bare si profile chiar prelucrate la suprafata dar neprelucrate altfel din material plastic Din polimeri de etilena',
    'Monofilamente a caror dimensiune maxima in sectiunea transversala depaseste mm monofire inele tije bare si profile chiar prelucrate la suprafata dar neprelucrate altfel din material plastic Din polimeri de clorura de vinil',
    'Monofilamente a caror dimensiune maxima in sectiunea transversala depaseste mm monofire inele tije bare si profile chiar prelucrate la suprafata dar neprelucrate altfel din material plastic Din produse de polimerizare de reorganizare sau de condensare chiar modificate chimic',
    'Monofilamente a caror dimensiune maxima in sectiunea transversala depaseste mm monofire inele tije bare si profile chiar prelucrate la suprafata dar neprelucrate altfel din material plastic Din produse de polimerizare de aditie',
    'Monofilamente a caror dimensiune maxima in sectiunea transversala depaseste mm monofire inele tije bare si profile chiar prelucrate la suprafata dar neprelucrate altfel din material plastic Altele',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Din proteine intarite',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Din materiale plastice celulozice',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Obtinute fara sudura sau lipire si de lungime care depaseste dimensiunea maxima a sectiunii transversale chiar fasonate dar nu altfel prelucrate',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Obtinute fara sudura sau lipire si cu o lungime care depaseste dimensiunea maxima a sectiunii transversale chiar fasonate dar nu altfel prelucrate',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Obtinute fara sudura sau lipire si cu o lungime care depaseste dimensiunea maxima a sectiunii transversale chiar fasonate dar nu altfel prelucrate',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Din alte materiale plastice',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Tuburi tevi i furtunuri flexibile care pot suporta o presiune minima de MPa',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele neranforsate cu alte materiale nici  combinate in alt mod cu alte materiale fara accesorii',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele neranforsate cu alte materiale nicicombinate in alt mod cu alte materiale cu accesorii',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Altele',
    'Tuburi tevi si accesorii ale acestora de exemplu imbinari coturi flanse din materiale plastice Accesorii',
    'Invelitori din materiale plastice pentru podele autoadezive sau nu in rulouri sau in forma de placi de pardoseala sau de dale invelitori pentru pereti si tavane din materiale plastice definite la nota din acest capitol Constand intrun suport impregnat imbracat sau acoperit cu policlorura de vinil',
    'Invelitori din materiale plastice pentru podele autoadezive sau nu in rulouri sau in forma de placi de pardoseala sau de dale invelitori pentru pereti si tavane din materiale plastice definite la nota din acest capitol Altele',
    'Invelitori din materiale plastice pentru podele autoadezive sau nu in rulouri sau in forma de placi de pardoseala sau de dale invelitori pentru pereti si tavane din materiale plastice definite la nota din acest capitol Din alte materiale plastice',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Din policlorura de vinil sau din polietilena',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Din polipropilena',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Altele',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Altele',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Paduri abrazive autoadezive de forma circulara de tipul celor utilizate pentru fabricarea plachetelor wafers de semiconductori',
    'Placi foi folii benzi panglici pelicule si alte forme plate autoadezive din materiale plastice chiar in rulouri Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Pelicule de polietilena cu o grosime de minimum de microni dar de maximum de microni pentru fabricarea peliculelor fotorezistente utilizate la fabricarea semiconductorilor sau a circuitelor imprimate',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Pelicule extensibile neimprimate',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport De minimum',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Pasta de hartie sintetica sub forma de foi umede formate din fibre necoezive de polietilena amestecate sau nu cu fibre de celuloza intro proportie de maximum  care contine ca agent de umezire polialcool vinilic dizolvat in apa',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Orientate biaxial',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de peste mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din polimeri de stiren',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de maximum mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de peste mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de maximum mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de peste mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din polimetacrilat de metil',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Copolimer din esteri acrilic si metacrilic sub forma de pelicule cu grosimea de maximum de microni',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din policarbonati',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Pelicule de polietilen tereftalat cu grosimea de minimum de microni dar de maximum de microni pentru producerea discurilor magnetice flexibile pelicule de polietilen tereftalat cu grosimea de minimum de microni dar de maximum de microni pentru producerea placilor de imprimare din fotopolimeri',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Cu grosime de peste mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din poliesteri nesaturati',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din alti poliesteri',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din celuloza regenerata',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Pelicule sub forma de role sau benzi pentru cinematografie sau pentru fotografie',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din fibre vulcanizate',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din polibutiral de vinil',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din poliamide',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din rasini aminice',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Din rasini fenolice',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Folii si benzi din poliamida neacoperite sau acoperite ori imbracate numai cu materiale plastice',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Folii din polifluorura de vinil pelicule din polialcool vinilic orientat biaxial care contine in greutate minimum  polialcool vinilic neacoperite de o grosime de maximum mm',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Membrane schimbatoare de ioni din material plastic fluorurat utilizate in celule electrolitice cloralcaline',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi foi folii pelicule benzi panglici si lame din materiale plastice nealveolare neranforsate nestratificate neasociate cu alte materiale neprevazute cu un suport Altele',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din polimeri de stiren',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din polimeri de clorura de vinil',
    'Alte placi folii pelicule benzi si lame din materiale plastice Flexibile',
    'Alte placi folii pelicule benzi si lame din materiale plastice Altele',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din celuloza regenerata',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din alte materiale plastice',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din poliesteri',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din rasini fenolice',
    'Alte placi folii pelicule benzi si lame din materiale plastice Sub presiune inalta cu suprafete decorative pe o fata sau pe ambele fete',
    'Alte placi folii pelicule benzi si lame din materiale plastice Altele',
    'Alte placi folii pelicule benzi si lame din materiale plastice Altele',
    'Alte placi folii pelicule benzi si lame din materiale plastice Altele',
    'Alte placi folii pelicule benzi si lame din materiale plastice Din produse de polimerizare de aditie',
    'Alte placi folii pelicule benzi si lame din materiale plastice Altele',
    'Cazi de baie cazi de dus chiuvete lavoare bideuri vase de closet scaune si capace pentru closete rezervoare de apa si articole similare pentru utilizari sanitare sau igienice din materiale plastice Cazi de baie cazi de dus chiuvete si lavoare',
    'Cazi de baie cazi de dus chiuvete lavoare bideuri vase de closet scaune si capace pentru closete rezervoare de apa si articole similare pentru utilizari sanitare sau igienice din materiale plastice Scaune si capace pentru closete',
    'Cazi de baie cazi de dus chiuvete lavoare bideuri vase de closet scaune si capace pentru closete rezervoare de apa si articole similare pentru utilizari sanitare sau igienice din materiale plastice Altele',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Cutii lazi stelaje si articole similare din plastic special realizate pentru transportul sau ambalarea plachetelor wafers de semiconductori a mastilor sau a reticulelor',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Altele',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Din polimeri de etilena',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Din policlorura de vinil',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Altele',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Cu capacitate de maximum l',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Cu capacitate de peste l',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Bobine si suporturi similare pentru rularea filmelor si peliculelor fotografice si cinematografice sau a benzilor filmelor etc mentionate la pozitia',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Altele',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Capsule si dopuri pentru astupare',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Altele',
    'Articole de transport sau de ambalare din materiale plastice busoane dopuri capace capsule si alte dispozitive de inchidere din materiale plastice Altele',
    'Vesela alte articole de menaj sau obiecte de uz casnic si articole de igiena sau de toaleta din materiale plastice Vesela si alte articole pentru servirea mesei sau de bucatarie',
    'Vesela alte articole de menaj sau obiecte de uz casnic si articole de igiena sau de toaleta din materiale plastice Altele',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Rezervoare butoaie cuve si recipienti similari cu capacitatea de peste l',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Usi ferestre cadre si tocuri pervazuri si praguri',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Obloane storuri inclusiv storuri venetiene si alte articole similare si parti ale acestora',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Accesorii si garnituri destinate pentru instalarea definitiva in sau pe usi ferestre scari pereti sau pe alte parti de constructii',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Conducte tevi si doze de perete pentru circuite electrice',
    'Articole pentru echiparea constructiilor din materiale plastice nedenumite si necuprinse in alta parte Altele',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Articole de birou si articole scolare',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Articole si accesorii de imbracaminte inclusiv manusi mitene si manusi cu un deget',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Garnituri pentru mobila caroserii sau similare',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Statuete si alte obiecte de ornament',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Gratare si articole similare pentru filtrarea apei la intrarea in canalul de scurgere',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Dispozitive de protectie pentru fataviziere',
    'Alte articole din materiale plastice si articole din alte materiale de la pozitiile Altele',
    'Cauciuc natural balata gutaperca guayul chicle si gume naturale similare sub forme primare sau in placi folii sau benzi Latex de cauciuc natural chiar prevulcanizat',
    'Cauciuc natural balata gutaperca guayul chicle si gume naturale similare sub forme primare sau in placi folii sau benzi Foi afumate',
    'Cauciuc natural balata gutaperca guayul chicle si gume naturale similare sub forme primare sau in placi folii sau benzi Cauciuc natural tehnic specificat TSNR',
    'Cauciuc natural balata gutaperca guayul chicle si gume naturale similare sub forme primare sau in placi folii sau benzi Altele',
    'Cauciuc natural balata gutaperca guayul chicle si gume naturale similare sub forme primare sau in placi folii sau benzi Balata gutaperca guayul chicle si gume naturale similare',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Latex',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Cauciuc stirenbutadienic fabricat prin polimerizare in emulsie ESBR in baloti',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Copolimeri bloc stirenbutadienestiren fabricati prin polimerizare in solutie SBS elastomeri termoplastici sub forma de granule firimituri sau pudra',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Cauciuc stiren butadienic fabricat prin polimerizare in solutie SSBR in baloti',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Altele',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Cauciuc butadienic BR',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Cauciuc izobutenizoprenic butilcauciuc IIR',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Altele',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Latex',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Altele',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Latex',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Altele',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Cauciuc izoprenic IR',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Caucic etilenopropilendienic neconjugat EPDM',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Amestecuri ale produselor de la pozitia cu produse de la aceasta pozitie',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Latex',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Produse modificate prin incorporarea de materiale plastice',
    'Cauciuc sintetic si factice derivat din uleiuri sub forme primare sau in placi foi si benzi amestecuri ale produselor din pozitia cu produse de la prezenta pozitie sub forme primare sau in placi foi sau benzi Altele',
    'Cauciuc regenerat sub forme primare sau in placi foi sau benzi Cauciuc regenerat sub forme primare sau in placi foi sau benzi',
    'Deseuri bavuri si resturi de cauciuc nedurificat chiar in pulbere sau in granule Deseuri bavuri si resturi de cauciuc nedurificat chiar in pulbere sau in granule',
    'Cauciuc amestecat nevulcanizat sub forme primare sau in placi foi sau benzi Cauciuc amestecat cu negru de fum sau silice',
    'Cauciuc amestecat nevulcanizat sub forme primare sau in placi foi sau benzi Solutii dispersii altele decat cele din subpozitia',
    'Cauciuc amestecat nevulcanizat sub forme primare sau in placi foi sau benzi Placi foi si benzi',
    'Cauciuc amestecat nevulcanizat sub forme primare sau in placi foi sau benzi Altele',
    'Alte forme de exemplu baghete tuburi profile si articole de exemplu discuri rondele din cauciuc nevulcanizat Profile pentru resapare',
    'Alte forme de exemplu baghete tuburi profile si articole de exemplu discuri rondele din cauciuc nevulcanizat Altele',
    'Fire si corzi din cauciuc vulcanizat Fire si corzi din cauciuc vulcanizat',
    'Placi foi benzi baghete si profile din cauciuc vulcanizat nedurificat Placi foi si benzi',
    'Placi foi benzi baghete si profile din cauciuc vulcanizat nedurificat Altele',
    'Placi foi benzi baghete si profile din cauciuc vulcanizat nedurificat Acoperitoare de podele si stergatoare pentru picioare',
    'Placi foi benzi baghete si profile din cauciuc vulcanizat nedurificat Altele',
    'Placi foi benzi baghete si profile din cauciuc vulcanizat nedurificat Altele',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Fara accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Cu accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Fara accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Cu accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Fara accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Cu accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Fara accesorii',
    'Tuburi tevi si furtunuri din cauciuc vulcanizat nedurificat prevazute sau nu cu accesoriile lor de exemplu racorduri garnituri coturi flanse Cu accesorii',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Ranforsate numai cu metal',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Ranforsate numai cu materiale textile',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Altele',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit striate de sectiune trapezoidala cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit de sectiune trapezoidala altele decat cele striate cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit de sectiune trapezoidala striate cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit de sectiune trapezoidala altele decat cele striate cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit crestate sincrone cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Curele de transmisie continue fara sfarsit crestate sincrone cu o circumferinta exterioara de peste cm dar de maximum cm',
    'Benzi transportoare sau curele de transmisie din cauciuc vulcanizat Altele',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru autoturisme inclusiv cele de tip break si cele de curse',
    'Anvelope pneumatice noi din cauciuc Cu un indice de sarcina de maximum',
    'Anvelope pneumatice noi din cauciuc Cu un indice de sarcina de peste',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru aeronave',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru motociclete',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru biciclete',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru vehicule si masini agricole si forestiere',
    'Anvelope pneumatice noi din cauciuc De tipul celor utilizate pentru vehicule si masini de constructie miniere sau de manipulare industriala',
    'Anvelope pneumatice noi din cauciuc Altele',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc De tipul celor utilizate pentru autoturisme inclusiv masinile de tip break si masinile de curse',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc De tipul celor utilizate pentru autobuze sau camioane',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc De tipul celor utilizate pentru aeronave',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc Altele',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc Anvelope pneumatice uzate',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc Bandaje pline sau cave',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc Benzi de rulare amovibile pentru pneuri',
    'Anvelope pneumatice resapate sau uzate din cauciuc bandaje benzi de rulare amovibile pentru anvelope si flapsuri din cauciuc Flapsuri',
    'Camere de aer din cauciuc De tipul celor utilizate pentru autoturisme inclusiv vehicule de tip break si vehicule de curse pentru autobuze sau pentru camioane',
    'Camere de aer din cauciuc De tipul celor utilizate pentru biciclete',
    'Camere de aer din cauciuc Altele',
    'Articole de igiena sau de farmacie inclusiv tetinele din cauciuc vulcanizat nedurificat chiar cu parti din cauciuc durificat ebonita Prezervative',
    'Articole de igiena sau de farmacie inclusiv tetinele din cauciuc vulcanizat nedurificat chiar cu parti din cauciuc durificat ebonita Altele',
    'Imbracaminte si accesorii de imbracaminte inclusiv manusi mitene si manusi cu un deget din cauciuc vulcanizat nedurificat pentru orice utilizare De tipul celor utilizate in medicina in chirurgie in stomatologie sau in scopuri veterinare',
    'Imbracaminte si accesorii de imbracaminte inclusiv manusi mitene si manusi cu un deget din cauciuc vulcanizat nedurificat pentru orice utilizare Altele',
    'Imbracaminte si accesorii de imbracaminte inclusiv manusi mitene si manusi cu un deget din cauciuc vulcanizat nedurificat pentru orice utilizare Altele',
    'Alte articole din cauciuc vulcanizat nedurificat Din cauciuc alveolar',
    'Alte articole din cauciuc vulcanizat nedurificat Acoperitoare de podea si stergatoare de picioare',
    'Alte articole din cauciuc vulcanizat nedurificat Gume de sters',
    'Alte articole din cauciuc vulcanizat nedurificat Garnituri saibe si alte etansari',
    'Alte articole din cauciuc vulcanizat nedurificat Parasocuri chiar gonflabile pentru acostarea navelor',
    'Alte articole din cauciuc vulcanizat nedurificat Alte articole gonflabile',
    'Alte articole din cauciuc vulcanizat nedurificat Piese din cauciucmetal',
    'Alte articole din cauciuc vulcanizat nedurificat Altele',
    'Alte articole din cauciuc vulcanizat nedurificat Piese din cauciucmetal',
    'Alte articole din cauciuc vulcanizat nedurificat Altele',
    'Cauciuc durificat de exemplu ebonita sub toate formele inclusiv deseurile si resturile articole din cauciuc durificat Cauciuc durificat de exemplu ebonita sub toate formele inclusiv deseurile si resturile articole din cauciuc durificat',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Proaspete',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Sarate umed',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Uscate sau sarate uscat',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Altele',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Proaspete',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Sarate umed',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Uscate sau sarate uscat',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Altele',
    'Piei brute de bovine inclusiv de bivoli sau de ecvidee proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite Altele inclusiv crupoanele semicrupoanele si flancurile',
    'Piei brute de ovine proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin nota literac din acest capitol De miel',
    'Piei brute de ovine proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin nota literac din acest capitol De alte ovine',
    'Piei brute de ovine proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin nota literac din acest capitol Piclate',
    'Piei brute de ovine proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin nota literac din acest capitol Altele',
    'Alte piei brute proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin notele literab sau  literac ale acestui capitol De reptile',
    'Alte piei brute proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin notele literab sau  literac ale acestui capitol De porcine',
    'Alte piei brute proaspete sau sarate uscate cenusarite piclate sau altfel conservate dar netabacite nepergamentate sau altfel preparate chiar epilate sau spaltuite altele decat cele excluse prin notele literab sau  literac ale acestui capitol Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi de bovine inclusiv de bivoli cu o suprafata unitara de maximum picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi cu o suprafata unitara de peste picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi de bovine inclusiv de bivoli cu o suprafata unitara de maximum picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi cu o suprafata unitara de peste picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei box de India kips intregi sau chiar fara cap si labe cu o greutate neta unitara de maximum kg tabacite simplu cu ajutorul unor substante vegetale chiar supuse unor tratamente dar care sunt in mod clar neutilizabile ca atare pentru fabricarea unor articole din piele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi cu o suprafata unitara de peste picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei box de India kips intregi sau chiar fara cap si picioare cu o greutate neta unitara de maximum kg tabacite simplu cu ajutorul unor substante vegetale chiar supuse unor tratamente dar care sunt in mod clar neutilizabile ca atare pentru fabricarea unor articole din piele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Piei intregi cu o suprafata unitara de peste picioare patrate m',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau piei semifinite de ovine epilate chiar spaltuite dar fara alte prelucrari In stare umeda inclusiv pielea tabacita cu crom wetblue',
    'Piei tabacite sau piei semifinite de ovine epilate chiar spaltuite dar fara alte prelucrari De metis de India pretabacite vegetal chiar supuse unor tratamente dar care sunt in mod clar neutilizabile ca atare pentru fabricarea unor articole din piele',
    'Piei tabacite sau piei semifinite de ovine epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari In stare umeda inclusiv pielea tabacita cu crom wetblue',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari De capre de India pretabacite vegetal chiar supuse unor tratamente  dar care sunt in mod clar neutilizabile ca atare pentru fabricarea unor articole din piele',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari In stare umeda inclusiv pielea tabacita cu crom wetblue',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari In stare uscata semifinita',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari Pretabacite vegetal',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari Altele',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari In stare umeda inclusiv pielea tabacita cu crom wetblue',
    'Piei tabacite sau semifinite ale altor animale si piei fara blana epilate chiar spaltuite dar fara alte prelucrari In stare uscata semifinita',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Piele box de vitel boxcalf',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Piele box de vitel boxcalf',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De bovine inclusiv de bivoli',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De ecvidee',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Piei intregi de bovine inclusiv de bivoli cu o suprafata unitara de maximum picioare patrate m',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Pentru talpi',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De bovine inclusiv de bivoli',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De ecvidee',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De bovine inclusiv de bivoli',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de bovine inclusiv de bivoli sau de ecvidee epilate chiar spaltuite altele decat cele de la pozitia De ecvidee',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de ovine epilate chiar spaltuite altele decat cele de la pozitia Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate de ovine epilate chiar spaltuite altele decat cele de la pozitia',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate ale altor animale epilate si piei prelucrate dupa tabacire si piei pergamentate de animale lipsite de par chiar spaltuite altele decat cele de la pozitia De caprine',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate ale altor animale epilate si piei prelucrate dupa tabacire si piei pergamentate de animale lipsite de par chiar spaltuite altele decat cele de la pozitia De porcine',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate ale altor animale epilate si piei prelucrate dupa tabacire si piei pergamentate de animale lipsite de par chiar spaltuite altele decat cele de la pozitia De reptile',
    'Piei prelucrate dupa tabacire sau dupa uscare si piei pergamentate ale altor animale epilate si piei prelucrate dupa tabacire si piei pergamentate de animale lipsite de par chiar spaltuite altele decat cele de la pozitia Altele',
    'Piei tabacite in grasimi piei chamois inclusiv cele obtinute prin tabacire combinata piei lacuite sau lacuite stratificate piei metalizate De ovine',
    'Piei tabacite in grasimi piei chamois inclusiv cele obtinute prin tabacire combinata piei lacuite sau lacuite stratificate piei metalizate De alte animale',
    'Piei tabacite in grasimi piei chamois inclusiv cele obtinute prin tabacire combinata piei lacuite sau lacuite stratificate piei metalizate Piei lacuite sau lacuite stratificate piei metalizate',
    'Piei reconstituite pe baza de piele sau de fibre de piele sub forma de placi foi sau benzi chiar rulate resturi si alte deseuri de piele sau de piele reconstituita inutilizabile pentru fabricarea unor articole din piele praf pudra si faina de piele Piele reconstituita pe baza de piele sau de fibre de piele sub forma de placi foi sau benzi chiar rulate',
    'Piei reconstituite pe baza de piele sau de fibre de piele sub forma de placi foi sau benzi chiar rulate resturi si alte deseuri de piele sau de piele reconstituita inutilizabile pentru fabricarea unor articole din piele praf pudra si faina de piele Resturi sau alte deseuri de piele sau de piele prelucrata sau de piele reconstituita neutilizabile pentru fabricarea unor articole din piele praf pudra si faina de piele',
    'Articole de selarie sau de curelarie pentru orice animale inclusiv sleauri zgarzi genunchiere botnite paturi pentru sa oblancuri imbracaminte pentru caini si articole similare din orice materiale Articole de selarie sau de curelarie pentru orice animale inclusiv sleauri zgarzi genunchiere botnite paturi pentru sa oblancuri imbracaminte pentru caini si articole similare din orice materiale',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Mape portdocumente serviete ghiozdane si articole similare',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Mape portdocumente serviete ghiozdane si articole similare',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din material plastic turnat',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Mape portdocumente serviete ghiozdane si articole similare',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din aluminiu',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din alte materiale',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Cu suprafata exterioara din piele naturala sau din piele reconstituita',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din foi de material plastic',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din materiale textile',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Cu suprafata exterioara din piele naturala sau din piele reconstituita',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din foi de material plastic',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Din materiale textile',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Genti de voiaj truse de toaleta rucsacuri si genti pentru articole sportive',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Genti de voiaj truse de toaleta rucsacuri si genti pentru articole sportive',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Cutii pentru instrumente muzicale',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Genti de voiaj truse de toaleta rucsacuri si genti pentru articole sportive',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Valize geamantane si cufere inclusiv casete de toaleta si mape portdocumente serviete ghiozdane tocuri pentru ochelari tocuri pentru binocluri tocuri pentru aparate fotografice pentru aparate de filmat cutii pentru instrumente muzicale sau arme tocuri de pistol si articole similare genti de voiaj genti izolate termic pentru produse alimentare si bauturi truse de toaleta rucsacuri genti sacose pentru cumparaturi portofele portmonee portharti tabachere pungi pentru tutun truse pentru scule genti pentru articole sportive cutii pentru sticle sau bijuterii cutii pentru pudra casete pentru argintarie si articole similar din piele naturala sau reconstituita din foi de material plastic din materiale textile din fibra vulcanizata sau din carton sau acoperite in totalitate sau in mare parte cu astfel de materiale sau cu hartie Altele',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita Imbracaminte',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita Special destinate pentru practicarea sporturilor',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita De protectie pentru toate meseriile',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita Altele',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita Curele centuri si banduliere',
    'Imbracaminte si accesorii de imbracaminte din piele naturala sau reconstituita Alte accesorii de imbracaminte',
    'Alte articole din piele naturala sau reconstituita Curele de transmisie sau de transport',
    'Alte articole din piele naturala sau reconstituita Altele',
    'Alte articole din piele naturala sau reconstituita Altele',
    'Articole din intestine de animale altele decat cele de la viermii de matase din basici din vezici sau din tendoane Articole din intestine de animale altele decat cele de la viermii de matase din basici din vezici sau din tendoane',
    'Blanuri brute inclusiv capetele cozile labele si alte bucati utilizate in blanarie altele decat pieile brute de la pozitiile  sau  De vizon intregi chiar fara capete cozi sau labe',
    'Blanuri brute inclusiv capetele cozile labele si alte bucati utilizate in blanarie altele decat pieile brute de la pozitiile  sau  De miel denumite astrahan breitschwanz caracul persan sau similare de miel de India China Mongolia sau Tibet intregi chiar fara capete cozi sau labe',
    'Blanuri brute inclusiv capetele cozile labele si alte bucati utilizate in blanarie altele decat pieile brute de la pozitiile  sau  De vulpe intregi chiar fara capete cozi sau labe',
    'Blanuri brute inclusiv capetele cozile labele si alte bucati utilizate in blanarie altele decat pieile brute de la pozitiile  sau  Alte blanuri intregi chiar fara capete cozi sau labe',
    'Blanuri brute inclusiv capetele cozile labele si alte bucati utilizate in blanarie altele decat pieile brute de la pozitiile  sau  Capete cozi labe si alte parti utilizabile in blanarie',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De vizon',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Din castor bizam sau vulpe',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De iepure de casa sau de iepure de camp',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De pui de foca harpa cu mantie alba sau de pui de foca cu capison cu spinarea albastra',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Altele',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De miel din speciile astrahan breitschwanz caracul persan sau similare de miel de India China Mongolia sau Tibet',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Altele',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Altele',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Capete cozi labe si alte parti deseuri si resturi neasamblate',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Piei numite intinse',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De iepure de casa sau de iepure de camp',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia De pui de foca harpa cu mantie alba sau de pui de foca cu capison cu spinarea albastra',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Altele',
    'Blanuri tabacite sau finisate inclusiv capetele cozile labele si alte parti deseuri si resturi neasamblate sau asamblate fara adaugarea altor materiale altele decat cele de la pozitia Altele',
    'Imbracaminte accesorii de imbracaminte si alte articole din blana Din blana de pui de foca harpa cu mantie alba sau de pui de foca cu capison cu spinarea albastra',
    'Imbracaminte accesorii de imbracaminte si alte articole din blana Altele',
    'Imbracaminte accesorii de imbracaminte si alte articole din blana Altele',
    'Blanuri artificiale si articole din blanuri artificiale Blanuri artificiale si articole din blanuri artificiale',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare De conifere',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Altele decat de conifere',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare De conifere',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare De eucalipt Eucalyptus spp',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Altele',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Pelete din lemn',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Brichete din lemn',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Altele',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Rumegus',
    'Lemn de foc sub forma de trunchiuri butuci vreascuri ramuri sau sub forme similare lemn sub forma de aschii sau particule rumegus deseuri si resturi de lemn chiar aglomerate sub forma de butuci brichete pelete sau forme similare Altele',
    'Carbune de lemn inclusiv carbunele din coji sau din nuci chiar aglomerat De bambus',
    'Carbune de lemn inclusiv carbunele din coji sau din nuci chiar aglomerat Din coji sau din nuci',
    'Carbune de lemn inclusiv carbunele din coji sau din nuci chiar aglomerat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat De conifere',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele decat de conifere',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Busteni pentru cherestea',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din pin Pinus spp altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Busteni pentru cherestea',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din brad Abies spp si molid Picea spp altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Busteni pentru cherestea',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Dark Red Meranti Light Red Meranti si Meranti Bakau',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Tec',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Acaju de Africa Iroko si Sapelli',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Okum si Sipo',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat De stejar Quercus spp',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din fag Fagus spp pentru care cea mai mica dimensiune a sectiunii transversale este de cel putin cm',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din fag Fagus spp altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Busteni pentru cherestea',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din mesteacan Betula spp altele',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din plop si plop tremurator Populus spp',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Din eucalipt Eucalyptus spp',
    'Lemn brut chiar cojit curatat de ramuri sau ecarisat Altele',
    'Lemn pentru dogarie prajini despicate tarusi si pari din lemn ascutiti nedespicati longitudinal lemn simplu degrosat sau rotunjit dar nestrunjit necurbat sau altfel prelucrat pentru bastoane umbrele manere de scule sau similare lemn sub forma de eclise lame benzi si produse similare De conifere',
    'Lemn pentru dogarie prajini despicate tarusi si pari din lemn ascutiti nedespicati longitudinal lemn simplu degrosat sau rotunjit dar nestrunjit necurbat sau altfel prelucrat pentru bastoane umbrele manere de scule sau similare lemn sub forma de eclise lame benzi si produse similare Altele decat de conifere',
    'Lana de lemn faina de lemn Lana de lemn faina de lemn',
    'Traverse din lemn pentru cai ferate sau similare De conifere',
    'Traverse din lemn pentru cai ferate sau similare Altele decat de conifere',
    'Traverse din lemn pentru cai ferate sau similare De conifere',
    'Traverse din lemn pentru cai ferate sau similare Altele decat de conifere',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar slefuit sau geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar slefuit sau geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Din SPF molid Picea spp pin Pinus spp si brad Abies spp',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Din Hemfir  combinatie de tsuga vestica Tsuga heterophylla si brad Abies spp',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar slefuit sau geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin imbinare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin imbinare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin imbinare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar slefuit sau geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lipit prin imbinare cap la cap chiar slefuit sau geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Palisandru de Para Palisandru de Rio si Palisandru de Rose geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit lipit prin imbinare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Lame si frize pentru parchet neasamblate',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm De fag Fagus spp',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Geluit lipit prin asamblare cap la cap chiar geluit sau slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Slefuit',
    'Lemn taiat sau despicat longitudinal transat sau derulat chiar geluit slefuit sau lipit prin imbinare cap la cap cu o grosime de peste mm Altele',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Geluite slefuite lipite prin imbinare cap la cap chiar geluite sau slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Scandurele destinate fabricarii creioanelor',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Altele',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Lipite prin imbinare cap la cap chiar geluite sau slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Geluite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Altele',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Slefuite lipite prin imbinare cap la cap chiar geluite sau slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Geluite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Altele',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Geluite slefuite lipite prin imbinare cap la cap chiar geluite sau slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Scandurele destinate fabricarii creioanelor',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Cu o grosime de maximum mm',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Cu o grosime de peste mm',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Geluite slefuite lipite prin imbinare cap la cap chiar geluite sau slefuite',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Scandurele destinate fabricarii creioanelor',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Cu o grosime de maximum mm',
    'Foi pentru furnir inclusiv cele obtinute prin transarea lemnului stratificat foi pentru placaj sau pentru alt lemn stratificat similar si alt lemn taiat longitudinal transat sau derulat chiar geluit slefuit imbinat lateral sau cap la cap cu o grosime de maximum mm Cu o grosime de peste mm',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Baghete si forme de lemn pentru rame de tablouri fotografii oglinzi sau obiecte similare',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Altele',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap De bambus',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Din lemn tropical',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Baghete si forme de lemn pentru rame de tablouri fotografii oglinzi sau obiecte similare',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Lame si frize pentru parchet neasamblate',
    'Lemn inclusiv lame si frize de parchet neasamblate profilat sub forma de lamba de uluc faltuit nutuit sanfrenat imbinat in V mulurat rotunjit sau similare in lungul unuia sau a mai multor canturi fete sau capete chiar geluit slefuit sau lipit prin imbinare cap la cap Altele',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Neprelucrate sau cel mult geluite placi brute',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Acoperite la suprafata cu hartie impregnata cu melamina',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Acoperite la suprafata cu foi subtiri din materiale plastice',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Altele',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Neprelucrate sau cel mult geluite placi brute',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Altele',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Altele',
    'Placi aglomerate panouri numite oriented strand board OSB si panouri similare de exemplu panourile numite waferboard din lemn sau din alte materiale lemnoase chiar aglomerate cu rasini sau cu alti lianti organici Altele',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Neprelucrate mecanic si nici acoperite la suprafata placi brute',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate peste  gcm HDF',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate de maximum  gcm',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Neprelucrate mecanic si nici acoperite la suprafata placi brute',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate peste  gcm HDF',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate de maximum  gcm',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Neprelucrate mecanic si nici acoperite la suprafata placi brute',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate peste  gcm HDF',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate peste  gcm dar de maximum  gcm',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate de maximum  gcm',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Neprelucrate mecanic si nici acoperite la suprafata placi brute',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Altele',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Cu o densitate peste gcm dar de maximum gcm',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Neprelucrate mecanic si nici acoperite la suprafata placi brute',
    'Panouri fibrolemnoase sau din alte materiale lemnoase chiar aglomerate cu rasini sau alti lianti organici Altele',
    'Placaj lemn furniruit si lemn stratificat similar De bambus',
    'Placaj lemn furniruit si lemn stratificat similar De Acaju de Africa Dark Red Meranti Light Red Meranti Limba Mahogany Swietenia spp Obeche Okum Palisandru de Para Palisandru de Rio si Palisandru de Rose Sapelli Sipo Virola sau White Lauan',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Cu cel putin un strat exterior din mesteacan Betula spp',
    'Placaj lemn furniruit si lemn stratificat similar Fara niciun strat exterior din mesteacan dar cu cel putin o fata exterioara din plop sau plop tremurator Populus spp',
    'Placaj lemn furniruit si lemn stratificat similar Fara niciun strat exterior din mesteacan plop sau plop tremurator Populus spp dar cu cel putin o fata exterioara din eucalipt Eucalyptus spp',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu cel putin  un strat exterior din lemn altul decat de conifere nespecificat la subpozitia',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu ambele straturi exterioare din lemn de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Avand cel putin un strat exterior din alt lemn decat de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu cel putin un strat exterior din lemn de alt tip decat de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu ambele straturi exterioare din lemn de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Avand cel putin un strat exterior din alt lemn decat de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu cel putin un strat exterior din lemn de alt tip decat de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Altele cu ambele straturi exterioare din lemn de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Care conin cel puin un strat din placi aglomerate',
    'Placaj lemn furniruit si lemn stratificat similar Avand cel putin un strat exterior din alt lemn decat de conifere',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Care conin cel puin un strat din placi aglomerate',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Placaj lemn furniruit si lemn stratificat similar Care conin cel puin un strat din placi aglomerate',
    'Placaj lemn furniruit si lemn stratificat similar Altele',
    'Lemn densificat in blocuri scanduri lame sau sub forma de profile Lemn densificat in blocuri scanduri lame sau sub forma de profile',
    'Rame din lemn pentru tablouri fotografii oglinzi sau obiecte similare Din lemn tropical mentionat la nota complementara de la acest capitol',
    'Rame din lemn pentru tablouri fotografii oglinzi sau obiecte similare Altele',
    'Rame din lemn pentru tablouri fotografii oglinzi sau obiecte similare Altele',
    'Lazi ladite cosuri cilindri si ambalaje similare din lemn tambure pentru cabluri din lemn paleti simpli boxpaleti si alte platforme de incarcare din lemn grilaje pentru paleti din lemn Lazi ladite cosuri cilindri si ambalaje similare',
    'Lazi ladite cosuri cilindri si ambalaje similare din lemn tambure pentru cabluri din lemn paleti simpli boxpaleti si alte platforme de incarcare din lemn grilaje pentru paleti din lemn Tambure pentru cabluri',
    'Lazi ladite cosuri cilindri si ambalaje similare din lemn tambure pentru cabluri din lemn paleti simpli boxpaleti si alte platforme de incarcare din lemn grilaje pentru paleti din lemn Paleti simpli grilaje pentru paleti',
    'Lazi ladite cosuri cilindri si ambalaje similare din lemn tambure pentru cabluri din lemn paleti simpli boxpaleti si alte platforme de incarcare din lemn grilaje pentru paleti din lemn Altele',
    'Butoaie cuve putini si alte produse de dogarie si partile lor din lemn inclusiv doagele Butoaie cuve putini si alte produse de dogarie si partile lor din lemn inclusiv doagele',
    'Unelte suporturi si manere de unelte monturi de perii cozi de maturi sau de perii din lemn forme calapoade si sanuri pentru incaltaminte din lemn Unelte suporturi si manere de unelte monturi de perii cozi de maturi sau de perii din lemn forme calapoade si sanuri pentru incaltaminte din lemn',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Din lemn tropical',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn De conifere',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Din lemn tropical mentionat la nota complementara de la acest capitol',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn De conifere',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Stalpi si grinzi altele decat produsele de la subpozitiile',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Cofraje pentru betonare',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Sindrile shingles si shakes',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Pentru podele mozaicate',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele pentru podele mozaicate',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele multistratificate',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Din lemn lamelar glulam',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Din lemn laminat cu lamele ortogonale CLT sau Xlam',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Grinzi in I',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Din bambus',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Panouri celulare',
    'Lucrari de tamplarie si piese de dulgherie pentru constructii inclusiv panouri celulare panouri asamblate pentru acoperit podeaua si sindrile shingles si shakes din lemn Altele',
    'Articole de masa sau de bucatarie din lemn Planseta de taiat painea plansete pentru tocat si alte plansete similare',
    'Articole de masa sau de bucatarie din lemn Betisoare chinezesti',
    'Articole de masa sau de bucatarie din lemn Altele',
    'Articole de masa sau de bucatarie din lemn Din lemn tropical mentionat la nota complementara de la acest capitol',
    'Articole de masa sau de bucatarie din lemn Altele',
    'Articole de masa sau de bucatarie din lemn Altele',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Din lemn tropical mentionat la nota complementara de la acest capitol',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Altele',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Altele',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Lemn marchetat si lemn incrustat',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Din lemn tropical mentionat la nota complementara de la acest capitol',
    'Lemn marchetat si lemn incrustat sipete casete si cutii pentru bijuterii sau argintarie si articole similare din lemn statuete si alte obiecte de ornament din lemn articole de mobilier din lemn necuprinse la capitolul Altele',
    'Alte articole din lemn Umerase pentru imbracaminte',
    'Alte articole din lemn Din panouri fibrolemnoase',
    'Alte articole din lemn Altele',
    'Alte articole din lemn Din bambus',
    'Alte articole din lemn Din panouri fibrolemnoase',
    'Alte articole din lemn Altele',
    'Pluta naturala bruta sau simplu prelucrata deseuri de pluta pluta concasata granulata sau pulverizata Pluta naturala bruta sau simplu prelucrata',
    'Pluta naturala bruta sau simplu prelucrata deseuri de pluta pluta concasata granulata sau pulverizata Altele',
    'Pluta naturala decojita sau simplu ecarisata sau in cuburi placi foi sau benzi de forma patrata sau dreptunghiulara inclusiv ebosele cu muchii nefinisate pentru dopuri Pluta naturala decojita sau simplu ecarisata sau in cuburi placi foi sau benzi de forma patrata sau dreptunghiulara inclusiv ebosele cu muchii nefinisate pentru dopuri',
    'Articole din pluta naturala Cilindrice',
    'Articole din pluta naturala Altele',
    'Articole din pluta naturala Altele',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Pentru vin spumos inclusiv cele cu garnitura de etansare din pluta naturala',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Altele',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Cu lianti',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Altele',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Dopuri',
    'Pluta aglomerata cu sau fara liant si articole din pluta aglomerata Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Impletituri si articole similare din materiale de impletit chiar asamblate in benzi',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Impletituri si articole similare din materiale de impletit chiar asamblate in benzi',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Impletituri si articole similare din materiale de impletit chiar asamblate in benzi',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Impletituri si articole similare din materiale de impletit chiar asamblate in benzi',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Confectionate din impletituri si articole similare din materiale de impletit',
    'Impletituri si articole similare din materiale de impletit chiar asamblate in benzi materiale de impletit impletituri si articole similare din materiale de impletit tesute sau asezate paralel sub forma de foi chiar finisate de exemplu rogojini stergatoare si diverse impletituri Altele',
    'Obiecte impletite i alte articole confectionate direct in forma finala din materiale de impletit sau din articole de la pozitia articole din lufa Din bambus',
    'Obiecte impletite i alte articole confectionate direct in forma finala din materiale de impletit sau din articole de la pozitia articole din lufa Din ratan',
    'Obiecte impletite i alte articole confectionate direct in forma finala din materiale de impletit sau din articole de la pozitia articole din lufa Impletituri de paie pentru sticle utilizate ca ambalaj de protectie',
    'Obiecte impletite i alte articole confectionate direct in forma finala din materiale de impletit sau din articole de la pozitia articole din lufa Altele',
    'Obiecte impletite i alte articole confectionate direct in forma finala din materiale de impletit sau din articole de la pozitia articole din lufa Altele',
    'Paste mecanice din lemn Paste termomecanice din lemn',
    'Paste mecanice din lemn Altele',
    'Paste chimice din lemn de dizolvare Paste chimice din lemn de dizolvare',
    'Paste chimice din lemn cu soda sau cu sulfat altele decat pastele de dizolvare De conifere',
    'Paste chimice din lemn cu soda sau cu sulfat altele decat pastele de dizolvare Altele decat de conifere',
    'Paste chimice din lemn cu soda sau cu sulfat altele decat pastele de dizolvare De conifere',
    'Paste chimice din lemn cu soda sau cu sulfat altele decat pastele de dizolvare Altele decat de conifere',
    'Paste chimice din lemn cu bisulfit altele decat pastele de dizolvare De conifere',
    'Paste chimice din lemn cu bisulfit altele decat pastele de dizolvare Altele decat de conifere',
    'Paste chimice din lemn cu bisulfit altele decat pastele de dizolvare De conifere',
    'Paste chimice din lemn cu bisulfit altele decat pastele de dizolvare Altele decat de conifere',
    'Paste din lemn obtinute prin combinarea unei prelucrari mecanice si a unei prelucrari chimice Paste din lemn obtinute prin combinarea unei prelucrari mecanice si a unei prelucrari chimice',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Paste din linters de bumbac',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Paste din fibre obtinute din hartie sau carton reciclate deseuri si maculatura',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Altele din bambus',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Mecanice',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Chimice',
    'Paste din fibre obtinute din hartie sau carton reciclate deseuri sau maculatura sau din alte materiale fibrocelulozice Obtinute printro combinatie de procedee mecanice si chimice',
    'Hartie sau carton reciclabile deseuri si maculatura Hartii sau carton kraft nealbite sau hartie sau cartoane ondulate',
    'Hartie sau carton reciclabile deseuri si maculatura Alte hartii sau cartoane obtinute in principal din pasta chimica albita necolorate in masa',
    'Hartie sau carton reciclabile deseuri si maculatura Numere vechi si nevandute de ziare si reviste anuare telefonice brosuri si imprimate publicitare',
    'Hartie sau carton reciclabile deseuri si maculatura Altele',
    'Hartie sau carton reciclabile deseuri si maculatura Netriate',
    'Hartie sau carton reciclabile deseuri si maculatura Triate',
    'Hartie de ziar in rulouri sau foi Hartie de ziar in rulouri sau foi',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Hartii si cartoane fabricate manual',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Hartii si cartoane suport pentru hartii si cartoane fotosensibile termosensibile sau electrosensibile',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Fara fibre obtinute printrun procedeu mecanic sau la care maximum  din greutatea totala a compozitiei fibroase o constituie astfel de fibre',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate sub gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate de minimum gm dar sub gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate de minimum gm dar sub gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate de minimum gm dar sub gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate de minimum gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual La care o latura are mm si cealalta mm format A',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele cu o greutate de minimum gm dar de maximum gm',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual In rulouri',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Cu o greutate sub gm si la care mai mult de  din greutatea totala a compozitiei fibroase o constituie fibrele obtinute printrun procedeu mecanic',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual In foi la care o latura are maximum mm si cealalta este de maximum mm in stare nepliata',
    'Hartii si cartoane necretate de tipul celor utilizate pentru scris tiparit sau in alte scopuri grafice si hartii si cartoane pentru cartele sau benzi de perforat neperforate in rulouri sau foi de forma patrata sau dreptunghiulara de orice dimensiuni altele decat hartia de la pozitia sau  hartii si cartoane fabricate manual Altele',
    'Hartii de tipul celor utilizate ca hartie igienica servetele de demachiat  pentru sters mainile ca servetele sau hartii similare pentru uz casnic de igiena sau de toaleta vata de celuloza si straturi subtiri din fibre celulozice chiar creponate incretite gofrate stantate perforate colorate la suprafata decorate la suprafata sau imprimate in rulouri sau foi Vata de celuloza',
    'Hartii de tipul celor utilizate ca hartie igienica servetele de demachiat  pentru sters mainile ca servetele sau hartii similare pentru uz casnic de igiena sau de toaleta vata de celuloza si straturi subtiri din fibre celulozice chiar creponate incretite gofrate stantate perforate colorate la suprafata decorate la suprafata sau imprimate in rulouri sau foi De maximum gm',
    'Hartii de tipul celor utilizate ca hartie igienica servetele de demachiat  pentru sters mainile ca servetele sau hartii similare pentru uz casnic de igiena sau de toaleta vata de celuloza si straturi subtiri din fibre celulozice chiar creponate incretite gofrate stantate perforate colorate la suprafata decorate la suprafata sau imprimate in rulouri sau foi De peste gm',
    'Hartii de tipul celor utilizate ca hartie igienica servetele de demachiat  pentru sters mainile ca servetele sau hartii similare pentru uz casnic de igiena sau de toaleta vata de celuloza si straturi subtiri din fibre celulozice chiar creponate incretite gofrate stantate perforate colorate la suprafata decorate la suprafata sau imprimate in rulouri sau foi Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Cu greutatea sub gm',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Cu greutatea de minimum gm dar sub gm',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Cu greutatea de minimum gm',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Sub g',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  De minimum g',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  In care minimum  din greutatea totala a compozitiei fibroase o constituie fibrele de conifere obtinute prin procedeul chimic cu sulfat sau cu soda',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  In care minimum  din greutatea totala a compozitiei fibroase o constituie fibrele de conifere obtinute prin procedeul chimic cu sulfat sau cu soda',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Utilizate ca izolant in electrotehnica',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Albite uniform in masa',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Hartii si cartoane denumite saturating kraft',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Albite uniform in masa si la care peste  din greutatea compozitiei fibroase totale o constituie fibrele de lemn obtinute printrun procedeu chimic',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Nealbite',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Albite uniform in masa si la care peste  din greutatea compozitiei fibroase totale o constituie fibrele de lemn obtinute printrun procedeu chimic',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  In care minimum  din greutatea totala a compozitiei fibroase o constituie fibrele de conifere obtinute prin procedeul chimic cu sulfat sau cu soda',
    'Hartii si cartoane kraft necretate in rulouri sau foi altele decat cele de la pozitia sau  Altele',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Hartie semichimica pentru caneluri',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Hartie din paie pentru caneluri',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Wellenstoff',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Altele',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Cu o greutate de maximum gm',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Cu o greutate de peste gm',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Hartie sulfit pentru ambalaj',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Hartie si carton filtru',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Hartie si carton fetru hartie si carton lanos',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Cu o greutate de maximum gm',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Cu o greutate de peste gm dar sub gm',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Realizate din hartie reciclata',
    'Alte hartii si cartoane necretate in rulouri sau foi care nu au fost supuse unei prelucrari suplimentare sau unor tratamente altele decat cele stipulate la nota din acest capitol Altele',
    'Hartii si cartoane sulfurizate hartii rezistente la grasimi hartii calc hartii pergament si alte hartii glazurate transparente sau translucide in rulouri sau foi Hartii si cartoane sulfurizate pergament vegetal',
    'Hartii si cartoane sulfurizate hartii rezistente la grasimi hartii calc hartii pergament si alte hartii glazurate transparente sau translucide in rulouri sau foi Hartii rezistente la grasimi greaseproof',
    'Hartii si cartoane sulfurizate hartii rezistente la grasimi hartii calc hartii pergament si alte hartii glazurate transparente sau translucide in rulouri sau foi Hartie calc',
    'Hartii si cartoane sulfurizate hartii rezistente la grasimi hartii calc hartii pergament si alte hartii glazurate transparente sau translucide in rulouri sau foi Hartie pergament transparenta',
    'Hartii si cartoane sulfurizate hartii rezistente la grasimi hartii calc hartii pergament si alte hartii glazurate transparente sau translucide in rulouri sau foi Altele',
    'Hartii si cartoane asamblate plan prin lipire necretate la suprafata si neimpregnate chiar intarite in interior in rulouri sau coli Realizate din hartie reciclata chiar acoperite cu hartie',
    'Hartii si cartoane asamblate plan prin lipire necretate la suprafata si neimpregnate chiar intarite in interior in rulouri sau coli Altele',
    'Hartii si cartoane ondulate chiar acoperite prin lipire creponate incretite gofrate stantate sau perforate in rulouri sau in foi altele decat cele de la pozitia Hartii si cartoane ondulate chiar perforate',
    'Hartii si cartoane ondulate chiar acoperite prin lipire creponate incretite gofrate stantate sau perforate in rulouri sau in foi altele decat cele de la pozitia Hartii kraft creponate sau incretite chiar gofrate stantate sau perforate',
    'Hartii si cartoane ondulate chiar acoperite prin lipire creponate incretite gofrate stantate sau perforate in rulouri sau in foi altele decat cele de la pozitia Altele',
    'Hartii carbon hartii denumite autocopiante si alte hartii pentru copii inclusiv hartii cretate acoperite sau impregnate pentru hartii stencil sau placi offset chiar imprimate in rulouri sau foi Hartii autocopiante',
    'Hartii carbon hartii denumite autocopiante si alte hartii pentru copii inclusiv hartii cretate acoperite sau impregnate pentru hartii stencil sau placi offset chiar imprimate in rulouri sau foi Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni In rulouri',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni In foi la care una dintre laturi este de maximum mm si cealalta de maximum mm in stare nepliata',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Hartie usor cretata denumita LWC',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni In rulouri',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Albite uniform in masa si la care peste  din greutatea totala a compozitiei fibroase o constituie fibrele de lemn obtinute printrun procedeu chimic avand o greutate de maximum gm',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Cretate sau acoperite cu caolin',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni La care fiecare strat este albit',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni La care un singur strat este albit',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Din pasta albita acoperite sau impregnate cu caolin',
    'Hartii si cartoane cretate cu caolin sau cu alte substante anorganice pe una sau pe ambele fete cu sau fara lianti fara alte stratificari sau aplicari la suprafata chiar colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara de orice dimensiuni Altele',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Hartii si cartoane gudronate bituminate sau asfaltate',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Cu o latime de maximum cm a caror acoperire consta in cauciuc natural sau sintetic nevulcanizat',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Altele',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Altele',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Albite cu o greutate de peste gm',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Altele',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Hartii si cartoane cretate impregnate sau acoperite cu ceara parafina stearina ulei sau glicerol',
    'Hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice cretate impregnate acoperite colorate la suprafata decorate la suprafata sau imprimate in rulouri sau in foi de forma patrata sau dreptunghiulara avand orice dimensiuni altele decat produsele de tipul celor descrise la pozitiile  sau  Alte hartii si cartoane vata de celuloza si straturi subtiri din fibre celulozice',
    'Blocuri filtrante si placi filtrante din pasta de hartie Blocuri filtrante si placi filtrante din pasta de hartie',
    'Hartie pentru tigari chiar taiata la dimensiune sub forma de colite sau sub forma tubulara Sub forma de colite sau sub forma tubulara',
    'Hartie pentru tigari chiar taiata la dimensiune sub forma de colite sau sub forma tubulara In rulouri cu o latime maxima de cm',
    'Hartie pentru tigari chiar taiata la dimensiune sub forma de colite sau sub forma tubulara In rulouri cu o latime de peste cm dar de maximum cm',
    'Hartie pentru tigari chiar taiata la dimensiune sub forma de colite sau sub forma tubulara Altele',
    'Hartii tapet si hartii similare pentru acoperit peretii hartii transparente autocolante Hartii tapet si hartii similare pentru acoperit peretii constituite din hartie impregnata sau acoperita pe fata cu un strat de material plastic gofrat colorat imprimat cu motive sau altfel decorat',
    'Hartii tapet si hartii similare pentru acoperit peretii hartii transparente autocolante Hartii tapet si hartii similare pentru acoperit pereti constituite din hartie gofrata colorata la suprafata imprimata cu motive sau altfel decorata la suprafata impregnata sau acoperita cu material plastic protector transparent',
    'Hartii tapet si hartii similare pentru acoperit peretii hartii transparente autocolante Altele',
    'Hartii carbon hartii denumite autocopiante si alte hartii copiative altele decat cele de la pozitia hartii stencil si placi offset din hartie chiar ambalate in cutii Hartii denumite autocopiante',
    'Hartii carbon hartii denumite autocopiante si alte hartii copiative altele decat cele de la pozitia hartii stencil si placi offset din hartie chiar ambalate in cutii Altele',
    'Plicuri hartie in forma de plicuri carti postale neilustrate si carti postale pentru corespondenta din hartie sau carton cutii mape si articole similare din hartie sau carton care contin un set de articole pentru corespondenta Plicuri',
    'Plicuri hartie in forma de plicuri carti postale neilustrate si carti postale pentru corespondenta din hartie sau carton cutii mape si articole similare din hartie sau carton care contin un set de articole pentru corespondenta Hartie in forma de plic carti postale neilustrate si carti postale pentru corespondenta',
    'Plicuri hartie in forma de plicuri carti postale neilustrate si carti postale pentru corespondenta din hartie sau carton cutii mape si articole similare din hartie sau carton care contin un set de articole pentru corespondenta Cutii mape si articole similare din hartie sau din carton care contin un set de articole pentru corespondenta',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Cu greutatea de maximum gm pe strat',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Cu greutatea de peste gm pe strat',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Batiste si servetele demachiante',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice In rulouri',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Altele',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Fete de masa si servete de masa',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Imbracaminte si accesorii de imbracaminte',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Articole de uz chirurgical medical sau igienic neconditionate pentru vanzarea cu amanuntul',
    'Hartii de tipul celor utilizate ca hartie igienica si ca hartii similare vata de celuloza sau straturi subtiri din fibre celulozice de tipul celor pentru uz casnic sau sanitar in rulouri cu o latime de maximum cm sau taiate la dimensiune batiste servetele demachiante hartie pentru sters mainile fete de masa si servete lenjerie de pat si articole similare pentru uz casnic de toaleta pentru uz igienic sau spitalicesc imbracaminte si accesorii de imbracaminte din pasta de hartie hartie vata de celuloza sau straturi subtiri din fibre celulozice Altele',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Cutii din hartie sau carton ondulat',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Cutii si obiecte din carton pliante din hartie sau carton neondulat',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Saci cu latimea bazei de minimum cm',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Alti saci pungi mape altele decat cele pentru discuri si cornete',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Alte ambalaje inclusiv mape pentru discuri',
    'Cutii saci pungi cornete si alte ambalaje din hartie carton vata de celuloza sau straturi subtiri din fibre celulozice obiecte din carton pentru birou pentru magazine sau similare Obiecte din carton pentru birou pentru magazine si similare',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Registre registre contabile si carnete de comenzi sau de chitante',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Carnete de note blocuri de hartie pentru scrisori si blocnotesuri',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Agende',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Altele',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Caiete',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Bibliorafturi carnete cu cleme metalice altele decat copertele de carte plicuri si coperte pentru dosare',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Albume pentru esantioane sau pentru colectii',
    'Registre registre contabile carnete de note de comenzi de chitante agende blocnotesuri blocuri de hartie pentru scrisori si articole similare caiete mape bibliorafturi carnete cu cleme metalice cu foi detasabile sau altele coperte pentru dosare sau alte articole scolare de birou sau de papetarie inclusiv formulare pentru diferite activitati si carnete diverse chiar cu foi intercalate de hartie carbon din hartie sau din carton albume pentru esantioane sau pentru colectii si coperte pentru carti din hartie sau carton Altele',
    'Etichete de toate genurile din hartie sau carton imprimate sau nu Autoadezive',
    'Etichete de toate genurile din hartie sau carton imprimate sau nu Altele',
    'Etichete de toate genurile din hartie sau carton imprimate sau nu Autoadezive',
    'Etichete de toate genurile din hartie sau carton imprimate sau nu Altele',
    'Tamburi bobine fuzete canete mosoare si suporturi similare din pasta de hartie hartie sau carton chiar perforate sau intarite De tipul celor folosite pentru infasurarea firelor textile',
    'Tamburi bobine fuzete canete mosoare si suporturi similare din pasta de hartie hartie sau carton chiar perforate sau intarite Altele',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Hartie si carton de filtru',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Hartii cu diagrame pentru aparatele de inregistrat sub forma de bobine foi sau discuri',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Din bambus',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Tavi vesela farfurii',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Altele',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Ambalaje alveolare pentru oua',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Altele',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Hartie si carton de tipul celor folosite pentru scris tiparit sau pentru alte scopuri grafice',
    'Alte hartii cartoane vata de celuloza si straturi subtiri din fibre celulozice decupate la dimensiune alte articole din pasta de hartie din hartie din carton din vata de celuloza sau din straturi subtiri din fibre celulozice Altele',
    'Carti brosuri si tiparituri similare chiar in foi volante In foi volante chiar pliate',
    'Carti brosuri si tiparituri similare chiar in foi volante Dictionare si enciclopedii chiar in fascicule',
    'Carti brosuri si tiparituri similare chiar in foi volante Altele',
    'Ziare si publicatii periodice imprimate chiar ilustrate sau care contin material publicitar Cu cel putin patru aparitii pe saptamana',
    'Ziare si publicatii periodice imprimate chiar ilustrate sau care contin material publicitar Altele',
    'Albume sau carti cu ilustratii si carti de desenat sau colorat pentru copii Albume sau carti cu ilustratii si carti de desenat sau colorat pentru copii',
    'Partituri muzicale manuscrise sau imprimate chiar ilustrate sau legate Partituri muzicale manuscrise sau imprimate chiar ilustrate sau legate',
    'Lucrari cartografice de orice fel inclusiv harti de perete planuri topografice si globuri imprimate Sub forma de carti sau de brosuri',
    'Lucrari cartografice de orice fel inclusiv harti de perete planuri topografice si globuri imprimate Altele',
    'Planuri si desene de arhitectura de inginerie si alte planuri si desene industriale comerciale topografice sau similare originale executate manual texte manuscrise reproduceri fotografice pe hartie sensibilizata si copii carbon obtinute dupa planurile desenele sau textele enumerate mai sus Planuri si desene de arhitectura de inginerie si alte planuri si desene industriale comerciale topografice sau similare originale executate manual texte manuscrise reproduceri fotografice pe hartie sensibilizata si copii carbon obtinute dupa planurile desenele sau textele enumerate mai sus',
    'Timbre postale timbre fiscale si similare neobliterate in circulatie sau urmand a fi puse in circulatie in tara in care ele au sau vor avea o valoare nominala recunoscuta hartie timbrata bancnote cecuri titluri de actiuni sau  obligatiuni si titluri similare Timbre postale timbre fiscale si similare',
    'Timbre postale timbre fiscale si similare neobliterate in circulatie sau urmand a fi puse in circulatie in tara in care ele au sau vor avea o valoare nominala recunoscuta hartie timbrata bancnote cecuri titluri de actiuni sau  obligatiuni si titluri similare Bancnote',
    'Timbre postale timbre fiscale si similare neobliterate in circulatie sau urmand a fi puse in circulatie in tara in care ele au sau vor avea o valoare nominala recunoscuta hartie timbrata bancnote cecuri titluri de actiuni sau  obligatiuni si titluri similare Altele',
    'Decalcomanii abtibilduri de orice fel Decalcomanii vitrifiabile',
    'Decalcomanii abtibilduri de orice fel Altele',
    'Carti postale imprimate sau ilustrate carti postale cu felicitari sau mesaje personale chiar ilustrate cu sau fara plicuri garnituri sau aplicatii Carti postale imprimate sau ilustrate carti postale cu felicitari sau mesaje personale chiar ilustrate cu sau fara plicuri garnituri sau aplicatii',
    'Calendare de orice fel imprimate inclusiv calendare cu file detasabile Calendare de orice fel imprimate inclusiv calendare cu file detasabile',
    'Alte imprimate inclusiv imagini gravuri si fotografii Cataloage comerciale',
    'Alte imprimate inclusiv imagini gravuri si fotografii Altele',
    'Alte imprimate inclusiv imagini gravuri si fotografii Imagini gravuri si fotografii',
    'Alte imprimate inclusiv imagini gravuri si fotografii Altele',
    'Gogosi de viermi de matase de pe care se pot depana fire Gogosi de viermi de matase de pe care se pot depana fire',
    'Matase bruta nerasucita Matase bruta nerasucita',
    'Deseuri de matase inclusiv gogosi nedepanabile deseuri de fire si destramatura Deseuri de matase inclusiv gogosi nedepanabile deseuri de fire si destramatura',
    'Fire de matase altele decat firele din deseuri de matase neconditionate pentru vanzarea cu amanuntul Nealbite degomate sau albite',
    'Fire de matase altele decat firele din deseuri de matase neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din deseuri de matase neconditionate pentru vanzarea cu amanuntul Nealbite degomate sau albite',
    'Fire din deseuri de matase neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire de matase sau din deseuri de matase conditionate pentru vanzarea cu amanuntul intestine de viermi de matase Fire de matase',
    'Fire de matase sau din deseuri de matase conditionate pentru vanzarea cu amanuntul intestine de viermi de matase Fire de deseuri de matase intestine de viermi de matase',
    'Tesaturi din matase sau din deseuri de matase Tesaturi din pieptanatura de matase',
    'Tesaturi din matase sau din deseuri de matase Nealbite degomate sau albite',
    'Tesaturi din matase sau din deseuri de matase Altele',
    'Tesaturi din matase sau din deseuri de matase Tesatura panza nealbita sau simplu degomata',
    'Tesaturi din matase sau din deseuri de matase Tesatura panza',
    'Tesaturi din matase sau din deseuri de matase Altele',
    'Tesaturi din matase sau din deseuri de matase Tesaturi transparente rare',
    'Tesaturi din matase sau din deseuri de matase Nealbite degomate sau albite',
    'Tesaturi din matase sau din deseuri de matase Vopsite',
    'Tesaturi din matase sau din deseuri de matase Din fire de diferite culori',
    'Tesaturi din matase sau din deseuri de matase Imprimate',
    'Tesaturi din matase sau din deseuri de matase Nealbite degomate sau albite',
    'Tesaturi din matase sau din deseuri de matase Vopsite',
    'Tesaturi din matase sau din deseuri de matase Din fire de diferite culori',
    'Tesaturi din matase sau din deseuri de matase Imprimate',
    'Lana necardata nepieptanata Lana tunsa',
    'Lana necardata nepieptanata Altele',
    'Lana necardata nepieptanata Lana tunsa',
    'Lana necardata nepieptanata Altele',
    'Lana necardata nepieptanata Carbonizata',
    'Par fin sau grosier necardat si nepieptanat De capra de Casmir',
    'Par fin sau grosier necardat si nepieptanat De iepure de Angora',
    'Par fin sau grosier necardat si nepieptanat De alpaca de lama de vicuna',
    'Par fin sau grosier necardat si nepieptanat De camila inclusiv dromader de iac de capra de Angora de capra de Tibet si de capre similare',
    'Par fin sau grosier necardat si nepieptanat De alti iepuri decat cei de Angora de iepure salbatic de castor de nutrie si de bizam',
    'Par fin sau grosier necardat si nepieptanat Par grosier',
    'Deseuri de lana sau de par fin sau grosier de animale inclusiv deseuri de fire cu exceptia destramaturii Necarbonizate',
    'Deseuri de lana sau de par fin sau grosier de animale inclusiv deseuri de fire cu exceptia destramaturii Carbonizate',
    'Deseuri de lana sau de par fin sau grosier de animale inclusiv deseuri de fire cu exceptia destramaturii Alte deseuri de lana sau de par fin de animale',
    'Deseuri de lana sau de par fin sau grosier de animale inclusiv deseuri de fire cu exceptia destramaturii Deseuri de par grosier de animale',
    'Destramatura de lana sau de par fin sau grosier de animale Destramatura de lana sau de par fin sau grosier de animale',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac Lana cardata',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac Lana pieptanata in vrac',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac Altele',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac De capra de Casmir',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac Altele',
    'Lana par fin sau grosier cardate sau pieptanate inclusiv lana pieptanata in vrac Par grosier pieptanat sau cardat',
    'Fire din lana cardata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana cardata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana cardata neconditionate pentru vanzarea cu amanuntul Cu un continut de lana si de par fin de animale de minimum  din greutate',
    'Fire din lana cardata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana cardata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din lana pieptanata neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din par fin de animale cardate sau pieptanate neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din par fin de animale cardate sau pieptanate neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din par fin de animale cardate sau pieptanate neconditionate pentru vanzarea cu amanuntul Nealbite',
    'Fire din par fin de animale cardate sau pieptanate neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana sau din par fin de animale conditionate pentru vanzarea cu amanuntul In gheme sculuri sau jurubite cu o greutate de peste g dar de maximum g',
    'Fire din lana sau din par fin de animale conditionate pentru vanzarea cu amanuntul Altele',
    'Fire din lana sau din par fin de animale conditionate pentru vanzarea cu amanuntul Altele',
    'Fire din par grosier sau din par de cal inclusiv fire din par de cal imbracate chiar conditionate pentru vanzarea cu amanuntul Fire din par grosier sau din par de cal inclusiv fire din par de cal imbracate chiar conditionate pentru vanzarea cu amanuntul',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu o greutate de maximum gm',
    'Tesaturi din lana cardata sau din par fin de animale cardat Altele',
    'Tesaturi din lana cardata sau din par fin de animale cardat Altele amestecate in principal sau numai cu filamente sintetice sau artificiale',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu o greutate de maximum gm',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu o greutate de peste gm',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu un continut total de materiale textile de la capitolul de peste  din greutate',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu o greutate de maximum gm',
    'Tesaturi din lana cardata sau din par fin de animale cardat Cu o greutate de peste gm',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu o greutate maxima de gm',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Altele',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Altele amestecate in principal sau numai cu filamente sintetice sau artificiale',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu o greutate de maximum gm',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu o greutate de peste gm',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu un continut total de materiale textile de la capitolul de peste  din greutate',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu o greutate de maximum gm',
    'Tesaturi din lana pieptanata sau din par fin de animale pieptanat Cu o greutate de peste gm',
    'Tesaturi din par grosier de animale sau din par de cal Tesaturi din par grosier de animale sau din par de cal',
    'Bumbac necardat si nepieptanat Hidrofil sau albit',
    'Bumbac necardat si nepieptanat Altele',
    'Deseuri de bumbac inclusiv deseuri de fire de bumbac si destramatura Deseuri de fire',
    'Deseuri de bumbac inclusiv deseuri de fire de bumbac si destramatura Destramatura',
    'Deseuri de bumbac inclusiv deseuri de fire de bumbac si destramatura Altele',
    'Bumbac cardat sau pieptanat Bumbac cardat sau pieptanat',
    'Ata de cusut din bumbac chiar conditionata pentru vanzarea cu amanuntul Care contine bumbac minimum  din greutate',
    'Ata de cusut din bumbac chiar conditionata pentru vanzarea cu amanuntul Altele',
    'Ata de cusut din bumbac chiar conditionata pentru vanzarea cu amanuntul Conditionate pentru vanzarea cu amanuntul',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand minimum decitex maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex peste numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand minimum decitex maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex peste numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu minimum decitex maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex peste numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu minimum decitex maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac minimum  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex peste numere metrice fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand minimum decitex maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex peste numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand minimum decitex maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand sub decitex peste numere metrice',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu minimum decitex maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex peste numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu minimum decitex maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex dar minimum decitex peste numere metrice pe fir simplu dar maximum numere metrice pe fir simplu',
    'Fire din bumbac altele decat ata de cusut care contin bumbac sub  din greutate neconditionate pentru vanzarea cu amanuntul Masurand pe fir simplu sub decitex peste numere metrice pe fir simplu',
    'Fire de bumbac altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Care contin bumbac minimum  din greutate',
    'Fire de bumbac altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Altele',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Materiale pentru fabricarea feselor a pansamentelor si a tifoanelor medicale',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Altele',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Materiale pentru fabricarea feselor a pansamentelor si a tifoanelor medicale',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Altele',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror  raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura panza cu o greutate de maximum gm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De maximum cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm De peste cm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura panza cu o greutate de maximum gm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura panza cu o greutate de peste gm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura panza cu o greutate de maximum gm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura panza cu o greutate de peste gm',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport cu legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate cu o greutate de maximum gm Altele',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura diagonala sau incrucisata al  caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Tesaturi denim',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi cu legatura diagonala sau incrucisata al  caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac minimum  din greutate avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Albite',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Tesatura denim',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Alte tesaturi cu legatura diagonala sau incrucisata al caror  raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Tesaturi Jacquard',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Altele',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura panza',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Cu legatura diagonala sau incrucisata al caror raport de legatura este maximum',
    'Tesaturi din bumbac care contin bumbac sub  din greutate amestecate in principal sau numai cu fibre sintetice sau artificiale avand o greutate de peste gm Alte tesaturi',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'Alte tesaturi din bumbac In amestec in principal sau numai cu in',
    'Alte tesaturi din bumbac In altfel de amestecuri',
    'In brut sau prelucrat dar nefilat calti si deseuri din in inclusiv deseuri din fire si destramatura In brut sau topit',
    'In brut sau prelucrat dar nefilat calti si deseuri din in inclusiv deseuri din fire si destramatura Zdrobit sau melitat',
    'In brut sau prelucrat dar nefilat calti si deseuri din in inclusiv deseuri din fire si destramatura Altele',
    'In brut sau prelucrat dar nefilat calti si deseuri din in inclusiv deseuri din fire si destramatura Calti si deseuri din in',
    'Canepa Cannabis sativa L bruta sau prelucrata dar nefilata calti si deseuri din canepa inclusiv deseuri din fire si destramatura Canepa bruta sau topita',
    'Canepa Cannabis sativa L bruta sau prelucrata dar nefilata calti si deseuri din canepa inclusiv deseuri din fire si destramatura Altele',
    'Iuta si alte fibre textile liberiene exceptand inul canepa si ramia brute sau prelucrate dar nefilate calti si deseuri din aceste fibre inclusiv deseuri din fire si destramatura Iuta si alte fibre textile liberiene brute sau topite',
    'Iuta si alte fibre textile liberiene exceptand inul canepa si ramia brute sau prelucrate dar nefilate calti si deseuri din aceste fibre inclusiv deseuri din fire si destramatura Altele',
    'Fibre din nuca de cocos de abaca canepa de Manila sau Musa textilis Nee ramia sau alte fibre textile vegetale nedenumite si necuprinse in alta parte brute sau prelucrate dar nefilate calti si deseuri din aceste fibre inclusiv deseuri din fire si destramatura Fibre din nuca de cocos de abaca canepa de Manila sau Musa textilis Nee ramia sau alte fibre textile vegetale nedenumite si necuprinse in alta parte brute sau prelucrate dar nefilate calti si deseuri din aceste fibre inclusiv deseuri din fire si destramatura',
    'Fire din in Masurand minimum decitex maximum  numere metrice',
    'Fire din in Masurand sub decitex dar minimum decitex peste  numere metrice dar maximum  numere metrice',
    'Fire din in Masurand sub decitex peste  numere metrice',
    'Fire din in Conditionate pentru vanzarea cu amanuntul',
    'Fire din in Neconditionate pentru vanzarea cu amanuntul',
    'Fire din in Conditionate pentru vanzarea cu amanuntul',
    'Fire din iuta sau din alte fibre textile liberiene de la pozitia Simple',
    'Fire din iuta sau din alte fibre textile liberiene de la pozitia Rasucite sau cablate',
    'Fire din alte fibre textile vegetale fire din hartie Fire din nuca de cocos',
    'Fire din alte fibre textile vegetale fire din hartie Neconditionate pentru vanzarea cu amanuntul',
    'Fire din alte fibre textile vegetale fire din hartie Conditionate pentru vanzarea cu amanuntul',
    'Fire din alte fibre textile vegetale fire din hartie Masurand minimum decitex maximum  numere metrice',
    'Fire din alte fibre textile vegetale fire din hartie Masurand sub decitex peste  numere metrice',
    'Fire din alte fibre textile vegetale fire din hartie Fire din hartie',
    'Fire din alte fibre textile vegetale fire din hartie Altele',
    'Tesaturi din in Nealbite',
    'Tesaturi din in Albite',
    'Tesaturi din in Altele',
    'Tesaturi din in Nealbite sau albite',
    'Tesaturi din in Altele',
    'Tesaturi din iuta sau din alte fibre textile liberiene de la pozitia Cu o latime de maximum cm',
    'Tesaturi din iuta sau din alte fibre textile liberiene de la pozitia Cu o latime de peste cm',
    'Tesaturi din iuta sau din alte fibre textile liberiene de la pozitia Altele',
    'Tesaturi din alte fibre textile vegetale tesaturi din fire de hartie Tesaturi din ramie',
    'Tesaturi din alte fibre textile vegetale tesaturi din fire de hartie Altele',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Filamente din poliester infasurate in fibre de bumbac',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Altele',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Fire texturate',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Altele',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Conditionate pentru vanzarea cu amanuntul',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Neconditionate pentru vanzarea cu amanuntul',
    'Ata de cusut din filamente sintetice sau artificiale chiar conditionata pentru vanzarea cu amanuntul Conditionate pentru vanzarea cu amanuntul',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din aramide',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Fire de mare rezistenta din poliesteri  chiar texturate',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din nailon sau din alte poliamide cu grosimea firului simplu de maximum tex',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din nailon sau din alte poliamide cu grosimea firului simplu de peste tex',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din poliesteri',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din polipropilena',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din elastomeri',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele din nailon sau din alte poliamide',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele din poliesteri partial orientati',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele din poliesteri',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele din polipropilena',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din nailon sau din alte poliamide',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din poliesteri',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din polipropilena',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din nailon sau din alte poliamide',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din poliesteri',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Din polipropilena',
    'Fire din filamente sintetice altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele sintetice sub decitex Altele',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Fire de mare rezistenta din filamente de viscoza',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Din filamente de viscoza nerasucite sau avand maximum rasuciturim',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Din filamente de viscoza avand peste  de rasuciturim',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Din acetat de celuloza',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Altele',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Din filamente de viscoza',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Din acetat de celuloza',
    'Fire din filamente artificiale altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul inclusiv monofilamentele artificiale avand sub decitex Altele',
    'Monofilamente sintetice de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu paie artificiale din materiale textile sintetice cu o latime aparenta de maximum mm Din elastomeri',
    'Monofilamente sintetice de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu paie artificiale din materiale textile sintetice cu o latime aparenta de maximum mm Altele din polipropilena',
    'Monofilamente sintetice de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu paie artificiale din materiale textile sintetice cu o latime aparenta de maximum mm Altele',
    'Monofilamente sintetice de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu paie artificiale din materiale textile sintetice cu o latime aparenta de maximum mm Din polipropilena',
    'Monofilamente sintetice de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu paie artificiale din materiale textile sintetice cu o latime aparenta de maximum mm Altele',
    'Monofilamente artificiale de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu pai artificial din materiale textile artificiale cu o latime aparenta de maximum mm Monofilamente artificiale de minimum decitex care au cea mai mare dimensiune a sectiunii transversale de maximum mm benzi si forme similare de exemplu pai artificial din materiale textile artificiale cu o latime aparenta de maximum mm',
    'Fire din filamente sintetice sau artificiale altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Fire din filamente sintetice sau artificiale altele decat ata de cusut conditionate pentru vanzarea cu amanuntul',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Tesaturi obtinute din fire de mare rezistenta din nailon sau din alte poliamide sau poliesteri',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Sub m',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia De minimum m',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Altele',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Tesaturi prevazute la nota de la sectiuneaXI',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Altele',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Vopsite',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente sintetice inclusiv tesaturile obtinute din produsele de la pozitia Imprimate',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Tesaturi obtinute din fire de mare tenacitate din filamente de viscoza',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Cu latimea de peste cm dar de maximum cm cu legatura panza diagonala diagonala incrucisata sau satin',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Altele',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Imprimate',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Nealbite sau albite',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Vopsite',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Din fire de diferite culori',
    'Tesaturi din fire de filamente artificiale inclusiv tesaturi obtinute din produsele mentionate la pozitia Imprimate',
    'Cabluri din filamente sintetice Din aramide',
    'Cabluri din filamente sintetice Altele',
    'Cabluri din filamente sintetice Din poliesteri',
    'Cabluri din filamente sintetice Acrilice sau modacrilice',
    'Cabluri din filamente sintetice Din polipropilena',
    'Cabluri din filamente sintetice Altele',
    'Cabluri din filamente artificiale Din acetat de celuloza',
    'Cabluri din filamente artificiale Altele',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Din aramide',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Altele',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Din poliesteri',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Acrilice sau modacrilice',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Din polipropilena',
    'Fibre sintetice discontinue necardate si nepieptanate si nici altfel prelucrate pentru filare Altele',
    'Fibre artificiale discontinue necardate si nepieptanate nici altfel prelucrate pentru filare Din viscoza',
    'Fibre artificiale discontinue necardate si nepieptanate nici altfel prelucrate pentru filare Altele',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Din nailon sau din alte poliamide',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Din poliesteri',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Acrilice sau modacrilice',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Din polipropilena',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Altele',
    'Deseuri din fibre sintetice sau artificiale inclusiv pieptanatura deseuri de fire si destramatura Din fibre artificiale',
    'Fibre sintetice discontinue cardate pieptanate sau altfel prelucrate pentru filare Din nailon sau din alte poliamide',
    'Fibre sintetice discontinue cardate pieptanate sau altfel prelucrate pentru filare Din poliesteri',
    'Fibre sintetice discontinue cardate pieptanate sau altfel prelucrate pentru filare Acrilice sau modacrilice',
    'Fibre sintetice discontinue cardate pieptanate sau altfel prelucrate pentru filare Din polipropilena',
    'Fibre sintetice discontinue cardate pieptanate sau altfel prelucrate pentru filare Altele',
    'Fibre artificiale discontinue cardate pieptanate sau altfel prelucrate pentru filare Fibre artificiale discontinue cardate pieptanate sau altfel prelucrate pentru filare',
    'Ata de cusut din fibre sintetice sau artificiale discontinue chiar conditionata pentru vanzarea cu amanuntul Neconditionate pentru vanzarea cu amanuntul',
    'Ata de cusut din fibre sintetice sau artificiale discontinue chiar conditionata pentru vanzarea cu amanuntul Conditionate pentru vanzarea cu amanuntul',
    'Ata de cusut din fibre sintetice sau artificiale discontinue chiar conditionata pentru vanzarea cu amanuntul Neconditionate pentru vanzarea cu amanuntul',
    'Ata de cusut din fibre sintetice sau artificiale discontinue chiar conditionata pentru vanzarea cu amanuntul Conditionate pentru vanzarea cu amanuntul',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Simple',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Rasucite sau cablate',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Simple',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Rasucite sau cablate',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Simple',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Rasucite sau cablate',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Simple',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Rasucite sau cablate',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu fibre artificiale discontinue',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu lana sau par fin de animale',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu bumbac',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu lana sau cu par fin de animale',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu bumbac',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu lana sau par fin de animale',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Amestecate in principal sau numai cu bumbac',
    'Fire din fibre sintetice discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Altele',
    'Fire din fibre artificiale discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Simple',
    'Fire din fibre artificiale discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Rasucite sau cablate',
    'Fire din fibre artificiale discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Alte fire amestecate in principal sau numai cu lana sau par fin de animale',
    'Fire din fibre artificiale discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Alte fire amestecate in principal sau numai cu bumbac',
    'Fire din fibre artificiale discontinue altele decat ata de cusut neconditionate pentru vanzarea cu amanuntul Alte fire',
    'Fire din fibre sintetice sau artificiale discontinue altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Din fibre sintetice discontinue care contin minimum  din greutate astfel de fibre',
    'Fire din fibre sintetice sau artificiale discontinue altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Din fibre sintetice discontinue care contin sub  din greutate astfel de fibre',
    'Fire din fibre sintetice sau artificiale discontinue altele decat ata de cusut conditionate pentru vanzarea cu amanuntul Din fibre artificiale discontinue',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Nealbite sau albite',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Imprimate',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Altele',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Nealbite sau albite',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Imprimate',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Altele',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Nealbite sau albite',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Imprimate',
    'Tesaturi din fibre sintetice discontinue care contin minimum  din greutate fibre sintetice discontinue Altele',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Cu o latime de maximum cm',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Cu o latime de peste cm',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Din fibre discontinue de poliester cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Alte tesaturi din fibre discontinue de poliester',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Altele',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de maximum gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Altele',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi din fibre discontinue de poliester',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi din fibre discontinue de poliester',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura panza',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Din fibre discontinue de poliester cu legatura diagonala sau incrucisata al caror raport de legatura este de maximum',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi din fibre discontinue de poliester',
    'Tesaturi din fibre sintetice discontinue care contin sub  din greutate astfel de fibre amestecate in principal sau numai cu bumbac avand o greutate de peste gm Alte tesaturi',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Alte tesaturi din fibre sintetice discontinue Nealbite sau albite',
    'Alte tesaturi din fibre sintetice discontinue Imprimate',
    'Alte tesaturi din fibre sintetice discontinue Altele',
    'Tesaturi din fibre artificiale discontinue Nealbite sau albite',
    'Tesaturi din fibre artificiale discontinue Vopsite',
    'Tesaturi din fibre artificiale discontinue Din fire de diferite culori',
    'Tesaturi din fibre artificiale discontinue Imprimate',
    'Tesaturi din fibre artificiale discontinue Nealbite sau albite',
    'Tesaturi din fibre artificiale discontinue Vopsite',
    'Tesaturi din fibre artificiale discontinue Tesaturi Jacquard cu o latime de minimum cm panza de saltele',
    'Tesaturi din fibre artificiale discontinue Altele',
    'Tesaturi din fibre artificiale discontinue Imprimate',
    'Tesaturi din fibre artificiale discontinue Nealbite sau albite',
    'Tesaturi din fibre artificiale discontinue Vopsite',
    'Tesaturi din fibre artificiale discontinue Din fire de diferite culori',
    'Tesaturi din fibre artificiale discontinue Imprimate',
    'Tesaturi din fibre artificiale discontinue Nealbite sau albite',
    'Tesaturi din fibre artificiale discontinue Vopsite',
    'Tesaturi din fibre artificiale discontinue Din fire de diferite culori',
    'Tesaturi din fibre artificiale discontinue Imprimate',
    'Tesaturi din fibre artificiale discontinue Nealbite sau albite',
    'Tesaturi din fibre artificiale discontinue Vopsite',
    'Tesaturi din fibre artificiale discontinue Din fire de diferite culori',
    'Tesaturi din fibre artificiale discontinue Imprimate',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Hidrofila',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Altele',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Rulouri cu un diametru de maximum mm',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Altele',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Altele',
    'Vata din materiale textile si articole din aceasta fibre textile cu o lungime de maximum mm fire tunse de pe postav noduri si nopeuri butoni din materiale textile Fire tunse de pe postav noduri si nopeuri butoni din materiale textile',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din iuta sau din altfel de fibre textile liberiene de la pozitia',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din alte materiale textile',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din lana sau par fin de animale',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din alte materiale textile',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Impregnate imbracate acoperite sau stratificate',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din lana sau din par fin de animale',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Din alte materiale textile',
    'Pasla chiar impregnata imbracata acoperita sau stratificata Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Foaie suport din poliester mentionata in nota complementara  din acest capitol',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Imbracate sau acoperite',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Foaie suport din poliester mentionata in nota complementara',
    'Articole netesute chiar impregnate imbracate acoperite sau stratificate Altele',
    'Fire si corzi din cauciuc acoperite cu materiale textile fire textile benzi si forme similare de la pozitia sau  impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Fire si corzi din cauciuc acoperite cu materiale textile',
    'Fire si corzi din cauciuc acoperite cu materiale textile fire textile benzi si forme similare de la pozitia sau  impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Fire de mare tenacitate din poliester din nailon sau din alte poliamide sau din viscoza impregnate sau imbracate',
    'Fire si corzi din cauciuc acoperite cu materiale textile fire textile benzi si forme similare de la pozitia sau  impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Altele',
    'Fire metalizate chiar imbracate constituite din fire textile benzi sau forme similare de la pozitia sau  combinate cu metal sub forma de fire benzi sau pulbere sau acoperite cu metal Fire metalizate chiar imbracate constituite din fire textile benzi sau forme similare de la pozitia sau  combinate cu metal sub forma de fire benzi sau pulbere sau acoperite cu metal',
    'Fire imbracate benzi si forme similare de la pozitia sau  imbracate altele decat cele de la pozitia si altele decat firele de par de cal imbracate fire tip chenille omida fire cu bucleuri Fire cu bucleuri',
    'Fire imbracate benzi si forme similare de la pozitia sau  imbracate altele decat cele de la pozitia si altele decat firele de par de cal imbracate fire tip chenille omida fire cu bucleuri Fire imbracate',
    'Fire imbracate benzi si forme similare de la pozitia sau  imbracate altele decat cele de la pozitia si altele decat firele de par de cal imbracate fire tip chenille omida fire cu bucleuri Altele',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Sfori de legat',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Altele',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Sfori pentru legat',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Impletite',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Altele',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Masurand maximum decitex gm',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Impletite',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Altele',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Masurand maximum decitex gm',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Din alte fibre sintetice',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Din abaca canepa de Manila sau Musa textilis Nee sau alte fibre din frunze dure din iuta sau din alte fibre textile liberiene de la pozitia',
    'Sfori funii si franghii impletite sau nu chiar impregnate imbracate acoperite sau invelite cu cauciuc sau material plastic Altele',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Din sfori funii sau franghii',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Altele',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Din sfori funii sau franghii',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Altele',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Altele',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Altele',
    'Plase cu ochiuri innodate din sfori funii sau franghii plase confectionate pentru pescuit si alte plase confectionate din materiale textile Altele',
    'Articole din fire benzi sau forme similare de la pozitia sau  sfori funii sau franghii nedenumite si necuprinse in alta parte Articole din fire benzi sau forme similare de la pozitia sau  sfori funii sau franghii nedenumite si necuprinse in alta parte',
    'Covoare si alte acoperitoare de podea din materiale textile cu puncte innodate sau infasurate chiar confectionate Cu un continut de matase sau de deseuri de matase altele decat pieptanatura de matase de peste  din greutatea totala',
    'Covoare si alte acoperitoare de podea din materiale textile cu puncte innodate sau infasurate chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din materiale textile cu puncte innodate sau infasurate chiar confectionate Din matase deseuri de matase altele decat pieptanatura de matase fibre sintetice fire de la pozitia sau din materiale textile care contin fire de metal',
    'Covoare si alte acoperitoare de podea din materiale textile cu puncte innodate sau infasurate chiar confectionate Din alte materiale textile',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Covoare denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Acoperitoare de podea din fibre de nuci de cocos',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Covoare Axminster',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Altele',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din materiale textile sintetice sau artificiale',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din alte materiale textile',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Covoare Axminster',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Altele',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din materiale textile sintetice sau artificiale',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din alte materiale textile',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din lana sau din par fin de animale',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din polipropilena',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Altele',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din alte materiale textile',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din lana sau din par fin de animale',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din polipropilena',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Altele',
    'Covoare si alte acoperitoare de podea din materiale textile tesute fara smocuri sau suvite chiar confectionate inclusiv covoarele denumite Kelim sau Kilim Schumacks sau Soumak Karamanie si covoare similare tesute manual Din alte materiale textile',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Din lana sau din par fin de animale',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Gazon',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Covorase a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Covorase a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Gazon',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Covorase a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Covorase a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Covorase a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din materiale textile inclusiv gazon cu smocuri chiar confectionate Altele',
    'Covoare si alte acoperitoare de podea din pasla fara smocuri sau suvite chiar confectionate Carpete a caror suprafata este de maximum m',
    'Covoare si alte acoperitoare de podea din pasla fara smocuri sau suvite chiar confectionate Carpete a caror suprafata este de peste m dar nu mai mult de m',
    'Covoare si alte acoperitoare de podea din pasla fara smocuri sau suvite chiar confectionate Altele',
    'Alte covoare si acoperitoare de podea din materiale textile chiar confectionate Din materiale textile sintetice sau artificiale',
    'Alte covoare si acoperitoare de podea din materiale textile chiar confectionate Din alte materiale textile',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Din lana sau din par fin de animale',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de batatura netaiate',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de batatura taiate reiate',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Alte tipuri de catifele si plusuri de batatura',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Tesaturi tip chenille omida',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de urzeala',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de batatura netaiate',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de batatura taiate reiate',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Alte catifele si plusuri de batatura',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Tesaturi tip chenille omida',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Catifele si plusuri de urzeala',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Din in',
    'Catifele si plusuri tesute si tesaturi tip chenille omida altele decat articolele de la pozitia sau  Altele',
    'Tesaturi buclate de tip buret ponge altele decat articolele de la pozitia suprafete textile cu smocuri altele decat produsele de la pozitia Tesaturi buclate de tip buret ponge din bumbac',
    'Tesaturi buclate de tip buret ponge altele decat articolele de la pozitia suprafete textile cu smocuri altele decat produsele de la pozitia Tesaturi buclate de tip buret ponge din alte materiale textile',
    'Tesaturi buclate de tip buret ponge altele decat articolele de la pozitia suprafete textile cu smocuri altele decat produsele de la pozitia Suprafete textile cu smocuri',
    'Tesaturi tip gazeu altele decat cele de la pozitia Din bumbac',
    'Tesaturi tip gazeu altele decat cele de la pozitia Din matase sau din deseuri de matase',
    'Tesaturi tip gazeu altele decat cele de la pozitia Altele',
    'Tuluri alte panze tip tul si tesaturi cu ochiuri innodate dantele din una sau mai multe bucati benzi sau motive decorative altele decat cele de la pozitiile Simple',
    'Tuluri alte panze tip tul si tesaturi cu ochiuri innodate dantele din una sau mai multe bucati benzi sau motive decorative altele decat cele de la pozitiile Altele',
    'Tuluri alte panze tip tul si tesaturi cu ochiuri innodate dantele din una sau mai multe bucati benzi sau motive decorative altele decat cele de la pozitiile Din fibre sintetice sau artificiale',
    'Tuluri alte panze tip tul si tesaturi cu ochiuri innodate dantele din una sau mai multe bucati benzi sau motive decorative altele decat cele de la pozitiile Din alte materiale textile',
    'Tuluri alte panze tip tul si tesaturi cu ochiuri innodate dantele din una sau mai multe bucati benzi sau motive decorative altele decat cele de la pozitiile Dantele executate manual',
    'Tapiserii tesute manual de tip Gobelin Flandra Aubusson Beauvais si similare si tapiserii cu acul de exemplu cu punct mic cu cruciulite chiar confectionate Tapiserii tesute manual de tip Gobelin Flandra Aubusson Beauvais si similare si tapiserii cu acul de exemplu cu punct mic cu cruciulite chiar confectionate',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Panglici din catifea plus tesaturi tip chenille omida sau din esaturi buclate tip buret ponge',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Alte panglici care contin minimum  din greutate fire din elastomeri sau din cauciuc',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Din bumbac',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Cu liziere reale',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Altele',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Din alte materiale textile',
    'Panglici altele decat articolele de la pozitia panglici fara batatura din fire sau fibre paralelizate si lipite bolduc Panglici fara batatura din fire sau fibre paralelizate si lipite bolduc',
    'Etichete ecusoane si articole similare din materiale textile sub forma de bucati benzi sau decupate nebrodate Cu inscrisuri sau motive obtinute prin tesere',
    'Etichete ecusoane si articole similare din materiale textile sub forma de bucati benzi sau decupate nebrodate Altele',
    'Etichete ecusoane si articole similare din materiale textile sub forma de bucati benzi sau decupate nebrodate Din pasla sau materiale netesute',
    'Etichete ecusoane si articole similare din materiale textile sub forma de bucati benzi sau decupate nebrodate Altele',
    'Impletituri sub forma de bucati articole de pasmanterie si articole ornamentale similare din una sau mai multe bucati fara broderie altele decat cele crosetate sau tricotate ciucuri pampoane si articole similare Impletituri din una sau mai multe bucati',
    'Impletituri sub forma de bucati articole de pasmanterie si articole ornamentale similare din una sau mai multe bucati fara broderie altele decat cele crosetate sau tricotate ciucuri pampoane si articole similare Altele',
    'Tesaturi din fire din metal si tesaturi din fire metalice sau din fire textile metalizate de la pozitia de tipul celor utilizate pentru imbracaminte mobilier sau utilizari similare nedenumite si necuprinse in alta parte Tesaturi din fire din metal si tesaturi din fire metalice sau din fire textile metalizate de la pozitia de tipul celor utilizate pentru imbracaminte mobilier sau utilizari similare nedenumite si necuprinse in alta parte',
    'Broderii sub forma de bucati benzi sau motive decorative Cu o valoare de peste kg greutate neta',
    'Broderii sub forma de bucati benzi sau motive decorative Altele',
    'Broderii sub forma de bucati benzi sau motive decorative Cu o valoare de peste kg greutate neta',
    'Broderii sub forma de bucati benzi sau motive decorative Altele',
    'Broderii sub forma de bucati benzi sau motive decorative Cu o valoare de peste kg greutate neta',
    'Broderii sub forma de bucati benzi sau motive decorative Altele',
    'Broderii sub forma de bucati benzi sau motive decorative Cu o valoare de peste kg greutate neta',
    'Broderii sub forma de bucati benzi sau motive decorative Altele',
    'Produse textile matlasate din una sau sau mai multe bucai constituite dintrunul sau din mai multe straturi de materiale textile asociate cu un material de umplutura tighelite capitonate sau altfel compartimentate altele decat broderiile de la pozitia Produse textile matlasate din una sau sau mai multe bucai constituite dintrunul sau din mai multe straturi de materiale textile asociate cu un material de umplutura tighelite capitonate sau altfel compartimentate altele decat broderiile de la pozitia',
    'Tesaturi acoperite cu clei sau cu substante amilacee de tipul celor utilizate in legatorie cartonaj ca materiale de acoperire sau pentru utilizari similare panze de calc sau transparente pentru desen panze pregatite pentru pictura vatir si tesaturi similare pentru confectionarea palariilor Tesaturi acoperite cu clei sau cu substante amilacee de tipul celor utilizate in legatorie cartonaj ca materiale de acoperire a cartilor sau pentru utilizari similare',
    'Tesaturi acoperite cu clei sau cu substante amilacee de tipul celor utilizate in legatorie cartonaj ca materiale de acoperire sau pentru utilizari similare panze de calc sau transparente pentru desen panze pregatite pentru pictura vatir si tesaturi similare pentru confectionarea palariilor Altele',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Impregnate cu cauciuc',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Altele',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Impregnate cu cauciuc',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Altele',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Impregnate cu cauciuc',
    'Tesaturi cord pentru anvelope din fire de mare rezistenta din nailon sau din alte poliamide din poliesteri sau viscoza Altele',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Impregnate',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Acoperite imbracate sau stratificate',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Impregnate',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Acoperite imbracate sau stratificate',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Impregnate',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Cu derivati din celuloza sau din alte materiale plastice materialul textil reprezentand doar fata',
    'Tesaturi impregnate imbracate sau acoperite cu material plastic sau stratificate cu material plastic altele decat cele de la pozitia Altele',
    'Linoleum chiar decupat acoperitoare de podea constand dintro imbracare sau o acoperire aplicata pe un suport textil chiar decupate Linoleum',
    'Linoleum chiar decupat acoperitoare de podea constand dintro imbracare sau o acoperire aplicata pe un suport textil chiar decupate Altele',
    'Tapet din materiale textile Constand din fire dispuse paralel pe un suport',
    'Tapet din materiale textile Din in',
    'Tapet din materiale textile Din iuta',
    'Tapet din materiale textile Din fibre sintetice sau artificiale',
    'Tapet din materiale textile Altele',
    'Tesaturi cauciucate altele decat cele de la pozitia Benzi adezive cu latimea de maximum cm',
    'Tesaturi cauciucate altele decat cele de la pozitia Din materiale tricotate sau crosetate',
    'Tesaturi cauciucate altele decat cele de la pozitia esaturi prevazute la nota literac din prezentul capitol',
    'Tesaturi cauciucate altele decat cele de la pozitia Altele',
    'Alte tesaturi impregnate imbracate sau acoperite panze pictate pentru decoruri de teatru decoruri pentru studiouri sau pentru utilizari similare Alte tesaturi impregnate imbracate sau acoperite panze pictate pentru decoruri de teatru decoruri pentru studiouri sau pentru utilizari similare',
    'Fitile textile tesute impletite sau tricotate pentru lampi lampi de gatit brichete lumanari sau articole similare mansoane pentru lampi cu incandescenta si tricoturi tubulare care servesc la fabricarea acestora chiar impregnate Fitile textile tesute impletite sau tricotate pentru lampi lampi de gatit brichete lumanari sau articole similare mansoane pentru lampi cu incandescenta si tricoturi tubulare care servesc la fabricarea acestora chiar impregnate',
    'Furtunuri pentru pompe si furtunuri similare din materiale textile chiar cu armaturi sau accesorii din alte materiale Din fibre sintetice',
    'Furtunuri pentru pompe si furtunuri similare din materiale textile chiar cu armaturi sau accesorii din alte materiale Din alte materiale textile',
    'Benzi transportoare sau curele de transmisie din materiale textile chiar impregnate imbracate acoperite sau stratificate cu materiale plastice sau ranforsate cu metal sau cu alte materiale Benzi transportoare sau curele de transmisie din materiale textile chiar impregnate imbracate acoperite sau stratificate cu materiale plastice sau ranforsate cu metal sau cu alte materiale',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Tesaturi pasle si tesaturi dublate cu pasla acoperite imbracate sau stratificate cu cauciuc piele sau alte materiale de tipul celor utilizate pentru fabricarea garniturilor de carda si produse similare pentru alte utilizari tehnice inclusiv panglicile fabricate din catifea impregnate cu cauciuc pentru acoperirea sulurilor de la masinile de tesut',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Panze de sita chiar confectionate',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol esaturi de tipul celor utilizate pentru maini de fabricat hartie de exemplu de constituire a esaturilor',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Altele',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Din alte materiale textile',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol esaturi cu un strat de capueala cusut pe ele de tipul celor utilizate pentru maini de fabricat hartie de exemplu pasla pentru presa',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Altele',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Din alte materiale textile',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Tesaturi pentru filtrare sau strecurare de tipul celor utilizate pentru presele de ulei sau pentru utilizari tehnice similare inclusiv cele din par uman',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Din pasla',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Discuri abrazive autoadezive de tipul celor folosite la fabricarea plachetelor de semiconductori',
    'Produse si articole textile pentru utilizari tehnice mentionate la nota din acest capitol Altele',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Materiale asazise cu par lung',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din bumbac',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din fibre sintetice sau artificiale',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din alte materiale textile',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din bumbac',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din fibre sintetice sau artificiale',
    'Catifea plus inclusive materiale asazise cu par lung si materiale buclate tricotate sau crosetate Din alte materiale textile',
    'Materiale tricotate sau crosetate cu o latime de maximum cm care contin minimum  din greutate fire din elastomeri sau fire din cauciuc altele decat cele de la pozitia Care contin minimum  din greutate fire din elastomeri dar nu contin fire din cauciuc',
    'Materiale tricotate sau crosetate cu o latime de maximum cm care contin minimum  din greutate fire din elastomeri sau fire din cauciuc altele decat cele de la pozitia Altele',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Din lana sau din par fin de animale',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Din bumbac',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Dantele Raschel',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Altele',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Din fibre artificiale',
    'Materiale tricotate sau crosetate cu o latime de maximum cm altele decat cele de la pozitiile si  Altele',
    'Materiale tricotate sau crosetate cu o latime de peste cm care contin minimum  din greutate fire din elastomeri sau fire din cauciuc altele decat cele de la pozitia Care contin minimum  din greutate elastomeri dar nu contin fire din cauciuc',
    'Materiale tricotate sau crosetate cu o latime de peste cm care contin minimum  din greutate fire din elastomeri sau fire din cauciuc altele decat cele de la pozitia Altele',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Nealbite sau albite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Vopsite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Din fire de diferite culori',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Imprimate',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Tesaturi mentionate in nota de subpozitie  din prezentul capitol',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Altele albite sau nealbite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Altele vopsite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Altele din fire de diferite culori',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Altele imprimate',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Nealbite sau albite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Vopsite',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Din fire de diferite culori',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Imprimate',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Din lana sau din par fin de animale',
    'Materiale tricotate cu urzeala inclusiv cele obtinute pe masini de produs panglici altele decat cele de la pozitiile Altele',
    'Alte materiale tricotate sau crosetate Din lana sau par fin de animale',
    'Alte materiale tricotate sau crosetate Nealbite sau albite',
    'Alte materiale tricotate sau crosetate Vopsite',
    'Alte materiale tricotate sau crosetate Din fire de diferite culori',
    'Alte materiale tricotate sau crosetate Imprimate',
    'Alte materiale tricotate sau crosetate Nealbite sau albite',
    'Alte materiale tricotate sau crosetate Vopsite',
    'Alte materiale tricotate sau crosetate Din fire de diferite culori',
    'Alte materiale tricotate sau crosetate Imprimate',
    'Alte materiale tricotate sau crosetate Nealbite sau albite',
    'Alte materiale tricotate sau crosetate Vopsite',
    'Alte materiale tricotate sau crosetate Din fire de diferite culori',
    'Alte materiale tricotate sau crosetate Imprimate',
    'Alte materiale tricotate sau crosetate Altele',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru barbati sau pentru baieti cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Paltoane scurte pelerine si articole similare',
    'Paltoane scurte pelerine hanorace bluzoane si articole similare tricotate sau crosetate pentru femei sau fete cu exceptia articolelor de la pozitia Hanorace inclusiv jachete de schi bluzoane si articole similare',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti din lana sau din par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din lana sau par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din lana sau par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre artificiale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele de baie tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Camasi si bluze tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Camasi si bluze tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice',
    'Camasi si bluze tricotate sau crosetate pentru barbati sau baieti Din fibre artificiale',
    'Camasi si bluze tricotate sau crosetate pentru barbati sau baieti Din lana sau par fin de animale',
    'Camasi si bluze tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din bumbac',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din fibre sintetice sau artificiale',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din lana sau din par fin de animale',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din matase sau deseuri de matase',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din in sau ramie',
    'Camasi bluze bluzecamasa tricotate sau crosetate pentru femei sau fete Din alte materiale textile',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice sau artificiale',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din fibre sintetice sau artificiale',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din bumbac',
    'Chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare tricotate sau crosetate pentru barbati sau baieti Din alte materiale textile',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din fibre sintetice sau artificiale',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din alte materiale textile',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din bumbac',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din fibre sintetice sau artificiale',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din alte materiale textile',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din bumbac',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din fibre sintetice sau artificiale',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din alte materiale textile',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din bumbac',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din fibre sintetice sau artificiale',
    'Combinezoane sau furouri jupoane chiloti camasi de noapte pijamale dezabieuri halate de baie halate de casa si articole similare tricotate sau crosetate pentru femei si fete Din alte materiale textile',
    'Tricouri Tshirts bluze si maiouri de corp tricotate sau crosetate Din bumbac',
    'Tricouri Tshirts bluze si maiouri de corp tricotate sau crosetate Din lana sau din par fin de animale ori din fibre sintetice sau artificiale',
    'Tricouri Tshirts bluze si maiouri de corp tricotate sau crosetate Din alte materiale textile',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Jerseuri si pulovere cu un continut de lana de minimum  din greutate si cu o greutate de minimum g pe bucata',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru barbati sau baieti',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru femei sau fete',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru barbati si baieti',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru femei sau fete',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru barbati sau baieti',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru femei sau fete',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Tricotaje subtiri care se poarta sub pulovere',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru barbati sau baieti',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru femei sau fete',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Tricotaje subtiri care se poarta sub pulovere',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru barbati sau baieti',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Pentru femei sau fete',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Din in sau ramie',
    'Jerseuri pulovere cardigane si articole similare veste si articole similare inclusiv tricotajele subtiri care se poarta sub pulovere tricotate sau crosetate Din alte materiale textile',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Manusi',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Altele',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Manusi',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Altele',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Manusi',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Altele',
    'Imbracaminte si accesorii de imbracaminte tricotate sau crosetate pentru sugari Din alte materiale textile',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Din bumbac',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Din fibre sintetice',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Din alte materiale textile',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Combinezoane si costume de schi',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Cu un continut de fire din cauciuc de minimum  din greutate',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Altele',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Cu un continut de fire din cauciuc de minimum  din greutate',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Altele',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Cu un continut de fire din cauciuc de minimum  din greutate',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Altele',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Cu un continut de fire din cauciuc de minimum  din greutate',
    'Treninguri combinezoane si costume de schi si costume chiloti si slipuri de baie tricotate sau crosetate Altele',
    'Imbracaminte confectionata din materiale tricotate sau crosetate de la pozitiile  sau  Din materiale tricotate sau crosetate de la pozitia',
    'Imbracaminte confectionata din materiale tricotate sau crosetate de la pozitiile  sau  Altele',
    'Alte articole de imbracaminte din materiale tricotate sau crosetate Din bumbac',
    'Alte articole de imbracaminte din materiale tricotate sau crosetate Din fibre sintetice sau artificiale',
    'Alte articole de imbracaminte din materiale tricotate sau crosetate Din alte materiale textile',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din fibre sintetice',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Altele',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din fibre sintetice avand finetea firului simplu sub decitex',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din fibre sintetice avand finetea firului simplu de minimum decitex',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din alte materiale textile',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Ciorapi treisferturi',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Alti ciorapi',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din alte materiale textile',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din lana sau par fin de animale',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din bumbac',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Ciorapi treisferturi',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Ciorapi pentru femei',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Altele',
    'Ciorapichilot dresuri ciorapi sosete si alte articole similare inclusiv ciorapii cu compresie progresiva spre exemplu pentru varice si incaltaminte fara talpa aplicata tricotate sau crosetate Din alte materiale textile',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Manusi impregnate imbracate acoperite sau stratificate cu cauciuc',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Altele',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Din lana sau par fin de animale',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Din bumbac',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Din fibre sintetice',
    'Manusi mitene si manusi cu un deget tricotate sau crosetate Din alte materiale textile',
    'Alte accesorii confectionate de imbracaminte tricotate sau crosetate parti de imbracaminte sau accesorii de imbracaminte tricotate sau crosetate Saluri esarfe fulare mantile voaluri voalete si articole similare',
    'Alte accesorii confectionate de imbracaminte tricotate sau crosetate parti de imbracaminte sau accesorii de imbracaminte tricotate sau crosetate Din tesaturi tricotate sau crosetate elastice sau cauciucate',
    'Alte accesorii confectionate de imbracaminte tricotate sau crosetate parti de imbracaminte sau accesorii de imbracaminte tricotate sau crosetate Altele',
    'Alte accesorii confectionate de imbracaminte tricotate sau crosetate parti de imbracaminte sau accesorii de imbracaminte tricotate sau crosetate Parti',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Din lana sau din par fin de animale',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Cu o greutate pe bucata de maximum kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Cu o greutate pe bucata de peste kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Cu o greutate pe bucata de maximum kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Cu o greutate pe bucata de peste kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si alte articole similare pentru barbati sau baieti cu exceptia articolelor de la pozitia Din alte materiale textile',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Din lana sau par fin de animale',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Cu o greutate pe bucata de maximum kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Cu o greutate pe bucata de peste kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Cu o greutate pe bucata de maximum kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Cu o greutate pe bucata de peste kg',
    'Paltoane scurte pelerine hanorace inclusiv geci de ski geci de vant si articole similare pentru femei sau fete cu exceptia articolelor de la pozitia Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din lana sau par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din fibre sintetice',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din bumbac',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din fibre artificiale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din lana sau par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din lana sau par fin de animale',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din alte materiale textile',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Pantaloni si pantaloni scurti',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Salopete cu bretele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din tesaturi denim',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din catifea si plus din urzeala taiate reiate',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti De lucru',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Altele',
    'Costume sau compleuri ansambluri jachete sacouri pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat cele pentru baie pentru barbati sau baieti Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre artificiale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre artificiale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din matase sau din deseuri de matase',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din lana sau par fin de animale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din bumbac',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre sintetice',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din fibre artificiale',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Pantaloni si pantaloni scurti',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din tesaturi denim',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din catifea si plus de urzeala taiate reiate',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete De lucru',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Altele',
    'Taioare ansambluri jachete sacouri rochii fuste fustepantalon pantaloni salopete cu bretele pantaloni scurti si sorturi altele decat pentru baie pentru femei si fete Din alte materiale textile',
    'Camasi si camasi cu maneca scurta pentru barbati sau baieti Din bumbac',
    'Camasi si camasi cu maneca scurta pentru barbati sau baieti Din fibre sintetice sau artificiale',
    'Camasi si camasi cu maneca scurta pentru barbati sau baieti Din in sau ramie',
    'Camasi si camasi cu maneca scurta pentru barbati sau baieti Din alte materiale textile',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din matase sau din deseuri de matase',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din lana sau par fin de animale',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din bumbac',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din fibre sintetice sau artificiale',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din in sau ramie',
    'Camasi bluze bluzecamasi si camasi cu maneca scurta pentru femei sau fete Din alte materiale textile',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din bumbac',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din alte materiale textile',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din bumbac',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din fibre sintetice sau artificiale',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din alte materiale textile',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din bumbac',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din fibre sintetice sau artificiale',
    'Bluze i maiouri de corp chiloti indispensabili camasi de noapte pijamale halate de baie halate de casa si articole similare pentru barbati sau pentru baieti Din alte materiale textile',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din fibre sintetice sau artificiale',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din alte materiale textile',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din bumbac',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din fibre sintetice sau artificiale',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din alte materiale textile',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din bumbac',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din fibre sintetice sau artificiale',
    'Bluze de corp si camasi de zi combinezoane sau furouri jupoane chiloti camasi de noapte pijamale neglijeuri halate de baie halate de casa si articole similare pentru femei sau fete Din alte materiale textile',
    'Imbracaminte si accesorii de imbracaminte pentru sugari Din bumbac',
    'Imbracaminte si accesorii de imbracaminte pentru sugari Din fibre sintetice',
    'Imbracaminte si accesorii de imbracaminte pentru sugari Din lana sau din par fin de animale',
    'Imbracaminte si accesorii de imbracaminte pentru sugari Din alte materiale textile',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Din produsele de la pozitia',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Halate de unica folosinta de tipul celor utilizate de pacienti sau chirurgi in cursul interventiilor chirurgicale',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Altele',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Alta imbracaminte de tipul celei descrise la pozitia',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Alta imbracaminte de tipul celei descrise la pozitia',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Alta imbracaminte pentru barbati sau baieti',
    'Imbracaminte confectionata din produsele de la pozitiile    sau  Alta imbracaminte pentru femei sau fete',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Pentru barbati sau baieti',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Pentru femei si fete',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Combinezoane si costume de schi',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Imbracaminte de lucru',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Al carei exterior este realizat din acelasi material',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti superioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti inferioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Altele',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Imbracaminte de lucru',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Al carei exterior este realizat din acelasi material',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti superioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti inferioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Altele',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Din alte materiale textile',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Sorturi bluze si alta imbracaminte de lucru',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Al carei exterior este realizat din acelasi material',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti superioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti inferioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Altele',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Sorturi bluze si alta imbracaminte de lucru',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Al carei exterior este realizat din acelasi material',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti superioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Parti inferioare',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Altele',
    'Imbracaminte de sport treninguri combinezoane si costume de schi costume chiloti si slipuri de baie alte articole de imbracaminte Din alte materiale textile',
    'Sutiene brauri corsete bretele suspensoare jartiere si articole similare si partile lor chiar tricotate sau crosetate Prezentate in seturi conditionate pentru vanzarea cu amanuntul care contin un sutien sau o bustiera si un chilot',
    'Sutiene brauri corsete bretele suspensoare jartiere si articole similare si partile lor chiar tricotate sau crosetate Altele',
    'Sutiene brauri corsete bretele suspensoare jartiere si articole similare si partile lor chiar tricotate sau crosetate Brauri i braurichilot',
    'Sutiene brauri corsete bretele suspensoare jartiere si articole similare si partile lor chiar tricotate sau crosetate Corsete combinate',
    'Sutiene brauri corsete bretele suspensoare jartiere si articole similare si partile lor chiar tricotate sau crosetate Altele',
    'Batiste Din bumbac',
    'Batiste Din alte materiale textile',
    'Saluri esarfe fulare mantile voaluri voalete si articole similare Din matase sau din deseuri de matase',
    'Saluri esarfe fulare mantile voaluri voalete si articole similare Din lana sau par fin de animale',
    'Saluri esarfe fulare mantile voaluri voalete si articole similare Din fibre sintetice',
    'Saluri esarfe fulare mantile voaluri voalete si articole similare Din fibre artificiale',
    'Saluri esarfe fulare mantile voaluri voalete si articole similare Din alte materiale textile',
    'Cravate papioane si fulare cravata Din matase sau din deseuri de matase',
    'Cravate papioane si fulare cravata Din fibre sintetice sau artificiale',
    'Cravate papioane si fulare cravata Din alte materiale textile',
    'Manusi mitene si manusi cu un deget Manusi mitene si manusi cu un deget',
    'Alte accesorii de imbracaminte confectionate parti de imbracaminte sau de accesorii de imbracaminte altele decat cele de la pozitia Accesorii',
    'Alte accesorii de imbracaminte confectionate parti de imbracaminte sau de accesorii de imbracaminte altele decat cele de la pozitia Parti',
    'Paturi si pleduri Paturi electrice',
    'Paturi si pleduri Tricotate sau crosetate',
    'Paturi si pleduri Altele',
    'Paturi si pleduri Tricotate sau crosetate',
    'Paturi si pleduri Altele',
    'Paturi si pleduri Tricotate sau crosetate',
    'Paturi si pleduri Altele',
    'Paturi si pleduri Tricotate sau crosetate',
    'Paturi si pleduri Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Lenjerie de pat tricotata sau crosetata',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din bumbac',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Netesute',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din in sau ramie',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din bumbac',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Netesute',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din in sau ramie',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Lenjerie de masa tricotata sau crosetata',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din bumbac',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Netesute',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din in',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Lenjerie de toaleta sau de bucatarie din tesaturi buclate tip buret ponge din bumbac',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din bumbac',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Netesute',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Din in',
    'Lenjerie de pat de masa de toaleta sau de bucatarie Altele',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Din fibre sintetice',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Din alte materiale textile',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Din bumbac',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Netesute',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Altele',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Netesute',
    'Transperante perdele si storuri de interior draperii si garnituri de pat Altele',
    'Alte stofe de mobila cu exceptia celor de la pozitia Tricotate sau crosetate',
    'Alte stofe de mobila cu exceptia celor de la pozitia Din bumbac',
    'Alte stofe de mobila cu exceptia celor de la pozitia Din in sau ramie',
    'Alte stofe de mobila cu exceptia celor de la pozitia Din alte materiale textile',
    'Alte stofe de mobila cu exceptia celor de la pozitia Plase pentru pat specificate la nota  de subpozitie de la acest capitol',
    'Alte stofe de mobila cu exceptia celor de la pozitia Tricotate sau crosetate',
    'Alte stofe de mobila cu exceptia celor de la pozitia Altele decat cele tricotate sau crosetate din bumbac',
    'Alte stofe de mobila cu exceptia celor de la pozitia Altele decat cele tricotate sau crosetate din fibre sintetice',
    'Alte stofe de mobila cu exceptia celor de la pozitia Altele decat cele tricotate sau crosetate din alte materiale textile',
    'Saci si saculeti pentru ambalarea marfurilor Uzati',
    'Saci si saculeti pentru ambalarea marfurilor Altele',
    'Saci si saculeti pentru ambalarea marfurilor Din bumbac',
    'Saci si saculeti pentru ambalarea marfurilor Tricotate sau crosetate',
    'Saci si saculeti pentru ambalarea marfurilor Altele',
    'Saci si saculeti pentru ambalarea marfurilor Altele',
    'Saci si saculeti pentru ambalarea marfurilor Tricotate sau crosetate',
    'Saci si saculeti pentru ambalarea marfurilor Altele',
    'Saci si saculeti pentru ambalarea marfurilor Altele',
    'Saci si saculeti pentru ambalarea marfurilor Din alte materiale textile',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Din fibre sintetice',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Din alte materiale textile',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Din fibre sintetice',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Din alte materiale textile',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Vele',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Saltele pneumatice',
    'Prelate storuri pentru exterior i umbrare corturi inclusiv prelate temporare si articole similare vele pentru ambarcatiuni planse cu vele carucioare cu vela articole de camping Altele',
    'Alte articole confectionate inclusiv tipare de imbracaminte Tricotate sau crosetate',
    'Alte articole confectionate inclusiv tipare de imbracaminte Netesute',
    'Alte articole confectionate inclusiv tipare de imbracaminte Altele',
    'Alte articole confectionate inclusiv tipare de imbracaminte Centuri si veste de salvare',
    'Alte articole confectionate inclusiv tipare de imbracaminte Tricotate sau crosetate',
    'Alte articole confectionate inclusiv tipare de imbracaminte Din pasla',
    'Alte articole confectionate inclusiv tipare de imbracaminte Campuri operatorii de unica folosinta confectionate din materiale de la pozitia de tipul celor utilizate in cursul interventiilor chirurgicale',
    'Alte articole confectionate inclusiv tipare de imbracaminte Masti faciale filtrante FFP in conformitate cu standardul EN alte masti conforme cu un standard similar pentru masti utilizate ca dispozitive de protectie respiratorie pentru protectia impotriva particulelor',
    'Alte articole confectionate inclusiv tipare de imbracaminte Altele',
    'Alte articole confectionate inclusiv tipare de imbracaminte Altele',
    'Seturi compuse din bucati de tesaturi si din fire cu sau fara accesorii pentru confectionarea covoarelor tapiseriilor fetelor de masa sau a prosoapelor brodate sau a articolelor textile similare conditionate pentru vanzarea cu amanuntul Seturi compuse din bucati de tesaturi si din fire cu sau fara accesorii pentru confectionarea covoarelor tapiseriilor fetelor de masa sau a prosoapelor brodate sau a articolelor textile similare conditionate pentru vanzarea cu amanuntul',
    'Imbracaminte purtata sau uzata si alte articole purtate sau uzate Imbracaminte purtata sau uzata si alte articole purtate sau uzate',
    'Zdrente sfori franghii si funii din materiale textile sub forma de deseuri sau de articole uzate Triate',
    'Zdrente sfori franghii si funii din materiale textile sub forma de deseuri sau de articole uzate Altele',
    'Incaltaminte etansa cu talpi exterioare si fete din cauciuc sau din material plastic a carei fata nu a fost nici fixata de talpa exterioara prin coasere sau prin nituri cuie suruburi stifturi sau dispozitive similare nici formata din diferite parti asamblate prin procedee similare Incaltaminte care incorporeaza in partea anterioara un bombeu de protecie din metal',
    'Incaltaminte etansa cu talpi exterioare si fete din cauciuc sau din material plastic a carei fata nu a fost nici fixata de talpa exterioara prin coasere sau prin nituri cuie suruburi stifturi sau dispozitive similare nici formata din diferite parti asamblate prin procedee similare Cu fete din cauciuc',
    'Incaltaminte etansa cu talpi exterioare si fete din cauciuc sau din material plastic a carei fata nu a fost nici fixata de talpa exterioara prin coasere sau prin nituri cuie suruburi stifturi sau dispozitive similare nici formata din diferite parti asamblate prin procedee similare Cu fete din material plastic',
    'Incaltaminte etansa cu talpi exterioare si fete din cauciuc sau din material plastic a carei fata nu a fost nici fixata de talpa exterioara prin coasere sau prin nituri cuie suruburi stifturi sau dispozitive similare nici formata din diferite parti asamblate prin procedee similare Altele',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Incaltaminte pentru schi',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Incaltaminte pentru surf pe zapada',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Altele',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Incaltaminte cu fete din cureluse sau bride fixate de talpa prin stifturi',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Care incorporeaza in partea anterioara un bombeu de protectie din metal',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Altele',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Care incorporeaza in partea anterioara un bombeu de protectie din metal',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Cu fete din cauciuc',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Cu inaltimea tocului inclusiv talpa de peste cm',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Altele',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Papuci de casa si alta incaltaminte de interior',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Sub cm',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Incaltaminte care nu poate fi identificata a fi pentru barbati sau pentru femei',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Pentru barbati',
    'Alta incaltaminte cu talpi exterioare si fete din cauciuc sau din material plastic Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte pentru schi si incaltaminte pentru surf pe zapada',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Altele',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte cu talpi exterioare din piele naturala si cu fete confectionate din benzi din piele naturala care trec peste restul piciorului si inconjoara degetul mare',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Alte articole de incaltaminte care incorporeaza un bombeu de protectie din metal',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte cu talpa de lemn fara talpi interioare',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte cu talpa de lemn fara talpi interioare',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Cu inaltimea tocului inclusiv talpa de peste cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Papuci de casa si alta incaltaminte de interior',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte cu talpa de lemn fara talpi interioare',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte care nu poate fi identificata a fi pentru barbati sau pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte care nu poate fi identificata a fi pentru barbati sau pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte cu talpa de lemn fara talpi interioare',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Cu inaltimea tocului inclusiv talpa de peste cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte care nu poate fi identificata a fi pentru barbati sau pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Papuci de casa si alta incaltaminte de interior',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Sub cm',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Incaltaminte care nu poate fi identificata a fi pentru barbati sau pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru barbati',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din piele naturala Pentru femei',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din materiale textile Incaltaminte pentru sport incaltaminte pentru tenis pentru baschet pentru gimnastica pentru antrenament si incaltaminte similara',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din materiale textile Papuci de casa si alta incaltaminte de interior',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din materiale textile Altele',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din materiale textile Papuci de casa si alta incaltaminte de interior',
    'Incaltaminte cu talpi exterioare din cauciuc material plastic piele naturala sau reconstituita si cu fete din materiale textile Altele',
    'Alta incaltaminte Cu fete din piele naturala sau reconstituita',
    'Alta incaltaminte Cu talpi exterioare din lemn sau pluta',
    'Alta incaltaminte Papuci de casa si alta incaltaminte de interior',
    'Alta incaltaminte Altele',
    'Alta incaltaminte Cu talpile exterioare din cauciuc material plastic piele naturala sau reconstituita',
    'Alta incaltaminte Cu talpi exterioare din alte materiale',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Din piele naturala',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Din alte materiale',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Din cauciuc',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Din material plastic',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Combinatii formate din fete de incaltaminte fixate pe primele talpi sau pe alte parti inferioare dar care sunt lipsite de talpile exterioare',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Talpi interioare detasabile si alte accesorii detasabile',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Talpi exterioare din piele naturala sau reconstituita',
    'Parti de incaltaminte inclusiv fetele chiar fixate pe talpi altele decat talpile exterioare talpi interioare detasabile branturi si articole similare detasabile ghetre jambiere si articole similare si parti ale acestora Altele',
    'Calote neformate pe calapod si fara boruri discuri si cilindri pentru palarii din pasla chiar taiate in directia inaltimii Calote neformate pe calapod si fara boruri discuri si cilindri pentru palarii din pasla chiar taiate in directia inaltimii',
    'Calote sau forme pentru palarii impletite sau fabricate din benzi asamblate din orice material neformate si fara boruri necaptusite si neimpodobite Calote sau forme pentru palarii impletite sau fabricate din benzi asamblate din orice material neformate si fara boruri necaptusite si neimpodobite',
    'Palarii si alte articole pentru acoperit capul impletite sau confectionate prin imbinarea benzilor din orice material chiar captusite sau impodobite Palarii si alte articole pentru acoperit capul impletite sau confectionate prin imbinarea benzilor din orice material chiar captusite sau impodobite',
    'Palarii si alte articole pentru acoperit capul tricotate sau crosetate confectionate din dantela pasla sau alte produse textile sub forma de una sau mai multe bucati dar nu din benzi chiar captusite sau impodobite plase si fileuri pentru par din orice material chiar captusite sau impodobite Din pasla din par fin de animale sau din lana si par fin de animale facute  din discurile si cilindriide la pozitia',
    'Palarii si alte articole pentru acoperit capul tricotate sau crosetate confectionate din dantela pasla sau alte produse textile sub forma de una sau mai multe bucati dar nu din benzi chiar captusite sau impodobite plase si fileuri pentru par din orice material chiar captusite sau impodobite Caschete chipiuri si alte articole similare cu cozoroc pentru acoperit capul',
    'Palarii si alte articole pentru acoperit capul tricotate sau crosetate confectionate din dantela pasla sau alte produse textile sub forma de una sau mai multe bucati dar nu din benzi chiar captusite sau impodobite plase si fileuri pentru par din orice material chiar captusite sau impodobite Altele',
    'Alte palarii sau articole pentru acoperit capul chiar captusite sau impodobite Din material plastic',
    'Alte palarii sau articole pentru acoperit capul chiar captusite sau impodobite Din alte materiale',
    'Alte palarii sau articole pentru acoperit capul chiar captusite sau impodobite Din cauciuc sau din material plastic',
    'Alte palarii sau articole pentru acoperit capul chiar captusite sau impodobite Din pasla din par fin de animale sau din lana si par fin de animale facute din discurile si cilindrii de la pozitia',
    'Alte palarii sau articole pentru acoperit capul chiar captusite sau impodobite Altele',
    'Benzi pentru garnituri interioare captuseli capace carcase cozoroace si cureluse pentru obiectele de acoperit capul Benzi pentru garnituri interioare captuseli capace carcase cozoroace si cureluse pentru obiectele de acoperit capul',
    'Umbrele de ploaie umbrele de soare inclusiv umbrelebaston umbrele de gradina si articole similare Umbrele de gradina si articolele similare',
    'Umbrele de ploaie umbrele de soare inclusiv umbrelebaston umbrele de gradina si articole similare Cu tije sau manere telescopice',
    'Umbrele de ploaie umbrele de soare inclusiv umbrelebaston umbrele de gradina si articole similare Cu aparatoare din material textil tesut',
    'Umbrele de ploaie umbrele de soare inclusiv umbrelebaston umbrele de gradina si articole similare Altele',
    'Bastoane bastoanescaun bice cravase si articole similare Bastoane bastoanescaun bice cravase si articole similare',
    'Parti garnituri si accesorii pentru articolele de la pozitia sau  Cadre pentru umbrele inclusiv cadre cu bete sau manere pentru umbrele de ploaie sau umbrele de soare',
    'Parti garnituri si accesorii pentru articolele de la pozitia sau  Manere si maciulii',
    'Parti garnituri si accesorii pentru articolele de la pozitia sau  Altele',
    'Piei si alte parti ale pasarilor acoperite cu pene sau cu puf pene parti de pene puf si articole din aceste materiale altele decat produsele de la pozitia si decat tijele penelor prelucrate Piei si alte parti ale pasarilor acoperite cu pene sau cu puf pene parti de pene puf si articole din aceste materiale altele decat produsele de la pozitia si decat tijele penelor prelucrate',
    'Flori frunze si fructe artificiale si partile lor articole confectionate din flori frunze sau fructe artificiale Din materiale plastice',
    'Flori frunze si fructe artificiale si partile lor articole confectionate din flori frunze sau fructe artificiale Din alte materiale',
    'Par uman pieptanat subtiat albit sau altfel prelucrat lana par de animale si alte materiale textile preparate pentru fabricarea perucilor si a articolelor similare Par uman pieptanat subtiat albit sau altfel prelucrat lana par de animale si alte materiale textile preparate pentru fabricarea perucilor si a articolelor similare',
    'Peruci barbi sprancene gene mese si articole similare din par uman din par de animale sau din materiale textile articole din par uman nedenumite si necuprinse in alta parte Peruci complete',
    'Peruci barbi sprancene gene mese si articole similare din par uman din par de animale sau din materiale textile articole din par uman nedenumite si necuprinse in alta parte Altele',
    'Peruci barbi sprancene gene mese si articole similare din par uman din par de animale sau din materiale textile articole din par uman nedenumite si necuprinse in alta parte Din par uman',
    'Peruci barbi sprancene gene mese si articole similare din par uman din par de animale sau din materiale textile articole din par uman nedenumite si necuprinse in alta parte Din alte materiale',
    'Pavele borduri pentru trotuar si dale pentru pavaj din pietre naturale altele decat ardezia Pavele borduri pentru trotuar si dale pentru pavaj din pietre naturale altele decat ardezia',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Dale cuburi blocuri si articole similare chiar sub alta forma decat cea patrata sau dreptunghiulara la care cea mai mare fata poate fi inscrisa intrun patrat cu latura sub  cm granule cioburi si praf colorate artificial',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Marmura travertin si alabastru',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Granit',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Alte pietre',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Marmura travertin si alabastru',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Alte pietre calcaroase',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Slefuit decorat sau altfel prelucrat dar nesculptat cu o greutate neta de minimum kg',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Altele',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Slefuite decorate sau altfel prelucrate dar nesculptate cu o greutate neta de minimum kg',
    'Pietre monumentale sau pentru constructie altele decat ardezia prelucrate si articole din aceste pietre cu exceptia celor de la pozitia cuburi blocuri si articole similare pentru mozaicuri din pietre naturale inclusiv ardezia chiar pe suport granule cioburi si praf de pietre naturale inclusiv ardezia colorate artificial Altele',
    'Ardezie naturala prelucrata si articole din ardezie naturala sau aglomerata ardezina Ardezie pentru acoperisuri sau pentru fatade',
    'Ardezie naturala prelucrata si articole din ardezie naturala sau aglomerata ardezina Altele',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Pietre de moara si pietre abrazive pentru macinat sfaramat defibrat sau decorticat',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Din diamante naturale sau sintetice aglomerate',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Fara armatura',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Cu armatura',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Din ceramica sau din silicati',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Din alte materiale',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Altele',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Din pietre naturale',
    'Pietre de moara pietre abrazive si articole similare fara batiuri pentru macinat defibrat concasat ascutit polizat slefuit rectificat taiat pietre de ascutit de slefuit sau de polizat manual si partile lor din pietre naturale din materiale abrazive naturale sau artificiale aglomerate sau din ceramica chiar avand parti din alte materiale Pietre de ascutit de slefuit sau polizat manual',
    'Pulberi sau granule abrazive naturale sau artificiale aplicate pe suport din materiale textile din hartie din carton sau din alte materiale chiar decupate cusute sau altfel asamblate Aplicate numai pe tesaturi din materiale textile',
    'Pulberi sau granule abrazive naturale sau artificiale aplicate pe suport din materiale textile din hartie din carton sau din alte materiale chiar decupate cusute sau altfel asamblate Aplicate numai pe hartie sau carton',
    'Pulberi sau granule abrazive naturale sau artificiale aplicate pe suport din materiale textile din hartie din carton sau din alte materiale chiar decupate cusute sau altfel asamblate Aplicate pe alte materiale',
    'Lana de zgura de roca si alta lana minerala similara vermiculita expandata argile expandate zgura expandata si produse din materiale minerale similare expandate amestecuri si articole din materiale minerale pentru izolari termice sau fonice sau pentru absorbtia zgomotului altele decat cele de la pozitiile  sau de la capitolul Lana de zgura de roca si alta lana minerala similara chiar amestecate intre ele in vrac foi sau rulouri',
    'Lana de zgura de roca si alta lana minerala similara vermiculita expandata argile expandate zgura expandata si produse din materiale minerale similare expandate amestecuri si articole din materiale minerale pentru izolari termice sau fonice sau pentru absorbtia zgomotului altele decat cele de la pozitiile  sau de la capitolul Argila expandata',
    'Lana de zgura de roca si alta lana minerala similara vermiculita expandata argile expandate zgura expandata si produse din materiale minerale similare expandate amestecuri si articole din materiale minerale pentru izolari termice sau fonice sau pentru absorbtia zgomotului altele decat cele de la pozitiile  sau de la capitolul Altele',
    'Lana de zgura de roca si alta lana minerala similara vermiculita expandata argile expandate zgura expandata si produse din materiale minerale similare expandate amestecuri si articole din materiale minerale pentru izolari termice sau fonice sau pentru absorbtia zgomotului altele decat cele de la pozitiile  sau de la capitolul Altele',
    'Articole din asfalt sau din materiale similare de exemplu bitum de petrol sau smoala de huila In rulouri',
    'Articole din asfalt sau din materiale similare de exemplu bitum de petrol sau smoala de huila Altele',
    'Panouri planse dale blocuri si articole similare din fibre vegetale din paie sau din talas aschii rumegus sau din alte deseuri din lemn aglomerate cu ciment ipsos sau alti lianti minerali Panouri planse dale blocuri si articole similare din fibre vegetale din paie sau din talas aschii rumegus sau din alte deseuri din lemn aglomerate cu ciment ipsos sau alti lianti minerali',
    'Articole din ipsos sau din compozitii pe baza de ipsos Acoperite sau intarite numai cu hartie sau cu carton',
    'Articole din ipsos sau din compozitii pe baza de ipsos Altele',
    'Articole din ipsos sau din compozitii pe baza de ipsos Alte articole',
    'Articole din ciment din beton sau din piatra artificiala chiar armate Din beton usor pe baza de piatra ponce sfaramata zgura granulata etc',
    'Articole din ciment din beton sau din piatra artificiala chiar armate Altele',
    'Articole din ciment din beton sau din piatra artificiala chiar armate Altele',
    'Articole din ciment din beton sau din piatra artificiala chiar armate Elemente prefabricate pentru cladiri sau constructii civile',
    'Articole din ciment din beton sau din piatra artificiala chiar armate Altele',
    'Articole din azbociment celulozociment sau similare Care contin azbest',
    'Articole din azbociment celulozociment sau similare Placi ondulate',
    'Articole din azbociment celulozociment sau similare Alte placi panouri pavele dale tigla si articole similare',
    'Articole din azbociment celulozociment sau similare Alte articole',
    'Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu articole din aceste amestecuri sau din azbest de exemplu fire tesaturi obiecte de imbracaminte obiecte de acoperit capul incaltamintegarnituri chiar armate altele decat cele de la pozitia sau  Prelucrat in fibre amestecuri pe baza de crocidolit sau pe baza de crocidolit si carbonat de magneziu',
    'Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu articole din aceste amestecuri sau din azbest de exemplu fire tesaturi obiecte de imbracaminte obiecte de acoperit capul incaltamintegarnituri chiar armate altele decat cele de la pozitia sau  Altele',
    'Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu articole din aceste amestecuri sau din azbest de exemplu fire tesaturi obiecte de imbracaminte obiecte de acoperit capul incaltamintegarnituri chiar armate altele decat cele de la pozitia sau  Imbracaminte accesorii de imbracaminte incaltaminte si obiecte de acoperit capul',
    'Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu articole din aceste amestecuri sau din azbest de exemplu fire tesaturi obiecte de imbracaminte obiecte de acoperit capul incaltamintegarnituri chiar armate altele decat cele de la pozitia sau  Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu',
    'Azbest prelucrat in fibre amestecuri pe baza de azbest sau pe baza de azbest si carbonat de magneziu articole din aceste amestecuri sau din azbest de exemplu fire tesaturi obiecte de imbracaminte obiecte de acoperit capul incaltamintegarnituri chiar armate altele decat cele de la pozitia sau  Altele',
    'Materiale de frictiune si articole din acestea de exemplu placi role benzi segmenti discuri saibe membrane nemontate pentru frane pentru ambreiaje sau pentru toate organele supuse frecarii din azbest sau din alte substante minerale sau celuloza chiar combinate cu materiale textile sau cu alte materiale Care contin azbest',
    'Materiale de frictiune si articole din acestea de exemplu placi role benzi segmenti discuri saibe membrane nemontate pentru frane pentru ambreiaje sau pentru toate organele supuse frecarii din azbest sau din alte substante minerale sau celuloza chiar combinate cu materiale textile sau cu alte materiale Garnituri de frana',
    'Materiale de frictiune si articole din acestea de exemplu placi role benzi segmenti discuri saibe membrane nemontate pentru frane pentru ambreiaje sau pentru toate organele supuse frecarii din azbest sau din alte substante minerale sau celuloza chiar combinate cu materiale textile sau cu alte materiale Altele',
    'Mica prelucrata si articole din mica inclusiv mica aglomerata sau reconstituita chiar pe suport din hartie din carton sau din alte materiale Placi foi si benzi de mica aglomerata sau reconstituita chiar pe suport',
    'Mica prelucrata si articole din mica inclusiv mica aglomerata sau reconstituita chiar pe suport din hartie din carton sau din alte materiale Altele',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Fibre de carbon',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Materiale textile din fibre de carbon',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Alte articole din fibre de carbon',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Altele',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Articole din turba',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Care contin magnezit magnezie sub forma de periclaz dolomita inclusiv sub forma de var dolomitic sau cromita',
    'Articole din piatra sau din alte minerale inclusiv din fibre de carbon si articole din acestea si articole din turba nedenumite si necuprinse in alta parte Altele',
    'Caramizi blocuri dale placi si alte produse ceramice din pulberi silicioase fosile de exemplu kiselgur tripolit diatomit sau din pamanturi silicioase similare Caramizi blocuri dale placi si alte produse ceramice din pulberi silicioase fosile de exemplu kiselgur tripolit diatomit sau din pamanturi silicioase similare',
    'Caramizi blocuri dale placi si produse similare din ceramica refractare pentru constructii altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut in greutate de peste  de Mg Ca sau Cr considerate izolat sau impreuna exprimate ca MgO CaO sau CrO',
    'Caramizi blocuri dale placi si produse similare din ceramica refractare pentru constructii altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut de silice SiO de minimum  din greutate',
    'Caramizi blocuri dale placi si produse similare din ceramica refractare pentru constructii altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut de alumina AlO de peste  dar sub  din greutate',
    'Caramizi blocuri dale placi si produse similare din ceramica refractare pentru constructii altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Altele',
    'Caramizi blocuri dale placi si produse similare din ceramica refractare pentru constructii altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Altele',
    'Alte produse ceramice refractare de exemplu retorte creuzete mufle ajutaje tampoane suporturi cupe tuburi tevi teci baghete profile si vane cu glisiera altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut in greutate de peste   de carbon liber',
    'Alte produse ceramice refractare de exemplu retorte creuzete mufle ajutaje tampoane suporturi cupe tuburi tevi teci baghete profile si vane cu glisiera altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut de alumina AlO sub  din greutate',
    'Alte produse ceramice refractare de exemplu retorte creuzete mufle ajutaje tampoane suporturi cupe tuburi tevi teci baghete profile si vane cu glisiera altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut de alumina AlO de minimum  din greutate',
    'Alte produse ceramice refractare de exemplu retorte creuzete mufle ajutaje tampoane suporturi cupe tuburi tevi teci baghete profile si vane cu glisiera altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Cu un continut de peste   din greutate dar maximum   carbon liber',
    'Alte produse ceramice refractare de exemplu retorte creuzete mufle ajutaje tampoane suporturi cupe tuburi tevi teci baghete profile si vane cu glisiera altele decat cele din pulberi silicioase fosile sau din pamanturi silicioase similare Altele',
    'Caramizi pentru constructii blocuri i dale pentru pardoseala caramizi pentru fatade si articole similare din ceramica Caramizi pentru constructii',
    'Caramizi pentru constructii blocuri i dale pentru pardoseala caramizi pentru fatade si articole similare din ceramica Altele',
    'Tigla pentru acoperisuri elemente de semineu canale de fum ornamente arhitectonice din ceramica si alte articole de constructie din ceramica Tigla pentru acoperisuri',
    'Tigla pentru acoperisuri elemente de semineu canale de fum ornamente arhitectonice din ceramica si alte articole de constructie din ceramica Altele',
    'Tevi jgheaburi si accesorii de tevarie din ceramica Tevi jgheaburi si accesorii de tevarie din ceramica',
    'Placi si dale din ceramica pentru pavaj sau pentru acoperit peretii cuburi din ceramica pentru mozaic si articole similare indiferent daca sunt pe un suport sau nu ceramica de finisaj Cu un coeficient de absorbtie a apei care nu depaseste  din greutate',
    'Placi si dale din ceramica pentru pavaj sau pentru acoperit peretii cuburi din ceramica pentru mozaic si articole similare indiferent daca sunt pe un suport sau nu ceramica de finisaj Cu un coeficient de absorbtie a apei care depaseste   dar nu depaseste  din greutate',
    'Placi si dale din ceramica pentru pavaj sau pentru acoperit peretii cuburi din ceramica pentru mozaic si articole similare indiferent daca sunt pe un suport sau nu ceramica de finisaj Cu un coeficient de absorbtie a apei care depaseste  din greutate',
    'Placi si dale din ceramica pentru pavaj sau pentru acoperit peretii cuburi din ceramica pentru mozaic si articole similare indiferent daca sunt pe un suport sau nu ceramica de finisaj Cuburi pentru mozaic si articole similare altele decat cele de la subpozitia',
    'Placi si dale din ceramica pentru pavaj sau pentru acoperit peretii cuburi din ceramica pentru mozaic si articole similare indiferent daca sunt pe un suport sau nu ceramica de finisaj Ceramica de finisaj',
    'Aparate si articole din ceramica pentru laborator utilizari chimice sau pentru alte scopuri tehnice jgheaburi rezervoare si recipiente similare din ceramica pentru gospodaria rurala ulcioare si recipiente similare de transport sau ambalare din ceramica Din portelan',
    'Aparate si articole din ceramica pentru laborator utilizari chimice sau pentru alte scopuri tehnice jgheaburi rezervoare si recipiente similare din ceramica pentru gospodaria rurala ulcioare si recipiente similare de transport sau ambalare din ceramica Articole cu o duritate echivalenta de minimum  pe scala Mohs',
    'Aparate si articole din ceramica pentru laborator utilizari chimice sau pentru alte scopuri tehnice jgheaburi rezervoare si recipiente similare din ceramica pentru gospodaria rurala ulcioare si recipiente similare de transport sau ambalare din ceramica Altele',
    'Aparate si articole din ceramica pentru laborator utilizari chimice sau pentru alte scopuri tehnice jgheaburi rezervoare si recipiente similare din ceramica pentru gospodaria rurala ulcioare si recipiente similare de transport sau ambalare din ceramica Altele',
    'Chiuvete lavoare suporti pentru lavoare cazi de baie bideuri vase de closet rezervoare de apa pentru closet pisoare si articole similare fixe din ceramica pentru uz sanitar Din portelan',
    'Chiuvete lavoare suporti pentru lavoare cazi de baie bideuri vase de closet rezervoare de apa pentru closet pisoare si articole similare fixe din ceramica pentru uz sanitar Altele',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din portelan Articole pentru servicii de masa sau de bucatarie',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din portelan Altele',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din ceramica comuna',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din gresie',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din faianta sau ceramica fina',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Altele',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din ceramica comuna',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din gresie',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Din faianta sau ceramica fina',
    'Articole pentru servicii de masa sau de bucatarie alte articole de menaj sau de uz gospodaresc si articole de igiena sau de toaleta din ceramica altele decat din portelan Altele',
    'Statuete si alte obiecte de ornament din ceramica Din portelan',
    'Statuete si alte obiecte de ornament din ceramica Din ceramica comuna',
    'Statuete si alte obiecte de ornament din ceramica Din faianta sau ceramica fina',
    'Statuete si alte obiecte de ornament din ceramica Altele',
    'Alte articole din ceramica Din portelan',
    'Alte articole din ceramica Altele',
    'Cioburi si alte deseuri si resturi din sticla cu exceptia sticlei provenite din tuburile catodice sau a altor tipuri de sticla activa de la pozitia  sticla in masa Cioburi si alte deseuri si resturi din sticla',
    'Cioburi si alte deseuri si resturi din sticla cu exceptia sticlei provenite din tuburile catodice sau a altor tipuri de sticla activa de la pozitia  sticla in masa Sticla optica',
    'Cioburi si alte deseuri si resturi din sticla cu exceptia sticlei provenite din tuburile catodice sau a altor tipuri de sticla activa de la pozitia  sticla in masa Altele',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Bile',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Din sticla optica',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Altele',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Din cuart sau din alte silice topite',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Din alta sticla cu un coeficient de dilatare liniara de maximum  per grad Kelvin intre C si C',
    'Bile din sticla altele decat microsferele de la pozitia bare baghete sau tuburi neprelucrate Altele',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Din sticla optica',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu strat nereflectorizant',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Altele',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Din sticla optica',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Altele',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Placi si foi armate',
    'Sticla turnata laminata in placi foi sau profile chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Profile',
    'Sticla trasa sau suflata in foi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Sticla optica',
    'Sticla trasa sau suflata in foi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu strat nereflectorizant',
    'Sticla trasa sau suflata in foi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Altele',
    'Sticla trasa sau suflata in foi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Sticla optica',
    'Sticla trasa sau suflata in foi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Altele',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu strat nereflectorizant',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel De maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel De peste mm dar de maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel De peste mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de peste mm dar de maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de peste mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de peste mm dar de maximum mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Cu grosimea de peste mm',
    'Geam sticla flotata si sticla slefuita sau polizata pe una sau doua fete in foi sau in placi chiar cu strat absorbant reflectorizant sau nereflectorizant dar neprelucrata altfel Geam armat',
    'Sticla de la pozitiile  sau  curbata bizotata taiata oblic gravata perforata emailata sau altfel prelucrata dar neinramata si necombinata cu alte materiale Sticla optica',
    'Sticla de la pozitiile  sau  curbata bizotata taiata oblic gravata perforata emailata sau altfel prelucrata dar neinramata si necombinata cu alte materiale Altele',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata De dimensiuni si forme care permit a fi folosite pentru autovehicule si tractoare',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Altele',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Emailate',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Colorate in masa opacifiate placate dublate sau cu strat absorbant sau reflectorizant',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Altele',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata De dimensiuni si forme care permit folosirea lor pentru autovehicule si tractoare',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Altele',
    'Sticla securit constand din sticla calita sau formata din foi lipite stratificata Altele',
    'Sticla izolanta cu straturi multiple Colorata in masa opacifiata placata dublata sau cu strat absorbant sau reflectorizant',
    'Sticla izolanta cu straturi multiple Formate din doua placi de sticla inchise ermetic de jur imprejur printro imbinare si separate printrun strat de aer de alt gaz sau de vid',
    'Sticla izolanta cu straturi multiple Altele',
    'Oglinzi din sticla chiar inramate inclusiv oglinzile retrovizoare Oglinzi retrovizoare pentru vehicule',
    'Oglinzi din sticla chiar inramate inclusiv oglinzile retrovizoare Neinramate',
    'Oglinzi din sticla chiar inramate inclusiv oglinzile retrovizoare Inramate',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Fiole',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Dopuri capace si alte dispozitive de inchidere',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Recipiente de sterilizare',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Obinute din tuburi tevi din sticla',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De peste l dar sub l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l dar de maximum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Sub l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De peste l dar sub l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l dar de maximum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Sub l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De minimum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Sub l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De peste l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla De maximum l',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Din sticla necolorata',
    'Damigene sticle baloane borcane cani ambalaje tubulare fiole si alte recipiente din sticla folosite pentru transport sau ambalare recipiente din sticla pentru conserve dopuri capace si alte dispozitive de inchidere din sticla Din sticla colorata',
    'Invelisuri din sticla inclusiv baloane si tuburi deschise si parti ale acestora din sticla fara garnituri pentru lampi electrice si surse de lumina tuburi catodice sau similare Pentru iluminat electric',
    'Invelisuri din sticla inclusiv baloane si tuburi deschise si parti ale acestora din sticla fara garnituri pentru lampi electrice si surse de lumina tuburi catodice sau similare Pentru tuburi catodice',
    'Invelisuri din sticla inclusiv baloane si tuburi deschise si parti ale acestora din sticla fara garnituri pentru lampi electrice si surse de lumina tuburi catodice sau similare Altele',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Obiecte din vitroceramica',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate manual',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate mecanic',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate manual',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate mecanic',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Slefuite sau altfel decorate',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Altele',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Slefuite sau altfel decorate',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Altele',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Din sticla calita',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Slefuite sau altfel decorate',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Altele',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Slefuite sau altfel decorate',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Altele',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate manual',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate mecanic',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Din sticla cu un coeficient de dilatare liniara de maximum  per grad Kelvin intre C si C',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Din sticla calita',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate manual',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate mecanic',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate manual',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Prelucrate mecanic',
    'Obiecte din sticla pentru servicii de masa pentru bucatarie toaleta birou pentru decorarea locuintelor sau pentru utilizari similare altele decat cele de la pozitiile sau  Altele',
    'Articole din sticla pentru semnalizare si elemente de optica din sticla altele decat cele de la pozitia neprelucrate optic Articole din sticla pentru semnalizare si elemente de optica din sticla altele decat cele de la pozitia neprelucrate optic',
    'Sticla pentru ceasuri si sticla similara sticla pentru industria optica sau medicala bombata curbata scobita sau similare neprelucrata optic sfere bule scobite si segmentele lor pentru fabricarea acestor obiecte din sticla Sticla pentru optica medicala',
    'Sticla pentru ceasuri si sticla similara sticla pentru industria optica sau medicala bombata curbata scobita sau similare neprelucrata optic sfere bule scobite si segmentele lor pentru fabricarea acestor obiecte din sticla Altele',
    'Pavele dale caramizi placi tigle si alte articole din sticla presata sau turnata chiar armata pentru cladiri sau constructii cuburi blocuri si alte articole din sticla chiar pe suport pentru mozaicuri sau decoratiuni similare sticla asamblata in vitralii sticla numita multicelulara sau sticla spumoasa in blocuri panouri placi cochilii sau forme similare Cuburi blocuri si alte articole din sticla chiar pe suport pentru mozaicuri sau decoratiuni similare',
    'Pavele dale caramizi placi tigle si alte articole din sticla presata sau turnata chiar armata pentru cladiri sau constructii cuburi blocuri si alte articole din sticla chiar pe suport pentru mozaicuri sau decoratiuni similare sticla asamblata in vitralii sticla numita multicelulara sau sticla spumoasa in blocuri panouri placi cochilii sau forme similare Sticla asamblata in vitralii',
    'Pavele dale caramizi placi tigle si alte articole din sticla presata sau turnata chiar armata pentru cladiri sau constructii cuburi blocuri si alte articole din sticla chiar pe suport pentru mozaicuri sau decoratiuni similare sticla asamblata in vitralii sticla numita multicelulara sau sticla spumoasa in blocuri panouri placi cochilii sau forme similare Blocuri si caramizi pentru cladiri sau constructii',
    'Pavele dale caramizi placi tigle si alte articole din sticla presata sau turnata chiar armata pentru cladiri sau constructii cuburi blocuri si alte articole din sticla chiar pe suport pentru mozaicuri sau decoratiuni similare sticla asamblata in vitralii sticla numita multicelulara sau sticla spumoasa in blocuri panouri placi cochilii sau forme similare Altele',
    'Sticlarie de laborator de igiena sau de farmacie chiar gradata sau calibrata Din cuart sau din alte silice topite',
    'Sticlarie de laborator de igiena sau de farmacie chiar gradata sau calibrata Din alta sticla cu coeficientul de dilatare liniara de maximum  per grad Kelvin intre C si C',
    'Sticlarie de laborator de igiena sau de farmacie chiar gradata sau calibrata Altele',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Slefuite si polizate mecanic',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Altele',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Imitatii de perle naturale sau de cultura',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Slefuite si polizate mecanic',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Altele',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Altele',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Microsfere din sticla cu diametrul de maximum mm',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Ochi de sticla obiecte de sticlarie',
    'Margele din sticla imitatii de perle naturale sau de cultura imitatii de pietre pretioase sau semipretioase articole similare de sticlarie si articole din acestea altele decat imitatiile de bijuterii ochi de sticla altii decat protezele statuete si alte articole de ornament din sticla prelucrata sau trasa sticla filata altele decat imitatiile de bijuterii microsfere din sticla cu diametrul de maximum mm Altele',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Fire taiate chopped strands cu lungimea de maximum mm',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Semitort rovings',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Alte fire suvite',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Covoare mats legate mecanic',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Covoare mats legate chimic',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Altele',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi din semitort rovings cu cusatura inchisa',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Deseuri si resturi',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Altele',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi din fire cu cusatura inchisa cu legatura panza neimbracate sau nestratificate',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi din fire cu cusatura inchisa cu legatura panza imbracate sau stratificate',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi din fire cu cusatura deschisa cu o latime de maximum cm',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi din fire cu cusatura deschisa cu o latime de minimum cm',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Tesaturi lucrate mecanic si tesaturi rasucite',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Altele',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Voaluri straturi subtiri',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Alte tesaturi cu cusatura inchisa',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Alte tesaturi cu cusatura deschisa',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Panouri saltele si produse similare',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Altele',
    'Fibre de sticla inclusiv vata de sticla si articole din aceste materiale de exemplu fire semitort rovings tesaturi Altele',
    'Alte articole din sticla Tuburi de reactie din cuart si suportii lor destinati cuptoarelor de difuzie si oxidare pentru producerea materialelor semiconductoare',
    'Alte articole din sticla Prelucrate incomplet',
    'Alte articole din sticla Prelucrate complet',
    'Alte articole din sticla Din cuart topit sau alte silice topite',
    'Alte articole din sticla Din sticla cu un coeficient de dilatare liniara de pana la  per grad Kelvin la o temperatura intre C si C',
    'Alte articole din sticla Altele',
    'Perle naturale sau de cultura chiar prelucrate sau asortate dar neinsirate nemontate neincastrate perle naturale sau de cultura insirate temporar pentru facilitarea transportului Perle naturale',
    'Perle naturale sau de cultura chiar prelucrate sau asortate dar neinsirate nemontate neincastrate perle naturale sau de cultura insirate temporar pentru facilitarea transportului Neprelucrate',
    'Perle naturale sau de cultura chiar prelucrate sau asortate dar neinsirate nemontate neincastrate perle naturale sau de cultura insirate temporar pentru facilitarea transportului Prelucrate',
    'Diamante chiar prelucrate dar nemontate si neincastrate Nesortate',
    'Diamante chiar prelucrate dar nemontate si neincastrate Neprelucrate sau simplu taiate despicate sau degrosate',
    'Diamante chiar prelucrate dar nemontate si neincastrate Altele',
    'Diamante chiar prelucrate dar nemontate si neincastrate Neprelucrate sau simplu taiate despicate sau degrosate',
    'Diamante chiar prelucrate dar nemontate si neincastrate Altele',
    'Pietre pretioase altele decat diamantele si semipretioase chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre pretioase si semipretioase altele decat diamantele neasortate insirate temporar pentru facilitarea transportului Neprelucrate sau simplu taiate sau degrosate',
    'Pietre pretioase altele decat diamantele si semipretioase chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre pretioase si semipretioase altele decat diamantele neasortate insirate temporar pentru facilitarea transportului Rubine safire si smaralde',
    'Pietre pretioase altele decat diamantele si semipretioase chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre pretioase si semipretioase altele decat diamantele neasortate insirate temporar pentru facilitarea transportului Altele',
    'Pietre artificiale sau reconstituite chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre artificiale sau reconstituite neasortate insirate temporar pentru facilitarea transportului Cuart piezoelectric',
    'Pietre artificiale sau reconstituite chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre artificiale sau reconstituite neasortate insirate temporar pentru facilitarea transportului Diamante',
    'Pietre artificiale sau reconstituite chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre artificiale sau reconstituite neasortate insirate temporar pentru facilitarea transportului Altele',
    'Pietre artificiale sau reconstituite chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre artificiale sau reconstituite neasortate insirate temporar pentru facilitarea transportului Diamante',
    'Pietre artificiale sau reconstituite chiar prelucrate sau asortate dar neinsirate nemontate neincastrate pietre artificiale sau reconstituite neasortate insirate temporar pentru facilitarea transportului Altele',
    'Pulberi si praf de pietre pretioase sau semipretioase naturale sau artificiale De diamant',
    'Pulberi si praf de pietre pretioase sau semipretioase naturale sau artificiale Altele',
    'Argint inclusiv argint aurit sau platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Pulberi',
    'Argint inclusiv argint aurit sau platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub forma bruta',
    'Argint inclusiv argint aurit sau platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Semiprelucrate',
    'Metale comune placate sau dublate cu argint sub forma bruta sau semiprelucrate Metale comune placate sau dublate cu argint sub forma bruta sau semiprelucrate',
    'Aur inclusiv aur platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Pulberi',
    'Aur inclusiv aur platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub alte forme brute',
    'Aur inclusiv aur platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Bare fire si profile cu sectiunea plina planse foi si benzi a caror grosime fara suport este de peste mm',
    'Aur inclusiv aur platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Altele',
    'Aur inclusiv aur platinat sub forma bruta sau semiprelucrata sau sub forma de pulbere Utilizat in monetarie',
    'Metale comune sau argint placate sau dublate cu aur sub forma bruta sau semiprelucrata Metale comune sau argint placate sau dublate cu aur sub forma bruta sau semiprelucrata',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub forma bruta sau sub forma de pulbere',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Bare tije fire si profile cu sectiunea plina planse foi si benzi a caror grosime fara suport este de peste mm',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Altele',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub forma bruta sau sub forma de pulbere',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Altele',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub forma bruta sau sub forma de pulbere',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Altele',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Sub forma bruta sau sub forma de pulbere',
    'Platina sub forma bruta sau semiprelucrata sau sub forma de pulbere Altele',
    'Metale comune argint sau aur placate sau dublate cu platina sub forma bruta sau semiprelucrata Metale comune argint sau aur placate sau dublate cu platina sub forma bruta sau semiprelucrata',
    'Deseuri si resturi de metale pretioase sau de metale placate sau dublate cu metale pretioase alte deseuri si resturi care contin metale pretioase sau compusi ai metalelor pretioase de tipul celor utilizate in principal pentru recuperarea metalelor pretioase altele decat produsele de la pozitia  Cenusi care contin metale pretioase sau compusi ai metalelor pretioase cu exceptia cenusilor de aur sau argint',
    'Deseuri si resturi de metale pretioase sau de metale placate sau dublate cu metale pretioase alte deseuri si resturi care contin metale pretioase sau compusi ai metalelor pretioase de tipul celor utilizate in principal pentru recuperarea metalelor pretioase altele decat produsele de la pozitia  De aur chiar metale placate sau dublate cu aur cu exceptia resturilor care contin alte metale pretioase',
    'Deseuri si resturi de metale pretioase sau de metale placate sau dublate cu metale pretioase alte deseuri si resturi care contin metale pretioase sau compusi ai metalelor pretioase de tipul celor utilizate in principal pentru recuperarea metalelor pretioase altele decat produsele de la pozitia  De platina chiar de metale placate sau dublate cu platina cu exceptia resturilor care contin alte metale pretioase',
    'Deseuri si resturi de metale pretioase sau de metale placate sau dublate cu metale pretioase alte deseuri si resturi care contin metale pretioase sau compusi ai metalelor pretioase de tipul celor utilizate in principal pentru recuperarea metalelor pretioase altele decat produsele de la pozitia  Altele',
    'Articole de bijuterie sau de giuvaergerie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din argint chiar acoperit placat sau dublat cu alte metale pretioase',
    'Articole de bijuterie sau de giuvaergerie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din alte metale pretioase chiar acoperite placate sau dublate cu metale pretioase',
    'Articole de bijuterie sau de giuvaergerie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din metale comune placate sau dublate cu metale pretioase',
    'Articole de aurarie sau argintarie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din argint chiar acoperit placat sau dublat cu alte metale pretioase',
    'Articole de aurarie sau argintarie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din alte metale pretioase chiar acoperite placate sau dublate cu metale pretioase',
    'Articole de aurarie sau argintarie si parti ale acestora din metale pretioase sau din metale placate sau dublate cu metale pretioase Din metale comune placate sau dublate cu metale pretioase',
    'Alte articole din metale pretioase sau din metale placate sau dublate cu metale pretioase Catalizatori din platina sub forma de plasa de sarma sau de retea',
    'Alte articole din metale pretioase sau din metale placate sau dublate cu metale pretioase Altele',
    'Articole din perle naturale sau de cultura din pietre pretioase sau semipretioase naturale artificiale sau reconstituite Din perle naturale sau de cultura',
    'Articole din perle naturale sau de cultura din pietre pretioase sau semipretioase naturale artificiale sau reconstituite Coliere bratari si alte lucrari numai din pietre pretioase sau semipretioase naturale simplu insirate fara dispozitiv de inchidere sau alte accesorii',
    'Articole din perle naturale sau de cultura din pietre pretioase sau semipretioase naturale artificiale sau reconstituite Altele',
    'Imitatii de bijuterii Butoni pentru mansete si butoni similari',
    'Imitatii de bijuterii Altele',
    'Imitatii de bijuterii Altele',
    'Monede Monede care nu au curs legal altele decat monedele din aur',
    'Monede Altele',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Cu un continut de siliciu de maximum  din greutate',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Cu un continut de siliciu de peste  din greutate',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Cu un continut de mangan de minimum  dar sub  din greutate',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Cu un continut de mangan sub',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Fonte brute nealiate care contin peste  din greutate fosfor',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Fonte brute aliate cu un continut de titan de minimum  si maximum  din greutate si cu un continut de vanadiu de minimum  si maximum',
    'Fonte brute si fonteoglinda spiegel in lingouri blocuri sau alte forme primare Altele',
    'Feroaliaje Cu o granulatie de maximum mm si cu un continut de mangan peste  din greutate',
    'Feroaliaje Altele',
    'Feroaliaje Altele',
    'Feroaliaje Care contine in greutate peste  siliciu',
    'Feroaliaje Cu un continut de magneziu de minimum  din greutate dar maximum',
    'Feroaliaje Altele',
    'Feroaliaje Ferosiliciumangan',
    'Feroaliaje Cu un continut de carbon de peste  din greutate dar maximum',
    'Feroaliaje Cu un continut de carbon de peste  din greutate',
    'Feroaliaje Cu un continut de carbon de maximum  din greutate',
    'Feroaliaje Cu un continut de carbon de peste  din greutate dar maximum',
    'Feroaliaje Cu un continut de carbon de peste  din greutate dar maximum',
    'Feroaliaje Ferosiliciucrom',
    'Feroaliaje Feronichel',
    'Feroaliaje Feromolibden',
    'Feroaliaje Ferotungsten si ferosiliciutungsten',
    'Feroaliaje Ferotitan si ferosiliciutitan',
    'Feroaliaje Ferovanadiu',
    'Feroaliaje Feroniobiu',
    'Feroaliaje Ferofosfor',
    'Feroaliaje Ferosiliciumagneziu',
    'Feroaliaje Altele',
    'Produse feroase obtinute prin reducerea directa a minereului de fier si alte produse feroase spongioase in bucati pelete sau forme similare fier cu o puritate minima in greutate de  in bucati pelete sau forme similare Produse feroase obtinute prin reducerea directa a minereului de fier',
    'Produse feroase obtinute prin reducerea directa a minereului de fier si alte produse feroase spongioase in bucati pelete sau forme similare fier cu o puritate minima in greutate de  in bucati pelete sau forme similare Altele',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Deseuri si resturi de fonta',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Cu un continut de nichel de minimum  din greutate',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Altele',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Altele',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Deseuri si resturi de fier sau de otel cositorit',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Deseuri de aschiere span deseuri de macinare pilitura',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Sub forma de baloturi',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Altele',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Fragmentate',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Sub forma de baloturi',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Altele',
    'Deseuri si resturi de fonta de fier sau de otel fier vechi deseuri lingotate din fier sau otel Deseuri lingotate',
    'Granule si pulberi de fonta bruta de fontaoglinda spiegel de fier sau de otel Granule',
    'Granule si pulberi de fonta bruta de fontaoglinda spiegel de fier sau de otel De oteluri aliate',
    'Granule si pulberi de fonta bruta de fontaoglinda spiegel de fier sau de otel Altele',
    'Fier si oteluri nealiate in lingouri sau in alte forme primare cu exceptia fierului de la pozitia Lingouri',
    'Fier si oteluri nealiate in lingouri sau in alte forme primare cu exceptia fierului de la pozitia Altele',
    'Semifabricate din fier sau din oteluri nealiate Din otel pentru prelucrare pe masiniunelte automate',
    'Semifabricate din fier sau din oteluri nealiate Cu grosimea de maximum mm',
    'Semifabricate din fier sau din oteluri nealiate Cu grosimea de peste mm',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Laminate sau obtinute prin turnare continua',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Laminate sau obtinute prin turnare continua',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Altele',
    'Semifabricate din fier sau din oteluri nealiate Din otel pentru prelucrare pe masiniunelte automate',
    'Semifabricate din fier sau din oteluri nealiate De minimum  dar sub  carbon',
    'Semifabricate din fier sau din oteluri nealiate De minimum  carbon',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Laminate sau obtinute prin turnare continua',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Laminate sau obtinute prin turnare continua',
    'Semifabricate din fier sau din oteluri nealiate Forjate',
    'Semifabricate din fier sau din oteluri nealiate Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite In rulouri simplu laminate la cald prezentand modele in relief',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de minimum mm dar sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de peste mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de minimum mm dar maximum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de minimum mm dar sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Altfel decat in rulouri simplu laminate la cald prezentand modele in relief',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime de peste mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite De minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Laminate pe cele patru fete sau in calibru inchis cu latimea de maximum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite De minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Mai mica de mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Laminate pe cele patru fete sau in calibru inchis cu latimea de maximum mm si grosimea de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Cu o grosime sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Perforate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la cald neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Cu o grosime de minimum mm dar sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Cu o grosime sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Perforate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm laminate la rece neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Tabla cositorita',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Acoperite cu plumb inclusiv fierul mat',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Acoperite electrolitic cu zinc',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Ondulate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Acoperite cu oxizi de crom sau cu crom si oxizi de crom',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Acoperite cu aliaje de aluminiuzinc',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Tabla cositorita lacuita produse acoperite cu oxizi de crom sau cu crom si oxizi de crom lacuite',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Placate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Cositorite si imprimate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime de minimum mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Laminate pe cele patru fete sau in calibru inchis cu o latime peste mm si cu o grosime de minimum mm altfel decat in rulouri si fara modele in relief',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Altele cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Numite magnetice',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Cu o grosime de minimum mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Cu o grosime sub mm',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Perforate',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm neplacate si neacoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Tabla cositorita simplu tratata la suprafata',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Acoperite electrolitic cu zinc',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Altfel acoperite cu zinc',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Tabla cositorita simplu lacuita produse acoperite cu oxizi de crom sau cu crom si oxizi de crom lacuite',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Acoperite cu oxizi de crom sau cu crom si oxizi de crom',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Acoperite cu crom sau nichel',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Acoperite cu cupru',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Acoperite cu aliaj de aluminiuzinc',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Altele',
    'Produse laminate plate din fier sau din oteluri nealiate cu o latime sub mm placate sau acoperite Placate',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care au crestaturi nervuri adancituri sau alte deformari adancite sau in relief produse in cursul laminarii',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Altele din oteluri pentru prelucrare pe masiniunelte automate',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate De tipul celor utilizate la armarea betonului',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate De tipul celor utilizate ca insertie pentru anvelope pneumatice',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate maximum  carbon',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate peste  carbon dar sub',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate minimum  carbon dar maximum',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate peste  carbon',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate sub  carbon',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Forjate',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Care au crestaturi nervuri adancituri sau alte deformari adancite sau in relief produse in cursul laminarii sau care au fost torsionate dupa laminare',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Altele din oteluri pentru prelucrare pe masiniunelte automate',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Care contin in greutate sub  carbon',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Care contin in greutate minimum  carbon',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare De tipul celor utilizate pentru armarea betonului',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare De minimum mm',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Sub mm',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Altele',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare De minimum mm',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Sub mm',
    'Alte bare si tije din fier sau din oteluri nealiate simplu forjate laminate trase sau extrudate la cald inclusiv cele torsionate dupa laminare Altele',
    'Alte bare si tije din fier sau din oteluri nealiate Din oteluri pentru prelucrare pe masiniunelte automate simplu obtinute sau finisate la rece',
    'Alte bare si tije din fier sau din oteluri nealiate Cu sectiunea dreptunghiulara alta decat patrata',
    'Alte bare si tije din fier sau din oteluri nealiate Altele',
    'Alte bare si tije din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Alte bare si tije din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Profile in forma de U I sau H simplu laminate trase la cald sau extrudate cu o inaltime sub mm',
    'Profile din fier sau din oteluri nealiate Profile in forma de L',
    'Profile din fier sau din oteluri nealiate Profile in forma de T',
    'Profile din fier sau din oteluri nealiate Cu inaltimea de minimum mm dar maximum mm',
    'Profile din fier sau din oteluri nealiate Cu inaltimea peste mm',
    'Profile din fier sau din oteluri nealiate Cu fetele aripilor paralele',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Cu fetele aripilor paralele',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Cu inaltimea de minimum mm dar maximum mm',
    'Profile din fier sau din oteluri nealiate Cu inaltimea peste mm',
    'Profile din fier sau din oteluri nealiate Profile in forma de L',
    'Profile din fier sau din oteluri nealiate Profile in forma de T',
    'Profile din fier sau din oteluri nealiate Cu sectiunea transversala care poate fi inscrisa intrun patrat cu latura de maximum mm',
    'Profile din fier sau din oteluri nealiate Profile plate cu proeminente',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Profile in forma de C L U Z  sau tub deschis',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Tabla cu nervuri striata',
    'Profile din fier sau din oteluri nealiate Altele',
    'Profile din fier sau din oteluri nealiate Altele',
    'Sarma din fier sau din oteluri nealiate Cu cea mai mare dimensiune a sectiunii transversale sub mm',
    'Sarma din fier sau din oteluri nealiate Care au crestaturi caneluri nervuri sau alte deformari adancite sau in relief produse in timpul procesului de laminare',
    'Sarma din fier sau din oteluri nealiate Altele',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon dar sub',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Sarma din fier sau din oteluri nealiate Cu cea mai mare dimensiune a sectiunii transversale sub mm',
    'Sarma din fier sau din oteluri nealiate Cu cea mai mare dimensiune a sectiunii transversale de minimum mm',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon dar sub',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Sarma din fier sau din oteluri nealiate Acoperite cu cupru',
    'Sarma din fier sau din oteluri nealiate Altele',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon dar sub',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate sub  carbon',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon dar sub',
    'Sarma din fier sau din oteluri nealiate Care contin in greutate minimum  carbon',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Lingouri si alte forme primare',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Care contin in greutate minimum  nichel',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Care contin in greutate sub  nichel',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Laminate sau obtinute prin turnare continua',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Forjate',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Laminate sau obtinute prin turnare continua',
    'Oteluri inoxidabile in lingouri sau in alte forme primare semifabricate din oteluri inoxidabile Forjate',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Cu o grosime de peste mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Cu o grosime de minimum mm dar sub mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Cu o grosime sub mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Cu o grosime de minimum mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Care contin in greutate sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Perforate',
    'Produse laminate plate din oteluri inoxidabile cu o latime de minimum mm Altele',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Cu o grosime de minimum mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Cu o grosime sub mm',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Minimum  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Sub  nichel',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Perforate',
    'Produse laminate plate din oteluri inoxidabile cu o latime sub mm Altele',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din oteluri inoxidabile Care contin in greutate minimum  nichel',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din oteluri inoxidabile Care contin in greutate sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Care contin in greutate minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Care contin in greutate sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Minimum  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Sub  nichel',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Altele',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Simplu laminate extrudate sau trase la cald',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Simplu obtinute sau finisate la rece',
    'Alte bare tije din oteluri inoxidabile profile din oteluri inoxidabile Altele',
    'Sarma din oteluri inoxidabile Cu un continut de nichel de minimum  dar maximum  si cu un continut de crom de minimum  dar maximum',
    'Sarma din oteluri inoxidabile Altele',
    'Sarma din oteluri inoxidabile Cu un continut de crom de minimum  dar de maximum  si cu un continut de aluminiu de minimum  dar maximum',
    'Sarma din oteluri inoxidabile Altele',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Din oteluri de scule',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Altele',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Din oteluri de scule',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Din oteluri rapide',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Cu un continut de carbon de maximum  de mangan de minimum  dar maximum  si de siliciu de minimum  dar maximum  din greutate cu un continut de bor de minimum  din greutate fara ca alt element sa atinga continutul minim indicat la nota literaf a acestui capitol',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Altele',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Altele',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Forjate',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Cu un continut de carbon de minimum  dar maximum  si un continut de crom de minimum  dar maximum  si eventual un continut de molibden de maximum  din greutate',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Altele',
    'Alte oteluri aliate in lingouri sau in alte forme primare semifabricate din alte oteluri aliate Forjate',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Cu graunti orientati',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Laminate la cald',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Laminate la rece',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Din oteluri de scule',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Din oteluri rapide',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Altele',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Din oteluri de scule',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Din oteluri rapide',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Cu o grosime de peste mm',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Cu o grosime de minimum mm dar de maximum mm',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Cu o grosime sub mm',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Din oteluri rapide',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Altele',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Acoperite electrolitic cu zinc',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Altfel acoperite cu zinc',
    'Produse laminate plate din alte oteluri aliate cu o latime de minimum mm Altele',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Cu graunti orientati',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Simplu laminate la cald',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Altele',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Din oteluri rapide',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Din oteluri de scule',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Cu o grosime de minimum mm',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Cu o grosime sub mm',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Simplu laminate la rece',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Acoperite electrolitic cu zinc',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Altfel acoperite cu zinc',
    'Produse laminate plate din alte oteluri aliate cu o latime sub mm Altele',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din alte oteluri aliate Din oteluri rapide',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din alte oteluri aliate Din oteluri silicomanganoase',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din alte oteluri aliate Cu un continut de bor de minimum  din greutate fara ca alt element sa atinga continutul minim prevazut la nota literaf a acestui capitol',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din alte oteluri aliate Cu un continut de carbon de minimum  dar maximum  si un continut de crom de minimum  dar maximum  si eventual cu un continut de molibden de maximum  din greutate',
    'Bare si tije laminate la cald in rulouri cu spire nearanjate fil machine din alte oteluri aliate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Simplu laminate extrudate sau trase la cald laminate extrudate sau trase la cald simplu placate',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Forjate',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Cu sectiunea dreptunghiulara alta decat patrata laminate la cald pe cele patru fete',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Simplu laminate extrudate sau trase la cald laminate extrudate sau trase la cald simplu placate',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Din oteluri de scule',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Cu sectiunea circulara cu un diametru de minimum mm',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate De minimum mm',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Sub mm',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Cu sectiunea dreptunghiulara alta decat patrata laminate la cald pe cele patru fete',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Din oteluri de scule',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Din oteluri de scule',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Cu un continut de carbon de minimum  dar maximum  si cu un continut de crom de minimum  dar maximum  si eventual cu un continut de molibden de maximum  din greutate',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate De minimum mm',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Sub mm',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Din oteluri de scule',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Simplu laminate trase la cald sau extrudate',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Altele',
    'Alte bare si tije profile din alte oteluri aliate bare si profile tubulare pentru foraj din oteluri aliate sau nealiate Bare si profile tubulare pentru foraj',
    'Sarma din alte oteluri aliate Din oteluri silicomanganoase',
    'Sarma din alte oteluri aliate Din oteluri rapide',
    'Sarma din alte oteluri aliate Cu un continut de carbon de minimum  dar maximum  si cu un continut de crom de minimum  dar maximum  si eventual cu un continut de molibden de maximum  din greutate',
    'Sarma din alte oteluri aliate Altele',
    'Palplanse din fier sau din otel chiar perforate sau confectionate din elemente asamblate profile obtinute prin sudare din fier sau din otel Palplanse',
    'Palplanse din fier sau din otel chiar perforate sau confectionate din elemente asamblate profile obtinute prin sudare din fier sau din otel Profile',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Conducatoare de curent cu parti din metale neferoase',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Cu o greutate pe metru de minimum kg',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Cu o greutate pe metru sub kg',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Sine cu sant',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Altele',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Folosite',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Eclise de imbinare si placi de baza',
    'Materiale de constructie pentru liniile ferate din fonta din fier sau din otel sine contrasine si cremaliere ace si inimi de macaz macazuri si alte elemente de incrucisare sau de schimbare de cale traverse eclise de imbinare cuzineti pene de fixare placi de baza placi de asezare placi de strangere placi si bare de ecartament si alte piese special concepute pentru asezarea imbinarea sau fixarea sinelor Altele',
    'Tuburi tevi si profile tubulare din fonta Tuburi si tevi de tipul celor utilizate pentru canalizari sub presiune',
    'Tuburi tevi si profile tubulare din fonta Altele',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Din oteluri inoxidabile',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior peste mm dar de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametru exterior peste mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Prajini de foraj din oteluri inoxidabile',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Alte prajini de foraj',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Altele din oteluri inoxidabile',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior peste mm dar de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior peste mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel De precizie',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Altele',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Tuburi filetate sau filetabile numite conducte de  gaz',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel De maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel De peste mm dar maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel De peste mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Trase sau laminate la rece',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior peste mm dar de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de peste mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Drepte si cu peretele de grosime uniforma din oteluri aliate cu un continut carbon de minimum   si maximum   si cu un continut de crom de la   la   si eventual cu un continut de molibden de maximum   din greutate',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel De precizie',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Altele',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Drepte si cu peretele de grosime uniforma din oteluri aliate cu un continut carbon de minimum   si maximum   si cu un continut de crom de la   la   si eventual cu un continut de molibden de maximum   din greutate',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de peste mm dar maximum mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Cu diametrul exterior de peste mm',
    'Tuburi tevi si profile tubulare fara sudura din fier sau din otel Altele',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Sudate longitudinal sub flux',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Altele sudate longitudinal',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Altele',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Tuburi si tevi de cuvelaj de tipul celor utilizate pentru extractia petrolului sau a gazelor',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Sudate longitudinal',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Altele',
    'Alte tuburi si tevi de exemplu sudate sau nituite cu sectiunea circulara cu diametrul exterior peste mm din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Sudate din oteluri inoxidabile',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Sudate din oteluri inoxidabile',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Trase sau laminate la rece',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Acoperite cu zinc',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Acoperite cu zinc',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel De peste mm dar maximum mm',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Trase sau laminate la rece',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Trase sau laminate la rece',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Din oteluri inoxidabile',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Cu grosimea peretelui de maximum mm',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Cu grosimea peretelui peste mm',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Din oteluri inoxidabile',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Alte tuburi tevi si profile tubulare de exemplu sudate nituite faltuite sau cu margini simplu apropiate din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Pentru tuburi si tevi de tipul celor utilizate pentru  sisteme sub presiune',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Din fonta',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Flanse',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Mansoane',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Coturi si curbe',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Coturi si curbe',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Filetate',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Flanse',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Mansoane',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Coturi si curbe',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Coturi si curbe',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Coturi si curbe',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Filetate',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din fonta din fier sau din otel Altele',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Poduri si elemente de poduri',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Turnuri si piloni',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Usi ferestre si tocurile lor pervazuri si praguri',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Echipament  de schelarie de cofraj de sustinere sau de sprijinire',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Panouri formate din doi pereti din tabla nervurata si cu un strat interior izolant',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Altele',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri porti de ecluze turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si tocurile lor pervazuri si praguri obloane balustrade din fonta din fier sau din otel cu exceptia constructiilor prefabricate de la pozitia table tole tije bare profile tevi si produse similare din fonta din fier sau din otel pregatite in vederea utilizarii lor in constructii Altele',
    'Rezervoare cisterne cuve si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Pentru gaze cu exceptia gazului comprimat sau lichefiat',
    'Rezervoare cisterne cuve si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cu captuseala sau izolatie termica',
    'Rezervoare cisterne cuve si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic De peste l',
    'Rezervoare cisterne cuve si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic De maximum l',
    'Rezervoare cisterne cuve si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Pentru substante solide',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cu o capacitate de minimum l',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cutii de tipul celor pentru conservarea alimentelor',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cutii de tipul celor pentru conservarea bauturilor',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Sub mm',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic De minimum mm',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cu o grosime a peretelui sub mm',
    'Rezervoare butoaie bidoane cutii si recipiente similare pentru orice fel de substante cu exceptia gazelor comprimate sau lichefiate din fonta din fier sau din otel cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Cu o grosime a peretelui de minimum mm',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel Sub l',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel De minimum l dar de maximum l',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel De peste l',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel Altele',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel Sub l',
    'Recipiente pentru gaze comprimate sau lichefiate din fonta din fier sau din otel De minimum l',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Din otel inoxidabil',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Acoperite cu aliaje pe baza de cupruzinc alama',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Altele',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Neacoperite',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Acoperite cu zinc',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Altele',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Peste mm dar de maximum mm',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Peste mm dar de maximum mm',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Peste mm dar de maximum mm',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Peste mm',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Altele',
    'Toroane cabluri benzi impletite parame odgoane si articole similare din fier sau din otel neizolate electric Altele',
    'Sarma ghimpata din fier sau din otel torsade ghimpate sau nu din sarma sau din benzi de fier sau de otel de tipul celor utilizate pentru imprejmuiri Sarma ghimpata din fier sau din otel torsade ghimpate sau nu din sarma sau din benzi de fier sau de otel de tipul celor utilizate pentru imprejmuiri',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Panze metalice continue sau fara sfarsit pentru utilaje din oteluri inoxidabile',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Alte panze metalice tesute din oteluri inoxidabile',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Altele',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Din sarma nervurata',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Altele',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Acoperite cu zinc',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Altele',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Acoperite cu zinc',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Acoperite cu materiale plastice',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Altele',
    'Panze metalice inclusiv panzele continue sau fara sfarsit grilaje plase si zabrele din sarma de fier sau de otel table si benzi expandate din fier sau din otel Table si benzi expandate',
    'Lanturi si partile acestora din fonta din fier sau din otel De tipul celor utilizate pentru biciclete si motociclete',
    'Lanturi si partile acestora din fonta din fier sau din otel Altele',
    'Lanturi si partile acestora din fonta din fier sau din otel Alte lanturi',
    'Lanturi si partile acestora din fonta din fier sau din otel Parti',
    'Lanturi si partile acestora din fonta din fier sau din otel Lanturi antiderapante',
    'Lanturi si partile acestora din fonta din fier sau din otel Lanturi cu zale cu punte de legatura',
    'Lanturi si partile acestora din fonta din fier sau din otel Alte lanturi cu zale sudate',
    'Lanturi si partile acestora din fonta din fier sau din otel Altele',
    'Lanturi si partile acestora din fonta din fier sau din otel Alte parti',
    'Ancore ancore gheare de pisica si partile acestora din fier sau din otel Ancore ancore gheare de pisica si partile acestora din fier sau din otel',
    'Cuie tinte pioneze crampoane cu varf agrafe ondulate sau cu margini taiate oblic altele decat cele de la pozitia si articole similare din fier sau din otel chiar cu cap din alte materiale cu exceptia celor cu cap din cupru Tinte prinse in benzi sau rulouri',
    'Cuie tinte pioneze crampoane cu varf agrafe ondulate sau cu margini taiate oblic altele decat cele de la pozitia si articole similare din fier sau din otel chiar cu cap din alte materiale cu exceptia celor cu cap din cupru Altele',
    'Cuie tinte pioneze crampoane cu varf agrafe ondulate sau cu margini taiate oblic altele decat cele de la pozitia si articole similare din fier sau din otel chiar cu cap din alte materiale cu exceptia celor cu cap din cupru Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Tirfoane',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Carlige si pitoane filetate',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Suruburi pentru tabla',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Pentru fixarea elementelor de cale ferata',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Sub MPa',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel De minimum MPa',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Din otel inoxidabil',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Sub MPa',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel De minimum MPa',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Piulite nit cu cap inecat',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Piulite nit cu cap inecat',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel De siguranta',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel De maximum mm',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel De peste mm',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Saibe elastice si alte saibe de blocare',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Alte saibe',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Nituri',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Stifturi si pene',
    'Suruburi buloane piulite tirfoane carlige filetate nituri stifturi pene saibe inclusiv saibele elastice de siguranta si articole similare din fonta din fier sau din otel Altele',
    'Ace de cusut ace de tricotat andrele crosete poansoane pentru broderie si articole similare pentru folosire manuala din fier sau din otel ace de siguranta si alte ace din fier sau din otel nedenumite si necuprinse in alta parte Ace de siguranta si alte ace',
    'Ace de cusut ace de tricotat andrele crosete poansoane pentru broderie si articole similare pentru folosire manuala din fier sau din otel ace de siguranta si alte ace din fier sau din otel nedenumite si necuprinse in alta parte Ace de cusut de stopat sau de brodat',
    'Ace de cusut ace de tricotat andrele crosete poansoane pentru broderie si articole similare pentru folosire manuala din fier sau din otel ace de siguranta si alte ace din fier sau din otel nedenumite si necuprinse in alta parte Altele',
    'Arcuri si foi de arcuri din fier sau din otel Arcuri parabolice si foile lor',
    'Arcuri si foi de arcuri din fier sau din otel Altele',
    'Arcuri si foi de arcuri din fier sau din otel Altele',
    'Arcuri si foi de arcuri din fier sau din otel Formate la cald',
    'Arcuri si foi de arcuri din fier sau din otel Arcuri de compresiune',
    'Arcuri si foi de arcuri din fier sau din otel Arcuri de tractiune',
    'Arcuri si foi de arcuri din fier sau din otel Altele',
    'Arcuri si foi de arcuri din fier sau din otel Arcuri plate in spirala',
    'Arcuri si foi de arcuri din fier sau din otel Arcuri in forma de discuri',
    'Arcuri si foi de arcuri din fier sau din otel Altele',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Cu cuptor inclusiv cuptoarele separate',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Altele',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Cu combustibili lichizi',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Altele inclusiv cu combustibili solizi',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Cu combustibili gazosi sau cu gaz si cu alti combustibili',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Cu combustibili lichizi',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Altele inclusiv cu combustibili solizi',
    'Sobe de incalzit cazane cu vatra masini de gatit cuptoare inclusiv cele care pot fi utilizate suplimentar si la incalzirea centrala gratare barbecue placi radiante resouri cu gaze plite de incalzit mancarea si aparate neelectrice similare pentru uz casnic precum si partile acestora din fier sau din otel Parti',
    'Radiatoare pentru incalzirea centrala cu incalzire neelectrica si partile acestora din fonta din fier sau din otel generatoare si distribuitoare de aer cald inclusiv cele care pot functiona si ca distribuitoare de aer rece sau de aer conditionat cu incalzire neelectrica prevazute cu un ventilator sau cu o suflanta cu motor si partile acestora din fier sau din otel Din fonta',
    'Radiatoare pentru incalzirea centrala cu incalzire neelectrica si partile acestora din fonta din fier sau din otel generatoare si distribuitoare de aer cald inclusiv cele care pot functiona si ca distribuitoare de aer rece sau de aer conditionat cu incalzire neelectrica prevazute cu un ventilator sau cu o suflanta cu motor si partile acestora din fier sau din otel Altele',
    'Radiatoare pentru incalzirea centrala cu incalzire neelectrica si partile acestora din fonta din fier sau din otel generatoare si distribuitoare de aer cald inclusiv cele care pot functiona si ca distribuitoare de aer rece sau de aer conditionat cu incalzire neelectrica prevazute cu un ventilator sau cu o suflanta cu motor si partile acestora din fier sau din otel Altele',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Din fonta neemailate',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Din fonta emailate',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Din oteluri inoxidabile',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Din fier sau din otel emailate',
    'Articole de menaj sau de uz gospodaresc si partile acestora din fonta din fier sau din otel sarma de parchet din fier sau din otel bureti spalatoare de vase manusi si articole similare pentru curatat lustruit sau utilizari similare din fier sau din otel Altele',
    'Articole sanitare de igiena sau de toaleta si partile acestora din fonta din fier sau din otel Chiuvete de bucatarie sau de baie din oteluri inoxidabile',
    'Articole sanitare de igiena sau de toaleta si partile acestora din fonta din fier sau din otel Din fonta chiar emailate',
    'Articole sanitare de igiena sau de toaleta si partile acestora din fonta din fier sau din otel Altele',
    'Articole sanitare de igiena sau de toaleta si partile acestora din fonta din fier sau din otel Altele inclusiv partile acestora',
    'Alte articole turnate din fonta din fier sau din otel Din fonta nemaleabila',
    'Alte articole turnate din fonta din fier sau din otel Bile si articole similare pentru concasoare',
    'Alte articole turnate din fonta din fier sau din otel Din fonta',
    'Alte articole turnate din fonta din fier sau din otel Altele',
    'Alte articole din fier sau din otel Bile si articole similare pentru concasoare',
    'Alte articole din fier sau din otel Forjate in matrita deschisa',
    'Alte articole din fier sau din otel Altele',
    'Alte articole din fier sau din otel Articole din sarma de fier sau de otel',
    'Alte articole din fier sau din otel Scari si trepte',
    'Alte articole din fier sau din otel Palete si tavi de genul celor utilizate pentru manipularea marfurilor',
    'Alte articole din fier sau din otel Bobine pentru cabluri furtune etc',
    'Alte articole din fier sau din otel Volete de aerisire nemecanice jgheaburi carlige si alte lucrari utilizate in industria constructiilor',
    'Alte articole din fier sau din otel Forjate in matrita deschisa',
    'Alte articole din fier sau din otel Forjate in matrita inchisa',
    'Alte articole din fier sau din otel Sinterizate',
    'Alte articole din fier sau din otel Altele',
    'Mate de cupru cupru de cementare precipitat de cupru Mate de cupru cupru de cementare precipitat de cupru',
    'Cupru nerafinat anozi din cupru pentru rafinare electrolitica Cupru nerafinat anozi din cupru pentru rafinare electrolitica',
    'Cupru rafinat si aliaje de cupru sub forma bruta Catozi si sectiuni de catozi',
    'Cupru rafinat si aliaje de cupru sub forma bruta Bare pentru sarma wirebars',
    'Cupru rafinat si aliaje de cupru sub forma bruta Tagle',
    'Cupru rafinat si aliaje de cupru sub forma bruta Altele',
    'Cupru rafinat si aliaje de cupru sub forma bruta Pe baza de cupruzinc alama',
    'Cupru rafinat si aliaje de cupru sub forma bruta Pe baza de cuprustaniu bronz',
    'Cupru rafinat si aliaje de cupru sub forma bruta Alte aliaje de cupru cu exceptia aliajelor de baza de la pozitia',
    'Deseuri si resturi de cupru De cupru rafinat',
    'Deseuri si resturi de cupru Pe baza de cupruzinc alama',
    'Deseuri si resturi de cupru Altele',
    'Aliaje de baza din cupru Aliaje de baza din cupru',
    'Pulberi si fulgi paiete din cupru Pulberi cu structura nelamelara',
    'Pulberi si fulgi paiete din cupru Pulberi cu structura lamelara fulgi paiete',
    'Bare tije si profile din cupru Din cupru rafinat',
    'Bare tije si profile din cupru Bare si tije',
    'Bare tije si profile din cupru Profile',
    'Bare tije si profile din cupru Altele',
    'Sarma din cupru A carei dimensiune maxima a sectiunii transversale este peste mm',
    'Sarma din cupru A caror cea mai mare dimensiune a sectiunii transversale este peste mm',
    'Sarma din cupru A caror cea mai mare dimensiune a sectiunii transversale este de maximum mm',
    'Sarma din cupru Pe baza de cupruzinc alama',
    'Sarma din cupru Pe baza de cuprunichel cupronichel sau de cuprunichelzinc alpaca',
    'Sarma din cupru Altele',
    'Tabla si banda din cupru cu o grosime peste mm In rulouri',
    'Tabla si banda din cupru cu o grosime peste mm Altele',
    'Tabla si banda din cupru cu o grosime peste mm In rulouri',
    'Tabla si banda din cupru cu o grosime peste mm Altele',
    'Tabla si banda din cupru cu o grosime peste mm In rulouri',
    'Tabla si banda din cupru cu o grosime peste mm Altele',
    'Tabla si banda din cupru cu o grosime peste mm Din aliaje pe baza de cuprunichel cupronichel sau de cuprunichelzinc alpaca',
    'Tabla si banda din cupru cu o grosime peste mm Din alte aliaje de cupru',
    'Folii si benzi subtiri din cupru chiar imprimate sau fixate pe hartie pe carton pe material plastic sau pe suporturi similare cu o grosime de maximum mm fara suport Din cupru rafinat',
    'Folii si benzi subtiri din cupru chiar imprimate sau fixate pe hartie pe carton pe material plastic sau pe suporturi similare cu o grosime de maximum mm fara suport Din aliaje de cupru',
    'Folii si benzi subtiri din cupru chiar imprimate sau fixate pe hartie pe carton pe material plastic sau pe suporturi similare cu o grosime de maximum mm fara suport Din cupru rafinat',
    'Folii si benzi subtiri din cupru chiar imprimate sau fixate pe hartie pe carton pe material plastic sau pe suporturi similare cu o grosime de maximum mm fara suport Din aliaje de cupru',
    'Tuburi si tevi din cupru Drepte',
    'Tuburi si tevi din cupru Altele',
    'Tuburi si tevi din cupru Drepte',
    'Tuburi si tevi din cupru Altele',
    'Tuburi si tevi din cupru Pe baza de cuprunichel cupronichel sau de cuprunichelzinc alpaca',
    'Tuburi si tevi din cupru Altele',
    'Accesorii de tevarie de exemplu racorduri coturi bucse din cupru Din cupru rafinat',
    'Accesorii de tevarie de exemplu racorduri coturi bucse din cupru Din aliaje de cupru',
    'Toroane cabluri odgoane benzi impletite si articole similare din cupru neizolate electric Toroane cabluri odgoane benzi impletite si articole similare din cupru neizolate electric',
    'Tinte cuie pioneze agrafe altele decat cele de la pozitia crampoane cu varf si articole similare din cupru sau cu tija din fier sau din otel si cap din cupru suruburi buloane piulite carlige filetate nituri cuie spintecate stifturi piroane pene saibe inele inclusiv saibele si inelele elastice de siguranta si articole similare din cupru Tinte cuie pioneze agrafe crampoane cu varf si articole similare',
    'Tinte cuie pioneze agrafe altele decat cele de la pozitia crampoane cu varf si articole similare din cupru sau cu tija din fier sau din otel si cap din cupru suruburi buloane piulite carlige filetate nituri cuie spintecate stifturi piroane pene saibe inele inclusiv saibele si inelele elastice de siguranta si articole similare din cupru Saibe si inele inclusiv saibe si inele elastice de siguranta',
    'Tinte cuie pioneze agrafe altele decat cele de la pozitia crampoane cu varf si articole similare din cupru sau cu tija din fier sau din otel si cap din cupru suruburi buloane piulite carlige filetate nituri cuie spintecate stifturi piroane pene saibe inele inclusiv saibele si inelele elastice de siguranta si articole similare din cupru Altele',
    'Tinte cuie pioneze agrafe altele decat cele de la pozitia crampoane cu varf si articole similare din cupru sau cu tija din fier sau din otel si cap din cupru suruburi buloane piulite carlige filetate nituri cuie spintecate stifturi piroane pene saibe inele inclusiv saibele si inelele elastice de siguranta si articole similare din cupru Suruburi buloane si piulite',
    'Tinte cuie pioneze agrafe altele decat cele de la pozitia crampoane cu varf si articole similare din cupru sau cu tija din fier sau din otel si cap din cupru suruburi buloane piulite carlige filetate nituri cuie spintecate stifturi piroane pene saibe inele inclusiv saibele si inelele elastice de siguranta si articole similare din cupru Altele',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din cupru bureti din sarma spalatori de vase manusi si articole similare pentru curatat pentru lustruit sau pentru utilizari similare din cupru Aparate neelectrice pentru gatit sau pentru incalzit de tipul celor de uz gospodaresc si partile acestora din cupru',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din cupru bureti din sarma spalatori de vase manusi si articole similare pentru curatat pentru lustruit sau pentru utilizari similare din cupru Altele',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din cupru bureti din sarma spalatori de vase manusi si articole similare pentru curatat pentru lustruit sau pentru utilizari similare din cupru Articole sanitare de igiena sau de toaleta si partile acestora',
    'Alte articole din cupru Turnate formate stantate sau forjate dar neprelucrate altfel',
    'Alte articole din cupru Panze metalice inclusiv panze continue sau fara sfarsit grilaje si plase din sarma de cupru a carei dimensiune maxima a sectiunii transversale nu depaseste mm table si benzi expandate',
    'Alte articole din cupru Arcuri',
    'Alte articole din cupru Altele',
    'Mate de nichel sinteri de oxizi de nichel si alte produse intermediare ale metalurgiei nichelului Mate de nichel',
    'Mate de nichel sinteri de oxizi de nichel si alte produse intermediare ale metalurgiei nichelului Sinteri de oxizi de nichel si alte produse intermediare ale metalurgiei nichelului',
    'Nichel sub forma bruta Nichel nealiat',
    'Nichel sub forma bruta Aliaje de nichel',
    'Deseuri si resturi din nichel Din nichel nealiat',
    'Deseuri si resturi din nichel Din aliaje de nichel',
    'Pulberi si fulgi paiete din nichel Pulberi si fulgi paiete din nichel',
    'Bare tije profile si sarma din nichel Din nichel nealiat',
    'Bare tije profile si sarma din nichel Din aliaje de nichel',
    'Bare tije profile si sarma din nichel Din nichel nealiat',
    'Bare tije profile si sarma din nichel Din aliaje de nichel',
    'Table benzi si folii din nichel Din nichel nealiat',
    'Table benzi si folii din nichel Din aliaje de nichel',
    'Tuburi tevi si accesorii de tevarie de exemplu racorduri coturi mansoane din nichel Din nichel nealiat',
    'Tuburi tevi si accesorii de tevarie de exemplu racorduri coturi mansoane din nichel Din aliaje de nichel',
    'Tuburi tevi si accesorii de tevarie de exemplu racorduri coturi mansoane din nichel Accesorii de tevarie',
    'Alte articole din nichel Panze metalice si grilaje din sarma de nichel',
    'Alte articole din nichel Altele',
    'Aluminiu sub forma bruta Brame',
    'Aluminiu sub forma bruta Altele',
    'Aluminiu sub forma bruta Brame',
    'Aluminiu sub forma bruta Tagle',
    'Aluminiu sub forma bruta Altele',
    'Deseuri si resturi din aluminiu Deseuri de aschiere span deseuri de macinare pilitura deseuri de foi si de benzi subtiri colorate acoperite sau lipite intre ele cu o grosime de maximum mm fara suport',
    'Deseuri si resturi din aluminiu Altele inclusiv rebuturile de fabricatie',
    'Deseuri si resturi din aluminiu Resturi',
    'Pulberi si fulgi paiete din aluminiu Pulberi cu structura nelamelara',
    'Pulberi si fulgi paiete din aluminiu Pulberi cu structura lamelara fulgi paiete',
    'Bare tije si profile din aluminiu Bare si tije',
    'Bare tije si profile din aluminiu Profile',
    'Bare tije si profile din aluminiu Profile tubulare',
    'Bare tije si profile din aluminiu Bare si tije',
    'Bare tije si profile din aluminiu Profile',
    'Sarma din aluminiu A carei dimensiune maxima a sectiunii transversale este de peste mm',
    'Sarma din aluminiu Altele',
    'Sarma din aluminiu A carei dimensiune maxima a sectiunii transversale este de peste mm',
    'Sarma din aluminiu Altele',
    'Table si benzi de aluminiu cu o grosime peste mm Panouri compozite din aluminiu',
    'Table si benzi de aluminiu cu o grosime peste mm Lacuite vopsite sau acoperite cu material plastic',
    'Table si benzi de aluminiu cu o grosime peste mm Sub mm',
    'Table si benzi de aluminiu cu o grosime peste mm De minimum mm dar sub mm',
    'Table si benzi de aluminiu cu o grosime peste mm De minimum mm',
    'Table si benzi de aluminiu cu o grosime peste mm Pentru corpuri de doze pentru bauturi',
    'Table si benzi de aluminiu cu o grosime peste mm Pentru capace si inele de doze pentru bauturi',
    'Table si benzi de aluminiu cu o grosime peste mm Panouri compozite din aluminiu',
    'Table si benzi de aluminiu cu o grosime peste mm Lacuite vopsite sau acoperite cu material plastic',
    'Table si benzi de aluminiu cu o grosime peste mm Sub mm',
    'Table si benzi de aluminiu cu o grosime peste mm De minimum mm dar sub mm',
    'Table si benzi de aluminiu cu o grosime peste mm De minimum mm',
    'Table si benzi de aluminiu cu o grosime peste mm Din aluminiu nealiat',
    'Table si benzi de aluminiu cu o grosime peste mm Din aliaje de aluminiu',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport In rulouri avand fiecare o greutate de maximum kg',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Altele',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Cu grosimea de minimum mm dar maximum mm',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Cu grosimea sub mm',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Cu grosimea de minimum mm dar maximum mm',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Cu grosimea grosimea suportului nu este inclusa sub mm',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Panouri compozite din aluminiu',
    'Folii si benzi subtiri din aluminiu chiar imprimate sau fixate pe hartie pe carton pe materiale plastice sau pe alte suporturi similare cu o grosime de maximum mm fara suport Altele',
    'Tuburi si tevi din aluminiu Din aluminiu nealiat',
    'Tuburi si tevi din aluminiu Sudate',
    'Tuburi si tevi din aluminiu Simplu extrudate',
    'Tuburi si tevi din aluminiu Altele',
    'Accesorii de tevarie de exemplu racorduri coturi mansoane din aluminiu Accesorii de tevarie de exemplu racorduri coturi mansoane din aluminiu',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si ramele acestora pervazuri praguri balustrade din aluminiu cu exceptia constructiilor prefabricate de la pozitia table bare profile tuburi si similare pregatite in vederea utilizarii in constructii Usi ferestre si ramele acestora pervazuri si praguri',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si ramele acestora pervazuri praguri balustrade din aluminiu cu exceptia constructiilor prefabricate de la pozitia table bare profile tuburi si similare pregatite in vederea utilizarii in constructii Poduri si elemente de poduri turnuri si piloni',
    'Constructii si parti de constructii de exemplu poduri si elemente de poduri turnuri piloni stalpi coloane sarpante acoperisuri usi si ferestre si ramele acestora pervazuri praguri balustrade din aluminiu cu exceptia constructiilor prefabricate de la pozitia table bare profile tuburi si similare pregatite in vederea utilizarii in constructii Altele',
    'Rezervoare cisterne cuve si recipiente similare pentru orice substante cu exceptia gazelor comprimate sau lichefiate din aluminiu cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Rezervoare cisterne cuve si recipiente similare pentru orice substante cu exceptia gazelor comprimate sau lichefiate din aluminiu cu o capacitate peste l fara dispozitive mecanice sau termice chiar captusite sau izolate termic',
    'Rezervoare butoaie butii bidoane cutii si recipiente similare din aluminiu inclusiv recipiente tubulare rigide sau flexibile pentru orice substante cu exceptia gazelor comprimate sau lichefiate cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Recipiente tubulare flexibile',
    'Rezervoare butoaie butii bidoane cutii si recipiente similare din aluminiu inclusiv recipiente tubulare rigide sau flexibile pentru orice substante cu exceptia gazelor comprimate sau lichefiate cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Recipiente de tipul celor utilizate pentru aerosoli',
    'Rezervoare butoaie butii bidoane cutii si recipiente similare din aluminiu inclusiv recipiente tubulare rigide sau flexibile pentru orice substante cu exceptia gazelor comprimate sau lichefiate cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Fabricate din foita cu o grosime de maximum mm',
    'Rezervoare butoaie butii bidoane cutii si recipiente similare din aluminiu inclusiv recipiente tubulare rigide sau flexibile pentru orice substante cu exceptia gazelor comprimate sau lichefiate cu o capacitate de maximum l fara dispozitive mecanice sau termice chiar captusite sau izolate termic Altele',
    'Recipiente din aluminiu pentru gaze comprimate sau lichefiate Recipiente din aluminiu pentru gaze comprimate sau lichefiate',
    'Toroane cabluri benzi impletite si articole similare din aluminiu neizolate electric Cu inima de otel',
    'Toroane cabluri benzi impletite si articole similare din aluminiu neizolate electric Altele',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din aluminiu bureti de sarma spalatoare de vase manusi si articole similare pentru curatare pentru lustruire sau pentru utilizari similare din aluminiu Turnate',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din aluminiu bureti de sarma spalatoare de vase manusi si articole similare pentru curatare pentru lustruire sau pentru utilizari similare din aluminiu Fabricate din foita cu o grosime de maximum mm',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din aluminiu bureti de sarma spalatoare de vase manusi si articole similare pentru curatare pentru lustruire sau pentru utilizari similare din aluminiu Altele',
    'Articole de uz casnic sau de uz gospodaresc sanitare de igiena sau de toaleta si partile acestora din aluminiu bureti de sarma spalatoare de vase manusi si articole similare pentru curatare pentru lustruire sau pentru utilizari similare din aluminiu Articole sanitare de igiena sau de toaleta si partile acestora',
    'Alte articole din aluminiu Tinte cuie crampoane cu varf suruburi buloane piulite carlige cu filet nituri cuie spintecate stifturi pene saibe si inele si articole similare',
    'Alte articole din aluminiu Panze metalice grilaje si plase din sarma de aluminiu',
    'Alte articole din aluminiu Turnate',
    'Alte articole din aluminiu Altele',
    'Plumb sub forma bruta Plumb rafinat',
    'Plumb sub forma bruta Care contine stibiu antimoniu ca alt element predominant in greutate',
    'Plumb sub forma bruta Pentru rafinare continand minimum  din greutate argint lingouri de plumb',
    'Plumb sub forma bruta Altele',
    'Deseuri si resturi de plumb Deseuri si resturi de plumb',
    'Placi table folii si benzi din plumb pulberi si fulgi paiete din plumb Folii si benzi cu o grosime de maximum mm fara a considera suportul',
    'Placi table folii si benzi din plumb pulberi si fulgi paiete din plumb Altele',
    'Placi table folii si benzi din plumb pulberi si fulgi paiete din plumb Pulberi si fulgi paiete',
    'Alte articole din plumb Ambalaje prevazute cu blindaje de protectie din plumb impotriva radiatiilor pentru transportul sau depozitarea materialelor radioactive Euratom',
    'Alte articole din plumb Altele',
    'Zinc sub forma bruta Care contine in greutate minimum  zinc',
    'Zinc sub forma bruta Care contine in greutate minimum  dar sub  zinc',
    'Zinc sub forma bruta Care contine in greutate minimum  dar sub  zinc',
    'Zinc sub forma bruta Care contine in greutate minimum  dar sub  zinc',
    'Zinc sub forma bruta Aliaje de zinc',
    'Deseuri si resturi din zinc Deseuri si resturi din zinc',
    'Praf pulberi si fulgi  paiete din zinc Praf de zinc',
    'Praf pulberi si fulgi  paiete din zinc Altele',
    'Bare tije profile si sarma din zinc Bare tije profile si sarma din zinc',
    'Table folii si benzi din zinc Table folii si benzi din zinc',
    'Alte articole din zinc Alte articole din zinc',
    'Staniu sub forma bruta Staniu nealiat',
    'Staniu sub forma bruta Aliaje de staniu',
    'Deseuri si resturi de staniu Deseuri si resturi de staniu',
    'Bare tije profile si sarma din staniu Bare tije profile si sarma din staniu',
    'Alte articole din staniu Table benzi si folii cu o grosime peste mm',
    'Alte articole din staniu Altele',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Pulberi',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Tungsten sub forma bruta inclusiv barele si tijele simplu obtinute prin sinterizare',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Sarma',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Deseuri si resturi',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Bare si tije altele decat cele simplu obtinute prin sinterizare profile table benzi si folii',
    'Tungsten wolfram si articole din tungsten inclusiv deseuri si resturi Altele',
    'Molibden si articole din molibden inclusiv deseuri si resturi Pulberi',
    'Molibden si articole din molibden inclusiv deseuri si resturi Molibden sub forma bruta inclusiv barele si tijele simplu obtinute prin sinterizare',
    'Molibden si articole din molibden inclusiv deseuri si resturi Bare si tije altele decat cele simplu obtinute prin sinterizare profile table benzi si folii',
    'Molibden si articole din molibden inclusiv deseuri si resturi Sarma',
    'Molibden si articole din molibden inclusiv deseuri si resturi Deseuri si resturi',
    'Molibden si articole din molibden inclusiv deseuri si resturi Altele',
    'Tantal si articole din tantal inclusiv deseuri si resturi Tantal sub forma bruta inclusiv barele si tijele simplu obtinute prin sinterizare pulberi',
    'Tantal si articole din tantal inclusiv deseuri si resturi Deseuri si resturi',
    'Tantal si articole din tantal inclusiv deseuri si resturi Creuzete',
    'Tantal si articole din tantal inclusiv deseuri si resturi Bare si tije altele decat cele obtinute prin sinterizare profile sarme table benzi si folii',
    'Tantal si articole din tantal inclusiv deseuri si resturi Altele',
    'Magneziu si articole din magneziu inclusiv deseuri si resturi Care contine minimum  magneziu in greutate',
    'Magneziu si articole din magneziu inclusiv deseuri si resturi Altele',
    'Magneziu si articole din magneziu inclusiv deseuri si resturi Deseuri si resturi',
    'Magneziu si articole din magneziu inclusiv deseuri si resturi Span si granule calibrate pulberi',
    'Magneziu si articole din magneziu inclusiv deseuri si resturi Altele',
    'Mate de cobalt si alte produse intermediare ale metalurgiei cobaltului cobalt si articole din cobalt inclusiv deseuri si resturi Mate de cobalt si alte produse intermediare ale metalurgiei cobaltului cobalt sub forma bruta pulberi',
    'Mate de cobalt si alte produse intermediare ale metalurgiei cobaltului cobalt si articole din cobalt inclusiv deseuri si resturi Deseuri si resturi',
    'Mate de cobalt si alte produse intermediare ale metalurgiei cobaltului cobalt si articole din cobalt inclusiv deseuri si resturi Altele',
    'Bismut si articole din bismut inclusiv deseuri si resturi Bismut sub forma bruta deseuri si resturi pulberi',
    'Bismut si articole din bismut inclusiv deseuri si resturi Altele',
    'Bismut si articole din bismut inclusiv deseuri si resturi Bismut sub forma bruta deseuri si resturi pulberi',
    'Bismut si articole din bismut inclusiv deseuri si resturi Altele',
    'Titan si articole din titan inclusiv deseuri si resturi Titan sub forma bruta pulberi',
    'Titan si articole din titan inclusiv deseuri si resturi Deseuri si resturi',
    'Titan si articole din titan inclusiv deseuri si resturi Bare tije profile si sarma',
    'Titan si articole din titan inclusiv deseuri si resturi Table benzi si foi',
    'Titan si articole din titan inclusiv deseuri si resturi Tuburi si tevi',
    'Titan si articole din titan inclusiv deseuri si resturi Altele',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Cu un continut in greutate mai mic de  parte hafniu la  de parti zirconiu',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Altele',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Cu un continut in greutate mai mic de  parte hafniu la  de parti zirconiu',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Altele',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Cu un continut in greutate mai mic de  parte hafniu la  de parti zirconiu',
    'Zirconiu si articole din zirconiu inclusiv deseuri si resturi Altele',
    'Antimoniu stibiu si articole din antimoniu inclusiv deseuri si resturi Antimoniu stibiu sub forma bruta pulberi',
    'Antimoniu stibiu si articole din antimoniu inclusiv deseuri si resturi Deseuri si resturi',
    'Antimoniu stibiu si articole din antimoniu inclusiv deseuri si resturi Altele',
    'Mangan si articole din mangan inclusiv deseuri si resturi Mangan sub forma bruta pulberi',
    'Mangan si articole din mangan inclusiv deseuri si resturi Deseuri si resturi',
    'Mangan si articole din mangan inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Sub forma bruta pulberi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Aliaje de crom cu un continut de nichel peste  din greutate',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Sub forma bruta deseuri si resturi pulberi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Sub forma bruta pulberi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Cadmiu sub forma bruta pulberi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Altele',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Deseuri si resturi',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Niobiu columbiu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Indiu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Galiu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Vanadiu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Germaniu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Germaniu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Niobiu columbiu',
    'Beriliu crom hafniu reniu taliu cadmiu germaniu vanadiu galiu indiu si niobiu columbiu articole din aceste metale inclusiv deseuri si resturi Galiu indiu vanadiu',
    'Metaloceramice si articole din compui metaloceramici inclusiv deseuri si resturi Sub forma bruta',
    'Metaloceramice si articole din compui metaloceramici inclusiv deseuri si resturi Deseuri si resturi',
    'Metaloceramice si articole din compui metaloceramici inclusiv deseuri si resturi Altele',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Cazmale harlete si lopeti',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Tarnacoape sape sapaligi greble si raclete',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Securi topoare barde si unelte similare cu tais',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Foarfece de gradinar si alte foarfece similare actionate cu o singura mana inclusiv foarfece pentru pasari de curte',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Foarfece pentru garduri vii de gradinar si alte unelte similare actionate cu doua maini',
    'Cazmale harlete lopeti tarnacoape sape sapaligi furci greble si raclete securi topoare barde cosoare de gradina si unelte similare cu tais foarfece de gradinar de orice tip seceri si coase cutite pentru fan sau pentru paie foarfece pentru garduri vii pene pentru spart lemne si alte unelte agricole horticole sau forestiere manuale Alte unelte agricole horticole sau forestiere manuale',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Ferastraie manuale',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Panze de ferastraubanda ferastraupanglica',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Cu partea activa din otel',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Altele inclusiv parti',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Lanturi numite taietoare pentru ferastraie cu lant',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Panze drepte de ferastrau pentru prelucrarea metalelor',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Pentru prelucrarea metalelor',
    'Ferastraie manuale panze de ferastrau de orice fel inclusiv frezeferastrau si panze nedanturate pentru taiere Pentru prelucrarea altor materiale',
    'Pile raspele clesti chiar pentru taiat patente pensete foarfece de taiat metale unelte de taiat tevi unelte de taiat buloane poansoane preducele si unelte similare de mana Pile raspele si unelte similare',
    'Pile raspele clesti chiar pentru taiat patente pensete foarfece de taiat metale unelte de taiat tevi unelte de taiat buloane poansoane preducele si unelte similare de mana Clesti chiar pentru taiat patente pensete si unelte similare',
    'Pile raspele clesti chiar pentru taiat patente pensete foarfece de taiat metale unelte de taiat tevi unelte de taiat buloane poansoane preducele si unelte similare de mana Foarfece de taiat metale si unelte similare',
    'Pile raspele clesti chiar pentru taiat patente pensete foarfece de taiat metale unelte de taiat tevi unelte de taiat buloane poansoane preducele si unelte similare de mana Unelte de taiat tevi unelte de taiat buloane preducele si unelte similare',
    'Chei manuale de strangere inclusiv chei dinamometrice bucse si mansoane de strangere interschimbabile cu sau fara maner Fixe',
    'Chei manuale de strangere inclusiv chei dinamometrice bucse si mansoane de strangere interschimbabile cu sau fara maner Reglabile',
    'Chei manuale de strangere inclusiv chei dinamometrice bucse si mansoane de strangere interschimbabile cu sau fara maner Bucse si mansoane de strangere interschimbabile cu sau fara maner',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Unelte si scule de gaurit de filetat sau de tarodat',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Ciocane baroase si maiuri',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Rindele dalti si unelte similare de taiat pentru prelucrarea lemnului',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Surubelnite',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala De uz gospodaresc',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Unelte pentru zidarie turnare cimentare modelare ipsos si zugravit',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Altele',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Lampi de sudura sau de lipit si similare',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Menghine cleme de strangere si similare',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Nicovale forje portative polizoare cu batiuri manuale sau cu pedala',
    'Unelte si scule manuale inclusiv diamante pentru taiat geamuri nedenumite si necuprinse in alta parte lampi de sudura sau de lipit si similare menghine cleme de strangere si similare altele decat cele care constituie accesorii sau parti de masiniunelte sau masini de taiat cu jet de apa nicovale forje portative polizoare cu batiuri manuale sau cu pedala Seturi de articole de la cel putin doua dintre subpozitiile de la aceasta pozitie',
    'Unelte de la cel putin doua din pozitiile conditionate in seturi pentru vanzarea cu amanuntul Unelte de la cel putin doua din pozitiile conditionate in seturi pentru vanzarea cu amanuntul',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din compui metaloceramici',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din diamant sau din diamant aglomerat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din diamant sau din diamant aglomerat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din alte materiale',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Pentru prelucrarea metalelor',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Unelte pentru tarodat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Unelte pentru filetat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din diamant sau din diamant aglomerat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Sfredele pentru zidarie',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din compui metaloceramici',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din oteluri rapide',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din alte materiale',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din diamant sau din diamant aglomerat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Pentru prelucrarea metalelor',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Pentru prelucrarea metalelor',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din compui metaloceramici',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Freze cu coada',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din compui metaloceramici',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Din alte materiale',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Cu partea activa din diamant sau din diamant aglomerat',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Varfuri bits de surubelnite',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Unelte de danturare pentru prelucrarea rotilor dintate',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Pentru prelucrarea metalelor',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Pentru prelucrarea metalelor',
    'Unelte interschimbabile pentru utilaje de mana mecanice sau nu sau pentru masiniunelte de exemplu de ambutisat de stantat de poansonat de tarodat de filetat de gaurit de alezat de brosat de frezat de strunjit de insurubat inclusiv filiere pentru tragerea sau extrudarea metalelor precum si  scule pentru gaurirea rocilor sau sondarea terenului Altele',
    'Cutite si lame taietoare pentru masini sau pentru aparate mecanice Pentru prelucrarea metalelor',
    'Cutite si lame taietoare pentru masini sau pentru aparate mecanice Pentru prelucrarea lemnului',
    'Cutite si lame taietoare pentru masini sau pentru aparate mecanice Pentru aparate de bucatarie sau pentru masini folosite in industria alimentara',
    'Cutite si lame taietoare pentru masini sau pentru aparate mecanice Pentru masini agricole horticole sau forestiere',
    'Cutite si lame taietoare pentru masini sau pentru aparate mecanice Altele',
    'Placute baghete varfuri si obiecte similare pentru unelte si scule nemontate din compui metaloceramici Placute detasabile',
    'Placute baghete varfuri si obiecte similare pentru unelte si scule nemontate din compui metaloceramici Altele',
    'Aparate mecanice actionate manual cu o greutate de maximum kg utilizate pentru prepararea conditionarea sau servirea alimentelor sau bauturilor Aparate mecanice actionate manual cu o greutate de maximum kg utilizate pentru prepararea conditionarea sau servirea alimentelor sau bauturilor',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Seturi',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Cutite de masa cu lama fixa',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Alte cutite cu lama fixa',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Cutite altele decat cele cu lama fixa inclusiv cosoarele care se inchid',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Lame',
    'Cutite altele decat cele de la pozitia cu lama taietoare sau zimtata inclusiv cosoare care se inchid si lamele acestora Manere din metale comune',
    'Brice si aparate de ras si  lame de ras inclusiv ebosele in benzi Aparate de ras cu protectie cu lama neinlocuibila',
    'Brice si aparate de ras si  lame de ras inclusiv ebosele in benzi Altele',
    'Brice si aparate de ras si  lame de ras inclusiv ebosele in benzi Lame pentru aparate de ras cu protectie inclusiv ebosele in benzi',
    'Brice si aparate de ras si  lame de ras inclusiv ebosele in benzi Alte parti',
    'Foarfece cu doua brate si lamele acestora Foarfece cu doua brate si lamele acestora',
    'Alte articole de cutitarie de exemplu masini de tuns satare de macelarie sau de bucatarie cutite de ghilotina cutite de transat sau de tocat cutite de taiat hartie instrumente si seturi de instrumente pentru manichiura sau pedichiura inclusiv pilele de unghii Cutite de taiat hartie de deschis scrisori cutite de razuit ascutitori de creioane si lamele acestora',
    'Alte articole de cutitarie de exemplu masini de tuns satare de macelarie sau de bucatarie cutite de ghilotina cutite de transat sau de tocat cutite de taiat hartie instrumente si seturi de instrumente pentru manichiura sau pedichiura inclusiv pilele de unghii Instrumente si seturi de instrumente pentru manichiura sau pedichiura inclusiv pilele de unghii',
    'Alte articole de cutitarie de exemplu masini de tuns satare de macelarie sau de bucatarie cutite de ghilotina cutite de transat sau de tocat cutite de taiat hartie instrumente si seturi de instrumente pentru manichiura sau pedichiura inclusiv pilele de unghii Altele',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Care contin numai obiecte argintate aurite sau platinate',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Din otel inoxidabil',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Altele',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Din otel inoxidabil',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Altele',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Argintate aurite sau platinate',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Din otel inoxidabil',
    'Linguri furculite polonice spumiere palete pentru prajituri cutite speciale pentru peste sau pentru unt clesti pentru zahar si articole similare Altele',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Lacate',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Broaste de tipul celor utilizate pentru autovehicule',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Broaste de tipul celor utilizate pentru mobila',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Broaste cu cilindru',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Altele',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Alte broaste zavoare',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Inchizatoare si monturiinchizatoare prevazute cu broaste',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Parti',
    'Lacate broaste si zavoare cu chei cu cifru sau electrice din metale comune inchizatoare si monturiinchizatoare prevazute cu broaste din metale comune chei pentru aceste articole din metale comune Chei prezentate separat',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Sarniere si articulatii de orice fel inclusiv balamale si similare',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Rotite si rotile',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Alte decoratiuni articole de feronerie si articole similare pentru autovehicule',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Pentru usi',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Pentru ferestre si usiferestre glasvanduri',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Altele',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Altele pentru mobila',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Altele',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Cuiere pentru haine cuiere pentru palarii suporturi si articole similare',
    'Decoratiuni articole de feronerie si articole similare din metale comune pentru mobila  usi  scari  ferestre  jaluzele  caroserii articole de selarie valize cufere cofrete si pentru alte articole similare cuiere pentru haine cuiere pentru palarii suporturi si articole similare din metale comune rotite si rotile cu monturi din metale comune inchizatori automate din metale comune pentru usi Inchizatori automate pentru usi',
    'Case de bani usi blindate si compartimente pentru camere blindate cufere si casete de siguranta si articole similare din metale comune Case de bani usi blindate si compartimente pentru camere blindate',
    'Case de bani usi blindate si compartimente pentru camere blindate cufere si casete de siguranta si articole similare din metale comune Cufere si casete de siguranta si articole similare',
    'Bibliorafturi fisiere cutii de clasat suporturi pentru hartii penare portstampile si materiale si furnituri similare pentru birou din metale comune cu exceptia mobilierului de birou de la pozitia Bibliorafturi fisiere cutii de clasat suporturi pentru hartii penare portstampile si materiale si furnituri similare pentru birou din metale comune cu exceptia mobilierului de birou de la pozitia',
    'Mecanisme pentru legarea foilor volante sau pentru clasoare cleme si agrafe pentru hartii colturi pentru documente calareti si obiecte similare de birou din metale comune agrafe prezentate in benzi de exemplu de birou pentru tapiterie pentru ambalare din metale comune Mecanisme pentru legarea foilor volante sau pentru bibliorafturi',
    'Mecanisme pentru legarea foilor volante sau pentru clasoare cleme si agrafe pentru hartii colturi pentru documente calareti si obiecte similare de birou din metale comune agrafe prezentate in benzi de exemplu de birou pentru tapiterie pentru ambalare din metale comune Agrafe prezentate in benzi',
    'Mecanisme pentru legarea foilor volante sau pentru clasoare cleme si agrafe pentru hartii colturi pentru documente calareti si obiecte similare de birou din metale comune agrafe prezentate in benzi de exemplu de birou pentru tapiterie pentru ambalare din metale comune Altele inclusiv partile',
    'Clopote clopotei gonguri si articole similare neelectrice din metale comune statuete si alte obiecte de ornament din metale comune rame pentru fotografii pentru gravuri sau pentru altele similare din metale comune oglinzi din metale comune Clopote clopotei gonguri si articole similare',
    'Clopote clopotei gonguri si articole similare neelectrice din metale comune statuete si alte obiecte de ornament din metale comune rame pentru fotografii pentru gravuri sau pentru altele similare din metale comune oglinzi din metale comune Argintate aurite sau platinate',
    'Clopote clopotei gonguri si articole similare neelectrice din metale comune statuete si alte obiecte de ornament din metale comune rame pentru fotografii pentru gravuri sau pentru altele similare din metale comune oglinzi din metale comune Altele',
    'Clopote clopotei gonguri si articole similare neelectrice din metale comune statuete si alte obiecte de ornament din metale comune rame pentru fotografii pentru gravuri sau pentru altele similare din metale comune oglinzi din metale comune Rame pentru fotografii pentru gravuri sau pentru altele similare oglinzi',
    'Tuburi flexibile din metale comune cu sau fara accesoriile acestora Din fier sau din otel',
    'Tuburi flexibile din metale comune cu sau fara accesoriile acestora Din alte metale comune',
    'Inchizatoare monturiinchizatoare catarame catarameincheietori agrafe copci capse si articole similare din metale comune de tipul folosit pentru imbracaminte sau pentru accesorii de imbracaminte pentru incaltaminte pentru bijuterii pentru ceasuri de mana pentru carti pentru prelate pentru articole de marochinarie pentru articole de voiaj sau pentru articole de selarie ori pentru alte articole confectionate nituri tubulare sau cu tija bifurcata din metale comune margele si paiete din metale comune Agrafe copci capse',
    'Inchizatoare monturiinchizatoare catarame catarameincheietori agrafe copci capse si articole similare din metale comune de tipul folosit pentru imbracaminte sau pentru accesorii de imbracaminte pentru incaltaminte pentru bijuterii pentru ceasuri de mana pentru carti pentru prelate pentru articole de marochinarie pentru articole de voiaj sau pentru articole de selarie ori pentru alte articole confectionate nituri tubulare sau cu tija bifurcata din metale comune margele si paiete din metale comune Nituri tubulare sau cu tija bifurcata',
    'Inchizatoare monturiinchizatoare catarame catarameincheietori agrafe copci capse si articole similare din metale comune de tipul folosit pentru imbracaminte sau pentru accesorii de imbracaminte pentru incaltaminte pentru bijuterii pentru ceasuri de mana pentru carti pentru prelate pentru articole de marochinarie pentru articole de voiaj sau pentru articole de selarie ori pentru alte articole confectionate nituri tubulare sau cu tija bifurcata din metale comune margele si paiete din metale comune Altele inclusiv partile',
    'Dopuri inclusiv dopurile cu coroana cu filet si de turnare in pahare capace capsule pentru sticle cepuri filetate acoperitoare de cepuri sigilii si alte accesorii pentru ambalaje din metale comune Dopuri cu coroana',
    'Dopuri inclusiv dopurile cu coroana cu filet si de turnare in pahare capace capsule pentru sticle cepuri filetate acoperitoare de cepuri sigilii si alte accesorii pentru ambalaje din metale comune Capsule din plumb pentru sticle capsule din aluminiu cu un diametru de peste  mm pentru sticle',
    'Dopuri inclusiv dopurile cu coroana cu filet si de turnare in pahare capace capsule pentru sticle cepuri filetate acoperitoare de cepuri sigilii si alte accesorii pentru ambalaje din metale comune Altele',
    'Placi indicatoare placi pentru firme pentru adrese si alte placi similare cifre litere si insemne diverse din metale comune cu exceptia celor clasificate la pozitia Placi indicatoare placi pentru firme pentru adrese si alte placi similare cifre litere si insemne diverse din metale comune cu exceptia celor clasificate la pozitia',
    'Sarma baghete tuburi placi electrozi si articole similare din metale comune sau din carburi metalice acoperite sau umplute cu decapanti sau cu fondanti pentru lipire pentru sudare sau pentru depunere de metal sau de carburi metalice sarma si baghete din pulberi de metale comune aglomerate utilizate la metalizarea prin pulverizare Electrozi acoperiti pentru sudura cu arc electric din metale comune',
    'Sarma baghete tuburi placi electrozi si articole similare din metale comune sau din carburi metalice acoperite sau umplute cu decapanti sau cu fondanti pentru lipire pentru sudare sau pentru depunere de metal sau de carburi metalice sarma si baghete din pulberi de metale comune aglomerate utilizate la metalizarea prin pulverizare Sarma umpluta pentru sudura cu arc electric din metale comune',
    'Sarma baghete tuburi placi electrozi si articole similare din metale comune sau din carburi metalice acoperite sau umplute cu decapanti sau cu fondanti pentru lipire pentru sudare sau pentru depunere de metal sau de carburi metalice sarma si baghete din pulberi de metale comune aglomerate utilizate la metalizarea prin pulverizare Baghete acoperite si sarma umpluta pentru lipire sau sudura cu flacara din metale comune',
    'Sarma baghete tuburi placi electrozi si articole similare din metale comune sau din carburi metalice acoperite sau umplute cu decapanti sau cu fondanti pentru lipire pentru sudare sau pentru depunere de metal sau de carburi metalice sarma si baghete din pulberi de metale comune aglomerate utilizate la metalizarea prin pulverizare Altele',
    'Reactoare nucleare elemente combustibile cartuse neiradiate pentru reactoare nucleare masini si aparate pentru separare izotopica Reactoare nucleare Euratom',
    'Reactoare nucleare elemente combustibile cartuse neiradiate pentru reactoare nucleare masini si aparate pentru separare izotopica Masini si aparate pentru separare izotopica si partile lor Euratom',
    'Reactoare nucleare elemente combustibile cartuse neiradiate pentru reactoare nucleare masini si aparate pentru separare izotopica Elemente combustibile cartuse neiradiate Euratom',
    'Reactoare nucleare elemente combustibile cartuse neiradiate pentru reactoare nucleare masini si aparate pentru separare izotopica Parti de reactoare nucleare Euratom',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Cazane acvatubulare cu o productie orara de abur peste tone',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Cazane acvatubulare cu o productie orara de abur de maximum tone',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Cazane ignitubulare',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Altele',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Cazane numite de apa supraincalzita',
    'Cazane generatoare de abur sau de alti vapori altele decat cazanele pentru incalzire centrala destinate sa produca in acelasi timp apa calda si abur de joasa presiune cazane numite de apa supraincalzita Parti',
    'Cazane pentru incalzire centrala altele decat cele de la pozitia Din fonta',
    'Cazane pentru incalzire centrala altele decat cele de la pozitia Altele',
    'Cazane pentru incalzire centrala altele decat cele de la pozitia Din fonta',
    'Cazane pentru incalzire centrala altele decat cele de la pozitia Altele',
    'Instalatii auxiliare pentru cazanele de la pozitiile sau  de exemplu economizoare supraincalzitoare dispozitive de curatat funingine sau recuperatoare de gaz condensatoare pentru masinile cu abur sau alti vapori Instalatii auxiliare pentru cazanele de la pozitiile sau',
    'Instalatii auxiliare pentru cazanele de la pozitiile sau  de exemplu economizoare supraincalzitoare dispozitive de curatat funingine sau recuperatoare de gaz condensatoare pentru masinile cu abur sau alti vapori Condensatoare pentru masinile cu abur sau alti vapori',
    'Instalatii auxiliare pentru cazanele de la pozitiile sau  de exemplu economizoare supraincalzitoare dispozitive de curatat funingine sau recuperatoare de gaz condensatoare pentru masinile cu abur sau alti vapori Parti',
    'Generatoare de gaz de aer sau generatoare de gaz de apa cu sau fara epuratoarele lor generatoare de acetilena si generatoare similare de gaz prin procedeul cu apa cu sau fara epuratoarele lor Generatoare de gaz de aer sau generatoare de gaz de apa cu sau fara epuratoarele lor generatoare de acetilena si generatoare similare de gaz prin procedeul cu apa cu sau fara epuratoarele lor',
    'Generatoare de gaz de aer sau generatoare de gaz de apa cu sau fara epuratoarele lor generatoare de acetilena si generatoare similare de gaz prin procedeul cu apa cu sau fara epuratoarele lor Parti',
    'Turbine cu vapori Turbine pentru propulsia navelor',
    'Turbine cu vapori De o putere peste MW',
    'Turbine cu vapori De o putere de maximum MW',
    'Turbine cu vapori Palete de stator rotoare si paletele lor',
    'Turbine cu vapori Altele',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Motoare pentru aviatie',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica de maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu puterea de maximum kW',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu puterea peste kW',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Altele',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica de maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica peste cm dar maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica peste cm dar maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica peste cm dar maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica peste cm dar maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Destinate industriei de montaj motocultoarelor de la subpozitia autovehiculelor de la pozitia autovehiculelor de la pozitia cu capacitate cilindrica mai mica de cm autovehiculelor de la pozitia',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Folosite',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie De maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie De peste cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu capacitatea cilindrica de maximum cm',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Destinate industriei de montaj motocultoarelor de la subpozitia autovehiculelor de la pozitia autovehiculelor de la pozitia cu capacitate cilindrica mai mica de cm autovehiculelor de la pozitia',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu puterea de maximum kW',
    'Motoare cu piston alternativ sau rotativ cu aprindere prin scanteie motoare cu explozie Cu puterea peste kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Pentru vasele maritime de la pozitiile remorcherele de la subpozitia si vasele de razboi de la subpozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Altele',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Destinate industriei de montaj motocultoarelor de la subpozitia autovehiculelor de la pozitia autovehiculelor de la pozitia cu capacitate cilindrica mai mica de cm autovehiculelor de la pozitia',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De propulsie pentru vehiculele feroviare',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel Folosite',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW dar de maximum kW',
    'Motoare cu piston cu aprindere prin compresie motoare diesel sau semidiesel De peste kW',
    'Parti care pot fi recunoscute ca fiind destinate numai sau in principal motoarelor de la pozitiile sau  De motoare pentru aviatie',
    'Parti care pot fi recunoscute ca fiind destinate numai sau in principal motoarelor de la pozitiile sau  Destinate numai sau in principal motoarelor cu piston cu aprindere prin scanteie',
    'Parti care pot fi recunoscute ca fiind destinate numai sau in principal motoarelor de la pozitiile sau  Altele',
    'Turbine hidraulice roti hidraulice si regulatoare pentru acestea Cu puterea maximum kW',
    'Turbine hidraulice roti hidraulice si regulatoare pentru acestea Cu puterea peste kW dar de maximum kW',
    'Turbine hidraulice roti hidraulice si regulatoare pentru acestea Cu puterea peste kW',
    'Turbine hidraulice roti hidraulice si regulatoare pentru acestea Parti inclusiv regulatoarele',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz De o forta de maximum kN',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz De o forta peste kN dar de maximum kN',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz De o forta peste kN dar de maximum kN',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz De o forta peste kN',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea de maximum kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea peste kW dar de maximum kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea peste kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea de maximum kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea peste kW dar de maximum kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea peste kW dar de maximum kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Cu puterea peste kW',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz De turboreactoare sau de turbopropulsoare',
    'Turboreactoare turbopropulsoare si alte turbine cu gaz Altele',
    'Alte motoare si masini motrice Motoare cu reactie altele decat turboreactoarele',
    'Alte motoare si masini motrice Sisteme hidraulice',
    'Alte motoare si masini motrice Altele',
    'Alte motoare si masini motrice Sisteme hidraulice',
    'Alte motoare si masini motrice Motoare hidraulice cu ulei',
    'Alte motoare si masini motrice Altele',
    'Alte motoare si masini motrice Cu miscare rectilinie cilindri',
    'Alte motoare si masini motrice Altele',
    'Alte motoare si masini motrice Motoare cu abur sau alti vapori',
    'Alte motoare si masini motrice Altele',
    'Alte motoare si masini motrice De motoare cu reactie altele decat turboreactoarele',
    'Alte motoare si masini motrice De motoare hidraulice',
    'Alte motoare si masini motrice Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe pentru distribuirea carburantilor sau lubrifiantilor de tipul celor utilizate la statiile de alimentare sau in garaje',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe manuale altele decat cele de la subpozitiile sau',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe de injectie',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe de beton',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Agregate hidraulice',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe dozatoare',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe hidraulice cu ulei',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Agregate hidraulice',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe hidraulice cu ulei',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe hidraulice cu ulei',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe cu surub elicoidal',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Monoetajate',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Multietajate',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe pentru circularea apei in instalatii de incalzire centrala si de apa calda',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid De maximum mm',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe cu roti cu canale si pompe cu canal lateral',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Monobloc',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Altele',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Cu mai multe fluxuri',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Multietajate',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Monoetajate',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Multietajate',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Pompe',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid Elevatoare de lichid',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid De pompe',
    'Pompe pentru lichide chiar cu dispozitiv de masurare elevatoare de lichid De elevatoare de lichid',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre De tipul celor utilizate la fabricarea semiconductoarelor sau utilizate exclusiv sau in principal la fabricarea ecranelor plate',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Pompe cu piston rotativ pompe cu palete pompe moleculare si pompe Roots',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Pompe de difuziune pompe criostatice si pompe de adsorbtie',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Pompe actionate de mana pentru biciclete',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Cu puterea de maximum kW',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Ermetice sau semiermetice',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Cu debitul de maximum m pe minut',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Cu debitul peste m pe minut',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Ventilatoare de masa de sol de perete de fereastra de plafon de acoperis cu motor electric incorporat de o putere de maximum W',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Ventilatoare de tipul celor utilizate numai sau in principal pentru racirea microprocesoarelor a aparatelor de telecomunicaie a masinilor automate de prelucrare a datelor sau a unitatilor masinilor automate de prelucrare a datelor',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Ventilatoare axiale',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Ventilatoare centrifugale',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Hote a caror cea mai mare latura orizontala este de maximum cm',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Nise de securitate biologica etanse la gaz',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Monoetajate',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Multietajate',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre De maximum m',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Peste m',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre De maximum m',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Peste m',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Cu un singur arbore',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Cu surub',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Altele',
    'Pompe de aer sau de vid compresoare de aer sau de alte gaze si ventilatoare hote aspirante de extractie sau de reciclare cu ventilator incorporat chiar filtrante nise de securitate biologica etanse la gaz echipate sau nu cu filtre Parti',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Care formeaza un singur corp',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Sisteme cu elemente separate splitsystem',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat De tipul celor utilizate pentru confortul persoanelor in autovehicule',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Cu dispozitiv de racire si supapa pentru inversarea ciclului termic pompe de caldura reversibile',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Altele cu dispozitiv de racire',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Fara dispozitiv de racire',
    'Masini si aparate pentru conditionarea aerului care au ventilator cu motor si dispozitive proprii de modificare a temperaturii si umiditatii inclusiv cele la care umiditatea nu poate fi reglata separat Parti',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Cu dispozitiv de control automat incorporat',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Altele',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Care functioneaza numai cu gaz monobloc avand incorporat un ventilator si un dispozitiv de control',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Arzatoare mixte',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Altele',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Focare automate inclusiv antefocarele lor gratarele lor mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusei si dispozitivele similare',
    'Arzatoare pentru alimentarea focarelor cu combustibili lichizi cu combustibili solizi pulverizati sau cu gaz focare automate inclusiv antefocarele gratarele mecanice dispozitivele mecanice ale acestora pentru evacuarea cenusii si dispozitivele similare Parti',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Furnale si cuptoare pentru calcinare topire sau alte tratamente termice ale minereurilor sau metalelor',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Cuptoare tunel',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Altele',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Cuptoare si furnale pentru arderea produselor ceramice',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Cuptoare si furnale pentru arderea cimentului sticlei sau produselor chimice',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Altele',
    'Furnale si cuptoare industriale sau de laborator inclusiv incineratoare neelectrice Parti',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea peste l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Altele',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea peste l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Frigidere tip masa',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Pentru incastrat',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia De maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Peste l dar maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Altele',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea de maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea peste l dar de maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea de maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Cu capacitatea peste l dar de maximum l',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Pentru produse congelate',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Altele',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Alt mobilier frigorific',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Pompe de caldura altele decat cele pentru masinile si aparatele pentru conditionarea aerului de la pozitia',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Altele',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Mobilier destinat echipamentelor pentru producerea frigului',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Evaporatoare si condensatoare altele decat cele pentru aparatele de tip menajer',
    'Frigidere congelatoare si alte echipamente pentru producerea frigului electrice sau nu pompe de caldura altele decat masinile si aparatele pentru conditionarea aerului de la pozitia Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Cu incalzire instantanee cu gaz',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Incalzitoare de apa solare',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Sterilizatoare medicochirurgicale sau de laborator',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Aparat de liofilizare aparate de criodesicare si uscatoare prin pulverizare',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele pentru produse agricole',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele pentru lemn pasta de hartie hartie sau carton',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Aparate de distilare sau de rectificare',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Schimbatoare de caldura facute din fluorpolimeri si cu orificii pentru tuburi de intrare si iesire avand diametre interioare de maximum cm',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Aparate si dispozitive pentru lichefierea aerului sau a altor gaze',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Filtre si alte aparate pentru prepararea cafelei si a altor bauturi calde',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Turnuri de racire si dispozitive similare pentru racire directa fara perete despartitor prin recircularea apei',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Aparate si dispozitive de metalizare sub vid',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Pentru sterilizatoarele de la subpozitia',
    'Aparate si dispozitive chiar incalzite electric cu exceptia furnalelor cuptoarelor si a altor aparate de la pozitia pentru prelucrarea materialelor prin operatii ce implica o schimbare a temperaturii cum sunt incalzirea coacerea fierberea prajirea distilarea rectificarea sterilizarea pasteurizarea etuvarea uscarea evaporarea vaporizarea condensarea sau racirea altele decat aparatele de uz casnic incalzitoare de apa neelectrice cu incalzire instantanee sau cu stocare Altele',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini De tipul celor folosite in industria textila',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini De tipul celor folosite in industria hartiei',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini Laminoare cu rulouri de tipul celor utilizate numai sau in principal pentru fabricarea substraturilor pentru circuitele imprimate sau a circuitelor imprimate',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini Altele',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini Din fonta',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini Altele',
    'Calandre si laminoare altele decat cele pentru metale sau sticla si cilindri pentru aceste masini Altele',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Centrifuge pentru smantanire',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Uscatoare de rufe',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Centrifuge de tipule celor utilizate in laboratoare',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Altele',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Pentru filtrarea sau purificarea  apei',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Pentru filtrarea sau purificarea  bauturilor altele decat apa',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Pentru filtrarea uleiurilor minerale in motoare cu aprindere prin scanteie sau prin compresie',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Facute din fluopolimeri si cu filtru sau grosime a membranei de purificare  de maximum  de microni',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Altele',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Filtre de aer pentru motoarele cu aprindere prin scanteie sau prin compresie',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Convertoare catalitice sau filtre de particule combinate sau nu pentru purificarea sau filtrarea gazelor de evacuare provenite de la motoarele cu ardere interna',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Cu carcasa din otel inoxidabil si cu orificii pentru tuburi de intrare si iesire avand diametre interioare de maximum  cm',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Aparate pentru filtrarea sau purificarea aerului',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Prin procedee catalitice',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Altele',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor De centrifuge inclusiv de uscatoare centrifugale',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Parti de masini si aparate de la subpozitiile  sau',
    'Centrifuge inclusiv uscatoare centrifugale masini si aparate pentru purificarea sau epurarea lichidelor sau gazelor Altele',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor De uz casnic',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor Altele',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor Masini si aparate pentru curatat sau uscat sticle sau alte recipiente',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor Masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate de capsulat sticle borcane tuburi sau recipiente similare masini si aparate pentru gazeificarea bauturilor',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor Alte masini si aparate de impachetat sau de ambalat marfurile inclusiv masinile si aparatele de ambalat in folie termoretractabila',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor De masini de spalat vesela',
    'Masini pentru spalat vesela masini si aparate pentru curatat sau uscat sticle sau alte recipiente masini si aparate pentru umplut inchis astupat sau etichetat sticle cutii saci sau alte recipiente masini si aparate pentru capsulat sticle borcane tuburi sau recipiente similare alte masini si aparate de impachetat sau ambalat marfuri inclusiv masini si aparate de ambalat in folie termoretractabila masini si aparate pentru gazeificarea acidularea bauturilor Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Balante de uz casnic',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Care utilizeaza mijloace electronice de calibrare a greutatii',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Care utilizeaza mijloace electronice de calibrare a greutatii',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Instrumente de control prin referinta la o greutate predeterminata cu functionare automata inclusiv masinile de sortat dupa greutate',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Aparate si instrumente de cantarit si etichetat produsele preambalate',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Balante de magazin',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Care utilizeaza mijloace electronice de calibrare a greutatii cu exceptia aparatelor si instrumentelor de cantarit autovehicule',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Instrumente de control prin referinta la o greutate predeterminata cu functionare automata inclusiv masinile de sortat dupa greutate',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Care utilizeaza mijloace electronice de calibrare a greutatii',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Parti de aparate si instrumente de cantarit de la subpozitiile         sau',
    'Aparate si instrumente de cantarit inclusiv bascule si balante pentru verificat piese industriale cu excepia balanelor sensibile la o greutate de cg sau mai mica greutati de cantarit pentru orice balante Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Extinctoare chiar incarcate',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Aerografe si aparate similare',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Echipate cu dispozitiv de incalzit',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Cu aer comprimat',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Pulverizatoare portabile',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Pulverizatoare si prafuitoare proiectate pentru a fi purtate sau trase de tractoare',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Aparate de stropit',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Aparate mecanice pentru proiectat dispersat sau pulverizat de tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate sau a ansamblelor de circuite imprimate',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Parti de aparate mecanice de la subpozitia',
    'Aparate mecanice inclusiv cele manevrate manual pentru proiectat dispersat sau pulverizat lichide sau pulberi extinctoare chiar incarcate aerografe si aparate similare masini si aparate cu jet de nisip cu jet de vapori si aparate similare cu jet Altele',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Cu motor electric',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Altele',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Cu motor electric',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Altele',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Elevatoare fixe de masini pentru garaje',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Alte cricuri si vinciuri hidraulice',
    'Palane altele decat ascensoare basculante trolii si cabestane cricuri si vinciuri Altele',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Poduri rulante pe sine fixe',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Portaluri mobile pe roti cu pneuri si carucioarecalaret',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Altele',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Macaraleturn',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Macareleportal sau macarale cu brat',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Pe roti cu pneuri',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Altele',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Macarale hidraulice pentru incarcarea sau descarcarea vehiculului',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Altele',
    'Macarale derrick biga macarale inclusiv macarale suspendate poduri rulante macaraleportal de descarcare sau de manevrare macaralepod carucioarecalaret si carucioaremacara Altele',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Care ridica la inaltimea de minimum m',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Altele',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Carucioare stivuitoare pentru orice tip de teren',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Altele',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Altele',
    'Carucioarestivuitoare alte carucioare de manevrare echipate cu un dispozitiv de ridicat Alte carucioare',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Electrice',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Pentru produse in vrac',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Special proiectate pentru extractia miniera subterana sau pentru alte activitati subterane',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele cu cupe',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele cu banda sau curea',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Transportoare sau conveioare cu role sau galeti',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Scari si trotuare rulante',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Teleferice inclusiv telescaune si teleschiuri mecanisme de tractiune pentru funiculare',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Roboti industriali',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Concepute pentru a fi atasate tractoarelor agricole',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele',
    'Alte masini si aparate de ridicat de manevrat de incarcat sau de descarcat de exemplu ascensoare scari rulante transportatoare teleferice Altele',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Pe senile',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Altele',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Gredere si nivelatoare',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Screpere',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Rulouri vibratoare',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Altele',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Compactoare',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Incarcatoare special proiectate pentru mine subterane sau pentru alte munci subterane',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Incarcatoare pe senile',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Altele',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Excavatoare pe senile',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Altele',
    'Buldozere inclusiv cu lama orientabila gredere nivelatoare screpere lopeti mecanice excavatoare incarcatoare si lopeti incarcatoare compactoare si rulouri compresoare autopropulsate Altele',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Sonete pentru baterea pilonilor si masini pentru extragerea pilonilor',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Pluguri de zapada si dispozitive de indepartat zapada',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Autopropulsate',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Altele',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Autopropulsate',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Altele',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Alte masini si aparate autopropulsate',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Masini si aparate de tasat sau de compactat',
    'Alte masini si aparate pentru terasarea nivelarea decopertarea excavarea compactarea extractia sau forarea pamantului mineralelor sau minereurilor sonete pentru baterea pilonilor si masini pentru extragerea pilonilor pluguri de zapada si dispozitive de indepartat zapada Altele',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile De masini sau aparate de la pozitia',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile De masini sau aparate de la pozitia',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Pentru ascensoare de persoane sau de marfuri sau pentru scari rulante',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Cupe bene graifere lopeti clesti',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Lame de buldozere chiar reglabile',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Parti de masini pentru sondaj sau pentru foraj de la subpozitiile sau',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Turnate din fonta fier sau otel',
    'Parti care pot fi recunoscute ca fiind numai sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Pluguri',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Grape cu disc pulverizatoare',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Scarificatoare si cultivatoare',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Grape altele decat cu disc',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Motosapatoare',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Altele',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Semanatoare masini de plantat si masini de repicat direct fara arat',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport De precizie cu comanda centrala',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Altele',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Masini de plantat si de repicat',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Masini de imprastiat gunoiul de grajd',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Distribuitoare de ingrasaminte',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Alte masini aparate si dispozitive',
    'Masini aparate si dispozitive agricole horticole sau silvicole pentru pregatirea sau lucrarea solului sau pentru culturi tavaluguri pentru peluze sau terenuri de sport Parti',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Electrice',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Prevazute cu scaun',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Electrice',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Prevazute cu scaun',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Fara motor',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Cu motor',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Proiectate pentru a fi tractate sau atasate la tractor',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Alte masini si utilaje de cosit',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Prese de balotat paie sau furaje inclusiv prese colectoare',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Combine pentru recoltat si treierat',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Alte masini si utilaje pentru treierat',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Masini pentru recoltat cartofi',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Masini de decoletat si de recoltat sfecla',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Autopropulsate',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Altele',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Masini pentru curatat sortat sau triat oua fructe sau alte produse agricole',
    'Masini si utilaje pentru recoltat si treierat produse agricole inclusiv prese de balotat paie sau furaje masini si utilaje pentru tuns gazon si pentru secerat masini pentru curatat sau sortat oua fructe sau alte produse agricole altele decat masinile si aparatele de la pozitia Parti',
    'Masini pentru muls si masini si aparate pentru laptarie Masini pentru muls',
    'Masini pentru muls si masini si aparate pentru laptarie Masini si aparate pentru laptarie',
    'Masini pentru muls si masini si aparate pentru laptarie Parti',
    'Prese teascuri masini si dispozitive de stors similare pentru fabricarea vinului cidrului sucurilor de fructe sau bauturilor similare Masini si aparate',
    'Prese teascuri masini si dispozitive de stors similare pentru fabricarea vinului cidrului sucurilor de fructe sau bauturilor similare Parti',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Masini si utilaje pentru prepararea hranei animalelor',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Incubatoare si clocitoare',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Altele',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Pentru silvicultura',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Altele',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura De masini si utilaje pentru avicultura',
    'Alte masini si utilaje pentru agricultura horticultura silvicultura avicultura sau apicultura inclusiv germinatoarele prevazute cu dispozitive mecanice sau termice incubatoarele si clocitoarele pentru avicultura Altele',
    'Masini pentru curatat cernut sortat sau triat seminte boabe sau legume uscate masini si aparate pentru morarit sau pentru prelucrarea cerealelor sau a legumelor uscate altele decat masinile si aparatele de tipul celor folosite la ferme Masini pentru curatat cernut sortat sau triat seminte boabe sau legume uscate',
    'Masini pentru curatat cernut sortat sau triat seminte boabe sau legume uscate masini si aparate pentru morarit sau pentru prelucrarea cerealelor sau a legumelor uscate altele decat masinile si aparatele de tipul celor folosite la ferme Alte masini si aparate',
    'Masini pentru curatat cernut sortat sau triat seminte boabe sau legume uscate masini si aparate pentru morarit sau pentru prelucrarea cerealelor sau a legumelor uscate altele decat masinile si aparatele de tipul celor folosite la ferme Parti',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Pentru brutarie patiserie sau fabricarea biscuitilor',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Pentru fabricarea pastelor fainoase alimentare',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Masini si aparate pentru produse de cofetarie sau pentru fabricat cacao sau ciocolata',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Masini si aparate pentru fabricarea zaharului',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Masini si aparate pentru fabricarea berii',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Masini si aparate pentru prelucrarea carnii',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Masini si aparate pentru prelucrarea fructelor sau legumelor',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Pentru tratarea si prelucrarea cafelei sau ceaiului',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Pentru prepararea sau fabricarea bauturilor',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Altele',
    'Masini si aparate nedenumite si necuprinse in alta parte in acest capitol pentru prepararea sau fabricarea industriala a alimentelor sau bauturilor altele decat masinile si aparatele pentru extragerea sau prepararea uleiurilor sau grasimilor de origine vegetala sau microbiana stabile sau animala Parti',
    'Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice sau pentru fabricarea sau finisarea hartiei sau cartonului Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice',
    'Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice sau pentru fabricarea sau finisarea hartiei sau cartonului Masini si aparate pentru fabricarea hartiei sau cartonului',
    'Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice sau pentru fabricarea sau finisarea hartiei sau cartonului Masini si aparate pentru finisarea hartiei sau cartonului',
    'Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice sau pentru fabricarea sau finisarea hartiei sau cartonului De masini si aparate pentru fabricarea pastei din materiale fibroase celulozice',
    'Masini si aparate pentru fabricarea pastei din materiale fibroase celulozice sau pentru fabricarea sau finisarea hartiei sau cartonului Altele',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Masini de pliat',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Masini de asamblat si masini de adunat coli',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Masini de cusut si masini de prins agrafe',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Masini de legat prin lipire',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Altele',
    'Masini si aparate pentru brosat sau legat carti inclusiv masinile pentru cusut foi Parti',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Masini de taiatbobinatoare',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Masini de taiat in lungime sau in latime',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Ghilotine',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Altele',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Masini pentru fabricarea sacilor pungilor sau plicurilor',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Masini pentru fabricarea cutiilor lazilor tuburilor de carton bidoanelor sau ambalajelor similare altfel decat prin mulare',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Masini pentru mularea articolelor din pasta de hartie hartie sau carton',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Alte masini si aparate',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel De masini de taiat',
    'Alte masini si aparate pentru prelucrarea pastei de hartie hartiei sau cartonului inclusiv masinile de taiat de orice fel Altele',
    'Masini aparate si echipamente altele decat masinile de la pozitiile pentru pregatirea sau fabricarea cliseelor planselor cilindrilor sau altor componente imprimante clisee planse cilindri si alte componente imprimante pietre litografice cilindri planse si placi pregatite pentru imprimare de exemplu slefuite plan granulate lustruite Masini aparate si echipamente',
    'Masini aparate si echipamente altele decat masinile de la pozitiile pentru pregatirea sau fabricarea cliseelor planselor cilindrilor sau altor componente imprimante clisee planse cilindri si alte componente imprimante pietre litografice cilindri planse si placi pregatite pentru imprimare de exemplu slefuite plan granulate lustruite Parti ale acestor masini aparate sau echipamente',
    'Masini aparate si echipamente altele decat masinile de la pozitiile pentru pregatirea sau fabricarea cliseelor planselor cilindrilor sau altor componente imprimante clisee planse cilindri si alte componente imprimante pietre litografice cilindri planse si placi pregatite pentru imprimare de exemplu slefuite plan granulate lustruite Clisee planse cilindri si alte componente imprimante pietre litografice cilindri planse si placi pregatite pentru imprimare de exemplu slefuite plan granulate lustruite',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat tip offset alimentate cu hartie in sul',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat tip offset de birou alimentate cu foi de hartie cu o latura de maximum cm si cealalta latura de maximum cm',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Folosite',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii De maximum cm',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii De peste cm dar de maximum cm',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii De peste cm',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat tipografice alimentate cu hirtie in sul cu exceptia masinilor si aparatelor flexografice',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat tipografice altele decat cele alimentate cu hirtie in sul cu exceptia masinilor si aparatelor flexografice',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat flexografice',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini si aparate de imprimat heliografice',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Pentru imprimarea materialelor textile',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Utilizate pentru fabricarea produselor semiconductoare',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Masini care asigura cel putin doua din functiile urmatoare imprimare copiere ori transmisie de telecopii care pot fi conectate la o masina automata de prelucrare a datelor sau la o retea',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Imprimante',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Ale aparatelor de la subpozitia',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Turnate din fonta fier sau otel',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Subansambluri electronice',
    'Masini si aparate utilizate la imprimarea cu ajutorul planselor cilindrilor si altor organe de imprimare de la pozitia alte imprimante masini de copiat si telecopiatoare chiar combinate intre ele parti si accesorii Altele',
    'Masini pentru extrudarea laminarea texturarea sau taierea materialelor textile sintetice sau artificiale Masini pentru extrudare',
    'Masini pentru extrudarea laminarea texturarea sau taierea materialelor textile sintetice sau artificiale Altele',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Masini de cardat',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Masini de pieptanat',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Laminoare si flaiere',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Altele',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Masini de filat material textile',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Masini de dublat sau rasucit materialele textile',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Masini de bobinat inclusiv masinile de canetat sau de depanat material textile',
    'Masini pentru prepararea materialelor textile masini pentru filarea dublarea sau rasucirea materialelor textile si alte masini si aparate pentru fabricarea firelor textile masini de bobinat inclusiv masini de canetat sau de depanat material textile si masini pentru pregatirea firelor textile in vederea utilizarii lor pe masinile de la pozitiile sau  Altele',
    'Masini de tesut razboaie Pentru tesaturi de o latime de maximum cm',
    'Masini de tesut razboaie Cu motor',
    'Masini de tesut razboaie Altele',
    'Masini de tesut razboaie Pentru tesaturi de o latime peste cm fara suveici',
    'Masini de tricotat de coaseretricotare de imbracat de produs tul dantela broderie articole de pasmanterie sireturi plasa sau smocuri Cu diametrul cilindrului de maximum mm',
    'Masini de tricotat de coaseretricotare de imbracat de produs tul dantela broderie articole de pasmanterie sireturi plasa sau smocuri Cu diametrul cilindrului de peste mm',
    'Masini de tricotat de coaseretricotare de imbracat de produs tul dantela broderie articole de pasmanterie sireturi plasa sau smocuri Masini de tricotat cu urzeala inclusiv razboaie Raschel masini de coaseretricotare',
    'Masini de tricotat de coaseretricotare de imbracat de produs tul dantela broderie articole de pasmanterie sireturi plasa sau smocuri Altele',
    'Masini de tricotat de coaseretricotare de imbracat de produs tul dantela broderie articole de pasmanterie sireturi plasa sau smocuri Altele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Ratiere si mecanisme Jacquard reductoare perforatoare si copiatoare de unctu masini de snuruit unctu dupa perforare',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Altele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Parti si accesorii ale masinilor de la pozitia sau ale dispozitivelor sau aparatelor lor auxiliare',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Garnituri de carda',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Ale masinilor pentru prepararea materialelor textile altele decat garniturile de carde',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Fuse de filat si furcile de flaier inele de filatura si cursori pentru masinile cu inele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Altele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Piepteni cocleti si rame pentru cocleti',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Altele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Platine',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Altele',
    'Masini si aparate auxiliare pentru masinile de la pozitiile   sau  de exemplu ratiere mecanisme Jacquard veghetoare de urzeala si veghetoare de batatura mecanisme pentru schimbarea suveicilor parti si accesorii recunoscute ca fiind destinate numai sau in principal masinilor de la aceasta pozitie sau de la pozitiile   sau  de exemplu fuse de filat furci garnituri de carda piepteni iglite barete duze de extrudare suveici cocleti rame pentru cocleti ace platine crosete Altele',
    'Masini si aparate pentru fabricarea sau finisarea fetrului sau articolelor netesute in bucati sau in forme inclusive masini si aparate pentru fabricarea palariilor din fetru calapoade pentru palarii Masini si aparate pentru fabricarea sau finisarea fetrului sau articolelor netesute in bucati sau in forme inclusive masini si aparate pentru fabricarea palariilor din fetru calapoade pentru palarii',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Cu incarcare frontala',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Cu incarcare pe sus',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Cu capacitatea exprimata in greutatea rufelor uscate de peste kg dar de maximum kg',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Alte masini cu storcator centrifugal incorporat',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Altele',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Masini cu o capacitate unitara exprimata in greutatea rufelor uscate de peste kg',
    'Masini de spalat rufe inclusiv cele cu dispozitiv de uscare Parti',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini pentru curatirea uscata',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Cu o capacitate unitara exprimata in greutatea rufelor uscate de maximum kg',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Altele',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini si prese de calcat inclusive presele de fixare',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini pentru spalare albire sau vopsire',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini pentru captusit tesaturi si alti suporti in vederea fabricarii invelitorilor de pardoseala cum este linoleumul etc',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Masini pentru apretat sau finisat',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Altele',
    'Masini si aparate altele decat cele de la pozitia pentru spalarea curatirea stoarcerea uscarea calcarea presarea inclusiv presele de fixare albirea vopsirea apretarea finisarea acoperirea sau impregnarea firelor tesaturilor sau articolelor confectionate din materiale textile si masini pentru acoperirea tesaturilor sau altor suporturi utilizate pentru fabricarea articolelor de acoperit podele cum este linoleumul masini pentru rularea derularea plierea taierea sau dantelarea tesaturilor Parti',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Masini de cusut de valoare unitara batiu masa sau mobilier neinclus mai mare de',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Altele',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Alte masini de cusut si alte capete pentru masini de cusut',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Automate',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Altele',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Ace pentru masini de cusut',
    'Masini de cusut altele decat masinile de cusut foi de la pozitia mobila suporti si acoperitoare special concepute pentru masinile de cusut ace pentru masinile de cusut Mobila suporti si acoperitoare pentru masinile de cusut si partile lor alte parti ale masinilor de cusut',
    'Masini si aparate pentru prepararea tabacirea sau prelucrarea pieilor sau pentru fabricarea sau repararea incaltamintei sau altor obiecte din piele altele decat masinile de cusut Masini si aparate pentru prepararea tabacirea sau prelucrarea pieilor',
    'Masini si aparate pentru prepararea tabacirea sau prelucrarea pieilor sau pentru fabricarea sau repararea incaltamintei sau altor obiecte din piele altele decat masinile de cusut Masini si aparate pentru fabricarea sau repararea incaltamintei',
    'Masini si aparate pentru prepararea tabacirea sau prelucrarea pieilor sau pentru fabricarea sau repararea incaltamintei sau altor obiecte din piele altele decat masinile de cusut Alte masini si aparate',
    'Masini si aparate pentru prepararea tabacirea sau prelucrarea pieilor sau pentru fabricarea sau repararea incaltamintei sau altor obiecte din piele altele decat masinile de cusut Parti',
    'Convertizoare oale de turnare lingotiere si masini de turnare pentru metalurgie otelarie sau topitorie Convertizoare',
    'Convertizoare oale de turnare lingotiere si masini de turnare pentru metalurgie otelarie sau topitorie Lingotiere si oale de turnare',
    'Convertizoare oale de turnare lingotiere si masini de turnare pentru metalurgie otelarie sau topitorie Masini de turnat sub presiune',
    'Convertizoare oale de turnare lingotiere si masini de turnare pentru metalurgie otelarie sau topitorie Altele',
    'Convertizoare oale de turnare lingotiere si masini de turnare pentru metalurgie otelarie sau topitorie Parti',
    'Laminoare pentru metale si cilindrii lor Laminoare de tevi',
    'Laminoare pentru metale si cilindrii lor Laminoare la cald si laminoare combinate la cald si la rece',
    'Laminoare pentru metale si cilindrii lor Laminoare la rece',
    'Laminoare pentru metale si cilindrii lor Din fonta',
    'Laminoare pentru metale si cilindrii lor Cilindri de prelucrare la cald cilindri de sprijin la cald si la rece',
    'Laminoare pentru metale si cilindrii lor Cilindri de prelucrare la rece',
    'Laminoare pentru metale si cilindrii lor Altele',
    'Laminoare pentru metale si cilindrii lor Alte parti',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa De tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate a ansamblelor de circuite imprimate a partilor de la pozitia  sau a partilor de masini automate de prelucrare a datelor',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Altele',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa De tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate a ansamblelor de circuite imprimate a partilor de la pozitia  sau a partilor de masini automate de prelucrare a datelor',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Altele',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Care actioneaza cu ultrasunete',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Prin fir',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Altele',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Altele',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Care actioneaza cu jet de plasma',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Masini de taiat cu jet de apa',
    'Masiniunelte care prelucreaza orice tip de material prin eliminarea materialului actionand cu laser sau cu alt fascicul de lumina sau de fotoni cu ultrasunete prin electroeroziune prin procedee electrochimice cu fascicule de electroni cu fascicule de ioni sau cu jet de plasma masini de taiat cu jet de apa Altele',
    'Centre de prelucrare masini cu post fix si masini cu posturi multiple pentru prelucrarea metalelor Orizontale',
    'Centre de prelucrare masini cu post fix si masini cu posturi multiple pentru prelucrarea metalelor Altele',
    'Centre de prelucrare masini cu post fix si masini cu posturi multiple pentru prelucrarea metalelor Masini cu post fix',
    'Centre de prelucrare masini cu post fix si masini cu posturi multiple pentru prelucrarea metalelor Cu comanda numerica',
    'Centre de prelucrare masini cu post fix si masini cu posturi multiple pentru prelucrarea metalelor Altele',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Centre de strunjire',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Monoax',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Multiax',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Altele',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Altele',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Centre de strunjire',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Altele',
    'Strunguri inclusiv centre de strunjire pentru prelucrarea metalelor prin  eliminarea materialului Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Centrele de prelucrare glisante',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Cu comanda numerica',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Cu comanda numerica',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Cu comanda numerica',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Cu comanda numerica',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Masini de frezat scule',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Masini de frezat scule',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Altele',
    'Masiniunelte inclusiv centrele de prelucrare glisante de gaurit alezat frezat filetat sau tarodat metalele prin eliminarea materialului altele decat strungurile inclusiv centrele de strunjire de la pozitia Alte masini de filetat sau tarodat',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Masini de rectificat fara centru cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Alte masini de rectificat cilindric cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Pentru suprafete cilindrice',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Cu comanda numerica',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele',
    'Masiniunelte pentru debavurat ascutit polizat rectificat honuit lepuit slefuit sau pentru alte operatii de finisat metale sau compui metaloceramici cu pietre de rectificat cu abrazive sau cu alte produse de slefuit altele decat masinile de danturat sau finisat angrenaje de la pozitia Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Sepinguri si masini de mortezat',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Cu comanda numerica',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Cu comanda numerica',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Cu comanda numerica',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Cu comanda numerica',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Cu ferastrau circular',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Masini de debitat',
    'Masiniunelte de rabotat sepinguri de mortezat de brosat de danturat si finisat angrenaje dintate de debitat de taiat cu ferastraul de sectionat si alte masini care actioneaza prin eliminarea metalului sau compuilor metaloceramici nedenumite si necuprinse in alta parte Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Prese hidraulice de indoit cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Prese pentru panouri cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Masini de rulat cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Alte masini de curbat pliat indreptat sau aplatizat cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Masini de forfecat cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Cu comanda numerica',
    'Masiniunelte inclusiv prese pentru prelucrarea metalelor prin forjare ciocanire matritare cu exceptia laminoarelor masiniunelte inclusiv prese linii de taiere longitudinala si linii de taiere la lungime pentru prelucrarea metalelor prin curbare pliere indreptare aplatizare forfecare perforare crestare sau deformare cu exceptia bancurilor de trefilare prese pentru prelucrarea metalelor sau a carburilor metalice altele decat cele mentionate anterior Altele',
    'Alte masiniunelte pentru prelucrarea metalelor saucompuilor metaloceramici care actioneaza fara eliminarea materialului Bancuri de trefilat sarma',
    'Alte masiniunelte pentru prelucrarea metalelor saucompuilor metaloceramici care actioneaza fara eliminarea materialului Altele',
    'Alte masiniunelte pentru prelucrarea metalelor saucompuilor metaloceramici care actioneaza fara eliminarea materialului Masini pentru executarea unei filetari exterioare sau interioare prin rulare sau laminare',
    'Alte masiniunelte pentru prelucrarea metalelor saucompuilor metaloceramici care actioneaza fara eliminarea materialului Masini pentru fabricarea sarmei',
    'Alte masiniunelte pentru prelucrarea metalelor saucompuilor metaloceramici care actioneaza fara eliminarea materialului Altele',
    'Masiniunelte pentru prelucrarea pietrei a produselor ceramice betonului azbocimentului sau materialelor minerale similare sau pentru prelucrarea la rece a sticlei Masini de taiat',
    'Masiniunelte pentru prelucrarea pietrei a produselor ceramice betonului azbocimentului sau materialelor minerale similare sau pentru prelucrarea la rece a sticlei Sticlei optice',
    'Masiniunelte pentru prelucrarea pietrei a produselor ceramice betonului azbocimentului sau materialelor minerale similare sau pentru prelucrarea la rece a sticlei Altele',
    'Masiniunelte pentru prelucrarea pietrei a produselor ceramice betonului azbocimentului sau materialelor minerale similare sau pentru prelucrarea la rece a sticlei Altele',
    'Masiniunelte pentru prelucrarea pietrei a produselor ceramice betonului azbocimentului sau materialelor minerale similare sau pentru prelucrarea la rece a sticlei Altele',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Cu manevrare manuala a piesei intre fiecare operatiune',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Fara manevrare manuala a piesei intre fiecare operatiune',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Centre de prelucrare',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Cu ferastraie banda',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Ferastraie circulare',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Altele',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Masini de indreptat sau de rabotat masini de frezat sau de modelatmulurat prin taiere',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Masini de slefuit de polizat sau de lustruit',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Masini de curbat sau de asamblat',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Masini de gaurit sau de mortezat',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Masini de spintecat de retezat sau de derulat',
    'Masiniunelte inclusiv masinile pentru batut cuie de prins cu scoabe de lipit de incleiat sau pentru alte asamblari pentru prelucrarea lemnului plutei osului ebonitei materialelor plastice dure sau a altor materiale dure similare Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Mandrine clesti si bucse',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Pentru strunguri',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Filiere cu declansare automata',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Montaje de prelucrare si ansamblele lor de componente standard',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Pentru strunguri',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Capete divizoare si alte dispozitive speciale pentru maini',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Turnate din fonta fier sau otel',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Turnate din fonta fier sau otel',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Parti si accesorii ale masinilor de la subpozitiile         sau  destinate numai sau in principal pentru fabricarea circuitelor imprimate a ansamblelor de circuite imprimate a partilor de la pozitia  sau a partilor de masini automate de prelucrare a datelor',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Pentru masini de la subpozitia',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Altele',
    'Parti si accesorii destinate numai sau in principal masinilor de la pozitiile inclusiv portpiesele si portsculele filierele cu declansare automata capetele divizoare si alte dispozitive speciale care se monteaza pe masini portscule destinate uneltelor sau sculelor manuale de orice tip Pentru masinile de la pozitiile sau',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Pentru prelucrarea metalelor',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Care functioneaza fara sursa de energie exterioara',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Electropneumatice',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Ferastraie si masini de debitat cu lant',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Ferastraie circulare',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Care functioneaza fara sursa de energie exterioara',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Masini de polizat unghiular',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Masini de slefuit cu banda',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Rindele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Foarfeci pentru taiat gardul viu foarfeci pentru tuns peluza si plivitoare',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Ferastraie si masini de debitat cu lant',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala De ferastraie si masini de debitat cu lant',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala De unelte pneumatice',
    'Unelte pneumatice hidraulice sau cu motor electric sau neelectric incorporat pentru utilizare manuala Altele',
    'Masini si aparate pentru lipit sau sudat chiar cu posibilitatea de taiere altele decat cele de la pozitia masini si aparate cu gaz pentru calire superficiala Arzatoare dirijate manual',
    'Masini si aparate pentru lipit sau sudat chiar cu posibilitatea de taiere altele decat cele de la pozitia masini si aparate cu gaz pentru calire superficiala Alte masini si aparate cu gaz',
    'Masini si aparate pentru lipit sau sudat chiar cu posibilitatea de taiere altele decat cele de la pozitia masini si aparate cu gaz pentru calire superficiala Alte masini si aparate',
    'Masini si aparate pentru lipit sau sudat chiar cu posibilitatea de taiere altele decat cele de la pozitia masini si aparate cu gaz pentru calire superficiala Parti',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Masini electronice de calculat care pot sa functioneze fara sursa de energie exterioara si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor care au o functie de calcul',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Care au un organ imprimant incorporat',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Altele',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Alte masini de calculat',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Case de inregistrare',
    'Masini de calculat si masini de buzunar care permit inregistrarea reproducerea si afisarea datelor cu functie de calcul masini de contabilizat masini de francat masini de emis bilete si masini similare cu dispozitiv de calcul case de inregistrare Altele',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Masini automate de prelucrare a datelor portabile de o greutate de maximum kg care contin cel putin o unitate centrala de prelucrare o tastatura si un ecran',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Care au in acelasi corp cel putin o unitate centrala de prelucrare si o unitate de intrare si una de iesire chiar combinate',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Altele prezentate sub forma de sisteme',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Unitati de prelucrare a datelor altele decat cele de la subpozitiile sau  chiar care contin in acelasi corp unul sau doua tipuri din unitatile urmatoare unitate de memorie unitate de intrare unitate de iesire',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Tastaturi',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Altele',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Unitati de memorie centrale',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Optice inclusiv magnetooptice',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Unitati de memorie pe hard discuri',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Altele',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Unitati de memorie pe benzi magnetice',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Altele',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Alte unitati de masini automate de prelucrare a datelor',
    'Masini automate de prelucrare a datelor si unitati ale acestora cititoare magnetice sau optice masini pentru transpunerea datelor pe suport sub forma codificata si masini de prelucrare a acestor date nedenumite si necuprinse in alta parte Altele',
    'Alte masini si aparate de birou de exemplu duplicatoare hectografice sau cu matrite masini de imprimat adrese distribuitoare automate de bancnote masini de triat de numarat sau de facut fisicuri de monede aparate de ascutit creioane aparate de perforat sau de capsat Duplicatoare',
    'Alte masini si aparate de birou de exemplu duplicatoare hectografice sau cu matrite masini de imprimat adrese distribuitoare automate de bancnote masini de triat de numarat sau de facut fisicuri de monede aparate de ascutit creioane aparate de perforat sau de capsat Masini pentru trierea plierea punerea in plic sau pe banda a corespondentei masini de deschis de inchis sau de stampilat corespondenta si masini de aplicat sau de obliterat timbre',
    'Alte masini si aparate de birou de exemplu duplicatoare hectografice sau cu matrite masini de imprimat adrese distribuitoare automate de bancnote masini de triat de numarat sau de facut fisicuri de monede aparate de ascutit creioane aparate de perforat sau de capsat Masini de triat de numarat sau de facut fisicuri din monede',
    'Alte masini si aparate de birou de exemplu duplicatoare hectografice sau cu matrite masini de imprimat adrese distribuitoare automate de bancnote masini de triat de numarat sau de facut fisicuri de monede aparate de ascutit creioane aparate de perforat sau de capsat Altele',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Subansambluri electronice',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Subansambluri electronice',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Subansambluri electronice',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Subansambluri electronice',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Subansambluri electronice',
    'Parti si accesorii altele decat cutiile de transport husele si altele asemanatoare care pot fi recunoscute ca fiind in exclusivitate sau in principal destinate masinilor sau aparatelor de la pozitiile Altele',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Masini si aparate pentru sortat cernut separat sau spalat',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Masini si aparate pentru concasat macinat sau pulverizat',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Betoniere si aparate pentru amestecarea cimentului',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Masini pentru amestecat materiale minerale cu bitum',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Altele',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Masini si aparate pentru aglomerarea formarea sau modelarea pastei ceramice',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Altele',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Turnate din fonta fier sau otel',
    'Masini si aparate pentru sortat cernut separat spalat concasat macinat amestecat sau malaxat pamant pietre minereuri si alte substante minerale solide inclusiv praf si pasta masini pentru aglomerat format sau modelat combustibili minerali solizi paste ceramice ciment gips sau alte substante minerale sub forma de praf sau de pasta masini pentru fabricat forme de nisip pentru turnat Altele',
    'Masini pentru asamblarea lampilor tuburilor si becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis de sticla masini pentru fabricarea sau prelucrarea la cald a sticlei sau a obiectelor din sticla Masini pentru asamblarea lampilor tuburilor sau becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis din sticla',
    'Masini pentru asamblarea lampilor tuburilor si becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis de sticla masini pentru fabricarea sau prelucrarea la cald a sticlei sau a obiectelor din sticla Masini pentru fabricarea fibrelor optice si a eboselor acestora',
    'Masini pentru asamblarea lampilor tuburilor si becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis de sticla masini pentru fabricarea sau prelucrarea la cald a sticlei sau a obiectelor din sticla Altele',
    'Masini pentru asamblarea lampilor tuburilor si becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis de sticla masini pentru fabricarea sau prelucrarea la cald a sticlei sau a obiectelor din sticla Parti de masini de la subpozitia',
    'Masini pentru asamblarea lampilor tuburilor si becurilor electrice sau electronice sau a becurilor pentru bliuri care contin un invelis de sticla masini pentru fabricarea sau prelucrarea la cald a sticlei sau a obiectelor din sticla Altele',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Care contin dispozitive de incalzire sau de racire',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Altele',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Care contin dispozitive de incalzire sau de racire',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Masini pentru schimbat monede',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Altele',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Parti de masini pentru schimbat monede',
    'Masini automate pentru vanzarea produselor de exemplu timbre postale tigari produse alimentare bauturi inclusiv masini pentru schimbat monede Altele',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini pentru formare prin injectie',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini pentru extrudare',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini pentru formare prin suflare',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini pentru formare sub vid si alte masini pentru termoformare',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Pentru formarea sau resaparea anvelopelor pneumatice sau pentru formarea sau modelarea camerelor de aer',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Prese',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Altele',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini pentru procesarea rasinilor reactive',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Altele',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini de fragmentare',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Amestecatoare malaxoare si agitatoare',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Masini de taiat si masini de despicat',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Altele',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Turnate din fonta fier sau otel',
    'Masini si aparate pentru prelucrarea cauciucului sau a materialelor plastice sau pentru fabricarea produselor din aceste materiale nedenumite si necuprinse in alta parte a acestui capitol Altele',
    'Masini si aparate pentru prepararea sau prelucrarea tutunului nedenumite si necuprinse in alta parte in acest capitol Masini si aparate',
    'Masini si aparate pentru prepararea sau prelucrarea tutunului nedenumite si necuprinse in alta parte in acest capitol Parti',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini si aparate pentru lucrari publice constructii sau activitati similare',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini si aparate pentru extractia sau prepararea grasimilor sau uleiurilor de origine vegetala sau microbiana stabile sau animale',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Prese',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini pentru fabricarea franghiilor sau cablurilor',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Roboti industriali nedenumiti si necuprinsi in alta parte',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Aparate pentru racirea aerului prin evaporare',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol De tipul celor utilizate in aeroporturi',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Pentru prelucrarea metalelor inclusiv masinile de bobinat pentru infasurarile electrice',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Pentru amestecare malaxare concasare spargere macinare cernere omogenizare emulsionare sau agitare',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Prese izostatice la rece',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Sustinator mobil hidraulic pentru mine',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Aparate numite de gresare centralizata',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini automate de dispunere a componentelor electronice de tipul celor utilizate numai sau in principal pentru fabricarea ansamblelor de circuite imprimate',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Parti de masini de la subpozitia',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Turnate din fonta fier sau otel',
    'Masini si aparate mecanice cu functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Rame pentru forme de turnatorie',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Placi de baza pentru forme',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Din lemn',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Altele',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Pentru turnare prin injectie sau prin comprimare',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Altele',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Forme pentru sticla',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Forme pentru materiale minerale',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Pentru turnare prin injectie sau comprimare',
    'Rame pentru forme de turnatorie placi de baza pentru forme modele pentru forme forme pentru metale altele decat lingotierele pentru carburi metalice sticla materiale minerale cauciuc sau materiale plastice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Combinate cu filtre sau lubrificatoare',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din fonta sau din otel',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Valve pentru transmisii oleohidraulice',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Valve pentru transmisii pneumatice',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din fonta sau din otel',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din fonta sau din otel',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice De amestec',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Robinete termostatice',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Valve pentru anvelope si pentru camere de aer',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice A temperaturii',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din fonta',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din otel',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din fonta',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Din otel',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Robinete cu turnant sferic conic sau cilindric',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Robinete fluture',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Robinete cu membrana',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Altele',
    'Articole de robinetarie si articole similare pentru tevi cazane rezervoare cuve si recipiente similare inclusiv reductoarele de presiune si vanele termostatice Parti',
    'Rulmenti cu bile cu galeti cu role sau cu ace Al caror cel mai mare diametru exterior este de maximum mm',
    'Rulmenti cu bile cu galeti cu role sau cu ace Altele',
    'Rulmenti cu bile cu galeti cu role sau cu ace Rulmenti cu role conice inclusiv subansamblurile de conuri si role conice',
    'Rulmenti cu bile cu galeti cu role sau cu ace Rulmenti cu role butoi',
    'Rulmenti cu bile cu galeti cu role sau cu ace Rulmenti cu ace inclusiv subansamblurile de conuri si role cu ace',
    'Rulmenti cu bile cu galeti cu role sau cu ace Alti rulmenti cu role cilindrice inclusiv subansamblurile de conuri si role conice',
    'Rulmenti cu bile cu galeti cu role sau cu ace Altele inclusiv rulmentii combinati',
    'Rulmenti cu bile cu galeti cu role sau cu ace Role conice',
    'Rulmenti cu bile cu galeti cu role sau cu ace Altele',
    'Rulmenti cu bile cu galeti cu role sau cu ace Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Turnate din fonta fier sau otel',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Din otel forjat',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Arbori articulati',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Lagare cu rulmenti incorporati',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Pentru rulmenti de toate tipurile',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Cuzineti',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Roti cilindrice cu dinti drepti si cu dinti inclinati',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Roti conice cu dinti drepti si roti conicecilindrice cu dinti drepti',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Roti melcate',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Suruburi cu bile sau cu role',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Traductoare de viteza',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Turnate din fonta fier sau otel',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Turnate din fonta fier sau otel',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Parti de lagare pentru rulmenti de toate tipurile',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Turnate din fonta fier sau otel',
    'Arbori de transmisie inclusiv arbori cu came si vilbrochene si manivele lagare si cuzineti angrenaje si roti de frictiune suruburi cu bile sau cu role traductoare de viteza si alte variatoare inclusiv convertizoare de cuplu volanti si fulii inclusiv fulii cu mufle ambreiaje si organe de cuplare inclusiv articulatii Altele',
    'Garnituri de etansare si articole similare din foi de metal combinate cu alte materiale sau din doua sau mai multe straturi de metal seturi sau sortimente de garnituri de etansare si similare de compozitii diferite prezentate in pungi plicuri sau ambalaje similare garnituri pentru etansari mecanice Garnituri de etansare si articole similare din foi de metal combinate cu alte materiale sau din doua sau mai multe straturi de metal',
    'Garnituri de etansare si articole similare din foi de metal combinate cu alte materiale sau din doua sau mai multe straturi de metal seturi sau sortimente de garnituri de etansare si similare de compozitii diferite prezentate in pungi plicuri sau ambalaje similare garnituri pentru etansari mecanice Garnituri pentru etansari mecanice',
    'Garnituri de etansare si articole similare din foi de metal combinate cu alte materiale sau din doua sau mai multe straturi de metal seturi sau sortimente de garnituri de etansare si similare de compozitii diferite prezentate in pungi plicuri sau ambalaje similare garnituri pentru etansari mecanice Altele',
    'Masini pentru fabricatie aditiva imprimare D Prin depunere de metale',
    'Masini pentru fabricatie aditiva imprimare D Prin depunere de materiale plastice sau cauciuc',
    'Masini pentru fabricatie aditiva imprimare D Prin depunere de ipsos ciment sau ceramica',
    'Masini pentru fabricatie aditiva imprimare D Altele',
    'Masini pentru fabricatie aditiva imprimare D Masini pentru fabricatie aditiva imprimare D cu depunere de nisip ciment sau alte produse minerale',
    'Masini pentru fabricatie aditiva imprimare D Altele',
    'Masini pentru fabricatie aditiva imprimare D Parti ale masinilor de la subpozitiile  sau',
    'Masini pentru fabricatie aditiva imprimare D Altele',
    'Masini si aparate utilizate numai sau in principal pentru fabricarea lingourilor sau plachetelor wafers sau a dispozitivelor cu semiconductori a circuitelor electronice integrate sau a dispozitivelor de afisare cu ecran plat masini si aparate mentionate la nota  litera C a capitolului  parti si accesorii Masini si aparate pentru fabricarea lingourilor sau plachetelor wafers',
    'Masini si aparate utilizate numai sau in principal pentru fabricarea lingourilor sau plachetelor wafers sau a dispozitivelor cu semiconductori a circuitelor electronice integrate sau a dispozitivelor de afisare cu ecran plat masini si aparate mentionate la nota  litera C a capitolului  parti si accesorii Masini si aparate pentru fabricarea dispozitivelor cu semiconductori sau a circuitelor electronice integrate',
    'Masini si aparate utilizate numai sau in principal pentru fabricarea lingourilor sau plachetelor wafers sau a dispozitivelor cu semiconductori a circuitelor electronice integrate sau a dispozitivelor de afisare cu ecran plat masini si aparate mentionate la nota  litera C a capitolului  parti si accesorii Masini si aparate pentru fabricarea dispozitivelor de afisare cu ecran plat',
    'Masini si aparate utilizate numai sau in principal pentru fabricarea lingourilor sau plachetelor wafers sau a dispozitivelor cu semiconductori a circuitelor electronice integrate sau a dispozitivelor de afisare cu ecran plat masini si aparate mentionate la nota  litera C a capitolului  parti si accesorii Masini si aparate mentionate la nota literaC a capitolului',
    'Masini si aparate utilizate numai sau in principal pentru fabricarea lingourilor sau plachetelor wafers sau a dispozitivelor cu semiconductori a circuitelor electronice integrate sau a dispozitivelor de afisare cu ecran plat masini si aparate mentionate la nota  litera C a capitolului  parti si accesorii Parti si accesorii',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Din bronz',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Altele',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Din fonta',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Turnate din otel',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Forjate in matrita deschisa sau forjate in matrita inchisa din fier sau otel',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Altele',
    'Parti de masini sau de aparate nedenumite si necuprinse in alta parte in acest capitol fara conexiuni electrice parti izolate electric bobinaje contacte sau alte elemente caracteristice electrice Altele',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare sincrone de o putere de maximum W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare universale',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare de curent alternativ',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare de curent continuu',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare universale de o putere peste W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de maximum W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste W dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kW dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de maximum W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de maximum W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste W dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kW dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kW dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Motoare de tractiune',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De peste kW dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De peste kW dar de maximum kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De peste kW',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de maximum kVA',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kVA dar de maximum kVA',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kVA dar de maximum kVA',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kVA dar de maximum kVA',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste kVA',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de maximum W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene De o putere de peste W',
    'Motoare si generatoare electrice cu exceptia grupurilor electrogene Generatoare fotovoltaice de curent alternativ',
    'Grupuri electrogene si convertizoare rotative electrice De maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA dar de maximum kVA',
    'Grupuri electrogene si convertizoare rotative electrice De o putere de peste kVA',
    'Grupuri electrogene si convertizoare rotative electrice Eoliene',
    'Grupuri electrogene si convertizoare rotative electrice Turbogeneratoare',
    'Grupuri electrogene si convertizoare rotative electrice Altele',
    'Grupuri electrogene si convertizoare rotative electrice Convertizoare rotative electrice',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal masinilor de la pozitiile sau  Frete nemagnetizate',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal masinilor de la pozitiile sau  Turnate din fonta fier sau otel',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal masinilor de la pozitiile sau  Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Bobine de reactanta inclusiv cele cuplate cu un condensator',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de maximum kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA dar de maximum kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA dar de maximum kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Pentru masurarea tensiunii',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA dar de maximum kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA dar de maximum kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Incarcatoare de acumulatoare',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Redresoare',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de maximum  kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De o putere de peste  kVA',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Alte bobine de reactanta si de inductanta',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Miezuri de ferita',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Laminate sau miezuri de otel inclusiv suprapuse sau infasurate',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta Altele',
    'Transformatoare electrice convertizoare statice de exemplu redresoare bobine de reactanta si de inductanta De convertizoare statice',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Care contin neodim praseodim disprosiu sau samariu',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Altele',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Magneti permanenti din ferita aglomerata',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Altele',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Electromagneti de tipul celor utilizati numai sau in principal pentru aparatele de diagnostic cu vizualizare prin rezonanta magnetica altii decat electromagnetii de la pozitia',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Altele',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Capete de ridicare electromagnetice',
    'Electromagneti magneti permanenti si articole destinate sa devina magneti permanenti dupa magnetizare platouri mandrine si dispozitive magnetice sau electromagnetice similare pentru fixare cuplaje ambreiaje schimbatoare de viteza si frane electromagnetice capete de ridicare electromagnetice Parti',
    'Pile si baterii de pile electrice Pile cilindrice',
    'Pile si baterii de pile electrice Altele',
    'Pile si baterii de pile electrice Pile cilindrice',
    'Pile si baterii de pile electrice Altele',
    'Pile si baterii de pile electrice Cu oxid de mercur',
    'Pile si baterii de pile electrice Cu oxid de argint',
    'Pile si baterii de pile electrice Pile cilindrice',
    'Pile si baterii de pile electrice Pile buton',
    'Pile si baterii de pile electrice Altele',
    'Pile si baterii de pile electrice Cu aerzinc',
    'Pile si baterii de pile electrice Baterii uscate cu zinccarbon cu tensiunea de minimum V dar de maximum V',
    'Pile si baterii de pile electrice Altele',
    'Pile si baterii de pile electrice Parti',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Care functioneaza cu electrolit lichid',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Altele',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Care functioneaza cu electrolit lichid',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Altele',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Inchise ermetic',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Altele',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Cu hidrura de nichelmetal',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Cu ioni de litiu',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Alte acumulatoare',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Separatoare',
    'Acumulatoare electrice inclusiv separatoarele lor chiar de forma patrata sau dreptunghiulara Altele',
    'Aspiratoare De o putere de maximum W si avand un sac pentru colectarea prafului sau un alt recipient de maximum l',
    'Aspiratoare Altele',
    'Aspiratoare Alte aspiratoare',
    'Aspiratoare Parti',
    'Aparate electromecanice cu motor electric incorporat de uz casnic altele decat aspiratoarele de la pozitia Aparate de tocat si amestecat alimente storcatoare de fructe si de legume',
    'Aparate electromecanice cu motor electric incorporat de uz casnic altele decat aspiratoarele de la pozitia Alte aparate',
    'Aparate electromecanice cu motor electric incorporat de uz casnic altele decat aspiratoarele de la pozitia Parti',
    'Masini de ras de tuns si masini de epilat cu motor electric incorporat Masini de ras',
    'Masini de ras de tuns si masini de epilat cu motor electric incorporat Masini de tuns',
    'Masini de ras de tuns si masini de epilat cu motor electric incorporat Masini de epilat',
    'Masini de ras de tuns si masini de epilat cu motor electric incorporat Parti',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Bujii de aprindere',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Magnetouri dinamurimagnetou volanti magnetici',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Distribuitori bobine de aprindere',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Demaroare chiar functionand ca generatoare',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Alte generatoare',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Alte aparate si dispozitive',
    'Aparate si dispozitive electrice de aprindere sau de pornire pentru motoare cu aprindere prin scanteie sau prin compresie de exemplu magnetouri dinamurimagnetou bobine de aprindere bujii de aprindere sau de incalzire demaroare generatoare de exemplu dinamuri alternatoare si conjunctoaredisjunctoare utilizate cu aceste motoare Parti',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Aparate de iluminat sau de semnalizare vizuala de tipul celor utilizate pentru biciclete',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Alte aparate de iluminat sau de semnalizare vizuala',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Avertizoare electrice antifurt de tipul utilizat pentru autovehicule',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Altele',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Stergatoare de parbriz dispozitive antigivraj si dispozitive de dezaburire',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule De aparate de la subpozitia',
    'Aparate electrice de iluminat sau de semnalizare cu exceptia articolelor de la pozitia stergatoare de parbriz dispozitive antigivraj si de dezaburire electrice de tipul celor utilizate pentru biciclete motociclete sau autovehicule Altele',
    'Lampi electrice portabile care functioneaza cu ajutorul sursei proprii de energie de exemplu cu pile cu acumulatoare electromagnetice altele decat aparatele de iluminat de la pozitia Lampi',
    'Lampi electrice portabile care functioneaza cu ajutorul sursei proprii de energie de exemplu cu pile cu acumulatoare electromagnetice altele decat aparatele de iluminat de la pozitia Parti',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Prese izostatice la cald',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Cuptoare pentru brutarie patiserie sau pentru biscuiti',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Altele',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Care functioneaza prin inductie',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Care functioneaza prin pierderi prin dielectric',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric De tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate sau a ansamblelor de circuite',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Altele',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric De tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate sau a ansamblelor de circuite',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Altele',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric De tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate sau a ansamblelor de circuite',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Altele',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Alte aparate pentru tratamentul termic  al materialelor prin inductie sau prin pierderi prin dielectric',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric De furnale si cuptoare de la subpozitiile   sau',
    'Furnale si cuptoare electrice industriale sau de laborator inclusiv cele care functioneaza prin inductie sau prin pierderi prin dielectric alte aparate industriale sau de laborator pentru tratament termic a materialelor  prin inductie sau prin pierderi prin dielectric Altele',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Ciocane si pistoale de lipit',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Masini pentru lipire in valuri de tipul celor utilizate numai sau in principal pentru fabricarea ansamblelor de circuite imprimate',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Altele',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Complet sau partial automatizate',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Altele',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Complet sau partial automatizate',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Un transformator',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Un generator sau un convertizor rotativ sau un convertizor static sau un redresor',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Altele',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Pentru tratamentul metalelor',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Altele',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici De masini pentru lipire in valuri de la subpozitia',
    'Masini si aparate electrice pentru lipire sau sudare chiar pentru taiat inclusiv cele cu gaz incalzite electric sau care functioneaza cu laser sau cu alte fascicule de lumina sau de fotoni cu ultrasunete cu fascicule de electroni cu impulsuri magnetice sau cu jet de plasma masini si aparate electrice pentru pulverizarea la cald a metalelor sau a  compuilor metaloceramici Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Instantanee',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Radiatoare cu acumulare de caldura',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Radiatoare care functioneaza prin circulaia unui lichid',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Radiatoare care functioneaza prin convectie',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Cu ventilator incorporat',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Uscatoare de par',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Alte aparate pentru coafura',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Aparate pentru uscarea mainilor',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Fiare electrice de calcat',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Cuptoare cu microunde',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Masini de gatit',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Resouri inclusiv plitele de gatit',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Gratare si rotisoare',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Cuptoare pentru a fi incastrate',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Aparate pentru prepararea cafelei sau a ceaiului',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Prajitoare de paine',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Friteuze',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Montate pe un suport simplu din material izolant',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Altele',
    'Incalzitoare de apa instantanee sau cu stocare si termoplonjoare electrice aparate electrice pentru incalzirea incaperilor a solului sau pentru utilizari similare aparate electrotermice pentru coafura de exemplu uscatoare de par casti pentru coafura ondulatoare sau pentru uscarea mainilor fiare electrice de calcat alte aparate electrotermice pentru uz casnic rezistente incalzitoare altele decat cele de la pozitia Parti',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Aparate telefonice pentru beneficiari pentru telefonie prin fir cu receptor fara fir',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Telefoane inteligente',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Altele',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Statii de baza',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Aparate pentru receptia conversia si transmisia sau regenerarea vocii a imaginii sau a altor date inclusiv aparatele pentru comutare switch si rutare router',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Videofoane',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Interfoane',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Aparate  receptoare pentru radiotelefonie sau radiotelegrafie',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Altele',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Antene si reflectoare de antene de toate tipurile parti care se utilizeaza impreuna cu aceste articole',
    'Aparate telefonice pentru beneficiari inclusiv telefoanele inteligente si alte aparate telefonice pentru reteaua de telefonie mobila si pentru alte retele fara fir alte aparate pentru transmisia sau receptia vocii a imaginii sau a altor date inclusiv aparatele pentru comunicatie in retele cu sau fara fir cum ar fi o retea locala sau o retea de mare suprafata altele decat aparatele de transmisie sau receptie de la pozitiile   sau  Altele',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Microfoane si suporturile lor',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Difuzoare individuale montate in carcasa',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Difuzoare multiple montate in aceeasi carcasa',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Altele',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Casti de ascultare care se pun pe cap sau care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Amplificatoare electrice de audiofrecventa',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Seturi de aparate  electrice de amplificare a sunetului',
    'Microfoane si suporturile lor difuzoare chiar montate in carcasele lor casti de ascultare care se pun pe cap sau  care se introduc in ureche chiar combinate cu un microfon  ansambluri sau seturi formate dintrun microfon si unul sau mai multe difuzoare amplificatoare electrice de audiofrecventa seturi de aparate electrice de amplificare a sunetului Parti',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Tonomate record players care functioneaza prin introducerea unei monede sau a unei fise',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Cu sistem optic de citire prin fascicul laser',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Altele',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Pickupuri care nu sunt prevazute cu amplificatoare electrice de audiofrecventa patefoane si gramofoane',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Care utilizeaza un suport magnetic optic sau cu semiconductori',
    'Aparate de inregistrare a sunetului aparate de reproducere a sunetului aparate de inregistrare si reproducere a sunetului Altele',
    'Aparate de inregistrare sau de reproducere videofonica chiar incorporand un aparat receptor de semnale videofonice De o latime de maximum cm si a caror viteza de rulare este de maximum mm pe secunda',
    'Aparate de inregistrare sau de reproducere videofonica chiar incorporand un aparat receptor de semnale videofonice Altele',
    'Aparate de inregistrare sau de reproducere videofonica chiar incorporand un aparat receptor de semnale videofonice Altele',
    'Parti si accesorii care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitia sau  Doze de pickup',
    'Parti si accesorii care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitia sau  Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Cartele prevazute cu o banda magnetica',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Neinregistrate',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Discuri pentru sisteme de citire prin fascicul laser cu capacitatea de inregistrare de maximum megaocteti altele decat cele care pot fi sterse',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Discuri pentru sisteme de citire prin fascicul laser cu capacitatea de inregistrare de peste megaocteti dar de maximum gigaocteti altele decat cele care pot fi sterse',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Discuri digitale versatile DVD',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Neinregistrate',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Cartele inteligente',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Neinregistrate',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Neinregistrate',
    'Discuri benzi dispozitive de stocare remanenta a datelor pe baza de semiconductori cartele inteligente si alte suporturi pentru inregistrarea sunetului sau pentru inregistrari similare chiar inregistrate inclusiv matritele si formele galvanizate pentru fabricarea discurilor altele decat produsele de la capitolul Altele',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Cu cristale lichide',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Cu diode electroluminiscente organice OLED',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Altele',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Cu cristale lichide',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Cu diode electroluminiscente organice OLED',
    'Module de afisare cu ecran plat cu sau fara ecrane tactile incorporate Altele',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Aparate de emisie',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Aparate de emisie care incorporeaza un aparat de receptie',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Produse ultrarapide mentionate in nota de subpozitii  din prezentul capitol',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Altele rezistente la radiatii mentionate in nota de subpozitii  din prezentul capitol',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Altele cu vedere nocturna mentionate in nota de subpozitii  din prezentul capitol',
    'Aparate de emisie pentru radiodifuziune sau televiziune chiar incorporand un aparat de receptie sau un aparat de inregistrare sau de reproducere a sunetului camere de televiziune aparate fotografice digitale si camere video cu inregistrare Altele',
    'Aparate de radiodetectie si de radiosondaj radar aparate de radionavigatie si aparate de radiotelecomanda Aparate de radiodetectie si de radiosondaj radar',
    'Aparate de radiodetectie si de radiosondaj radar aparate de radionavigatie si aparate de radiotelecomanda Receptoare de radionavigatie',
    'Aparate de radiodetectie si de radiosondaj radar aparate de radionavigatie si aparate de radiotelecomanda Altele',
    'Aparate de radiodetectie si de radiosondaj radar aparate de radionavigatie si aparate de radiotelecomanda Aparate de radiotelecomanda',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Radiocasetofoane de buzunar',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Alte aparate combinate cu un aparat de inregistrare sau de reproducere a sunetului',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Altele',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Cu sistem de citire prin fascicol laser',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Cu caseta si sistem de citire analog sau digital',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Altele',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Cu sistem de citire prin fascicol laser',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Cu caseta si sistem de citire analog sau digital',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Altele',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Altele',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Combinate cu un aparat de inregistrare sau de reproducere a sunetului',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Necombinate cu un aparat de inregistrare sau de reproducere a sunetului dar combinate cu un ceas',
    'Aparate de receptie pentru radiodifuziune chiar combinate in acelasi corp cu un aparat de inregistrare sau de reproducere a sunetului sau cu un ceas Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Capabile de o legatura directa cu o masina automata de prelucrare a datelor de la pozitia  si destinate sa fie folosite cu o asemenea masina',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor De tipul exclusiv sau in principal destinat unei masini automate de prelucrarea datelor de la pozitia',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Cu tehnologia ecranului cu cristale lichide LCD',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Capabile de o legatura directa cu o masina automata de prelucrare a datelor de la pozitia  si destinate sa fie folosite cu o asemenea masina',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Monocrome',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Subansamble electronice pentru a fi incorporate in masinile automate de prelucrare a datelor',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Aparate cuprinzand un dispozitiv cu microprocesor avand incorporat un modem pentru acces la Internet si asigurand o functie de schimb de informatii interactiv capabil de a receptiona semnale de televiziune decodoare cu functie de comunicare inclusiv cele care cuprind un dispozitiv cu functie de inregistrare sau reproducere cu conditia ca acestea sa isi pastreze caracterul esential de decodor cu functie de comunicare',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Aparate cuprinzand un dispozitiv cu microprocesor avand incorporat un modem pentru acces la Internet si asigurand o functie de schimb de informatii interactiv capabil de a receptiona semnale de televiziune decodoare cu functie de comunicare inclusiv cele care cuprind un dispozitiv cu functie de inregistrare sau reproducere cu conditia ca acestea sa isi pastreze caracterul esential de decodor cu functie de comunicare',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Teleproiectoare',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Aparate combinate in aceeasi carcasa cu un aparat de inregistrare sau de reproducere videofonica',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Cu tub de imagine incorporat',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Cu tehnologia ecranului cu cristale lichide LCD',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Cu tehnologia ecranului cu plasma PDP',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele',
    'Monitoare si proiectoare care nu incorporeaza un aparat receptor de televiziune aparate receptoare de televiziune chiar incorporand un aparat  receptor de radiodifuziune sau un aparat de inregistrare sau de reproducere a sunetului sau a imaginilor Altele monocrome',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Antene telescopice si de tip vergea pentru aparate portabile si aparate pentru automobile',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Antene de exterior pentru aparate receptoare de radiodifuziune si de televiziune',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Antene de interior pentru aparate receptoare de radiodifuziune si de televiziune',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Altele',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Filtre si separatoare de antene',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Altele',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Module de diode organice emitatoare de lumina si panouri de diode organice emitatoare de lumina pentru aparatele de la pozitia  sau',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile de articole de la subpozitiile  si',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile de aparate fotografice digitale care se incadreaza la subpozitiile    si  de aparate de la subpozitiile    si',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Cutii si carcase',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Subansamble electronice',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Module pentru iluminatul de fundal cu diode emitatoare de lumina LEDcare sunt surse de iluminat formate dintrunul sau mai multe LEDuri unul sau mai multi conectori si alte componente pasive si care sunt montate pe un circuit imprimat sau pe un alt substrat similar chiar combinate cu diode de protectie sau componente optice si care sunt utilizate pentru iluminatul de fundal al dispozitivelor de afisare cu cristale lichide LCDuri',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Pentru camerele de televiziune de la subpozitiile    si  si aparatele de la pozitiile  si',
    'Parti care pot fi recunoscute ca fiind destinate exclusiv sau in principal aparatelor de la pozitiile Altele',
    'Aparate electrice de semnalizare altele decat cele pentru transmiterea de mesaje de securitate de control sau de comanda pentru caile ferate sau similare pentru caile rutiere fluviale aeriene sau locuri de parcare instalatii portuare sau aeroporturi altele decat cele de la pozitia Aparatele pentru caile ferate sau similare',
    'Aparate electrice de semnalizare altele decat cele pentru transmiterea de mesaje de securitate de control sau de comanda pentru caile ferate sau similare pentru caile rutiere fluviale aeriene sau locuri de parcare instalatii portuare sau aeroporturi altele decat cele de la pozitia Alte aparate',
    'Aparate electrice de semnalizare altele decat cele pentru transmiterea de mesaje de securitate de control sau de comanda pentru caile ferate sau similare pentru caile rutiere fluviale aeriene sau locuri de parcare instalatii portuare sau aeroporturi altele decat cele de la pozitia Parti',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  De tipul celor utilizate pentru cladiri',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Altele',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Cu diode emitatoare de lumina LED',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Care incorporeaza o matrice activa de dispozitive cu cristale lichide LCD',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Altele',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Sonerii buzere mecanisme de sonerie si similare',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Altele',
    'Aparate electrice de semnalizare acustica sau vizuala de exemplu sonerii sirene tablouri de avertizare alarme antifurt sau impotriva incendiilor altele decat cele de la pozitiile sau  Parti',
    'Condensatoare electrice fixe variabile sau reglabile Condensatoare fixe destinate retelelor electrice de Hz cu o putere reactiva de minimum kvar condensatoare de putere',
    'Condensatoare electrice fixe variabile sau reglabile Cu tantal',
    'Condensatoare electrice fixe variabile sau reglabile Electrolitice cu aluminiu',
    'Condensatoare electrice fixe variabile sau reglabile Cu dielectric din ceramica cu un singur strat',
    'Condensatoare electrice fixe variabile sau reglabile Cu dielectric din ceramica cu mai multe straturi',
    'Condensatoare electrice fixe variabile sau reglabile Cu dielectric din hartie sau din material plastic',
    'Condensatoare electrice fixe variabile sau reglabile Altele',
    'Condensatoare electrice fixe variabile sau reglabile Condensatoare variabile sau ajustabile semireglabile',
    'Condensatoare electrice fixe variabile sau reglabile Parti',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Rezistori ficsi cu carbon aglomerati sau in strat',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Pentru o putere absorbita de maximum W',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Altele',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Pentru o putere de maximum W',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Altele',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Pentru o putere absorbita de maximum W',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Altele',
    'Rezistori electrici inclusiv reostate si potentiometre altele decat cele pentru incalzit Parti',
    'Circuite imprimate Circuite multistrat',
    'Circuite imprimate Altele',
    'Circuite imprimate Care contin alte elemente pasive',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Sigurante si intrerupatoare cu elemente fuzibile',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Pentru o tensiune sub kV',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Pentru o tensiune sub kV',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Paratrasnete limitatoare de tensiune si regulatoare de unda',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare sigurante eclatoare pentru paratrasnete limitatoare de tensiune regulatoare de unda prize de curent si alti conectori cutii de jonctiune sau doze de legatura pentru o tensiune peste de volti Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de peste A dar de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de peste A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de  peste A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de peste A dar de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate a curentului de peste A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru o intensitate peste A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Intrerupatoare de curent alternativ care constau din circuite de intrare si de iesire cuplate optic intrerupatoare de curent alternativ cu tiristor izolat',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Intrerupatoare electronice inclusiv intrerupatoare electronice izolate termic care constau dintrun tranzistor si un circuit logic tehnologie chiponchip',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Intrerupatoare electromecanice cu actionare brusca pentru current electric de maximum A',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Cu clapa sau buton',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Rotative',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Dulii Edison',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru cabluri coaxiale',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Pentru circuite imprimate',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Conectori pentru fibre optice fascicole sau cabluri de fibre optice',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Elemente prefabricate pentru trasee electrice',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Conexiuni si elemente de contact pentru fire si cabluri',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Cleme de baterie de tipul celor utilizate pentru autovehiculele de la pozitia    sau',
    'Aparatura pentru comutarea taierea protectia bransarea racordarea sau conectarea circuitelor electrice de exemplu intrerupatoare comutatoare relee sigurante regulatoare de unda fise prize de curent dulii pentru lampi si alti conectori cutii de jonctiune pentru o tensiune de maximum de volti conectori pentru fibre optice fascicole sau cabluri de fibre optice Altele',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Panouri de comanda numerica care incorporeaza o masina automata de prelucrare a datelor',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Aparate de comanda cu memorie programabila',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Dispozitive tactile de introducere a datelor asanumite ecrane tactile fara capacitati de afisare pentru a fi incorporate in aparate care au afisaj care functioneaza prin detectarea prezentei si prin atingerea zonei de afisare',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Altele',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Pentru o tensiune peste V dar de maximum kV',
    'Tablouri panouri console pupitre dulapuri si alte suporturi echipate cu mai multe aparate de la pozitiile sau  pentru comanda sau distribuirea electricitatii inclusiv cele care incorporeaza instrumente sau aparate de la capitolul precum si aparatele cu comanda numerica altele decat aparatele de comutare de la pozitia Pentru o tensiune peste kV',
    'Parti destinate exclusiv sau in principal aparatelor de la pozitiile   sau  Tablouri panouri console pupitre dulapuri si alte suporturi de la pozitia neechipate cu aparatele lor',
    'Parti destinate exclusiv sau in principal aparatelor de la pozitiile   sau  Subansamble electronice',
    'Parti destinate exclusiv sau in principal aparatelor de la pozitiile   sau  Altele',
    'Parti destinate exclusiv sau in principal aparatelor de la pozitiile   sau  Subansamble electronice',
    'Parti destinate exclusiv sau in principal aparatelor de la pozitiile   sau  Altele',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Articole numite faruri si proiectoare etanse',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED De tipul celor utilizate pentru motociclete sau alte autovehicule',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Peste V',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED De maximum V',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Cu reflectoare',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Altele',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED De tipul celor utilizate pentru motociclete sau alte autovehicule',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Peste V',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED De maximum V',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Cu doua socluri',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Altele',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Cu vapori de mercur sau de sodiu',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Cu halogenura metalica',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Lampi fluorescente cu catod rece CCFL pentru retroiluminarea dispozitivelor de afisare cu ecran plat',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Altele',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Lampi cu arc',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Altele',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Module cu diode emitatoare de lumina LED',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Lampi cu diode emitatoare de lumina LED',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Socluri',
    'Lampi si tuburi electrice cu incandescenta sau cu descarcare articole numite faruri si proiectoare etanse si lampi si tuburi cu raze ultraviolete sau infrarosii lampi cu arc surse de lumina cu diode emitatoare de lumina LED Altele',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia In culori',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Monocrome',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Tuburi pentru camere de televiziune',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Alte tuburi',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Tuburi de vizualizare a datelor sau a graficelor monocrome tuburi de vizualizare a datelor sau a graficelor in culori ecranul avand o rezolutie de maximum mm distanta intre puncte',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Alte tuburi catodice',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Magnetroane',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Altele',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Tuburi si valve electronice de receptie sau de amplificare',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Altele',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia De tuburi catodice',
    'Lampi tuburi si valve electronice cu catod cald cu catod rece sau cu fotocatod de exemplu lampi tuburi si valve cu vid cu vapori sau cu gaz tuburi redresoare cu vapori de mercur tuburi catodice tuburi si valve pentru camere de televiziune altele decat cele de la pozitia Altele',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Diode altele decat fotodiodele si diodele emitatoare de lumina LED',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Cu o putere de disipare sub W',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Altele',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Tiristori diacuri si triacuri altele decat dispozitivele fotosensibile',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Diode emitatoare de lumina LED',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Celule fotovoltaice neasamblate in module sau nemontate in panouri',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Celule fotovoltaice asamblate in module sau montate in panouri',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Altele',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Traductoare cu semiconductor',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Altele',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Cristale piezoelectrice montate',
    'Dispozitive cu semiconductor de exemplu diode tranzistori traductoare cu semiconductor dispozitive fotosensibile semiconductoare inclusiv celule fotovoltaice chiar asamblate in module sau montate in panouri diode emitatoare de lumina LED asamblate sau nu cu alte diode emitatoare de lumina LED cristale piezoelectrice montate Parti',
    'Circuite integrate electronice Circuite integrate multicomponent MCO',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Circuite integrate multicomponent MCO',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Cu capacitate de memorare de maxim Mbiti',
    'Circuite integrate electronice Cu capacitate de memorare de peste Mbiti',
    'Circuite integrate electronice Memorii statice cu citirescriere cu acces aleator SRAMs inclusiv memorii tampon cu citirescriere cu acces aleator cacheRAMs',
    'Circuite integrate electronice Memorii exclusiv de citire programabile care pot fi sterse cu raze ultraviolete EPROMs',
    'Circuite integrate electronice A caror capacitate de stocare este de maximum Mbiti',
    'Circuite integrate electronice A caror capacitate de stocare este peste Mbiti',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Alte memorii',
    'Circuite integrate electronice Circuite integrate multicomponent MCO',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Circuite integrate multicomponent MCO',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Altele',
    'Circuite integrate electronice Parti',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Acceleratoare de particule',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Generatoare de semnal',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini pentru galvanoplastie si electroliza de tipul celor utilizate numai sau in principal pentru fabricarea circuitelor imprimate',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Tigari electronice si alte dispozitive de vaporizare electrice personale similare',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Articole special concepute pentru conectarea la aparate sau instrumente telegrafice sau telefonice sau la retele telegrafice sau telefonice',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Amplificatoare pentru microunde',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Dispozitive de telecomanda fara fir cu raze infrarosii pentru consolele de jocuri',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Inregistratoare numerice de date de zbor',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Cititor electronic portabil cu baterie pentru inregistrarea si redarea unui fisier text fisier imagine fixa sau fisier audio',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Aparate de prelucrare a semnalelor digitale capabile sa se conecteze la o retea cu sau fara fir pentru mixarea sunetului',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Dispozitive electronice interactive educative portabile concepute in principal pentru copii',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini de curatat cu plasma care inlatura contaminantii organici din esantioane si portesantioane pentru microscoape electronice',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Dispozitive tactile de introducere a datelor asanumite ecrane tactile fara capacitati de afisare pentru a fi incorporate in aparate care au afisaj care functioneaza prin detectarea prezentei si prin atingerea zonei de afisare',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Masini electrice cu functie de traducere sau de dictionar',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Amplificatoare de antena',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Banci si cupole solare si aparate similare pentru bronzat',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Electrificatori de demarcatie',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Masini si aparate electrice care au o functie proprie nedenumite si necuprinse in alta parte in acest capitol Parti',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Emailate sau lacuite',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Altele',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Altele',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Cabluri coaxiale si alte conductoare electrice coaxiale',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Seturi de fise pentru bujiile de aprindere si alte seturi de fise de tipul celor utilizate in mijloacele de transport',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori De tipul celor utilizate pentru telecomunicatii',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Altele',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori De tipul celor utilizate pentru telecomunicatii pentru o tensiune de maxim V',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Fire si cabluri cu un diametru pe fir peste mm',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Pentru o tensiune de maxim V',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Pentru o tensiune peste V dar sub V',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Pentru o tensiune de V',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Cu conductori din cupru',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Cu alti conductori',
    'Fire cabluri inclusiv cabluri coaxiale si alte conductoare electrice izolate chiar emailate sau oxidate anodic cu sau fara conectori cabluri din fibre optice constituite din fibre izolate individual chiar echipate cu conductoare electrice sau prevazute cu conectori Cabluri din fibre optice',
    'Electrozi din carbune perii din carbune carbuni pentru lampi sau pentru baterii si alte articole din grafit sau din alt carbune cu sau fara metal pentru utilizari electrice De tipul celor utilizati pentru cuptoare',
    'Electrozi din carbune perii din carbune carbuni pentru lampi sau pentru baterii si alte articole din grafit sau din alt carbune cu sau fara metal pentru utilizari electrice Altele',
    'Electrozi din carbune perii din carbune carbuni pentru lampi sau pentru baterii si alte articole din grafit sau din alt carbune cu sau fara metal pentru utilizari electrice Perii',
    'Electrozi din carbune perii din carbune carbuni pentru lampi sau pentru baterii si alte articole din grafit sau din alt carbune cu sau fara metal pentru utilizari electrice Rezistente de incalzire',
    'Electrozi din carbune perii din carbune carbuni pentru lampi sau pentru baterii si alte articole din grafit sau din alt carbune cu sau fara metal pentru utilizari electrice Altele',
    'Izolatori electrici din orice material Din sticla',
    'Izolatori electrici din orice material Din ceramica',
    'Izolatori electrici din orice material Din materiale plastice',
    'Izolatori electrici din orice material Altele',
    'Piese izolatoare in intregime din materiale izolante sau continand piese simple metalice de asamblare de exemplu dulii cu filet incorporate in masa pentru masini aparate sau instalatii electrice altele decat izolatorii de la pozitia tuburile izolatoare si piesele lor de racordare din metale comune izolate in interior Piese izolatoare din ceramica',
    'Piese izolatoare in intregime din materiale izolante sau continand piese simple metalice de asamblare de exemplu dulii cu filet incorporate in masa pentru masini aparate sau instalatii electrice altele decat izolatorii de la pozitia tuburile izolatoare si piesele lor de racordare din metale comune izolate in interior Piese  izolatoare din material plastic',
    'Piese izolatoare in intregime din materiale izolante sau continand piese simple metalice de asamblare de exemplu dulii cu filet incorporate in masa pentru masini aparate sau instalatii electrice altele decat izolatorii de la pozitia tuburile izolatoare si piesele lor de racordare din metale comune izolate in interior Altele',
    'Parti electrice de masini sau aparate nedenumite si necuprinse in alta parte la acest capitol Memorii dinamice cu acces aleator DRAMs sub forma de combinatii multiple cum ar fi memoriile DRAM organizate in stiva stack DRAMs si module',
    'Parti electrice de masini sau aparate nedenumite si necuprinse in alta parte la acest capitol Module de retroiluminare cu diode emitatoare de lumina LED care sunt surse de iluminat compuse din unul sau mai multe LEDuri si din unul sau mai multi conectori si care sunt montate pe un circuit imprimat sau alt substrat similar si alte componente pasive combinate sau nu cu componente optice sau diode de protectie si utilizate ca retroiluminare pentru afisajele cu cristale lichide LCDuri',
    'Parti electrice de masini sau aparate nedenumite si necuprinse in alta parte la acest capitol Altele',
    'Deseuri si resturi electrice si electronice Acumulatoare cu plumb scoase din uz',
    'Deseuri si resturi electrice si electronice Deseuri si resturi de acumulatoare cu plumb',
    'Deseuri si resturi electrice si electronice Pile si baterii de pile electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Acumulatoare electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Pile si baterii de pile electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Acumulatoare electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Pile si baterii de pile electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Acumulatoare electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Pile si baterii de pile electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Acumulatoare electrice scoase din uz',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Care contin pile baterii de pile acumulatoare electrice intrerupatoare cu mercur sticla provenita din tuburi catodice sau alte tipuri de sticla activa sau componente electrice sau electronice care contin cadmiu mercur plumb sau bifenili policlorurati PCB',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Care contin pile baterii de pile acumulatoare electrice intrerupatoare cu mercur sticla provenita din tuburi catodice sau alte tipuri de sticla activa sau componente electrice sau electronice care contin cadmiu mercur plumb sau bifenili policlorurati PCB',
    'Deseuri si resturi electrice si electronice Altele',
    'Deseuri si resturi electrice si electronice Care contin pile baterii de pile acumulatoare electrice intrerupatoare cu mercur sticla provenita din tuburi catodice sau alte tipuri de sticla activa sau componente electrice sau electronice care contin cadmiu mercur plumb sau bifenili policlorurati PCB',
    'Deseuri si resturi electrice si electronice Altele',
    'Locomotive si locotractoare cu sursa exterioara de electricitate sau cu acumulatoare electrice Cu sursa exterioara de electricitate',
    'Locomotive si locotractoare cu sursa exterioara de electricitate sau cu acumulatoare electrice Cu acumulatoare electrice',
    'Alte locomotive si locotractoare tendere Locomotive dieselelectrice',
    'Alte locomotive si locotractoare tendere Altele',
    'Vagoane automotoare pentru cale ferata sau tramvai si drezine cu motor altele decat cele de la pozitia Cu sursa exterioara de electricitate',
    'Vagoane automotoare pentru cale ferata sau tramvai si drezine cu motor altele decat cele de la pozitia Altele',
    'Vehicule pentru intretinere sau pentru servicii de cale ferata sau similare chiar autopropulsate de exemplu vagoaneatelier vagoanemacara vagoane echipate pentru completarea balastului masini de aliniat sinele pentru calea ferata vagoane de proba si drezine Vehicule pentru intretinere sau pentru servicii de cale ferata sau similare chiar autopropulsate de exemplu vagoaneatelier vagoanemacara vagoane echipate pentru completarea balastului masini de aliniat sinele pentru calea ferata vagoane de proba si drezine',
    'Vagoane de calatori vagoane de bagaje vagoane postale si alte vagoane speciale pentru cai ferate sau similare cu exceptia vagoanelor de la pozitia Vagoane de calatori vagoane de bagaje vagoane postale si alte vagoane speciale pentru cai ferate sau similare cu exceptia vagoanelor de la pozitia',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Vagoanecisterna si similare',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Vagoane cu descarcare automata altele decat cele de la subpozitiile',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Special construite pentru transportul produselor puternic radioactive Euratom',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Altele',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Deschise cu lateralele nerabatabile cu o inaltime peste cm basculante',
    'Vagoane pentru transportul marfurilor pe calea ferata sau cai similare Altele',
    'Partile de vehicule pentru cai ferate sau similare Boghiuri si boghiuriBissel de tractiune',
    'Partile de vehicule pentru cai ferate sau similare Alte boghiuri si boghiuriBissel',
    'Partile de vehicule pentru cai ferate sau similare Osii montate sau nu roti si parti ale acestora',
    'Partile de vehicule pentru cai ferate sau similare Parti de boghiuri boghiuriBissel si similare',
    'Partile de vehicule pentru cai ferate sau similare Turnate din fonta fier sau otel',
    'Partile de vehicule pentru cai ferate sau similare Altele',
    'Partile de vehicule pentru cai ferate sau similare Altele',
    'Partile de vehicule pentru cai ferate sau similare Carlige si alte sisteme de cuplare tampoane de soc si parti ale acestora',
    'Partile de vehicule pentru cai ferate sau similare Cutiile osiilor si parti ale acestora',
    'Partile de vehicule pentru cai ferate sau similare Altele',
    'Partile de vehicule pentru cai ferate sau similare Cutiile osiilor si parti ale acestora',
    'Partile de vehicule pentru cai ferate sau similare Altele',
    'Material fix de cai ferate sau similare aparate mecanice inclusiv electromecanice de semnalizare de siguranta de control sau de comanda pentru caile ferate sau similare rutiere sau fluviale zonele de parcare instalatiile portuare sau aerodromuri parti ale acestora Material fix de cai ferate sau similare aparate mecanice inclusiv electromecanice de semnalizare de siguranta de control sau de comanda pentru caile ferate sau similare rutiere sau fluviale zonele de parcare instalatiile portuare sau aerodromuri parti ale acestora',
    'Cadre si containere inclusiv containerecisterna si containererezervor special concepute si echipate pentru unul sau mai multe moduri de transport Containere cu blindaj de plumb pentru protectie impotriva radiatiilor pentru transportul materialelor radioactive Euratom',
    'Cadre si containere inclusiv containerecisterna si containererezervor special concepute si echipate pentru unul sau mai multe moduri de transport Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Motocultoare',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Noi',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Folosite',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Noi',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Folosite',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Noi',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Folosite',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Noi',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Folosite',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare cu senile',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare agricole si forestiere cu roti',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare agricole si forestiere cu roti',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare agricole si forestiere cu roti',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare agricole si forestiere cu roti',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Tractoare agricole si forestiere cu roti',
    'Tractoare cu exceptia carucioarelortractoare de la pozitia Altele',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Noi',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Folosite',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Noi',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Folosite',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Cu o capacitate cilindrica peste cm',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Cu o capacitate cilindrica de maximum cm',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Cu o capacitate cilindrica peste cm',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Cu o capacitate cilindrica de maximum cm',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Avand doar motor electric pentru propulsie',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Noi',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Folosite',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Noi',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Folosite',
    'Autovehicule pentru transportul de minimum persoane inclusiv soferul Cu alte motoare',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Vehicule special concepute pentru a se deplasa pe zapada cu motor cu piston cu aprindere prin compresie diesel sau semidiesel sau prin scanteie',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Altele',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Rulote autopropulsate',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Altele',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Rulote autopropulsate',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Altele',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Rulote autopropulsate',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Altele',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Alte vehicule avand atat motor cu pistoane cu combustie interna cu aprindere prin compresie diesel sau semidiesel cat si motor electric ca motoare pentru propulsie altele decat cele care pot fi incarcate prin conectarea la o sursa externa de energie electrica',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Alte vehicule avand atat motor cu pistoane cu combustie interna cu aprindere prin compresie diesel sau semidiesel cat si motor electric ca motoare pentru propulsie care pot fi incarcate prin conectarea la o sursa externa de energie electrica',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Noi',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Folosite',
    'Autoturisme si alte autovehicule proiectate in principal pentru transportul persoanelor altele decat cele de la pozitia inclusiv masinile de tip break si masinile de curse Altele',
    'Autovehicule pentru transportul marfurilor Cu motor cu piston cu aprindere prin compresie diesel sau semidiesel sau prin scanteie',
    'Autovehicule pentru transportul marfurilor Altele',
    'Autovehicule pentru transportul marfurilor Special concepute pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Autovehicule pentru transportul marfurilor Noi',
    'Autovehicule pentru transportul marfurilor Folosite',
    'Autovehicule pentru transportul marfurilor Altele avand doar un motor electric pentru propulsie',
    'Autovehicule pentru transportul marfurilor Altele',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Automacarale',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Macarale mobile derrick pentru sondare sau forare',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Autovehicule pentru stingerea incendiilor',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Autobetoniere',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Autopompe pentru beton',
    'Autovehicule pentru utilizari speciale altele decat cele proiectate in principal pentru transportul de persoane sau de marfuri de exemplu pentru depanare automacarale pentru stingerea incendiilor autobetoniere pentru curatarea strazilor pentru imprastiat materiale autoateliere unitati radiologice auto Altele',
    'Sasiuri echipate cu motor pentru autovehiculele de la pozitiile Pentru autovehiculele de la pozitia sau',
    'Sasiuri echipate cu motor pentru autovehiculele de la pozitiile Altele',
    'Sasiuri echipate cu motor pentru autovehiculele de la pozitiile Pentru autovehiculele de la pozitia',
    'Sasiuri echipate cu motor pentru autovehiculele de la pozitiile Altele',
    'Caroserii inclusiv cabinele pentru autovehiculele de la pozitiile Destinate industriei de montaj',
    'Caroserii inclusiv cabinele pentru autovehiculele de la pozitiile Altele',
    'Caroserii inclusiv cabinele pentru autovehiculele de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Caroserii inclusiv cabinele pentru autovehiculele de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentruautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentruautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Pentru frane cu discuri',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Cutii de viteza',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentruautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Punti cu diferential chiar prevazute cu alte organe de transmisie si osii portante',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Pentru osii portante',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Roti din aluminiu parti si accesorii de roti din aluminiu',
    'Parti si accesorii de autovehicule de la pozitiile Parti de roti turnate dintro singura piesa in forma de stea din fonta din fier sau otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentruautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Amortizoare de suspensie',
    'Parti si accesorii de autovehicule de la pozitiile Bare stabilizatoare bare de torsiune',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Radiatoare',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Tobe si tevi de esapament',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentruautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Volane coloane si cutii de directie',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Parti si accesorii de autovehicule de la pozitiile Destinate industriei de montaj pentrumotocultoare de la subpozitiaautovehicule de la pozitiaautovehicule de la pozitia cu motor cu piston cu aprindere prin compresie diesel sau semidiesel cu o capacitate cilindrica de maximum cm sau cu motor cu piston cu aprindere prin scanteie cu o capacitate cilindrica de maximum cmautovehicule de la pozitia',
    'Parti si accesorii de autovehicule de la pozitiile Forjate in matrita inchisa din otel',
    'Parti si accesorii de autovehicule de la pozitiile Altele',
    'Carucioare autopropulsate fara dispozitiv de ridicare de tipul celor folosite in fabrici depozite porturi sau aeroporturi pentru transportul marfurilor pe distante scurte carucioare de tractare de tipul celor utilizate in gari parti ale acestora Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Carucioare autopropulsate fara dispozitiv de ridicare de tipul celor folosite in fabrici depozite porturi sau aeroporturi pentru transportul marfurilor pe distante scurte carucioare de tractare de tipul celor utilizate in gari parti ale acestora Altele',
    'Carucioare autopropulsate fara dispozitiv de ridicare de tipul celor folosite in fabrici depozite porturi sau aeroporturi pentru transportul marfurilor pe distante scurte carucioare de tractare de tipul celor utilizate in gari parti ale acestora Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Carucioare autopropulsate fara dispozitiv de ridicare de tipul celor folosite in fabrici depozite porturi sau aeroporturi pentru transportul marfurilor pe distante scurte carucioare de tractare de tipul celor utilizate in gari parti ale acestora Altele',
    'Carucioare autopropulsate fara dispozitiv de ridicare de tipul celor folosite in fabrici depozite porturi sau aeroporturi pentru transportul marfurilor pe distante scurte carucioare de tractare de tipul celor utilizate in gari parti ale acestora Parti',
    'Tancuri si alte vehicule blindate de lupta motorizate inarmate sau nu parti ale acestora Tancuri si alte vehicule blindate de lupta motorizate inarmate sau nu parti ale acestora',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Cu motor cu piston cu o capacitate cilindrica de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Scutere',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Peste cm dar de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Peste cm dar de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Cu o capacitate cilindrica peste cm dar de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Cu o capacitate cilindrica peste cm dar de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Cu motor cu piston cu o capacitate cilindrica peste cm dar de maximum cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Cu motor cu piston cu o capacitate cilindrica peste cm',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Biciclete tricicluri si cvadricicluri cu pedalare asistata cu un motor electric auxiliar cu o putere nominala continua de maximum  W',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Altele',
    'Motociclete inclusiv mopede si biciclete triciclete sau similare echipate cu pedale si cu motor auxiliar cu sau fara atas atase Altele',
    'Biciclete si similare inclusiv tricicluri cu cutie de transport marfuri fara motor Biciclete cu rulmenti cu bile',
    'Biciclete si similare inclusiv tricicluri cu cutie de transport marfuri fara motor Altele',
    'Scaune cu rotile si alte vehicule pentru invalizi chiar cu motor sau cu alt mecanism de propulsie Fara mecanism de propulsie',
    'Scaune cu rotile si alte vehicule pentru invalizi chiar cu motor sau cu alt mecanism de propulsie Altele',
    'Parti si accesorii de vehicule de la pozitiile Frane si parti ale acestora',
    'Parti si accesorii de vehicule de la pozitiile Cutii de viteza si parti ale acestora',
    'Parti si accesorii de vehicule de la pozitiile Roti parti si accesorii pentru roti',
    'Parti si accesorii de vehicule de la pozitiile Tobe si tevi de esapament parti ale acestora',
    'Parti si accesorii de vehicule de la pozitiile Ambreiaje si parti ale acestora',
    'Parti si accesorii de vehicule de la pozitiile Altele',
    'Parti si accesorii de vehicule de la pozitiile De scaune cu rotile sau de alte vehicule pentru invalizi',
    'Parti si accesorii de vehicule de la pozitiile Cadre',
    'Parti si accesorii de vehicule de la pozitiile Furci frontale',
    'Parti si accesorii de vehicule de la pozitiile Parti',
    'Parti si accesorii de vehicule de la pozitiile Jante',
    'Parti si accesorii de vehicule de la pozitiile Spite',
    'Parti si accesorii de vehicule de la pozitiile Butuci de roti altii decat butucii de frane si pinioane de roti libere',
    'Parti si accesorii de vehicule de la pozitiile Frane',
    'Parti si accesorii de vehicule de la pozitiile Parti',
    'Parti si accesorii de vehicule de la pozitiile Sei',
    'Parti si accesorii de vehicule de la pozitiile Pedale',
    'Parti si accesorii de vehicule de la pozitiile Dispozitive de pedalare',
    'Parti si accesorii de vehicule de la pozitiile Parti',
    'Parti si accesorii de vehicule de la pozitiile Ghidoane',
    'Parti si accesorii de vehicule de la pozitiile Portbagaje',
    'Parti si accesorii de vehicule de la pozitiile Schimbatoare de viteza',
    'Parti si accesorii de vehicule de la pozitiile Altele parti',
    'Landouri carucioare si altele similare pentru transportul copiilor parti ale acestor produse Landouri carucioare si vehicule similare',
    'Landouri carucioare si altele similare pentru transportul copiilor parti ale acestor produse Parti',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Cu o greutate de maximum kg',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Cu o greutate peste kg',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Remorci si semiremorci autoincarcatoare sau autodescarcatoare pentru utilizari agricole',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Remorci cisterna si semiremorci cisterna',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Special proiectate pentru transportul produselor puternic radioactive Euratom',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Semiremorci',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Altele',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Folosite',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Alte remorci si semiremorci',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Alte vehicule',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Sasiuri',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Caroserii',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Osii',
    'Remorci si semiremorci pentru toate vehiculele alte vehicule nepropulsate mecanic parti ale acestora Alte parti',
    'Baloane si dirijabile planoare deltaplane si alte vehicule aeriene neconcepute pentru propulsia cu motor Baloane si dirijabile planoare si deltaplane',
    'Baloane si dirijabile planoare deltaplane si alte vehicule aeriene neconcepute pentru propulsia cu motor Altele',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Cu o greutate fara incarcatura de maximum kg',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Cu o greutate fara incarcatura peste kg',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Avioane si alte vehicule aeriene cu o greutate fara incarcatura de maximum kg',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Avioane si alte vehicule aeriene cu o greutate fara incarcatura peste kg dar maximum kg',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Avioane si alte vehicule aeriene cu o greutate fara incarcatura peste kg',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Sateliti de telecomunicatii',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Altele',
    'Alte vehicule aeriene de exemplu elicoptere avioane cu exceptia vehiculelor aeriene fara pilot de la pozitia  vehicule spatiale inclusiv sateliti si vehicule pentru lansarea acestora si vehicule suborbitale Vehicule pentru lansarea acestora si vehicule suborbitale',
    'Parasute inclusiv parasute dirijabile si parapante si rotochute parti si accesorii ale acestora Parasute inclusiv parasute dirijabile si parapante si rotochute parti si accesorii ale acestora',
    'Aparate si dispozitive pentru lansarea vehiculelor aeriene aparate si dispozitive pentru apuntarea vehiculelor aeriene si aparate si dispozitive similare simulatoare de zbor pentru antrenament la sol parti ale acestora Aparate si dispozitive pentru lansarea vehiculelor aeriene si parti ale acestora',
    'Aparate si dispozitive pentru lansarea vehiculelor aeriene aparate si dispozitive pentru apuntarea vehiculelor aeriene si aparate si dispozitive similare simulatoare de zbor pentru antrenament la sol parti ale acestora Altele',
    'Aparate si dispozitive pentru lansarea vehiculelor aeriene aparate si dispozitive pentru apuntarea vehiculelor aeriene si aparate si dispozitive similare simulatoare de zbor pentru antrenament la sol parti ale acestora Simulatoare pentru lupte aeriene si parti ale acestora',
    'Aparate si dispozitive pentru lansarea vehiculelor aeriene aparate si dispozitive pentru apuntarea vehiculelor aeriene si aparate si dispozitive similare simulatoare de zbor pentru antrenament la sol parti ale acestora Altele',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura de maximum kg',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura peste kg',
    'Vehicule aeriene fara pilot Multirotoare echipate cu aparate integrate permanent de la subpozitia  pentru captarea si inregistrarea de imagini video si imagini statice',
    'Vehicule aeriene fara pilot Altele',
    'Vehicule aeriene fara pilot Multirotoare echipate cu aparate integrate permanent de la subpozitia  pentru captarea si inregistrarea de imagini video si imagini statice',
    'Vehicule aeriene fara pilot Altele',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare de peste  kg dar de maximum  kg',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare de peste  kg dar de maximum  kg',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura de maximum kg',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura peste kg',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare mai mica de  g',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare de peste  g dar de maximum  kg',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare de peste  kg dar de maximum  kg',
    'Vehicule aeriene fara pilot Cu o greutate maxima la decolare de peste  kg dar de maximum  kg',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura de maximum kg',
    'Vehicule aeriene fara pilot Cu o greutate fara incarcatura peste kg',
    'Parti ale aparatelor de la pozitiile  sau  Elice si rotoare si parti ale acestora',
    'Parti ale aparatelor de la pozitiile  sau  Trenuri de aterizare si parti ale acestora',
    'Parti ale aparatelor de la pozitiile  sau  Alte parti ale avioanelor ale elicopterelor sau ale vehiculelor aeriene fara pilot',
    'Parti ale aparatelor de la pozitiile  sau  Pentru zmee',
    'Parti ale aparatelor de la pozitiile  sau  De sateliti de telecomunicatii',
    'Parti ale aparatelor de la pozitiile  sau  Altele',
    'Parti ale aparatelor de la pozitiile  sau  Pentru vehicule lansatoare si pentru vehicule suborbitale',
    'Parti ale aparatelor de la pozitiile  sau  Altele',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Pentru navigatia maritima',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Altele',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Pentru navigatia maritima',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Altele',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Pentru navigatia maritima',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Altele',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Pentru navigatia maritima',
    'Pacheboturi nave de croaziera feriboturi cargouri slepuri si nave similare pentru transportul persoanelor sau marfurilor Altele',
    'Nave de pescuit nave fabrica si alte nave pentru prelucrarea sau conservarea produselor de pescarie Pentru navigatia maritima',
    'Nave de pescuit nave fabrica si alte nave pentru prelucrarea sau conservarea produselor de pescarie Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Echipate cu un motor sau concepute pentru a fi echipate cu un motor cu greutatea fara incarcatura neta fara motor de maximum kg',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Care nu sunt concepute pentru a fi utilizate cu un motor si cu o greutate fara incarcatura neta de maximum kg',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Cu o lungime de maximum m',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Pentru navigatia maritima',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Pentru navigatia maritima',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Cu o lungime de maximum m',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Pentru navigatia maritima',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Pentru navigatia maritima',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Cu greutatea unitara de maximum kg',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Cu greutatea unitara de maximum kg',
    'Iahturi si alte nave si ambarcatiuni de agrement sau pentru sport barci cu rame si canoe Altele',
    'Remorchere si impingatoare Remorchere',
    'Remorchere si impingatoare Pentru navigatia maritima',
    'Remorchere si impingatoare Altele',
    'Navefar nave pentru stingerea incendiilor nave de dragare macarale plutitoare si alte nave pentru care navigatia este numai subsidiara fata de functia principala docuri plutitoare platforme de forare sau de exploatare plutitoare sau submersibile Pentru navigatia maritima',
    'Navefar nave pentru stingerea incendiilor nave de dragare macarale plutitoare si alte nave pentru care navigatia este numai subsidiara fata de functia principala docuri plutitoare platforme de forare sau de exploatare plutitoare sau submersibile Altele',
    'Navefar nave pentru stingerea incendiilor nave de dragare macarale plutitoare si alte nave pentru care navigatia este numai subsidiara fata de functia principala docuri plutitoare platforme de forare sau de exploatare plutitoare sau submersibile Platforme de forare sau de exploatare plutitoare sau submersibile',
    'Navefar nave pentru stingerea incendiilor nave de dragare macarale plutitoare si alte nave pentru care navigatia este numai subsidiara fata de functia principala docuri plutitoare platforme de forare sau de exploatare plutitoare sau submersibile Pentru navigatia maritima',
    'Navefar nave pentru stingerea incendiilor nave de dragare macarale plutitoare si alte nave pentru care navigatia este numai subsidiara fata de functia principala docuri plutitoare platforme de forare sau de exploatare plutitoare sau submersibile Altele',
    'Alte nave inclusiv nave de razboi si nave de salvare altele decat cele cu rame Nave de razboi',
    'Alte nave inclusiv nave de razboi si nave de salvare altele decat cele cu rame Pentru navigatia maritima',
    'Alte nave inclusiv nave de razboi si nave de salvare altele decat cele cu rame Cu greutatea unitara de maximum kg',
    'Alte nave inclusiv nave de razboi si nave de salvare altele decat cele cu rame Altele',
    'Alte dispozitive plutitoare de exemplu plute rezervoare chesoane geamanduri de ancorare geamanduri si balize Plute gonflabile',
    'Alte dispozitive plutitoare de exemplu plute rezervoare chesoane geamanduri de ancorare geamanduri si balize Altele',
    'Nave si alte dispozitive plutitoare pentru dezmembrare Nave si alte dispozitive plutitoare pentru dezmembrare',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Cabluri conductoare de imagine',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Materiale polarizante sub forma de foi sau de placi',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Lentile de contact',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Necorectoare',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Unifocale',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Necorectoare',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Unifocale',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Fibre optice si fascicule de fibre optice cabluri din fibre optice altele decat cele de la pozitia materiale polarizante sub forma de foi sau placi lentile inclusiv lentile de contact prisme oglinzi si alte elemente optice din orice material nemontate altele decat cele din sticla neprelucrata optic Altele',
    'Lentile prisme oglinzi si alte elemente optice din orice material montate pentru instrumente sau aparate altele decat cele din sticla neprelucrata optic Pentru aparate de luat vederi pentru proiectoare pentru aparate fotografice sau cinematografice de marire sau de micsorare',
    'Lentile prisme oglinzi si alte elemente optice din orice material montate pentru instrumente sau aparate altele decat cele din sticla neprelucrata optic Altele',
    'Lentile prisme oglinzi si alte elemente optice din orice material montate pentru instrumente sau aparate altele decat cele din sticla neprelucrata optic Filtre',
    'Lentile prisme oglinzi si alte elemente optice din orice material montate pentru instrumente sau aparate altele decat cele din sticla neprelucrata optic Altele',
    'Monturi si rame de ochelari sau de articole similare si parti ale acestora Din material plastic',
    'Monturi si rame de ochelari sau de articole similare si parti ale acestora Din alte materiale',
    'Monturi si rame de ochelari sau de articole similare si parti ale acestora Parti',
    'Ochelari de corectie de protectie sau altele si articole similare Cu lentile prelucrate optic',
    'Ochelari de corectie de protectie sau altele si articole similare Cu lentile din material plastic',
    'Ochelari de corectie de protectie sau altele si articole similare Altele',
    'Ochelari de corectie de protectie sau altele si articole similare Cu lentile din material plastic',
    'Ochelari de corectie de protectie sau altele si articole similare Altele',
    'Binocluri lunete lunete astronomice telescoape optice si monturi ale acestora alte instrumente de astronomie si suporturile acestora cu exceptia instrumentelor de radioastronomie Binocluri',
    'Binocluri lunete lunete astronomice telescoape optice si monturi ale acestora alte instrumente de astronomie si suporturile acestora cu exceptia instrumentelor de radioastronomie Alte instrumente',
    'Binocluri lunete lunete astronomice telescoape optice si monturi ale acestora alte instrumente de astronomie si suporturile acestora cu exceptia instrumentelor de radioastronomie Parti si accesorii inclusiv suporturi',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Aparate fotografice special construite pentru fotografierea submarina sau aeriana pentru examenul medical al organelor interne sau pentru laboratoarele de medicina legala sau de criminalistica',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Aparate fotografice cu developare si revelare instantanee',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Aparate fotografice de unica folosinta',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Altele',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Altele',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Aparate cu tub cu descarcare pentru producerea luminii blitz numite blitzuri electronice',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Altele',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia De aparate fotografice',
    'Aparate fotografice altele decat cinematografice aparate si dispozitive inclusiv lampi si tuburi pentru producerea luminii de blitz in tehnica fotografica cu exceptia lampilor si tuburilor cu descarcare de la pozitia Altele',
    'Aparate de filmat si proiectoare cinematografice chiar incorporand aparate de inregistrare sau de reproducere a sunetului Aparate de filmat',
    'Aparate de filmat si proiectoare cinematografice chiar incorporand aparate de inregistrare sau de reproducere a sunetului Proiectoare',
    'Aparate de filmat si proiectoare cinematografice chiar incorporand aparate de inregistrare sau de reproducere a sunetului De aparate de filmat',
    'Aparate de filmat si proiectoare cinematografice chiar incorporand aparate de inregistrare sau de reproducere a sunetului De proiectoare',
    'Proiectoare de imagini fixe altele decat cinematografice aparate fotografice de marire sau de reducere Proiectoare si aparate de marire sau de reducere',
    'Proiectoare de imagini fixe altele decat cinematografice aparate fotografice de marire sau de reducere Parti si accesorii',
    'Aparate si echipamente pentru laboratoare fotografice sau cinematografice nedenumite si necuprinse in alta parte in acest capitol negatoscoape ecrane pentru proiectii Aparate si echipamente pentru developarea automata a peliculelor fotografice a filmelor cinematografice sau a hartiei fotografice in role sau pentru expunerea automata a peliculei developate pe role de hartie fotografica',
    'Aparate si echipamente pentru laboratoare fotografice sau cinematografice nedenumite si necuprinse in alta parte in acest capitol negatoscoape ecrane pentru proiectii Alte aparate si dispozitive pentru laboratoare fotografice sau cinematografice negatoscoape',
    'Aparate si echipamente pentru laboratoare fotografice sau cinematografice nedenumite si necuprinse in alta parte in acest capitol negatoscoape ecrane pentru proiectii Ecrane pentru proiectii',
    'Aparate si echipamente pentru laboratoare fotografice sau cinematografice nedenumite si necuprinse in alta parte in acest capitol negatoscoape ecrane pentru proiectii De aparate si echipamente de la subpozitiile    sau',
    'Aparate si echipamente pentru laboratoare fotografice sau cinematografice nedenumite si necuprinse in alta parte in acest capitol negatoscoape ecrane pentru proiectii Altele',
    'Microscoape optice inclusiv microscoape pentru microfotografie microcinematografie sau microproiectie Microscoape stereoscopice',
    'Microscoape optice inclusiv microscoape pentru microfotografie microcinematografie sau microproiectie Microscoape microfotografice dotate cu echipamente special proiectate pentru manipularea si transportul discurilor wafers semiconductoare sau reticulelor',
    'Microscoape optice inclusiv microscoape pentru microfotografie microcinematografie sau microproiectie Altele',
    'Microscoape optice inclusiv microscoape pentru microfotografie microcinematografie sau microproiectie Alte microscoape',
    'Microscoape optice inclusiv microscoape pentru microfotografie microcinematografie sau microproiectie Parti si accesorii',
    'Microscoape altele decat microscoapele optice difractografe Microscoape altele decat microscoapele optice si difractografe',
    'Microscoape altele decat microscoapele optice difractografe Parti si accesorii',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Telescoape concepute pentru a face parte din masini dispozitive instrumente sau aparate de la prezentul capitol sau din sectiunea XVI',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Altele',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Lasere altele decat diodele laser',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Separatoare optice pasive fara elemente electrice sau electronice pentru telecomunicatii',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Altele',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Pentru lunetele de ochire pentru arme sau periscoape',
    'Lasere altele decat diodele laser alte aparate si instrumente optice nedenumite si necuprinse la alte pozitii ale acestui capitol Altele',
    'Busole inclusiv compasuri de navigatie alte instrumente si aparate de navigatie Busole inclusiv compasuri de navigatie',
    'Busole inclusiv compasuri de navigatie alte instrumente si aparate de navigatie Sistem de navigatie inertial',
    'Busole inclusiv compasuri de navigatie alte instrumente si aparate de navigatie Altele',
    'Busole inclusiv compasuri de navigatie alte instrumente si aparate de navigatie Alte instrumente si aparate',
    'Busole inclusiv compasuri de navigatie alte instrumente si aparate de navigatie Parti si accesorii',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Telemetre',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Teodolite si tahometre',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Electronice',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Altele',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Instrumente si aparate de fotogrametrie',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre De meteorologie hidrologie si de geofizica',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre De geodezie topografie arpentaj nivelmetrie si de hidrografie',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Altele',
    'Instrumente si aparate de geodezie de topografie de arpentaj de nivelmetrie de fotogrametrie de hidrografie de oceanografie de hidrologie de meteorologie sau de geofizica cu exceptia busolelor telemetre Parti si accesorii',
    'Balante sensibile la o greutate de cg sau mai putin cu sau fara greutati Balante',
    'Balante sensibile la o greutate de cg sau mai putin cu sau fara greutati Parti si accesorii',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Plottere',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Plottere',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Alte instrumente de desen',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Instrumente de trasare',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Instrumente de calcul',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Micrometre sublere calibre reglabile si joje',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Metri si rigle gradate',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Altele',
    'Instrumente de desen de trasare sau de calcul de exemplu masini de desenat pantografe raportoare truse de matematica rigle si discuri de calcul instrumente pentru masurarea lungimii pentru folosire manuala de exemplu rigle rulete micrometre sublere calibre nedenumite si necuprinse in alta parte in acest capitol Parti si accesorii',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Electrocardiografe',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate pentru diagnostic prin scanare ultrasonica scanners',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate de diagnostic cu vizualizare prin rezonanta magnetica',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate de scintigrafie',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate de monitorizare capabile sa urmareasca simultan doi sau mai multi parametri fiziologici',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Altele',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate cu raze ultraviolete sau infrarosii',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Din material plastic',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Altele',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Ace tubulare din metal',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Ace pentru sutura',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Altele',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Freze dentare chiar combinate pe o baza comuna cu alte echipamente dentare',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Pietre de slefuit discuri freze si perii pentru freze dentare',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Altele',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Neoptice',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Optice',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Instrumente si aparate pentru masurarea presiunii arteriale',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Endoscoape',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Rinichi artificiali',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate de diatermie',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate de transfuzie si perfuzie',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Instrumente si aparate pentru anestezie',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Aparate pentru stimularea nervilor',
    'Instrumente si aparate pentru medicina chirurgie stomatologie sau medicina veterinara inclusiv aparate de scintigrafie si alte aparate electromedicale precum si aparate pentru testarea vederii Altele',
    'Aparate de mecanoterapie aparate de masaj aparate pentru testari psihologice aparate de ozonoterapie de oxigenoterapie de aerosoloterapie aparate respiratorii de reanimare si alte aparate de terapie respiratorie Vibromasoare electrice',
    'Aparate de mecanoterapie aparate de masaj aparate pentru testari psihologice aparate de ozonoterapie de oxigenoterapie de aerosoloterapie aparate respiratorii de reanimare si alte aparate de terapie respiratorie Altele',
    'Aparate de mecanoterapie aparate de masaj aparate pentru testari psihologice aparate de ozonoterapie de oxigenoterapie de aerosoloterapie aparate respiratorii de reanimare si alte aparate de terapie respiratorie Aparate de ventilatie mecanica capabile sa asigure o ventilatie invaziva',
    'Aparate de mecanoterapie aparate de masaj aparate pentru testari psihologice aparate de ozonoterapie de oxigenoterapie de aerosoloterapie aparate respiratorii de reanimare si alte aparate de terapie respiratorie Aparate de ventilatie mecanica neinvazive',
    'Aparate de mecanoterapie aparate de masaj aparate pentru testari psihologice aparate de ozonoterapie de oxigenoterapie de aerosoloterapie aparate respiratorii de reanimare si alte aparate de terapie respiratorie Altele inclusiv parti si accesorii',
    'Alte aparate respiratorii si masti de gaze excluzand mastile de protectie lipsite de parti mecanice si element filtrant amovibil Masti de gaze',
    'Alte aparate respiratorii si masti de gaze excluzand mastile de protectie lipsite de parti mecanice si element filtrant amovibil Altele inclusiv parti si accesorii',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Articole si aparate de ortopedie',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Articole si aparate pentru fracturi',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Din material plastic',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Din alte materiale',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Altele',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Proteze articulare',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Proteze oculare',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Altele',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Aparate pentru facilitarea auzului surzilor cu exceptia partilor si accesoriilor',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Stimulatoare cardiace cu exceptia partilor si accesoriilor',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Parti si accesorii pentru aparate acustice pentru facilitarea auzului la surzi',
    'Articole si aparate de ortopedie inclusiv centurile si bandajele medicochirurgicale si carjele atele gutiere si alte articole si aparate pentru fracturi articole si aparate de proteza aparate pentru facilitarea auzului surzilor si alte aparate care se poarta se duc in mana sau care se implanteaza in organism pentru compensarea unei deficiente sau infirmitati Altele',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Aparate de tomografie computerizata',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Altele pentru stomatologie',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Altele de utilizare medicala chirurgicala sau veterinara',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Pentru alte utilizari',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament De utilizare medicala chirurgicala stomatologica sau veterinara',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Pentru alte utilizari',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Tuburi cu razeX',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Parti si accesorii pentru aparate cu raze X',
    'Aparate cu razeX si aparate care utilizeaza raze alfa beta gama sau alte radiatii ionizante chiar de utilizare medicala chirurgicala stomatologica sau veterinara inclusiv aparate de radiofotografie sau de radioterapie tuburi cu razeX si dispozitive generatoare de razeX generatoare de tensiune pupitre de comanda ecrane mese fotolii si suporturi similare pentru examinare sau tratament Altele',
    'Instrumente aparate si modele destinate demonstratiilor de exemplu in invatamant sau la expozitii improprii altor utilizari Pentru demonstratii de fizica chimie sau tehnica',
    'Instrumente aparate si modele destinate demonstratiilor de exemplu in invatamant sau la expozitii improprii altor utilizari Altele',
    'Masini si aparate pentru incercari de duritate tractiune comprimare elasticitate sau de alte proprietati mecanice ale materialelor de exemplu metale lemn textile hartie material plastic Universale si pentru testarea tractiunii',
    'Masini si aparate pentru incercari de duritate tractiune comprimare elasticitate sau de alte proprietati mecanice ale materialelor de exemplu metale lemn textile hartie material plastic Pentru testarea duritatii',
    'Masini si aparate pentru incercari de duritate tractiune comprimare elasticitate sau de alte proprietati mecanice ale materialelor de exemplu metale lemn textile hartie material plastic Altele',
    'Masini si aparate pentru incercari de duritate tractiune comprimare elasticitate sau de alte proprietati mecanice ale materialelor de exemplu metale lemn textile hartie material plastic Alte masini si aparate',
    'Masini si aparate pentru incercari de duritate tractiune comprimare elasticitate sau de alte proprietati mecanice ale materialelor de exemplu metale lemn textile hartie material plastic Parti si accesorii',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Medicale sau veterinare',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Altele',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Altele',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Barometre necombinate cu alte instrumente',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Electronice',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Altele',
    'Densimetre areometre si instrumente flotante similare termometre pirometre barometre higrometre si psihrometre cu inregistrare sau nu chiar combinate intre ele Parti si accesorii',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Debitmetre',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Altele',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Debitmetre',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Altele',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Electronice',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Manometre cu spirala sau cu membrane manometrice metalice',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Altele',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Electronice',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Altele',
    'Instrumente si aparate pentru masurarea sau controlul debitului nivelului presiunii sau altor caracteristici variabile ale lichidelor sau gazelor de exemplu debitmetre indicatoare de nivel manometre contoare de caldura cu exceptia instrumentelor si aparatelor de la pozitiile   sau  Parti si accesorii',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Electronice',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Altele',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Cromatografe si aparate de electroforeza',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Spectrometre spectrofotometre si spectrografe care utilizeaza radiatii optice UV vizibile IR',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Alte instrumente si aparate care folosesc radiatii optice UV vizibile IR',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Spectrometre de masa',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Exponometre',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame pHmetre rHmetre si alte aparate pentru masurarea conductivitatii',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Altele',
    'Instrumente si aparate pentru analize fizice sau chimice de exemplu polarimetre refractometre spectrometre analizoare de gaz sau de fum instrumente si aparate de masura si control al viscozitatii porozitatii dilatarii tensiunii superficiale sau similare sau pentru masurari calorimetrice acustice sau fotometrice inclusiv exponometre microtoame Microtoame parti si accesorii',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Contoare de gaze',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Contoare de lichide',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Monofazat',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Polifazat',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Altele',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor De contoare electrice',
    'Contoare de gaze de lichide sau de electricitate inclusiv contoare pentru etalonarea lor Altele',
    'Alte contoare de exemplu contoare de turatii de productie pentru taximetre de kilometraj pedometre indicatoare de viteza si tahometre altele decat cele de la pozitia sau  stroboscoape Contoare de turatii sau de productie pentru taximetre de kilometraj pedometre si contoare similare',
    'Alte contoare de exemplu contoare de turatii de productie pentru taximetre de kilometraj pedometre indicatoare de viteza si tahometre altele decat cele de la pozitia sau  stroboscoape Indicatoare de viteza pentru vehicule terestre',
    'Alte contoare de exemplu contoare de turatii de productie pentru taximetre de kilometraj pedometre indicatoare de viteza si tahometre altele decat cele de la pozitia sau  stroboscoape Altele',
    'Alte contoare de exemplu contoare de turatii de productie pentru taximetre de kilometraj pedometre indicatoare de viteza si tahometre altele decat cele de la pozitia sau  stroboscoape Stroboscoape',
    'Alte contoare de exemplu contoare de turatii de productie pentru taximetre de kilometraj pedometre indicatoare de viteza si tahometre altele decat cele de la pozitia sau  stroboscoape Parti si accesorii',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Instrumente si aparate pentru masurarea sau detectarea radiatiilor ionizante',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Osciloscoape si oscilografe',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Multimetre fara dispozitiv inregistrator',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Multimetre cu dispozitiv inregistrator',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Instrumente de masurare a rezistentei',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Altele',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Altele cu dispozitiv inregistrator',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Alte instrumente si aparate special proiectate pentru telecomunicatii de exemplu ipsometre cherdometre distorsiometre psofometre',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Pentru masurarea sau controlul discurilor sau al dispozitivelor cu semiconductor inclusiv circuitele integrate',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Altele cu dispozitiv inregistrator',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Altele',
    'Osciloscoape analizoare de spectru si alte instrumente si aparate pentru masurarea sau controlul marimilor electrice cu exceptia celor de la pozitia instrumente si aparate pentru masurarea sau detectarea radiatiilor alfa beta gama a razelorX a radiatiilor cosmice sau a altor radiatii ionizante Parti si accesorii',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Masini de echilibrat piese mecanice',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Bancuri de proba',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Pentru controlul discurilor sau al dispozitivelor cu semiconductor inclusiv circuitele integrate sau pentru controlul fotomastilor sau al reticulelor utilizate in fabricarea dispozitivelor cu semiconductor inclusiv circuitele integrate',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Proiectoare de profile',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Altele',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Pentru masurarea sau controlul marimilor geometrice',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Altele',
    'Instrumente aparate si masini de masura sau de control nedenumite si necuprinse in alta parte in acest capitol proiectoare de profile Parti si accesorii',
    'Instrumente si aparate pentru reglare sau control automat Electronice',
    'Instrumente si aparate pentru reglare sau control automat Altele',
    'Instrumente si aparate pentru reglare sau control automat Manostate presostate',
    'Instrumente si aparate pentru reglare sau control automat Hidraulice sau pneumatice',
    'Instrumente si aparate pentru reglare sau control automat Altele',
    'Instrumente si aparate pentru reglare sau control automat Parti si accesorii',
    'Parti si accesorii nedenumite si necuprinse in alta parte in acest capitol pentru masini aparate instrumente sau articole de la capitolul Module pentru iluminatul de fundal cu diode emitatoare de lumina LEDcare sunt surse de iluminat formate dintrunul sau mai multe LEDuri unul sau mai multi conectori si alte componente pasive si care sunt montate pe un circuit imprimat sau pe un alt substrat similar chiar combinate cu diode de protectie sau componente optice si care sunt utilizate pentru iluminatul de fundal al dispozitivelor de afisare cu cristale lichide LCDuri',
    'Parti si accesorii nedenumite si necuprinse in alta parte in acest capitol pentru masini aparate instrumente sau articole de la capitolul Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Numai cu afisaj mecanic',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Cu remontoar dispozitiv de intoarcere automat',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Actionate electric',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip cu carcase din metale pretioase sau din metale placate sau dublate cu metale pretioase Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Numai cu afisaj mecanic',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Numai cu afisaj optoelectronic',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Cu remontoar dispozitiv de intoarcere automat',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Altele',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Actionate electric',
    'Ceasuri de mana ceasuri de buzunar si alte ceasuri similare inclusiv cronometrele de acelasi tip altele decat cele de la pozitia Altele',
    'Ceasuri desteptatoare si pendule mici cu mecanism de ceas exceptand ceasurile de la pozitia Actionate electric',
    'Ceasuri desteptatoare si pendule mici cu mecanism de ceas exceptand ceasurile de la pozitia Altele',
    'Ceasuri de bord si ceasuri similare pentru automobile avioane vehicule aeriene nave sau alte vehicule Ceasuri de bord si ceasuri similare pentru automobile avioane vehicule aeriene nave sau alte vehicule',
    'Alte ceasuri Actionate electric',
    'Alte ceasuri Altele',
    'Alte ceasuri Actionate electric',
    'Alte ceasuri Altele',
    'Alte ceasuri Actionate electric',
    'Alte ceasuri Altele',
    'Aparate pentru controlul timpului si cronometre cu mecanism de ceasornic sau cu motor sincron de exemplu ceasuri de pontare ceasuri cu data ceasuri contoare de timp Ceasuri de pontare ceasuri cu data ceasuri contoare de timp',
    'Aparate pentru controlul timpului si cronometre cu mecanism de ceasornic sau cu motor sincron de exemplu ceasuri de pontare ceasuri cu data ceasuri contoare de timp Altele',
    'Intrerupatoarele orare si alte aparate care permit declansarea unui mecanism dupa un interval de timp prestabilit prevazute cu un mecanism de ceasornic sau cu un motor sincron Intrerupatoarele orare si alte aparate care permit declansarea unui mecanism dupa un interval de timp prestabilit prevazute cu un mecanism de ceasornic sau cu un motor sincron',
    'Mecanisme pentru ceasuri de mana complete si asamblate Numai cu afisaj mecanic sau cu un dispozitiv care permite incorporarea unui afisaj mecanic',
    'Mecanisme pentru ceasuri de mana complete si asamblate Numai cu afisaj optoelectronic',
    'Mecanisme pentru ceasuri de mana complete si asamblate Altele',
    'Mecanisme pentru ceasuri de mana complete si asamblate Cu remontoar dispozitiv de intoarcere automat',
    'Mecanisme pentru ceasuri de mana complete si asamblate Altele',
    'Mecanisme de ceasornicarie complete si asamblate altele decat ceasurile de mana Actionate electric',
    'Mecanisme de ceasornicarie complete si asamblate altele decat ceasurile de mana Altele',
    'Mecanisme de ceasornicarie complete neasamblate sau partial asamblate seturi de mecanisme mecanisme incomplete de ceasornic asamblate ebose pentru mecanisme de ceasornicarie Cu balansiere spirala',
    'Mecanisme de ceasornicarie complete neasamblate sau partial asamblate seturi de mecanisme mecanisme incomplete de ceasornic asamblate ebose pentru mecanisme de ceasornicarie Altele',
    'Mecanisme de ceasornicarie complete neasamblate sau partial asamblate seturi de mecanisme mecanisme incomplete de ceasornic asamblate ebose pentru mecanisme de ceasornicarie Mecanisme incomplete asamblate',
    'Mecanisme de ceasornicarie complete neasamblate sau partial asamblate seturi de mecanisme mecanisme incomplete de ceasornic asamblate ebose pentru mecanisme de ceasornicarie Ebose pentru mecanisme de ceasornicarie',
    'Mecanisme de ceasornicarie complete neasamblate sau partial asamblate seturi de mecanisme mecanisme incomplete de ceasornic asamblate ebose pentru mecanisme de ceasornicarie Altele',
    'Carcase de ceasuri de mana de la pozitiile sau  si parti ale acestora Carcase din metale pretioase sau placate sau dublate cu metale pretioase',
    'Carcase de ceasuri de mana de la pozitiile sau  si parti ale acestora Carcase din metale comune chiar aurite sau argintate',
    'Carcase de ceasuri de mana de la pozitiile sau  si parti ale acestora Alte carcase',
    'Carcase de ceasuri de mana de la pozitiile sau  si parti ale acestora Parti',
    'Carcase si cutii protectoare pentru aparate de ceasornicarie si parti ale acestora Carcase si cutii',
    'Carcase si cutii protectoare pentru aparate de ceasornicarie si parti ale acestora Parti',
    'Bratari de ceasuri si parti ale acestora Din metale pretioase',
    'Bratari de ceasuri si parti ale acestora Placate sau dublate cu metale pretioase',
    'Bratari de ceasuri si parti ale acestora Din metale comune chiar aurite sau argintate',
    'Bratari de ceasuri si parti ale acestora Altele',
    'Alte furnituri de ceasornicarie Cadrane',
    'Alte furnituri de ceasornicarie Platine si punti',
    'Alte furnituri de ceasornicarie Resorturi inclusiv spirale',
    'Alte furnituri de ceasornicarie Altele',
    'Piane chiar automate clavecine si alte instrumente cu corzi cu claviatura Noi',
    'Piane chiar automate clavecine si alte instrumente cu corzi cu claviatura Folosite',
    'Piane chiar automate clavecine si alte instrumente cu corzi cu claviatura Piane cu coada',
    'Piane chiar automate clavecine si alte instrumente cu corzi cu claviatura Altele',
    'Alte instrumente muzicale cu corzi de exemplu chitare viori harpe Viori',
    'Alte instrumente muzicale cu corzi de exemplu chitare viori harpe Altele',
    'Alte instrumente muzicale cu corzi de exemplu chitare viori harpe Chitare',
    'Alte instrumente muzicale cu corzi de exemplu chitare viori harpe Altele',
    'Instrumente muzicale de suflat de exemplu orgi cu tuburi si claviatura acordeoane clarinete trompete cimpoaie altele decat orgile pentru distractii in aer liber si flasnetele Instrumente numite alamuri',
    'Instrumente muzicale de suflat de exemplu orgi cu tuburi si claviatura acordeoane clarinete trompete cimpoaie altele decat orgile pentru distractii in aer liber si flasnetele Acordeoane si instrumente similare',
    'Instrumente muzicale de suflat de exemplu orgi cu tuburi si claviatura acordeoane clarinete trompete cimpoaie altele decat orgile pentru distractii in aer liber si flasnetele Muzicute',
    'Instrumente muzicale de suflat de exemplu orgi cu tuburi si claviatura acordeoane clarinete trompete cimpoaie altele decat orgile pentru distractii in aer liber si flasnetele Orgi cu tuburi si claviatura armonii si instrumente similare cu claviatura si ancii libere metalice',
    'Instrumente muzicale de suflat de exemplu orgi cu tuburi si claviatura acordeoane clarinete trompete cimpoaie altele decat orgile pentru distractii in aer liber si flasnetele Altele',
    'Instrumente muzicale de percutie de exemplu tobe xilofoane cinele castaniete maracase Instrumente muzicale de percutie de exemplu tobe xilofoane cinele castaniete maracase',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Orgi',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Piane digitale',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Sintetizatoare',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Altele',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Chitare',
    'Instrumente muzicale al caror sunet este produs sau amplificat prin mijloace electrice de exemplu orgi chitare acordeoane Altele',
    'Cutii muzicale orgi pentru distractii in aer liber flasnete pasari mecanice cantatoare ferastraie muzicale si alte instrumente muzicale nedenumite si necuprinse in alta pozitie a acestui capitol fluiere sau signale cornuri pentru apel si alte instrumente de suflat pentru apel sau semnalizare Cutii muzicale',
    'Cutii muzicale orgi pentru distractii in aer liber flasnete pasari mecanice cantatoare ferastraie muzicale si alte instrumente muzicale nedenumite si necuprinse in alta pozitie a acestui capitol fluiere sau signale cornuri pentru apel si alte instrumente de suflat pentru apel sau semnalizare Altele',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Corzi pentru instrumente muzicale',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Parti si accesorii de piane',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Parti si accesorii ale instrumentelor muzicale de la pozitia',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Parti si accesorii ale instrumentelor muzicale de la pozitia',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Parti si accesorii ale instrumentelor muzicale de la pozitia',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Metronoame si diapazoane',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Mecanisme pentru cutii muzicale',
    'Parti de exemplu mecanisme de cutii muzicale si accesorii de exemplu cartele si role pentru aparate de cantat mecanic ale instrumentelor muzicale metronoame si diapazoane de toate tipurile Altele',
    'Arme de razboi altele decat revolvere pistoale si arme albe de la pozitia Piese de artilerie de exemplu tunuri obuziere si mortiere',
    'Arme de razboi altele decat revolvere pistoale si arme albe de la pozitia Tuburi lansatoare de rachete aruncatoare de flacari aruncatoare de grenade aruncatoare de torpile si aruncatoare similare',
    'Arme de razboi altele decat revolvere pistoale si arme albe de la pozitia Altele',
    'Revolvere si pistoale altele decat cele de la pozitia sau  Revolvere si pistoale altele decat cele de la pozitia sau',
    'Alte arme de foc si dispozitive care utilizeaza incarcaturi explozive de exemplu pusti si arme de vanatoare sau de tir sportiv arme de foc care se incarca numai prin teava pistoale de lansare a rachetelor si alte dispozitive pentru lansarea rachetelor de semnalizare pistoale si revolvere pentru incarcaturi oarbe pistoale cu cui pentru abator tunuri lansatoare de parame Arme de foc care se incarca numai prin teava',
    'Alte arme de foc si dispozitive care utilizeaza incarcaturi explozive de exemplu pusti si arme de vanatoare sau de tir sportiv arme de foc care se incarca numai prin teava pistoale de lansare a rachetelor si alte dispozitive pentru lansarea rachetelor de semnalizare pistoale si revolvere pentru incarcaturi oarbe pistoale cu cui pentru abator tunuri lansatoare de parame Cu o singura teava lisa',
    'Alte arme de foc si dispozitive care utilizeaza incarcaturi explozive de exemplu pusti si arme de vanatoare sau de tir sportiv arme de foc care se incarca numai prin teava pistoale de lansare a rachetelor si alte dispozitive pentru lansarea rachetelor de semnalizare pistoale si revolvere pentru incarcaturi oarbe pistoale cu cui pentru abator tunuri lansatoare de parame Altele',
    'Alte arme de foc si dispozitive care utilizeaza incarcaturi explozive de exemplu pusti si arme de vanatoare sau de tir sportiv arme de foc care se incarca numai prin teava pistoale de lansare a rachetelor si alte dispozitive pentru lansarea rachetelor de semnalizare pistoale si revolvere pentru incarcaturi oarbe pistoale cu cui pentru abator tunuri lansatoare de parame Alte pusti si carabine de vanatoare sau de tir sportiv',
    'Alte arme de foc si dispozitive care utilizeaza incarcaturi explozive de exemplu pusti si arme de vanatoare sau de tir sportiv arme de foc care se incarca numai prin teava pistoale de lansare a rachetelor si alte dispozitive pentru lansarea rachetelor de semnalizare pistoale si revolvere pentru incarcaturi oarbe pistoale cu cui pentru abator tunuri lansatoare de parame Altele',
    'Alte arme de exemplu pusti carabine si pistoale cu resort cu aer comprimat sau cu gaz bastoane cu exceptia celor de la pozitia Alte arme de exemplu pusti carabine si pistoale cu resort cu aer comprimat sau cu gaz bastoane cu exceptia celor de la pozitia',
    'Parti si accesorii ale articolelor de la pozitiile De revolvere sau pistoale',
    'Parti si accesorii ale articolelor de la pozitiile De pusti sau carabine de la pozitia',
    'Parti si accesorii ale articolelor de la pozitiile Ale armelor de razboi de la pozitia',
    'Parti si accesorii ale articolelor de la pozitiile Altele',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Cartuse',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Altele',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Pentru revolvere si pistoale de la pozitia sau pentru pistoale mitraliera de la pozitia',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Pentru arme de razboi',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Altele',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse De razboi',
    'Bombe grenade torpile mine rachete cartuse si alte munitii si proiectile si parti ale acestora inclusiv alice plumbi de vanatoare si fultuiala pentru cartuse Altele',
    'Sabii spade baionete lanci si alte arme albe partile si tecile acestora Sabii spade baionete lanci si alte arme albe partile si tecile acestora',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Scaune de tipul celor utilizate pentru vehicule aeriene',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Scaune de tipul celor utilizate pentru automobile',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Din lemn',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Din lemn',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Din bambus',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Din ratan',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Tapitate',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Tapitate',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Alte scaune',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor De scaune de tipul celor utilizate pentru aeronave',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor De scaune de tipul celor utilizate pentru aeronave',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor De scaune de tipul celor utilizate pentru automobile',
    'Scaune cu exceptia celor de la pozitia chiar transformabile in paturi si partile lor Altele',
    'Mobilier medical chirurgical stomatologic sau pentru medicina veterinara de exemplu mese de operatie mese de examinare paturi cu mecanism pentru utilizari clinice fotolii de stomatologie fotolii pentru saloanele de coafura si fotolii similare cu dispozitive de orientare si de ridicare in acelasi timp parti ale acestor articole Fotolii pentru stomatologie fotolii pentru saloanele de coafura si fotolii similare si partile lor',
    'Mobilier medical chirurgical stomatologic sau pentru medicina veterinara de exemplu mese de operatie mese de examinare paturi cu mecanism pentru utilizari clinice fotolii de stomatologie fotolii pentru saloanele de coafura si fotolii similare cu dispozitive de orientare si de ridicare in acelasi timp parti ale acestor articole Altele',
    'Alt mobilier si parti ale acestuia Birouri',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Dulapuri cu usi cu obloane sau cu usi basculante',
    'Alt mobilier si parti ale acestuia Dulapuri cu sertare clasoare si fisiere',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Paturi',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Birouri',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Dulapuri cu usi cu obloane sau cu usi basculante clasoare si fisiere si alte dulapuri',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Elemente de mobila de bucatarie',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Mobilier din lemn de tipul celui utilizat in dormitoare',
    'Alt mobilier si parti ale acestuia Mobilier din lemn de tipul celui utilizat in sufragerii si livinguri',
    'Alt mobilier si parti ale acestuia Mobilier din lemn de tipul celui utilizat in magazine',
    'Alt mobilier si parti ale acestuia Alt mobilier din lemn',
    'Alt mobilier si parti ale acestuia Mobilier din material plastic',
    'Alt mobilier si parti ale acestuia Din bambus',
    'Alt mobilier si parti ale acestuia Din ratan',
    'Alt mobilier si parti ale acestuia Altele',
    'Alt mobilier si parti ale acestuia Din lemn',
    'Alt mobilier si parti ale acestuia Din metal',
    'Alt mobilier si parti ale acestuia Din alte materiale',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Somiere',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Din cauciuc',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Din material plastic',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Cu arcuri metalice',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Altele',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Saci de dormit',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Umplute cu pene sau cu puf',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Altele',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Umpluti cu pene sau cu puf',
    'Somiere articole de pat si articole similare de exemplu saltele paturi plapumi perne cu arcuri sau umplute cu orice fel de materiale inclusiv cele din cauciuc alveolar sau material plastic alveolar acoperite sau nu Altele',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic sau din ceramica',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din sticla',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic sau din ceramica',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din sticla',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic sau din ceramica',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din sticla',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic sau din ceramica',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din sticla',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Concepute pentru utilizarea doar cu surse de lumina cu diode emitatoare de lumina LED',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Altele',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Proiectoare',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Altele',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Proiectoare',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Altele',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Proiectoare',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Aparate si corpuri de iluminat neelectrice',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din alte materiale',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Articole pentru aparatele electrice de iluminat cu exceptia proiectoarelor',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Altele',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Din material plastic',
    'Aparate si corpuri de iluminat inclusiv proiectoarele si partile lor nedenumite si necuprinse in alta parte lampi pentru reclame luminoase insemne luminoase placi indicatoare luminoase si articole similare care au o sursa de lumina permanenta fixa si parti ale acestora nedenumite si necuprinse in alta parte Altele',
    'Constructii prefabricate Din lemn',
    'Constructii prefabricate Unitati de constructie modulare din otel',
    'Constructii prefabricate Case transportabile',
    'Constructii prefabricate Sere',
    'Constructii prefabricate Altele',
    'Constructii prefabricate Din alte materiale',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Papusi',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Parti si accesorii',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Trenuri electrice inclusiv sinele semnalele si alte accesorii minimodele animate sau nu pentru asamblare',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din material plastic',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din alte materiale',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Umplute cu materiale de umplutura lana calti etc',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Altele',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Instrumente si aparate muzicale de jucarie',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din lemn',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Altele',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Alte jucarii prezentate in seturi sau serii complete',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din material plastic',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din alte materiale',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Arme de jucarie',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Modele in miniatura obtinute prin turnare din metal',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Dispozitive electronice interactive educative portabile concepute in principal pentru copii',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Din material plastic',
    'Triciclete trotinete automobile cu pedale si jucarii similare cu roti landouri si carucioare pentru papusi papusi alte jucarii minimodele si modele similare pentru divertisment animate sau nu jocuri de tip puzzle de orice fel Altele',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Articole si accesorii pentru jocuri de biliard',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Jocuri cu ecran',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Alte jocuri',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Parti',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Carti de joc',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Console si masini de jocuri video altele decat cele de la subpozitia',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Circuite electrice cu automobile avand caracteristicile jocurilor de competitie',
    'Console si masini de jocuri video articole pentru jocuri de societate inclusiv jocurile cu motor sau cu mecanisme de miscare jocuri de biliard mese speciale pentru jocuri de cazino si jocurile de popice automate jocuri care functioneaza prin introducerea unei monede a unei bancnote a unui card bancar a unei fise sau a altor instrumente de plata Altele',
    'Articole pentru sarbatori carnaval sau alte divertismente inclusiv articolele de magie si articolele surpriza Din sticla',
    'Articole pentru sarbatori carnaval sau alte divertismente inclusiv articolele de magie si articolele surpriza Din alte materiale',
    'Articole pentru sarbatori carnaval sau alte divertismente inclusiv articolele de magie si articolele surpriza Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Schiuri pentru schi fond',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Monoschiuri si snowboarduri',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Alte schiuri',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Legaturi pentru schiuri',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Planse cu vele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Crose complete',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Mingi',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Parti de crose',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Articole si echipamente pentru tenis de masa',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Rachete de tenis chiar fara coarde',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Mingi de tenis',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Gonflabile',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Mingi de crichet sau de polo',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Patine pentru gheata',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Patine cu rotile',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Parti si accesorii',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Aparate pentru exercitii fizice cu mecanisme de forta ajustabile',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Articole pentru crichet sau polo altele decat mingile',
    'Articole si echipament pentru cultura fizica gimnastica atletism alte sporturi inclusiv tenis de masa sau pentru jocuri in aer liber nedenumite si necuprinse in alta parte in acest capitol piscine si bazine Altele',
    'Undite de pescuit carlige de pescuit si alte articole pentru pescuit cu undita plase pentru prins peti plase pentru prins fluturi capcane pentru pasari altele decat cele de la pozitiile sau  si articole de vanatoare similare Undite de pescuit',
    'Undite de pescuit carlige de pescuit si alte articole pentru pescuit cu undita plase pentru prins peti plase pentru prins fluturi capcane pentru pasari altele decat cele de la pozitiile sau  si articole de vanatoare similare Carlige nemontate',
    'Undite de pescuit carlige de pescuit si alte articole pentru pescuit cu undita plase pentru prins peti plase pentru prins fluturi capcane pentru pasari altele decat cele de la pozitiile sau  si articole de vanatoare similare Altele',
    'Undite de pescuit carlige de pescuit si alte articole pentru pescuit cu undita plase pentru prins peti plase pentru prins fluturi capcane pentru pasari altele decat cele de la pozitiile sau  si articole de vanatoare similare Mulinete pentru pescuit',
    'Undite de pescuit carlige de pescuit si alte articole pentru pescuit cu undita plase pentru prins peti plase pentru prins fluturi capcane pentru pasari altele decat cele de la pozitiile sau  si articole de vanatoare similare Altele',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Circuri ambulante si menajerii ambulante',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Carusele',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Carusele balansoare si manejuri',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Masinute tamponabile',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Simulatoare de miscare si sali de cinematograf cu scaune dinamice',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Tobogane acvatice',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Atractii pentru parcurile acvatice',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Altele',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Atractii pentru balciuri',
    'Circuri ambulante si menajerii ambulante manejuri pentru parcuri de distractii si atractii pentru parcurile acvatice atractii pentru balciuri inclusiv standuri de tir teatre ambulante Teatre ambulante',
    'Fildes os carapace de broasca testoasa coarne de animale corali sidef si alte materiale de origine animala pentru taiat prelucrate si articole din aceste materiale inclusiv produsele obtinute prin turnare Fildes prelucrat si obiecte de fildes',
    'Fildes os carapace de broasca testoasa coarne de animale corali sidef si alte materiale de origine animala pentru taiat prelucrate si articole din aceste materiale inclusiv produsele obtinute prin turnare Altele',
    'Materiale vegetale sau minerale prelucrate pentru fasonat si articole din aceste materiale articole modelate sau fasonate din ceara parafina stearina guma sau rasini naturale sau din paste pentru mulaje si alte articole modelate sau fasonate nedenumite si necuprinse in alta parte gelatina neintarita prelucrata cu exceptia celei de la pozitia si articole din gelatina neintarita Materiale vegetale sau minerale prelucrate pentru fasonat si articole din aceste materiale articole modelate sau fasonate din ceara parafina stearina guma sau rasini naturale sau din paste pentru mulaje si alte articole modelate sau fasonate nedenumite si necuprinse in alta parte gelatina neintarita prelucrata cu exceptia celei de la pozitia si articole din gelatina neintarita',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Maturi si perii din nuiele sau alte materiale vegetale legate in manunchi cu manere sau fara manere',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Perii de dinti inclusiv periile pentru proteze dentare',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Perii pentru par',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Altele',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Pensule si perii pentru artisti pensule pentru scris',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Pensule pentru aplicarea produselor cosmetice',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Perii si pensule pentru pictat sau zugravit pentru ungere pentru lacuit sau similare',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Tampoane si rulouri pentru zugravit',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Alte perii care constituie parti de masini de aparate sau de vehicule',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Maturi mecanice actionate manual altele decat cele cu motor',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Perii si periimatura pentru intretinerea suprafetelor sau pentru menaj inclusiv periile de haine sau de incaltaminte articole de periat pentru toaleta animalelor',
    'Pensule si perii inclusiv cele care constituie parti de masini de aparate sau de vehicule maturi mecanice pentru folosire manuala altele decat cele cu motor pamatufuri fire si pamatufuri pregatite pentru confecionarea periilor tampoane si rulouri pentru vopsit raclete din cauciuc sau din alte materiale elastice similare Altele',
    'Site si ciururi utilizate manual Site si ciururi utilizate manual',
    'Seturi personale de toaleta de voiaj de croitorie pentru curatirea incaltamintei sau a hainelor Seturi personale de toaleta de voiaj de croitorie pentru curatirea incaltamintei sau a hainelor',
    'Nasturi butoni si capse forme pentru nasturi si alte parti ale acestora ebose de nasturi Capse si butoni folositi prin presare si partile lor',
    'Nasturi butoni si capse forme pentru nasturi si alte parti ale acestora ebose de nasturi Din material plastic neacoperiti cu material textil',
    'Nasturi butoni si capse forme pentru nasturi si alte parti ale acestora ebose de nasturi Din metale comune neacoperiti cu material textil',
    'Nasturi butoni si capse forme pentru nasturi si alte parti ale acestora ebose de nasturi Altele',
    'Nasturi butoni si capse forme pentru nasturi si alte parti ale acestora ebose de nasturi Forme pentru nasturi si alte parti de nasturi ebose de nasturi',
    'Fermoare si partile lor Cu agrafe din metale comune',
    'Fermoare si partile lor Altele',
    'Fermoare si partile lor Din metale comune inclusiv benzile prevazute cu agrafe din metale comune',
    'Fermoare si partile lor Altele',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Cu cerneala lichida',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Cu rezerva care poate fi inlocuita',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Altele',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Stilouri si marcatoare cu varful din fetru sau din alte materiale poroase',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Stilouri cu penita si alte stilouri',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Creioane mecanice',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Seturi de articole formate din doua sau mai multe articole de la subpozitiile precedente',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Cartuse de schimb rezerve pentru stilouri sau pixuri cu bila asociate cu varful lor',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Penite de scris si varfuri pentru penite',
    'Stilouri si pixuri cu bila stilouri si marcatoare cu varf de fetru sau alte varfuri poroase stilouri cu penita si alte stilouri tragatoare pentru desen creioane mecanice tocuri pentru penite prelungitoare pentru creioane si articole similare parti inclusiv capace si agatatori ale acestor articole cu exceptia celor de la pozitia Altele',
    'Creioane altele decat creioanele de la pozitia mine creioane colorate carbune pentru desen creta de scris sau de desenat si creta pentru croitorie Cu mina de grafit',
    'Creioane altele decat creioanele de la pozitia mine creioane colorate carbune pentru desen creta de scris sau de desenat si creta pentru croitorie Altele',
    'Creioane altele decat creioanele de la pozitia mine creioane colorate carbune pentru desen creta de scris sau de desenat si creta pentru croitorie Mine pentru creioane sau creioane mecanice',
    'Creioane altele decat creioanele de la pozitia mine creioane colorate carbune pentru desen creta de scris sau de desenat si creta pentru croitorie Creioane colorate si carbuni pentru desen',
    'Creioane altele decat creioanele de la pozitia mine creioane colorate carbune pentru desen creta de scris sau de desenat si creta pentru croitorie Altele',
    'Placi de ardezie si table pentru scris sau desen chiar cu rama Placi de ardezie si table pentru scris sau desen chiar cu rama',
    'Stampile manuale de datare sigilare numerotare timbrare si articole similare inclusiv aparate pentru imprimarea etichetelor matrite tipografice si imprimatoare tipografice cu matrita manuale Stampile manuale de datare sigilare numerotare timbrare si articole similare inclusiv aparate pentru imprimarea etichetelor matrite tipografice si imprimatoare tipografice cu matrita manuale',
    'Panglici pentru masini de scris si panglici similare impregnate sau altfel pregatite pentru a lasa amprente chiar montate pe bobine sau in carcase tusiere chiar impregnate cu sau fara cutie Din material plastic',
    'Panglici pentru masini de scris si panglici similare impregnate sau altfel pregatite pentru a lasa amprente chiar montate pe bobine sau in carcase tusiere chiar impregnate cu sau fara cutie Din fibre sintetice sau artificiale cu o latime sub mm introduse in carcase de plastic sau metal de tipul celor folosite la masini de scris automate echipamente de prelucrare automata a datelor si alte masini',
    'Panglici pentru masini de scris si panglici similare impregnate sau altfel pregatite pentru a lasa amprente chiar montate pe bobine sau in carcase tusiere chiar impregnate cu sau fara cutie Altele',
    'Panglici pentru masini de scris si panglici similare impregnate sau altfel pregatite pentru a lasa amprente chiar montate pe bobine sau in carcase tusiere chiar impregnate cu sau fara cutie Tusiere',
    'Brichete si alte aprinzatoare chiar mecanice sau electrice si partile lor altele decat pietrele si fitilele Brichete de buzunar cu gaz nereincarcabile',
    'Brichete si alte aprinzatoare chiar mecanice sau electrice si partile lor altele decat pietrele si fitilele Brichete de buzunar cu gaz reincarcabile',
    'Brichete si alte aprinzatoare chiar mecanice sau electrice si partile lor altele decat pietrele si fitilele Alte brichete si aprinzatoare',
    'Brichete si alte aprinzatoare chiar mecanice sau electrice si partile lor altele decat pietrele si fitilele Parti',
    'Pipe inclusiv capete de pipe porttigari porttigarete si parti ale acestora Ebose de pipe din lemn sau radacini',
    'Pipe inclusiv capete de pipe porttigari porttigarete si parti ale acestora Altele',
    'Piepteni de coafat sau de prins in par agrafe si articole similare ace de par clame de par cleme pentru onduleuri bigudiuri si articole similare pentru coafura altele decat cele de la pozitia si parti ale acestora Din cauciuc durificat sau din material plastic',
    'Piepteni de coafat sau de prins in par agrafe si articole similare ace de par clame de par cleme pentru onduleuri bigudiuri si articole similare pentru coafura altele decat cele de la pozitia si parti ale acestora Altele',
    'Piepteni de coafat sau de prins in par agrafe si articole similare ace de par clame de par cleme pentru onduleuri bigudiuri si articole similare pentru coafura altele decat cele de la pozitia si parti ale acestora Altele',
    'Pulverizatoare de toaleta monturile lor si capetele de monturi pufuri pentru pudra sau pentru aplicarea altor produse cosmetice sau de toaleta Pulverizatoare de toaleta',
    'Pulverizatoare de toaleta monturile lor si capetele de monturi pufuri pentru pudra sau pentru aplicarea altor produse cosmetice sau de toaleta Monturi si capete de monturi pentru pulverizatoare de toaleta',
    'Pulverizatoare de toaleta monturile lor si capetele de monturi pufuri pentru pudra sau pentru aplicarea altor produse cosmetice sau de toaleta Pufuri pentru pudra sau pentru aplicarea unor produse cosmetice sau de toaleta',
    'Termosuri si alte recipiente izoterme complete parti ale acestora altele decat recipientul de sticla din interior Termosuri si alte recipiente izoterme complete parti ale acestora altele decat recipientul de sticla din interior',
    'Manechine si articole similare automate si scene animate pentru decorarea vitrinelor Manechine si articole similare automate si scene animate pentru decorarea vitrinelor',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Din vata din materiale textile',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Tampoane igienice tampoane interne si articole similare',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Servetele scutece si articole similare',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Tampoane igienice',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Tampoane interne',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Altele',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Servetele si scutece pentru copii',
    'Tampoane igienice si tampoane interne servetele igienice scutece si articole similare din orice material Altele de exemplu articole pentru incontinenta',
    'Monopiede bipiede trepiede si articole similare De tipul celor utilizate pentru aparatele digitale fotografice sau video aparatele si proiectoarele cinematografice de tipul celor utilizate pentru alte aparate de la capitolul',
    'Monopiede bipiede trepiede si articole similare Din material plastic sau aluminiu',
    'Monopiede bipiede trepiede si articole similare Altele',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Tablouri picturi si desene',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Mozaicuri',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Altele',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Tablouri picturi si desene',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Mozaicuri',
    'Tablouri picturi si desene executate in intregime manual cu exceptia desenelor de la pozitia si a altor articole industriale pictate sau decorate manual colaje mozaicuri si mici tablouri decorative similare Altele',
    'Gravuri stampe si litografii originale Mai vechi de  de ani',
    'Gravuri stampe si litografii originale Altele',
    'Productii originale de arta statuara sau de sculptura din orice material Mai vechi de  de ani',
    'Productii originale de arta statuara sau de sculptura din orice material Altele',
    'Timbre postale timbre fiscale marci postale plicuri prima zi serii postale complete si similare obliterate sau neobliterate altele decat articolele de la pozitia Timbre postale timbre fiscale marci postale plicuri prima zi serii postale complete si similare obliterate sau neobliterate altele decat articolele de la pozitia',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Colectii si piese de colectie care prezinta un interes arheologic etnografic sau istoric',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Specimene umane si parti ale acestora',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Specii disparute sau aflate pe cale de disparitie si parti ale acestora',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Altele',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Mai vechi de  de ani',
    'Colectii si specimene pentru colectii care prezinta un interes arheologic etnografic istoric zoologic botanic mineralogic anatomic paleontologic sau numismatic Altele',
    'Obiecte de antichitate mai vechi de  de ani Mai vechi de  de ani',
    'Obiecte de antichitate mai vechi de  de ani Altele',
    'Livrari de marfuri destinate aprovizionarii navelor si aeronavelor Marfuri de la capitolele  din NC pentru livrari aferente aprovionarii navelor si aeronavelor',
    'Livrari de marfuri destinate aprovizionarii navelor si aeronavelor Marfuri de la capitolul  din NC pentru livrari aferente aprovionarii navelor si aeronavelor',
    'Livrari de marfuri destinate aprovizionarii navelor si aeronavelor Marfuri clasificate in alta parte pentru livrari aferente aprovionarii navelor si aeronavelor',
    'Marfuri destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine Marfuri de la capitolele  din NC pentru livrarile destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine',
    'Marfuri destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine Marfuri de la capitolul  din NC pentru livrarile destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine',
    'Marfuri destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine Marfuri clasificate in alta parte pentru livrarile destinate echipajului care asigura exploatarea instalatiei marine sau functionarii motoarelor a masinilor si a altor aparate ale instalatiei marine',
    
];