///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {

    const getYear = () => {
        const year = new Date().getFullYear();
        return year;
    }

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div className='footer-wrapper'>
    <div className='footer'>
        <ul className='list-block'>
            <Link to={'/'} className='link'>Meniu</Link>
        </ul>
        <ul className='list-block'>
            <Link className='link' to={'/termeni-si-conditii'} onClick={scrollToTop}>Termeni și condiții</Link>
            <Link className='link' to={'/politica-de-confidentialitate'} onClick={scrollToTop}>Politica de confidențialitate</Link>
            <Link className='link' to={'/info'} onClick={scrollToTop}>Informații</Link>
        </ul>
        <ul className='list-block'>
            <Link className='link' to={'/contact'} onClick={scrollToTop}>Contact</Link>
            <Link className='link' to={'/docs'} onClick={scrollToTop}>Documentație</Link>
        </ul>
    </div>
    <p className='copyright'>© Copyright {getYear()} ASQUARED SRL. Toate drepturile rezervate.</p>
    </div>
  )
}

export default Footer