///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useRef, useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FixedSizeList as List } from "react-window";

import { countryCodes, countryNames, EuCountries } from '../../databases/CountryCodes';
import { createNotification } from '../../services/NotificationService';
import { DeliveryConditions } from '../../databases/DeliveryCondition';
import { TransactionNatures } from '../../databases/TransactionNature';
import { CoduriVamale, Definitions } from '../../databases/CodVamal'
import { TransportModes } from '../../databases/TransportMode';
import { CurrencyCode } from '../../databases/Currencies';


const returnTableValues = ({data, index, style}) => {
    const {selectValue, items} = data
    const cod = items[0][index]
    const description = items[1][index]
    return(
        <div className='modal-element' style={style} onClick={() => selectValue(cod)} key={`${cod} - ${index}`} id={`${cod}`}>
            <div>{cod}</div>
            <div>{description}</div>
        </div>
    )
}

const ModalSetValue = ({values, close, setValue}) => {
    const [loadSearch, setLoadSearch] = useState(false);
    const [noDescription, setNoDescription] = useState(false);
    const [queryCode, setQueryCode] = useState('');
    const [currentArray, setCurrentArray] = useState([]);
    const [textArray, settextArray] = useState([]);
    const [queryDescr, setQueryDescr] = useState('');
    const [items, setItems] = useState([])
    const [itemCount, setItemCount] = useState(0)
    const listRef = useRef();

    const setData = () => {
        const type = values.type;
        let currentArrayNow = [];
        let textArrayNow = [];

        // non-required
        // Currency          [-1]
        if ( type === -1 ) {
            currentArrayNow = new Array(...CurrencyCode);
            textArrayNow = new Array(currentArrayNow.length).fill('');
            setNoDescription(true);
        }
        // required
        // Cod Vamal          [00]
        if ( type === 0 ) {
            currentArrayNow = Array.from(CoduriVamale);
            textArrayNow = new Array(...Definitions);
        }
        // Conditie Livrare   [03]
        else if ( type === 3 ) {
            currentArrayNow = DeliveryConditions.map(val => val.code);
            textArrayNow = DeliveryConditions.map(val => val.value);
        }
        // Tara Origine       [04]
        else if ( type === 4 ) {
            currentArrayNow = new Array(...countryCodes);
            textArrayNow = new Array(...countryNames);
        }
        // Tara Expediere     [05]
        else if ( type === 5 ) {
            currentArrayNow = EuCountries.Item.map(val => val.Code);
            textArrayNow = EuCountries.Item.map(val => val.NameRo);
        }
        // Natura Tranzactiei [07]
        else if ( type === 7 ) {
            currentArrayNow = TransactionNatures.map(val => `${val.categ}.${val.code}`);
            textArrayNow = TransactionNatures.map(val => val.value);
        }
        // Mod Transport      [08]
        else if ( type === 8 ) {
            currentArrayNow = TransportModes.map(val => val.code);
            textArrayNow = TransportModes.map(val => val.value);
        }
        else {
            textArrayNow = new Array(currentArrayNow.length).fill('');
        }
        setCurrentArray(currentArrayNow)
        settextArray(textArrayNow)
        setItems([currentArrayNow, textArrayNow])
        setItemCount(currentArrayNow.length)
    }

    const filterData = () => {
        if(currentArray.length === 0) { 
            return 
        }
        if(queryCode === '' && queryDescr === '') {
            setItemCount(currentArray.length)
            return setItems([currentArray, textArray])
        }
        const arrCurrent = currentArray;
        const arrText = textArray;

        let finalArray = [] 
        let definitionsArray = queryCode !== '' ? [] : arrText

        finalArray = queryCode !== '' ? arrCurrent.filter((item, index) => {
            if(item.toString().toUpperCase().includes(queryCode.toString().toUpperCase())) {
                definitionsArray.push(arrText[index])
                return true
            }
            return false
        }) : arrCurrent

        let finalArrayHelper = queryDescr === '' ? finalArray : []

        const finalDefinitions = queryDescr !== '' ? definitionsArray.filter((item, index) => {
            if(item.toString().toUpperCase().includes(queryDescr.toString().toUpperCase())) {
                finalArrayHelper.push(finalArray[index])
                return true
            }
            else 
                return false
        }) : definitionsArray

        setItems([finalArrayHelper, finalDefinitions])
        finalArrayHelper.length > 0 && setItemCount(finalArrayHelper.length)
    }

    // on start
    useEffect(() => {
        setData()
        setLoadSearch(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setLoadSearch(true)
            filterData()
            setLoadSearch(false)
        }, 400)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryCode, queryDescr])

    const selectValue = (cod) => {
        if(!cod || cod === '') return false
        if(setValue === false) {
            navigator.clipboard.writeText(cod)
            createNotification('success', `Codul ${cod} a fost copiat în clipboard`)
        }
        else
            setValue(cod, values.bigIndex, values.smallIndex, values.type);
        close();
    }

    const handleChange = (type, value) => {
        value !== '' && setLoadSearch(true)
        if (type === 0) {
            return setQueryCode(value)
        }
        else {
            return setQueryDescr(value)
        }
    }

    return (
        <div className='modal-wrapper'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <div>Valori valide</div>
                    <div className='button-secondary' onClick={() => close()}>Închide</div>
                </div>

                <div className='modal-top'>
                    <div>Caută</div>
                    <div>
                        <input type='text' 
                        placeholder='Cod' id='search-code' defaultValue={''}
                        onChange={(e) => handleChange(0, e.target.value)} className='input-text' />
                        <input type='text' 
                        placeholder='Denumire' id='search-name' defaultValue={''} disabled={noDescription}
                        onChange={(e) => handleChange(1, e.target.value)} className='input-text' />
                    </div>
                </div>
                <div className='modal-body-wrapper'>
                    <div className='modal-body'>
                        {itemCount !== 0 && !loadSearch ?
                        <List ref={listRef}
                            height={400}
                            width={1000}
                            itemSize={150}
                            itemCount={itemCount}
                            itemData={{selectValue, items}}
                        >
                            {returnTableValues}
                        </List> :
                        <FontAwesomeIcon className='spinner' icon={faSpinner}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSetValue