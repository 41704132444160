///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const TransactionNatures = [
    {
        categ: 1,
        code: 1,
        value: 'Achiziţie / vânzare definitivă, exceptând comerțul direct cu/realizat de către consumatorii privați'
    },
    {
        categ: 1,
        code: 2,
        value: 'Comerț direct cu/realizat de către consumatorii privați (inclusiv vânzarea la distanță)'
    },
    {
        categ: 2,
        code: 1,
        value: 'Comerț direct cu/realizat de către consumatorii privați (inclusiv vânzarea la distanță)'
    },
    {
        categ: 2,
        code: 2,
        value: 'Înlocuiri de bunuri returnate'
    },
    {
        categ: 2,
        code: 3,
        value: 'Înlocuiri - de exemplu, sub garanţie - de bunuri care nu au fost returnate'
    },
    {
        categ: 3,
        code: 1,
        value: 'Mișcări către/de la un antrepozit (excluzând stocurile la dispoziția clientului și stocurile în regim de consignație)'
    },
    {
        categ: 3,
        code: 2,
        value: 'Furnizarea pentru vânzare la vedere sau cu testare (inclusiv stocurile la dispoziția clientului și stocurile în regim de consignație)'
    },
    {
        categ: 3,
        code: 3,
        value: 'Leasing financiar'
    },
    {
        categ: 3,
        code: 4,
        value: 'Tranzacții care implică transferul proprietății fără compensații financiare'
    },
    {
        categ: 4,
        code: 1,
        value: 'Bunuri destinate reintroducerii în statul membru/țara de export inițial(ă)'
    },
    {
        categ: 4,
        code: 2,
        value: 'Bunuri care nu sunt destinate reintroducerii în statul membru/țara de export inițial(ă)'
    },
    {
        categ: 4,
        code: 3,
        value: 'Bunuri destinate prelucrării, considerate din punct de vedere fiscal drept achiziţii/livrari intracomunitare asimilate'
    },
    {
        categ: 5,
        code: 1,
        value: 'Bunuri reintroduse în statul membru/țara de export inițial(ă)'
    },
    {
        categ: 5,
        code: 2,
        value: 'Bunuri care nu sunt reintroduse în statul membru/țara de export inițial(ă)'
    },
    {
        categ: 5,
        code: 3,
        value: 'Bunuri obţinute în urma prelucrării produselor anterior declarate cu codul de natura tranzacţiei 4.3'
    },
    {
        categ: 7,
        code: 1,
        value: 'Punerea bunurilor în liberă circulație într-un stat membru în vederea unui export ulterior către un alt stat membru'
    },
    {
        categ: 7,
        code: 2,
        value: 'Transportul bunurilor dintr-un stat membru în alt stat membru pentru plasarea bunurilor în regim de export'
    },
    {
        categ: 9,
        code: 1,
        value: 'Închirieri, împrumuturi, leasing operaţional cu durata de peste 24 de luni'
    },
    {
        categ: 9,
        code: 2,
        value: 'Altele'
    },
]