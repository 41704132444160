///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React from 'react'

const TermsConditions = () => {
  return (
    <div className='terms-wrapper'>
        <h1>Termeni și condiții</h1>
        <div className='terms-text'>
            <div>
            <div>
                <h1>1. Parțile Contractante</h1>
                <div>ASQUARED S.R.L., cu sediul social în Brașov, Strada Hărmanului, Nr 15, camera 1, Bl 30BIS, Ap 10, jud Brașov, înregistrata la
                    Oficiul
                    Registrului Comertului sub nr. J2024032303003 CUI RO 14578650, atribut fiscal RO, având contul nr.
                    RO80BTRLRONCRT0689772201, deschis la Banca Transilvania. <br />Clientul, utilizatorul platformei programintrastat.ro și a tuturor serviciilor oferite prin intermediul acesteia
                    au
                    agreat urmatoarele:</div>
                <div>Acesta reprezintă contractul propus de Prestator și trebuie acceptat înainte de comandarea oricarui
                    serviciu oferit de Prestator. Orice comandă confirmata de Client, prin bifarea căsuței "Sunt de acord cu
                    termenii si condițiile" reprezintă o acceptare din partea Clientului a ofertei Presatorului, în
                    condițiile stipulate de art. 9 din Legea comertului electronic 365/2002. Bifarea căsuței reprezintă o
                    semnatură electronică, în sensul art. 4 pct. 3 din Legea semnăturii electronice nr. 455/2001, având
                    aceeași valoare cu o semnatură olografa.</div>
                <h4><b>Definiții:</b></h4>
                <div>programintrastat.ro: site-ul de internet, precum și ansamblul serviciilor accesibile pe acest
                    site web sau pe subdomeniile acestuia.</div>
                <div><b>Serviciu</b>: serviciile puse la dispoziție de Prestator prin intermediul site-ului programintrastat.ro, sub forma unui abonament.</div>
                <div><b>Client</b>: persoana juridică care face o comandă pe site-ul programintrastat.ro sau se
                    înregistreaza ca utilizator.</div>
                <div><b>Comanda</b>: comandă de achizitionare a unuia sau mai multor servicii afișate pe site-ul programintrastat.ro, realizata de un client care își dorește să beneficieze de
                    acel/acele
                    servicii.</div>
                <div><b>Vizitator</b>: persoana fizică ce viziteaza website-ul www.programintrastat.ro, fară a fi achizitionat
                    servicii și fară să fi facut o Comanda.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>2. Obiectul și acceptarea prezentului Contract de prestări servicii</h1>
                <div>2.1. Prezentul Contract de prestări servicii este aplicabil tuturor comenzilor sau conturilor realizate
                    prin intermediul programintrastat.ro. Clientul se obligă să ia la cunoștinta de aceste
                    condiții înainte de a realiza o comandă sau de a-și deschide un cont. Toate comenzile și deschiderea
                    contului implica acceptarea de către Client a tuturor acestor condiții de prestări servicii. Acceptarea
                    prezentului contract de către client se face prin bifarea căsuței „Sunt de acord cu termenii și
                    condițiile”.</div>
                <div>2.2. Prestatorul își rezervă dreptul de a modifica prezentele condiții de prestări servicii în orice
                    moment. Versiunea aplicabila unei comenzi realizate este aceea în vigoare pe programintrastat.ro la momentul la care s-a plasat comandă de către client.</div>
                <div>2.4. Serviciile pot fi accesate de pe o serie de interfete precum: aplicatii desktop ce se instaleaza pe
                    sisteme de operare Windows, Mac și Linux, aplicatii iOS și Android și web browser.</div>
                <div>2.5. Prin încheierea contractului, Clientul întelege și confirma faptul că alti utilizatori (firme) cu
                    care Clientul a tranzacționat în platforma programintrastat.ro vor fi conștienți de faptul că firma Clientului
                    utilizeaza serviciul programintrastat.ro.
                </div>
                <div>2.6. Prezentul contract de prestări servicii este însoțit și trebuie interpretat împreuna cu Politica de
                    confidentialitate gasita la adresa
                    programintrastat.ro
                </div>
            </div>
        </div>

        <div>
            <div>
                <h1>3. Durata contractului</h1>
                <div>3.1. Prezentul contract se încheie pe o perioada nedeterminata începând de la data plasarii comenzii sau
                    data crearii contului, iar obligațiile parților intră în vigoare de la aceasta data.</div>
                <div>3.2. Imediat ce se efectueaza plata tipului de abonament ales, Clientul va avea acces la serviciile
                    incluse în pachetul abonamentului din momentul confirmarii platii de către Prestator. Anularea comenzii
                    se poate efectua pâna în momentul efectuarii platii. Orice erori survenite la comandă facuta de Client
                    pot fi corectate pâna în momentul confirmarii platii de către Prestator printr-un email trimis la
                    programintrastat@gmail.ro.</div>

                <div>3.3. În cazul în care Clientul dorește modificarea tipului de abonament ales pe parcursul perioadei în
                    care este activ un abonament anterior ales, acest lucru este posibil – schimbarea intrând în vigoare în
                    termen de 5 zile lucratoare de la efectuarea plații abonamentului nou.</div>
                <div>3.4. Prestatorul ofera posibilitatea de a testa gratuit serviciile programintrastat.ro pe o <b>perioada de 3
                    luni</b>. Dupa expirarea acestei perioade, Clientul poate să opteze pentru un abonament. În cazul în
                    care nu mai dorește continuarea serviciilor, accesul va fi întrerupt definitiv.</div>
                <div>3.6 Contul Clientului va fi sters automat dupa 2 ani de la expirarea abonamentului sau în cazul în care
                    Clientul nu a continuat cu un abonament platit, dupa 2 ani de la expirarea perioadei de proba.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>4. Valoarea contractului și efectuarea plații</h1>
                <div>4.1. Pentru serviciile prestate, Clientul va achita Prestatorului contravaloarea abonamentului, prevazuta
                    în momentul finalizarii comenzii.</div>
                <div>4.2. Prestatorul va trimite notificari de plata regulate în conformitate cu tipul de abonament ales.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>5. Descarcarea, instalarea si utilizarea programului / aplicatiei programintrastat.ro</h1>
                <div>5.1 Aplicația programintrastat.ro este special dezvoltată că o alternativă (la aplicatia oferită gratuit de către
                    autoritățile române) care să vină în ajutorul agenților economici care doresc să fie eficienți și rapizi
                    în cazul creeri declarațiilor Intrastat. Pricipala funcționalitate este importul datelor din fișiere
                    Excel și generarea declarație intrăstat (format xml).</div>

                <div>5.2 Aplicatie programintrastat.ro NU are nici un fel de legatura cu Institutul National de Statistica sau cu alte
                    autoritati ale staului roman. Utilizarea programului programintrastat.ro NU este obligatorie. Programul
                    programintrastat.ro este o alternativa la programul oferit de INS</div>

                <div>5.3 Prin documentul de față, Termeni si condiții de utilizare , precum si prin Acordul de licența
                    programintrastat.ro, care se gaseste în kitul de instalare al programului, Furnizorul va acorda Beneficiarului
                    si
                    numai acestuia licența ne-exclusiva pentru utilizarea produsului programintrastat.ro sau a altor bunuri si
                    servicii, si numai în urma plații si a comenzii pentru produsele oferite contra-cost.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>6. Disponibilitatea serviciilor afișate</h1>
                <div>6.1 Oferta Prestatorului cu privire la servicii și prețul acestora este valabila atâta vreme cât este
                    afișata pe programintrastat.ro. Prestatorul își va îndeplini obligațiile contractuale
                    imediat ce clientul va efectua plata aferenta serviciului selectat.</div>
                <div>6.2. Orice modificari ale tarifelor sau cu privire la disponibilitatea serviciilor vor fi comunicate prin
                    email la adresa de contact a clientului din contul de utilizator sau prin intermediul aplicatiei cu cel
                    putin 3 zile înaintea oricarei modificari. Serviciile de mentenanta ale aplicatiei sunt anuntate
                    prin intermediul aplicatiei sau prin email.</div>
                <div>6.3. Clientul este responsabil cu actualizarea datelor din contul de utilizator imediat ce intervin
                    schimbari. Prestatorul nu își asuma responsabilitatea în cazul în care intervin prejudicii sau pagube de
                    orice natura din cauza imposibilitatii de a accesa adresa de e-mail declarata în contul de utilizator.
                </div>
                <div>6.4. Accesul la serviciile programintrastat.ro este posibil pâna la renuntarea la serviciu și/sau ștergerea
                    contului de Client, indiferent de progresul tehnologic sau modificari, cu exceptia cazului în care
                    Clientul nu își mai platește abonamentul. Acest acces cuprinde și actualizari legislative pentru
                    serviciile disponibile, cât și asistenta pe întreaga perioada de desfașurare a prezentului contract.</div>
                <div>6.5. Prestatorul depune toate eforturile pentru a oferi permanent accesul la serviciile oferite, dar nu
                    poate garanta disponibilitatea serviciului decât în masura de 95%. Perioada de disponibilitate nu
                    include perioadele de mentenanta anuntate în prealabil prin email sau în cadrul serviciului, însa
                    include acele perioade de mentenanta neanuntate. Ca atare, având în vedere dependenta serviciilor
                    oferite de accesul la Internet, posibilele limitari tehnice și de sistem ce pot aparea, Prestatorul își
                    asuma o obligatie de diligenta în ceea ce privește disponibilitatea serviciilor conform procentului de
                    mai sus.</div>
                <div>6.6. În condițiile în care serviciul dorit nu mai este disponibil, din motive imputabile Prestatorului,
                    Clientul își poate exporta documentele si inregistraile în format tabelar. În
                    condițiile în care serviciul dorit nu mai este disponibil, din motive imputabile Clientului (neplata,
                    încalcarea acestui contract etc.), Prestatorul se va stradui, fară a garanta însa, să ofere Clientului
                    acces pentru a își exporta documentele / datele în format tabelar.
                    Prestatorul nu își poate asuma vreo obligatie legata de modul cum aceste date pot fi încarcate în alte
                    sisteme informatice.</div>

            </div>
        </div>

        <div>
            <div>
                <h1>7. Drepturile și obligațiile parților</h1>
                <h4><b>7.1. Drepturile și obligațiile Clientului</b></h4>
                <div>7.1.1. Clientul este unicul responsabil pentru întreg continutul existent în contul sau sau pentru orice
                    încalcare a legii sau a drepturilor unei terte persoane.</div>
                <div>7.1.2. Clientul se obliga să foloseasca serviciile oferite de Prestator în deplina legalitate și
                    cunoscând dispozitiile legale în vigoare cu privire la activitatea pe care o desfașoara.</div>
                <div>7.1.3. Clientul se obliga să respecte în totalitate prevederile legate de drepturile de autor și protectia datelor
                    cu caracter personal în ceea ce privește datele introduse în sistem.</div>
                <div>7.1.4. Clientul își asuma întreaga raspundere pentru crearea, personalizarea, administrarea, gestionarea,
                    acuratetea și legalitatea documentelor emise si a fisierelor trimise cu ajutorul serviciilor
                    oferite.</div>
                <div>7.1.5. Clientul va procesa datele cu caracter personal conform prevederilor legale în vigoare și are
                    obligatia de a respecta legislatia în acest domeniu, iar Prestatorul nu poate fi facut responsabil
                    pentru niciun fel prejudicii sau încalcari ale drepturilor tertelor persoane.</div>
                {/* <div>7.1.6. Are dreptul de a adauga în sistemul informatic mai multi utilizatori ai contului cu diverse
                    drepturi de acces – în totalitate sau în parte – în contul sau. De asemenea poate delega atributiile
                    sale unuia sau mai multor utilizatori.</div> */}
                <div>7.1.6. Clientul întelege și este de acord cu Termenii și Conditiile. </div>
                <h4><b>7.2. Drepturile și obligațiile Prestatorului </b></h4>
                <div>7.2.1. Prestatorul asigura:</div>
                <ul>
                    <li>posibilitatea de a importa fisiere Excel si generarea
                        declaratiilor INSTRASTAT.
                        Exista și posibilitatea testarii gratuite a serviciilor pe o perioada de 6 luni. Dupa expirarea
                        acestei perioade serviciul devine inactiv.
                    </li>
                    <li>conectarea la sistem printr-un protocol sigur de comunicare (https)</li>
                    <li>sincronizarea datelor din contul de utilizator în așa fel încât să fie disponibile de pe orice tip
                        de dispozitiv, de oriunde, prin accesul la Internet.
                    </li>
                </ul>
                <div>7.2.2. programintrastat.ro actioneaza ca un gazduitor pentru continutul adaugat de Client,
                    în condițiile Legii 365/2002 privind comertul electronic. În același timp, o parte limitata de date sunt
                    stocate și pe calculatoarele Clientului. În schimb, datele nu sunt pastrate în browser sau pe mobil.
                    Prestatorul nu își asuma responsabilitatea pentru pierderea parolelor de acces la contul de utilizator
                    sau pentru activitati ce pot compromite contul Clientului. În cazul în care Prestatorul primește o
                    notificare cu privire la existenta unor servicii cu o aparenta nelegala realizata de către Client, își
                    rezerva dreptul de a suspenda contul de utilizator sau bloca accesul la acesta.</div>
                {/* <div>7.2.3. Fiecare Client va avea un sistem de utilizatori carora li se vor putea configura un anumit set de
                    drepturi. Clientul poarta întreaga responsabilitate în cazul în care utilizatorii aprobati de către
                    Client încalca dispozitiile documentelor precizate la punctul 2.6 al prezentului contract sau se
                    angajeaza în activitati ilegale sau imorale.</div> */}

                <div>7.2.3. Prestatorul nu monitorizeaza și nu exercita nici un control asupra datelor și documentelor
                    Clientului.</div>
                <div>7.2.4. Prestatorul ofera un drept de utilizare (licenta) neexclusiva și nelimitata în timp și spatiu pe
                    perioada abonamentului, pentru folosirea serviciilor oferite.</div>
                <div>7.2.5 Prestatorul are dreptul de a colecta date anonime despre modul de utilizare a serviciilor sale si
                    de a le face publice în mod agregat.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>8. Înregistrare, parole și responsabilitati</h1>
                <div>8.1. Înregistrarea unui cont se face în mod gratuit și permite testarea serviciilor pentru 6 luni.
                    Prestatorul recomandă utilizatorilor inregistrati să nu dezvaluie nimanui parolele de acces.
                </div>
                <div>8.2. Din pacate, nici o transmisie de date prin intermediul internetului nu poate fi garantata a fi 100%
                    sigura. În consecinta, în ciuda eforturilor noastre de a va proteja informatia și de a folosi protocoale
                    securizate de transmitere a informatiei (https), Prestatorul nu poate asigura sau garanta securitatea
                    informatiilor transmise de Client către Prestator. Așadar, orice informatie trimisa către Prestator se
                    va face pe riscul Clientului.</div>
                <div>8.3. Orice acces neautorizat la elementele nepublice ale site-ul programintrastat.ro sau
                    accesul altor persoane în afară celor autorizate la un cont programintrastat.ro reprezintă infractiunea de acces
                    fară drept la un sistem informatic și va fi sanctionata conform legislatiei române în vigoare.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>9. Raspunderea contractuala</h1>
                <div>9.1. Clientul garanteaza datele introduse și poarta întreaga responsabilitate pentru felul și scopul în
                    care utilizeaza serviciile puse la dispozitie de Prestator precum și pentru felul în care configureaza
                    sistemul de utilizatori ce au acces la contul Clientului și comportamentul acestora.</div>
                <div>9.2. Clientul este singurul responsabil cu privire la introducerea informatiilor într-un mod corect și
                    complet, precum și cu pastrarea acuratetii informatiilor sau a actualizarii lor în contul
                    programintrastat.ro.</div>
                <div>9.3. Prestatorul nu poate fi tinut raspunzator în cazul în care Clientul se folosește de aplicatiile și
                    serviciile puse la dispozitie în scopuri ilegale sau imorale.</div>
                <div>9.4. Clientul este de acord că este singurul responsabil și va despagubi Prestatorul pentru orice paguba,
                    costuri sau limitari de profit care apar că urmare a oricaror actiuni frauduloase din partea acestuia.
                    Prin prezentul document, Clientul întelege și accepta faptul că Prestatorul va transmite datele acestuia
                    către organele de ancheta, în cazul în care legea îl obliga să faca acest lucru.</div>
                <div>9.5. Deși în fiecare moment face eforturi în vederea asigurarii calitatii și corectitudinii mesajelor
                    publicate în site, Prestatorul nu poate garanta, expres sau implicit, în privinta continutului, a
                    software-ului ori a produselor și serviciilor publicate sub egida sa. Prestatorul nu își asuma
                    raspunderea, în nici o situatie, pentru nici o dauna sau eroare pricinuita direct sau indirect, pentru
                    nici o lipsa de profit directa sau indirecta (incluzând, dar fară a se limita la aceasta enumerare:
                    daune pentru pierderea profitului, întreruperea unor afaceri, sau alte daune pecuniare), suferite ca
                    urmare a utilizarii sau a întreruperii utilizarii ori a lipsei regularitatii informatiilor și
                    serviciilor furnizate de site.</div>

            </div>
        </div>

        <div>
            <div>
                <h1>10. Încetarea contractului</h1>
                <div>10.1. Prezentul contract înceteaza în urmatoarele cazuri:</div>
                <ol>
                    <li>partile convin de comun acord încetarea contractului;</li>
                    <li>neîndeplinirea sau îndeplinirea defectuoasa repetata a obligatiilor contractuale de către una din
                        parti;
                    </li>
                    <li>decizia unilaterala a uneia dintre parti, transmisa în scris celeilalte parti; receptia notificarii
                        de încetare trebuie să aiba loc cu minimum 30 zile calendaristice înainte de data stabilita pentru
                        încetarea colaborarii;
                    </li>
                    <li>în caz de dizolvare, lichidare, faliment, retragerea autorizatiei de functionare a unuia din
                        contractanti, caz în care partile vor fi tinute a îndestula datoriile uneia către alta, datorii
                        rezultate pâna la momentul interventiei cauzei de disparitie.
                    </li>
                </ol>
            </div>
        </div>

        <div>
            <div>
                <h1>11. Exonerarea de raspundere</h1>
                <div>11.1. Prestatorul nu va raspunde pentru niciun prejudiciu material sau moral, orice paguba sau costuri ce
                    pot interveni din întârzierea platilor ce sunt în sarcina Clientului, precum și încalcarea de către
                    acesta a oricaror obligatii legale daca nu sunt din vina Prestatorului.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>12. Confidentialitate</h1>
                <div>12.1. Nici una din Partile contractante nu are dreptul, fară acordul scris prealabil al celeilalte Parti:
                </div>
                <ol>
                    <li>de a face cunoscuta orice informatie confidentiala unei terte parti, în afară acelor persoane
                        implicate în derularea si executarea contractului;
                    </li>
                    <li>de a utiliza orice informatie confidentiala sau la care are acces în perioada de derulare a
                        contractului, în alt scop decât acela de a-si executa obligațiile asumate. Toate informatiile
                        confidentiale trebuie marcate ca atare de partea care le invoca a fi confidentiale.
                    </li>
                </ol>
                <div>12.2. Restrictia prevazuta anterior nu va fi aplicabila daca:</div>
                <ol>
                    <li>informatia era cunoscuta partii contractante înainte că ea să fi fost primita în executarea
                        prezentului contract;
                    </li>
                    <li>informatia era accesibila publicului;</li>
                    <li>partea în cauza a fost obligata în conformitate cu dispozitiile legale să dezvaluie informatiile în
                        cauza.
                    </li>
                </ol>
            </div>
        </div>

        <div>
            <div>
                <h1>13. Forta majora</h1>
                <div>13.1. Forta majora exonereaza de raspundere partile, în cazul neexecutarii partiale sau totale a
                    obligatiilor asumate prin prezentul contract. Prin forta majora se întelege un eveniment independent de
                    vointa parților, imprevizibil și insurmontabil, aparut dupa încheierea contractului și care împiedica
                    partile să execute total sau partial obligațiile asumate.</div>
                <div>13.2. Partea care invoca forta majora are obligatia să o aduca la cunoștinta celeilalte parti, în scris,
                    în maximum 5 zile de la aparitie.</div>
                <div>13.3. Partea care invoca forta majora are obligatia să aduca la cunoștinta celeilalte parti încetarea
                    cauzei acesteia în maximum 15 zile de la încetare.</div>
                <div>13.4. Daca aceste împrejurari și consecintele lor dureaza mai mult de 2 luni, fiecare partener poate
                    renunta la executarea contractului pe mai departe. În acest caz, nici una din parti nu are dreptul de a
                    cere despagubiri de la cealalta parte, dar ele au îndatorirea de a-și onora toate obligațiile pâna la
                    aceasta data.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>14. Legea guvernanta</h1>
                <div>14.1. Drepturile și obligațiile parților impuse de prezentul contract, precum și toate efectele juridice
                    pe care acesta le produce vor fi interpretate și guvernate de legea româna în vigoare.</div>
                <div>14.2. Orice litigiu care are că obiect acest acord va fi solutionat pe cale amiabila, iar în cazul în
                    care solutionarea amiabila nu este posibila va fi adus spre solutionare în fata unui arbitru. În
                    condițiile în care partile nu cad de acord asupra numirii unui arbitru în termen de 15 zile de la
                    notificarea litigiului, competenta va reveni instantelor românești de la sediul Prestatorului.</div>
            </div>
        </div>

        <div>
            <div>
                <h1>15. Dispozitii finale</h1>
                <div>15.1. Partile declara că au negociat toate clauzele prezentului contract și acestea sunt acceptate în mod
                    expres prin semnarea contractului, orice întelegere anterioara neproducând efecte juridice între
                    acestea.</div>
                <div>15.2. Prestatorul isi rezerva dreptul de a actualiza și modifica periodic acesti termeni. În astfel de
                    cazuri, vom informa Clientul în avans, prin postarea acestei politici pe website cu 15 de zile înainte
                    de intrărea să în vigoare, motiv pentru care rugam Clientul să verifice periodic continutul acestui
                    document.</div>
                <div>15.3. Orice notificare către Prestator trebuie trimisa electronic la adresele de email programintrastat.ro</div>
            </div>
        </div>
        <div>
            <h1>Anexa 1 – prelucrarea datelor personale de către programintrastat.ro conform GDPR</h1>
            <div>Prezenta anexa prevede regulile specifice în ceea ce privește prelucrarea datelor cu
                caracter personal trimise de către Beneficiar, în calitate de Operator, de către ASQUARED S.R.L, în calitate de împuternicit în conformitate cu Regulamentului (UE)
                2016/679 privind protectia persoanelor fizice în ceea ce privește prelucrarea datelor cu
                caracter personal și privind libera circulatie a acestor date (denumit în continuare
                ”GDPR”), că și orice legislatie nationala subsecventa cu privire la domeniul protectiei
                datelor cu caracter personal.</div>
            <div><strong>Art 1. Termeni</strong></div>
            <div>În cuprinsul acestei anexe termenii folositi vor fi interpretati în conformitate cu GDPR, iar
                acolo unde este cazul, termenii folositi vor avea definitiile stipulate de art. 4 din GDPR.</div>
            <div><strong>Art 2. Obiectul prelucrarii</strong></div>
            <div>Obiectul prelucrarii datelor personale îl reprezintă prelucrarea de către Împuternicit a
                datelor personale trimise de către Operator în scopul furnizarii serviciilor stipulate în
                contractul principal de servicii programintrastat.ro.</div>
            <div><strong>Art 3. Date colectate</strong></div>
            <div>Datele personale puse la dispozitie de către Operator prelucrate în temeiul acestui
                contract sunt:
            <div>3.1 Pentru serviciul programintrastat.ro – datele personale sunt cele care apar pe
                documente, cum ar fi: Datele Companie, Nume prenume emitent document și date de identificare
                ale acestuia, email, telefon; în plus datele necesare pentru intocmirea declaratiei intrastat si incarcate
                din fisierele excel sau introduse manual.</div>
            <div>3.2 Pentru serviciul programintrastat.ro – configurare transmitere email-uri către clienti,
                datele sunt – email utilizator și parola prin care se transmit email-urile, cat și
                adresele de email către care se trimit notificările.</div>

            </div>
            <div><strong>Art.4 Categorii de persoane vizate</strong></div>
            <div>Categoriile de persoane vizate sunt:</div>
            <ul>
                <li>angajati ai Operatorului
                    <ul>
                        <li>Doar cei care emit facturi (Art. 3.1)</li>
                        <li>Doar cei care trimit facturi (Art. 3.2)</li>
                    </ul>
                </li>
                <li>clienti ai Operatorului (doar pentru datele specifice mentionate expres la Art. 3)</li>
            </ul>
            <div><strong>Art. 5. Instructiuni specifice</strong></div>
            <div>
                În temeiul acestui contract Operatorul da urmatoarele instructiuni specifice Împuternicitului:
            <div>5.1. Sa colecteze și să prelucreze date cu caracter personal primite de la Operator
                în mod direct în scopul prevazut la art.7. Aceste date sunt cele specificate în
                articolul 3.</div>
            <div>5.2. Sa trimita mesaje prin email în numele Operatorului, pentru serviciul de
                transmitere documente prin email (daca acest serviciu este folosit de către
                Operator);</div>
            <div>5.3. Sa colecteze și să prelucreze informatii daca documentele sale trimise si
                gazduite pe platforma programintrastat.ro au fost deschise si/sau vizualizate de către
                Clientii Operatorului.</div>
            </div>
            <div><strong>Art. 6. Durata prelucrarii</strong></div>
            <div>Durata prelucrarii datelor cu caracter personal este identica cu durata functionarii
                contractului principal de furnizare servicii.</div>
            <div><strong>Art. 7 Natura și scopul prelucrarii</strong></div>
            <div>Natura și scopul prelucrarii sunt cele stabilite de către Operator în temeiul contractului
                principal și anume furnizarea serviciilor programintrastat.ro, în funcție de pachetul de servicii
                achizitionat.</div>
            <div>Pentru clientii care utilizeaza autentificarea prin recunoașterea profilului de tastare,
                prelucrarea datelor are că scop protejarea împotriva substituirii de persoana și
                a potentialelor riscuri ce pot decurge din acest fapt.</div>
            <div><strong>Art. 8 Sub-împuterniciti</strong></div>
            <div>
                În cazul în care prelucrarea datelor Operatorului sau anumite parti are prelucrarii se
                efectueaza de către Împuternicit prin intermediul altor persoane, numite sub-împuterniciti,
                acesta trebuie să respecte urmatoarele principii:
            {/* <div>8.1. În temeiul acestui articol Operatorul întelege să autorizeze Împuternicitul sa
                prelucreze datele sale prin urmatorii sub-împuterniciti pentru urmatoarele activitati:
                <br />– A2 Hosting VAT Number: EU528002111 // GSTIN: 9921USA29023OSM - locatie Olanda - pentru gazduire
            </div> */}

            <div>8.1 Pentru sub-împuterniciti viitori, Împuternicitul primește o autorizare generala de a
                subcontracta cu orice alt furnizor din U.E., EEA sau tara cu nivel adecvat de protectie
                recunoscut prin decizie a Comisiei Europene, care este necesar pentru anumite parti ale
                procesarii datelor conform prezentului contract care ofera un nivel de securitate adecvat, cel
                putin la nivelul prezentului contract. Aceasta autorizare include obligatia de informare a
                Operatorului, printr-un mesaj prin contul de pe site-ul Împuternicitului sau prin email.
                Operatorul are posibilitatea de a formula obiectii în termen de 2 zile lucratoare.</div>
            <div>8.2 De asemenea programintrastat.ro poate trimite datele prelucrate de Operator către
                alti împuterniciti ai Operatorului (cum ar fi de exemplu procesatorii de
                plati) doar daca exista un contract între Operator și acești alti
                împuterniciti, că și cererea Operatorului către programintrastat.ro de a trimite
                datele către acest împuternicit.</div>
            </div>
            <div><strong>Art. 9. Drepturile și obligațiile Operatorului</strong></div>
            <ul>
                <li>Dreptul să primeasca informatii de la Împuternicit sau să verifice prin auditor
                    mandatat daca Împuternicitul are și pune în aplicare masuri tehnice și organizatorice
                    adecvate, astfel încât prelucrarea să respecte cerintele prevazute de GDPR;
                    verificarea va avea loc în baza unei notificari scrise prealabile, transmisa cu cel putin
                    14 zile lucratoare înainte de efectuarea verificarii;
                </li>
                <li>Dreptul să primeasca asistenta din partea Împuternicitului, în special pentru
                    îndeplinirea obligatiei sale de a raspunde cererilor persoanelor vizate cu privire la
                    exercitarea drepturilor lor prevazute de GDPR;
                </li>
                <li>Dreptul de a face obiectiuni cu privire la alti sub-împuterniciti conform art. 8.2;</li>
                <li>Sa respecte obligațiile sale conform GDPR în calitate de Operator cu privire la datele
                    cu caracter personal colectate sau prelucrate de Împuternicit, pe seama sa;
                </li>
                <li>Obligatia de a face informarea persoanelor vizate conform GDPR, inclusiv în ceea
                    ce privește informarea cu privire la prelucrarea datelor de către Împuternicit în temeiul
                    acestui contract;
                </li>
                <li>Obligatia să raspunda exclusiv de stabilirea temeiului legal pentru prelucrarea datelor
                    cu caracter personal ce face obiectul prezentului contract;
                </li>
                <li>Obligatia de a implementa masuri tehnice și organizatorice adecvate conform GDPR,
                    inclusiv securizarea transferului datelor de la persoanele vizate către Împuternicit;
                </li>
                <li>Operatorul întelege că din momentul ștergerii datelor dupa încheierea prestării
                    serviciilor de către Împuternicit conform obligatiilor GDPR și a art. 10 a acestui
                    contract, datele nu mai pot fi recuperate și este întreaga responsabilitate a
                    Operatorului să se asigure că și-a facut o copie completa a acestora.
                </li>
                <li>În toate situatiile în care Operatorul este cel care trebuie să execute o obligatie, cum
                    este, spre exemplu, informarea persoanei vizate cu privire la încalcarea securitatii
                    datelor cu caracter personal, Împuternicitul nu poate fi tinut de inactiunile Operatorului
                    din sfera acelei obligatii.
                </li>
            </ul>
            <div><strong>Art. 10. Drepturile și obligațiile Împuternicitului:</strong></div>
            <ul>
                <li>Obligatia să informeze Operatorul în termen de maxim 10 zile, în cazul în care, în
                    opinia Împuternicitului, o instructiune încalca GDPR și/sau alta dispozitie legala
                    privind prelucrarea datelor cu caracter personal;
                </li>
                <li>Obligatia să asigure securitatea datelor cu caracter personal prelucrate în numele
                    Operatorului în conformitate cu art 32 din GDPR și cu art 11 din prezenta Anexa;
                </li>
                <li>Obligatia de a informa Operatorul fară întârzieri nejustificate de o încalcare a
                    securitatii datelor personale ale Operatorului pe parcursul prelucrarii realizate de
                    către Împuternicit;
                </li>
                <li>Obligatia de a asista Operatorul cu toate informatiile necesare în vederea notificarii,
                    daca este cazul, către Autoritatea competenta pentru încalcarea securitatii datelor,
                    dar fară a se substitui Operatorului în obligatia să de notificare;
                </li>
                <li>Obligatia de a acorda asistenta Operatorului să asigure respectarea obligatiilor
                    prevazute la articolele 32-36 din GDPR;
                </li>
                <li>Obligatia de a asista Operatorul pentru solutionarea cererilor persoanelor vizate sau
                    de a transmite Operatorului orice cerere primita de la persoanele vizate, în legatura
                    cu datele personale care au fost colectate și prelucrate de Împuternicit, în termen de
                    maxim 5 zile calendaristice de la primirea acesteia. Aceasta asistenta nu se aplica în
                    situatia în care Operatorul are deja în instrumentele tehnice furnizate de către
                    Împuternicit posibilitatea de a solutiona direct cererea persoanei vizate (de ex.
                    Dreptul la acces – unde Operatorul are deja toate informatiile cu privire la ce date
                    colecteaza);
                </li>
                <li>Obligatia să nu transmita date cu caracter personal și/sau informatii confidentiale, ce
                    pot fi date cu caracter personal, despre care a luat cunoștinta în timpul executarii
                    contractului;
                </li>
                <li>Obligatia să asigure instruirea personalului autorizat să prelucreze datele cu caracter
                    personal, cu privire la confidentialitatea acestor date;
                </li>
                <li>Obligatia să includa obligatii de confidentialitate către angajati și sub-împuterniciti;</li>
                <li>Dreptul să dezvaluie anumite date cu caracter personal în virtutea unei obligatii legale
                    sau a unei alte condiții prevazute de legislatie la solicitarea unei autoritatii, institutii
                    publice sau instante judecatorești.
                </li>
                <li>Dreptul de a recruta sub-împuterniciti conform art. 8 sau în situatia în care a primit
                    aprobare din partea Operatorului;
                </li>
                <li>Dreptul la acoperirea costurilor generate de asigurarea asistentei Operatorului, de
                    către Operator, în situatiile prevazute de GDPR conform art. 9, daca acestea
                    depașesc costul lunar al serviciilor prestate de către Împuternicit.
                </li>
                <li>Dreptul de a folosi informatii statistice anonimizate că urmare a activitatilor prestate
                    că urmare a acestui contract sau a întregii sale activitati.
                </li>
                <li>Împuternicitul nu poate stabili scopuri sau mijloace de prelucrare a datelor cu
                    caracter personal, acestea fiind stabilite exclusiv de către Operator.
                </li>
            </ul>
            <div><strong>Art.11 Securitatea prelucrarii</strong></div>
            <div>
            <div>11.1 Împuternicitul trebuie să îndeplineasca masuri tehnice și organizatorice adecvate
                pentru a asigura masurile adecvate de securitate raportate la risc, conforme cu bunele
                practici în industrie. În stabilirea nivelului adecvat de securitate, Împuternicitul trebuie să ia
                în considerare stadiul actual al dezvoltarii, costurile implementarii și natura, domeniul de
                aplicare, contextul și scopurile prelucrarii, precum și riscul cu diferite grade de probabilitate
                și gravitate pentru drepturile și libertatile persoanelor fizice, că și riscurile care apar ca
                urmare a prelucrarii, în special cu privire la cele care pot duce care duce, în mod accidental
                sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizata a datelor cu
                caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul
                neautorizat la acestea.</div>
            <div>11.2 În acest context Împuternicitul a stabilit aplicarea interna a urmatoarele masuri
                organizatorice și tehnice de securitate pentru securitatea datelor personale, luând în
                considerare tipul de activitate prestata:
                <ul>

                    <li>acces limitat la baza de date pentru un numar foarte restrans de angajati ai
                        Imputernicitului
                    </li>
                    <li>monitorizarea permanenta a accesului la baza de date</li>
                    <li>criptarea conexiunii folosite de Operator pentru accesarea serviciului folosind SSL</li>
                    <li>parolele clientilor stocate criptat</li>
                    {/* <li>copii de siguranta regulate</li> */}
                </ul>
            </div>
            <br />
            <div>11.3 În mod voluntar, Împuternicitul poate trimite rezumatele concluziilor auditorilor de
                securitate (dupa ștergerea informatiilor comerciale sau confidentiale) realizate periodic către
                Operatori pentru a demonstra activitatile sale continue pe acest subiect.
                Art.12 Limitarea raspunderii
                Operatorul este de acord să exonereze Împuternicitul de orice raspundere pentru pagubele
                ce ar putea decurge din:
                <ul>
                    <li>nerespectarea contractului din cauza unor evenimente ce exced oricarei raspunderi
                        a Împuternicitului;
                    </li>
                    <li>respectarea instructiunilor Operatorului sau nerespectarea instructiunilor
                        Operatorului justificata în prealabil printr-o notificare referitoare la nelegalitatea ei;
                    </li>
                    <li>lipsa sau vicierea acordului persoanelor vizate sau a utilizarii unui temei legal greșit
                        de către Operator;
                    </li>
                    <li>nerespectarea contractului din cauza unor actiuni ale Operatorului.</li>
                </ul>
            </div>
            </div>
            <div><strong>Art.13 Delimitarea raspunderii</strong></div>
            <div>Operatorul și Împuternicitul își delimiteaza responsabilitatile cu privire la asigurarea
                protectiei datelor cu caracter personal (de exemplu asigurarea confidentialitatii sau a
                securitatii prelucrarii), în funcție de accesul și controlul efectiv exercitat asupra datelor, atât
                din punct de vedere contractual, cât și tehnic</div>
            <div><strong>Art.14 Intrare în vigoare și modificari.</strong></div>
            <div>Aceasta anexa intră în vigoare pe 01 februarie 2023 și este valabilă pâna la modificarea ei de
                către programintrastat.ro și informarea clientilor în acest sens. Utilizarea contului dupa informarea
                clientilor înseamna acordul acestor cu privire la acest document.</div>
        </div>
        </div>
    </div>
  )
}

export default TermsConditions