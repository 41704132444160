///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import { saveAs } from "file-saver";
import { 
    COD_VAMAL, 
    CONDITIE_LIVRARE, 
    LOCALITATE, 
    MOD_TRANSPORT, 
    NATURA_TRANZACTIEI, 
    TARA_DEST_EXP, 
    TARA_ORIGINE, 
    TARA_ORIGINE_EU, 
    UNITATE 
} from "../databases/VersionsIntrastat";
// import { getCountyCode } from "./CountryService";
import { getXMLTimestamp } from "./DateService";
import { splitVat } from "./ValidationService";

const encodeXmlEntities = (str) => {
    return str.replace(/[&<>"']/g, (char) => {
        switch (char) {
        case '&':
            return '&amp;';
        case '<':
            return '&lt;';
        case '>':
            return '&gt;';
        case '"':
            return '&quot;';
        case "'":
            return '&apos;';
        default:
            return char;
        }
    });
}

const top = 
`<?xml version="1.0" encoding="UTF-8" ?>
`

// 0 - new 1 - Revised
const main_start = (type) => {
    const declType = type === 'achizitii' ? 'Arrival' : 'Dispatch'
    const toReturn = [
`<InsNew${declType} SchemaVersion="1.0" xmlns="http://www.intrastat.ro/xml/InsSchema">
`,
`<InsRevised${declType} SchemaVersion="1.0" xmlns="http://www.intrastat.ro/xml/InsSchema">
`
]
    return toReturn
}
// 0 - new 1 - Revised
const main_end = (type) => {
    const declType = type === 'achizitii' ? 'Arrival' : 'Dispatch'
    const toReturn = [
`</InsNew${declType}>
`,
`</InsRevised${declType}>
`
]
    return toReturn
}

const versions = 
`   <InsCodeVersions>
        <CountryVer>${TARA_ORIGINE}</CountryVer>
        <EuCountryVer>${TARA_ORIGINE_EU}</EuCountryVer>
        <CnVer>${COD_VAMAL}</CnVer>
        <ModeOfTransportVer>${MOD_TRANSPORT}</ModeOfTransportVer>
        <DeliveryTermsVer>${CONDITIE_LIVRARE}</DeliveryTermsVer>
        <NatureOfTransactionAVer>${NATURA_TRANZACTIEI}</NatureOfTransactionAVer>
        <NatureOfTransactionBVer>${NATURA_TRANZACTIEI}</NatureOfTransactionBVer>
        <CountyVer>${TARA_DEST_EXP}</CountyVer>
        <LocalityVer>${LOCALITATE}</LocalityVer>
        <UnitVer>${UNITATE}</UnitVer>
    </InsCodeVersions>
`;

const header = (data) => {
    const toReturnStart = 
`   <InsDeclarationHeader>
        <VatNr>${data.compVat}</VatNr>
        <FirmName>${encodeXmlEntities(data.compName)}</FirmName>
        <RefPeriod>${data.refPeriod}</RefPeriod>
        <CreateDt>${getXMLTimestamp()}</CreateDt>
        <ContactPerson>
            <LastName>${encodeXmlEntities(data.fName)}</LastName>
            <FirstName>${encodeXmlEntities(data.lName)}</FirstName>
            <Email>${encodeXmlEntities(data.email)}</Email>
            <Phone>${data.phone}</Phone>
            <Position>${encodeXmlEntities(data.position)}</Position>
        </ContactPerson>
`;
    const tertPart = 
    // in DTPAddress:
    // <Street></Street>
    // <StreetNumber></StreetNumber>
    // <LocalityCode></LocalityCode>
    // <CountyCode>${getCountyCode(data.county)}</CountyCode>
    // <PostalCode></PostalCode>
`        <DTPDetails>
            <VatNr>${data.tertVat}</VatNr>
            <FirmName>${encodeXmlEntities(data.tertName)}</FirmName>
            <DTPAddress>
            </DTPAddress>
         </DTPDetails>
`;
    const toReturnFinal =
`   </InsDeclarationHeader>
`;
    if(data.tert) return (toReturnStart + tertPart + toReturnFinal)
    return (toReturnStart + toReturnFinal)
}

const items = (data) => {
    const { itemArray, type, isValStat } = data
    if(itemArray.length < 1) return
    let finalString = ``
    // header of item
    const start = (idx) => type === 'achizitii' ? 
`   <InsArrivalItem OrderNr="${idx+1}">
` : 
`   <InsDispatchItem OrderNr="${idx+1}">
`;
    // footer of item
    const end = type === 'achizitii' ?
`   </InsArrivalItem>
` :
`   </InsDispatchItem>
`;
    // content of item
    for(let i = 0; i < itemArray.length; i++) {
        const item = itemArray[i]
        const itemSize = item.length - 1
        const transNatureA = item[7].toString().substr(0, item[7].indexOf('.'));
        const transNatureB = item[7].toString().substr(0, item[7].indexOf('.') + 2);
        let helperString = start(i)
        helperString +=
`        <Cn8Code>${item[0]}</Cn8Code>
        <InvoiceValue>${item[1]}</InvoiceValue>
`;
        if(isValStat) helperString +=
`        <StatisticalValue>${item[6]}</StatisticalValue>
`;
        helperString +=
`        <NetMass>${item[2]}</NetMass>
        <NatureOfTransactionACode>${transNatureA}</NatureOfTransactionACode>
        <NatureOfTransactionBCode>${transNatureB}</NatureOfTransactionBCode>
        <DeliveryTermsCode>${item[3]}</DeliveryTermsCode>
        <ModeOfTransportCode>${item[8]}</ModeOfTransportCode>
        <CountryOfOrigin>${item[4]}</CountryOfOrigin>
`;
        if(item[9] !== '') helperString +=
`        <InsSupplUnitsInfo>
            <SupplUnitCode>${item[9]}</SupplUnitCode>
            <QtyInSupplUnits>${item[10]}</QtyInSupplUnits>
        </InsSupplUnitsInfo>
`;
        if(type === 'achizitii') helperString +=
`        <CountryOfConsignment>${item[5]}</CountryOfConsignment>
`;
        else helperString +=
`        <CountryOfDestination>${item[5]}</CountryOfDestination>
        <PartnerCountryCode>${splitVat(item[itemSize])[0]}</PartnerCountryCode>
        <PartnerVatNr>${splitVat(item[itemSize])[1]}</PartnerVatNr>
`;
        helperString += end
        finalString += helperString
    }
    return finalString
}

// Order:
// top
// main_start
// ->versions
// ->header
// ->items
// main_end

export const getXMLGeneratedData = (data) => {
    const result = top + main_start(data.type)[data.reviz ? 1 : 0] + versions + header(data) + items(data) + main_end(data.type)[data.reviz ? 1 : 0]
    return result
}

export const downloadXmlFomString = (string, title = 'programintrastat_XML_generat') => {
    const blob = new Blob([string], {type: 'text/xml'})
    saveAs(blob, `${title}.xml`)
}