///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import { CoduriVamale } from "../databases/CodVamal"


export const checkValueCodVamal = (value) => {
    if(!value || value === '') return false
    if(CoduriVamale.has(value.toString())) return true
    else return false
}