///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import './App.css';
import Policy from './components/Policy';
import TermsConditions from './components/TermsConditions';
import Header from './components/Header';
import Footer from './components/Footer';
import Info from './components/Info';
import SignIn from './components/SignIn';
import Register from './components/Register';
import { AuthProvider } from './context/AuthContext';
import MainPage from './components/MainPage';
import Contact from './components/Contact';
import ServiceInfoScroll from './components/ServiceInfoScroll';
import Docs from './components/Docs/Docs';
// for BETA TESTING TODO remove for prod
import { allowedIPS } from './forBetaTesting/AllowedIPV4S';
// for BETA TESTING TODO remove for prod
import ComingSoon from './forBetaTesting/ComingSoon';
import { BETA_TEST_COOKIE, checkCookie, setCookie } from './services/CookieService';
import NotFountPage from './components/NotFountPage';
import WelcomeScreen from './components/WelcomeScreen';
import HelperMenu from './components/Helpers/HelperMenu';

function App() {
  const TESTING = false;
  // for BETA TESTING TODO remove for prod
  const [allowed, setAllowed] = useState(false)
  // for BETA TESTING TODO remove for prod
  const [loaded, setLoaded] = useState(false)

  // for BETA TESTING TODO remove for prod
  useEffect(() => {
    if (!TESTING) return;
    if(checkCookie(BETA_TEST_COOKIE)) {
      setAllowed(true)
      setLoaded(true)
      return
    }

    const fetchGeo = async () => {
      const geoRes = await 
        fetch('https://geolocation-db.com/json/')
        .then(res => res.json())
        .then(message => { return message });
      if (geoRes === null || geoRes.IPv4 === '') {
        setLoaded(true)
        return
      }
      
      const toCheck = (geoRes.IPv4).toString()
      if (allowedIPS.has(toCheck)) {
        setCookie(BETA_TEST_COOKIE, true, 62, true)
        setAllowed(true)
      }
      setLoaded(true)
    }
    fetchGeo()
    // eslint-disable-next-line
  }, [])

  if (!TESTING) {
    return (
      <div className="App">
        <WelcomeScreen />
        <ServiceInfoScroll />
        <Router>
          <AuthProvider>
            <Header />
              <Routes>
                <Route element={<MainPage />} exact path="/" />
                <Route element={<Policy />} path="/politica-de-confidentialitate" />
                <Route element={<TermsConditions />} path="/termeni-si-conditii"/>
                <Route element={<Info />} path="/info"/>
                <Route element={<SignIn />} path="/sign-in"/>
                <Route element={<Register />} path="/register"/>
                <Route element={<Contact />} path="/contact"/>
                <Route element={<Docs />} path="/docs"/>
                <Route element={<HelperMenu />} path="/IntHelpers"/>
                <Route element={<NotFountPage />} path="*" />
              </Routes>
            <Footer />
          </AuthProvider>
        </Router>
        <NotificationContainer/>
      </div>
    );
  }

  // for BETA TESTING TODO remove for prod
  if (!allowed && loaded) 
  return (
    <>
      <ComingSoon />
    </>
  );

  // for BETA TESTING TODO remove for prod
  if (loaded)
  return (
    <div className="App">
      <WelcomeScreen />
      <ServiceInfoScroll />
      <Router>
        <AuthProvider>
          <Header />
            <Routes>
              <Route element={<MainPage />} exact path="/" />
              <Route element={<Policy />} path="/politica-de-confidentialitate" />
              <Route element={<TermsConditions />} path="/termeni-si-conditii"/>
              <Route element={<Info />} path="/info"/>
              <Route element={<SignIn />} path="/sign-in"/>
              <Route element={<Register />} path="/register"/>
              <Route element={<Contact />} path="/contact"/>
              <Route element={<NotFountPage />} path="*" />
            </Routes>
          <Footer />
        </AuthProvider>
      </Router>
      <NotificationContainer/>
    </div>
  );
  else
  return (
    <div>Se încarcă(BETA)...</div>
  )
}

export default App;
