///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics"

import { checkIfUserSideId, decodeUserSideId, userSideId } from "../services/IdService"
import { createNotification } from "../services/NotificationService"
import { analytics, auth, db } from "../services/FirebaseService"
import { debugMsgLog } from "../services/LoggingService"
import { 
    currentSubMonth, 
    getLayoutsLimit, 
    setStartLines, 
    subscriptionLines, 
    subscriptionPrices, 
    subscriptionTime,
    subscriptionNames } 
from "../services/SubscriptionsService"
import { getDateFromTimestamp, toFormatDate, toFormatTime } from "../services/DateService"

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] =         useState({});
    const [userData, setUserData] =               useState({});
    const [invoiceData, setInvoiceData] =         useState([]);
    const [displayInvoices, setDisplayInvoices] = useState([]);
    const [hasUnpaid, setHasUnpaid] =             useState(undefined)
    const [taxData, setTaxData] =                 useState({});
    const [layouts, setLayouts] =                 useState([]);
    const [companies, setCompanies] =             useState([]);
    const [currentRates, setCurrentRates] =       useState({})
    const [contactEmails, setContactEmails] =     useState([])
    const [userAccounts, setUserAccounts] =       useState([])
    const [generatedHistory, setGeneratedHistory] = useState([])
    // For loading
    const [loading, setLoading] =             useState(true)
    const [loadingAction, setLoadingAction] = useState(false)

    const navigate = useNavigate();

    // const API_URL = `http://127.0.0.1:5001/program-intrastat/us-central1/rest_api/` // For localhost
    const API_URL = `https://program-intrastat-backend.web.app/` // For production
    const EMAIL_URL = 'p37FyXv66qhTXDD52PxHWQVpN1XTnh2BcVL'
    const GET_USERS_URL = 'wxN70yXYwsHTzZgRZNWK'
    const IMPERSONATE_USERS_URL = 'R65v2AcQ3p5xgcJiR01h'
    const HELPERS_CHECK_URL = 'GMngJMP0bGCkLnVbKpcs'
    const CARS_URL = 'QHW4Qi5gmyqWmTH38WwR'
    const NEW_CAR_URL = 'hk70fmTx9LV1ANxpRwPc'
    const ADMIN_KEY = "x3DrG8rQ1wJauBtWLMQFpyrgqgYXyJZbnyZJfs7kxj4tMBhnYu8rWrLCnrsNCBeU3AzN8ABFMUNyACJH1fNCGuqyEXHEyHYWmtCV"

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Add error to firestore DB if logging is not enabled
    //-----------------------------------------------------------------------------------------
    const errorFirestoreLog = async (error, eId) => {
        const eMsg = error.message;
        const isDebug = debugMsgLog(eMsg, eId)
        // TODO remove redundant check
        if (!isDebug || isDebug) 
        {
            try {
                const today = new Date()
                const newData = {
                    date: today.toString(),
                    timeStamp: today,
                    message: eMsg,
                    uid: currentUser ? currentUser.uid : null,
                    errorId: eId
                }

                await db.collection("error_logging").add(newData);
            }
            catch (err) {
                debugMsgLog(err.message)
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls API to send email
    //-----------------------------------------------------------------------------------------
    const emailAction = async (body) => {
        await fetch(`${API_URL}${EMAIL_URL}`, {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(message => {
            debugMsgLog(message)
            // Analytics
            logEvent(analytics, 'email_sent');
            return true
        })
        .catch((error) => {
            debugMsgLog("Email send error")
            debugMsgLog(error)
            return false
        });
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Creates new user in firestore with new demo subscription as default
    //-----------------------------------------------------------------------------------------
    const signup = async (name, email, phone, password, cui, company, city, county, address, regCom, tert, promoCode, ip) => {
        try{
            await auth.createUserWithEmailAndPassword(email, password)
            .then(async (res) => {
                const user = res.user;

                user.updateProfile({
                    displayName: name,
                    phoneNumber: phone
                })

                user.sendEmailVerification();

                const dataToAdd = {
                    uid: user.uid,
                    name,
                    email,
                    cui,
                    phone,
                    company,
                    city,
                    county,
                    promoCode,
                    tert,
                    address,
                    regCom,
                    ip,
                    admin: false,
                    accessLevel: 0,
                }

                const today = new Date();

                const subData = {
                    uid: user.uid,
                    name: 0,
                    date: today.toString(),
                    timeStamp: today,
                    duration: subscriptionTime(0),
                    layoutsAvailable: getLayoutsLimit(0),
                    lines: setStartLines(0, subscriptionTime(0)),
                    linesStart: subscriptionLines(0),
                    cifCheck: true,
                    valStatCalc: true,
                    compAvailable: 1,
                    valid: true,
                    manualDisable: false,
                    price: subscriptionPrices(0)
                }

                try {
                    await db.collection('user_info').add(dataToAdd)
                    await db.collection('subscriptions').add(subData)
                }
                catch(err) {
                    errorFirestoreLog(err, '01')
                    return
                }
                const userNow = {data:{...dataToAdd}, subscriptions:[{...subData}]}

                setCurrentUser(user);
                setUserData(userNow);

                const emailData = {
                    type: '6', // New user notification
                    mail: 'programintrastat@gmail.com',
                    name,
                    email,
                    cui,
                    phone,
                    company,
                    city,
                    county,
                    tert,
                    address,
                    regCom,
                    uid: user.uid,
                    time: new Date(),
                }

                try {
                    await emailAction(emailData);
                }
                catch(err) {
                    debugMsgLog(err)
                }

                createNotification('success', `Utilizatorul ${email} a fost creat!`)
                navigate('/')

                // Analytics
                logEvent(analytics, 'user_created');
            })
        }
        catch(err){
            errorFirestoreLog(err, '02')
            // Analytics
            logEvent(analytics, 'error_user_created');
            if(err.message === 'Firebase: The email address is already in use by another account. (auth/email-already-in-use).') {
                createNotification('error', `Utilizatorul cu adresa de email ${email} este deja înregistrat`)
            }
            else createNotification('error', 'A existat o eroare la crearea contului.')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Logs user in or displays login error messages
    //-----------------------------------------------------------------------------------------
    const login = async (email, password) => {
        try { 
            await auth.signInWithEmailAndPassword(email, password)
            navigate('/')
        }
        catch(err){
            if(err.message === 'Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).') {
                createNotification('error', `Utilizatorul ${email} nu există`)
            }
            else if(err.message === 'Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).') {
                createNotification('error', `Parolă sau email incorecte`)
            }
            else if(err.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).') {
                createNotification('error', `Acest cont a fost restricționat temporar datorită numeroaserol încercări nereușite de autentificare. Puteți aștepta să intrați in cont mai târziu sau puteți reseta parola pentru a intra acum.`)
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Logs user out and navigates to main page
    //-----------------------------------------------------------------------------------------
    const logout = () => {
        auth.signOut()
        return navigate('/')
    }

    const resetPassword = async (email) => {
        try{
            await auth.sendPasswordResetEmail(email)
            createNotification('info', `Un email pentru resetarea parolei a fost trimis la adresa ${email}`)
            // Analytics
            logEvent(analytics, 'password_reset');
        }
        catch(err){
            if(err.message === 'Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).') {
                createNotification('error', `Utilizatorul ${email} nu există`)
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sends email to user email address to verify it
    //-----------------------------------------------------------------------------------------
    const verifyEmail = () => {
        try{
            auth.onAuthStateChanged(user => user.sendEmailVerification())
            createNotification('info', `Un email pentru activarea contului a fost trimis.`)
        }
        catch(err){
            errorFirestoreLog(err, '11')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates user email
    //-----------------------------------------------------------------------------------------
    const updateEmail = async (email) => {
        try{
            await currentUser.updateEmail(email)
        }
        catch(err){
            errorFirestoreLog(err, '21')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates user data: name, email and phone
    //-----------------------------------------------------------------------------------------
    const updateProfile = async (name, email, phone) => {
        try{
            await currentUser.updateProfile({
                displayName: name
            })
            //currentUser.updatePhoneNumber(phone);
            await updateEmail(email);

            const objUpdate = {
                name,
                email,
                phone
            }

            await db.collection('user_info').doc(userData.data.infoId).update(objUpdate)
            setUserData(prev => ({...prev, data:{...prev.data, ...objUpdate}}))
            createNotification('success', `Datele pentru ${email} au fost salvate!`)
            return true;
        }
        catch(err){
            errorFirestoreLog(err, '31')
            createNotification('error', `A apărut o eroare la salvarea datelor.`)
            return false;
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates information about user's company
    //-----------------------------------------------------------------------------------------
    const updateUserCompany = async (name, cui, regCom, city, county, address) => {
        try{
            const objUpdate = {
                company: name,
                cui,
                regCom,
                city,
                county,
                address
            }
            await db.collection('user_info').doc(userData.data.infoId).update(objUpdate)

            createNotification('success', `Datele pentru compania ${name} au fost salvate!`)
            setUserData(prev => ({...prev, data:{...prev.data, ...objUpdate}}))
            return true;
        }
        catch(err){
            errorFirestoreLog(err, '41')
            createNotification('error', `A apărut o eroare la salvarea datelor.`)
            return false;
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Create new layout in firestore and locally
    //-----------------------------------------------------------------------------------------
    const newLayout = async (data) => {
        try {
            let newData = data
            await db.collection("layouts").add(data)
            .then(doc => {
                newData.dataId = doc.id
            })
            setLayouts(prev => ([...prev, newData])) // TODO: Change order
            createNotification('success', `Datele pentru macheta ${data.name} au fost salvate!`)

            // Analytics
            logEvent(analytics, 'new_layout');
        }
        catch(err) {
            errorFirestoreLog(err, '51')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates user's already existing layout in firestore and locally
    //-----------------------------------------------------------------------------------------
    const updateLayout = async (id, data) => {
        try {
            await db.collection("layouts").doc(id).update(data)
            const newData = layouts.map(lay => {
                if(lay.dataId === id) { return {...lay, ...data}}
                return lay
            })
            setLayouts(newData)
            createNotification('success', `Datele pentru macheta ${data.name} au fost salvate!`)
        }
        catch(err) {
            errorFirestoreLog(err, '61')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Delets user's layout permanently
    //-----------------------------------------------------------------------------------------
    const deleteLayout = async (id) => {
        const ask = window.confirm('Sigur doriți să ștergeți această machetă?');
        if(ask){
            try{
                await db.collection("layouts").doc(id).delete()
                const layoutData = await getStartingLayoutsData(currentUser.uid)
                setLayouts(layoutData)
                createNotification('success', `Macheta a fost ștearsă!`)
                // Analytics
                logEvent(analytics, 'delete_layout');
            }
            catch(err){
                errorFirestoreLog(err, '71')
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Creates new company for user in firestore and locally
    //-----------------------------------------------------------------------------------------
    const newCompany = async (data) => {
        try {
            let newData = data
            await db.collection("companies").add(data)
            .then(doc => {
                newData.dataId = doc.id
            })
            setCompanies(prev => ([...prev, newData]))
            createNotification('success', `Datele pentru firmă au fost salvate!`)
            // Analytics
            logEvent(analytics, 'new_company');
        }
        catch(err) {
            errorFirestoreLog(err, '81')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates user's existin company on firestore and locally
    //-----------------------------------------------------------------------------------------
    const updateCompany = async (id, data) => {
        try {
            await db.collection("companies").doc(id).update(data)
            const newData = companies.map(comp => {
                if(comp.dataId === id) { return {...comp, ...data} }
                return comp
            })
            setCompanies(newData)
            createNotification('success', `Datele pentru firmă au fost salvate!`)
        }
        catch(err) {
            errorFirestoreLog(err, '91')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Deletes user's company permanently
    //-----------------------------------------------------------------------------------------
    const deleteCompany = async (id) => {
        const ask = window.confirm('Sigur doriți să ștergeți această firmă?');
        if(ask){
            try{
                await db.collection("companies").doc(id).delete()
                const compData = await getStartingCompaniesData(currentUser.uid, userData)
                setCompanies(compData)
                createNotification('success', `Firma a fost ștearsă!`)
                // Analytics
                logEvent(analytics, 'delete_company');
            }
            catch(err){
                errorFirestoreLog(err, '101')
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets exchange rates from firestore taking in m (month) and y (year) ex: m: 01; y: 2023;
    //-----------------------------------------------------------------------------------------
    const getExchangeRates = async (m, y) => {
        let toReturn = {}
        try {
            await db.collection("bnr_exchange_rates").where('month-year', '==', `${m}-${y}`)
            .get().then(results => {
                results.forEach(res => { toReturn = {dataId: res.id, data: res.data()} })
            })
            // Analytics
            logEvent(analytics, 'exchange_rates_db');
        }
        catch(err) {
            errorFirestoreLog(err, '111')
            // Analytics
            logEvent(analytics, 'error_exchange_rates_db');
        }
        setCurrentRates(toReturn)
        return toReturn
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Updates lines available in current user subsription
    //-----------------------------------------------------------------------------------------
    const updateUserLines = async (
            linesLeft, 
            id = userData.subscriptions[0].subId,
            lineIdx = currentSubMonth(userData.subscriptions[0].date)) => 
    {
        const finalLines = linesLeft >= 0 ? linesLeft : 0
        const data = userData.subscriptions[0]
        data.lines[lineIdx] = parseInt(finalLines)
        try {
            await db.collection('subscriptions').doc(id).update(data)
            const newLines = data.lines
            const newData = userData.subscriptions.map(sub => {
                if(sub.subId === id) { return {...sub, lines: newLines} }
                return sub
            })
            setUserData(prev => ({...prev, subscriptions: newData}))
        }
        catch(err) {
            errorFirestoreLog(err, '121')
        }
    }

    // CHECKS WITHOUT USER LOGGED IN
    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if copany existis on creating new user and returns
    //? empty array if it doesn't exist, and array with company if it exists
    //-----------------------------------------------------------------------------------------
    const checkIfCompany = async (comp) => {
        try {
            const isComp = []
            await db.collection('user_info').where('cui', '==', comp).limit(1)
            .get().then(results => {
                results.forEach(res => { 
                    isComp.push(res.data())
                })
            })
            // Analytics
            if (isComp.length > 0) logEvent(analytics, 'tried_existing_company');
            return isComp
        }
        catch(err) {
            errorFirestoreLog(err, '161')
        }
        return []
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Disables last subscription of user
    //-----------------------------------------------------------------------------------------
    const disableLastSubscription = async (id, notification = true) => {
        // id = user id
        try {
            let sub_id = ''
            let isDisabled = true
            let found = false
            await db.collection('subscriptions').where('uid', '==', id)
            .orderBy('timeStamp', "desc")
            .get().then(results => {
                results.forEach(res => {
                    if (!found && !res.data().manualDisable) {
                        sub_id = res.id
                        isDisabled = false
                        found = true
                    }
                })
            })
            if (sub_id === '') {
                notification && createNotification('info', 'Utilizatorul nu are alte abonamente active')
                return true
            }
            if (isDisabled) {
                notification && createNotification('info', 'Ultimul abonament al utilizatorului este dezactivat/expirat')
                return true
            }
            // update data to set manualDisable to TRUE
            try {
                await db.collection('subscriptions').doc(sub_id).update({manualDisable: true})
                notification && createNotification('info', `Abonamentul anterior ${sub_id} a fost dezactivat`)
                return true
            }
            catch(err) {
                errorFirestoreLog(err, '191')
                notification && createNotification('error', `A existat o eroare la dezactivarea abonamentului ${sub_id}`)
                return false
            }

        }
        catch(err) {
            errorFirestoreLog(err, '192')
            return false
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets and returns tax information about programintrastat.ro parent company
    //? from firestore database
    //-----------------------------------------------------------------------------------------
    const fetchTaxData = async (ifEmpty = false, hasLoading = false, setState = true,) => {
        // only fetch if empty
        if (ifEmpty) {
            if (!Object.keys(taxData).length === 0)
            return taxData
        }
        let data = {}
        try {
            hasLoading && setLoadingAction(true)
            await db.collection('tax_info')
            .get().then(results => {
                results.forEach(res => { 
                    data = {...res.data(), id: res.id}
                })
            })
            setState && setTaxData(data)
            hasLoading && setLoadingAction(false)
            return data
        }
        catch (err) {
            debugMsgLog(err)
            errorFirestoreLog(err, '251')
            hasLoading && setLoadingAction(false)
            return {}
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Creates new invoice
    //-----------------------------------------------------------------------------------------
    const newInvoice = async (data, sub_id, setState = true) => {
        try {
            // Get number and series of invoice
            const invoiceSeriesDB = data.type === '0' ? 'proformSeries' : 'series'
            const invoiceCurrentNumberDB = data.type === '0' ? 'proformCurrentNr' : 'currentNr'
            // series + number
            let invoiceSeries = ''
            let invoiceNumber = 0
            let invoiceManagerId = ''
            let exists = false
            const getInvData = async () => {
                await db.collection('invoice_manager').get()
                .then(results => {
                    results.forEach(res => { 
                        invoiceSeries = res.data()[`${invoiceSeriesDB}`]
                        invoiceNumber = +res.data()[`${invoiceCurrentNumberDB}`]
                        invoiceManagerId = res.id
                    })
                })
                exists = false
                await db.collection('invoices')
                .where('seriesNumber', '==', `${invoiceSeries}${invoiceNumber}`)
                .get().then(results => {
                    results.forEach(res => { 
                        exists = true
                    })
                })
            }

            // check for invoice not to already exist
            if (data.type === '1') {
                const maxRetries = 5
                for (let i = 0; i < maxRetries; i++) {
                    !exists && await getInvData()
                    if (!exists) break
                    await new Promise(r => setTimeout(r, 2000));
                }
            }
            else {
                await getInvData()
            }

            // get currency rate
            let today = null
            // NOT 6(saturday) or 0(sunday) because we get from day before
            if (new Date().getDay() !== 6 && new Date().getDay() !== 0) { 
                let daysBack = 1
                if (new Date().getDay() === 1) daysBack = 3
                today = new Date(new Date() - 1000 * 60 * 60 * 24 * daysBack)
            }
            else {
                const daysBack = 2
                today = new Date(new Date() - 1000 * 60 * 60 * 24 * daysBack)
            }

            // converts single digit number to '0x' string
            const numberToString = (nr) => {
                if (nr > 9) return nr
                return `0${nr}`
            }

            let currencyRate = 4.985
            try {
                await db.collection("bnr_exchange_rates").where('month-year', '==', `${today.getMonth() + 1}-${today.getFullYear()}`)
                .get().then(results => {
                    results.forEach(res => { 
                        currencyRate = +res.data()[`${today.getFullYear()}-${numberToString(today.getMonth()+1)}-${numberToString(today.getDate())}`]['EUR'].rate 
                    })
                })
            }
            catch(error) {
                debugMsgLog(error.message)
                currencyRate = 4.985
            }
            
            // exchange price
            const totalPrice = (currencyRate * data.subscription.priceEur).toFixed(2)

            // get tax info to hardcode into invoice
            const dataForTax = await fetchTaxData(true)

            if (Object.keys(dataForTax).length === 0) {
                throw new Error('Error getting tax data for invoice')
            }

            // set total data for invoice
            const totalInvoiceData = { 
                series: invoiceSeries, 
                number: invoiceNumber,
                seriesNumber: `${invoiceSeries}${invoiceNumber}`,
                currencyRate: currencyRate,
                price: totalPrice,
                ...data,
                subscription: { id: sub_id, ...data.subscription },
                taxData: dataForTax
            }

            // add invoice to DB
            let inv_doc_id = ''
            await db.collection("invoices").add(totalInvoiceData)
            .then(doc => {
                inv_doc_id = doc.id
            })

            // set number of invoice counter in db
            await db.collection('invoice_manager').doc(invoiceManagerId)
                .update({[`${invoiceCurrentNumberDB}`]: (invoiceNumber + 1)})

            setState && setInvoiceData(prev => ([...prev, { id: inv_doc_id, ...totalInvoiceData }]))

            return { id: inv_doc_id, ref: `${invoiceSeries}${invoiceNumber}`, price: totalPrice }
        }
        catch(err) {
            debugMsgLog(err)
            errorFirestoreLog(err, '261')
            return false
        }
    }

    // TODO send email to admin when getting new order PAID
    const newSubscription = async (data, invoiceDataGot) => {
        setLoadingAction(true)
        try {
            // check if user has unpaid invoices
            let userHasUnpaid = hasUnpaid
            if (hasUnpaid === undefined) {
                userHasUnpaid = await fetchInvoiceData(invoiceDataGot.uid)
            }

            if (userHasUnpaid) {
                setLoadingAction(false)
                return createNotification('info', `Nu se poate crea un abonament nou cât timp aveți facturi neplătite. 
                Puteți anula o comandă contactându-ne dacă v-ați răzgândit. 
                Direct la programintrastat@gmail.com sau prin formularul de contact de pe platformă.`, 'Facturi restante', 20000)
            }

            // Disable latest subscription
            const disabled_OK = await disableLastSubscription(currentUser.uid, false)
            if (!disabled_OK) {
                setLoadingAction(false)
                return createNotification('error', 'A existat o eroare în crearea abonamentului')
            }

            // Create new subscription
            let sub_doc_id = ''
            await db.collection("subscriptions").add(data)
            .then(doc => {
                sub_doc_id = doc.id
            })

            const inv_data = await newInvoice(invoiceDataGot, sub_doc_id, true)

            const emailData = {
                type: '0', // Factura proforma
                subscription: subscriptionNames(data.name),
                id: userSideId(sub_doc_id),
                mail: currentUser.email
            }
            let emailSent = await emailAction(emailData)
            if (!emailSent) {
                createNotification('info', `Plata abonamentului se poate efectura în secțiunea 'Plăți'`)
            } 
            // TODO test send email to admin
            await new Promise(r => setTimeout(r, 500));
            const emailForAdmin = {
                type: '4', // Email to admin
                id: sub_doc_id,
                status: '0',
                orderName: subscriptionNames(data.name),
                uMail: currentUser.email,
                valTotal: inv_data.price,
                valTva: ((19/100) * (+inv_data.price)).toFixed(2),
                invId: inv_data.id,
                invSeriesNumber: inv_data.ref,
                mail: 'programintrastat@gmail.com'
            }
            // send email to admin
            await emailAction(emailForAdmin)

            createNotification('success', `Abonamentul ${subscriptionNames(data.name)} cu id ${userSideId(sub_doc_id)} a fost creat cu succes`)
            // update state for subscriptions
            setUserData(prev => 
                ({...prev, subscriptions: [...prev.subscriptions, {subId: sub_doc_id, ...data}]})
            )
            // Analytics
            logEvent(analytics, 'user_new_subscription');
            await new Promise(r => setTimeout(r, 500));
            // reload window
            window.location.reload()
            return setLoadingAction(false)

        }
        catch(err) {
            setLoadingAction(false)
            errorFirestoreLog(err, '221')
            return createNotification('error', 'A existat o eroare în crearea abonamentului')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sends contact email to admin email
    //-----------------------------------------------------------------------------------------
    const sendContact = async (data) => {
        try {
            let doc_id = ''
            await db.collection("contact_form").add(data)
            .then(doc => {
                doc_id = doc.id
            })
            const dataToSend = {
                id: userSideId(doc_id),
                type: '3',
                ...data
            }
            let emailSent = await emailAction(dataToSend)
            if (!emailSent) {
                // return createNotification('error', 'A existat o eroare')
            } 
            createNotification('success', `Mesajul a fost trimis. Vă vom contacta în curând`)
            // Analytics
            logEvent(analytics, 'contact_form_send');
        }
        catch(err) {
            errorFirestoreLog(err, '231')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets data about user invoices and returns if user has unpaid invoices
    //-----------------------------------------------------------------------------------------
    const fetchInvoiceData = async (id) => {
        let data = {}
        let dataArray = []
        let userHasUnpaid = false
        setLoadingAction(true)
        try {
            await db.collection('invoices').where('uid', '==', id)
            .get().then(results => {
                results.forEach(res => { 
                    data = {...res.data(), id: res.id} 
                    dataArray.push(data)
                    // if user has ANY unpaid invoice -> don't allow to order new subscription
                    if (res.data().status === '0') userHasUnpaid = true
                })
            })
            setLoadingAction(false)
            setInvoiceData(dataArray)
            setHasUnpaid(userHasUnpaid)
            return userHasUnpaid
        }
        catch (err) {
            setLoadingAction(false)
            debugMsgLog(err)
            errorFirestoreLog(err, '241')
        }
    }

    // START - STATISTICS
    /***
    *   @collection stats_generated_xml_data
    *   @name       StatsGeneratedXml
    *   @structure  | id | uid | lines | fileName | type | 
    *   @           |  company | tertCompany | layoutId  | 
    *   @           |hasErrors |valStat |name | position | 
    *   @           |   date   |   timestamp  |
    ***/
   //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Generate statistics about generated XML
    //-----------------------------------------------------------------------------------------
    const newStatsGeneratedXml = async (data) => {
        // Analytics
        logEvent(analytics, 'generated_xml');
        logEvent(analytics, `generated_xml_${data.uid}`); // custom per user
        try {
            await db.collection("stats_generated_xml_data").add(data)
        }
        catch(err) {
            errorFirestoreLog(err, '171')
        }
    }
    
    // END - STATISTICS

    // GET START DATA

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets subscriptions and user info data about user
    //-----------------------------------------------------------------------------------------
    const getStartingUserData = async (id) => {
        let subs = {};
        let subsArray = []
        try {
            await db.collection('subscriptions').where('uid', '==', id)
            .orderBy('timeStamp', "desc")
            .get().then(results => {
                results.forEach(res => {
                    if (!res.data().manualDisable) {
                        subs = {...res.data(), subId: res.id} 
                        subsArray.push(subs)
                    }
                })
            })
        }
        catch(err) {
            errorFirestoreLog(err, '131')
        }
        
        let info = {};
        try {
            await db.collection('user_info').where('uid', '==', id).limit(1)
                .get().then(results => {
                    results.forEach(res => { 
                        info = {...res.data(), infoId: res.id} })
                })
        }
        catch(err) {
            errorFirestoreLog(err, '132')
        }
        
        const toReturn = {data: {...info}, subscriptions: subsArray}
        return toReturn;
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets layouts data about user
    //-----------------------------------------------------------------------------------------
    const getStartingLayoutsData = async (id) => {
        let data = {}
        let dataArray = []

        try {
            await db.collection('layouts').where('uid', '==', id)
            .get().then(results => {
                results.forEach(res => { 
                    data = {...res.data(), dataId: res.id} 
                    dataArray.push(data)
                })
            })
        }
        catch(err) {
            errorFirestoreLog(err, '141')
        }

        return dataArray
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets companies data about user
    //-----------------------------------------------------------------------------------------
    const getStartingCompaniesData = async (id, add) => {
        let dataObj = {}
        let dataArray = [{
            name: add.data.company,
            cif: add.data.cui,
            city: add.data.city,
            county: add.data.county,
            regCom: add.data.regCom,
            dataId: 0
        }]

        try {
            await db.collection('companies').where('uid', '==', id)
            .get().then(results => {
                results.forEach(res => { 
                    dataObj = {...res.data(), dataId: res.id} 
                    dataArray.push(dataObj)
                })
            })
        }
        catch(err) {
            errorFirestoreLog(err, '151')
        }
        return dataArray
    }

    // START DATA
    const getFirstData = async (user, impersonation = false) => {
        if(user) {
            const additional = await getStartingUserData(user.uid)
            const layoutData = await getStartingLayoutsData(user.uid)
            const compData = await getStartingCompaniesData(user.uid, additional)
            setCurrentUser(user)
            setUserData(additional)
            setLayouts(layoutData)
            setCompanies(compData)
            setGeneratedHistory([])
            // Analytics
            const analytics_event = impersonation ? "user_impers_data_fetch" : 'user_data_fetch';
            logEvent(analytics, analytics_event);
            await new Promise(r => setTimeout(r, 100));
        }
        else {
            setCurrentUser(user)
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls all the starting fetch functions if user is logged in
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            setLoading(true);
            getFirstData(user);
            setLoading(false);
        })
        setLoading(false);
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // HISTORY
    /**
     * Populate generatedHistory with the 10 latest generated XML files info
     * @param {String} id - The id of the user which data to fetch
     * @param {Number} limit - The limit of data to fetch - Only configurable if admin
     * @function
     */
    const fetchGeneratedHistory = async (id, limit = 10) => {
        const userIsAdmin = userData.data ? userData.data.admin : false;
        const HISTORY_LIMIT = userIsAdmin ? limit : 10;
        if (generatedHistory.length >= HISTORY_LIMIT) return;
        setLoadingAction(true);
        try {
            if (!userIsAdmin || id !== "all") {
                // Limit file history to HISTORY_LIMIT
                await db.collection('stats_generated_xml_data')
                    .where('uid', '==', id)
                    .orderBy('timeStamp', "desc")
                    .limit(HISTORY_LIMIT)
                    .get().then(results => {
                        setGeneratedHistory(results.docs.map(doc => doc.data()));
                    });
            }
            // If the user is admin and requested all history
            else if (userIsAdmin && id === "all") {
                await db.collection('stats_generated_xml_data')
                    .orderBy('timeStamp', "desc")
                    .limit(HISTORY_LIMIT)
                    .get().then(results => {
                        setGeneratedHistory(results.docs.map(doc => doc.data()));
                    });
            }
        }
        catch(err) {
            errorFirestoreLog(err, '331');
        }
        finally  {
            setLoadingAction(false);
        }
    }

    /**
     * Updates the generatedHistory array with new data and removes the oldest one.
     * If the array is not populated, return (data will anyway be fetched, no need to populate).
     * @param {Object} data - The data to insert at the top of the generatedHistory array
     * @function
     */
    const addInGeneratedHistory = (data) => {
        if (!data || generatedHistory.length === 0) return;
        setGeneratedHistory(prevArray => {
            const newArray = [data, ...prevArray.slice(0, -1)];
            return newArray;
        });
    }


    // ADMIN PANEL

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if user exists
    //-----------------------------------------------------------------------------------------
    const checkIfUser = async (id) => {
        try {
            const isUser = []
            await db.collection('user_info').where('uid', '==', id).limit(1)
            .get().then(results => {
                results.forEach(res => { 
                    isUser.push(res.data())
                })
            })
            return isUser
        }
        catch(err) {
            errorFirestoreLog(err, '181')
            return false
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Creates new subscription and invoice for user
    //-----------------------------------------------------------------------------------------
    const createNewSubscription = async (data, id, withInvoice = false, sendEmail = false, isPaid = false) => {
        // id = user id
        try {
            const checkUser = await checkIfUser(id)
            if (!checkUser.length > 0) {
                createNotification('error', `Utilizatorul ${id} este inexistent`, 'Eroare')
                return
            }

            const disabled_OK = await disableLastSubscription(id)
            if (!disabled_OK) return

            let doc_id = ''
            await db.collection("subscriptions").add(data)
            .then(doc => {
                doc_id = doc.id
            })

            const printSuccess = (doc, uId) => createNotification('success', `Abonamentul ${doc} pentru utilizatorul ${uId} a fost creat cu succes`)

            if (!withInvoice) return printSuccess(doc_id, id)
            
            // create invoice
            const uData = checkUser[0]
            const invoiceDataToAdd = {
                // id,
                uid: uData.uid,
                type: data.valid ? '1' : '0',
                // series,
                // number,
                // seriesNumber,
                status: isPaid ? '1' : '0',
                timestamp: new Date(),
                created: new Date(),
                paidDate: '',
                dayMonthYear: `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,
                // price ,
                // currencyRate,
                subscription: {
                    // id,
                    name: subscriptionNames(data.name),
                    period: data.duration,
                    quantity: 1,
                    priceEur: +data.price * +data.duration
                },
                client: {
                    name: uData.company,
                    cui: uData.cui,
                    regCom: uData.regCom,
                    address: uData.address,
                    county: uData.county,
                    email: uData.email
                }
            }

            await newInvoice(invoiceDataToAdd, doc_id, false)

            if (!sendEmail) return printSuccess(doc_id, id)

            const emailData = {
                type: data.valid ? '1' : '0', // Factura proforma sau normala
                subscription: subscriptionNames(data.name),
                id: userSideId(doc_id),
                mail: uData.email
            }
            await emailAction(emailData)

            printSuccess(doc_id, id)
        }
        catch(err) {
            errorFirestoreLog(err, '201')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Toggles invoice state to paid or canceled
    //-----------------------------------------------------------------------------------------
    const toggleInvoiceState = async (action, data) => {
        try {
            const { id: invId, sendMail, type } = data

            if (invId === '') {
                createNotification('error', 'ID invalid')
                return
            }

            // check if ID is user side
            let id = checkIfUserSideId(invId) ? decodeUserSideId(invId) : invId

            let userId = ''
            let subName = ''
            let subId = ''
            let currentNr = 0
            let currentSeries = ''
            let wasPaid = false
            let currentStatus = ''
            if (+type === 0) {
                let exists = false
                // get doc by ID
                await db.collection('invoices').doc(id).get()
                .then(res => {
                    exists = res.exists
                    userId = res.data().uid
                    subName = res.data().subscription.name
                    subId = res.data().subscription.id
                    currentNr = res.data().number
                    currentSeries = res.data().series
                    wasPaid = res.data().paidDate !== ''
                    currentStatus = res.data().status
                })
                if (!exists) {
                    createNotification('error',`Factura cu ID ${id} nu există`)
                    return false
                }
            }
            else {
                // get by series and nr
                let got = { exists: false }
                await db.collection('invoices').where('seriesNumber', '==', id).get()
                .then(results => {
                    results.forEach(res => { 
                        userId = res.data().uid
                        subName = res.data().subscription.name
                        subId = res.data().subscription.id
                        currentNr = res.data().number
                        currentSeries = res.data().series
                        wasPaid = res.data().paidDate !== ''
                        currentStatus = res.data().status
                        got.exists = true
                        id = res.id
                    })
                })
                if (!got.exists) {
                    createNotification('error',`Factura cu seria si numarul ${id} nu există`)
                    return false
                }
            }

            if (currentStatus === action) return createNotification('info', 'Acțiunea nu poate avea loc deoarece factura este deja marcată astfel.')
            
            // Get number and series of invoice
            const invoiceSeriesDB = 'series'
            const invoiceCurrentNumberDB ='currentNr'
            // series + number
            let invoiceSeries = action === '1' ? '' : currentSeries
            let invoiceNumber = action === '1' ? 0 : currentNr
            let invoiceManagerId = ''
            
            // only update number and series IF action is to set as paid AND was not paid already
            if (action === '1' && !wasPaid) {
                await db.collection('invoice_manager').get()
                .then(results => {
                    results.forEach(res => { 
                        invoiceSeries = res.data()[`${invoiceSeriesDB}`]
                        invoiceNumber = +res.data()[`${invoiceCurrentNumberDB}`]
                        invoiceManagerId = res.id
                    })
                })
            }

            // update invoice status
            let toUpdate = {
                type: action,
                status: action,
                paidDate: action === '1' ? new Date() : '',
                // update series Xand numberX
                series: invoiceSeries,
                // number: invoiceNumber,
                seriesNumber: `${invoiceSeries}${invoiceNumber}`,
                // update date month year for filtering purpose
                dayMonthYear: `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`
            }

            // only update timestamp if invoice is paid
            if (action === '1') toUpdate.timestamp = new Date()

            // set number of invoice counter in db
            if (action === '1' && !wasPaid) {
                await db.collection('invoice_manager').doc(invoiceManagerId)
                    .update({[`${invoiceCurrentNumberDB}`]: (invoiceNumber + 1)})
            }
            
            // update invoice action
            await db.collection('invoices').doc(id).update(toUpdate)
            createNotification('success', 'Factura a fost actualizată')

            // send email
            if (!sendMail) return
            createNotification('info', 'Se trimite email...')

            // get user email
            let uMail = ''
            await db.collection('user_info').where('uid', '==', userId).get()
            .then(results => {
                results.forEach(res => { 
                    uMail = res.data().email
                })
            })

            const emailData = {
                type: action, // tip factura
                subscription: subName,
                id: userSideId(subId),
                mail: uMail
            }
            // send email action
            await emailAction(emailData)

            // TODO send email to admin 

            createNotification('success', `Email trimis cu succes către ${uMail}`)
        }
        catch (err) {
            debugMsgLog(err)
            errorFirestoreLog(err, '271')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Toggles layout state and changes invoice to active if layout is activated
    //-----------------------------------------------------------------------------------------
    const toggleLayoutState = async (data, mode, currValue) => {
        try {
            const { id: givenId, type, updateInvoice, sendMail } = data
            
            if (givenId === '') {
                createNotification('error', 'ID invalid')
                return
            }

            // check if ID is user side
            const id = checkIfUserSideId(givenId) ? decodeUserSideId(givenId) : givenId

            let currentDoc = ''
            const isDoc = []
            let userId = ''
            let docNameId = ''
            // get doc by ID
            if (parseInt(type) === 0) {
                try {
                    let exists = false
                    await db.collection('subscriptions').doc(id).get()
                    .then(result => {
                        exists = result.exists
                        userId = result.data().uid
                        docNameId = result.data().name
                    })
                    if (!exists) {
                        createNotification('error',`Abonamentul cu ID ${id} nu există`)
                        return false
                    }
                    isDoc.push(id)
                }
                catch(err) { 
                    return false
                }
            }
            // get doc by user id
            else if (parseInt(type) === 1) {
                userId = id
                try {
                    await db.collection('subscriptions').where('uid', '==', id)
                    .orderBy('timeStamp', "desc").limit(1)
                    .get().then(results => {
                        results.forEach(res => {
                            isDoc.push(res.id)
                            docNameId = res.data().name
                        })
                    })
                }
                catch(err) { 
                    return false
                }
            }
            
            if (isDoc.length < 1) {
                createNotification('error', 'Abonament inexistent')
                return false
            }
            currentDoc = isDoc[0]
            if (currentDoc === '') {
                createNotification('error', 'Eroare la căutarea ID-ului abonamentului')
                return false
            }
            const key = mode === 0 ? 'valid' : 'manualDisable'
            
            await db.collection('subscriptions').doc(currentDoc).update({[`${key}`]: currValue})

            const successMessage = (docId = currentDoc) => createNotification('success', `Abonamentul cu ID ${docId} a fost modificat cu succes`)

            // update invoice ONLY if 'validate' or 'invalidate' mode is called
            if (!updateInvoice || (mode === 0 && !currValue) || (mode === 1 && !currValue)) return successMessage()
            let invoice_doc_id = ''
            let invoiceIsPaid = false
            let invoiceIsCanceled
            let invoiceRefNr = ''
            await db.collection('invoices').where('subscription.id', '==', currentDoc)
            .get().then(results => {
                results.forEach(res => {
                    invoice_doc_id = res.id
                    invoiceIsCanceled = res.data().status === '2'
                    invoiceIsPaid = !res.data().status === '0'
                    invoiceRefNr = res.data().seriesNumber
                })
            })
            if ((invoiceIsPaid && currValue) || (invoiceIsCanceled && currValue)) return createNotification('info', 'Factura nu s-a modificat deoarece este deja marcată astfel.')

            // Get number and series of invoice
            const invoiceSeriesDB = 'series'
            const invoiceCurrentNumberDB ='currentNr'
            // series + number
            let invoiceSeries = ''
            let invoiceNumber = 0
            let invoiceManagerId = ''
            let exists = false

            const getInvData = async () => {
                await db.collection('invoice_manager').get()
                .then(results => {
                    results.forEach(res => { 
                        invoiceSeries = res.data()[`${invoiceSeriesDB}`]
                        invoiceNumber = +res.data()[`${invoiceCurrentNumberDB}`]
                        invoiceManagerId = res.id
                    })
                })
                exists = false
                await db.collection('invoices')
                .where('seriesNumber', '==', `${invoiceSeries}${invoiceNumber}`)
                .get().then(results => {
                    results.forEach(res => { 
                        exists = true
                    })
                })
            }

            // check for invoice not to already exist
            const maxRetries = 5
            for (let i = 0; i < maxRetries; i++) {
                !exists && await getInvData()
                if (!exists) break
                await new Promise(r => setTimeout(r, 2000));
            }

            // update invoice status - 1 when setting to paid 2 when manualdisable is true
            const newStatus = (mode === 0 && currValue) ? '1' : '2'
            let toUpdate = {
                type: newStatus,
                status: newStatus,
            }

            // only update nr and series if paid
            if (mode === 0 && currValue) {
                toUpdate.timestamp = new Date()
                toUpdate.paidDate = new Date()
                toUpdate.series = invoiceSeries
                toUpdate.number = invoiceNumber
                toUpdate.seriesNumber = `${invoiceSeries}${invoiceNumber}`
                toUpdate.dayMonthYear = `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`
            }

            // set number of invoice counter in db
            await db.collection('invoice_manager').doc(invoiceManagerId)
                .update({[`${invoiceCurrentNumberDB}`]: (invoiceNumber + 1)})
            // update invoice action
            await db.collection('invoices').doc(invoice_doc_id).update(toUpdate)

            createNotification('success', `Factura cu ID ${invoice_doc_id} si numele de referinta: 
            [ vechi: ${invoiceRefNr} | nou: ${invoiceSeries}${invoiceNumber} ] a fost marcată ca și achitată.`)
            
            // send email
            if (!sendMail) return successMessage()

            // get user email
            let uMail = ''
            await db.collection('user_info').where('uid', '==', userId).get()
            .then(results => {
                results.forEach(res => { 
                    uMail = res.data().email
                })
            })

            const emailData = {
                type: newStatus, // Tip factura
                subscription: subscriptionNames(docNameId),
                id: userSideId(currentDoc),
                mail: uMail
            }
            // send email action
            await emailAction(emailData)

            createNotification('success', `Email trimis cu succes către ${uMail}`)
            successMessage()
        }
        catch(err) {
            errorFirestoreLog(err, '211')
            return false
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Get invoices between given dates
    //-----------------------------------------------------------------------------------------
    const fetchInvoicesByDates = async(dates) => {
        try {
            setLoadingAction(true)
            let invArray = []
            await db.collection('invoices')
            .where('timestamp', '>=', dates.from)
            .where('timestamp', '<=', dates.to)
            .get().then(results => {
                results.forEach(res => { 
                    const data = {...res.data(), id: res.id} 
                    invArray.push(data)
                })
            })
            const reversedArray = invArray.slice(0).reverse()
            setDisplayInvoices(reversedArray)
            setLoadingAction(false)
            return createNotification('info', `Au fost găsite ${invArray.length} facturi.`)
        }
        catch (err) {
            setLoadingAction(false)
            errorFirestoreLog(err, '281')
            return createNotification('error', 'A existat o eroare în încărcarea facturilor')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Gets invoice with coresponding series and number
    //-----------------------------------------------------------------------------------------
    const loadinvoiceWithSeriesNr = async (value) => {
        if (value === '') return createNotification('error', 'Valoare invalidă')
        try {
            setLoadingAction(true)
            let invArray = []
            await db.collection('invoices')
            .where('seriesNumber', '==', value)
            .get().then(results => {
                results.forEach(res => { 
                    const data = {...res.data(), id: res.id} 
                    invArray.push(data)
                })
            })
            setDisplayInvoices(invArray)
            setLoadingAction(false)
            const message = invArray.length > 0 ? 'A ' : 'Nu a'
            return createNotification('info', `${message} fost găsită factura ${value}.`)
        }
        catch (err) {
            setLoadingAction(false)
            errorFirestoreLog(err, '301')
            return createNotification('error', 'A existat o eroare în încărcarea facturii')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Cancels subscription. Only cancel last sub if not paid yet
    //-----------------------------------------------------------------------------------------
    const cancelLastSubscription = async (data) => {
        try {
            const { id:givenId, sendMail } = data

            if (givenId === '') {
                createNotification('error', 'ID invalid')
                return
            }

            // check if ID is user side
            const id = checkIfUserSideId(givenId) ? decodeUserSideId(givenId) : givenId
            
            // get user's subscriptions
            let sub = {}
            let subs = []
            await db.collection('subscriptions').where('uid', '==', id)
            .orderBy('timeStamp', "desc")
            .get().then(results => {
                results.forEach(res => {
                    sub = {...res.data(), id: res.id} 
                    subs.push(sub)
                })
            })
            
            if (subs.length === 0) return createNotification('error', 'Utilizatorul nu are abonamente')
            // check if invoice is paid
            let isPaid = false
            let isCanceled = false
            let invoice_doc_id = ''
            await db.collection('invoices').where('subscription.id', '==', subs[0].id)
            .get().then(results => {
                results.forEach(res => {
                    isPaid = (res.data().status === '1' && res.data().type === '1')
                    isCanceled = res.data().status === '2'
                    invoice_doc_id = res.id
                })
            })
            
            if (invoice_doc_id === '') return createNotification('error', 'Nu a fost găsită o factură pentru acest abonament. Dezactivați doar abonamentul')
            if (isPaid) return createNotification('error', 'Abonamentul nu se poate dezactiva deoarece a fost achitat')
            if (isCanceled) return createNotification('error', 'Abonamentul este deja anulat')

            // update subscription to canceled
            await db.collection('subscriptions').doc(subs[0].id).update({ manualDisable: true })
            createNotification('info', `Abonamentul cu id ${subs[0].id} a fost dezactivat`)

            // update invoice
            await db.collection('invoices').doc(invoice_doc_id).update({ status: '2' })
            createNotification('info', `Factura cu id ${invoice_doc_id} a fost marcată ca și anulată`)

            // search if old sub needs reactivation
            let idToReactivate = ''
            for (const s of subs) {
                if (s.valid && s.manualDisable) {
                    idToReactivate = s.id
                    break
                }
            }

            if (idToReactivate === '') return createNotification('info', 'Nu au existat abonamente vechi valide de reactivat')
            // update subscription to reactivate
            await db.collection('subscriptions').doc(idToReactivate).update({ manualDisable: false })
            createNotification('info', `Abonamentul cu id ${idToReactivate} a fost reactivat`)
            
            // send email
            if (!sendMail) return createNotification('success', 'Acțiune încheiată cu succes')

            // get user email
            let uMail = ''
            await db.collection('user_info').where('uid', '==', id).get()
            .then(results => {
                results.forEach(res => { 
                    uMail = res.data().email
                })
            })
            
            const emailData = {
                type: '2', // Tip factura
                subscription: subscriptionNames(subs[0].name),
                id: userSideId(subs[0].id),
                mail: uMail
            }
            // send email action
            const emailSent = await emailAction(emailData)
            emailSent && createNotification('info', `Email trimis către ${uMail}`)

            return createNotification('success', 'Acțiune încheiată cu succes')
        }
        catch(err) {
            errorFirestoreLog(err, '291')
            return createNotification('error', 'A existat o eroare în anularea abonamentului: '+err)
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Get unreplied contact emails
    //-----------------------------------------------------------------------------------------
    const fetchUnrepliedContacts = async () => {
        if (loadingAction) return;
        setLoadingAction(true);
        try {
            const contactResult = [];
            await db.collection('contact_form').where('reply', '==', false)
            .orderBy('timeStamp', "desc").get().then(results => {
                results.forEach(res => contactResult.push({...res.data(), infoId: res.id}));
            })
            setContactEmails(contactResult);
        }
        catch(err) {
            errorFirestoreLog(err, '311')
            return createNotification('error', 'A existat o eroare în încărcarea datelor: '+err);
        }
        finally {
            setLoadingAction(false);
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Add new admin
    //-----------------------------------------------------------------------------------------
    // const addNewAdmin = async (id, lvl) => {
    //     if (loadingAction) return;
    //     setLoadingAction(true);
    //     try {
    //         db.collection('user_info').doc(userData.data.infoId).update({ admin: true, accessLevel: lvl });
    //         const customClaims = {
    //             admin: true,
    //             adminLvl: lvl
    //         };
    //         auth.createCustomToken(id, customClaims)
    //         .then((token) => console.log(token));
    //     }
    //     catch(err) {
    //         // errorFirestoreLog(err, '321')
    //         console.log(err)
    //         return createNotification('error', 'A existat o eroare în anularea încărcarea datelor: '+err);
    //     }
    //     finally {
    //         setLoadingAction(false);
    //     }
    // }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Reply to contact message
    //-----------------------------------------------------------------------------------------
    const replyToContactMessage = async (data) => {
        if (loadingAction) return;
        setLoadingAction(true);
        try {
            db.collection('contact_form').doc(data.infoId).update({ reply: true });
            setContactEmails(prev => (prev.filter(msg => msg.infoId !== data.infoId)));
            const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
            const emailData = {
                type: '5', // Contact reply email
                ...data,
                message: data.message.replace(scriptRegex, ''), // sanitize
                mail: data.fromMail, // overwriting "mail"
                timeToDisplay: `${toFormatDate(getDateFromTimestamp(data.timeStamp))} - ${toFormatTime(getDateFromTimestamp(data.timeStamp))}`
            }
            await emailAction(emailData);
            return createNotification('success', 'Răspunsul a fost trimis');
        }
        catch(err) {
            errorFirestoreLog(err, '331')
            return createNotification('error', 'A existat o eroare în trimiterea mesajului: '+err);
        }
        finally {
            setLoadingAction(false);
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Load users
    //-----------------------------------------------------------------------------------------
    const loadUsersAPI = async (limit = 1000) => {
        if (loadingAction) return;
        setLoadingAction(true);
        
        try {
            await currentUser.getIdToken(true)
            .then((idToken) => {
              return fetch(`${API_URL}${GET_USERS_URL}/${ADMIN_KEY}/${idToken}/${limit}`);
            })
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .then((users) => {
                // Sort by last signin
                const sortedUsers = users.sort((a, b) => {
                    const timestampA = new Date(a.metadata.lastSignInTime).getTime();
                    const timestampB = new Date(b.metadata.lastSignInTime).getTime();
                    return timestampB - timestampA;
                })
              setUserAccounts(sortedUsers);
              return true;
            })
            .catch((error) => {
              console.error(error);
              setLoadingAction(false);
              return createNotification('error', '(1)An error occurred while loading users: ' + error.message);
            });
        } catch (err) {
          console.error(err);
          return createNotification('error', '(2)An error occurred while loading users: ' + err.message);
        } finally {
          setLoadingAction(false);
        }
    };

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Load users
    //-----------------------------------------------------------------------------------------
    const adminImpersonate = async (idToImpersonate) => {
        if (loadingAction) return;
        setLoadingAction(true);
        
        try {
            await currentUser.getIdToken(true)
            .then((idToken) => {
                return fetch(`${API_URL}${IMPERSONATE_USERS_URL}/${ADMIN_KEY}/${idToken}/${idToImpersonate}`);
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((user) => {
                getFirstData(user, true);
                return true;
            })
            .catch((error) => {
                console.error(error);
                setLoadingAction(false);
                return createNotification('error', '(1)An error occurred while impersonating user: ' + error.message);
            });
        } catch (err) {
            console.error(err);
            return createNotification('error', '(2)An error occurred while impersonating user: ' + err.message);
        } finally {
            setLoadingAction(false);
        }
    };

    // HELPERS
    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Check if user is authorised
    //-----------------------------------------------------------------------------------------
    const checkHelperAuthorisation = async () => {
        if (!currentUser || !currentUser.getIdToken) return false;

        setLoadingAction(true);

        try {
            const token = await currentUser.getIdToken(true);
            const response = await fetch(`${API_URL}${HELPERS_CHECK_URL}/${ADMIN_KEY}/${token}`);

            if (!response.ok || response.status !== 200) return false;
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
        finally {
            setLoadingAction(false);
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Get cars data
    //-----------------------------------------------------------------------------------------
    const getCarsData = async () => {
        if (!currentUser || !currentUser.getIdToken) return false;

        setLoadingAction(true);

        try {
            const response = await fetch(`${API_URL}${CARS_URL}/${ADMIN_KEY}`);

            if (!response.ok || response.status !== 200) return [];
            return response.json();
        }
        catch (err) {
            console.error(err);
            return [];
        }
        finally {
            setLoadingAction(false);
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Send new car data
    //-----------------------------------------------------------------------------------------
    const newCarData = async (newCar) => {
        if (loadingAction || !currentUser) return false;

        setLoadingAction(true);
        createNotification("info", "Se încarcă, așteptați..");

        try {
            const response = await fetch(`${API_URL}${NEW_CAR_URL}/${ADMIN_KEY}/${encodeURIComponent(newCar.id)}/${encodeURIComponent(newCar.name)}/${encodeURIComponent(newCar.weight)}/${encodeURIComponent(newCar.type)}/${encodeURIComponent(newCar.CN)}/${encodeURIComponent(newCar.fuel)}/${encodeURIComponent(newCar.engine_capacity)}/${encodeURIComponent(newCar.date)}/${encodeURIComponent(newCar.added_by)}`);

            if (response.status !== 200) {
                createNotification("error", "Au existat erori.");
                return false;
            }

            const message = await response.json();
            
            if (!message.success) {
                createNotification("error", "Au existat erori.");
                return false;
            }

            createNotification("success", `${newCar.name} introdus cu succes!`);
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
        finally {
            setLoadingAction(false);
        }
    }

    // END ADMIN

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Exports all information for Auth consumers
    //-----------------------------------------------------------------------------------------
    const value = {
        currentUser,
        userData,
        layouts,
        companies,
        currentRates,
        invoiceData,
        hasUnpaid,
        taxData,
        displayInvoices,
        fetchInvoiceData,
        fetchTaxData,
        signup,
        login,
        logout,
        verifyEmail,
        resetPassword,
        updateProfile,
        updateUserCompany,
        newLayout,
        updateLayout,
        deleteLayout,
        newCompany,
        updateCompany,
        deleteCompany,
        getExchangeRates,
        updateUserLines,
        newSubscription,
        sendContact,
        // without user signed in
        checkIfCompany,
        // stats
        newStatsGeneratedXml,
        // History
        fetchGeneratedHistory,
        generatedHistory,
        addInGeneratedHistory,
        // admin
        createNewSubscription,
        toggleLayoutState,
        toggleInvoiceState,
        fetchInvoicesByDates,
        loadinvoiceWithSeriesNr,
        cancelLastSubscription,
        fetchUnrepliedContacts,
        contactEmails,
        replyToContactMessage,
        loadUsersAPI,
        userAccounts,
        adminImpersonate,
        checkHelperAuthorisation,
        getCarsData,
        newCarData,
        // addNewAdmin,
        // loading
        loading, // loading main data
        loadingAction // loading event data
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}