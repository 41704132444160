///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState } from 'react'

import { useAuth } from '../../context/AuthContext';
import { cities } from '../../databases/RoCities';
import { validateVATro } from '../../services/ValidationService';

import ReactTooltip from 'react-tooltip';
import { createNotification } from "../../services/NotificationService"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faX } from '@fortawesome/free-solid-svg-icons'

const Companies = () => {

    const emptyObj = {
        name: '',
        cif: '',
        city: '',
        active: true
    }
    const [openForm, setOpenForm] = useState(false)
    const [company, setCompany] = useState(emptyObj)
    const [toUpdate, setToUpdate] = useState(false)
    const [updateId, setUpdateId] = useState('')

    const {
        currentUser,
        userData,
        companies,
        newCompany,
        updateCompany,
        deleteCompany
    } = useAuth()

    const openSection = (toggle, edit, id) =>  {
        if(edit) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            for(const comp of companies) {
                if(id === comp.dataId) {
                    setOpenForm(true)
                    setCompany(comp)
                    setToUpdate(true)
                    setUpdateId(id)
                    return
                }
            }
        }

        if(companies.length >= parseInt(userData.subscriptions[0].compAvailable)) {
            setOpenForm(false)
            return createNotification('error', `Ați atins numărul maxim de firme disponibile pentrul abonamnetul actual.`)
        }

        if(toggle) {
            setToUpdate(false)
            setCompany(emptyObj)
            return setOpenForm(!openForm)
        }
        setOpenForm(true)
    }

    const searchCity = () => {
        if(company.city === '') return cities.sort()
        else return cities.filter(city => city.toUpperCase().includes(company.city.toUpperCase())).sort()
    }

    const discard = () => {
        setToUpdate(false)
        setOpenForm(false)
        setCompany(emptyObj)
    }

    const checkComp = (k, v) => {
        for(const comp of companies) {
            if(comp[k] === v) return false
        }
        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!toUpdate) {
            if(!checkComp('name', company.name)) {
                return createNotification('error', `Frima cu numele ${company.name} există deja`)
            }

            if(!checkComp('cif', company.cif)) {
                return createNotification('error', `Frima cu CIF ${company.cif} există deja`)
            }
        }

        if(!validateVATro(company.cif)) {
            return createNotification('error', `CIF invalid`)
        }

        if(!cities.includes(company.city)) {
            return createNotification('error', `Oraș invalid`)
        }

        const toSave = {...company, uid: currentUser.uid}

        setOpenForm(false)
        toUpdate ? await updateCompany(updateId, toSave) : await newCompany(toSave)
        setCompany(emptyObj)

        setToUpdate(false)
    }

    const deleteComp = async (id) => {
        setOpenForm(false)
        await deleteCompany(id)
        setToUpdate(false)
        setCompany(emptyObj)
    }

    return (
        <div className='app-default-wrapper'>
            <div className='app-default-section'>
                <div className='app-default-header'>
                    <div onClick={() => openSection(true)}
                    className='button-secondary btn-green'>Firmă nouă</div>
                </div>

                {
                openForm &&
                <form className='login-form' onSubmit={(e) => handleSubmit(e)}>
                    <p>Denumire</p>
                    <input value={company.name} 
                    onChange={(e) => setCompany(prev => ({...prev, name: e.target.value}))}
                    className='input-text' type='text' id='nume' name='nume' maxLength='100' required/>

                    <p>CIF</p>
                    <input value={company.cif} 
                    onChange={(e) => setCompany(prev => ({...prev, cif: e.target.value}))}
                    className='input-text' type='text' placeholder='RO1234567890' ame='cif' id='cif' maxLength='20' required/>

                    <p>Oraș</p>
                    <input className='input-text dropdown-search' value={company.city} title='Oraș' 
                        name='oras' id='oras' type='text' 
                        onChange={(e) => setCompany(prev => ({...prev, city: e.target.value}))} required/>
                    <div className='dropdown-wrapper'>
                        {searchCity().map((city, index) => 
                        <p key={`${index}-${city}`} 
                        onClick={() => setCompany(prev => ({...prev, city: city}))}>{city}</p>
                        )}
                    </div>

                    <input type='submit' className='button-secondary btn-green' value='Salvează' />
                    <div onClick={() => discard()} className='button-secondary'>Anulează</div>
                </form>
                }

                <table className='simple-table'>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Denumire</th>
                            <th>CIF</th>
                            <th>Oraș</th>
                            <th>Acțiuni</th>
                        </tr>
                    </thead>
                    {companies.length > 0 ? companies.map((comp, index) => {
                        return (
                        <tbody key={`${comp.dataId} - ${comp.name}`}>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{comp.name}</td>
                                <td>{comp.cif}</td>
                                <td>{comp.city}</td>
                                {index > 0 && 
                                <td>
                                    <div className='edit-buttons'>
                                        <span onClick={() => openSection(false, true, comp.dataId)}
                                        data-tip data-for="edit-tt">
                                        <FontAwesomeIcon style={{color:"green"}} icon={faPenToSquare}/>
                                        </span>
                                        <span onClick={() => deleteComp(comp.dataId)}
                                        data-tip data-for="delete-tt">
                                        <FontAwesomeIcon style={{color:"red"}} icon={faX}/>
                                        </span>
                                    </div>
                                </td>}
                            </tr>
                        </tbody>
                        )
                    }) :
                    <tbody>
                        <tr>
                            <td colSpan={5}>Nici o machetă disponibilă <b>
                                <span className='link'>creați una</span></b>
                            </td>
                        </tr>
                    </tbody>
                    }
                </table>
                <small style={{textAlign:'left'}}>Număr firme: <b>{companies.length}</b> /
                {userData.subscriptions && parseInt(userData.subscriptions[0].compAvailable)}</small>
            </div>
            <ReactTooltip effect="solid" id='edit-tt' type='success'>Editează</ReactTooltip>
            <ReactTooltip effect="solid" id='delete-tt' type='error'>Sterge</ReactTooltip>
        </div>
    )
}

export default Companies