///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext';
import { cities, counties } from '../../databases/RoCities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { validateRegCom, validateVATro } from '../../services/ValidationService';
import { createNotification } from '../../services/NotificationService';

const Profile = () => {
  const { 
    currentUser, 
    userData, 
    updateProfile,
    updateUserCompany
  } = useAuth()

  const [loading, setLoading] = useState(false)
  const [personal, setPersonal] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: ''
  })
  const [personalComp, setPersonalComp] = useState(personal)
  const [canEditPers, setCanEditPers] = useState(false);

  const [company, setCompany] = useState({
    name: '',
    cui: '',
    regCom: '',
    city: '',
    county: '',
    address: ''
  })
  const [companyComp, setCompanyComp] = useState(company)
  const [canEditComp, setCanEditComp] = useState(false);

  useEffect(() => {
    // Personal data
    const splitName = currentUser.displayName.trim().split(/\s+/)
    const fName = splitName.map((curr, index) => {
        return (index < splitName.length - 1) ? curr : ''
    }).join(' ').trim()
    const lName = splitName[splitName.length - 1]
    const baseObj = {
        name: fName,
        lastName: lName,
        email: currentUser.email,
        phone: userData.data.phone
    }
    setPersonalComp(baseObj)
    setPersonal(baseObj)

    // Company data
    const baseCompObj = {
        name: userData.data.company,
        cui: userData.data.cui,
        regCom: userData.data.regCom,
        city: userData.data.city,
        county: userData.data.county,
        address: userData.data.address
    }
    setCompanyComp(baseCompObj)
    setCompany(baseCompObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(JSON.stringify(personal) === JSON.stringify(personalComp)) {
        setCanEditPers(false)
    }
    else {
        setCanEditPers(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personal, personalComp])

  useEffect(() => {
    if(JSON.stringify(company) === JSON.stringify(companyComp)) {
        setCanEditComp(false)
    }
    else {
        setCanEditComp(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, companyComp])

  const submitProfileForm = async (e) => {
    e.preventDefault()
    setLoading(true)

    const noError = await updateProfile(`${personal.name} ${personal.lastName}`, personal.email, personal.phone);

    noError && setPersonalComp(personal)

    setLoading(false)
  }

  const searchCity = () => {
    if(company.city === '') return cities.sort()
    else return cities.filter(city => city.toUpperCase().includes(company.city.toUpperCase())).sort()
  }

  const searchCounty = () => {
    if(company.county === '') return counties.sort()
    else return counties.filter(county => county.toUpperCase().includes(company.county.toUpperCase())).sort()
  }

  const submitCompanyForm = async (e) => {
    e.preventDefault()
    setLoading(true)

    if(!validateRegCom(company.regCom)) {
        setLoading(false)
        return createNotification('error', `Reg com este invalid`)
    }

    if(!validateVATro(company.cui)){
        setLoading(false)
        return createNotification('error', `Codul fiscal este invalid`)
    }

    const noError = await updateUserCompany(company.name, company.cui, company.regCom, company.city, company.county, company.address)

    noError && setCompanyComp(company)

    setLoading(false)
  }
  
  return (
    <div className='profile-wrapper'>
        <div className='profile-section'>
            <form className='login-form' onSubmit={(e) => submitProfileForm(e)}>
                <h2>Date personale</h2>
                <div><b>Username: </b>{`${personal.name} ${personal.lastName}`}</div>
                <p>Nume</p>
                <input type='text' className='input-text' id='name' value={personal.name} 
                onChange={(e) => setPersonal(prev => ({...prev, name: e.target.value}))} required/>
                <p>Prenume</p>
                <input type='text' className='input-text' id='famName' value={personal.lastName} 
                onChange={(e) => setPersonal(prev => ({...prev, lastName: e.target.value}))} required/>
                <p>Email</p>
                <input type='text' disabled={true} className='input-text' id='email' value={personal.email} 
                onChange={(e) => setPersonal(prev => ({...prev, email: e.target.value}))} 
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
                <p>Telefon</p>
                <input type='text' className='input-text' id='phone' value={personal.phone} 
                onChange={(e) => setPersonal(prev => ({...prev, phone: e.target.value}))} 
                pattern="[0-9]{10,12}" required/>

                {loading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 
                <input disabled={!canEditPers} className='input-submit' type="submit" value='Salvează' />
                }
            </form>
        </div>

        <div className='profile-section'>
            <form className='login-form' onSubmit={(e) => submitCompanyForm(e)}>
                <h2>Date companie</h2>
                <div><b>{`${company.name} - ${company.cui}`}</b></div>
                <p>Nume</p>
                <input type='text' className='input-text' id='name' value={company.name} 
                onChange={(e) => setCompany(prev => ({...prev, name: e.target.value}))} required/>
                <p>Cod fiscal</p>
                <input type='text' disabled={true} className='input-text' id='cif' value={company.cui} 
                onChange={(e) => setCompany(prev => ({...prev, cui: e.target.value}))} required/>
                <p>Reg com</p>
                <input type='text' disabled={true} className='input-text' id='regCom' value={company.regCom}
                onChange={(e) => setCompany(prev => ({...prev, regCom: e.target.value}))} 
                required/>
                
                <p>Județ</p>
                <input className='input-text dropdown-search' value={company.county} title='Județ' 
                  name='judet' id='judet' type='text' 
                  onChange={e => setCompany(prev => ({...prev, county: e.target.value}))} required/>
                <div className='dropdown-wrapper'>
                  {searchCounty().map((county, index) => 
                    <p key={`${index}-${county}`} 
                    onClick={() => setCompany(prev => ({...prev, county: county}))}>{county}</p>
                  )}
                </div>

                <p>Oraș</p>
                <input className='input-text dropdown-search' value={company.city} title='Oraș' 
                    name='oras' id='oras' type='text' 
                    onChange={(e) => setCompany(prev => ({...prev, city: e.target.value}))} required/>
                <div className='dropdown-wrapper'>
                    {searchCity().map((city, index) => 
                    <p key={`${index}-${city}`} 
                    onClick={() => setCompany(prev => ({...prev, city: city}))}>{city}</p>
                    )}
                </div>

                <p>Adresă</p>
                <input type='text' className='input-text' id='address' value={company.address}
                onChange={(e) => setCompany(prev => ({...prev, address: e.target.value}))} 
                required/>

                {loading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 
                <input disabled={!canEditComp} className='input-submit' type="submit" value='Salvează' />
                }
            </form>
        </div>
    </div>
  )
}

export default Profile