///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const countryCodes = [
'AD',
'AE',
'AF',
'AG',
'AI',
'AL',
'AM',
'AO',
'AQ',
'AR',
'AS',
'AT',
'AU',
'AW',
'AZ',
'BA',
'BB',
'BD',
'BE',
'BF',
'BG',
'BH',
'BI',
'BJ',
'BL',
'BM',
'BN',
'BO',
'BQ',
'BR',
'BS',
'BT',
'BV',
'BW',
'BY',
'BZ',
'CA',
'CC',
'CD',
'CF',
'CG',
'CH',
'CI',
'CK',
'CL',
'CM',
'CN',
'CO',
'CR',
'CU',
'CV',
'CW',
'CX',
'CY',
'CZ',
'DE',
'DJ',
'DK',
'DM',
'DO',
'DZ',
'EC',
'EE',
'EG',
'EH',
'ER',
'ES',
'ET',
'FI',
'FJ',
'FK',
'FM',
'FO',
'FR',
'GA',
// 'GB',
'XI',
'XU',
'GD',
'GE',
'GH',
'GI',
'GL',
'GM',
'GN',
'GQ',
'GR',
'GS',
'GT',
'GU',
'GW',
'GY',
'HK',
'HM',
'HN',
'HR',
'HT',
'HU',
'ID',
'IE',
'IL',
'IN',
'IO',
'IQ',
'IR',
'IS',
'IT',
'JM',
'JO',
'JP',
'KE',
'KG',
'KH',
'KI',
'KM',
'KN',
'KP',
'KR',
'KW',
'KY',
'KZ',
'LA',
'LB',
'LC',
'LI',
'LK',
'LR',
'LS',
'LT',
'LU',
'LV',
'LY',
'MA',
'MD',
'ME',
'MG',
'MH',
'MK',
'ML',
'MM',
'MN',
'MO',
'MP',
'MR',
'MS',
'MT',
'MU',
'MV',
'MW',
'MX',
'MY',
'MZ',
'NA',
'NC',
'NE',
'NF',
'NG',
'NI',
'NL',
'NO',
'NP',
'NR',
'NU',
'NZ',
'OM',
'PA',
'PE',
'PF',
'PG',
'PH',
'PK',
'PL',
'PM',
'PN',
'PS',
'PT',
'PW',
'PY',
'QA',
'RO',
'RU',
'RW',
'SA',
'SB',
'SC',
'SD',
'SE',
'SG',
'SH',
'SI',
'SK',
'SL',
'SM',
'SN',
'SO',
'SR',
'SS',
'ST',
'SV',
'SX',
'SY',
'SZ',
'TC',
'TD',
'TF',
'TG',
'TH',
'TJ',
'TK',
'TL',
'TM',
'TN',
'TO',
'TR',
'TT',
'TV',
'TW',
'TZ',
'UA',
'UG',
'UM',
'US',
'UY',
'UZ',
'VA',
'VC',
'VE',
'VG',
'VI',
'VN',
'VU',
'WF',
'WS',
'XC',
'XK',
'XL',
'XS',
'YE',
'ZA',
'ZM',
'ZW',
'QP',
'QR',
'QS',
'QV',
'QW',
'QX',
];

export const countryNames = [
'Andorra',
'Emiratele Arabe Unite',
'Afganistan',
'Antigua şi Barbuda ',
'Anguilla ',
'Albania ',
'Armenia ',
'Angola ',
'Antarctica ',
'Argentina ',
'Samoa Americană ',
'Austria ',
'Australia ',
'Aruba ',
'Azerbaidjan ',
'Bosnia şi Herţegovina ',
'Barbados ',
'Bangladesh ',
'Belgia ',
'Burkina Faso ',
'Bulgaria ',
'Bahrain ',
'Burundi ',
'Benin ',
'Saint-Barthélemy ',
'Bermuda ',
'Brunei Darussalam ',
'Statul Plurinaţional al Boliviei ',
'Bonaire, Sint Eustatius şi Saba ',
'Brazilia ',
'Bahamas ',
'Bhutan ',
'Insula Bouvet ',
'Botswana ',
'Belarus ',
'Belize ',
'Canada ',
'Insulele Cocos (sau Insulele Keeling) ',
'Republica Democratică Congo ',
'Republica Centrafricană ',
'Congo ',
'Elveţia ',
'Coasta de Fildeş',
'Insulele Cook ',
'Chile ',
'Camerun ',
'China ',
'Columbia ',
'Costa Rica ',
'Cuba ',
'Capul Verde ',
'Curaçao ',
'Insula Christmas ',
'Cipru ',
'Cehia',
'Germania ',
'Djibouti ',
'Danemarca ',
'Dominica ',
'Republica Dominicană ',
'Algeria ',
'Ecuador ',
'Estonia ',
'Egipt ',
'Sahara Occidentală ',
'Eritreea ',
'Spania',
'Etiopia ',
'Finlanda ',
'Fiji ',
'Insulele Falkland ',
'Statele Federate ale Microneziei ',
'Insulele Feroe ',
'Franţa ',
'Gabon ',
'Regatul Unit',
'Regatul Unit (Irlanda de Nord)',
'Regatul Unit (exclusiv Irlanda de Nord)',
'Grenada ',
'Georgia ',
'Ghana ',
'Gibraltar ',
'Groenlanda ',
'Gambia ',
'Guineea ',
'Guineea Ecuatorială ',
'Grecia ',
'Georgia de Sud şi Insulele Sandwich de Sud ',
'Guatemala ',
'Guam ',
'Guineea-Bissau ',
'Guyana ',
'Hong Kong ',
'Insula Heard şi Insulele McDonald ',
'Honduras ',
'Croaţia ',
'Haiti ',
'Ungaria ',
'Indonezia ',
'Irlanda ',
'Israel ',
'India ',
'Teritoriul Britanic din Oceanul Indian ',
'Irak ',
'Republica Islamică Iran ',
'Islanda ',
'Italia ',
'Jamaica ',
'Iordania ',
'Japonia ',
'Kenya ',
'Kârgâzstan ',
'Cambodgia ',
'Kiribati ',
'Comore ',
'Saint Kitts şi Nevis ',
'Coreea de Nord',
'Coreea de Sud',
'Kuweit ',
'Insulele Cayman ',
'Kazahstan ',
'Republica Democrată Populară Laos ',
'Liban ',
'Saint Lucia ',
'Liechtenstein ',
'Sri Lanka ',
'Liberia ',
'Lesotho ',
'Lituania ',
'Luxemburg ',
'Letonia ',
'Libia ',
'Maroc ',
'Republica Moldova ',
'Muntenegru ',
'Madagascar ',
'Insulele Marshall ',
'Macedonia de Nord',
'Mali ',
'Myanmar ',
'Mongolia ',
'Macao ',
'Insulele Mariane de Nord ',
'Mauritania ',
'Montserrat ',
'Malta ',
'Mauritius ',
'Maldive ',
'Malawi ',
'Mexic ',
'Malaezia',
'Mozambic ',
'Namibia ',
'Noua Caledonie ',
'Niger ',
'Insula Norfolk ',
'Nigeria ',
'Nicaragua ',
'Țările de Jos (Olanda)',
'Norvegia ',
'Nepal',
'Nauru ',
'Niue ',
'Noua Zeelandă ',
'Oman ',
'Panama ',
'Peru ',
'Polinezia Franceză ',
'Papua-Noua Guinee ',
'Filipine ',
'Pakistan ',
'Polonia ',
'Saint-Pierre şi Miquelon ',
'Pitcairn ',
'Teritoriile Palestiniene Ocupate',
'Portugalia ',
'Palau ',
'Paraguay ',
'Qatar ',
'România ',
'Federaţia Rusă ',
'Rwanda ',
'Arabia Saudită ',
'Insulele Solomon ',
'Seychelles ',
'Sudan ',
'Suedia ',
'Singapore ',
'Sfânta Elena, Ascension şi Tristan da Cunha ',
'Slovenia ',
'Slovacia ',
'Sierra Leone ',
'San Marino ',
'Senegal ',
'Somalia ',
'Suriname ',
'Sudanul de Sud ',
'São Tomé şi Principe ',
'Salvador ',
'Sint-Maarten (partea neerlandeză) ',
'Republica Arabă Siriană ',
'Eswatini',
'Insulele Turks şi Caicos ',
'Ciad ',
'Teritoriile Australe Franceze ',
'Togo ',
'Thailanda ',
'Tadjikistan ',
'Tokelau ',
'Timorul de Est ',
'Turkmenistan ',
'Tunisia ',
'Tonga ',
'Turcia ',
'Trinidad şi Tobago ',
'Tuvalu ',
'Taiwan ',
'Republica Unită Tanzania ',
'Ucraina',
'Uganda ',
'Insulele Minore Îndepărtate ale Statelor Unite ale Americii',
'Statele Unite ',
'Uruguay ',
'Uzbekistan',
'Sfântul Scaun',
'Saint Vincent şi Grenadine ',
'Republica Bolivariană a Venezuelei ',
'Insulele Virgine Britanice',
'Insulele Virgine Americane ',
'Vietnam',
'Vanuatu',
'Wallis şi Futuna ',
'Samoa ',
'Ceuta ',
'Kosovo ',
'Melilla ',
'Serbia  ',
'Yemen  ',
'Africa de Sud ',
'Zambia ',
'Zimbabwe ',
'Marea liberă',
'Stocuri şi provizii în cadrul comerţului intra-UE',
'Stocuri şi provizii în cadrul comerţului extra-UE',
'Ţări şi teritorii nespecificate în cadrul comerţului intra-UE',
'Ţări şi teritorii nespecificate în cadrul comerțului extra-UE',
'Ţări şi teritorii nespecificate din motive comerciale sau militare ',
];

// https://www.freeformatter.com/xml-to-json-converter.html
// EU

export const EuCountries = {
    "Id": "EU_COUNTRY",
    "OrderNr": "621",
    "NameEn": "EU Countries",
    "NameRo": "EU Countries",
    "Version": "2021",
    "Item": [
      {
        "Code": "AT",
        "NameEn": "Austria",
        "NameRo": "Austria",
        "Level": "1"
      },
      {
        "Code": "BE",
        "NameEn": "Belgium",
        "NameRo": "Belgia",
        "Level": "1"
      },
      {
        "Code": "BG",
        "NameEn": "Bulgaria",
        "NameRo": "Bulgaria",
        "Level": "1"
      },
      {
        "Code": "CY",
        "NameEn": "Cyprus",
        "NameRo": "Cipru",
        "Level": "1"
      },
      {
        "Code": "HR",
        "NameEn": "Croatia",
        "NameRo": "Croatia",
        "Level": "1"
      },
      {
        "Code": "DK",
        "NameEn": "Denmark",
        "NameRo": "Danemarca",
        "Level": "1"
      },
      {
        "Code": "EE",
        "NameEn": "Estonia",
        "NameRo": "Estonia",
        "Level": "1"
      },
      {
        "Code": "FI",
        "NameEn": "Finland",
        "NameRo": "Finlanda",
        "Level": "1"
      },
      {
        "Code": "FR",
        "NameEn": "France",
        "NameRo": "Franta",
        "Level": "1"
      },
      {
        "Code": "DE",
        "NameEn": "Germany",
        "NameRo": "Germania",
        "Level": "1"
      },
      {
        "Code": "GR",
        "NameEn": "Greece",
        "NameRo": "Grecia",
        "Level": "1"
      },
      {
        "Code": "IE",
        "NameEn": "Ireland",
        "NameRo": "Irlanda",
        "Level": "1"
      },
      {
        "Code": "IT",
        "NameEn": "Italy",
        "NameRo": "Italia",
        "Level": "1"
      },
      {
        "Code": "LV",
        "NameEn": "Latvia",
        "NameRo": "Letonia",
        "Level": "1"
      },
      {
        "Code": "LT",
        "NameEn": "Lithuania",
        "NameRo": "Lituania",
        "Level": "1"
      },
      {
        "Code": "LU",
        "NameEn": "Luxembourg",
        "NameRo": "Luxemburg",
        "Level": "1"
      },
      {
        "Code": "MT",
        "NameEn": "Malta",
        "NameRo": "Malta",
        "Level": "1"
      },
      {
        "Code": "NL",
        "NameEn": "Netherlands",
        "NameRo": "Olanda",
        "Level": "1"
      },
      {
        "Code": "PL",
        "NameEn": "Poland",
        "NameRo": "Polonia",
        "Level": "1"
      },
      {
        "Code": "PT",
        "NameEn": "Portugal",
        "NameRo": "Portugalia",
        "Level": "1"
      },
      {
        "Code": "XI",
        "NameEn": "The Northern Ireland - United Kingdom",
        "NameRo": "Irlanda de Nord - Regatul Unit",
        "Level": "1"
      },
      {
        "Code": "CZ",
        "NameEn": "Czech Republic",
        "NameRo": "Republica Ceha",
        "Level": "1"
      },
      {
        "Code": "SK",
        "NameEn": "Slovakia",
        "NameRo": "Slovacia",
        "Level": "1"
      },
      {
        "Code": "SI",
        "NameEn": "Slovenia",
        "NameRo": "Slovenia",
        "Level": "1"
      },
      {
        "Code": "ES",
        "NameEn": "Spain",
        "NameRo": "Spania",
        "Level": "1"
      },
      {
        "Code": "SE",
        "NameEn": "Sweden",
        "NameRo": "Suedia",
        "Level": "1"
      },
      {
        "Code": "HU",
        "NameEn": "Hungary",
        "NameRo": "Ungaria",
        "Level": "1"
      },
      {
        "Code": "QV",
        "NameEn": "Unknown countries",
        "NameRo": "vTara necunoscuta",
        "Level": "1"
      }
    ]
  }