///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, January 2023
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { createNotification } from '../services/NotificationService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/AuthContext'
import { checkCookie, CONTACT_FORM_COOKIE, setMinuteCookie } from '../services/CookieService'
import { bigID } from '../services/IdService'

const Contact = () => {
    const WORD_SIZE = 5

    const { sendContact } = useAuth()

    const [noBotText, setNoBotText] = useState('x1')
    const [loading, setLoading] = useState(false)
    const [randomId, setRandomId] = useState(bigID())

    const changeNoBotText = () => {
        const newId = bigID()
        setRandomId(newId)
        const result = Math.random().toString(36).substring(2, WORD_SIZE + 2).toUpperCase();
        setNoBotText(result)
        // create image from text
        const canvHeight = 10
        const textCanvas = document.getElementById(randomId).getContext('2d')
        textCanvas.canvas.width = textCanvas.measureText(result).width;
        textCanvas.fillStyle = 'white'
        textCanvas.fillRect(0, 0, textCanvas.measureText(result).width, canvHeight)
        textCanvas.fillStyle = 'black'
        textCanvas.fillText(result, 0, canvHeight);
    }

    useEffect(() => {
        changeNoBotText()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitForm = async (e) => {
        e.preventDefault()

        const formValues = e.target.elements
        const textToCheck = formValues.verify.value
        if (textToCheck === '' || textToCheck.length !== WORD_SIZE || textToCheck !== noBotText) {
            changeNoBotText()
            return createNotification('error', 'Codul de verificare este greșit')
        }

        if (checkCookie(CONTACT_FORM_COOKIE)) {
            return createNotification('info', 'Vă rugăm așteptați înainte de a trimite un nou mesaj.')
        }
        setMinuteCookie(CONTACT_FORM_COOKIE, true, 10)

        setLoading(true)
        let geoRes = await 
            fetch('https://geolocation-db.com/json/')
            .then(res => res.json())
            .then(message => { return message })
            .catch(() => { return { country_name: '', city: '', postal: '', state: '', IPv4: '' }} )
        if (geoRes === null) {
            geoRes = { country_name: '', city: '', postal: '', state: '', IPv4: '' }
        }
        const location = `Țară: ${geoRes.country_name}, Oraș: ${geoRes.city}, Stat: ${geoRes.state}, Cod Postal: ${geoRes.postal}, IP: ${geoRes.IPv4}`
        const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        const mailData = {
            // id - toBeGenerated
            // type - 3
            name: formValues.name.value,
            fromMail: formValues.mail.value,
            mail: 'programintrastat@gmail.com',
            company: formValues.compName.value,
            message: formValues.message.value.replace(scriptRegex, ''),
            timeStamp: new Date(),
            location: location,
            ip: geoRes.IPv4,
            reply: false
        }
        await sendContact(mailData)
        document.getElementById("contactForm").reset()
        changeNoBotText()
        setLoading(false)
    }

    return (
        <div className='app-wrapper'>
            <h1>Contact</h1>
            <form className='login-form' id='contactForm' onSubmit={e => submitForm(e)}>
                <p>Nume*</p>
                <input className='input-text' type='text' name='name' id='name' title='nume' required/>
                <p>Email*</p>
                <input className='input-text' type='email' name='mail' id='mail' title='mail' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
                <p>Nume firmă*</p>
                <input className='input-text' title='Nume firmă' name='compName' id='compName' type='text' required/>
                <p>Telefon</p>
                <input className='input-text' 
                type='tel' pattern="[0-9]{10,12}" id='telefon' title='telefon' name='telefon'/>
                <p>Mesaj*</p>
                <textarea className='input-text' type='text' name='message' id='message' title='mesaj' rows={5}  required/>
                <canvas id={randomId} style={{marginTop:'1em', pointerEvents:'none'}} height={10}></canvas>
                <p>Introdu textul de mai sus*</p>
                <input className='input-text' type='text' name='verify' id='verify' required/>
                {   loading ?
                    <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                    <input className='input-submit' type="submit" value='Trimite' />
                }
            </form>
        </div>
    )
}

export default Contact