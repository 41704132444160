///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const CurrencyCode = [
    "AED",
    "AUD",
    "BGN",
    "BRL",
    "CAD",
    "CHF",
    "CNY",
    "CZK",
    "DKK",
    "EGP",
    "EUR",
    "GBP",
    "HRK",
    "HUF",
    "INR",
    "JPY",
    "KRW",
    "MDL",
    "MXN",
    "NOK",
    "NZD",
    "PLN",
    "RON",
    "RSD",
    "RUB",
    "SEK",
    "THB",
    "TRY",
    "UAH",
    "USD",
    "XAU",
    "XDR",
    "ZAR",
]