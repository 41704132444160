///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React,{ useEffect, useState } from 'react'

const ConfirmBox = ({
onConfirm, 
onClose, 
content, 
title, 
buttonConfirm = 'Confirmă', 
buttonCancel = 'Anulează'
}) => {
    const OUTSIDE_ID = 'modal-confirm-wrapper-bg'

    const handleOutsideClick = (id) => {
        if(id === OUTSIDE_ID) onClose()
    }

    const [pos, setPos] = useState({ scale:'0' })
    useEffect(() => {
        setPos({scale:'1'})
    }, [])
    
    return (
        <div className='modal-wrapper' id={OUTSIDE_ID} onClick={(e) => handleOutsideClick(e.target.id)}>
            <div className='modal-content confirm-box' 
            style={{ transform:`scale(${pos.scale})` }}>
                <div className='modal-header confirm-box'>
                    <h2>{title}</h2>
                    <div></div>
                </div>
                <div className='modal-body-wrapper confirm-box'>
                    {content}
                </div>
                <div className='modal-bottom center'>
                    <div className='button-secondary selected-btn' onClick={() => onConfirm()}>
                        {buttonConfirm}
                    </div>
                    <div className='button-secondary' onClick={() => onClose()}>
                        {buttonCancel}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmBox