///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React from 'react'
import WeclomePage from './WeclomePage';
import { useAuth } from '../context/AuthContext';
import MainApp from './MainApp';

const MainPage = () => {

  const { currentUser } = useAuth()

  return (
    <>{currentUser ? <MainApp /> :<WeclomePage />}</>
  )
}

export default MainPage