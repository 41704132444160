///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const TransportModes = [
    {
        code: 1,
        value: 'Transport maritim'
    },
    {
        code: 2,
        value: 'Transport feroviar'
    },
    {
        code: 3,
        value: 'Transport rutier'
    },
    {
        code: 4,
        value: 'Transport aerian'
    },
    {
        code: 5,
        value: 'Expediere prin poştă'
    },
    {
        code: 6,
        value: 'Transport multimodal'
    },
    {
        code: 7,
        value: 'Transport prin instalatii de transport fixe'
    },
    {
        code: 8,
        value: 'Transport naval pe căi navigabile interioare'
    },
    {
        code: 9,
        value: 'Propulsie proprie'
    },
]