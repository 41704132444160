///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import { saveAs } from "file-saver";
import { checkIfDate, toFormatDate } from "./DateService";

export const getExcelSheets = (file) => {
  if (!file) return [];

  let toReturn = [];
  const fileReader = new FileReader();
  fileReader.readAsBinaryString(file);

  fileReader.onload = (event) => {
    const data = event.target.result;
    const workbook = window.XLSX.read(data, { type: "binary" });
    workbook.SheetNames.forEach((sheet) => {
      toReturn.push(sheet);
    });
  };
  return toReturn;
};

export const getSheetValues = (file) => {
  if (!file) return [];

  let toReturn = [];
  const fileReader = new FileReader();
  fileReader.readAsBinaryString(file);

  fileReader.onload = (event) => {
    const data = event.target.result;
    const workbook = window.XLSX.read(data, {
      type: "binary",
      cellDates: true,
    });
    workbook.SheetNames.forEach((sheet) => {
      const rowObject = window.XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[sheet],
        { defval: "" }
      );
      for (const row of rowObject) {
        for (const [key, value] of Object.entries(row)) {
          if (checkIfDate(value)) {
            row[key] = toFormatDate(value);
          }
        }
      }
      toReturn.push(rowObject);
    });
  };
  return toReturn;
};

export const getHeaders = (arr, withNr = false) => {
  if (!arr || arr.length <= 0) return [];
  let toReturn = [];
  const obj = arr[0];
  let index = 1;
  for (const [key] of Object.entries(obj)) {
    if (key !== "__rowNum__") {
      toReturn.push(`${withNr ? index + " - " : ""}${key}`);
      index++;
    }
  }
  return toReturn;
};

export const getHeaderIndex = (name) => {
  return parseInt(name.substr(0, name.indexOf("-")).trim()) - 1;
};

export const exportExcel = (
  cells,
  headers,
  title = "Exportat din programintrastat.ro"
) => {
  if (!cells && !headers) return false;
  if (!cells[0]) return false;

  const wb = window.XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    Subject: "programintrastat.ro",
    Author: "programintrastat.ro",
    CreatedDate: new Date(),
  };
  const firstPage = [headers];

  cells[0].forEach((c, i) => {
    const row = [];
    cells.forEach((cell) => {
      row.push(cell[i]);
    });
    firstPage.push(row);
  });
  // First sheet
  wb.SheetNames.push("Tabel întreg");
  wb.Sheets["Tabel întreg"] = window.XLSX.utils.aoa_to_sheet(firstPage);

  const wbout = window.XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    "Export-programintrastatRo.xlsx"
  );
};
