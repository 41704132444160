///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const vatUrlString = (country, vatCode) => {
  return `https://program-intrastat-backend.web.app/validate-vat/${country}/${vatCode}/NJwjrlDtUzrBrT44iNz1Libfpu0Q8GvknhYf7RcoSZEmKk`;
};

export const validateRegCom = (check) => {
  const pattern = new RegExp("([J][0-9]{2})/([0-9]{3,4})/([0-9]{4})");
  return pattern.test(check.toUpperCase());
};

export const validateIban = (check) => {
  const pattern =
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;
  return pattern.test(check.toUpperCase());
};

export const validateVATro = (check) => {
  if (!check) return false;
  check.replace(/\s/g, "");
  // if(check.toUpperCase().substring(0,2) !== 'RO') return true
  let intNr = 10;
  let vNr = 753217532;
  let cifNr = parseInt(check.toUpperCase().replace("RO", ""));
  let c1 = cifNr % intNr;
  cifNr = Math.floor(cifNr / intNr);
  let t = 0;
  while (cifNr > 0) {
    t += (cifNr % intNr) * (vNr % intNr);
    cifNr = Math.floor(cifNr / intNr);
    vNr = Math.floor(vNr / intNr);
  }
  //modulo 11
  let c2 = (t * intNr) % 11;
  if (c2 === intNr) c2 = 0;
  return c1 === c2;
};

export const validateVATEu = (value, skipDefault = false) => {
  if (!value) return false;
  const defaultValue = "QV999999";
  if (skipDefault && defaultValue === value) return true;
  const vatRegex =
    /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8,10}[a-zA-Z]|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(HR)?[0-9]{2,12}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{8,15}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2})$/g;
  return vatRegex.test(value);
};

export const splitVat = (value) => {
  if (value === "" || !value) return false;
  const country = value.toString().substring(0, 2);
  const code = value.toString().substring(2, value.length);
  return [country, code];
};

export const getXMLVat = value => {
  const VAT_LENGTH = 10
  const numbers = splitVat(value)[1]
  const zeros = new Array(VAT_LENGTH - numbers.length).fill('0').join('')
  return zeros + numbers
}
