///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext';
import { currentSubMonth, isStillActive, subDisplayData, subscriptionExpireDate, subscriptionNames } from '../../services/SubscriptionsService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faX, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { formatNr } from '../../services/FormatService';
import { userSideId } from '../../services/IdService';
import ReactTooltip from 'react-tooltip';
import Popup from '../Popup';

const Subscriptions = () => {
  const {  
    userData,
    currentUser,
    newSubscription,
    loadingAction
  } = useAuth()

  const [openPopup, setOpenPopup]   = useState(false)
  const [popupData, setPopupData]   = useState({})
  const [subDisplay, setSubDisplay] = useState([])
  const [isOpen, setIsOpen]         = useState(false)
  const [chartData, setChartData]   = useState([])
  const [isLoading, setIsLoading]   = useState(false)

  const chartColors = ['--chart-good', '--chart-warn', '--chart-bad'];

  const getPerc = (min, max) => {
    if (min <= 0) return 0
    if (min >= max) return 100
    return Math.round((min * 100)/max)
  }

  const setChartDisplayData = () => {
    if (userData.subscriptions.length === 0) return
    const uSub = userData.subscriptions[0]
    if (Object.keys(uSub).length === 0) return
    if (!uSub.valid || uSub.manualDisable) return 
    const currLines = uSub.lines[currentSubMonth(uSub.date)] !== undefined ? parseInt(uSub.lines[currentSubMonth(uSub.date)]) : 0
    const currLinesPerc = getPerc(currLines, parseInt(uSub.linesStart))
    const currMPerc = getPerc((uSub.duration - currentSubMonth(uSub.date)), parseInt(uSub.duration))
    const toAdd = [
        {
            maxVal: uSub.linesStart,
            currVal: currLines,
            currPerc: currLinesPerc,
            title: 'Linii rămase din lună',
            color: currLinesPerc > 30 ? chartColors[0] : currLinesPerc > 15 ? chartColors[1] : chartColors[2]
        },
        {
            maxVal: uSub.duration,
            currVal: uSub.duration - currentSubMonth(uSub.date),
            currPerc: currMPerc,
            title: 'Luni rămase',
            color: currMPerc > 30 ? chartColors[0] : currMPerc > 15 ? chartColors[1] : chartColors[2]
        }
    ]
    setChartData(toAdd)
  }

  useEffect(() => {
    setSubDisplay(subDisplayData())
    setChartDisplayData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenPopup = (data) => {
    const dataToAdd = {
        type: 3,
        data,
        title: 'Confirmați achziția'
    }
    setPopupData(dataToAdd)
    setOpenPopup(true)
  }

  const getTotalPrice = (price, months = 12) => {
    return (parseFloat(price) * months).toFixed(2)
  }

  const purchaseSubscription = async (data) => {
    if (loadingAction || isLoading) return
    const today = new Date();
    // Example object data
    // {
    //     "id": 2, // = type
    //     "name": "Plus",
    //     "lines": 1300,
    //     "time": 12,
    //     "price": 19.99,
    //     "options": {
    //         "exchange": true,
    //         "valStat": true,
    //         "vat": true,
    //         "layouts": 3,
    //         "companies": 1
    //     }
    // }
    setIsLoading(true)
    const subData = {
        uid: currentUser.uid,
        name: data.id,
        date: today.toString(),
        timeStamp: today,
        duration: data.time,
        lines: new Array(parseInt(data.time)).fill(data.lines),
        linesStart: data.lines,
        cifCheck: data.options.vat,
        compAvailable: data.options.companies,
        valStatCalc: data.options.valStat,
        layoutsAvailable: data.options.layouts,
        manualDisable: false,
        valid: false,
        price: data.price
    }
    const invoiceData = {
        // id,
        uid: currentUser.uid,
        type: '0',
        // series,
        // number,
        // seriesNumber,
        status: '0',
        timestamp: new Date(),
        created: new Date(),
        paidDate: '',
        dayMonthYear: `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,
        // price ,
        // currencyRate,
        subscription: {
            // id,
            name: data.name,
            period: data.time,
            quantity: 1,
            priceEur: +data.price * +data.time
        },
        client: {
            name: userData.data.company,
            cui: userData.data.cui,
            regCom: userData.data.regCom,
            address: userData.data.address,
            county: userData.data.county,
            email: currentUser.email
        }
    }
    await newSubscription(subData, invoiceData)
    setOpenPopup(false)
    setIsLoading(false)
  }

  const getTax = (val) => {
    return ((19/100)* +val).toFixed(2)
  }

  const getWithTax = (val) => {
    const tax = 19/100
    return (tax * (+val) + (+val))
  }

  const popupContent = (data) => (
    <div className='modal-body-wrapper'>
        <div className='modal-form'>
            <div className='modal-form-section'>
                <div>Denumire: <b>{data.name}</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Preț fără TVA: <b>{formatNr(getTotalPrice(data.price, 12))}€</b></div>
            </div>
            <div className='modal-form-section'>
                <div>TVA: <b>{formatNr(getTax(getTotalPrice(data.price, 12)))}€</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Preț TOTAL: <b>{formatNr(getWithTax(getTotalPrice(data.price, 12)))}€</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Durată: <b>{data.time} luni</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Linii: <b>{formatNr(data.lines)}</b>/ lună</div>
            </div>
            <div className='modal-form-section'>
                <div>Machete: <b>{data.options.layouts}</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Firme: <b>{data.options.companies}</b></div>
            </div>
            <div className='modal-form-section'>
                <div>Expiră la: <b>{subscriptionExpireDate(new Date(), data.time)}</b></div>
            </div>
            <div style={{color:'red'}}>! Atenție !</div>
            <div style={{color:'red'}}>Abonamentul anterior va fi dezactivat odată cu achiziția.</div>
            <div className='modal-bottom'>
                <div onClick={() => purchaseSubscription(data)} className='button-secondary selected-btn'>
                    {isLoading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 'Achiziționează'}
                </div>
            </div>
        </div>
    </div>
  )

  return (
    <div className='app-default-wrapper'>
        <div className='app-default-section'>
            <div className='app-default-header'>
                <div className='button-secondary btn-green' onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Închide' :'Abonament nou'}</div>
            </div>
            {isOpen &&
            <>
            <div className='sub-view-wrapper'>
                {subDisplay.map(data => {
                    return(
                    <div key={`${data.id} - ${data.name}`} 
                        onClick = {() => handleOpenPopup(data)}
                        className='small-section sub-card'>
                        <h2>{data.name}</h2>
                        <h3><b>Preț:</b> {data.price}<small>€/lună</small></h3>
                        <div><b>Linii: </b>{formatNr(data.lines)}</div>
                        <div><b>Machete: </b>{data.options.layouts}</div>
                        <div><b>Firme: </b>{data.options.companies}</div>
                        <div><b>Verificare CIF: </b>{data.options.vat ? 'DA' : 'NU'}</div>
                        <div><b>Valoare Statistică: </b>{data.options.valStat ? 'DA' : 'NU'}</div>
                        <div><b>Convertire valută BNR: </b>{data.options.exchange ? 'DA' : 'NU'}</div>
                    </div>
                    )
                })
                }
            </div>
            <div className='small-info'>
                <small>Toate abonamentele sunt facturate pe perioada duratei acestora.</small><br />
                <small>Pentru o ofertă personalizată vă rugăm să ne contactați.</small> <br />
                <small>Prețurile afișate NU includ TVA.</small>
            </div>
            </>
            }
            <div className="table-fixed-header">
                <table className='simple-table'>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Abonament</th>
                        <th>Perioadă</th>
                        <th>Expirare</th>
                        <th>Linii rămase din lună</th>
                        <th>Stare</th>
                    </tr>
                    </thead>
                    {userData.subscriptions.map(sub => {
                        return(
                        <tbody key={sub.subId}>
                        <tr>
                            <td className='break-word' style={{userSelect:'all'}}>{userSideId(sub.subId)}</td>
                            <td>{subscriptionNames(sub.name)}</td>
                            <td>{`${sub.duration} luni`}</td>
                            <td>{subscriptionExpireDate(sub.date, sub.duration)}</td>
                            <td>{!sub.manualDisable ? (`${sub.lines[currentSubMonth(sub.date)] !== undefined ?
                                formatNr(sub.lines[currentSubMonth(sub.date)]) : 0
                                }`) : 0}</td>
                            <td data-tip data-for={
                                (!sub.valid && !sub.manualDisable ) ? 'sub-invalid' :
                                isStillActive(sub.date, sub.duration, sub.manualDisable) ? 'sub-active' : 'sub-expired'
                                }>
                                {(!sub.valid && !sub.manualDisable ) ? <span style={{color:"orange"}}><FontAwesomeIcon icon={faX} /></span> :
                                isStillActive(sub.date, sub.duration, sub.manualDisable) ?
                                <span style={{color:"green"}}><FontAwesomeIcon icon={faCircleCheck} /></span> :
                                <span style={{color:"red"}}><FontAwesomeIcon icon={faX} /></span>
                                }
                            </td>
                        </tr>
                        </tbody>
                        )
                    })}
                </table>
            </div>
            <div className='gauges-row'>
                {
                    chartData.length > 0 &&
                    chartData.map((val, i) => {
                        return(
                            <div className='gauge-default' key={`${val.title} - ${i} - ${val.currPerc}`}>
                                <h3>{val.title}</h3>
                                <div className="semi-donut" style={{"--percentage-semi-donut": `${val.currPerc}`, 
                                    "--fill": `var(${val.color})`}}>
                                    <div>{formatNr(val.currVal)}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

        {
            openPopup &&
            <Popup data = { popupData } close = {() => setOpenPopup(false)} >
                { popupContent(popupData.data) }
            </Popup>
        }

        <ReactTooltip effect="solid" id='sub-active' type='success'>Activ</ReactTooltip>
        <ReactTooltip effect="solid" id='sub-expired' type='error'>Inactiv</ReactTooltip>
        <ReactTooltip effect="solid" id='sub-invalid' type='warning'>
            <p><b>Invalid</b></p>
            <p>Efectuați plata în secțiunea "Plăți" pentru validare.</p>
        </ReactTooltip>
    </div>
  )
}

export default Subscriptions