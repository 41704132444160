///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import { VERSION } from "./CodVamal"

export const COD_VAMAL = VERSION

export const TARA_ORIGINE = '2022'

export const TARA_ORIGINE_EU = '2021'

export const MOD_TRANSPORT = '2005'

export const CONDITIE_LIVRARE = '2021'

export const NATURA_TRANZACTIEI = '2022'

export const TARA_DEST_EXP = '1'

export const LOCALITATE = '06/2006'

export const UNITATE = '1'