import React from 'react';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';

import '../style/invoice.css'
// import logoImg from '../images/LogoNoBg.png'
import { getDateFromTimestamp, toFormatDate } from './DateService';

export const InvoiceToPrint = React.forwardRef((printData, ref) => {

  // deconstruct
  const { data, toDownload } = printData
  const { taxData } = data

  const tvaVal = 19/100

  const getValFromEur = (val) => {
    return (+val * +data.currencyRate).toFixed(2)
  }

  const getTva = (val) => {
    return (tvaVal * val).toFixed(2)
  }

  const getTotalWithTva = (val) => {
    return (+getTva(val) + val).toFixed(2)
  }

  const getInvoiceDate = () => {
    const newDate = data.status === '1' ? data.paidDate : data.timestamp
    return toFormatDate(getDateFromTimestamp(newDate))
  }

  const invoiceBody = (
    <>
      <section className='invoice-title'>
        <div className='main-title'>Factura</div>
        { data.type === '0' && <div className='bold-title'>proforma</div> }
        <div className='info-block'>
          <p>Seria: { data.series }</p>
          <p>Numar: { data.number }</p>
          <p>Data emiterii: { getInvoiceDate() }</p>
        </div>
      </section>
      <section className='top-left-info'>
          {/* <img className='logo-invoice' src={logoImg} placeholder='logo' alt='logo' /> */}
      </section>
      <section className='top-right-info'>
          <p className='series-big'>{ `${data.series}${data.number}` }</p>
          <p>Cap. social: { taxData.capSocial } lei</p>
          <p>Cota TVA: { taxData.cotaTva }%</p>
          { taxData.tipTva === 'la incasare' && <p>TVA la incasare</p> }
      </section>
      <section className='invoice-header'>
        {/* FURNIZOR */}
        <div className='info-block'>
          <p className='small-title'>Furnizor</p>
          <p className='bold-title'>{ taxData.name }</p>
          <p>CIF: { taxData.cif }</p>
          <p>Reg. com.: { taxData.regCom }</p>
          <p>Adresa: { taxData.address }</p>
          <p>Judetul: { taxData.county }</p>
          <p>IBAN: { taxData.iban }</p>
          <p>Banca: { taxData.bank }</p>
        </div>

        {/* CLIENT */}
        <div className='info-block'>
          <p className='small-title'>Client</p>
          <p className='bold-title clamp-title'>{ data.client.name }</p>
          <p>CIF: { data.client.cui }</p>
          <p>Reg. com.: { data.client.regCom }</p>
          <p>Adresa: { data.client.address }</p>
          <p>Judetul: { data.client.county }</p>
          <p>Email: { data.client.email }</p>
        </div>
      </section>
      <section className='invoice-body'>
        <table className='inv-table'>
          <tbody className='inv-tbody'>
            <tr>
              <th>Nr. <br/>crt</th>
              <th>Denumirea produselor <br/>sau a serviciilor</th>
              <th>U.M.</th>
              <th>Cant.</th>
              <th>Pret unitar <br/>LEI</th>
              <th>Valoarea <br/>LEI</th>
              <th>Valoarea TVA<br/>LEI</th>
            </tr>
            <tr>
              <td>1</td>
              {/* <td>Servicii raportare Intrastat { data.subscription.name } - { data.subscription.period } luni</td> */}
              <td>Access platformă Programintrastat.ro - { data.subscription.name } - { data.subscription.period } luni</td>
              <td>BUC</td>
              <td>{ data.subscription.quantity }</td>
              <td>{ getValFromEur(data.subscription.priceEur) }</td>
              <td>{ getValFromEur(data.subscription.priceEur) * +data.subscription.quantity }</td>
              <td>{ getTva(getValFromEur(data.subscription.priceEur)) }</td>
            </tr>
            <tr>
              <td colSpan={5}>TOTAL</td>
              <td>{ getValFromEur(data.subscription.priceEur) * +data.subscription.quantity }</td>
              <td>{ getTva(getValFromEur(data.subscription.priceEur)) * +data.subscription.quantity }</td>
            </tr>
          </tbody>
        </table>
        <div className='flex-width'>
          <div className='info-block'>
            <p>Curs <span className='series-big'>1 EURO = {data.currencyRate} Lei</span></p>
          </div>
          <div className='total-payment'>
            <p className='series-big'>Total de plata</p>
            <p className='bold-title'>{ getTotalWithTva(getValFromEur(data.subscription.priceEur) * +data.subscription.quantity) } lei</p>
          </div>
        </div>
      </section>
      <section className='invoice-footer'>
        <div className='info-block'>
          {/* <p>www.programintrastat.ro</p>
          <p>programintrastat@gmail.com</p> */}
        </div>
        <div className='footer-legal'>
          <p>Factura este valabila fara semnatura si stampila, conform art. 319 alin. 29 din legea 227/2015.</p>
          <p>Marfurile si/sau serviciile au fost vandute si livrate in conditii optime de calitate conform conditiilor de vanzare ale furnizorului.</p>
          <p>Marfurile si/sau serviciile raman proprietatea vanzatorului (furnizorului) pana la achitarea integrala a facturii.</p>
        </div>
      </section>
    </>
  )

  if (toDownload) {
    const ComponentToSave = () => {
      return (
        <div id='invoice-template'>
          { invoiceBody }
        </div>
      )
    }

    const saveNew = () => {
      const settings = {
        filename: data.seriesNumber
      }
      const printElement = ReactDOMServer.renderToString(ComponentToSave())
      html2pdf().from(printElement).set(settings).save();
    }

    // call once
    return saveNew()
  }

  return (
    <div id='invoice-template' ref={ref}>
      { invoiceBody }
    </div>
  )
  
})