///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import {NotificationManager} from 'react-notifications';

export const createNotification = (type, msg, title, timer = 10000) => {
    switch (type) {
    case 'info':
        NotificationManager.info(msg, title && title, timer && timer);
        break;
    case 'success':
        NotificationManager.success(msg, title && title, timer && timer);
        break;
    case 'warning':
        NotificationManager.warning(msg, title && title, timer && timer);
        break;
    case 'error':
        NotificationManager.error(msg, title && title, timer && timer);
    //   NotificationManager.error('Error message', 'Click me!', 5000, () => {
    //     alert('callback');
    //   });
        break;
    default:
        break;
    }
};